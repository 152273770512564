export const CHART_COLORS = [
    "#1A9479",
    "#4963E1",
    "#9719B6",
];

export function getAvailableColors(usedColors: string[] = []): IterableIterator<string> {
    const colors = new Set(CHART_COLORS);

    usedColors.forEach(cl => colors.delete(cl));

    return loop(colors.values());
}

function * loop<T>(iterable: IterableIterator<T>): IterableIterator<T> {
    const all = [...iterable];
    let i = 0;

    while (true) {
        yield all[i];
        i = (i + 1) % all.length;
    }
}
