import {
    ChangeDetectionStrategy,
    Component,
} from "@angular/core";

@Component({
    selector: "sk-date-picker-calendar-days-labels",
    templateUrl: "./datePickerCalendarDaysLabels.pug",
    styleUrls: ["./datePickerCalendarDaysLabels.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerCalendarDaysLabelsComponent {

    public dayLabels = [
        {
            short: "S",
            long: "Sunday",
        },
        {
            short: "M",
            long: "Monday",
        },
        {
            short: "T",
            long: "Tuesday",
        },
        {
            short: "W",
            long: "Wednesday",
        },
        {
            short: "T",
            long: "Thursday",
        },
        {
            short: "F",
            long: "Friday",
        },
        {
            short: "S",
            long: "Saturday",
        },
    ];
}
