import { Component, ChangeDetectionStrategy, Inject, Injector, AfterViewInit, ViewChild, TemplateRef } from "@angular/core";
import { makeObservable } from "mobx";
import { filter, from, map, mapTo, merge, Observable, switchMap } from "rxjs";
import { toStream } from "mobx-utils";

import { computed, observableRef } from "skCommon/state/mobxUtils";
import { assert } from "skCommon/utils/assert";
import { SidePanelService } from "skCommon/ui/sidePanel/sidePanel.service";
import { exists } from "skCommon/utils/types";
import { Logger } from "skCommon/utils/logger";
import { ComponentDef } from "skCommon/insights/dashboard";

import { LayoutComponent, LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { CardComponentDef } from "skInsights/framework/card/cardComponent";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { RenderableComponent } from "skInsights/framework/renderable";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { DynamicNavService } from "skInsights/framework/dynamicNav.service";
import { DataExplorerNavService } from "skInsights/dashboard/dashboardView/dataExplorer/dataExplorerNavService.service";
import { DashboardGlobals } from "skInsights/dashboard/globalConstants.service";
import { LineChartComponentType } from "skInsights/modules/charts/lineChart/lineChart.component";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { DashboardPdfService } from "skInsights/framework/dashboardPdf/dashboardPdf.service";

export const SidebarViewComponentType: SidebarViewComponentType = "core/sidebar-view";
export type SidebarViewComponentType = "core/sidebar-view";

@Component({
    selector: "sk-core-sidebar-view",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./sidebarView.pug",
    styleUrls: ["./sidebarView.scss"],
})
export class SidebarViewComponent
    extends LayoutComponent<SidebarViewComponentDef>
    implements AfterViewInit {

    @observableRef
    public dashboardName: string;

    public lastDataUpdate$: Observable<string | undefined> = this.getLastDataUpdate$();

    @observableRef
    public currentPage?: RenderableSidebarItem;

    @observableRef
    public mobileMenuOpen: boolean = false;

    @observableRef
    public loading: boolean = false;

    @ViewChild("theMenuContent", { read: TemplateRef })
    private theMenuContent?: TemplateRef<any>;

    @ViewChild("thePhoneMenuButton", { read: TemplateRef })
    private thePhoneMenuButton?: TemplateRef<any>;

    @observableRef
    public links: (string | undefined)[] = [];

    @computed
    public get sections(): RenderableSidebarSection[] {
        return this.createRenderableSections();
    }

    @computed
    public get renderable(): RenderableComponent | undefined {
        return this.currentPage?.content;
    }

    @computed
    public get neighbours(): RenderableNeighbours {
        const allPages = this.sections.flatMap(sec => sec.items);

        const foundIndex = this.currentPage
            ? allPages.indexOf(this.currentPage)
            : -1;

        if (foundIndex === -1) {
            return {
                previous: undefined,
                next: undefined,
            };
        } else {
            return {
                previous: allPages[foundIndex - 1],
                next: allPages[foundIndex + 1],
            };
        }
    }

    public get lastDataUpdateAvailable(): boolean {
        return !!this.currentPage?.page.lastDate;
    }

    public get dataExplorerUrl(): string[] | undefined {
        return this.dataExplorerNavService.available
            ? this.dataExplorerNavService.urlForCurrentDashboard
            : undefined;
    }

    public get canExportPage(): boolean {
        return this.dashboardPdfService.available;
    }

    constructor(
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        protected readonly def: SidebarViewComponentDef,
        private dataRefService: DataRefService,
        private moduleRegistryService: ModuleRegistryService,
        private injector: Injector,
        private dynamicNavService: DynamicNavService,
        private sidePanelService: SidePanelService,
        private dataExplorerNavService: DataExplorerNavService,
        private dashboardPdfService: DashboardPdfService,
        private logger: Logger,
        private snackBarService: SnackBarService,
    ) {
        super();

        makeObservable(this);

        this.dashboardName = this.dataRefService.getConstant(DashboardGlobals.DashboardName);
        this.currentPage = this.sections[0]?.items[0];
        this.links = this.getCurrentChartLinks();
    }

    public ngAfterViewInit(): void {
        assert(this.thePhoneMenuButton, "Phone menu button missing");

        this.dynamicNavService.renderNav({
            template: this.thePhoneMenuButton,
            beforeMain: true,
        });
    }

    public getLastDataUpdate$(): Observable<string | undefined> {
        const page$ = from(toStream(() => this.currentPage, true)).pipe(
            filter(exists),
            map(item => item.page),
            filter(page => !!page.lastDate),
        );

        return merge(
            // Reset header whenever page switches,
            page$.pipe(mapTo(undefined)),

            page$.pipe(
                switchMap(page => this.dataRefService.resolveString(`${page.lastDate!} | date`)),
                map(stringObj => stringObj.text),
                map(formattedDate => `Last data update: ${formattedDate}`),
            ),
        );
    }

    public setActive(item: RenderableSidebarItem): void {
        this.currentPage = item;
        this.mobileMenuOpen = false;
        this.links = this.getCurrentChartLinks();

        // Do not unnecessarily put menu into viewport, so the sidebar keeps
        // its position
        const top = Math.min(window.scrollY, 48);

        window.scrollTo({ top });
    }

    public openSidePanelMenu(): void {
        assert(this.theMenuContent, "Menu content is missing");

        this.sidePanelService.open(this.theMenuContent, {
            width: "auto",
        });
    }

    public toggleMobileMenu(): void {
        this.mobileMenuOpen = !this.mobileMenuOpen;
    }

    public async exportAsPdf(): Promise<void> {
        this.loading = true;
        try {
            await this.dashboardPdfService.downloadPdf(this.def);
        } catch (e) {
            this.logger.error(e, "Export dashboard as PDF");
            this.snackBarService.notify(`Could not export dashboard: ${e.message}`, 8);
        } finally {
            this.loading = false;
        }
    }

    private getCurrentChartLinks(): (string | undefined)[] {
        const titles: (string | undefined)[] = [];
        const content = this.currentPage!.page.content;
        const options = this.moduleRegistryService.getComponentOptions(content);
        if (options.getChildComponents) {
            const childComponents = options.getChildComponents(content);
            childComponents.forEach(child => {
                if (child.component === LineChartComponentType) {
                    const childComponent = child as unknown as CardComponentDef;
                    titles.push(childComponent.template?.title);
                }
            });
        }
        return titles;
    }

    private createRenderableSections(): RenderableSidebarSection[] {
        return this.def.sections.map(section => ({
            label: section.label,
            items: section.pages.map(page => ({
                page,
                title: page.title,
                content: this.makeRenderablePage(page),
            })),
        }));
    }

    private makeRenderablePage(page: PageDefinition): RenderableComponent {
        return {
            component: this.moduleRegistryService.getAngularComponent(page.content),
            injector: Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: LAYOUT_COMPONENT_DEF_TOKEN,
                        useValue: page.content,
                    },
                    ...this.dataRefService.extend({
                        SIDEBAR_VIEW_PAGE_TITLE: page.title,
                    }),
                ],
            }),
        };
    }
}

/**
 * Container component displaying child components on separate scrollable pages
 * with horizontal navigation next to them.
 */
export interface SidebarViewComponentDef {
    component: SidebarViewComponentType;
    /**
     * Section definitions in which are the pages separated into.
     */
    sections: SectionDefinition[];
}

export interface PageDefinition {
    /**
     * Title displayed in the sidebar navigation and also provided into the
     * child component as SIDEBAR_VIEW_PAGE_TITLE.
     */
    title: string;
    /**
     * Component to display on the page, best works with vertical flex
     * container
     */
    content: ComponentDef;
    /**
     * Reference to date string indicating last data update
     * @dataref
     */
    lastDate?: string;
    /**
     * If set to true, this page isn't included when exporting dashboard into PDF
     */
    excludeFromPdf?: boolean;
}

export interface SectionDefinition {
    /**
     * Label displayed at the top of the section
     */
    label: string;
    /**
     * Page definitions containing the child component and additional details.
     */
    pages: PageDefinition[];
}

interface RenderableSidebarSection {
    label: string;
    items: RenderableSidebarItem[];
}

interface RenderableSidebarItem {
    page: PageDefinition;
    title: string;
    content: RenderableComponent;
}

interface RenderableNeighbours {
    previous: RenderableSidebarItem | undefined;
    next: RenderableSidebarItem | undefined;
}
