import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";

import { SharedModule } from "skCommon/angular/shared.module";
import { AutorunModule } from "skCommon/angular/state/autorun.module";
import { ToggleChipComponent } from "skCommon/ui/toggleChip/toggleChip.component";

@NgModule({
    imports: [
        SharedModule,
        AutorunModule,
        MatRippleModule,
    ],
    declarations: [
        ToggleChipComponent,
    ],
    exports: [
        ToggleChipComponent,
    ],
})
export class ToggleChipModule { }
