import { Doc } from "skCommon/firebase/doc";
import { assert } from "skCommon/utils/assert";
import { sanitizeFilename } from "skCommon/utils/download";

import { InsightsDocument } from "skInsights/modules/documents/document";

export function getDocFileName(doc: Doc<InsightsDocument>): string {
    assert(doc.title, "File missing title");
    const title = doc.title ? doc.title.trim() : "newDoc";
    const sanitized = sanitizeFilename(title);
    const base = sanitized.replaceAll("[", "(").replaceAll("]", ")");
    const ids = doc.usedProductIds ?? [];
    return `${base}[${ids.toString()}].pdf`;
}
