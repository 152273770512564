import { Inject, Injectable, Optional } from "@angular/core";
import { computed, makeObservable } from "mobx";

import { MainNavEntry, MainNavEntryProvider, NavEntry, NAV_ENTRIES_TOKEN, NAV_ENTRY_PROVIDERS_TOKEN } from "skInsights/nav/nav";
import { UserService } from "skInsights/user/user.service";

@Injectable({ providedIn: "root" })
export class NavService {

    @computed
    public get allNavEntries(): MainNavEntry[] {
        return this.adaptMenuEntries([
            ...this.navEntries,
            ...this.navEntryProviders.flatMap(p => p.navEntries),
        ]);
    }

    constructor(
        private userService: UserService,
        @Optional()
        @Inject(NAV_ENTRIES_TOKEN)
        private navEntries: MainNavEntry[],
        @Optional()
        @Inject(NAV_ENTRY_PROVIDERS_TOKEN)
        private navEntryProviders: MainNavEntryProvider[],
    ) {
        this.navEntries = this.navEntries || [];
        this.navEntryProviders = this.navEntryProviders || [];

        makeObservable(this);
    }

    private adaptMenuEntries(mainEntries: MainNavEntry[]): MainNavEntry[] {
        return mainEntries
            .map(e => this.adaptSubMenuEntries(e))
            .filter(e => !e.menu || e.menu.length)
            .filter(e => this.checkEntryPermissions(e));
    }

    private adaptSubMenuEntries(e: MainNavEntry): MainNavEntry {
        if (e.menu) {
            return {
                ...e,
                menu: e.menu.filter(sub => this.checkEntryPermissions(sub)),
            };
        } else {
            return e;
        }
    }

    private checkEntryPermissions(e: NavEntry): boolean {
        return !e.neededPermissions
            || this.userService.loggedIn
            && this.userService.permissions.hasAll(e.neededPermissions);
    }
}
