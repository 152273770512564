import { Injectable, Type, Injector, StaticProvider } from "@angular/core";

import { DashboardSchema, AnyOperationDef } from "skCommon/insights/dashboard";

import { DashboardData } from "skInsights/framework/dashboardData";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { LayoutComponent, LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { DashboardEvents } from "skInsights/framework/dashboardEvents";
import { RenderableComponent } from "skInsights/framework/renderable";
import { OperationPipe, DashboardPipe } from "skInsights/framework/pipe";
import { CacheService } from "skInsights/framework/cache.service";
import { LinkService } from "skInsights/framework/link.service";
import { DashboardContext } from "skInsights/framework/abstract/dashboardContext";
import { MutationSource, MUTATION_TYPE } from "skInsights/framework/mutationSource.service";
import { DashboardPdfService } from "skInsights/framework/dashboardPdf/dashboardPdf.service";

/**
 * Provides all necessary methods to render a dashboard using the framework
 */
@Injectable({ providedIn: "root" })
export class BuilderService {

    constructor(
        private moduleRegistryService: ModuleRegistryService,
        private cacheService: CacheService,
        private injector: Injector,
        private mutationSource: MutationSource,
    ) { }

    /**
     * Prepare renderable component displaying dashboard of given schema
     */
    public async build({
        schema,
        constants = {},
        parent = this.injector,
        context = new DashboardContext({}),
        providers = [],
    }: BuildDashboardInput): Promise<RenderableComponent> {
        const dashboardData = await this.getDashboardData(schema);

        const injector = Injector.create({
            providers: [
                {
                    provide: LAYOUT_COMPONENT_DEF_TOKEN,
                    useValue: schema.layout,
                },
                {
                    provide: DashboardData,
                    useValue: dashboardData,
                },
                {
                    provide: DashboardEvents,
                    useClass: DashboardEvents,
                },
                {
                    provide: LinkService,
                    useClass: LinkService,
                },
                {
                    provide: DashboardContext,
                    useValue: context,
                },
                {
                    provide: DashboardPdfService,
                    useClass: DashboardPdfService,
                },
                ...providers,
                ...DataRefService.provide(constants),
            ],
            parent,
        });

        const component = this.resolveRootComponent(schema);

        return { injector, component };
    }

    public async getDashboardData(
        schema: DashboardSchema,
    ): Promise<DashboardData> {
        const cached = await this.cacheService.get(schema);

        return cached || this.createDashboardData(schema);
    }

    public resolveRootComponent(schema: DashboardSchema): Type<LayoutComponent<any>> {
        if (schema.layout) {
            return this.moduleRegistryService.getAngularComponent(schema.layout);
        } else {
            throw new Error("Dashboard has no component");
        }
    }

    private createOperationPipes(
        pipes: Record<string, AnyOperationDef[]>,
    ): Record<string, DashboardPipe> {
        return Object.fromEntries(Object.entries(pipes).map(([k, ops]) => [
            k,
            new OperationPipe(ops, this.moduleRegistryService),
        ]));
    }

    private createDashboardData(schema: DashboardSchema): DashboardData {
        const userPipes = this.createOperationPipes(schema.pipes || {});
        const dashboardData = new DashboardData({
            ...this.moduleRegistryService.getOperationProvidedPipes(),
            ...userPipes,
        });
        const sources = schema.sources
            ? Object.entries(schema.sources)
            : [];

        for (const [id, definition] of sources) {
            const source = this.moduleRegistryService.getSource(definition);

            dashboardData.addSource(id, { definition, source });
        }

        this.insertMutationSources(schema, dashboardData);

        return dashboardData;
    }

    /**
     * Compute mutations defined in given schema and save results into given
     * DashboardData instance.
     *
     * @todo better integrate with the pipes as used in dataRef.service
     */
    private insertMutationSources(
        schema: DashboardSchema,
        data: DashboardData,
    ): void {
        const mutationEntries = Object.entries(schema.mutations || {});

        for (const [id, mutation] of mutationEntries) {
            data.addSource(id, {
                source: this.mutationSource,
                definition: {
                    type: MUTATION_TYPE,
                    ...mutation,
                    omitFromDataExplorer: true,
                },
            });
        }
    }
}

interface BuildDashboardInput {
    schema: DashboardSchema;
    parent?: Injector;
    constants?: Record<string, string>;
    context?: DashboardContext;
    /**
     * Extra providers to provide in dashboard's context
     */
    providers?: StaticProvider[];
}
