import { Type } from "@angular/core";
import { makeObservable, observable } from "mobx";
import { ChartDataset, ChartType } from "chart.js";

import { ChartContext } from "skCommon/chart/chartContext";

/**
 * Chart context plugin which adds support for `group` property in Dataset
 * objects. Only datasets from single group (+ ungrouped datasets) can be
 * visible at once.
 */
export function seriesGroups<T extends Type<ChartContext>>(
    Base: T,
): T & Type<SeriesGroups> {
    class SeriesGroupsMixin extends Base implements SeriesGroups {

        @observable.ref
        public currentGroup?: string;

        private _allDatasets: ChartDataset[] = [];

        constructor(...args: any[]) {
            super(...args);

            makeObservable(this);

            this._allDatasets = this.config.data.datasets.slice();

            const visibleGroup = this.config.options.plugins?.seriesGroups?.visibleGroup;

            if (visibleGroup) {
                this.setGroup(visibleGroup);
            }
        }

        public showGroup(groupId: string): void {
            this.setGroup(groupId);
            this.update();
        }

        private setGroup(groupId: string): void {
            this.currentGroup = groupId;
            const datasets = this._allDatasets
                .filter(d => !groupId || !d.group || d.group === groupId);

            this.updateDatasets(datasets);
        }
    }

    return SeriesGroupsMixin;
}

export interface SeriesGroups {
    currentGroup?: string;

    showGroup(groupId: string);
}

declare module "chart.js" {
    interface ChartDatasetProperties<TType extends ChartType, TData extends unknown[]> {
        group?: string;
    }

    interface PluginOptionsByType {
        seriesGroups?: {
            visibleGroup: string;
        };
    }
}
