import { Injectable, Inject, Renderer2, InjectionToken, StaticProvider } from "@angular/core";
import { OverlayContainer, Overlay } from "@angular/cdk/overlay";
import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";

const OVERLAY_CONTAINER_GETTER_TOKEN = new InjectionToken(
    "Function providing the custom overlay container element",
);

export function customOverlayContainer(
    getter: OverlayContainerGetter,
): StaticProvider[] {
    return [
        {
            provide: MatDialog,
            useClass: MatDialog,
        },
        {
            provide: Overlay,
            useClass: Overlay,
        },
        {
            provide: OverlayContainer,
            useClass: CustomOverlayContainer,
        },
        {
            provide: OVERLAY_CONTAINER_GETTER_TOKEN,
            useValue: getter,
        },
    ];
}

/**
 * Service which allows providing different containers for material overlays
 * and dialog depending on location in the component logical tree.
 *
 * Usage:
 * ```
 * providers: [
 *     ...customOverlayContainer(overlayContainerGetter)
 * ]
 * ```
 *
 * Inspired by:
 * https://github.com/angular/components/issues/7349#issuecomment-337513040
 */
@Injectable()
export class CustomOverlayContainer extends OverlayContainer {

    constructor(
        @Inject(DOCUMENT) document: any,
        platform: Platform,
        @Inject(OVERLAY_CONTAINER_GETTER_TOKEN)
        private overlayContainerGetter: OverlayContainerGetter,
        private renderer2: Renderer2,
    ) {
        super(document, platform);
    }

    protected _createContainer(): void {
        if (!this._containerElement) {
            const newEl = this.overlayContainerGetter();

            if (newEl) {
                this.renderer2.addClass(newEl, "cdk-overlay-container");
                this._containerElement = newEl;
            }
        }
    }
}

type OverlayContainerGetter = () => HTMLElement | void;
