import { Injectable } from "@angular/core";

import { MutationPipe } from "skCommon/insights/dashboard";

import { DashboardSource } from "skInsights/framework/abstract/dashboardSource";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { OperationPipe, PipeResult } from "skInsights/framework/pipe";
import { DashboardData } from "skInsights/framework/dashboardData";

export const MUTATION_TYPE = "$$mutation-source";

@Injectable({ providedIn: "root" })
export class MutationSource implements DashboardSource<MutationPipe> {

    // Just to make the interface happy, it is not in fact used anywhere
    public type = MUTATION_TYPE;

    constructor(
        private moduleRegistryService: ModuleRegistryService,
    ) { }

    public async create(
        mutation: MutationPipe,
        dashboardData: DashboardData,
    ): Promise<PipeResult> {
        const inputData = await dashboardData.resolve(mutation.input);
        const pipeImpl = typeof mutation.pipe === "string"
            ? dashboardData.getNamedPipe(mutation.pipe)
            : new OperationPipe(mutation.pipe, this.moduleRegistryService);

        // Fixme #3435
        // Ugly hack! Mutations cannot access data ref service since it doesn't
        // exist yet when they are created. We want to remove mutation anyway
        // so hopefully we'll get rid of this soon.
        return pipeImpl.execute(inputData, [], {} as any);
    }

    public canAccess(): boolean {
        return true;
    }
}
