import { Injectable } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
// @ts-ignore
import sanitizeHtml from "sanitize-html";

import { HTML_CONFIG } from "skInsights/partials/richText/html";

@Injectable({ providedIn: "root" })
export class RichTextService {

    constructor(
        private domSanitizer: DomSanitizer,
    ) { }

    public sanitize(html: string): SafeHtml {
        const goodHtml = sanitizeHtml(html, {
            ...HTML_CONFIG,
            transformTags: {
                b: "strong",
            },
        });

        return this.domSanitizer.bypassSecurityTrustHtml(goodHtml);
    }
}
