// tslint:disable:max-line-length
export const CONFIG = {
    DEVICE: "Front-end",
    SCOPE: {
        DEFAULT: "openid email profile update:current_user_metadata read:current_user",
        PERMANENT: "openid email profile update:current_user_metadata read:current_user offline_access",
    },
    CONN: {
        DB: "Username-Password-Authentication",
        FACEBOOK: "facebook",
        LINKEDIN: "linkedin",
        GOOGLE: "google-oauth2",
        EMAIL: "email",
    },
    DONT_LOG_PROPS: {
        password: "***",
        refresh_token: "***",
        link_with: "***",
    },
    GRANT_TYPE: {
        REFRESH: "urn:ietf:params:oauth:grant-type:jwt-bearer",
        PASSWORD: "password",
    },
    RESPONSE_TYPE: {
        CODE: "code",
        TOKEN: "token",
    },
};
