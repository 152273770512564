import { SkError } from "skCommon/core/error";
import { getDefaultConfig } from "skCommon/api/config";
import { ApiObject } from "skCommon/api/apiConfig";
import { round } from "skCommon/utils/data";

/**
 * Class representing api urls
 *
 * @export
 * @class ConfigUrlUtil
 */
export class ConfigUrlUtil {
    /**
     * Get base URL of the application
     *
     * @param {string} [path] optional path to be appended to base
     */
    public base(path?: string) {
        let base = document.baseURI;

        // IE fix
        if (!base) {
            base = location.protocol
                + "//"
                + location.hostname
                + "/";
        }

        base = base.split("#")[0].split("?")[0];
        base = base.substr(0, base.lastIndexOf("/") + 1);

        if (path) {
            base = this.concat(base, path);
        }

        return base;
    }

    public concat(...params: string[]) {
        let result: string = null;

        result = params.join("/")
            .replace(new RegExp("/+", "g"), "/")
            .replace(":/", "://");

        return result;
    }

    /**
     * Get URL for api or api/method
     *
     * @param {string} api
     * @param {string} [method]
     * @returns {Promise<string>}
    */
    public get(
        api: ApiObject,
        method?: string,
        params?: {},
        suffix?: string,
    ) {
        let url: string;

        if (method) {
            url = this.concat(
                this.getApiUrl(api),
                api.methods?.[method] || method,
            );
        } else {
            url = this.getApiUrl(api);
        }

        if (suffix) {
            url = this.concat(url, suffix);
        }

        if (params) {
            url = this.appendParams(url, params);
        }

        url = this.removeEmptyParams(url);

        return url;
    }

    public removeEmptyParams(url: string) {
        return url.replace(/\/\{[^\}]+\}(\/?)/g, "$1");
    }

    public appendParams(url: string, params: {}) {
        if (url.indexOf("?") === -1) {
            // TODO: check the URL to decide whether ? or & should be used
            url += "?";
        }

        for (const key of Object.keys(params)) {
            let value = params[key];

            if (value === undefined) {
                continue;
            }

            if (typeof value === "number" && Math.abs(value) < 1e-5) {
                value = round(value, 5);
            }

            if (typeof value !== "string") {
                value = JSON.stringify(value);
            }

            // check whether a placeholder for the parameter exists
            // if it does replace it with value and continue
            if (url.indexOf(`{${key}}`) !== -1) {
                url = url.replace(`{${key}}`, encodeURIComponent(value));
                continue;
            }

            url += encodeURIComponent(key) + "=" + encodeURIComponent(value)
                + "&";
        }

        if (url.substr(-1) === "&" || url.substr(-1) === "?") {
            url = url.slice(0, -1);
        }

        return url;
    }



    private getApiUrl(api: ApiObject) {
        return getDefaultConfig().getByHost(api.urls);
    }
}

export const urlUtil = new ConfigUrlUtil();

///
///
/// Errors
///
///

export class ConfigUrlError extends SkError {

    public static readonly ERR_NO_API_METHOD = "ERR_NO_API_METHOD";

    public static readonly MESSAGES = new Map(<[string, string][]>[
        [
            ConfigUrlError.ERR_NO_API_METHOD,
            "Failed to get method for api",
        ],
    ]);

    get dataToLog() {
        return {
            api: this.api,
            method: this.method,
        };
    }

    constructor(code: string, public api: ApiObject, public method: string) {
        super("ConfigUrlError", code);
    }
}
