import { DataProvider } from "skInsights/framework/data/dataProvider";
import { DashboardSeries, DashboardCollection, SeriesLike, AnyDashboardData } from "skInsights/framework/data/structures";
import { collectionSelect } from "skInsights/framework/query/collectionSelect";

/**
 * Simple data provider which stores collection of series and allows selecting
 * series using wildcards. Generic T allows adding support for additional data
 * types to be returned by the select method.
 */
export class SeriesSetProvider<T extends AnyDashboardData | void = void> implements DataProvider {

    constructor(
        protected seriesSet: DashboardCollection<DashboardSeries>,
    ) { }

    public async select(q: string): Promise<SeriesLike | T | void> {
        if (!this.seriesSet) {
            throw new Error("Product source is not loaded.");
        }

        return collectionSelect(this.seriesSet, q) as T;
    }
}
