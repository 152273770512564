import {
    Injectable,
} from "@angular/core";
import {
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import {
    Observable,
} from "rxjs";

import {
    DateRangePickerDialogComponent,
    DateRangePickerDialogData,
    DateRangePickerPayload,
    DEFAULT_DATE_RANGE_PICKER_DIALOG_DATA,
} from "skCommon/dateRangePicker";

@Injectable()
export class DateRangePickerService {

    constructor(
        private readonly dialog: MatDialog,
    ) { }

    public dateRange$(
        from?: Date,
        to?: Date,
        options?: DateRangePickerDialogData,
    ): Observable<DateRangePickerPayload> {
        let data: DateRangePickerDialogData = options
            ? options : DEFAULT_DATE_RANGE_PICKER_DIALOG_DATA;

        if (from) {
            data = {
                ...data,
                startDateOptions: {
                    ...data.startDateOptions,
                    initialValue: from,
                },
            };
        }

        if (to) {
            data = {
                ...data,
                endDateOptions: {
                    ...data.endDateOptions,
                    initialValue: to,
                },
            };
        }

        const dialogRef: MatDialogRef<DateRangePickerDialogComponent> = this.dialog
            .open(DateRangePickerDialogComponent, {
                backdropClass: "sk-transparent-backdrop-overlay",
                data,
                autoFocus: false,
                restoreFocus: false,
            });

        return dialogRef.afterClosed();
    }
}
