import { Injectable } from "@angular/core";

import { PageExport } from "skCommon/exports/pageExport/pageExport";

import { ComponentStaticRenderer } from "skInsights/framework/dashboardPdf/componentStaticRenderer";
import { HtmlComponentDef } from "skInsights/modules/core/components/html/html.component";
import { CleanDataExportService } from "skInsights/partials/blockEditor/cleanDataExport.service";

@Injectable()
export class HtmlStaticRendererService implements ComponentStaticRenderer<HtmlComponentDef> {

    constructor(
        private cleanDataExportService: CleanDataExportService,
    ) { }

    public renderIntoPage(def: HtmlComponentDef, page: PageExport): void {

        if (typeof def.content === "string") {
            // Not supported
        } else {
            this.cleanDataExportService.writeToPage(def.content, page);
        }
    }
}
