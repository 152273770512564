import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { AutorunModule } from "skCommon/angular/state/autorun.module";
import { SharedModule as SkCommonModule } from "skCommon/angular/shared.module";

const sharedImports = [
    CommonModule,
    FormsModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    RouterModule,
    AutorunModule,
    SkCommonModule,
];

@NgModule({
    imports: sharedImports,
    exports: sharedImports,
})
export class SharedModule { }
