import { Injectable } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { ToolSettings } from "@editorjs/editorjs";
import ImageTool from "@editorjs/image";

import { generateUniqueId } from "skCommon/utils/uniqueId";

import { EditorJsPlugin } from "skInsights/partials/blockEditor/editorJs";

@Injectable({ providedIn: "root" })
export class ImageBlockPluginService implements EditorJsPlugin {

    constructor(
        private angularFireStorage: AngularFireStorage,
    ) { }

    public getTools(): { image: ToolSettings } {
        return {
            image: {
                class: ImageTool,
                inlineToolbar: true,
                config: {
                    uploader: {
                        uploadByFile: (file: File) => Promise.resolve(this.uploadByFile(file)),
                        uploadByUrl: (url: string) => Promise.resolve(this.uploadByUrl(url)),
                    },
                },
            },
        };
    }

    private async uploadByFile(file: File): Promise<UploadedImage> {
        const filename = generateUniqueId();
        const fileRef = this.angularFireStorage.ref(`documents-assets/${filename}`);
        await this.angularFireStorage.ref(`documents-assets/${filename}`)
            .put(file);

        const url = await fileRef.getDownloadURL().toPromise();

        return {
            success : 1,
            file: { url },
        };
    }

    private async uploadByUrl(foreignUrl: string): Promise<UploadedImage> {
        const buffer = await (await fetch(foreignUrl)).arrayBuffer();
        const filename = generateUniqueId();
        const fileRef = this.angularFireStorage.ref(`documents-assets/${filename}`);
        await this.angularFireStorage.ref(`documents-assets/${filename}`).put(buffer);

        const url = await fileRef.getDownloadURL().toPromise();

        return {
            success : 1,
            file: { url },
        };
    }
}


interface UploadedImage {
    success: 1;
    file: {
        url: string;
    };
}
