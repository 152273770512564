import { Client } from "skCommon/api/client/simple";
import { CONTENT_TYPES } from "skCommon/core/http";
import { SPACEKNOW_OAUTH } from "skCommon/auth/authenticator";

class CreditsApi extends Client {
    public readonly api = "credits-api";
    public readonly authType = SPACEKNOW_OAUTH;
    public contentType = CONTENT_TYPES.JSON;

    public async allocateGeojson(
        geojson: GeoJSON.GeoJsonObject,
        sceneIds: string[],
    ): Promise<{}> {
        return this.call<{}>({
            endpoint: "allocate-geojson",
            body: {
                geojson,
                sceneIds,
            },
        }).promise;
    }

    public async checkGeojson(
        geojson: GeoJSON.GeoJsonObject,
        sceneIds: string[],
    ): Promise<CheckAreaResponse> {
        return this.call<CheckAreaResponse>({
            endpoint: "check-geojson",
            body: {
                geojson,
                sceneIds,
            },
        }).promise;
    }

    public async addUser(
        groupId: string,
        userId: string,
    ): Promise<{}> {
        return this.call<{}>({
            endpoint: "bind-user",
            body: {
                userId: userId,
                groupId: groupId,
            },
        }).promise;
    }

    public async removeUser(
        userId: string,
    ): Promise<{}> {
        return this.call<{}>({
            endpoint: "unbind-user",
            body: {
                userId: userId,
            },
        }).promise;
    }

    public async searchGroup(
        name: string,
    ): Promise<SearchGroupResponse> {
        return this.call<SearchGroupResponse>({
            endpoint: "search-group",
            body: {
                groupName: name,
            },
        }).promise;
    }

    public async createGroup(
        name: string,
        credit: number,
    ): Promise<CreateGroupResponse> {
        return this.call<CreateGroupResponse>({
            endpoint: "create-group",
            body: {
                name: name,
                credit: credit,
            },
        }).promise;
    }

    public async changeGroupCredit(
        groupId: string,
        creditDelta: number,
    ): Promise<Credit> {
        return this.call<Credit>({
            endpoint: "change-group-credit",
            body: {
                groupId: groupId,
                creditDelta: creditDelta,
            },
        }).promise;
    }

    public async getGroup(
        groupId: string,
    ): Promise<RawGroup> {
        return this.call<RawGroup>({
            endpoint: "get-group",
            body: {
                groupId: groupId,
            },
        }).promise;
    }

    public async getRemainingCredits(): Promise<GetRemCreditsResponse> {
        return this.call<GetRemCreditsResponse>({
            endpoint: "get-remaining-credits",
        }).promise;
    }

    public async createPaymentToken(): Promise<CreatePaymentTokenResponse> {
        return this.call<CreatePaymentTokenResponse>({
            endpoint: "create-payment-token",
        }).promise;
    }

    public async buyCredit(
        paymentMethodNonce: string,
        amountToSpend: number,
    ): Promise<BuyCreditResponse> {
        return this.call<BuyCreditResponse>({
            endpoint: "buy-credit",
            body: {
                paymentMethodNonce,
                amountToSpend,
            },
        }).promise;
    }
}

let client: CreditsApi;

export function getCreditsClient(): CreditsApi {
    if (!client) {
        client = new CreditsApi();
    }

    return client;
}

export function setCreditsClient(newClient: CreditsApi): void {
    client = newClient;
}

///
///
/// Interfaces
///
///

export interface CheckAreaResponse {
    km2: number;
    cost: number;
}

interface SearchGroupResponse {
    groups: Array<RawGroup>;
}

interface CreateGroupResponse {
    groupId: string;
}

export interface RawGroup {
    groupId: string;
    name: string;
    credit: number;
    usedCredit: number;
    boundUserIds?: string[];
}

interface GetRemCreditsResponse {
    remainingCredit: number;
}

interface CreatePaymentTokenResponse {
    clientToken: string;
}

export interface BuyCreditResponse {
    purchasedCredit: number;
}

export interface Credit {
    credit: number;
}
