import { Injectable } from "@angular/core";

import { getDatacubeClient, CatalogueItem } from "skCommon/datacube/client";

@Injectable({ providedIn: "root" })
export class CatalogService {

    public availableItems?: Promise<CatalogueItem[]>;

    public getCatalog(): Promise<CatalogueItem[]> {
        if (!this.availableItems) {
            this.availableItems = getDatacubeClient().getCatalogue();
        }

        return this.availableItems;
    }

    public hasCatalogItemForQuery(): boolean {
        return true;
    }
}

export interface DatacubeAoi {
    name: string;
    point: GeoJSON.Point;
}
