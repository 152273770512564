import { exists } from "skCommon/utils/types";
import { generateUniqueId } from "skCommon/utils/uniqueId";

import {
    DashboardSeries,
    DashboardCollection,
    SeriesDataPoint,
    DashboardDataType,
    AnyDashboardData,
    DashboardString,
    DashboardSingleValue,
    DashboardMetadata,
    DashboardDataTypeMap,
    SeriesLike,
    SingleValueLike,
    Collectables,
    CollectableData,
    DashboardSeriesPair,
} from "skInsights/framework/data/structures";

/**
 * Run given function for each series in given dashboard data object and return
 * dashboard data of the dimension.
 */
export function transformSeries(
    data: SeriesLike,
    fn: TransformFn,
): SeriesLike | SingleValueLike {
    if (isCollection(data, DashboardDataType.Series)) {
        const setEntries = Object.entries(data.set);
        const transformedEntries = setEntries.map(([k, sub]) => {
            const merged = mergeTransformResult(sub, fn(sub));

            return merged
                ? [k, merged] as [string, DashboardSeries] | [string, DashboardSingleValue]
                : void 0;
        });
        const existingEntries = transformedEntries.filter(exists);

        if (!existingEntries.length) {
            throw new Error("Transformed into empty collection");
        }

        return {
            type: DashboardDataType.Collection,
            set: Object.fromEntries(existingEntries),
        };
    } else if (isSeries(data)) {
        const merged = mergeTransformResult(data, fn(data));

        if (!merged) {
            throw new Error("Transformed into no value");
        }

        return merged;
    } else {
        throw new Error(`Data type ${formatDataType(data)} cannot be transformed as series`);
    }
}

function mergeTransformResult(
    origData: DashboardSeries | DashboardSingleValue,
    result: TransformResult,
): DashboardSeries | DashboardSingleValue | null {
    const mergedMeta = {
        ...origData.meta,
        ...(result.meta || {}),
    };

    if (typeof result.value === "number") {
        return {
            type: DashboardDataType.SingleValue,
            value: result.value,
            meta: mergedMeta,
        };
    } else if (result.series) {
        if (!result.series.length) {
            return null;
        }

        return {
            type: DashboardDataType.Series,
            series: result.series,
            meta: mergedMeta,
        };
    } else {
        return {
            ...origData,
            meta: mergedMeta,
        };
    }
}

export function formatDataType(d: AnyDashboardData): string {
    if (d.type === DashboardDataType.Collection) {
        const children = Object.values(d.set);
        const prefix = children.length === 0 ? "Empty " : "";
        const suffix = children.length ? `<${formatDataType(children[0])}>` : "";
        return `${prefix}Collection${suffix}`;
    } else {
        return d.type[0].toUpperCase() + d.type.slice(1);
    }
}

export function assertType<T extends DashboardDataType>(
    data: AnyDashboardData,
    type: T|T[],
): asserts data is DashboardDataTypeMap[T] {
    if (!isType(data, type)) {
        throw new Error(`Type ${type} expected but got ${data.type}`);
    }
}

export function isType<T extends DashboardDataType>(
    data: AnyDashboardData,
    type: T|T[],
): data is DashboardDataTypeMap[T] {
    const types = type instanceof Array ? type : [type];

    if (types.includes(data.type as T)) {
        return true;
    } else {
        return false;
    }
}

export function assertSeries(d: AnyDashboardData): asserts d is DashboardSeries {
    if (!isSeries(d)) {
        throw new Error(`Type ${formatDataType(d)} is not single Series`);
    }
}

export function assertSeriesLike(d: AnyDashboardData): asserts d is SeriesLike {
    if (!isSeriesLike(d)) {
        throw new Error(`Type ${formatDataType(d)} is not Series-like`);
    }
}

export function assertSingleValuesLike(d: AnyDashboardData): asserts d is SingleValueLike {
    if (!isSingleValueLike(d)) {
        throw new Error(`Type ${formatDataType(d)} is not SingleValue-like`);
    }
}

export function assertSingleValue(d: AnyDashboardData): asserts d is DashboardSingleValue {
    if (!isSingleValue(d)) {
        throw new Error(`Type ${formatDataType(d)} is not SingleValue`);
    }
}

export function assertSingleValueCollection(
    d: AnyDashboardData,
): asserts d is DashboardCollection<DashboardSingleValue> {
    if (!isCollection(d, DashboardDataType.SingleValue)) {
        throw new Error(`Type ${formatDataType(d)} is not SingleValue Collection`);
    }
}

export function isSeriesLike(d: AnyDashboardData): d is SeriesLike {
    return isSeries(d)
        || isSeriesPair(d)
        || isCollection(d, DashboardDataType.Series);
}

export function isSingleValueLike(d: AnyDashboardData): d is SingleValueLike {
    return isSingleValue(d) || isCollection(d, DashboardDataType.SingleValue);
}

export function isSeries(d: AnyDashboardData): d is DashboardSeries {
    return d.type === DashboardDataType.Series;
}

export function isSeriesPair(d: AnyDashboardData): d is DashboardSeriesPair {
    return d.type === DashboardDataType.SeriesPair;
}

export function isCollection<T extends Collectables = Collectables>(
    d: AnyDashboardData,
    childType?: T,
): d is DashboardCollection<DashboardDataTypeMap[T]> {
    return d.type === DashboardDataType.Collection
        && Object.values(d.set).length > 0
        && (
            !childType
            || Object.values(d.set)[0].type === childType
        );
}

export function isString(d: AnyDashboardData): d is DashboardString {
    return d.type === DashboardDataType.String;
}

export function isSingleValue(d: AnyDashboardData): d is DashboardSingleValue {
    return d.type === DashboardDataType.SingleValue;
}

/**
 * Try to convert given dashboard data into DashboardString. Throw if
 * unsupported dashboard data type in provided.
 */
export function toDashboardString(d: AnyDashboardData | string): DashboardString {
    let outString: string;

    if (typeof d === "string") {
        outString = d;
    } else if (isString(d)) {
        outString = d.text;
    } else if (isSingleValueLike(d)) {
        const singleValue = deconstructCollection(d);

        outString = singleValue.value.toString();
    } else {
        // tslint:disable-next-line: max-line-length
        throw new Error(`Dashboard data of type ${formatDataType(d)} cannot be converted to string`);
    }

    return {
        text: outString,
        type: DashboardDataType.String,
    };
}

export function toDashboardSingleValue(
    d: AnyDashboardData | number,
    addMeta: Partial<DashboardMetadata> = {},
): DashboardSingleValue {
    let outNumber: number;
    let meta: DashboardMetadata = {
        uid: generateUniqueId(),
        name: "Converted value",
    };

    if (typeof d === "number") {
        outNumber = d;
    } else if (isSingleValueLike(d)) {
        const sv = deconstructCollection(d);
        meta = sv.meta;
        outNumber = sv.value;
    } else if (isString(d)) {
        outNumber = parseFloat(d.text);

        if (Number.isNaN(outNumber)) {
            throw new Error(`Cannot convert string "${d.text}" to single value`);
        }
    } else {
        throw new Error(
            `Dashboard data of type ${formatDataType(d)} cannot be converted to single value`,
        );
    }

    return {
        type: DashboardDataType.SingleValue,
        value: outNumber,
        meta: { ...meta, ...addMeta },
    };
}

/**
 * Convert series-pair into list of series with matching metadata.
 */
export function flattenSeriesPair(series: DashboardSeriesPair): DashboardSeries[] {
    return series.pair.map(datapoints => ({
        series: datapoints,
        meta: { ...series.meta },
        type: DashboardDataType.Series,
    }));
}

/**
 * If given input is collection, return its only child, otherwise return the
 * input data. On many occasions we want to handle e.g. sereis or collection of
 * 1 series the same way, this function helps removing that difference.
 */
export function deconstructCollection<T extends CollectableData>(
    input: DashboardCollection<T> | T,
): T {
    if (isCollection(input)) {
        const items = Object.values(input.set);

        if (items.length === 1) {
            return items[0];
        } else {
            throw new Error("Collection contains more than one item");
        }
    } else {
        return input;
    }
}

type TransformFn = (series: DashboardSeries) => TransformResult;

interface TransformResult {
    series?: SeriesDataPoint[];
    value?: number;
    meta?: Partial<DashboardMetadata>;
}
