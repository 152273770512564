import { Injectable, NgZone } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { OutputData } from "@editorjs/editorjs";

import { BlockEditorDialogComponent, BlockEditorDialogData, BlockEditorDialogOutput, BlockEditorResolve } from "skInsights/partials/blockEditor/blockEditorDialog.component";

@Injectable({ providedIn: "root" })
export class BlockEditorDialogService {

    constructor(
        private matDialog: MatDialog,
        private ngZone: NgZone,
    ) { }

    public open(data: OutputData): Promise<BlockEditorDialogOutput> {
        let resolve!: BlockEditorResolve;

        // Use observabll?????????
        const promise = new Promise<BlockEditorDialogOutput>(r => resolve = r);

        this.ngZone.run(() => {
            this.matDialog.open<BlockEditorDialogComponent, BlockEditorDialogData>(
                BlockEditorDialogComponent,
                {
                    height: "80vh",
                    minWidth: 300,
                    width: "60vw",
                    data: {
                        resolve,
                        data,
                    },
                    disableClose: true,
                },
            );
        });

        return promise;
    }
}
