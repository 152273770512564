import { Component, ChangeDetectionStrategy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SelectionModel } from "@angular/cdk/collections";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { makeObservable } from "mobx";

import { RouterStore } from "skCommon/angular/router.store";
import { Doc } from "skCommon/firebase/doc";
import {
    createDashboardAccess,
    DashboardAccess,
    InsightsCustomer,
} from "skCommon/insights/customers";
import { Logger } from "skCommon/utils/logger";
import { computed, observableRef } from "skCommon/state/mobxUtils";

import { Admin, AdminRoutes } from "skInsights/admin/admin.routing";
import {
    ManageSubscriptionDialog, ManageSubscriptionDialogData,
} from "skInsights/admin/manageSubscriptionsDialog/manageSubscriptionDialog";
import { CustomerManagementService } from "skInsights/admin/customerManagement.service";
import { InsightsService } from "skInsights/insights.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { UserService } from "skInsights/user/user.service";
import { AddCustomerDialogComponent } from "skInsights/admin/addCustomer/addCustomerDialog.component";

const LOADING = Symbol();
const SAVING = Symbol();

interface TableCustomer {
    company: string;
    email: string;
    name: string;
    status: boolean;
    userId: string;
    id: string;
}


@Component({
    selector: "sk-customer-management",
    templateUrl: "./customerManagement.pug",
    styleUrls: ["./admin.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerManagementComponent {

    public readonly displayedColumns: string[] = ["select", "company", "email", "name", "status"];

    public clickedRows = new Set<TableCustomer>();

    @observableRef
    public tableCustomers: TableCustomer[] = [];

    @observableRef
    public userCount = 0;

    @observableRef
    public customTrialDate?: Date;

    public hoverRowIndex = -1;

    @computed
    public get showAddCustomer(): boolean {
        return this.userService.permissions.has("admin.users.manage");
    }

    public selection = new SelectionModel<TableCustomer>(true, []);
    private selectedCustomers: TableCustomer[] = [];

    constructor(
        private userService: UserService,
        private insightsService: InsightsService,
        private customerManagementService: CustomerManagementService,
        private snackBarService: SnackBarService,
        private logger: Logger,
        private snackBar: MatSnackBar,
        private routerStore: RouterStore,
        private dialog: MatDialog,
    ) {
        makeObservable(this);
        this.startLoadingCustomers$().subscribe((customers) => {
            if (customers) {
                this.tableCustomers = customers.map(customer => ({
                    company: customer.company,
                    email: customer.email,
                    name: customer.name,
                    status: this.isActive(customer.dashboards),
                    userId: customer.userId,
                    id: customer.id,
                }) as TableCustomer);
                this.userCount = this.tableCustomers.length;
            }
        });
        this.selection.changed.subscribe((data) => {
            this.openSnackBar(data.source.selected.length);
            this.selectedCustomers = data.source.selected;
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.tableCustomers.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    public masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }
        this.selection.select(...this.tableCustomers);
    }

    /** The label for the checkbox on the passed row */
    public checkboxLabel(index: number, row?: TableCustomer): string {
        if (!row) {
            return `${this.isAllSelected() ? "deselect" : "select"} all`;
        }
        return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${index + 1}`;
    }


    private openSnackBar(customerCount: number) {
        const snackbarRef = this.snackBar.open(
            `${customerCount} selected`,
            "manage subscription",
            {
                panelClass: "sk-white-snackbar",
                duration: 1000000,
            },
        );

        snackbarRef.onAction().subscribe(() => {
            this.openSubscriptionManagement();
        });
    }

    public openSubscriptionManagement() {
        this.dialog.open(
            ManageSubscriptionDialog,
            {
                width: "640px",
                height: "auto",
                data: {
                    emails: this.selectedCustomers.map(customer => customer.email),
                    activeDashboards: [],
                    customerIds: this.selectedCustomers.map(customer => customer.id),
                    edit: false,
                } as ManageSubscriptionDialogData,
            },
        );
    }

    private isActive(dashboards:  Record<string, DashboardAccess>): boolean {
        return Object.entries(dashboards)
            .map(([, access]) => createDashboardAccess(access))
            .some((dashboardAccess) => dashboardAccess.hasAccess());
    }

    public async addCustomer(newCustomer: InsightsCustomer): Promise<void> {
        try {
            this.insightsService.globalLoadingProcesses.add(SAVING);
            await this.customerManagementService.addCustomer(newCustomer);
        } catch (e) {
            this.logger.error(e, "Adding customer");
            this.snackBarService.notify(`Could not add customer: ${e.message}`);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(SAVING);
        }
    }


    private startLoadingCustomers$(): Observable<Doc<InsightsCustomer>[]> {
        this.insightsService.globalLoadingProcesses.add(LOADING);

        return this.customerManagementService.getCustomers$().pipe(
            tap(() => this.insightsService.globalLoadingProcesses.delete(LOADING)),
        );
    }

    public mouseOver(rowIndex: number): void {
        this.hoverRowIndex = rowIndex;
    }

    public async goToCustomerPage(customerRow: TableCustomer) {
        await this.routerStore.navigate([Admin, AdminRoutes.CustomerManagement, customerRow.id]);
    }

    public openAddCustomerDialog(): void {
        this.dialog.open(
            AddCustomerDialogComponent,
            {
                width: "300px",
                height: "auto",
            })
            .afterClosed()
            .subscribe((data: InsightsCustomer) => {
                if (data) {
                    this.addCustomer({...data, dashboards: {}});
                }
            });
    }
}
