import { MatDialog, MatDialogConfig, MatDialogRef, DialogPosition } from "@angular/material/dialog";
import { Injectable, TemplateRef, Injector, NgZone } from "@angular/core";
import { ComponentType } from "@angular/cdk/portal";
import { OverlayContainer } from "@angular/cdk/overlay";

import { generateUniqueId } from "skCommon/utils/uniqueId";
import { customOverlayContainer } from "skCommon/angular/material/customOverlayContainer.service";
import { ComponentDef } from "skCommon/insights/dashboard";

import { PopupWrapperData, PopupWrapperComponent } from "skInsights/partials/popup/popupWrapper.component";
import { PANEL_CLASS } from "skInsights/partials/popup/popupDom";
import { POPUP_OVERLAY_CONTAINER_TOKEN } from "skInsights/partials/popup/config";
import { DialogWrapperComponent } from "skInsights/partials/popup/dialogWrapper.component";

/**
 * Material Dialog wrapper which z-index positionable, draggable popups and
 * automatically renders Layout Component if ComponentDef provided.
 *
 * Caller component's injector needs to be passed as well so the created
 * component uses correct parent injecotr (can access dashboard data etc...)
 */
@Injectable({ providedIn: "root" })
export class PopupService {

    constructor(
        private ngZone: NgZone,
        private matDialog: MatDialog,
    ) { }

    public open(
        injector: Injector,
        refOrDef: TemplateRef<any> | ComponentType<any> | ComponentDef,
        config: PopupConfig = {},
    ): MatDialogRef<PopupWrapperComponent, PopupWrapperData> {
        const matDialog = this.injectMatDialog(injector);
        const id = config.id || generateUniqueId();

        let dialog: MatDialogRef<PopupWrapperComponent> | void = matDialog.getDialogById(id);

        if (!dialog) {
            const panelClass = typeof config.panelClass === "string"
                ? [config.panelClass]
                : config.panelClass instanceof Array ? [...config.panelClass] : [];

            panelClass.push(PANEL_CLASS);
            panelClass.push(id);

            const newConfig: MatDialogConfig<PopupWrapperData> = {
                ...config,
                panelClass,
                id,
                hasBackdrop: false,
                autoFocus: false,
                data: {
                    data: config.data,
                    refOrDef,
                    injector,
                    id,
                },
                // As we allow setting different overlay containers which may
                // not cover whole viewport we need to subtract the container's
                // position so the position property stays overlay container
                // agnostic
                position: config.position
                    ? this.makeDialogPosition(injector, config.position)
                    : void 0,
            };


            dialog = this.ngZone.run(() => matDialog.open(PopupWrapperComponent, newConfig));
        } else {
            dialog.componentInstance.bringForward();
        }

        return dialog!;
    }

    public openDialog<T extends TemplateRef<any> | ComponentType<any> | ComponentDef>(
        injector: Injector,
        refOrDef: T,
        config: MatDialogConfig<void> = {},
    ): MatDialogRef<DialogWrapperComponent, Omit<PopupWrapperData, "id">> {
        const newConfig: MatDialogConfig<Omit<PopupWrapperData, "id">> = {
            ...config,
            panelClass: "dashboard-dialog-panel",
            data: {
                data: config.data,
                refOrDef,
                injector,
            },
            maxHeight: "80vh",
        };

        return this.ngZone.run(() => this.matDialog.open(DialogWrapperComponent, newConfig));
    }

    /**
     * Subtract overlay container position and convert to pixels
     */
    private makeDialogPosition(injector: Injector, pos: PopupPosition): DialogPosition {
        const overlayContainer = injector.get(OverlayContainer);
        const container = overlayContainer.getContainerElement();
        const conBox = container.getBoundingClientRect();

        const dialogPosEntries = Object.entries(pos)
            .filter(isPosition)
            .map(([k, v]) => [k, (v - conBox[k]) + "px"]);


        return Object.fromEntries(dialogPosEntries);

        function isPosition(ent: [any, any]): ent is [keyof PopupPosition, number] {
            return typeof ent[1] === "number";
        }
    }

    /**
     * Check whether extra popup configuration was provided via injection and
     * if so then apply it to a new injector before injecting the MatDialog
     * service.
     */
    private injectMatDialog(injector: Injector): MatDialog {
        const customOverlayGetter = injector.get(POPUP_OVERLAY_CONTAINER_TOKEN, null);

        if (customOverlayGetter) {
            injector = Injector.create({
                parent: injector,
                providers: customOverlayContainer(customOverlayGetter),
            });
        }

        return injector.get(MatDialog);
    }
}

interface PopupConfig extends Omit<MatDialogConfig, "position"> {
    position?: PopupPosition;
}

export interface PopupPosition {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
}
