import Header from "@editorjs/header";
import List from "@editorjs/list";
import Link from "@editorjs/link";
import Delimiter from "@editorjs/delimiter";
import Hyperlink from "editorjs-hyperlink";
import Raw from "@editorjs/raw";
import Table from "@editorjs/table";

export const BASE_TOOLS = {
    header: {
        class: Header,
        inlineToolbar: true,
        shortcut: "CTRL+SHIFT+H",
    },
    list: {
        class: List,
        inlineToolbar: true,
    },
    link: {
        class: Link,
        inlineToolbar: true,
    },
    hyperlink: {
        class: Hyperlink,
    },
    Raw,
    Table,
    delimiter: {
        class: Delimiter,
        inlineToolbar: true,
    },
};
