import intersect from "@turf/intersect";
import { feature, polygon as turfPolygon, featureCollection } from "@turf/helpers";
import turfBbox from "@turf/bbox";
import { toMercator, toWgs84 } from "@turf/projection";

import { Bbox, Point } from "skCommon/utils/geometry";
import { Scene } from "skCommon/ragnar/scene";
import { assert } from "skCommon/utils/assert";
import { exists } from "skCommon/utils/types";

export function projectToWebm(coord: GeoJSON.Position): Point {
    let sinY = Math.sin(coord[1] * Math.PI / 180);

    // Apply the WebMercator limits.
    sinY = Math.min(Math.max(sinY, -0.9999), 0.9999);

    // Project the point using WebMercator
    return {
        x: (0.5 + coord[0] / 360),
        y: (0.5 - Math.log((1 + sinY) / (1 - sinY))
                / (4 * Math.PI)),
    };
}

/**
 * Calculate x and y tile number (google maps numbering => top-left is 0,0)
 * for given zoom level
 */
export function projectToTile(coord: GeoJSON.Position, zoom: number): Tile {
    return webmToTile(projectToWebm(coord), Math.ceil(zoom));
}

/**
 * Calculate x and y tile number (google maps numbering => top-left is 0,0)
 * for given zoom level
 */
export function webmToTile(webmPoint: Point, zoom: number): Tile {
    const WORLD_SIZE = Math.pow(2, zoom);

    return [
        zoom,
        Math.floor(WORLD_SIZE * webmPoint.x),
        Math.floor(WORLD_SIZE * webmPoint.y),
    ];
}

/**
 * Calculate x and y tile number (google maps numbering => top-left is 0,0)
 * for given zoom level
 */
export function tileToWebm(tile: Tile): WebmBounds {
    const WORLD_SIZE = Math.pow(2, tile[0]);

    return {
        minX: tile[1] / WORLD_SIZE,
        maxX: (tile[1] + 1) / WORLD_SIZE,
        minY: tile[2] / WORLD_SIZE,
        maxY: (tile[2] + 1) / WORLD_SIZE,
    };
}

export function getTilesetBounds(tileset: Tile[]): TilesetBounds {
    let minXTile = Infinity,
        maxXTile = -Infinity,
        minYTile = Infinity,
        maxYTile = -Infinity;

    for (const tile of tileset) {
        minXTile = Math.min(minXTile, tile[1]);
        maxXTile = Math.max(maxXTile, tile[1]);

        minYTile = Math.min(minYTile, tile[2]);
        maxYTile = Math.max(maxYTile, tile[2]);
    }

    return {
        topLeft: [tileset[0][0], minXTile, minYTile],
        width: (maxXTile - minXTile) + 1,
        height: (maxYTile - minYTile) + 1,
    };
}

export function getTileBbox(tile: Tile) {
    return new Bbox(
        tileYToLat(tile[2], tile[0]),
        tileXToLon(tile[1] + 1, tile[0]),
        tileYToLat(tile[2] + 1, tile[0]),
        tileXToLon(tile[1] , tile[0]),
    );
}

/**
 * Get longtitude of tile's top left corner.
 */
function tileXToLon(x: number, zoom: number) {
    return x / (2 ** zoom) * 360 - 180;
}

/**
 * Get latitude of tile's top left corner.
 */
function tileYToLat(y: number, zoom: number) {
    const exp = (y - (2 ** (zoom - 1))) / (2 ** zoom / (-2 * Math.PI));

    return (2 * Math.atan(Math.exp(exp)) - Math.PI / 2) / (Math.PI / 180);
}

/**
 * If passed zoomLevel is smaller than tiles' zoomlevel, tiles of given
 * zoomlevel containing passed tiles are returned. Useful when making sure that
 * kraken release request doesn't contain tile with higher zoom level than
 * MAX_ZOOM.
 */
export function zoomOutTiles(tiles: Tile[], zoomLevel: number): Tile[] {
    if (tiles[0][0] - zoomLevel <= 0) {
        return tiles;
    }

    const newTiles: Tile[] = [];

    for (const tile of tiles) {
        let skip = false;

        // Make sure some of the new tiles doesn't already contain this tile
        for (const newTile of newTiles) {
            if (tileContainsTile(newTile, tile)) {
                skip = true;
                break;
            }
        }

        if (skip) {
            continue;
        }

        const worldRatio = Math.pow(2, zoomLevel) / Math.pow(2, tile[0]);

        newTiles.push([
            zoomLevel,
            Math.floor(tile[1] * worldRatio),
            Math.floor(tile[2] * worldRatio),
        ]);
    }

    return newTiles;
}

export function tileContainsTile(tile: Tile, smallTile: Tile): boolean {
    if (smallTile[0] < tile[0]) {
        // Small tile is actually larger
        return false;
    }

    if (smallTile[0] === tile[0]) {
        // Tiles have same zoom
        return smallTile[1] === tile[1] && smallTile[2] === tile[2];
    }

    // TODO: Consider storing this calculated value for all 20 zoom levels.
    // This method gets called pretty often, so it's should be as cheap as
    // possible.
    const tileWorldRatios = 1 / 2 ** (smallTile[0] - tile[0]);

    return Math.floor(smallTile[2] * tileWorldRatios) === tile[2]
        && Math.floor(smallTile[1] * tileWorldRatios) === tile[1];
}

export function tilesOverlap(tile: Tile, tile2: Tile): boolean {
    return tileContainsTile(tile, tile2) || tileContainsTile(tile2, tile);
}

/**
 * Get readable string representation of tile
 */
export function stringifyTile(tile: Tile): string {
    return tile.join("-");
}

/**
 * Calculate fractional zoom level in which the projection of the bbox will
 * have desired approximately desired size.
 * This is approximate value as it doesn't take bbox's shape into account.
 * Result is Mapbox specific as mapbox uses 512px tiles instead of 256px.
 *
 * @param bbox Bounding box or area in m²
 * @param desiredSize
 */
function calculateZoomLevel(bbox: number, desiredSize: number, lat: number): number;
function calculateZoomLevel(bbox: Bbox, desiredSize: number): number;
function calculateZoomLevel(
    bbox: Bbox|number,
    desiredSize: number,
    lat?: number,
): number {
    const TILE_SIZE = 512;
    const MAX_ZOOM = 19;
    const EQUATORIAL_CIRCUMFERENCE = 40000000;

    let dimension: number;

    if (bbox instanceof Bbox) {
        lat = bbox.center[1];
        dimension = bbox.leftRightDistance;
    } else {
        dimension = Math.sqrt(bbox);
    }

    assert(exists(lat), "calculateZoomLevel called with invalid arguments");

    const currentCircumference = EQUATORIAL_CIRCUMFERENCE
        * Math.abs(Math.cos(lat * Math.PI / 180));

    // Form of `worldInMeters / polygonInMeters = worldInPx / polygonInPx`
    const zoomLevel = Math.log2(
        desiredSize * currentCircumference / dimension / TILE_SIZE,
    );

    return Math.min(zoomLevel, MAX_ZOOM);
}
export { calculateZoomLevel };

/**
 * Return true if tile and the provided geojson intersect.
 */
export function tilePolygonIntersection(
    tile: Tile,
    geojson: GeoJSON.Polygon|GeoJSON.MultiPolygon,
) {
    const tileFeature = getTileBbox(tile).toPolygonFeature();
    let features: GeoJSON.Feature<GeoJSON.Polygon>[];

    if (geojson.type === "MultiPolygon") {
        features = geojson.coordinates.map(pol => turfPolygon(pol));
    } else {
        features = [feature(geojson)];
    }

    // Return true if the is at least one intersection
    return features.some(ft => {
        return !!intersect(ft, tileFeature);
    });
}

/**
 * Takes list of tiles and "transforms" them into equivalent tiles on
 * requested higher zoom level. Input tiles should not overlap.
 * E.g. ([[1, 1, 1],[2,4,3]], 2) => [[2,2,2],[2,2,3],[2,3,2],[2,3,3],[2,4,3]]
 */
export function decomposeTile(tiles: Tile[], zoomLevel: number): Tile[] {
    if (!tiles.length) {
        return [];
    }

    const out: Tile[] = [];

    for (const tile of tiles) {
        const inputZoom = tile[0];
        const zoomDiff = zoomLevel - inputZoom;
        const tilesPerRow = 2 ** zoomDiff;
        const tilesPerTile = tilesPerRow ** 2;

        for (let i = 0; i < tilesPerTile; i++) {
            out.push([
                zoomLevel,
                tile[1] * tilesPerRow + (i % tilesPerRow),
                tile[2] * tilesPerRow + Math.floor(i / tilesPerRow),
            ]);
        }
    }

    return out;
}

export function getTilesetBbox(tiles: Tile[]): GeoJSON.BBox {
    return turfBbox(featureCollection(
        tiles.map(tile => getTileBbox(tile).toPolygonFeature()),
    ));
}

/**
 * Return bbox which fits given bbox and has given aspect ratio in webmercator.
 * Can be also additionally scaled by given factor.
 *
 * @param bbox
 * @param [ratio] Aspect ratio (width/height) of the output bbox
 * @param [scale] Factor to scale the output bbox with. This scaling is
 *  different from turf's scale as it's scaled after webmercator projection.
 */
export function expandBbox(bbox: Bbox, ratio: number, scale: number): Bbox {
    const bottomLeft = toMercator(bbox.bottomLeft);
    const topRight = toMercator(bbox.topRight);
    const center = toMercator(bbox.center);

    const width = topRight[0] - bottomLeft[0];
    const height = topRight[1] - bottomLeft[1];

    let outWidth: number;
    let outHeight: number;

    const inputRatio = width / height;

    if (ratio > inputRatio) {
        outWidth = width * ratio / inputRatio;
        outHeight = height;
    } else {
        outWidth = width;
        outHeight = height * inputRatio / ratio;
    }

    outWidth *= scale;
    outHeight *= scale;

    return Bbox.fromVertices([
        toWgs84([center[0] - outWidth / 2, center[1] - outHeight / 2]),
        toWgs84([center[0] + outWidth / 2, center[1] + outHeight / 2]),
    ]);
}

/**
 * Calculate a zoom level on which the imagery with given GSD on given latitude
 * is neither upscaled nor downscaled.
 */
export function calculateExactZoom(lat: number, gsd: number, tileSize = 512) {
    const parallelLength = calculateParallelLength(lat / 180 * Math.PI);
    const tileSizeMeters = gsd * tileSize;

    return Math.log2(parallelLength / tileSizeMeters);
}

function calculateParallelLength(lat: number): number {
    const WGS84_EQUATORIAL_RADIUS = 6378137;
    const WGS84_POLAR_RADIUS = 6356752.3142;

    const eccSquared = 1 - WGS84_POLAR_RADIUS ** 2 / WGS84_EQUATORIAL_RADIUS ** 2;
    const numerator = Math.PI * WGS84_EQUATORIAL_RADIUS * Math.cos(lat);
    const denominator = 180 * Math.sqrt(1 - eccSquared * Math.sin(lat) ** 2);

    return numerator / denominator * 360;
}

/**
 * Calculate minimal rectangular raster canvas size for given tileset.
 */
export function calculateTilesetBboxPxSize(tiles: Tile[], tileSize: number): number {
    assert(tiles.length, "Cannot calculate bbox for empty tileset");

    const minX = tiles.map(t => t[1]).reduce((min, v) => v < min ? v : min);
    const maxX = tiles.map(t => t[1]).reduce((max, v) => v > max ? v : max);
    const minY = tiles.map(t => t[2]).reduce((min, v) => v < min ? v : min);
    const maxY = tiles.map(t => t[2]).reduce((max, v) => v > max ? v : max);

    return (maxX - minX + 1) * (maxY - minY + 1) * tileSize ** 2;
}

/**
 * Return only tiles that have intersection with the scene footprint and given
 * polygon.
 */
export function filterExistingTiles(
    tiles: Tile[],
    scene: Scene,
    polygon: GeoJSON.Feature<GeoJSON.Polygon>,
): Tile[] {
    if (!tiles.length) {
        return [];
    }

    const extent = !!scene.footprint
        ? intersect(scene.footprint, polygon) : polygon;

    if (extent) {
        return tiles.filter(tile => tilePolygonIntersection(tile, extent.geometry));
    } else {
        return [];
    }
}

export function getTilesForBbox(bbox: GeoJSON.BBox, zoom: number): Tile[] {
    const topLeftTile = projectToTile([bbox[0], bbox[3]], zoom);
    const botRightTile = projectToTile([bbox[2], bbox[1]], zoom);

    const xTiles = botRightTile[1] - topLeftTile[1] + 1;
    const yTiles = botRightTile[2] - topLeftTile[2] + 1;

    const tiles = new Array<Tile>(xTiles * yTiles);

    for (let i = 0; i < tiles.length; i++) {
        tiles[i] = [
            Math.ceil(zoom),
            topLeftTile[1] + i % xTiles,
            topLeftTile[2] + Math.floor(i / xTiles),
        ];
    }

    return tiles;
}

export function computeDistanceOnZoom(
    point0: GeoJSON.Position,
    point1: GeoJSON.Position,
    zoomLevel: number,
): number {
    const MAPBOX_TILE_SIZE = 512;
    const mapSize = Math.pow(2, zoomLevel) * MAPBOX_TILE_SIZE;

    const [startPoint, endPoint] = [point0, point1]
        .map(coord => projectToWebm(coord));

    const distance = Math.sqrt(
        (endPoint.x - startPoint.x) ** 2
        + (endPoint.y - startPoint.y) ** 2,
    );

    return distance * mapSize;
}

/**
 * We often need to make tile ID to serve as tile keys in maps etc. Using this
 * function allows us to have consistent IDs without creating a new one for
 * each use case.
 */
export function getTileId(tile: Tile): TileId {
    return `${tile[0]}-${tile[1]}-${tile[2]}`;
}

///
///
/// Interfaces
///
///

export interface PixelSizes {
    x: number;
    y: number;
}

export interface MapUnitsPerMeter {
    x: number;
    y: number;
}

export type Tile = Readonly<[number, number, number]>; // [zoom, x, y]

export type TileId = `${number}-${number}-${number}`;

export interface TilesetBounds {
    // topLeft tile
    topLeft: Tile;
    // width in number of tiles
    width: number;
    // height in number of tiles
    height: number;
}

export interface WebmBounds {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
}
