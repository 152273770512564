import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnInit,
} from "@angular/core";
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
    DateTime,
} from "luxon";

import {
    CalendarDialogData,
    DEFAULT_START_DAY,
} from "skCommon/datePicker/model";

@Component({
    selector: "sk-date-picker-calendar",
    templateUrl: "./datePickerCalendar.pug",
    styleUrls: ["./datePickerCalendar.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerCalendarComponent implements OnInit {

    public calendarStartDay: Date = DEFAULT_START_DAY;
    public calendarEndDay: Date = new Date();
    public activeDateTime = DateTime.now();

    constructor(
        private readonly dialogRef: MatDialogRef<DatePickerCalendarComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: CalendarDialogData,
    ) { }

    public ngOnInit(): void {
        this.activeDateTime = this.data.selectedDateTime
            ? this.data.selectedDateTime : DateTime.now();
    }

    public get calendarStartDateTime(): DateTime {
        return DateTime.fromJSDate(this.calendarStartDay, {
            zone: "utc",
        });
    }

    public get calendarEndDateTime(): DateTime {
        return DateTime.fromJSDate(this.calendarEndDay, {
            zone: "utc",
        });
    }

    public selectDateTime(dateTime: DateTime): void {
        this.dialogRef.close(dateTime);
    }

    public setActivedDateTime(activedDateTime: DateTime): void {
        this.activeDateTime = activedDateTime;
    }
}
