export function htmlCharactersToText(htmlText: string): string {
    const regex = /(&+[a-zA-Z]+;)/g;
    const specialCharacters = htmlText.match(regex);
    if (specialCharacters) {
        specialCharacters.forEach((character) => {
            htmlText = htmlText.replace(character, htmlCharacters[character]);
        });
    }
    return htmlText;
}

const htmlCharacters: Record<string, string> = {
    "&quot;": "\"",
    "&apos;": "\'",
    "&amp;": "&",
    "&lt;": "<",
    "&gt;": ">",
    "&nbsp;": " ",
    "&plusmn;": "±",
    "&times;": "×",
    "&divide;": "÷",
};
