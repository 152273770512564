import { Component, ChangeDetectionStrategy } from "@angular/core";
import { makeObservable } from "mobx";

import { observableShallow } from "skCommon/state/mobxUtils";
import { sleep } from "skCommon/utils/delay";

const RIPPLE_DURATION = 2000;

/**
 * Component that can be used inside the sk-chart overlay to show linear
 * ripples.
 */
@Component({
    selector: "sk-chart-ripples",
    templateUrl: "./chartRipples.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartRipplesComponent {

    @observableShallow
    private _ripples: Ripple[] = [];

    public get ripples(): readonly Readonly<Ripple>[] {
        return this._ripples;
    }

    constructor() {
        makeObservable(this);
    }

    public async showRipple(req: RippleRequest): Promise<void> {
        const ripple: Ripple = {
            style: {
                backgroundColor: req.color,
                left: req.left + "px",
            },
        };

        this._ripples.push(ripple);

        await sleep(RIPPLE_DURATION);

        this._ripples.splice(this.ripples.indexOf(ripple), 1);
    }
}

export interface Ripple {
    style: Pick<CSSStyleDeclaration, "backgroundColor" | "left">;
}

export interface RippleRequest {
    left: number;
    color: string;
}
