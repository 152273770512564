import { Observable, ReplaySubject, Subject } from "rxjs";

const MAX_PROCESSING_COUNT = 70;

export class Throttle {

    private currentlyProcessing = 0;

    private lastInQueue = 0;

    private firstInQueue = 0;

    private queue = new Map<number, Subject<void>>();

    public constructor() {}

    public canMakeCall(): Observable<void> {
        const subject = new ReplaySubject<void>(1);
        this.addToQueue(subject);
        return subject.asObservable();
    }

    public finishedRequest() {
        this.currentlyProcessing -= 1;
        if (this.firstInQueue !== this.lastInQueue) { // queue isn't empty
            this.fireNextRequest();
        }
    }

    private addToQueue(s: Subject<void>): void {
        this.queue.set(this.lastInQueue, s);
        this.lastInQueue += 1;
        this.fireNextRequest();
    }

    private fireNextRequest(): void {
        if (this.currentlyProcessing >= MAX_PROCESSING_COUNT) {
            return;
        }
        this.queue.get(this.firstInQueue).next();
        this.queue.delete(this.firstInQueue);
        this.firstInQueue += 1;
        this.currentlyProcessing += 1;
    }
}

let throttleInstance: Throttle;

export function getThrottle(): Throttle {
    if (!throttleInstance) {
         throttleInstance = new Throttle();
    }
    return throttleInstance;
}
