import { ChangeDetectionStrategy, Component, Injector, Input } from "@angular/core";
import { makeObservable } from "mobx";

import { computed, observableRef } from "skCommon/state/mobxUtils";
import { Logger } from "skCommon/utils/logger";

import { DesignerModel } from "skInsights/framework/designer/designerModel";
import { DesignerRegistryService } from "skInsights/framework/designer/designerRegistry.service";
import { RenderableComponent } from "skInsights/framework/renderable";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "sk-designer-wrapper",
    templateUrl: "./designerWrapper.pug",
    styleUrls: ["./designerWrapper.scss"],
})
export class DesignerWrapperComponent {

    @observableRef
    @Input()
    public model?: DesignerModel;

    @computed
    public get renderable(): RenderableComponent<any> | undefined {
        if (this.model) {
            const def = this.model.initialDefinition;
            // Make the model available to the designer component
            const injector = Injector.create({
                providers: [
                    {
                        provide: DesignerModel,
                        useValue: this.model,
                    },
                ],
                parent: this.injector,
            });

            return {
                component: this.designerRegistryService.getComponent(def),
                injector,
            };
        } else {
            this.logger.warning("Designer wrapper has no model provided.");
            return undefined;
        }
    }

    constructor(
        private injector: Injector,
        private designerRegistryService: DesignerRegistryService,
        private logger: Logger,
    ) {
        makeObservable(this);
    }
}
