import {
    AuthenticatorInterface,
    AuthenticationType,
    AuthenticationData,
} from "skCommon/core/authentication";
import { ClientOptions } from "skCommon/api/client/simple";
import { getDefaultConfig } from "skCommon/api/config";
import { getAuthClient } from "skCommon/auth/client";
import { ApiObject } from "skCommon/api/apiConfig";

export const SPACEKNOW_OAUTH = "spaceknow-oauth";

export class SpaceknowAuthenticator implements AuthenticatorInterface<UserAuthData> {

    public readonly authType = AuthenticationType.Options;

    public async validateAuth(authData: UserAuthData) {
        if (authData.expiryTimestamp <= Date.now() + 120000) {
            return false;
        }

        return true;
    }

    public async renewAuth(authData: UserAuthData): Promise<UserAuthData> {
        if (!("refreshToken" in authData.data)) {
            return null;
        }

        const response = await getAuthClient().getToken({
            refresh_token: authData.data.refreshToken,
            grant_type: "refresh_token",
            audience: null,
        });

        authData.data.accessToken = response.access_token;
        authData.data.refreshToken = response.refresh_token;
        authData.expiryTimestamp = Date.now() + (response.expires_in * 1000);

        return authData;
    }

    public async revokeAuth(authData: UserAuthData, silent: boolean) {
        if (!silent) {
            const auth0Client = getAuthClient();
            let url = location.protocol + "//" + location.host;

            try {
                url = await auth0Client.getLogoutUrl(authData, url);
            } finally {
                window.location.href = url;
            }
        }

        return true;
    }

    public setRequestOptions(
        api: string,
        options: ClientOptions,
        authData: UserAuthData,
    ) {
        if (!options.headers) {
            options.headers = new Headers();
        }

        if ("keys" in authData.data && api in authData.data.keys) {
            const key = getDefaultConfig()
                .getByHost(authData.data.keys[api].data.api_key);
            options.headers.set(
                "Authorization",
                "Key " + key,
            );
        } else if ("accessToken" in authData.data) {
            options.headers.set(
                "Authorization", "Bearer " + authData.data.accessToken,
            );
        }

        return options;
    }
}

export type UserAuthData =  AuthenticationData<AuthData>;

export type AuthData = OAuthTokenData | ServerAuthData;

export type TokenData = OAuthTokenData | OAuthCodeData | OAuthErrorData;

export interface OAuthCodeData {
    code: string;
    state: string;
}

export interface OAuthTokenData {
    accessToken: string;
    expiration: number;
    refreshToken?: string;
}

export interface OAuthErrorData {
    error: string;
    errorDescription: string;
}

export interface ServerAuthData {
    keys: { [api: string]: ApiObject };
}
