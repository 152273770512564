import { CalendarOptions } from "skCommon/datePicker/model/datePicker.interface";

export const CALENDAR_WIDTH = 244;
export const DATEPICKER_INPUT_BOTTOM_PADDING = 20;

export const DEFAULT_INPUT_LABEL = "Date:";
export const DEFAULT_START_DAY = new Date(Date.UTC(2000, 0, 1));
export const DEFAULT_CALENDAR_OPTIONS: CalendarOptions = {
    disableToday: false,
    disableFutureDates: false,
};
