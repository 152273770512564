import traverse from "traverse";

/**
 * Convert Timestamps to Dates, object-arrays to arrayns and add document's ID
 */
export function adaptFirestoreDoc<T>(doc: AdaptDocInput<T>): Doc<T> {
    let id: string;
    let data: T;

    if ("exists" in doc) {
        if (!doc.exists) {
            return null;
        }

        id = doc.ref.id;
        data = doc.data();
    } else {
        id = doc.id;
        data = doc;
    }

    traverse(data).forEach(function (v) {
        if (v instanceof Object && "toDate" in v) {
            this.update(v.toDate());
        } else if (v instanceof Object && "$$array" in v) {
            this.update(Array.from(Object.values(v.$$array)));
        }
    });

    return {
        ...data,
        id,
    };
}

/**
 * Prepare document to be storable into firestore
 */
export function createFirestoreDoc<T>(data: Object): Doc<T> {
    const firestoreData = createFirestoreObject<any>(data);

    if ("id" in firestoreData) {
        delete firestoreData.id;
    }

    return firestoreData;
}

export function createFirestoreObject<T>(data: T): T {
    return traverse(data).map(function (v) {
        if (v instanceof Array && v.length && v[0] instanceof Array) {
            const obj = {};

            for (const [i, val] of [...v.entries()]) {
                obj[i] = val;
            }

            this.update({ $$array: obj });
        } if (v === undefined) {
            this.remove();
        }
    });
}

type AdaptDocInput<T> = LibAgnosticDocumentSnapshot
    | GenericLibAgnosticDocumentSnapshot<T>
    | Doc<T>;

/**
 * firebase-admin and firebase DocumentSnapshot definitions are quite
 * different, but the firebase-admin's type cannot be used, as it depends on
 * NodeJS typings. This type contains just few common properties, so both
 * Snapshot types can be used here.
 */
export interface GenericLibAgnosticDocumentSnapshot<T = any> {
    ref: {
        id: string;
        path: string;
    };
    exists: boolean;
    data(): T;
}
export interface LibAgnosticDocumentSnapshot {
    ref: {
        id: string;
        path: string;
    };
    exists: boolean;
    data(): any;
}

export type Doc<T> = { id: string } & T;
