import { Component, ChangeDetectionStrategy, Inject, HostBinding, Injector } from "@angular/core";

import { ComponentDef } from "skCommon/insights/dashboard";

import { LayoutComponent, LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { RenderableComponent } from "skInsights/framework/renderable";

export const FlexComponentType: FlexComponentType = "core/flex";
export type FlexComponentType = "core/flex";

@Component({
    selector: "sk-core-flex",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./flex.pug",
    styleUrls: ["./flex.scss"],
})
export class FlexComponent extends LayoutComponent<FlexComponentDef> {

    @HostBinding("class")
    public classes: string[];

    public children: FlexRenderableComponent[];

    public get direction(): FlexDirection {
        return this.def.direction || "column";
    }

    constructor(
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        protected readonly def: FlexComponentDef,
        private injector: Injector,
        private moduleRegistryService: ModuleRegistryService,
    ) {
        super();

        this.classes = ["direction-" + this.direction];

        this.children = this.def.children.map(child => ({
            component: this.moduleRegistryService.getAngularComponent(child.content),
            injector: Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: LAYOUT_COMPONENT_DEF_TOKEN,
                        useValue: child.content,
                    },
                ],
            }),
            style: this.getChildStyle(child),
        }));
    }

    private getChildStyle(child: FlexChild): Partial<CSSStyleDeclaration> {
        const sizeProp = this.direction === "row" ? "width" : "height";

        return {
            [sizeProp]: child.size ?? undefined,
            flexBasis: child.size ?? undefined,
            flexGrow: child.grow ? "1" : undefined,
        };
    }
}

interface FlexRenderableComponent extends RenderableComponent {
    style: Partial<CSSStyleDeclaration>;
}

/**
 * Container allowing to display multiple components.
 */
export interface FlexComponentDef {
    component: FlexComponentType;
    /**
     * Child components to display in the container
     */
    children: FlexChild[];
    /**
     * How should the child components be positioned
     *
     * @default "column"
     */
    direction?: FlexDirection;
}

export type FlexDirection = "row" | "column";

export interface FlexChild {
    /**
     * Width or height (depending on component's direction). Any CSS value is
     * accepted (percents, viewport units, auto, px...)
     */
    size?: string;
    /**
     * Make component to take up all available space without deforming other
     * siblings which `"size": "100%"` would cause.
     */
    grow?: boolean;
    /**
     * The child definition component to display
     */
    content: ComponentDef;
}
