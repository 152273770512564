/**
 * Spaceknow common-js
 * Copyright (C) 2016 SpaceKnow, Inc.
 */

export class Backoff {
    public static alg: BackoffAlgorithm;

    public static calculate(attempt: number, ...args: any[]): number {
        return Backoff.alg.call(Backoff, attempt, ...args);
    }

    public static calculateWithAlg(alg: BackoffAlgorithm, attempt: number,
            ...args: any[]): number {
        return alg.call(Backoff, attempt, ...args);
    }

    public static setDefaultAlgorithm(alg: BackoffAlgorithm) {
        Backoff.alg = alg;
    }

    static get algorithms() {
        return {
            exponential: (attempt: number, factor: number = 1.5) => {
                return (Math.pow(2, Math.max(attempt, 0) / factor) - 1 ) * factor / 2;
            },
            noop: () => {
                return 0;
            },
        };
    }
}

Backoff.alg = Backoff.algorithms.exponential;

///
///
/// Interfaces
///
///

export type BackoffAlgorithm = (attempt: number, ...args: any[]) => number;
