import { OutputData } from "@editorjs/editorjs";

import { HtmlContent } from "skInsights/modules/core/components/html/html.component";

export function convertHtmlComponentContent(content: HtmlContent): OutputData {
    if (typeof content !== "string") {
        return content;
    }

    const wrapper = document.createElement("div");

    wrapper.innerHTML = content;

    const htmlTextContent = wrapper.innerText || "";
    const paragraphs = htmlTextContent.split("\n").filter(l => l);

    const pBlocks = paragraphs.map(text => ({
        type: "paragraph",
        data: { text },
    }));

    return {
        blocks: pBlocks,
        time: Date.now(),
        version: "2.19.1",
    };
}
