import { Inject, Injectable, Injector, Optional } from "@angular/core";

import {
    INSIGHTS_PROTOCOL,
    LinkHandler,
    LINK_HANDLERS_TOKEN,
} from "skInsights/framework/insightsProtocol";
import { SnackBarService } from "skInsights/utils/snackBar.service";

/**
 * Service which handles execution of links with app-specific URLs.
 *
 * To keep the HTML as clean and standard as possible, modules can provide
 * handlers for different URL formats for which those handlers should be
 * executed instead of navigation.
 *
 * Currently the link handler services are provided globally and dashboard's
 * injector is being passed to it.
 */
@Injectable()
export class LinkService {

    constructor(
        private snackBarService: SnackBarService,
        private injector: Injector,
        @Optional()
        @Inject(LINK_HANDLERS_TOKEN)
        private linkHandlers: LinkHandler[],
    ) { }

    public isInsightsLink(link: string): boolean {
        return link.startsWith(INSIGHTS_PROTOCOL);
    }

    public executeLink(link: string) {
        if (this.isInsightsLink(link)) {
            this.executeInsightsLink(link);
        } else {
            // TODO: test whether relative => use router navigate
            window.open(link);
        }
    }

    public executeInsightsLink(link: string): void {
        const linkHandler = (this.linkHandlers || [])
            .find(handler => handler.match.test(link));

        if (linkHandler) {
            linkHandler.execute(link, this.injector);
        } else {
            this.snackBarService.notify(`No handler for link ${link}`);
        }
    }
}
