import { ChangeDetectionStrategy, Component, HostBinding } from "@angular/core";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

import { BaseComponent } from "skCommon/angular/base/base.component";

import { DesignerModel } from "skInsights/framework/designer/designerModel";
import { FlexChildModel } from "skInsights/modules/core/components/flex/designer/flexChildModel";
import { FlexComponentDef } from "skInsights/modules/core/components/flex/flex.component";

@Component({
    selector: "sk-flex-designer",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./flexDesigner.pug",
})
export class FlexDesignerComponent extends BaseComponent {

    public children: FlexChildModel[];

    @HostBinding("style")
    public get style(): Partial<CSSStyleDeclaration> {
        return {
            display: "flex",
            flexGrow: "1",
            flexDirection: this.designerModel.initialDefinition.direction || "column",
        };
    }

    constructor(private designerModel: DesignerModel<FlexComponentDef>) {
        super();

        const initDef = this.designerModel.initialDefinition;

        this.children = initDef.children.map(child => new FlexChildModel(child));

        const children$ = combineLatest(this.children.map(model => model.getDefinition$()));

        const def$ = children$.pipe(
            map(children => ({
                ...initDef,
                children,
            })),
        );

        this.designerModel.updateDefinitionStream(def$);
    }
}
