import { Subject } from "rxjs";
import { take } from "rxjs/operators";

export class SkCursorPagination implements Pagination {
    private cursor: string;

    public setFromResponse<T>(response: SkCursorPaginatedResponse<T>) {
        this.cursor = !!response.cursor
            ? response.cursor
            : null;
    }

    public getResultsFromResponse<T>(response: SkCursorPaginatedResponse<T>) {
        return response.results;
    }

    public hasMore() {
        return this.cursor !== null;
    }

    public getNextPageParams() {
        let params: SkCursorPageParams = null;

        if (this.cursor !== null) {
            params = {cursor: this.cursor};
        }

        return params;
    }
}

export class ManualSkCursorPagination extends SkCursorPagination
        implements ManualPagination {
    private requests = new Subject<void>();

    public get nextPageRequested(): Promise<void> {
        return this.requests.pipe(
            take(1),
        ).toPromise();
    }

    public requestNextPage(): void {
        this.requests.next();
    }

    public cancel(): void {
        this.requests.error(new PaginationCanceled());
    }

    public setFromResponse<T>(response: SkCursorPaginatedResponse<T>) {
        super.setFromResponse(response);

        if (!this.hasMore()) {
            this.requests.complete();
        }
    }
}

export class PaginationCanceled extends Error {
    constructor() {
        super("Pagination was canceled");
    }
}

///
///
/// Interfaces
///
///

export interface Pagination {
    setFromResponse<T>(response: SkCursorPaginatedResponse<T>): void;
    getResultsFromResponse<T>(response: SkCursorPaginatedResponse<T>): T[];
    hasMore(): boolean;
    getNextPageParams(): {} | null;
}

export interface ManualPagination extends Pagination {
    nextPageRequested: Promise<void>;
    /**
     * Method called from inside the client when request is cancelled.
     */
    cancel(): void;
}

export interface SkCursorPaginatedResponse<T> {
    cursor: string | null;
    results: T[];
}

interface SkCursorPageParams {
    cursor?: string;
}
