import { Injectable } from "@angular/core";

import { SkColor } from "skCommon/colors";
import { PageExport } from "skCommon/exports/pageExport/pageExport";

import { DashboardGlobals } from "skInsights/dashboard/globalConstants.service";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { ComponentStaticRenderer } from "skInsights/framework/dashboardPdf/componentStaticRenderer";
import { SidebarViewComponentDef } from "skInsights/modules/core/components/sidebarView/sidebarView.component";
import { DashboardPdfService } from "skInsights/framework/dashboardPdf/dashboardPdf.service";
import { DashboardPdfOptions } from "skInsights/framework/dashboardPdf/dashboardPdfOptions";

@Injectable()
export class SidebarViewStaticRendererService implements ComponentStaticRenderer<SidebarViewComponentDef> {

    constructor(
        private dataRefService: DataRefService,
        private dashboardPdfService: DashboardPdfService,
    ) { }

    public async renderIntoPage(
        def: SidebarViewComponentDef,
        exportPage: PageExport,
        options: DashboardPdfOptions,
    ): Promise<void> {
        const dashboardName = this.dataRefService.getConstant(DashboardGlobals.DashboardName);

        for (const section of def.sections) {
            const pagesToExport = section.pages.filter(page => !page.excludeFromPdf);

            for (const page of pagesToExport) {
                exportPage.ensureNewPage();
                exportPage.typeLine(dashboardName, {
                    color: SkColor.Primary80,
                    style: "medium",
                });

                const pageTitle = await this.dataRefService.interpolate(page.title);

                exportPage.typeLine(pageTitle, {
                    fontSize: 20,
                });
                exportPage.movePointer(3);

                await this.dashboardPdfService.renderComponent(
                    exportPage,
                    page.content,
                    false,
                    options,
                );
            }
        }
    }
}
