const ALLOWED_STYLES: string[] = [
    "font-size",
    "font-weight",
    "line-height",
    "text-transform",
    "text-align",
    "letter-spacing",
    "font-style",
    "font-family",
    "padding",
    "margin",
    "white-space",
    "text-transform",
    "color",
];

export const HTML_CONFIG = {
    allowedTags: [
        "b",
        "strong",
        "img",
        "a",
        "br",
        "p",
        "em",
        "i",
        "table",
        "tr",
        "td",
        "th",
        "tbody",
        "thead",
        "ul",
        "ol",
        "li",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "caption",
        "figure",
        "figcaption",
        "div",
        "span",
    ],
    allowedAttributes: {
        "*": ["style", "class"],
        "a": ["component", "event", "data", "href"],
        "img": ["src"],
    },
    allowedStyles: {
        "*": Object.fromEntries(ALLOWED_STYLES.map(s => [s, [/.+/]])),
    },
    allowedClasses: {
        "*": [
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h7",
            "subtitle",
            "caption",
            "caption-2",
            "caption-3",
            "p",
            "p-2",
            "push-bottom",
            "clean-data-content",
        ],
    },
    allowedSchemes: ["http", "https", "ftp", "mailto", "tel", "insights"],
};
