import { NgModule } from "@angular/core";

import { DocsComponent } from "skInsights/docs/docs.component";
import { DocsButtonComponent } from "skInsights/docs/docsButton.component";
import { DocsItemComponent } from "skInsights/docs/docsItem.component";
import { SharedModule } from "skInsights/shared.module";

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        DocsComponent,
        DocsItemComponent,
        DocsButtonComponent,
    ],
    exports: [
        DocsButtonComponent,
    ],
})
export class DocsModule { }
