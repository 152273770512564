import { DateRangePickerDialogData } from "skCommon/dateRangePicker";

export const DEFAULT_DATE_RANGE_PICKER_DIALOG_DATA: DateRangePickerDialogData = {
    startDateOptions: {
        label: "Start Date",
        disableFutureDates: true,
        disableToday: true,
    },
    endDateOptions: {
        label: "End Date",
        disableFutureDates: true,
        disableToday: false,
    },
};
