/**
 * Translate access object into availability statement
 */
import { InsightsCustomerInterface } from "skInsights/admin/customerManagement.service";

export function getAccessType(
    customer: InsightsCustomerInterface | undefined,
    dbId: string,
): DashboardAccessType {
    if (customer) {
        const dbs = customer.dashboards;
        if (dbs[dbId]) {
            const dashboardAccess = createDashboardAccess(dbs[dbId]);
            return dashboardAccess.getCurrentAccess();
        } else {
            return DashboardAccessType.None;
        }
    }
    return DashboardAccessType.Full;
}

export const DENIED_HASH = "__DENIED__";

export interface InsightsCustomer {
    userId: string;
    email: string;
    name: string;
    company: string;
    /**
     * Dictionary of dashboard IDs mapped to the details about the access.
     */
    dashboards: Record<string, DashboardAccess>;
}

export enum DashboardAccessType {
    Full = "Full",
    TrialWithDataExplorer = "Trial with Data Explorer",
    Trial = "Trial",
    None = "None",
}

export function createDashboardAccess(access:
    {
        name: string,
        startDate: Date,
        endDate: Date,
        accessType: DashboardAccessType,
        hash: string,
    }): DashboardAccess {
    return new DashboardAccess(
        access.name, access.startDate, access.endDate, access.accessType, access.hash);
}

export class DashboardAccess {

    get name(): string {
        return this._name;
    }

    get startDate(): Date {
        return this._startDate;
    }

    get endDate(): Date {
        return this._endDate;
    }

    get accessType(): DashboardAccessType {
        return this._accessType;
    }

    get hash(): string {
        return this._hash;
    }

    constructor(
        private _name: string,
        private _startDate: Date,
        private _endDate: Date,
        private _accessType: DashboardAccessType = DashboardAccessType.None,
        private _hash: string,
    ) {}

    public getCurrentAccess(): DashboardAccessType {
        const today = new Date();
        if (this.endDate > today && this.startDate < today) {
            return this.accessType;
        }
        return DashboardAccessType.None;
    }

    public setAccess(startDate: Date, endDate: Date, access: DashboardAccessType): void {
        if (startDate < endDate) {
            this._startDate = startDate;
            this._endDate = endDate;
            this._accessType = access;
        } else {
            throw new Error();
        }
    }

    public hasAccess(): boolean {
        return this.getCurrentAccess() !== DashboardAccessType.None;
    }
}
