/**
 * Object representing single delivery containing completely all information
 * required to create the delivery PDF.
 */
export interface ClientDelivery {
    /**
     * Title to display on the intro slide
     */
    title: string;
    /**
     * Filename of the PDF that will be downloaded.
     * {todays-date}.pdf is automatically appended so do not include the extension.
     */
    filename: string;
    /**
     * Dashboard ID from which the datasets should be read from.
     */
    sourceDashboardId: string;
    /**
     * Structures describing content of each page
     */
    pages: (UglyChartOptions | UglyDoubleChartOptions)[];
}

/**
 * Options for page containing single chart
 */
export interface UglyChartOptions {
    header: string;
    chart: PageChart;
}

/**
 * Options for page containing two charts
 */
export interface UglyDoubleChartOptions {
    header: string;
    charts: [PageDoubleChart, PageDoubleChart];
}

/**
 * Representation of chart that should be rendered onto page
 */
export interface PageChart {
    /**
     * Datasets to include in the chart
     */
    datasets: ChartQuery[];
    /**
     * Text rendered above the chart. Should only be used for charts with
     * single timeseries, since charts with multiple series use legend instead
     * of this title.
     */
    title?: string;
    /**
     * Set charts min range to given year. If negative number is provided, the
     * date is computed from todays minus n years.
     */
    startYear?: number;
    /**
     * DCR threshold to apply to all timeseries with DCR available
     */
    dcrThreshold?: number;
}

/**
 * Represents single chart + text in the double chart layout
 */
export interface PageDoubleChart extends PageChart {
    /**
     * Text displayed on the left side of page
     */
    text: string;
}

export enum CsvExportType {
    NONE,
    DATA,
    FORECAST,
}

export interface CsvExport {
    csvExportType: CsvExportType;
    csvName?: string;
}
/**
 * Object describing chart structure and where should the datasets be loaded
 * from.
 */
export type ChartQuery = (ExistingChartQuery | ExtraChartQuery) & CsvExport;

/**
 * Query used to find chart in dashboard by its visible name. Useful since the
 * product IDs used in the dashboard may change someday, in which case we want
 * to propagate this change into the report without changing the report
 * definition.
 */
export interface ExistingChartQuery extends ChartQueryBase {
    chartName: string;
    tsName: string;
    /**
     * Visible tab name rather than the ID
     */
    tabName?: string;
}

/**
 * Query for chart that wasn't included in the dashboard and thus needs to be
 * constructed
 */
export interface ExtraChartQuery extends ChartQueryBase {
    productId: string;
    productSeries: string;
}

interface ChartQueryBase {
    /**
     * Title to display in legend if displayed
     */
    newTitle?: string;
    /**
     * Pipe to append to the found timeseries reference
     * @example `resample("month", "last")`
     */
    pipe?: string;
    /**
     * Set timeseries color, if not provided it's automatically chosen
     */
    overrideColor?: string;
    /**
     * Keep chart style identical to how it looked in dashboard
     */
    keepStyle?: boolean;
    /**
     * Override automatic scale range for this dataset (in form of [min, max]).
     */
    scaleRange?: [number, number];
}
