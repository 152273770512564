import { Inject, Component, NgModule, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { OutputData } from "@editorjs/editorjs";
import { MatDividerModule } from "@angular/material/divider";

import { BlockEditorModel } from "skInsights/partials/blockEditor/blockEditorModel";
import { BlockEditorModule } from "skInsights/partials/blockEditor/blockEditor.module";
import { SharedModule } from "skInsights/shared.module";

@Component({
    selector: "sk-block-editor-dialog",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./blockEditorDialog.pug",
})
export class BlockEditorDialogComponent {

    public model: BlockEditorModel;

    private resolved: boolean = false;

    constructor(
        private matDialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA)
        private dialogData: BlockEditorDialogData,
    ) {
        this.model = new BlockEditorModel(this.dialogData.data);

        this.matDialogRef.backdropClick().subscribe(() => {
            const close = confirm("Unsaved changes will be lost.");

            if (close) {
                this.resolve();
            }
        });
    }

    public async submit(): Promise<void> {
        const data = await this.model.getOutputData();

        this.resolve(data);
    }

    private resolve(d?: OutputData): void {
        if (!this.resolved) {
            this.resolved = true;
            this.dialogData.resolve(d);
            this.matDialogRef.close();
        }
    }
}

@NgModule({
    imports: [
        SharedModule,
        BlockEditorModule,
        MatDividerModule,
    ],
    declarations: [
        BlockEditorDialogComponent,
    ],
})
export class BlockEditorDialogModule { }

export interface BlockEditorDialogData {
    resolve: BlockEditorResolve;
    data: OutputData;
}

export type BlockEditorResolve = (d: BlockEditorDialogOutput) => void;

export type BlockEditorDialogOutput = OutputData | undefined;
