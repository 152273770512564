import { Injectable, NgZone, Inject, Optional } from "@angular/core";
import {
    Router,
    NavigationEnd,
    NavigationStart,
    NavigationError,
    Data,
    NavigationExtras,
    UrlTree,
    ExtraOptions,
    ROUTER_CONFIGURATION,
} from "@angular/router";
import { Title } from "@angular/platform-browser";
import {
    observable,
    action,
    makeObservable,
} from "mobx";
import { combineLatest, Observable, isObservable, of } from "rxjs";
import { filter, sample, map, switchMap, takeUntil } from "rxjs/operators";

import { Log } from "skCommon/utils/actionLogger";
import { LogLevel } from "skCommon/utils/logger";
import { APP_NAME } from "skCommon/angular/configuration";

@Injectable({
    providedIn: "root",
})
export class RouterStore<TRoutes extends string = any, TSubroutes extends string = any> {

    @observable
    public url = "";

    @observable
    public prevUrl = "";

    @observable
    public navigationInProgress = false;

    protected readonly rootRoute: string = "/";

    public get useHash(): boolean {
        return !!this.routerConfig.useHash;
    }

    constructor(
        private router: Router,
        private titleService: Title,
        private ngZone: NgZone,
        @Inject(ROUTER_CONFIGURATION)
        private routerConfig: ExtraOptions,
        @Optional()
        @Inject(APP_NAME)
        private appName: string,
    ) {
        makeObservable(this);

        const events$ = this.router.events;
        const navEnd$ = events$.pipe(
            filter(event => event instanceof NavigationEnd),
        );

        // This is to prevent the app from hanging on load
        // hence only the first event is watched
        events$.pipe(
            filter(event => event instanceof NavigationError),
            takeUntil(navEnd$),
        ).subscribe(() => {
            this.router.navigate([this.rootRoute]);
        });

        events$.pipe(
                filter(event => event instanceof NavigationStart),
                map(() => this.router.routerState.snapshot.url),
                sample(navEnd$),
            )
            .subscribe(url => {
                this.prevUrl = url;
            });

        navEnd$.subscribe((event: NavigationEnd) => {
            this.changeCurrentUrl(event.urlAfterRedirects);
        });

        // Change page title
        navEnd$.pipe(
            map(() => {
                let route = this.router.routerState.root,
                    depth = 0;

                while (route.firstChild && depth <= 1) {
                    route = route.firstChild;
                    depth++;
                }
                return route;
            }),
            filter(route => route.outlet === "primary"),
            switchMap(route => combineLatest([route.parent.data, route.data])),
            map(([parentData, childData]) => ({ ...parentData, ...childData })),
            switchMap(
                data => isDynamicTitleData(data)
                    ? data.title$
                    : of(isTitleData(data) ? data.getTitle(data) : null),
            ),
        ).subscribe(title => {
            const sk = this.appName || "SpaceKnow";

            this.titleService.setTitle(
                title ? title + " - " + sk : sk,
            );
        });
    }

    /**
     * Get fully qualified URL for given route on current hostname
     */
    public getRouteUrl(route: string[], extras?: NavigationExtras): string {
        const baseUrl = `${location.protocol}//${location.host}`;
        const tree = this.router.createUrlTree(route, extras);
        const path = this.router.serializeUrl(tree);
        if (this.useHash) {
            return `${baseUrl}/#${path}`;
        } else {
            return `${baseUrl}${path}`;
        }
    }

    public isActive(
        route: TRoutes,
        subroute?: TSubroutes,
    ): boolean {
        return this.testUrl(this.url, route, subroute);
    }

    public cameFrom(
        route: TRoutes,
        subroute?: TSubroutes,
    ): boolean {
        return this.testUrl(this.prevUrl, route, subroute);
    }

    public testUrl(
        url: string,
        route: TRoutes,
        subroute?: TSubroutes,
    ): boolean {
        return this.getRegExp(route, subroute).test(url);
    }

    public isRootUrlActive(): boolean {
        return new RegExp("^\/$").test(this.url)
            || new RegExp("^\/\\?.*$").test(this.url);
    }

    /**
     * Angular router's navigateByUrl wrapped into zone. As not all code in the
     * app run necessarily in zone, this method should be always used instead
     * of the angular's method.
     */
    public navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
        this.navigationInProgress = true;
        const promise = this.ngZone.run(() => this.router.navigateByUrl(url, extras));
        promise.then(() => this.navigationInProgress = false);
        return promise;
    }

    /**
     * Angular router's navigate wrapped into zone. As not all code in the
     * app run necessarily in zone, this method should be always used instead
     * of the angular's method.
     */
    public navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        this.navigationInProgress = true;
        const promise = this.ngZone.run(() => this.router.navigate(commands, extras));
        promise.then(() => this.navigationInProgress = false);
        return promise;
    }

    public onError(handler: (e: NavigationError) => void): void {
        this.router.events.pipe(
            filter<NavigationError>(event => event instanceof NavigationError),
        ).subscribe((e) => {
            handler(e);
        });
    }

    private getRegExp(
        route: TRoutes,
        subroute?: TSubroutes,
    ): RegExp {
        let str = "^\/";

        if (route.length) {
            str += route;
        }

        if (subroute) {
            if (route.length) {
                str += "/";
            }
            str += subroute;
        }

        if (!route.length && !subroute) {
            str += "[^\/]*$";
        }

        return new RegExp(str);
    }

    @Log.Action
    @Log.Level(LogLevel.Info)
    @Log.ParentClassName("RouterStore")
    @Log.Param("url", 0)
    @action
    private changeCurrentUrl(url: string): void {
        this.url = url;
    }
}


function isTitleData(data: Data): data is TitleData {
    return data.getTitle instanceof Function;
}

function isDynamicTitleData(data: Data): data is DynamicTitleData {
    return isObservable(data.title$);
}

interface TitleData extends Data {
    getTitle: (data: Data) => string;
}

interface DynamicTitleData extends Data {
    title$: Observable<string>;
}
