import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { OutputData } from "@editorjs/editorjs";
import { makeObservable } from "mobx";

import { observableRef } from "skCommon/state/mobxUtils";

import { LayoutComponent, LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { RichTextService } from "skInsights/partials/richText/richText.service";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { outputDataToHtml } from "skInsights/partials/blockEditor/outputData";

export const HtmlComponentType: HtmlComponentType = "core/html";
export type HtmlComponentType = "core/html";

@Component({
    selector: "sk-core-html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./html.pug",
    styleUrls: ["./html.scss"],
})
export class HtmlComponent extends LayoutComponent<HtmlComponentDef> {

    @observableRef
    public html: SafeHtml = this.richTextService.sanitize("Loading...");

    constructor(
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        protected readonly def: HtmlComponentDef,
        private dataRefService: DataRefService,
        private richTextService: RichTextService,
    ) {
        super();

        makeObservable(this);
    }

    public async ngOnInit(): Promise<void> {
        super.ngOnInit();

        const html = typeof this.def.content === "string"
            ? this.def.content
            : outputDataToHtml(this.def.content);

        const interpolated = await this.dataRefService.interpolate(html);
        this.html = this.richTextService.sanitize(interpolated);
    }
}

/**
 * Simple element displaying any custom HTML.
 */
export interface HtmlComponentDef {
    component: HtmlComponentType;
    /**
     * Any HTML code or output of the block editor
     * @interpolated
     */
    content: HtmlContent;
}

export type HtmlContent = string | OutputData;
