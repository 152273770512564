import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

/**
 * I hate MatSnackBar open signature, why would you add put action before ttl...
 */
@Injectable({ providedIn: "root" })
export class SnackBarService {

    constructor(
        private matSnackBar: MatSnackBar,
        private ngZone: NgZone,
    ) { }

    public notify(message: string, ttl?: number): void {
        this.open({
            message,
            ttl,
        });
    }

    public open(options: SnackBarOptions): void {
        this.ngZone.run(() => {
            this.matSnackBar.open(
                options.message,
                undefined,
                {
                    duration: options.ttl === null
                        ? 0
                        : (options.ttl ?? 10) * 1000,
                },
            );
        });
    }
}

interface SnackBarOptions {
    message: string;
    ttl?: number;
}
