import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
} from "@angular/core";
import {
    MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import {
    DateTime,
} from "luxon";

import {
    CalendarOptions,
    DEFAULT_CALENDAR_OPTIONS,
} from "skCommon/datePicker";
import {
    DateRangePickerDialogData,
    DateRangePickerPayload,
} from "skCommon/dateRangePicker";

@Component({
    selector: "sk-date-range-picker-dialog",
    templateUrl: "./dateRangePickerDialog.pug",
    styleUrls: ["./dateRangePickerDialog.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerDialogComponent implements OnInit {

    public startDate: Date | undefined = undefined;
    public endDate: Date | undefined = undefined;
    public startDateOptions: CalendarOptions = DEFAULT_CALENDAR_OPTIONS;
    public endDateOptions: CalendarOptions = DEFAULT_CALENDAR_OPTIONS;

    @Output()
    public dateRangeSelected = new EventEmitter<DateRangePickerPayload>();

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: DateRangePickerDialogData,
    ) {}

    public ngOnInit(): void {
        if (this.data) {
            this.startDateOptions = this.data.startDateOptions;
            this.endDateOptions = this.data.endDateOptions;
            this.startDate = this.data.startDateOptions.initialValue;
            this.endDate = this.data.endDateOptions.initialValue;
        }
    }

    public setStartDate(date: Date): void {
        this.startDate = date;
    }

    public setEndDate(date: Date): void {
        this.endDate = date;
    }

    public get hasDates(): boolean {
        return !!this.startDate && !!this.endDate;
    }

    public get dateRangeIsInvalid(): boolean {
        if (this.startDate && this.endDate) {
            const startDateTime = DateTime.fromJSDate(this.startDate, { zone: "utc" });
            const endDateTime = DateTime.fromJSDate(this.endDate, { zone: "utc" });
            const diffDays = endDateTime.diff(startDateTime, "days").days;

            return diffDays < 0;
        } else {
            return false;
        }
    }

    public get confirmButtonDisabled(): boolean {
        return !this.hasDates || this.dateRangeIsInvalid;
    }

    public get payload(): DateRangePickerPayload {
        return {
            from: this.startDate,
            to: this.endDate,
        };
    }
}
