import { Chart, Element } from "chart.js";
import { Type } from "@angular/core";
import "chartjs-plugin-annotation/src/index.js";
import { AnnotationOptions } from "chartjs-plugin-annotation/types/options";
import { observable, makeObservable } from "mobx";

import { ChartContext } from "skCommon/chart/chartContext";

/**
 * Aside from importing the original annotations plugin also add enter,
 * leave and click handlers to handle click nicely.
 */
export function annotationPlugin<T extends Type<ChartContext>>(
    Base: T,
): T & Type<AnnotationPlugin> {
    class AnnotationPluginMixin extends Base implements AnnotationPlugin {

        @observable.ref
        public hoveredAnnotation: AnnotationOptions<"line">;

        private annotationClickHandler: AnnotationClickHandler;

        private annotationPluginRegistered = false;

        constructor(...args: any[]) {
            super(...args);

            makeObservable(this);
        }

        public update() {
            if (!this.annotationPluginRegistered) {
                Object.assign(this.chart.options.plugins.annotation, {
                    enter: ctx => this.annotationOnEnter(ctx),
                    leave: ctx => this.annotationOnLeave(ctx),
                    click: ctx => this.annotationOnClick(ctx),
                });

                this.chart.canvas.addEventListener("mouseleave", () => {
                    (this.chart.options as any).plugins.annotation.annotations
                        .forEach(ann => this.unhoverAnnotation(ann));
                    this.chart.update();
                });

                this.annotationPluginRegistered = true;
            }
            super.update();
        }

        public onAnnotationClick(handler: AnnotationClickHandler): void {
            this.annotationClickHandler = handler;
        }

        /**
         * Find annotation by our custom property ID.
         */
        public findAnnotation(id: string): any {
            const anns = this.options.plugins?.annotation?.annotations || [];

            return anns.find((ann: any) => ann.id === id);
        }

        private annotationOnEnter(ctx: AnnotationEventContext) {
            const annotation = this.getAnnotation(ctx);

            this.hoveredAnnotation = annotation;

            if (annotation.hover) {
                ctx.chart.canvas.style.cursor = "pointer";
                annotation.$originalBorderWidth = annotation.borderWidth;
                annotation.borderWidth = 4;

                ctx.chart.update();
            }
        }

        private annotationOnLeave(ctx: AnnotationEventContext) {
            this.unhoverAnnotation(this.getAnnotation(ctx));

            ctx.chart.update();
        }

        private unhoverAnnotation(annotation: any): void {
            this.hoveredAnnotation = undefined;

            if (annotation.hover) {
                annotation.borderWidth = annotation.$originalBorderWidth;
                this.chart.canvas.style.cursor = "auto";
            }
        }

        private annotationOnClick(ctx: AnnotationEventContext) {

            if (this.annotationClickHandler) {
                const annotation = this.getAnnotation(ctx);

                this.annotationClickHandler({
                    options: annotation,
                    annotationId: annotation.id,
                    element: ctx.element,
                });
            }
        }

        private getAnnotation({ element, chart }: AnnotationEventContext) {
            const id = (element.options as any).id;
            const annotations = (chart.options as any)
                .plugins.annotation.annotations;
            return annotations.find((ann: any) => ann.id === id);
        }
    }

    return AnnotationPluginMixin;
}

export interface AnnotationPlugin {
    hoveredAnnotation?: AnnotationOptions<"line">;

    onAnnotationClick(handler: AnnotationClickHandler): void;
    findAnnotation(id: string): any;
}

type AnnotationClickHandler = (e: AnnotationClickEvent) => void;

export interface AnnotationClickEvent {
    annotationId: string;
    element: LineAnnotationElement;
    /**
     * Annotation options
     */
    options: AnnotationOptions;
}

interface AnnotationEventContext {
    chart: Chart;
    element: LineAnnotationElement;
}

export interface LineAnnotationElement extends Element {
    labelRect: {
        x: number;
        y: number;
        width: number;
        height: number;
    };
    options: any;
    width: number;
    height: number;
    x2: number;
    y2: number;
}

declare module "chart.js" {
    interface PluginOptionsByType {
        annotation: any;
    }
}

declare module "chartjs-plugin-annotation/types/options" {
    interface LineAnnotationOptions {
        tooltip?: string;
        hover?: boolean;
        id?: string;
    }
}
