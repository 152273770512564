import { NgModule } from "@angular/core";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatRippleModule } from "@angular/material/core";

import { SearchSelectComponent } from "skCommon/angular/partials/searchSelect/searchSelect.component";
import { SharedModule } from "skCommon/angular/shared.module";
import { AutorunModule } from "skCommon/angular/state/autorun.module";

@NgModule({
    imports: [
        SharedModule,
        ScrollingModule,
        MatRippleModule,
        AutorunModule,
    ],
    declarations: [
        SearchSelectComponent,
    ],
    exports: [
        SearchSelectComponent,
    ],
})
export class SearchSelectModule { }
