import { combineLatest, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { PageModel } from "skInsights/modules/core/components/sidebarView/designer/page.model";
import { SectionDefinition } from "skInsights/modules/core/components/sidebarView/sidebarView.component";

export class SectionModel {

    public readonly definition$: Observable<SectionDefinition>;

    public readonly pages: ReadonlyArray<PageModel>;

    public readonly label: string;

    constructor(private originalDef: SectionDefinition) {
        this.pages = originalDef.pages.map(pageDef => new PageModel(pageDef));
        this.label = originalDef.label;

        this.definition$ = combineLatest(this.pages.map(p => p.definition$)).pipe(
            map(pages => ({
                ...this.originalDef,
                label: this.label,
                pages,
            })),
            shareReplay(),
        );
    }
}
