export enum DashboardRoutes {
    Dashboard = "dashboard",
    Home = "home",
    EditDashboard = "edit-dashboard",
    DataExplorer = "data-explorer",
}

export enum EditDashboardRoutes {
    General = "general",
    Json = "json",
    Designer = "designer",
    History = "history",
}
