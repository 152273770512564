import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, of } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";

import { adaptFirestoreDoc, Doc } from "skCommon/firebase/doc";
import {
    DashboardAccess,
    DashboardAccessType,
    InsightsCustomer,
} from "skCommon/insights/customers";
import { insightsCustomers } from "skCommon/insights/database";
import { getLogger, Logger } from "skCommon/utils/logger";
import { sortByKey, SortDirection } from "skCommon/utils/sort";

import { SnackBarService } from "skInsights/utils/snackBar.service";

@Injectable({ providedIn: "root" })
export class CustomerManagementService {

    constructor(
        private angularFirestore: AngularFirestore,
        private snackBarService: SnackBarService,
        private logger: Logger,
    ) { }

    public getCustomers$(): Observable<Doc<InsightsCustomer>[]> {
        const customers$ = this.angularFirestore.collection<InsightsCustomer>(
            insightsCustomers(),
        ).valueChanges({ idField: "id" });

        return customers$.pipe(
            map(docs => docs.map(d => adaptFirestoreDoc(d))),
            map(docs => docs.sort(sortByKey("company", SortDirection.Asc))),
            catchError((e: Error) => {
                this.logger.error(e, "Loading customers");
                this.snackBarService.notify(`Could not load customers: ${e.message}`);
                return of([]);
            }),
            shareReplay(),
        );
    }

    public async addCustomer(newCustomer: InsightsCustomer): Promise<void> {
        await this.angularFirestore.collection(insightsCustomers()).add(newCustomer);
    }

    public getCustomer(customerId: string): Observable<InsightsCustomer | undefined> {
        return this.angularFirestore.collection<InsightsCustomerInterface>(
            insightsCustomers(),
        )
            .doc(customerId)
            .valueChanges({ idField: "id" })
            .pipe(
                map(doc => adaptFirestoreDoc(doc!)),
                map((customer) => this.mapCustomer(customer!)),
            );
    }

    private mapCustomer(customerInterface: InsightsCustomerInterface): InsightsCustomer {
        const newDashboardAccess: Record<string, DashboardAccess> = {};
        if (customerInterface.dashboards) {
            Object.entries(customerInterface.dashboards).forEach(([id, access]) => {
                newDashboardAccess[id] = new DashboardAccess(
                    access.name,
                    access.startDate,
                    access.endDate,
                    access.accessType,
                    access.hash,
                );
            });
        }
        return {
            userId: customerInterface.userId,
            email: customerInterface.email,
            name: customerInterface.name,
            company: customerInterface.company,
            dashboards: newDashboardAccess,
        };
    }

    public addDashboardAccess(
        customerId: string,
        dashboardId: string,
        startDate: Date,
        endDate: Date,
        name:  string,
        accessType: DashboardAccessType,
    ): Promise<void> {
        return this.angularFirestore.doc(insightsCustomers(customerId)).update({
            [`dashboards.${dashboardId}`]: {
                startDate: startDate,
                endDate: endDate,
                name:  name,
                accessType: accessType,
                hash: "",
            },
        });
    }

    public getDashboardAccess(
        customerId: string,
        dashboardId: string,
    ): Observable<DashboardAccess> {
        return this.angularFirestore.doc<InsightsCustomer>(insightsCustomers(customerId))
            .get()
            .pipe(
                map(doc => adaptFirestoreDoc<InsightsCustomer>(doc!)),
                map(doc => new DashboardAccess(
                        doc!.dashboards[dashboardId].name,
                        doc!.dashboards[dashboardId].startDate,
                        doc!.dashboards[dashboardId].endDate,
                        doc!.dashboards[dashboardId].accessType,
                        doc!.dashboards[dashboardId].hash,
                    ),
                ),
            );
    }

    public async removeDashboardAccess(
        customerId: string,
        dashboardId: string,
        ): Promise<void> {
        try {
            await this.angularFirestore.doc(insightsCustomers(customerId)).update({
                [`dashboards.${dashboardId}.endDate`]: new Date(),
            });
        } catch (e) {
            getLogger().error(e, "Could not remove access");
        }
    }
}

export interface InsightsCustomerInterface {
    userId: string;
    email: string;
    name: string;
    company: string;
    dashboards: Record<string,
        {
            name: string,
            startDate: Date,
            endDate: Date,
            accessType: DashboardAccessType,
            hash: string,
        }>;
}
