import { h } from "jsx-dom";
import { API, BlockTool, BlockToolConstructable, BlockToolConstructorOptions, ToolboxConfig, ToolConfig } from "@editorjs/editorjs";

import { getToolboxIcon } from "skInsights/partials/blockEditor/plugins/helpers/toolboxIcon";
import { TemplateSectionToolData } from "skInsights/partials/blockEditor/plugins/templateSection/templateSection";

class TemplateSectionToolImpl implements BlockTool {

    public static isReadOnlySupported = true;

    public static toolbox: ToolboxConfig = {
        icon: getToolboxIcon("brief"),
        title: "Template Partial Block",
    };

    private input = <input type="text" placeholder="Section name..." /> as HTMLInputElement;

    constructor(private options: BlockToolOptions) {
        if ("data" in this.options) {
            this.input.value = this.options.data.name || "";
        }
    }

    public render(): HTMLElement {
        return <div class="template-snippet">
            Template's section in which document may insert its own content
            {this.input}
        </div> as HTMLElement;
    }

    public save(): TemplateSectionToolData {
        return {
            name: this.input.value || "Unnamed section",
        };
    }
}

export const TemplateSectionTool = TemplateSectionToolImpl as
    BlockToolConstructable & typeof TemplateSectionToolImpl;

type BlockToolOptions = BlockToolConstructorOptions<TemplateSectionToolData>
    | { api: API, config?: ToolConfig };

