/**
 * Content of this file should mirror contents of
 * common/src/style/colors.scss
 *
 * Ideally in the future this should be created automatically from single
 * template like we do with algorithm colors & svgs.
 */
export enum SkColor {
    Primary80 = "#4963E1",
    Primary50 = "#9AA9F5",
    Primary20 = "#E1E5F9",
    Primary10 = "#EFF1FA",

    Neutral = "#000000",
    Neutral100 = "#000000",
    Neutral90 = "#525252",
    Neutral80 = "#696969",
    Neutral70 = "#808080",
    Neutral60 = "#969696",
    Neutral50 = "#ADADAD",
    Neutral40 = "#C4C4C4",
    Neutral30 = "#DBDBDB",
    Neutral20 = "#E6E6E6",
    Neutral10 = "#F0F0F0",
    Neutral00 = "#FFFFFF",

    Red10 = "#FCECEB",
    Red20 = "#FCDDDC",
    Red30 = "#F1D3D2",
    Red40 = "#E9B5B4",
    Red50 = "#FF8C8A",
    Red60 = "#F4504C",
    Red70 = "#E23734",
    Red80 = "#B22927",
    Red90 = "#9C1312",

    DarkGreen10 = "#E1F6F9",
    DarkGreen20 = "#CFEDF3",
    DarkGreen30 = "#C2E5EB",
    DarkGreen40 = "#A4D0D6",
    DarkGreen50 = "#70C5D2",
    DarkGreen60 = "#4FB6C6",
    DarkGreen70 = "#27A2B5",
    DarkGreen80 = "#1F8696",
    DarkGreen90 = "#106C7A",
    DarkGreen100 = "#0C4F5A",

    Green10 = "#F0F6DB",
    Green20 = "#E4EDC9",
    Green30 = "#DAE2C1",
    Green40 = "#C1C7AC",
    Green50 = "#A7C256",
    Green60 = "#8CAB30",
    Green70 = "#7A9135",
    Green80 = "#637D14",
    Green90 = "#4E6509",
    Green100 = "#37460C",
}
