import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { omit } from "skCommon/utils/object";

import { DesignerModel } from "skInsights/framework/designer/designerModel";
import { FlexChild } from "skInsights/modules/core/components/flex/flex.component";

export class FlexChildModel {

    public readonly contentModel: DesignerModel;

    private readonly details: Omit<FlexChild, "content">;

    constructor(flexChild: FlexChild) {
        this.details = omit(flexChild, "content");
        this.contentModel = new DesignerModel(flexChild.content);
    }

    public getDefinition$(): Observable<FlexChild> {
        return this.contentModel.definition$.pipe(
            map(content => ({ ...this.details, content })),
        );
    }
}
