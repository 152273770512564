import { StaticProvider, Injectable, InjectionToken, Inject } from "@angular/core";
import { formatDate } from "@angular/common";

import { Logger } from "skCommon/utils/logger";
import { extendError } from "skCommon/core/error";
import { parseDate, round } from "skCommon/utils/data";
import { assert } from "skCommon/utils/assert";
import { asyncCallbackReplace } from "skCommon/utils/string";
import { DataRef } from "skCommon/insights/dashboard";

import { DashboardData } from "skInsights/framework/dashboardData";
import { DashboardDataType, DashboardSeries, DashboardSingleValue, DashboardString, DashboardCollection, AnyDashboardData, DashboardDataTypeMap, DashboardSeriesPair } from "skInsights/framework/data/structures";
import { assertSeriesLike, isCollection, assertSingleValuesLike, isSingleValue, assertSingleValueCollection, toDashboardString, assertType, isSeriesPair, flattenSeriesPair } from "skInsights/framework/data/helpers";
import { DashboardPipe, StringPipe, NumberPipe, OperationPipe } from "skInsights/framework/pipe";
import { ExpressionService } from "skInsights/framework/expression.service";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { PickOperationDef } from "skInsights/modules/core/operations/pick";
import { PipeArgument, PipeCall } from "skInsights/framework/pipeCall";

const DATA_REF_DICTIONARY_TOKEN = new InjectionToken("DataRef variable dictionary");

/**
 * Service for selecting data from existing sources in the available dashboard
 * data instance.
 *
 * The service also replaces variable keywords (e.g. `${PARENT_SERIES}`) with
 * provided values in DataRef select queries. When new variable names need to
 * be added a new translator should be created using the provide method and
 * provided for child components.
 *
 * Requires DashboardData instance to be available in the injector.
 */
@Injectable()
export class DataRefService {

    private readonly pipes: Record<string, DashboardPipe> = {
        date: new StringPipe(d => formatDate(
            parseDate(d),
            "MMM dd, yyyy",
            "en_US",
        )),
        round: new NumberPipe((val: number, decimals: PipeArgument = 2) => {
            assert(typeof decimals === "number", `round pipe: ${decimals} is not a number`);
            return round(val, decimals);
        }),
        last: new OperationPipe([{
            operation: "core/pick",
            index: "last",
        } as PickOperationDef], this.moduleRegistryService),
    };

    public static provide(dict: Record<string, string> = {}): StaticProvider[] {
        return [
            {
                provide: DataRefService,
                useClass: DataRefService,
            },
            {
                provide: DATA_REF_DICTIONARY_TOKEN,
                useValue: dict,
            },
        ];
    }

    constructor(
        @Inject(DATA_REF_DICTIONARY_TOKEN)
        private dict: Record<string, string>,
        private dashboardData: DashboardData,
        private logger: Logger,
        private expressionService: ExpressionService,
        private moduleRegistryService: ModuleRegistryService,
    ) { }

    public async resolveFlattenSeries(ref: DataRef): Promise<DashboardSeries[]> {
        ref = await this.translate(ref);

        const series = await this.resolveData(
            ref,
            [
                DashboardDataType.Series,
                DashboardDataType.SeriesPair,
                DashboardDataType.Collection,
            ],
        );

        assertSeriesLike(series);

        if (isCollection(series)) {
            return Object.values(series.set);
        } if (isSeriesPair(series)) {
            return flattenSeriesPair(series);
        } else {
            return [series];
        }
    }

    public async resolveFlattenValues(ref: DataRef): Promise<DashboardSingleValue[]> {
        ref = await this.translate(ref);

        const data = await this.resolveData(
            ref,
            [DashboardDataType.SingleValue, DashboardDataType.Collection],
        );

        assertSingleValuesLike(data);

        if (isCollection(data)) {
            return Object.values(data.set);
        } else {
            return [data];
        }
    }

    public async resolveSeries(ref: DataRef): Promise<DashboardSeries> {
        ref = await this.translate(ref);

        return this.resolveData(ref, DashboardDataType.Series);
    }

    public async resolveSeriesPair(ref: DataRef): Promise<DashboardSeriesPair> {
        ref = await this.translate(ref);

        return this.resolveData(ref, DashboardDataType.SeriesPair);
    }

    public async resolveSingleValue(ref: DataRef): Promise<DashboardSingleValue> {
        ref = await this.translate(ref);

        return this.resolveData(ref, DashboardDataType.SingleValue);
    }

    public async resolveSingleValueCollection(
        ref: DataRef,
    ): Promise<DashboardCollection<DashboardSingleValue>> {
        ref = await this.translate(ref);

        const col = await this.resolveData(ref, DashboardDataType.Collection);

        assertSingleValueCollection(col);

        return col;
    }

    public async resolveString(ref: DataRef): Promise<DashboardString> {
        ref = await this.translate(ref);

        const stringOrValue = await this.resolveData(ref, [
            DashboardDataType.String,
            DashboardDataType.SingleValue,
        ]);

        if (isSingleValue(stringOrValue)) {
            return {
                text: stringOrValue.value.toString(),
                type: DashboardDataType.String,
            };
        } else {
            return stringOrValue;
        }
    }

    public async resolveAny(ref: DataRef): Promise<AnyDashboardData> {
        ref = await this.translate(ref);

        const { expression, pipes } = this.expressionService.parsePipedExpression(ref);

        return this.applyPipes(
            await this.resolveData(expression),
            pipes,
        );
    }

    /**
     * Resolve data from DashboardData, execute pipes and check the type of
     * resulting data.
     */
    private async resolveData<T extends DashboardDataType>(
        ref: DataRef,
        type?: T | T[],
    ): Promise<DashboardDataTypeMap[T]> {
        ref = await this.translate(ref);

        const { expression, pipes } = this.expressionService.parsePipedExpression(ref);

        const data = await this.applyPipes(
            await this.dashboardData.resolve(expression),
            pipes,
        );

        if (type) {
            assertType(data, type);
        }

        return data as DashboardDataTypeMap[T];
    }

    /**
     * Create new DataRef with variables replaced accordingly
     */
    public async translate(ref: DataRef): Promise<DataRef> {
        const { text, undefinedConstants } = await this.evaluateExpressions(ref);

        if (undefinedConstants.length) {
            const list = undefinedConstants.map(con => `"${con}"`).join(", ");
            throw new Error(`Undefined constant "${list}" in DataRef`);
        }

        return text;
    }

    /**
     * Interpolate data references and expressions in following forms:
     * @{SOURCE_ID/SELECT_QUERY}
     * ${SOME_CONSTANT}
     * @{SOURCE_ID/SELECT_QUERY | optionalPipes}
     * ${SOME_CONSTANT | optionalPipes | morePipes}
     */
    public async interpolate(input: string): Promise<string> {
        input = (await this.evaluateExpressions(input)).text;

        return asyncCallbackReplace(input, /@\{([^\}]+)\}/g, async (_, ref: string) => {
            const { expression, pipes } = this.expressionService.parsePipedExpression(ref);

            try {
                return toDashboardString(await this.applyPipes(
                    await this.resolveAny(expression),
                    pipes,
                )).text;
            } catch (e) {
                this.logger.error(e);
                return "#INTERPOLATION_ERROR#";
            }
        });
    }

    public async executePipe(
        pipeCall: PipeCall,
        data: AnyDashboardData,
    ): Promise<AnyDashboardData> {
        try {
            const { name, args } = pipeCall;
            const pipe = name in this.pipes
                ? this.pipes[name]
                : this.dashboardData.getNamedPipe(name);

            return (await pipe.execute(data, args, this)).select("*");
        } catch (e) {
            const pipeStr = this.stringifyPipeCall(pipeCall);
            throw extendError(e, `Pipe ${pipeStr} for data of type ${data.type} failed: `);
        }
    }

    /**
     * Provide a new DataRefService instance extending the original dictionary.
     */
    public extend(newDict: Record<string, string>): StaticProvider[] {
        return DataRefService.provide({
            ...this.dict,
            ...newDict,
        });
    }

    public getConstant(constName: string): string {
        return this.dict[constName] || "";
    }

    private async applyPipes(
        input: AnyDashboardData,
        pipes: PipeCall[],
    ): Promise<AnyDashboardData> {
        let modified = input;

        for (const pipe of pipes) {
            modified = await this.executePipe(pipe, modified);
        }

        return modified;
    }

    private async evaluateExpressions(q: string): Promise<EvaluateExpressionsResult> {
        const undefinedConstants: string[] = [];
        const text = await asyncCallbackReplace(q, /\${([^}]+)}/g, async (_, expression) => {
            const { expression: constName, pipes } = this.expressionService
                .parsePipedExpression(expression);
            if (constName in this.dict) {
                const constString = toDashboardString(this.dict[constName]);
                const modified = await this.applyPipes(constString, pipes);

                return toDashboardString(modified).text;
            } else {
                undefinedConstants.push(constName);
                this.logger.warning(`Interpolating undefined constant "${constName}"`);
                return "#UNDEFINED_CONSTANT#";
            }
        });

        return { text, undefinedConstants };
    }

    private stringifyPipeCall(pipeCall: PipeCall): string {
        const args = (pipeCall?.args || []).join(", ");
        const pipeName = pipeCall?.name || "#INVALID#";

        return `${pipeName}(${args})`;
    }
}

export interface EvaluateExpressionsResult {
    text: string;
    undefinedConstants: string[];
}
