import { NgModule } from "@angular/core";

import { Svg } from "skCommon/svg";
import { Insights } from "skCommon/permissions";

import { Admin, AdminRoutes } from "skInsights/admin/admin.routing";
import { provideNavEntry } from "skInsights/nav/nav";
import { Documents } from "skInsights/modules/documents/documents.routing";

@NgModule({
    providers: [
        provideNavEntry({
            icon: Svg.Home,
            label: "Home",
            link: "home",
        }),
        provideNavEntry({
            icon: Svg.Settings,
            label: "Admin",
            menu: [
                {
                    label: "Automated Deliveries",
                    link: [Admin, AdminRoutes.Deliveries],
                    neededPermissions: [Insights.Admin.Deliveries],
                },
                {
                    label: "External Sources",
                    link: [Admin, AdminRoutes.ExternalSources],
                    neededPermissions: [Insights.Admin.ExternalSources],
                },
                {
                    label: "Grant Access",
                    link: [Admin, AdminRoutes.GrantAccess],
                    neededPermissions: [Insights.Admin.GrantAccess],
                },
                {
                    label: "User Management",
                    link: [Admin, AdminRoutes.CustomerManagement],
                    neededPermissions: [Insights.Admin.ManageCustomers],
                },
                {
                    label: "Dashboard Migrator",
                    link: [Admin, AdminRoutes.Migrator],
                    neededPermissions: [Insights.Admin.Migrate],
                },
                {
                    label: "All dashboards",
                    link: [Admin, AdminRoutes.AllDashboards],
                    neededPermissions: [Insights.Admin.ManageCustomers],
                },
            ],
        }),
        provideNavEntry({
            icon: Svg.News,
            label: "Documents",
            link: [Documents],
            neededPermissions: [Insights.Documents.Manage],
        }),
    ],
})
export class MenuEntriesModule { }
