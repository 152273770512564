import { ClientDelivery, CsvExportType } from "skInsights/clientDelivery/clientDelivery";

export const MALAYSIA_DELIVERY: ClientDelivery = {
    sourceDashboardId: "MuzjuNbRxu1Vb37G3Cwg",
    title: "SpaceKnow Malaysia Nowcasting Package",
    filename: "malaysia-delivery",
    pages: [
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Industrial Production Index</font>,\n<font color="#4a86e8">update for period {period}.</font>`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Aggregated Industrial Production",
                    tsName: "30 Day Aggregated",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Aggregated Curated Industrial" +
                        " Production Index Malaysia 30d",
                }],
                title: "SK Malaysia Aggregated Curated Industrial Production Index 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices: <font color="#cc4125">SK Aggregated Curated Industrial Production Index</font>, <font color="#4a86e8"> the chart below compares</font> <font color="#cc4125">SK Forecast</font> <font color="#4a86e8">(green line) with the y-o-y Industrial Production Index compiled by Malaysia's Department of Statistics (gray line).</font>`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Aggregated Industrial Production",
                    tsName: "Malaysia Industrial Production",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Aggregated Industrial Production",
                    tsName: "SK Forecast [Malaysia Industrial Production]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Industrial Production",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Manufacturing Index</font>, <font color="#4a86e8">update for period {period}.</font>`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Aggregated Manufacturing",
                    tsName: "30 Day Aggregated",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Aggregated Curated Manufacturing Index Malaysia 30d",
                }],
                title: "SK Manalysia Aggregated Curated Manufacturing Index 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Manufacturing Index</font>, <font color="#4a86e8"> the chart below displays Manufacturing Index (RHS axis) against IHS Markit Manufacturing PMI (LHS axis).</font>`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Aggregated Manufacturing",
                    tsName: "Malaysia Manufacturing PMI",
                    newTitle: "IHS Markit Malaysia Manufacturing PMI",
                    scaleRange: [30, 60],
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Aggregated Manufacturing",
                    tsName: "30 Day Aggregated",
                    newTitle: "SK Aggregated Curated Manufacturing Index 30d",
                    pipe: `| resample("month", "last")`,
                    csvExportType: CsvExportType.NONE,
                }],
                startYear: 2017,
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Manufacturing Index</font><font color="#4a86e8">, the chart below compares</font> <font color="#cc4125">SK Forecast</font><font color="#4a86e8"> (green line) with the m-o-m IHS Markit Manufacturing PMI (gray line).</font>`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Aggregated Manufacturing",
                    tsName: "Malaysia Manufacturing PMI",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Aggregated Manufacturing",
                    tsName: "SK Forecast [Malaysia Manufacturing PMI]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Manufacturing PMI",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Construction Index</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Aggregated Construction",
                    tsName: "30 Day Aggregated",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Aggregated Curated Construction Index Malaysia",
                }],
                title: "SK Malaysia Aggregated Curated Construction Index 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Mining Index</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Aggregated Mining",
                    tsName: "30 Day Aggregated",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Aggregated Curated Mining Index Malaysia 30d",
                }],
                title: "SK Malaysia Aggregated Curated Mining Index 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Trade Index</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Aggregated Trade",
                    tsName: "30 Day Aggregated",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Aggregated Curated Trade Index Malaysia 30d",
                }],
                title: "SK Malaysia Aggregated Curated Trade Index 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Construction Index</font>, the chart below compares <font color="#cc4125">SK Forecast</font> (green line) with monthly Domestic Construction benchmark data compiled by Bank Negara Malaysia (gray line).`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Aggregated Construction",
                    tsName: "Malaysia Domestic Production of Construction",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Aggregated Construction",
                    tsName: "SK Forecast [Malaysia Domestic Production of Construction]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Domestic Construction",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Trade Index</font>, the chart below compares <font color="#cc4125">SK Forecast</font> (blue line) with the y-o-y percent difference in Total Trade Value (Exports + Imports) compiled by the Malaysia's Department of Statistics (gray line).`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Aggregated Trade",
                    tsName: "Malaysia Total Trade Value",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Aggregated Trade",
                    tsName: "SK Forecast [Malaysia Total Trade Value]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Total Trade Value (Exports + Imports)",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Automotive Indices:</font> <font color="#cc4125">SK Light Vehicle Manufacturing SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Light Vehicle Manufacturing",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Light Vehicle Manufacturing Malaysia 30d",
                }],
                title: "SK Light Vehicle Manufacturing SAI 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Automotive Indices:</font> <font color="#cc4125"> SK Light Vehicle Manufacturing SAI</font>, the chart below compares <font color="#cc4125">SK Forecast</font> (green line) with the m-o-m Car Production benchmark data compiled by the Malaysia's Department of Statistics (gray line).`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Light Vehicle Manufacturing",
                    tsName: "Malaysia Car Production",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Light Vehicle Manufacturing",
                    tsName: "SK Forecast [Malaysia Car Production]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Car Production",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Automotive Indices:</font> <font color="#cc4125">SK Light Vehicle Distribution SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Light Vehicle Distribution",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Light Vehicle Distribution SAI Malaysia 30d",
                }],
                title: "SK Light Vehicle Distribution SAI 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Steel Refineries SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Steel Refineries",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Steel Refineries SAI Malaysia 30d",

                }],
                title: "SK Steel Refineries SAI 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Steel Refineries SAI</font>, the chart below compares <font color="#cc4125">SK Forecast</font> (green line) with the m-o-m Steel Production benchmark index (2010=100) compiled by Bank Negara Malaysia (gray line).`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Steel Refineries",
                    tsName: "Malaysia Steel Production",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Steel Refineries",
                    tsName: "SK Forecast [Malaysia]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Steel Refineries",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Steel Storages SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Steel Storages",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Steel Storages SAI Malaysia",
                }],
                title: "SK Steel Storages SAI 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Electronic Appliances SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Electronic Appliances",
                    tsName: "30 Day Rollings",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Electronic Appliances SAI Malaysia 30d",
                }],
                title: "SK Electronic Appliances SAI 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Electronic Appliances SAI</font>, the chart below compares <font color="#cc4125">SK Forecast</font> (green line) with the m-o-m Electronics Production benchmark index (2010=100) compiled by the Bank Negara Malaysia (gray line).`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Electronic Appliances",
                    tsName: "Malaysia Electronics Production",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Electronic Appliances",
                    tsName: "SK Forecast [Malaysia Electronics Production]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Electronics Production",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Palm Oil Production SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Palm Oil Production",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Palm Oil Production SAI Malaysia",
                }],
                title: "SK Palm Oil Production 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Construction Indices:</font> <font color="#cc4125">SK Building Supplies SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Building Supplies",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Building Supplies SAI Malaysia 30d",
                }],
                title: "SK Building Supplies SAI 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Construction Indices:</font> <font color="#cc4125">SK Cement Refineries SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Cement Refineries",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Cement Refineries SAI Malaysia",
                }],
                title: "SK Cement Refineries SAI 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Construction Indices:</font> <font color="#cc4125">SK Cement Storages SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Cement Storages",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Cement Storages SAI Malaysia 30d",
                }],
                title: "SK Cement Storages SAI 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Construction Indices:</font> <font color="#cc4125">SK Limestone Mines SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Limestone Mines",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Limestone Mines SAI Malaysia 30d",
                }],
                title: "SK Limestone Mines SAI 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Oil and Gas Indices:</font> <font color="#cc4125">SK Oil and Gas Services SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Oil and Gas Services",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Oil and Gas Service SAI Malaysia 30d",
                }],
                title: "SK Oil and Gas Services SAI, 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Transportation Indices:</font> <font color="#cc4125">Trade Indicators</font>`,
            charts: [
                {
                    dcrThreshold: 0.1,
                    text: `<font color="#1155cc">Port Containers:</font>\n<font color="#cc4125">SK Port Containers SAI 30d,</font>\nupdate for {period},\nhigher activity = lower trade.`,
                    title: "SK Port Container SAI 30d",
                    datasets: [{
                        chartName: "Port Containers",
                        tsName: "30 Day Rolling",
                        csvExportType: CsvExportType.DATA,
                        csvName: "SpaceKnow Port Containers SAI Malaysia",
                    }],
                    startYear: -2,
                },
                {
                    dcrThreshold: 0.1,
                    text: `<font color="#1155cc">Industrial Ports:</font>\n<font color="#cc4125">SK Industrial Ports SAI 30d,</font>\nupdate for {period},\nhigher activity = more transaction volume.`,
                    title: "SK Industrial Ports SAI 30d",
                    datasets: [{
                        chartName: "Industrial Ports",
                        tsName: "30 Day Rolling (SA)",
                        csvExportType: CsvExportType.DATA,
                        csvName: "SpaceKnow Industrial Ports SAI Malaysia",
                    }],
                    startYear: -2,
                },
            ],
        },
        {
            header: `<font color="#1155cc">Transportation Indices:</font> <font color="#cc4125">SK Airports Passenger Parking SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Passenger Parking",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Airports Passenger Parking SAI Malaysia 30d",
                }],
                title: "SK Airports Passenger Parking SAI 30d",
                startYear: -2,
            },
        },
        {
            header: `<font color="#1155cc">Retail Indices:</font> <font color="#cc4125">SK Retail Parking CFI</font>, (no aggregation) update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    productId: "SK_COM_RET_PAR_CFI-R_MY_D",
                    productSeries: "SK_COM_RET_PAR_CFI-R_MY_D_rvi_asc",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Retail Parking Malaysia",
                }],
                title: "SK Retail Parking CFI",
            },
        },
        {
            header: `<font color="#1155cc">Retail Indices:</font> <font color="#cc4125">SK Retail Parking CFI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    productId: "SK_COM_RET_PAR_CFI-R_MY_D",
                    productSeries: "SK_COM_RET_PAR_CFI-R_MY_D_rvi_30d_ros_asc",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Retail Parking CF Index Malaysia 30d",
                }],
                title: "SK Retail Parking CFI 30d",
            },
        },
        {
            header: `<font color="#1155cc">Transportation Indices:</font> <font color="#cc4125">SK Logistic Centers CFI (Ascending = evening time)</font> (no aggregation), update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Logistic Centers (evening time)",
                    tsName: "Daily (evening time)",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Logistic Centers CF Index Malaysia (Ascending = evening time) nsa",
                }],
                title: "SK Logistic Centers CFI (Ascending = evening time)",
            },
        },
        {
            header: `<font color="#1155cc">Transportation Indices:</font> <font color="#cc4125">SK Logistic Centers CFI (Ascending = evening time)</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Logistic Centers (evening time)",
                    tsName: "30 Day Rolling (evening time)",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Logistic Centers CF Index Malaysia (Ascending = evening time) 30d",
                }],
                title: "SK Logistic Centers CFI (Ascending = evening time) 30d",
            },
        },
        {
            header: `<font color="#1155cc">Transportation Indices:</font> <font color="#cc4125">SK Logistic Centers CFI (Descending = morning time)</font> (no aggregation), update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Logistic Centers (morning time)",
                    tsName: "Daily (morning time)",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Logistic Centers CF Index Malaysia (Descending = morning time) nsa",
                }],
                title: "SK Logistic Centers CFI (Descending = morning time)",
            },
        },
        {
            header: `<font color="#1155cc">Transportation Indices:</font> <font color="#cc4125">SK Logistic Centers CFI (Descending = morning time)</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Logistic Centers (morning time)",
                    tsName: "30 Day Rolling (morning time)",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Logistic Centers CF Index Malaysia (Descending = morning time) 30d",
                }],
                title: "SK Logistic Centers CFI (Descending = morning time) 30d",
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Semiconductors CFI - SUM</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Semiconductors",
                    tsName: "70 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "Semiconductors CFI - SUM 70d",
                }],
                title: "SK Semiconductors CFI - SUM 70d",
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Semiconductors CFI - DIFF</font>, (no aggregation) update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Semiconductors",
                    tsName: "Daily",
                    csvExportType: CsvExportType.DATA,
                    csvName: "Semiconductors CFI - SUM 1d",
                }],
                title: "SK Semiconductors CFI - DIFF 1d",
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Semiconductors CFI - DIFF</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Semiconductors",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "Semiconductors CFI - SUM 30d",
                }],
                title: "SK Semiconductors CFI - DIFF 30d",
            },
        },
        {
            header: `<font color="#1155cc">Oil and Gas Indices:</font> <font color="#cc4125">SK Oil and Gas Services SAI</font>, the chart below compares <font color="#cc4125">SK Forecast</font> (green line) with the the m-o-m Crude Oil Production benchmark data provided by the US Energy Information Association (gray line).`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Oil and Gas Services",
                    tsName: "Malaysia Crude Oil Production",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Oil and Gas Services",
                    tsName: "SK Forecast [Malaysia]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Crude Oil Production",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Retail Indices:</font> <font color="#cc4125">SK Retail Sales</font>, the chart below compares <font color="#cc4125">SK Forecast Retail Sales</font> (green line, LHS axis) with the y-o-y Retail Sales benchmark data compiled by Malaysia Department of Statistics (gray line, RHS axis).`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Retail Sales",
                    tsName: "MY Retail Sales (YoY)",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Retail Sales",
                    tsName: "SK Forecast [MY Retail Sales YoY]",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }],
            },
        },
        {
            header: `<font color="#1155cc">Retail Indices:</font> <font color="#cc4125">SK Forecast Retail Sales</font>, (y-o-y) update for period {period}.`,
            chart: {
                dcrThreshold: 0.1,
                datasets: [{
                    chartName: "Retail Sales",
                    tsName: "SK Forecast [MY Retail Sales YoY]",
                    overrideColor: "#66aa51",
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Retail Sales",
                }],
                title: "SK Forecast Retail Sales, y-o-y",
            },

        },
    ],
};
