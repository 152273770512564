import { Injectable } from "@angular/core";
import { NavigationEnd, NavigationError, Router } from "@angular/router";
import { filter } from "rxjs/operators";

import { Logger } from "skCommon/utils/logger";

@Injectable({ providedIn: "root" })
export class RouterLoggerService {

    constructor(
        private router: Router,
        private logger: Logger,
    ) { }

    public register(): void {
        this.router.events.pipe(
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        ).subscribe(e => {
            this.logger.info(`Navigated to ${e.url}`);
        });

        this.router.events.pipe(
            filter((e): e is NavigationError => e instanceof NavigationError),
        ).subscribe(e => {
            this.logger.error(e.error);
        });
    }
}
