import { ChangeDetectionStrategy, Component } from "@angular/core";
import { toStream } from "mobx-utils";
import { EMPTY, from } from "rxjs";
import { switchMap } from "rxjs/operators";
import { makeObservable } from "mobx";

import { computed } from "skCommon/state/mobxUtils";
import { Logger } from "skCommon/utils/logger";
import { BaseComponent } from "skCommon/angular/base/base.component";
import { assert } from "skCommon/utils/assert";
import { ComponentDef, DashboardSchema } from "skCommon/insights/dashboard";

import { DashbaordEditorService } from "skInsights/dashboard/dashboardEditor/dashboardEditor.service";
import { DesignerModel } from "skInsights/framework/designer/designerModel";
import { InsightsService } from "skInsights/insights.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";

const SAVING = Symbol();

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: "sk-dashboard-designer",
    templateUrl: "./dashboardDesigner.pug",
    styleUrls: ["./dashboardDesigner.scss"],
})
export class DashboardDesignerComponent extends BaseComponent {

    public currentDefinition?: ComponentDef;

    @computed
    public get designerModel(): DesignerModel | undefined {
        const componentDef = this.dashbaordEditorService.dashboard?.schema.layout;

        if (componentDef) {
            return new DesignerModel(componentDef);
        } else {
            return undefined;
        }
    }

    constructor(
        private dashbaordEditorService: DashbaordEditorService,
        private insightsService: InsightsService,
        private snackBarService: SnackBarService,
        private logger: Logger,
    ) {
        super();

        makeObservable(this);

        const updatingSub = from(toStream(() => this.designerModel, true)).pipe(
            switchMap(model => model ? model.definition$ : EMPTY),
        ).subscribe(def => this.updateDefinition(def));

        this.addSubscription(updatingSub);
    }

    public async saveChanges(): Promise<void> {
        try {
            this.insightsService.globalLoadingProcesses.add(SAVING);

            const oldSchema = this.dashbaordEditorService.dashboard?.schema;

            assert(oldSchema && this.currentDefinition, "Dashboard isn't available anymore");

            const schema: DashboardSchema = {
                ...oldSchema,
                layout: this.currentDefinition,
            };

            await this.dashbaordEditorService.updateDashboard({ schema });

            this.snackBarService.notify(`Saved`, 3);
        } catch (e: any) {
            this.logger.error(e, "Saving JSON dashboard schema");
            this.snackBarService.notify(`Could not save schema: ${e.message}`);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(SAVING);
        }
    }

    private updateDefinition(def: ComponentDef): void {
        this.currentDefinition = def;
    }
}
