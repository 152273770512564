import { Injectable } from "@angular/core";
import { makeObservable } from "mobx";

import { observableRef } from "skCommon/state/mobxUtils";
import { ParsedDashboard } from "skCommon/insights/parsedDashboard";

import { DashboardService } from "skInsights/dashboard/dashboard.service";
import { DashboardRef } from "skInsights/framework/dashboardData";

@Injectable({ providedIn: "root" })
export class DashboardViewService {

    @observableRef
    public dashboard?: ParsedDashboard;

    constructor(private dashboardService: DashboardService) {
        makeObservable(this);
    }

    public async updateDashboard(ref: DashboardRef): Promise<ParsedDashboard> {
        if (this.dashboard && this.dashboard.id === ref.id && !ref.revisionId) {
            return this.dashboard;
        }

        return this.dashboard = await this.dashboardService.loadDashboardRef(ref);
    }
}
