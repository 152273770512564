import { OutputData } from "@editorjs/editorjs";
import { observable, makeObservable } from "mobx";

import { Doc } from "skCommon/firebase/doc";
import { assert } from "skCommon/utils/assert";

import { InsightsDocument } from "skInsights/modules/documents/document";

export class InsightsDocumentModel {

    public readonly id: string;
    public readonly userId: string;
    public readonly createdOn: Date;
    public readonly modifiedOn: Date;
    public readonly pdfGeneratedOn: Date;

    @observable.ref
    public title: string;

    @observable.ref
    public content: OutputData | null;

    @observable.ref
    public sections: Record<string, OutputData> | null;

    @observable.ref
    public extends: string | null;

    @observable.ref
    public template: boolean;

    @observable.shallow
    public define: Record<string, string>;

    @observable.shallow
    public requiredConstants: string[];

    @observable.ref
    public category: string;

    @observable.ref
    public usedProductIds: string[];

    constructor(doc: Doc<InsightsDocument>) {
        makeObservable(this);

        this.id = doc.id;
        this.userId = doc.userId;
        this.createdOn = doc.createdOn;
        this.modifiedOn = doc.modifiedOn;
        this.pdfGeneratedOn = doc.pdfGeneratedOn;
        this.title = doc.title;
        this.content = doc.content;
        this.sections = doc.sections || null;
        this.extends = doc.extends || null;
        this.template = doc.template || false;
        this.define = doc.define || {};
        this.requiredConstants = doc.requiredConstants || [];
        this.category = doc.category || "";
        this.usedProductIds = doc.usedProductIds || [];
    }

    public serialize(): Doc<InsightsDocument> {
        assert(
            this.content && !this.sections && !this.extends
            || !this.content && this.sections && this.extends,
            "Cannot specify both content and partials.",
        );

        const commons = {
            id: this.id,
            userId: this.userId,
            title: this.title,
            define: this.define,
            template: this.template,
            createdOn: this.createdOn,
            modifiedOn: new Date(),
            pdfGeneratedOn: new Date(),
            requiredConstants: this.requiredConstants,
            category: this.category,
            usedProductIds: this.usedProductIds,
        };

        if (this.content) {
            return {
                ...commons,
                content: this.content,
                sections: null,
                extends: null,
            };
        } else {
            return {
                ...commons,
                sections: this.sections!,
                extends: this.extends as string,
                content: null,
            };
        }
    }
}
