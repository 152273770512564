import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, of } from "rxjs";
import { catchError, map, publishReplay, refCount, shareReplay } from "rxjs/operators";

import { adaptFirestoreDoc, Doc } from "skCommon/firebase/doc";
import { omit } from "skCommon/utils/object";
import { Logger } from "skCommon/utils/logger";
import { insightsDocuments } from "skCommon/insights/database";

import { ProductsInDocument } from "skInsights/dashboard/dashboard.service";
import { InsightsDocument } from "skInsights/modules/documents/document";
import { UserService } from "skInsights/user/user.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { DocumentLoaderService } from "skInsights/modules/documents/documentLoader.service";

@Injectable({ providedIn: "root" })
export class DocumentService {

    public allDocs$ = this.getDocuments$().pipe(
        catchError(e => {
            this.logger.error(e, "Loading documents");
            this.snackBarService.notify(`Could not load documents: ${e.message}`);
            return of([]);
        }),
        shareReplay(),
    );

    public documents$ = this.allDocs$.pipe(
        map(docs => docs.filter(d => !d.template)),
    );

    public templates$ = this.allDocs$.pipe(
        map(docs => docs.filter(d => !!d.template)),
    );

    public categories$ = this.documents$.pipe(
        map((docs) => docs.map(doc => doc.category)),
        map((categories) => categories
            .filter((category) => typeof category === "string" && category !== "")),
        map((categories) => [...new Set(categories)]),
    );

    constructor(
        private angularFirestore: AngularFirestore,
        private userService: UserService,
        private snackBarService: SnackBarService,
        private documentLoaderService: DocumentLoaderService,
        private logger: Logger,
    ) { }

    public getDocuments$(): Observable<Doc<InsightsDocument>[]> {
        const docs$ = this.angularFirestore.collection<InsightsDocument>(
            insightsDocuments(),
            q => q.orderBy("modifiedOn", "desc"),
        ).valueChanges({ idField: "id" });

        return docs$.pipe(
            map(docs => docs.map(d => adaptFirestoreDoc<InsightsDocument>(d))),
            publishReplay(),
            refCount(),
        );
    }

    public loadDocument(id: string): Promise<Doc<InsightsDocument>> {
        return this.documentLoaderService.loadDocument(id);
    }

    public async saveDocument(doc?: Doc<InsightsDocument>): Promise<string> {
        const id = doc && doc.id || this.angularFirestore.createId();
        const data = doc ? omit(doc, "id") : {};

        await this.angularFirestore
            .doc<InsightsDocument>(insightsDocuments(id))
            .set({
                ...this.createEmptyDocument(doc?.template ? "template" : "document"),
                ...data,
            });

        return id;
    }

    public setDocumentAsRemoved(id: string): Promise<void> {
        return  this.angularFirestore
            .doc<InsightsDocument>(insightsDocuments(id))
            .update({removeDate: new Date()});
    }

    public async updateDocumentsUsage(productsInDocuments: ProductsInDocument[]): Promise<void> {
        for (const item of productsInDocuments) {
            await this.angularFirestore
                .doc<InsightsDocument>(insightsDocuments(item.documentId))
                .update({usedProductIds: item.productIds});
        }
    }

    private createEmptyDocument(type: "document" | "template" = "document"): InsightsDocument {
        return {
            title: "New Document",
            content: {
                blocks: [],
                time: Date.now(),
            },
            userId: this.userService.id,
            createdOn: new Date(),
            modifiedOn: new Date(),
            pdfGeneratedOn: new Date(),
            define: {},
            extends: null,
            template: type === "template",
            sections: null,
            requiredConstants: [],
            removeDate: null,
        };
    }
}

