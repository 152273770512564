import { SafeHtml } from "@angular/platform-browser";
import { toStream } from "mobx-utils";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { combineLatest, from } from "rxjs";
import { map } from "rxjs/operators";
import { makeObservable } from "mobx";

import { computed, observableRef } from "skCommon/state/mobxUtils";

import { DesignerModel } from "skInsights/framework/designer/designerModel";
import { HtmlComponentDef, HtmlContent } from "skInsights/modules/core/components/html/html.component";
import { RichTextService } from "skInsights/partials/richText/richText.service";
import { convertHtmlComponentContent } from "skInsights/modules/core/components/html/designer/contentConverter";
import { outputDataToHtml } from "skInsights/partials/blockEditor/outputData";
import { BlockEditorDialogService } from "skInsights/partials/blockEditor/blockEditorDialog.service";

@Component({
    selector: "sk-html-designer",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./htmlDesigner.pug",
    styleUrls: ["./htmlDesigner.scss"],
})
export class HtmlDesignerComponent {

    @observableRef
    public content: HtmlContent;

    @computed
    public get html(): SafeHtml {
        const html = typeof this.content === "string"
            ? this.content
            : outputDataToHtml(this.content);

        return this.richTextService.sanitize(html);
    }

    constructor(
        private designerModel: DesignerModel<HtmlComponentDef>,
        private richTextService: RichTextService,
        private blockEditorDialogService: BlockEditorDialogService,
    ) {
        makeObservable(this);

        this.content = designerModel.initialDefinition.content;

        const content$ = from(toStream(() => this.content, true));

        const def$ = combineLatest([content$]).pipe(
            map(([content]) => ({
                ...this.designerModel.initialDefinition,
                content,
            })),
        );

        this.designerModel.updateDefinitionStream(def$);
    }

    public async openContentEditor(): Promise<void> {
        let content = this.content;

        if (typeof content === "string") {
            const ok = confirm(
                "Content needs to be converted into block format, procceed?"
                + "\nNote: Formatting, images, links(...) will get lost.",
            );

            if (ok) {
                content = convertHtmlComponentContent(content);
            }
        }

        if (typeof content === "object" && "blocks" in content) {
            const newData = await this.blockEditorDialogService.open(content);

            if (newData) {
                this.content = newData;
            }
        }
    }
}
