import { Type } from "@angular/core";
import { ChartMeta, ChartType } from "chart.js";
import { observable, makeObservable, action } from "mobx";

import "skCommon/extensions/array.prototype.at";
import { ChartContext } from "skCommon/chart/chartContext";
import { assert } from "skCommon/utils/assert";

/**
 * Make currently displayed and visible datasets mobx-observable. Also
 * introduces the refs property on datasets, which is used to reference
 * and the indexes may change and there are no other property that may serve as
 * ID.
 */
export function observableDatasets<T extends Type<ChartContext>>(
    Base: T,
): T & Type<ObservableDatasets> {
    class ObservableDatasetsMixin extends Base implements ObservableDatasets {

        @observable.shallow
        private hiddenRefs: Set<string> = new Set();

        @observable.ref
        private existingRefs: Set<string> = new Set();

        constructor(...args: any[]) {
            super(...args);

            makeObservable(this);
        }

        protected override onDatasetsChanged(): void {
            super.onDatasetsChanged();

            this.existingRefs = new Set(this.config.data.datasets.flatMap(d => d.refs));

            for (const dataset of this.config.data.datasets) {
                if ((dataset as unknown as ChartMeta).hidden) {
                    this.hiddenRefs.add(
                        dataset.refs.at(-1),
                    );
                }
            }
        }

        @action
        public updateVisibility(): void {
            for (const [i, chartDataset] of this.chart.data.datasets.entries()) {
                if (this.isVisibleByRefs(chartDataset.refs)) {
                    this.chart.setDatasetVisibility(i, true);
                } else {
                    this.chart.setDatasetVisibility(i, false);
                }
            }

            this.update();
        }

        public showDataset(ref: string): void {
            this.hiddenRefs.add(ref);
            this.updateVisibility();
        }

        public hideDataset(ref: string): void {
            this.hiddenRefs.delete(ref);
            this.updateVisibility();
        }

        public toggleDataset(ref: string): void {
            if (this.hiddenRefs.has(ref)) {
                this.hiddenRefs.delete(ref);
            } else {
                this.hiddenRefs.add(ref);
            }

            this.updateVisibility();
        }

        /**
         * Check whether dataset should be displayed (doesn't necessary mean
         * that it's visible -- rendered on chart)
         */
        public isDisplayedByRefs(refs: string[]): boolean {
            return refs.every(ref => this.existingRefs.has(ref));
        }

        /**
         * Check whether dataset should be visible on chart
         */
        public isVisibleByRefs(refs: string[]): boolean {
            return refs.every(ref => !this.hiddenRefs.has(ref));
        }

        public getColorByRef(ref: string): string {
            const candidates = this.chart.data.datasets.filter(d => d.refs.includes(ref));

            assert(candidates.length > 0, `Chart has no dataset with ref ${ref}`);

            const dataset = candidates.reduce((bestDataset, candidate) => (
                candidate.refs.length < bestDataset.refs.length
                    ? candidate
                    : bestDataset
            ));

            return dataset ? dataset.borderColor.toString() : "#00000000";
        }
    }

    return ObservableDatasetsMixin;
}

export interface ObservableDatasets {
    update(): void;
    showDataset(ref: string): void;
    hideDataset(ref: string): void;
    toggleDataset(ref: string): void;
    isVisibleByRefs(refs: string[]): boolean;
    isDisplayedByRefs(refs: string[]): boolean;
    getColorByRef(ref: string): string;
}

declare module "chart.js" {
    interface ChartDatasetProperties<TType extends ChartType, TData extends unknown[]> {
        refs: [string, ...string[]];
    }
}
