import {
    NgModule,
} from "@angular/core";

import {
    DateRangePickerDialogModule,
    DateRangePickerService,
} from "skCommon/dateRangePicker";

@NgModule({
    imports: [
        DateRangePickerDialogModule,
    ],
    exports: [
        DateRangePickerDialogModule,
    ],
    providers: [
        DateRangePickerService,
    ],
})
export class DateRangePickerModule {}
