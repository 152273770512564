import { Injectable, NgZone, Type } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import {
    ConfirmDialogComponent,
    ConfirmDialogData,
} from "skCommon/ui/dialog/confirmDialog.component";
import {
    FeaturePromptPayload,
    PromptDialogComponent,
    PromptFeatures,
} from "skCommon/ui/dialog/promptDialog.component";
import { DialogData } from "skCommon/ui/dialog/dialog.misc";

@Injectable({ providedIn: "root" })
export class DialogService {

    constructor(
        private ngZone: NgZone,
        private matDialog: MatDialog,
    ) { }

    /**
     * Display dialog with Yes / No buttons returning either true or false
     * respectively. If `nullOption` is provided, thrid button is displayed
     * which returns null when clicked. If `noIsPrimary` is set to true
     * `No` will be highlighted ad primary button.
     */
     public confirm(
        title: string,
        description?: string,
        nullOption?: string,
        noIsPrimary?: boolean,
        actionButtons?: {
            negative?: {
                text: string,
                color?: "primary" | "warn",
            },
            positive?: {
                text: string,
                color?: "primary" | "warn",
            },
        },
    ): Promise<boolean | null> {
        return this.ngZone.run(() => {
            let resolve!: (val: boolean | null) => void;
            const promise = new Promise<boolean | null>(
                localResolve => resolve = localResolve,
            );

            const dialog = this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData>(
                ConfirmDialogComponent,
                {
                    disableClose: true,
                    data: {
                        defer: {
                            resolve,
                        },
                        payload: {
                            title,
                            description,
                            nullOption,
                            noIsPrimary,
                            actionButtons,
                        },
                    },
                },
            );

            promise.finally(() => dialog.close());

            return promise;
        });
    }

    public prompt(
        title: string,
        description: string | undefined,
        features?: PromptFeatures,
    ): Promise<string | null> {
        return this.createPrompt<string | null, FeaturePromptPayload>(
            PromptDialogComponent,
            {
                title,
                description,
                features,
            },
        );
    }

    public createPrompt<R, O extends Object = {}, T = any>(
        component: Type<T>,
        payload: O,
    ): Promise<R | null> {
        return this.ngZone.run(() => {
            let resolve!: (val: R | null) => void;
            const promise = new Promise<R | null>(
                localResolve => resolve = localResolve,
            );
            let resolved = false;

            const dialog = this.matDialog.open<T, DialogData<R, O>>(
                component,
                {
                    data: {
                        defer: {
                            resolve,
                        },
                        payload,
                    },
                },
            );

            promise.finally(() => {
                dialog.close();
                resolved = true;
            });

            dialog.afterClosed().subscribe(() => {
                if (!resolved) {
                    resolve(null);
                }
            });

            return promise;
        });
    }
}
