import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, Optional } from "@angular/core";

import { APP_NAME } from "skCommon/angular/configuration";

@Component({
    selector: "sk-app-logo",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./appLogo.pug",
    styleUrls: ["./appLogo.scss"],
})
export class AppLogoComponent {

    /**
     * Application name to display
     */
    @Input()
    public name?: string;

    /**
     * Application name to display
     */
    @Input()
    public size?: "large" | "heading";

    @HostBinding("class.sk-logo-large")
    public get largeSizeClass(): boolean {
        return this.size === "large";
    }

    @HostBinding("class.sk-logo-heading")
    public get headingSizeClass(): boolean {
        return this.size === "heading";
    }

    constructor(
        @Inject(APP_NAME)
        @Optional()
        appName: string | null,
    ) {
        if (appName) {
            this.name = appName;
        }
    }
}
