import { Component, ChangeDetectionStrategy, Inject, Injector, ElementRef, Type, ViewChild, AfterViewInit, HostListener } from "@angular/core";
import { Map as MapboxMap, MapboxOptions, LngLatBoundsLike, LngLatLike } from "mapbox-gl";
import { makeObservable } from "mobx";

import { MapboxService } from "skCommon/map/mapbox/mapbox.service";
import { observableRef } from "skCommon/state/mobxUtils";
import { StyleType } from "skCommon/map/mapbox/mapConfig";
import { patchMapboxForAngular } from "skCommon/map/mapbox/angularMapbox";
import { Logger } from "skCommon/utils/logger";
import { extendError } from "skCommon/core/error";
import { exists } from "skCommon/utils/types";
import { Bbox } from "skCommon/utils/geometry";
import { expandBbox } from "skCommon/utils/projection";

import { LayoutComponent, LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { MapPluginDef, MapPlugin, MAP_PLUGIN_DEF_TOKEN, injectPlugin } from "skInsights/modules/maps/plugin";

export const MapComponentType: MapComponentType = "maps/map";
export type MapComponentType = "maps/map";

/**
 * Dashboard component which displays empty mapbox map. Map can then be filled
 * by specifying various map plugins.
 */
@Component({
    selector: "sk-maps-map",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./map.pug",
})
export class MapComponent extends LayoutComponent<MapComponentDef>
        implements AfterViewInit {

    @observableRef
    public plugins: MapPluginRef[] = [];

    @observableRef
    public children: RenderableComponent[] = [];

    @ViewChild("container")
    public containerRef!: ElementRef<HTMLDivElement>;

    constructor(
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        protected readonly def: MapComponentDef,
        private injector: Injector,
        private mapboxService: MapboxService,
        private logger: Logger,
    ) {
        super();

        makeObservable(this);
    }

    public async ngAfterViewInit(): Promise<void> {
        patchMapboxForAngular();

        const map = await this.mapboxService.createMap({
            style: StyleType.Monochrome,
            ...this.def.options,
            container: this.containerRef.nativeElement,
        });

        await this.mapboxService.waitForInit(map);

        await this.initPlugins(map);
        this.updateMap(map);
        this.initPluginComponents();
        this.runRender();
    }

    @HostListener("wheel", ["$event"])
    public onMouseWheel(e: WheelEvent): void {
        // Do not scroll when zooming
        e.stopPropagation();
    }

    /**
     * Merge map options provided by created plugins and apply them to the map
     * instance.
     */
    private updateMap(map: MapboxMap): void {
        const options = this.plugins.map(({ plugin }) => plugin.mapOptions)
            .filter(exists);

        const extents = options.map(o => o.extent).filter(exists);
        const zooms = options.map(o => o.zoom).filter(exists);
        const minMaxZoom = zooms.length
            ? Math.min(...options.map(o => o.zoom).filter(exists))
            : null;

        if (extents.length) {
            const bbox = Bbox.expand(extents.map(b => Bbox.fromGeoJson(b)));
            const bounds = map.getContainer().getBoundingClientRect();
            const ratio = bounds.width / bounds.height;

            if (typeof minMaxZoom === "number") {
                map.setZoom(minMaxZoom);
                map.setCenter(bbox.center as LngLatLike);
            } else {
                const scaled = expandBbox(bbox, ratio, 1.3);

                map.fitBounds(scaled.toGeoJson() as LngLatBoundsLike, {
                    animate: false,
                });
            }
        }
    }

    /**
     * Instantiate all plugins in the map definition and initiate them.
     */
    private async initPlugins(map: MapboxMap): Promise<void> {
        const defs = (this.def.plugins || []);

        this.plugins = await Promise.all(
            defs.map(async pluginDef => this.initPlugin(map, pluginDef),
        ));
    }

    /**
     * Create angular components for all created map plugins which provide one
     * and render them in the map overlay container.
     */
    private initPluginComponents(): void {
        this.children = this.plugins
            .filter(({ plugin }) => plugin.component)
            .map(({ plugin, injector }) => ({
                component: plugin.component!,
                injector,
            }));
    }

    /**
     * Instantiate plugin "service" with given context map and plugin definition
     * a run its init method when defined.
     */
    private async initPlugin(map: MapboxMap, def: MapPluginDef): Promise<MapPluginRef> {
        const Plugin = this.injector.get<Type<MapPlugin>>(injectPlugin(def.name));
        const pluginInjector = Injector.create({
            parent: this.injector,
            providers: [
                {
                    provide: Plugin,
                    useClass: Plugin,
                },
                {
                    provide: MAP_PLUGIN_DEF_TOKEN,
                    useValue: def,
                },
                {
                    provide: MapboxMap,
                    useValue: map,
                },
            ],
        });

        const instance = pluginInjector.get(Plugin);

        if (instance.init) {
            await instance.init();
        }

        return {
            plugin: pluginInjector.get(Plugin),
            injector: pluginInjector,
            def: def,
        };
    }

    /**
     * Render all plugins
     */
    private runRender(): void {
        this.plugins.forEach(async ({ plugin, def }, i) => {
            if (plugin.render) {
                try {
                    await plugin.render();
                } catch (e) {
                    this.logger.error(extendError(
                        e,
                        `[maps/map][${i}:${def.name}] `,
                    ));
                }
            }
        });
    }
}

/**
 * Basic empty map component whose functionality may be extended by given plugins.
 */
interface MapComponentDef {
    component: MapComponentType;
    /**
     * Map instance settings
     *
     * @see https://docs.mapbox.com/mapbox-gl-js/api/map/
     */
    options?: MapboxOptions;
    /**
     * @fixme plugins not documented
     */
    plugins?: MapPluginDef[];
}

interface MapPluginRef {
    plugin: MapPlugin;
    injector: Injector;
    def: MapPluginDef;
}

interface RenderableComponent {
    injector: Injector;
    component: Type<any>;
}
