import { NgModule } from "@angular/core";

import { SharedModule } from "skCommon/angular/shared.module";
import { AutorunModule } from "skCommon/angular/state/autorun.module";

import { ZoomButtonComponent } from "skInsights/partials/zoomButton/zoomButton.component";

@NgModule({
    imports: [
        SharedModule,
        AutorunModule,
    ],
    declarations: [
        ZoomButtonComponent,
    ],
    exports: [
        ZoomButtonComponent,
    ],
})
export class ZoomButtonModule { }
