import { NgModule } from "@angular/core";

import { AppLogoComponent } from "skCommon/angular/partials/appLogo/appLogo.component";
import { SharedModule } from "skCommon/angular/shared.module";

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        AppLogoComponent,
    ],
    exports: [
        AppLogoComponent,
    ],
})
export class AppLogoModule { }
