import { Directive, ElementRef, HostBinding, ViewChild } from "@angular/core";
import { makeObservable } from "mobx";
import { combineLatest, delay, from, map, Observable, of, shareReplay, switchMap } from "rxjs";
import { toStream } from "mobx-utils";

import { observableRef } from "skCommon/state/mobxUtils";
import { ComponentDef } from "skCommon/insights/dashboard";

import { LayoutComponent } from "skInsights/framework/abstract/layoutComponent";
import { LayoutButton, DashboardButton } from "skInsights/partials/buttons/button";
import { CardService } from "skInsights/framework/card/card.service";

/**
 * Base component for components which extend the /dashboard/_templates/card.pug
 * template
 */
@Directive()
export abstract class CardComponent<TDef extends CardComponentDef> extends LayoutComponent<TDef> {

    @ViewChild("theCardTitle", { read: ElementRef })
    public theCardTitleRef?: ElementRef<HTMLElement>;

    @ViewChild("theCardButtons")
    public theCardButtonsRef?: ElementRef<HTMLElement>;

    @ViewChild("theCardHeader")
    public theCardHeaderRef?: ElementRef<HTMLElement>;

    @ViewChild("theCard")
    public theCardRef!: ElementRef<HTMLElement>;

    public readonly allCardButtons$ = this.getAllCardButtons$();

    public readonly showCardButtons$ = this.getShowCardButtons$();

    /**
     * Always show buttons even when cardButtons are empty. May be needed if
     * custom content is provided via the pug block.
     */
    protected readonly alwaysShowCardButtons?: boolean;

    /**
     * Always show buttons in card's header in mat menu instead of directly in
     * header. If not true, the menu will only be used when viewport size
     * forces it.
     */
    protected readonly alwaysShowHeaderMenu?: boolean;

    /**
     * Show button which allows customer to expand the card to fullscreen
     */
    protected readonly expandable: boolean = false;

    protected get cardButtons(): DashboardButton[] {
        return [];
    }

    @observableRef
    private cardHeaderOverflows: boolean = false;

    private lastCardButtonsWidth?: number;

    public get cardButtonsLoading(): boolean {
        return false;
    }

    public get templateOptions(): TemplateOptions {
        return this.def.template || {};
    }

    public get cardTitle(): string | void {
        return this.templateOptions.title;
    }

    public get showHeaderMenu(): boolean {
        return this.alwaysShowHeaderMenu
            || this.cardHeaderOverflows;
    }

    public get cardExpanded(): boolean {
        return this.cardService.isExpanded;
    }

    /**
     * Ignore any margin on the card element when it's expanded
     */
    @HostBinding("style.margin")
    public get cardStyleOverride(): string | undefined {
        return this.cardExpanded ? "0 !important" : undefined;
    }

    constructor(private cardService: CardService) {
        super();
        makeObservable(this);
    }

    public ngAfterViewInit(): void {
        this.updateCardComponentLayout();
        this.observeTileAndUpdateLayout();
    }

    /**
     * Expand (or collapse) button that should be only displayed on desktop
     * version
     */
    private getExpandButtons$(): Observable<DashboardButton[]> {
        if (this.expandable && this.cardService.viewportService.greaterThan("sm")) {
            const expanded$ = from(toStream(() => this.cardService.isExpanded, true));

            return expanded$.pipe(
                map(expanded => [
                    expanded ? {
                        icon: "collapse",
                        text: "Collapse card",
                        onClick: () => this.cardService.collapse(),
                    } : {
                        icon: "expand",
                        text: "Expand card",
                        onClick: () => this.cardService.expand(),
                    },
                ]),
            );
        } else {
            return of([]);
        }
    }

    /**
     * Create observable containing all buttons. Additional buttons included
     * by the child class should be provided via the cardButtons overridable
     * getter.
     *
     * We need to delay the process as the button creation is dependant on
     * abstract properties.
     */
    private getAllCardButtons$(): Observable<DashboardButton[]> {
        const delay$ = of(null).pipe(delay(1));
        const allButtons$ = delay$.pipe(
            switchMap(() => combineLatest({
                docsButtons: this.fetchDocumentationButtons(),
                expandButtons: this.getExpandButtons$(),
                baseButtons: of([
                    ...this.cardButtons,
                    ...(this.templateOptions.buttons || []),
                ]),
            })),
            map(({ docsButtons, baseButtons, expandButtons }) => [
                ...expandButtons,
                ...baseButtons,
                ...docsButtons,
            ]),
            shareReplay(),
        );

        return allButtons$;
    }

    /**
     * Create observable indicating whether buttons should be displayed
     */
    private getShowCardButtons$(): Observable<boolean> {
        return combineLatest({
            alwaysShow: of(!!this.alwaysShowCardButtons),
            buttons: this.getAllCardButtons$(),
        }).pipe(
            map(({ alwaysShow, buttons }) => alwaysShow || buttons.length > 0),
            shareReplay(),
        );
    }

    private observeTileAndUpdateLayout(): void {
        if (!this.theCardHeaderRef) {
            return;
        }

        const observer = new ResizeObserver(() => this.updateCardComponentLayout());

        observer.observe(this.theCardHeaderRef.nativeElement);

        this.removeListeners.push(() => observer.disconnect());
    }

    private updateCardComponentLayout(): void {
        if (!this.theCardTitleRef || !this.theCardHeaderRef) {
            return;
        }

        const buttonsWidth = this.theCardButtonsRef
            ? this.theCardButtonsRef.nativeElement.offsetWidth
            : this.lastCardButtonsWidth;

        const titleWidth = this.theCardTitleRef.nativeElement.offsetWidth;
        const availableWidth = this.theCardHeaderRef.nativeElement.clientWidth;

        if (typeof buttonsWidth === "number") {
            this.lastCardButtonsWidth = buttonsWidth;
            this.cardHeaderOverflows = (titleWidth + buttonsWidth + 60) > availableWidth;
        }
    }

    private async fetchDocumentationButtons(): Promise<DashboardButton[]> {
        const docDef = this.def.documentation;

        if (typeof docDef === "string") {
            return [{
                icon: "news",
                text: "Open documentation",
                link: await this.cardService.dataRefService.interpolate(docDef),
            }];
        } else if (docDef) {
            return [{
                icon: "news",
                text: "Open documentation",
                onClick: () => {
                    this.cardService.popupService.openDialog(
                        this.cardService.injector,
                        docDef,
                    );
                },
            }];
        } else {
            return [];
        }
    }
}

export interface CardComponentDef extends
    ComponentDef,
    CardComponentProperties { }

export interface CardComponentProperties {
    /**
     * Generic style options applicable to any component displayed in a card
     */
    template?: TemplateOptions;
    /**
     * Documentation link used in header button. Component definition may also
     * be used, in which case given component is opened in new overlay.
     * @interpolated
     */
    documentation?: string | ComponentDef;
}

export interface TemplateOptions {
    /**
     * Title of the card the component is displayd in. If no title is set the
     * card's header is hidden.
     * @interpolated
     */
    title?: string;
    /**
     * Buttons to display above card's content (the actual position depends on
     * header's visibility). If the component provides its own buttons the
     * provided buttons will be displayed alongside them.
     *
     * @fixime generics not documented
     */
    buttons?: LayoutButton[];

}
