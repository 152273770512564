import { DataRef } from "skCommon/insights/dashboard";

export function parseDataRef(ref: DataRef): DataRefObject {
    const firstSlashPos = ref.indexOf("/");

    return {
        id: ref.slice(0, firstSlashPos),
        select: ref.slice(firstSlashPos + 1),
    };
}

interface DataRefObject {
    /**
     * ID of the source / mutation to query data from
     */
    id: string;
    /**
     * Select query to be used to get the data
     */
    select: string;
}
