import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import moment from "moment";
import { take, tap } from "rxjs/operators";

import { ChartContext } from "skCommon/chart/chartContext";
import { assert } from "skCommon/utils/assert";
import {
    DateRangePickerService,
    DateRangePickerPayload,
} from "skCommon/dateRangePicker";

import { SeriesDataPoint } from "skInsights/framework/data/structures";
import { DEFAULT_PERIOD, FULL_PERIOD, isRange, ZoomPeriod, ZoomPeriodDef } from "skInsights/partials/zoomButton/zoomPeriod";
/**
 * Button offering some pre-defined zoom options, which are then applied to
 * provided chart instance.
 */
@Component({
    selector: "sk-zoom-button",
    templateUrl: "./zoomButton.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomButtonComponent {

    private currentSellectionFromDatePicker: DateRangePickerPayload | undefined = undefined;

    @Input()
    public chart?: ChartContext;

    @Input()
    public default?: ZoomPeriodDef;

    @Input()
    public size?: number | string;

    public readonly zoomPeriods: readonly ZoomPeriod[] = [
        {
            label: "Last 6 months",
            period: [6, "months"],
        },
        {
            label: "Jan 1, 2020 - present",
            period: { min: new Date("2020-01-01T00:00:00Z") },
        },
        {
            label: "Jan 1, 2017 - present (All)",
            period: { min: new Date("2017-01-01T00:00:00Z") },
        },
    ];

    constructor(
        private readonly dateRangePickerService: DateRangePickerService,
    ) { }

    public get buttonStyle(): Partial<CSSStyleDeclaration> {
        if (!this.pxSize) {
            return {};
        }

        return {
            width: `${this.pxSize}px`,
            height: `${this.pxSize}px`,
        };
    }

    public get iconStyle(): Partial<CSSStyleDeclaration> {
        if (!this.pxSize) {
            return {};
        }

        return {
            width: `${this.pxSize / 2}px`,
            height: `${this.pxSize / 2}px`,
        };
    }

    private get pxSize(): number | undefined {
        if (this.size === undefined) {
            return undefined;
        } else {
            return typeof this.size === "number"
                ? this.size
                : parseInt(this.size);
        }
    }

    public selectCustomDateRange(): void {
        this.dateRangePickerService.dateRange$(
            this.currentSellectionFromDatePicker?.from,
            this.currentSellectionFromDatePicker?.to,
        ).pipe(
            tap((dateRange) => {
                if (dateRange) {
                    this.currentSellectionFromDatePicker = dateRange;

                    const zoomPeriod: ZoomPeriodDef = {
                        max: dateRange.to,
                        min: dateRange.from,
                    };

                    this.setZoomPeriod(zoomPeriod);
                }
            }),
            take(1),
        ).subscribe();
    }

    public resetZoomPeriod(): void {
        this.currentSellectionFromDatePicker = undefined;
        this.setZoomPeriod(DEFAULT_PERIOD);
    }

    public setZoomPeriod(period: ZoomPeriodDef): void {
        if (period === DEFAULT_PERIOD && this.default) {
            return this.setZoomPeriod(this.default);
        }

        assert(
            this.chart
            && this.chart.options.scales
            && this.chart.options.scales.x,
            "Chart is not correctly init'd",
        );

        const ranges = this.chart.chart.data.datasets
            .flatMap(dataset => [dataset.data[0], dataset.data.at(-1)])
            .map(p => +(p as SeriesDataPoint).x);
        const min = Math.min(...ranges);
        const max = Math.max(...ranges);

        switch (period) {
            case FULL_PERIOD:
            case DEFAULT_PERIOD:
                // Default apparently wasn't provided => use min/max.
                this.chart.options.scales.x.max = max;
                this.chart.options.scales.x.min = min;
                break;
            default:
                if (isRange(period)) {
                    this.chart.options.scales.x.min = +(period.min || min);
                    this.chart.options.scales.x.max = +(period.max || max);
                } else {
                    const newMin = +moment(max).subtract(period[0], period[1]);
                    this.chart.options.scales.x.min = newMin;
                    this.chart.options.scales.x.max = max;
                }
        }

        this.chart.update();
    }
}
