import { Type } from "@angular/core";
import { ToolSettings } from "@editorjs/editorjs";

import { EditorJsPlugin } from "skInsights/partials/blockEditor/editorJs";
import { StylishParagraphTool } from "skInsights/partials/blockEditor/plugins/stylishParagraph/stylishParagraphTool";

/**
 * Plugin which replaces default paragrph block with one, that supports style
 * options such as font-size
 */
export class StylishParagraphPlugin implements EditorJsPlugin {

    constructor() { }

    public getTools(): Record<"paragraph", ToolSettings> {
        return {
            paragraph: {
                class: StylishParagraphTool as Type<StylishParagraphTool>,
            },
        };
    }
}
