import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { lastValueFrom, map } from "rxjs";

import { adaptFirestoreDoc, Doc } from "skCommon/firebase/doc";
import { insightsDocuments } from "skCommon/insights/database";

import { InsightsDocument } from "skInsights/modules/documents/document";
import { DocumentLoaderService } from "skInsights/modules/documents/documentLoader.service";

/**
 * @see DocumentLoaderService
 */
@Injectable()
export class WebDocumentLoaderService extends DocumentLoaderService {

    constructor(
        private angularFirestore: AngularFirestore,
    ) {
        super();
    }

    public loadDocument(id: string): Promise<Doc<InsightsDocument>> {
        return lastValueFrom(
            this.angularFirestore
                .doc<InsightsDocument>(insightsDocuments(id))
                .get()
                .pipe(
                    map(doc => adaptFirestoreDoc<InsightsDocument>(doc)),
                ),
        );
    }
}
