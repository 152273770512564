import { SkError } from "skCommon/core/error";
import { getDefaultAuthentication } from "skCommon/core/authentication";
import { getCreditsClient } from "skCommon/credits/client";
import { API_CONFIG } from "skCommon/api/apiConfig";
import { getDefaultConfig } from "skCommon/api/config";
import { userClient, BackendUserInfo } from "skCommon/user/client";
import { generateChallenge } from "skCommon/utils/pkce";
import {
    SPACEKNOW_OAUTH,
    SpaceknowAuthenticator,
    UserAuthData,
    OAuthCodeData,
    OAuthTokenData,
    TokenData,
} from "skCommon/auth/authenticator";
import { getAuthClient, UserProfile, UserMetadata, SignupOptions } from "skCommon/auth/client";
import { CONFIG } from "skCommon/auth/oauthConfig";
import { GetTokenResponse, AuthProvider, OAuthUserInfo } from "skCommon/auth/oauthClient";

const PKCE_VERIFIER_KEY = "pkceChallenge";
const PKCE_REDIRECT_KEY = "pkceRedirect";

let defaultUser: User;

export class User {

    constructor() {
        getDefaultAuthentication().registerAuthenticator(
            SPACEKNOW_OAUTH,
            new SpaceknowAuthenticator(),
        );
    }

    public async passiveLogin({
        redirectUrl = location.href,
        provider,
    }: PassiveLoginOptions = {}) {
        const { challenge, verifier } = await generateChallenge();

        redirectUrl = redirectUrl.replace(/\?.+/, "");

        localStorage.setItem(PKCE_VERIFIER_KEY, verifier);
        localStorage.setItem(PKCE_REDIRECT_KEY, redirectUrl);

        const url = getAuthClient().getAuthorizeUrl({
            response_type: CONFIG.RESPONSE_TYPE.CODE,
            connection: provider,
            redirect_uri: redirectUrl,
            scope: CONFIG.SCOPE.PERMANENT,
            code_challenge_method: "S256",
            code_challenge: challenge,
        });

        location.href = url;
    }

    public async getToken({
        username,
        password,
        permanent = false,
    }: GetTokenOptions): Promise<UserAuthData> {
        const tokenResponse = await getAuthClient().getToken({
            username,
            password,
            scope: permanent ? CONFIG.SCOPE.PERMANENT : CONFIG.SCOPE.DEFAULT,
            grant_type: "password",
        });

        return this.storeTokenData(tokenResponse);
    }

    private storeTokenData(tokenData: GetTokenResponse): UserAuthData {
        const authData = this.makeAuthData({
            accessToken: tokenData.access_token,
            expiration: tokenData.expires_in,
            refreshToken: tokenData.refresh_token,
        });

        getDefaultAuthentication().setAuthenticationData(
            SPACEKNOW_OAUTH,
            authData,
        );

        return authData;
    }

    public async setAuthDataFromUrlIfAvailable(
        hashSearch: string,
    ): Promise<UserAuthData> {
        try {
            let authData = this.getAuthDataFromHash(hashSearch);

            if (!authData) {
                return;
            }

            if ("code" in authData && localStorage.getItem(PKCE_VERIFIER_KEY)) {
                const exchanged = await getAuthClient().getToken({
                    grant_type: "authorization_code",
                    code: authData.code,
                    code_verifier: localStorage.getItem(PKCE_VERIFIER_KEY),
                    redirect_uri: localStorage.getItem(PKCE_REDIRECT_KEY),
                });
                authData = {
                    accessToken: exchanged.access_token,
                    refreshToken: exchanged.refresh_token,
                    expiration: exchanged.expires_in,
                };
            }

            // construct user authentication data
            if ("accessToken" in authData) {
                const userAuthData = this.makeAuthData(authData);

                getDefaultAuthentication()
                    .setAuthenticationData(SPACEKNOW_OAUTH, userAuthData);

                return userAuthData;
            }
        } finally {
            localStorage.removeItem(PKCE_VERIFIER_KEY);
            localStorage.removeItem(PKCE_REDIRECT_KEY);
        }
    }

    public signup(options: SignupOptions) {
        return getAuthClient()
            .signup(options);
    }

    public resetPassword(email: string) {
        return getAuthClient().resetPassword(email);
    }

    /**
     * Combine user info and user's profile
     */
    public async fetchProfile(): Promise<FullUserProfile> {
        const ns = getDefaultConfig().getByHost(API_CONFIG.auth.data.oidcNamespace);
        const client = getAuthClient();
        const info = await client.userInfo();
        const profile = await client.getUser(info.sub);
        const backendProfile = await userClient.info();

        for (const [k, v] of Object.entries(info)) {
            if (k.includes(ns)) {
                info[k.replace(ns, "")] = v;
            }
        }

        return {
            ...info,
            ...profile,
            ...backendProfile,
        };
    }

    public updateMetadata(userId: string, data: UserMetadata): Promise<UserMetadata> {
        return getAuthClient().updateUserMetadata(userId, data);
    }

    public tryToLoadPermanentAuthData() {
        return getDefaultAuthentication()
            .getAuthenticationData(SPACEKNOW_OAUTH);
    }

    public async fetchCredits() {
        const resp = await getCreditsClient().getRemainingCredits();

        return resp.remainingCredit;
    }

    ///
    ///
    /// Private
    ///
    ///

    ///
    ///
    /// Social login related
    ///
    ///

    private getAuthDataFromHash(
        hash: string,
    ): OAuthCodeData | OAuthTokenData | void {
        if (!hash) {
            return;
        }

        const parsed = this.parseParametersFromHash(hash);

        if (parsed && "error" in parsed) {
            throw new AuthError(parsed.error, parsed.errorDescription);
        }

        return parsed as OAuthTokenData | OAuthCodeData;
    }

    private parseParametersFromHash(hash: string): TokenData | null {
        const regex = /[?&#]([^=&#]+)=([^&#]*)/g;
        const rawParams: OAuthHashData = {};

        let match: string[];

        while (match = regex.exec(hash)) {
            const value = match[2].replace(/%20/g, " ");

            rawParams[match[1]] = value;
        }

        return this.adaptRawTokens(rawParams);
    }

    private adaptRawTokens(rawParams: OAuthHashData): TokenData | null {
        if ("error" in rawParams) {
            return {
                error: rawParams.error,
                errorDescription: rawParams.error_description,
            };
        } else if ("access_token" in rawParams) {
            return {
                accessToken: rawParams.access_token,
                refreshToken: rawParams.refresh_token,
                expiration: parseInt(rawParams.expires_in),
            };
        } else if ("code" in rawParams) {
            return {
                code: rawParams.code,
                state: rawParams.state,
            };
        } else {
            return null;
        }
    }

    ///
    ///
    /// Permissions related
    ///
    ///

    private makeAuthData(authResult: OAuthTokenData): UserAuthData {
        return {
            loginTimestamp: Date.now(),
            expiryTimestamp: Date.now() + authResult.expiration * 1000,
            data: authResult,
        };
    }
}

///
///
/// Errors
///
///

export class UserError extends SkError {
    public static readonly ERR_INVALID_AUTH_DATA = "ERR_INVALID_AUTH_DATA";
    public static readonly ERR_INVALID_LINK = "ERR_INVALID_LINK";

    public static readonly OLD_AUTH_DATA = "OLD_AUTH_DATA";

    public static readonly MESSAGES = new Map(<[string, string][]>[
        [
            UserError.ERR_INVALID_AUTH_DATA,
            "Provided authentication data is invalid",
        ],
    ]);

    get dataToLog() {
        return {};
    }

    constructor(code: string) {
        super("UserError", code);
    }
}

defaultUser = new User();

export function getUser() {
    return defaultUser;
}

export class AuthError extends SkError {

    get dataToLog(): { detail: string } {
        return {
            detail: this.detail,
        };
    }

    constructor(code: string, public detail: string = null) {
        super("AuthError", code);
    }
}

///
///
/// Interfaces & Types
///
///

/**
 * Combination of profile stored on auth provider, token info and backend user
 * info.
 */
export type FullUserProfile = UserProfile & OAuthUserInfo & BackendUserInfo;

export interface PassiveLoginOptions {
    redirectUrl?: string;
    provider?: AuthProvider;
}

export interface GetTokenOptions {
    password: string;
    username: string;
    permanent?: boolean;
}

interface OAuthHashData {
    access_token?: string;
    id_token?: string;
    refresh_token?: string;
    error?: string;
    error_description?: string;
    expires_in?: string;
    code?: string;
    state?: string;
}
