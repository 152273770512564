import { Component, ChangeDetectionStrategy } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";

import { Logger } from "skCommon/utils/logger";

import { InsightsService } from "skInsights/insights.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";

const UPDATING = Symbol();

@Component({
    selector: "sk-external-sources",
    templateUrl: "./externalSources.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalSourcesComponent {

    constructor(
        private angularFireFunctions: AngularFireFunctions,
        private insightsService: InsightsService,
        private snackBarService: SnackBarService,
        private logger: Logger,
    ) { }

    public async update(): Promise<void> {
        try {
            this.insightsService.globalLoadingProcesses.add(UPDATING);
            const fn = this.angularFireFunctions.httpsCallable("forceUpdateExternalSources");

            await fn({}).toPromise();
        } catch (e) {
            this.logger.error(e, "Update external sources");
            this.snackBarService.notify(`Update failed: ${e.message}`);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(UPDATING);
        }
    }
}
