import {
    Directive,
    ViewContainerRef,
    TemplateRef,
    OnInit,
    OnDestroy,
    EmbeddedViewRef,
    NgZone,
} from "@angular/core";
import { autorun } from "mobx";

import { Logger } from "skCommon/utils/logger";

/**
 * Inspired by the mobx-angular mobxAutorun directive which was dropped.
 * https://github.com/mobxjs/mobx-angular
 */
@Directive({ selector: "[skAutorun]" })
export class AutorunDirective implements OnInit, OnDestroy {

    protected dispose: () => void;

    protected view: EmbeddedViewRef<any>;

    constructor(
        protected templateRef: TemplateRef<any>,
        protected viewContainer: ViewContainerRef,
        protected zone: NgZone,
        private logger: Logger,
    ) { }

    public ngOnInit(): void {
        this.view = this.viewContainer.createEmbeddedView(this.templateRef);

        if (this.dispose) {
            this.dispose();
        }

        this.autoDetect(this.view);
    }

    public ngOnDestroy() {
        if (this.dispose) {
            this.dispose();
        }
    }

    public autoDetect(view: EmbeddedViewRef<any>) {
        this.dispose = autorun(
            () => this.zone.run(() => {
                try {
                    view.markForCheck();
                    view.detectChanges();
                } catch (e) {
                    this.logger.error(e, "Change detection");
                }
            }),
        );
    }
}
