import { Operation, OperationDef } from "skInsights/framework/abstract/operation";
import { AnyDashboardData, SeriesDataPoint } from "skInsights/framework/data/structures";
import { transformSeries, assertSeriesLike } from "skInsights/framework/data/helpers";

type CumulativeRollingAverageOperationType = "statistics/cumulative-rolling-average";
const CumulativeRollingAverageOperationType: CumulativeRollingAverageOperationType = "statistics/cumulative-rolling-average";

export class CumulativeRollingAverageOperation
        extends Operation<CumulativeRollingAverageOperationDef> {

    public readonly type = CumulativeRollingAverageOperationType;

    public execute(
        _def: CumulativeRollingAverageOperationDef,
        data: AnyDashboardData,
    ): AnyDashboardData {
        assertSeriesLike(data);

        return transformSeries(data, ({ series }) => ({
            series: this.calculate(series),
        }));
    }

    private calculate(
        series: SeriesDataPoint[],
    ): SeriesDataPoint[] {
        const numOfAvgs = series.length;
        const avgs = new Array<SeriesDataPoint>(numOfAvgs);

        for (let i = 0; i < numOfAvgs; i++) {
            if (i === 0) {
                avgs[i] = { ...series[0] };
            } else {
                avgs[i] = {
                    x: series[i].x,
                    y: (series[i].y + i * avgs[i - 1].y) / (i + 1),
                };
            }
        }

        return avgs;
    }
}

/**
 * Pipe operation which computes simple rolling average.
 */
interface CumulativeRollingAverageOperationDef
        extends OperationDef<CumulativeRollingAverageOperationType> {
}
