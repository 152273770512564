import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { makeObservable } from "mobx";

import { computed, observableRef } from "skCommon/state/mobxUtils";

import { isLinkAction, isEventAction, DashboardButton, isFnAction, isMatMenuAction, ButtonDef, MatMenuAction } from "skInsights/partials/buttons/button";
import { DashboardEvents } from "skInsights/framework/dashboardEvents";
import { LinkService } from "skInsights/framework/link.service";

@Component({
    selector: "sk-dashboard-button",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./dashboardButton.pug",
})
export class DashboardButtonComponent {

    @Input()
    @observableRef
    public button!: DashboardButton;

    @Input()
    @observableRef
    public menu?: boolean;

    public get togglable(): boolean {
        return isFnAction(this.button)
            && !!this.button.toggled === this.button.toggled;
    }

    public get toggled(): boolean {
        return isFnAction(this.button)
            ? !!this.button.toggled
            : false;
    }

    @computed
    public get classes(): Record<string, boolean> {
        const extra = isFnAction(this.button) && this.button.classes
            ? this.button.classes
            : {};

        return {
            ["accent-" + this.button.accent]: !this.menuStyle && !!this.button.accent,
            "icon-button-toggle": this.togglable && this.iconStyle,
            "enabled": isFnAction(this.button) && !this.menuStyle && this.button.toggled || false,
            ...extra,
        };
    }

    public get textStyle(): boolean {
        return !this.menu && !this.button.icon;
    }

    public get iconStyle(): boolean {
        return !this.menu && !!this.button.icon;
    }

    public get menuStyle(): boolean {
        return !!this.menu;
    }

    public get matMenuActionButton(): ButtonDef<MatMenuAction> | undefined {
        return isMatMenuAction(this.button)
            ? this.button
            : undefined;
    }

    constructor(
        private dashboardEvents: DashboardEvents,
        private linkService: LinkService,
    ) {
        makeObservable(this);
    }

    public runAction(): void {
        if (isLinkAction(this.button)) {
            this.linkService.executeLink(this.button.link);
        } else if (isEventAction(this.button)) {
            this.dashboardEvents.emit(this.button.event);
        } else if (isFnAction(this.button)) {
            this.button.onClick();
        } else {
            throw new Error("Clicked button with an invalid definition");
        }
    }
}
