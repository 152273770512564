import { ClassProvider, InjectionToken, Type, ValueProvider } from "@angular/core";

import { PermissionLike } from "skCommon/utils/permissions";

/**
 * Token for providing static (always displayed without any variable content)
 * menu entries.
 */
export const NAV_ENTRIES_TOKEN = new InjectionToken<MainNavEntry>("Main nav entries");

/**
 * Token for providing dynamic menu entries via service.
 */
export const NAV_ENTRY_PROVIDERS_TOKEN
    = new InjectionToken<MainNavEntryProvider>("Nav entry providers");

export function provideNavEntry(entry: MainNavEntry): ValueProvider {
    return {
        provide: NAV_ENTRIES_TOKEN,
        useValue: entry,
        multi: true,
    };
}

export function provideMainNavEntryProvider(Clss: Type<any>): ClassProvider {
    return {
        provide: NAV_ENTRY_PROVIDERS_TOKEN,
        useClass: Clss,
        multi: true,
    };
}

export interface MainNavEntry extends NavEntry {
    icon: string;
    /**
     * Set to true if the button can be hidden into "more" menu
     */
    menu?: NavEntry[];
}

export interface NavEntry {
    label: string;
    neededPermissions?: PermissionLike[];
    link?: string | string[];
    onClick?: () => void;
}

export interface MainNavEntryProvider {
    navEntries: MainNavEntry[];
}
