import { Component, ChangeDetectionStrategy, HostBinding } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { makeObservable, observable } from "mobx";
import { filter } from "rxjs";

import { computed } from "skCommon/state/mobxUtils";
import { RouterStore } from "skCommon/angular/router.store";
import { Logger } from "skCommon/utils/logger";
import { RouterLoggerService } from "skCommon/angular/routerLogger.service";
import { Insights } from "skCommon/permissions";

import { UserService } from "skInsights/user/user.service";
import { InsightsService } from "skInsights/insights.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { MainNavEntry } from "skInsights/nav/nav";
import { NavService } from "skInsights/nav/nav.service";
import { TrackingService } from "skInsights/tracking/tracking.service";
import { DynamicNav, DynamicNavService } from "skInsights/framework/dynamicNav.service";

const LOADING = Symbol();

@Component({
    selector: "sk-insights",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./insights.pug",
    styleUrls: ["./insights.scss"],
})
export class InsightsComponent {

    @observable.ref
    public hideOverflow: boolean = false;

    public get loggedIn(): boolean {
        return this.userService.loggedIn;
    }

    public get profilePicUrl(): string | void {
        return this.userService.profile.picture;
    }

    public get appLoading(): boolean {
        return this.userService.userInitInProgress
            || this.routerStore.navigationInProgress
            || this.insightsService.globalLoadingProcesses.size > 0;
    }

    public get isAdmin(): boolean {
        return this.canExternalSources || this.canGrantAccess;
    }

    public get canExternalSources(): boolean {
        return this.userService.loggedIn
            && this.userService.permissions.has(Insights.Admin.ExternalSources);
    }

    public get canGrantAccess(): boolean {
        return this.userService.loggedIn
            && this.userService.permissions.has(Insights.Admin.GrantAccess);
    }

    @computed
    public get visibleNavEntries(): MainNavEntry[] {
        return this.navService.allNavEntries;
    }

    public get dynamicNav(): DynamicNav | undefined {
        return this.dynamicNavService.dynamicNav;
    }

    @HostBinding("style")
    public get overflowStyle(): Partial<CSSStyleDeclaration> {
        if (this.hideOverflow) {
            return {
                overflow: "hidden",
                height: "100vh",
            };
        } else {
            return {};
        }
    }

    constructor(
        private userService: UserService,
        private routerStore: RouterStore,
        private insightsService: InsightsService,
        private snackBarService: SnackBarService,
        private logger: Logger,
        private routerLoggerService: RouterLoggerService,
        private navService: NavService,
        private trackingService: TrackingService,
        private dynamicNavService: DynamicNavService,
        private router: Router,
    ) {
        makeObservable(this);

        this.userService.initUser();
        this.routerLoggerService.register();
        this.trackingService.init();

        this.routerStore.onError(e => {
            if (e.error.message.startsWith("Cannot match any routes")) {
                this.routerStore.navigate(["/"]);
                this.snackBarService.notify(`Page ${e.url} not found`, 7);
            } else {
                this.snackBarService.notify("Cannot open page: " + e.error.message, 15);
            }
        });

        this.observeRouteOverflow();
    }

    public logOut(): void {
        this.userService.logOut();
    }

    public async changePassword(): Promise<void> {
        try {
            this.insightsService.globalLoadingProcesses.add(LOADING);
            await this.userService.changePassword(this.userService.profile.email);
            this.snackBarService.notify("Please check your e-mail for instructions.", 20);
        } catch (e) {
            this.logger.error(e, "Change password failed");
        } finally {
            this.insightsService.globalLoadingProcesses.delete(LOADING);
        }
    }

    /**
     * Some routes (such as data explorer) never overflow the viewport, but
     * some of their child elements may have overflow: auto of their own. For
     * that to work, this root component needs to have overflow: hidden a set
     * height.
     *
     * So routes may provide a `hideOverflow: true` data which sets those
     * required styles on this component.
     */
    private observeRouteOverflow(): void {
        this.router.events.pipe(
            filter(e => e instanceof NavigationEnd),
        ).subscribe(() => {
            let route: ActivatedRoute = this.router.routerState.root;
            let hideOverflow: boolean = false;

            while (route.firstChild) {
                if (route.firstChild.snapshot.data.hideOverflow) {
                    hideOverflow = true;
                }

                route = route.firstChild;
            }

            this.hideOverflow = hideOverflow;
        });
    }
}
