import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AngularFireFunctions } from "@angular/fire/functions";
import { BehaviorSubject, firstValueFrom, map, Observable, switchMap } from "rxjs";

import { assert } from "skCommon/utils/assert";
import { RouterStore } from "skCommon/angular/router.store";
import { Doc } from "skCommon/firebase/doc";
import { DialogService } from "skCommon/ui/dialog/dialog.service";
import { copyToClipboard } from "skCommon/utils/dom";
import { Logger } from "skCommon/utils/logger";

import { DashboardService, ProductsInDocument } from "skInsights/dashboard/dashboard.service";
import { InsightsService } from "skInsights/insights.service";
import { DocumentService } from "skInsights/modules/documents/document.service";
import { Documents, DocumentsRoutes } from "skInsights/modules/documents/documents.routing";
import { getDocFileName } from "skInsights/utils/getDocFileName";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { InsightsDocument } from "skInsights/modules/documents/document";

const ALL = "All";
const NO_CATEGORY = "No category";

const SAVING = Symbol();

@Component({
    selector: "sk-documents-list",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./documentsList.pug",
})
export class DocumentsListComponent {

    public documents$ = this.documentService.documents$
        .pipe(
            map((docs) => docs.filter((doc) => !doc.removeDate)),
            map((docs) => docs.map((doc) => (
                {
                    ...doc,
                    category: !doc.category ? NO_CATEGORY : doc.category,
                }),
            )),
            map((docs) => docs.map((doc) => ({...doc, downloading: false}))),
        );

    public templates$ = this.documentService.templates$;

    public zipFiles$ = this.getZipFiles()
        .pipe(
            map(values => values.filter(value => value!=="export/").map(value => ({title: value}))),
        );
    public categories$ = this.documentService.categories$
        .pipe(
            map(categories => [ALL, NO_CATEGORY, ...categories]),
        );

    private selectedCategorySubject$ = new BehaviorSubject<string>(ALL);
    public selectedCategory$ = this.selectedCategorySubject$.asObservable();

    public filteredDocs$ = this.selectedCategory$.pipe(
        switchMap(category => this.documents$.pipe(
            map((docs) => docs.filter(doc => doc.category === category || category === ALL)))),
    );

    constructor(
        private documentService: DocumentService,
        private routerStore: RouterStore,
        private snackBarService: SnackBarService,
        private logger: Logger,
        private dialogService: DialogService,
        private angularFireFunctions: AngularFireFunctions,
        private dashboardService: DashboardService,
        private insightsService: InsightsService,
    ) { }

    public async createNewDoc(): Promise<void> {
        try {
            const newDocId = await this.documentService.saveDocument();
            this.routerStore.navigate([Documents, DocumentsRoutes.Edit, newDocId]);
        } catch (e) {
            this.logger.error(e, "Create new document");
            this.snackBarService.notify(e.message);
        }
    }

    public copyDocId(e: MouseEvent, doc: Doc<InsightsDocument>): false {
        copyToClipboard(doc.id);
        this.snackBarService.notify("Copied.", 2);

        e.stopPropagation();
        return false;
    }

    public onRemoveClick(e: MouseEvent, doc: Doc<InsightsDocument>): false {
        this.removeDoc(doc);
        e.stopPropagation();
        return false;
    }

    private async removeDoc( doc: Doc<InsightsDocument>): Promise<void> {
        const result = await this.dialogService.confirm(`Delete ${doc.title}`);
        if (result) {
            try {
                await this.documentService.setDocumentAsRemoved(doc.id);
                this.snackBarService.notify("Document was successfully removed");
            } catch (e) {
                this.logger.error(e, "removing document");
                this.snackBarService.notify(e.message);
            }
        }
    }

    public onDownloadClick(
        e: MouseEvent,
        doc: Doc<InsightsDocument> & {downloading: boolean},
    ): false {
        this.downloadDoc(doc);
        e.stopPropagation();
        return false;
    }

    private getZipFiles(): Observable<string[]> {
        console.log("start");
        const fn = this.angularFireFunctions.httpsCallable("downloadAllPdf");
        const files =  fn({prefix: "export/"});
        console.log("files", files);
        // files.subscribe(data => console.log("files2", data));
        return files;
    }

    private async downloadDoc(doc: Doc<InsightsDocument> & {downloading: boolean}): Promise<void> {
        const bucketName = "spaceknow-insights-documents";
        const fileName = getDocFileName(doc);

        try {
            doc.downloading = true;
            const fn = this.angularFireFunctions.httpsCallable("downloadDocPdf");
            const url = await fn({ bucketName, fileName}).toPromise();
            this.triggerDownload(url, fileName);
        } catch (e) {
            this.logger.error(e, "downloading pdf failed");
            this.snackBarService.notify(e.message);
        } finally {
            doc.downloading = false;
        }
    }

    private triggerDownload(url: string, fileName: string) {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        link.setAttribute("target", "new");
        document.body.appendChild(link);
        link.click();
    }

    public categoryChange(value: string) {
        this.selectedCategorySubject$.next(value);
    }

    public async downloadCategory() {
        const category = this.selectedCategorySubject$.getValue();
        const filesToExport = await firstValueFrom(this.documentService.documents$
            .pipe(
                map((docs) => docs.filter((doc) => !doc.removeDate)),
                map((docs) => docs.filter(doc => doc.category === category || category === ALL)),
                map((docs) => docs.map(doc => getDocFileName(doc))),
            ));
        assert(filesToExport, "No files to export");
        try {
            this.snackBarService.notify(
                `compressing ${filesToExport.length} files. This may take a while :)`);
            const fn = this.angularFireFunctions.httpsCallable("downloadAllPdf");
            const url = await fn({ files: filesToExport, category}).toPromise();
            this.triggerDownload(url, `${category}.zip`);
        } catch (e) {
            this.logger.error(e, "downloading zip failed");
            this.snackBarService.notify(e.message);
        }
    }

    public async updateDocumentsUsage() {
        this.saveDocumentUsage(await this.dashboardService.getDocumentsUsage());
    }

    private async saveDocumentUsage(dashboardUsage: ProductsInDocument[]) {
        try {
            this.insightsService.globalLoadingProcesses.add(SAVING);
            this.snackBarService.notify(`updating documents usage...`, 2);
            await this.documentService.updateDocumentsUsage(dashboardUsage);
        } catch (e) {
            this.logger.error(e, "Saving document usage");
            this.snackBarService.notify(`Could not save document usage: ${e.message}`);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(SAVING);
        }
    }

    public async downloadZip($event: MouseEvent, name: string) {
        const bucketName = "spaceknow-insights-documents";
        console.log("zip1", name);
        try {
            const fn = this.angularFireFunctions.httpsCallable("downloadDocPdf");
            const url = await fn({ bucketName, name}).toPromise();
            console.log("before trigger");
            this.triggerDownload(url, name);
        } catch (e) {
            this.logger.error(e, "downloading zip failed");
            this.snackBarService.notify(e.message);
        }
        $event.stopPropagation();
        return false;
    }
}
