import bbox from "@turf/bbox";
import { featureCollection, point, feature as geojsonFeature } from "@turf/helpers";
import { getCoord } from "@turf/invariant";
import { GeoJSONSource, Map as GlMap, MapDataEvent } from "mapbox-gl";
import { Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

import { generateUniqueId } from "skCommon/utils/uniqueId";

const ID_SEPARATOR = "|";

export class MarkerClusterer<T extends {}> {

    public readonly clusters$ = this.observeClusters$();

    private inputData: Map<string, Point<T>> = new Map();

    private sourceId = generateUniqueId();

    private setDataIterator: number = 0;

    private get source(): GeoJSONSource {
        return this.mapboxMap.getSource(this.sourceId) as GeoJSONSource;
    }

    constructor(private mapboxMap: GlMap, clusterRadius: number) {
        this.mapboxMap.addSource(this.sourceId, {
            type: "geojson",
            data: featureCollection([]),
            cluster: true,
            clusterRadius,
            clusterProperties: {
                ["clustererId" as keyof PointerProps]: [
                    "concat",
                    [
                        "concat",
                        ID_SEPARATOR,
                        ["get", "clustererId" as keyof PointerProps],
                    ],
                ],
            },
        });

        this.mapboxMap.addLayer({
            type: "circle",
            paint: {
                "circle-opacity": 0,
            },
            source: this.sourceId,
            id: this.sourceId,
        });
    }

    public async setData(features: Point<T>[]): Promise<void> {
        const prefix = `${this.setDataIterator++}$`;

        const withId = features.map((feature, i) => ({ id: prefix + i, feature }));

        this.inputData.clear();

        withId.forEach(({ id, feature }) => this.inputData.set(id, feature));

        const pointers = withId.map<Pointer>(({ feature, id }) => geojsonFeature(
            feature.geometry,
            { clustererId: id },
        ));

        this.source.setData(featureCollection(pointers));
    }

    private observeClusters$(): Observable<Cluster<T>[]> {
        return this.observeClusterSource$().pipe(
            map(feats => this.dedupPointers(feats)),
            distinctUntilChanged(
                (a, b) => a === b,
                feats => feats.map(feat => feat.properties.clustererId).join("~"),
            ),
            map(feats => feats.map(feat => this.adaptPointer(feat))),
        );
    }

    private adaptPointer(feat: Pointer): Cluster<T> {
        let children: Point<T>[] = [];

        if ("cluster" in feat.properties) {
            const childrenIds = feat.properties.clustererId.split(ID_SEPARATOR);
            children = childrenIds
                .filter(id => this.inputData.has(id))
                .map(id => this.inputData.get(id)!);
        } else {
            children = [this.inputData.get(feat.properties.clustererId)];
        }

        return geojsonFeature(
            feat.geometry,
            {
                childrenProperties: children.map(c => c.properties),
                clusterBbox: children.length > 1
                    ? bbox(featureCollection(children))
                    : undefined,
                clusterSize: children.length,
            },
        );
    }

    private dedupPointers(feats: Pointer[]): Pointer[] {
        const records = feats.map(feat => [feat.properties.clustererId, feat] as const);

        return [...new Map(records).values()];
    }

    private observeClusterSource$(): Observable<Pointer[]> {
        return new Observable<Pointer[]>(suber => {
            const moveHandler = () => suber.next(this.queryVisiblePointers());
            const dataHandler = (e: MapDataEvent) => {
                if (
                    e.dataType === "source"
                    && e.sourceId === this.sourceId
                    && this.mapboxMap.isSourceLoaded(this.sourceId)
                ) {
                    suber.next(this.queryVisiblePointers());
                }
            };

            suber.next(this.queryVisiblePointers());

            this.mapboxMap.on("move", moveHandler);
            this.mapboxMap.on("data", dataHandler);

            return () => {
                this.mapboxMap.off("move", moveHandler);
                this.mapboxMap.off("data", dataHandler);
            };
        });
    }

    private queryVisiblePointers(): Pointer[] {
        const features = this.mapboxMap.querySourceFeatures(this.sourceId);
        // Convert mapbox feature instances to pure geojsons
        return features.map(feat => point(
            getCoord(feat as Point<any>),
            { ...(feat.properties || {}) },
        ) as Pointer);
    }

    // private waitForSource(sourceId: string): Promise<void> {
    //     if (this.map.isSourceLoaded(this.sourceId)) {
    //         return Promise.resolve();
    //     }

    //     return new Promise(res => {
    //         const onData = (e: MapDataEvent) => {
    //             if (e.dataType === "source" && e.sourceId === sourceId) {
    //                 const loaded = this.map.isSourceLoaded(this.sourceId);

    //                 if (loaded) {
    //                     res();
    //                     this.map.off("data", onData);
    //                 }
    //             }
    //         };

    //         this.map.on("data", onData);
    //     });
    // }
}

export type Cluster<T> = Point<ClusterProps<T>>;

type UnclusteredPointer = Point<PointerProps>;
type Pointer = UnclusteredPointer | Point<ClusterPointerProps>;

interface ClusterProps<P> {
    clusterSize: number;
    clusterBbox?: GeoJSON.BBox;
    childrenProperties: P[];
}

interface PointerProps {
    clustererId: string;
}

interface ClusterPointerProps {
    clustererId: string;
    // Properties added by mapboxgl when the feature is actually a cluster
    cluster: true;
    cluster_id: number;
    point_count: number;
    point_count_abbreviated: number;
}

type Point<T> = GeoJSON.Feature<GeoJSON.Point, T>;
