import { Component, ChangeDetectionStrategy, Inject, ElementRef, ViewChild, TemplateRef } from "@angular/core";
import { makeObservable } from "mobx";
import { fromEvent } from "rxjs";
import { throttleTime } from "rxjs/operators";

import { Logger } from "skCommon/utils/logger";
import { observableRef } from "skCommon/state/mobxUtils";
import { ComponentDef } from "skCommon/insights/dashboard";

import { LayoutComponent, LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { DynamicNavService } from "skInsights/framework/dynamicNav.service";

export const SlidesComponentType: SlidesComponentType = "core/slides";
export type SlidesComponentType = "core/slides";

@Component({
    selector: "sk-core-slides",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./slides.pug",
})
export class SlidesComponent extends LayoutComponent<SlidesComponentDef> {

    public children: ComponentDef[];

    public navButtons: (string | void)[];

    @observableRef
    public visible: number = 0;

    public slideHeight: number = this.elementRef.nativeElement.clientHeight;

    @ViewChild("nav", { read: TemplateRef })
    public navRef?: TemplateRef<any>;

    public get navEnabled(): boolean {
        return !!this.def.navigation;
    }

    constructor(
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        protected readonly def: SlidesComponentDef,
        private dynamicNavService: DynamicNavService,
        private logger: Logger,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        super();

        makeObservable(this);

        this.children = this.def.slides
            .map(slide => "component" in slide ? slide : slide.content);
        this.navButtons = this.def.slides
            .map(slide => "component" in slide ? void 0 : slide.title);

        const scrollSub = fromEvent(this.elementRef.nativeElement, "scroll").pipe(
            throttleTime(150, undefined, { leading: false, trailing: true }),
        ).subscribe(() => {
            this.updateVisibleSlide();
        });

        const observer = new ResizeObserver(
            () => this.slideHeight = this.elementRef.nativeElement.clientHeight,
        );
        observer.observe(this.elementRef.nativeElement);

        this.addSubscription(scrollSub);
    }

    public ngAfterViewInit(): void {
        if (this.navRef) {
            this.onDestroy(this.dynamicNavService.renderNav({ template: this.navRef }));
        }
    }

    public goToSlide(i: number): void {
        this.elementRef.nativeElement.scrollTo({
            top: i * this.slideHeight,
            behavior: "smooth",
        });
    }

    private updateVisibleSlide(): void {
        const top = this.elementRef.nativeElement.scrollTop;

        const newVisible = Math.floor(top / this.slideHeight);

        if (this.visible !== newVisible) {
            this.visible = newVisible;
            this.logger.info(`Scrolled to slide ${this.visible}`);
        }
    }
}

/**
 * Container component displaying child components on separate "slides"
 * accesible by scrolling.
 */
export interface SlidesComponentDef {
    component: SlidesComponentType;
    /**
     * Slide definition containing the child component and additional details.
     */
    slides: (ComponentDef | SlideDefinition)[];
    /**
     * Show navigation buttons for each slide in the menu
     *
     * @default false
     */
    navigation?: boolean;
}

export interface SlideDefinition {
    /**
     * Title used in the nav when enabled
     */
    title?: string;
    /**
     * Component to display on the slide
     */
    content: ComponentDef;
}
