export function serializeEntry(key: string, value: any) {
    if (!key) {
        return value;
    }

    const ctor = value ? value.constructor : undefined;
    if ( ctor && ctor !== Object && typeof value === "object" ) {
        if (value instanceof Array) {
            return value;
        }

        if (value instanceof Map || value instanceof Set) {
            return value.entries();
        }

        if (typeof value.toJSON === "function") {
            return value.toJSON();
        }

        return value.toString();
    } else {
        return value;
    }
}
