export enum Svg {
    AddPolygon = "add-polygon",
    AirDefense = "air-defense",
    AircraftFighter = "aircraft-fighter",
    AirplaneLanding = "airplane-landing",
    Airplane = "airplane",
    Api = "api",
    AppendFrame = "append_frame",
    ArrowDown = "arrow-down",
    ArrowLeft = "arrow-left",
    ArrowRight = "arrow-right",
    ArrowUp = "arrow-up",
    AssignmentInd = "assignment_ind",
    Back = "back",
    Bagr = "bagr",
    Baresoil = "baresoil",
    Blob = "blob",
    Boat = "boat",
    Bolt = "bolt",
    Box = "box",
    Bricks = "bricks",
    Brief = "brief",
    Buildings = "buildings",
    Calendar = "calendar",
    Camera = "camera",
    Cancel = "cancel",
    Car = "car",
    Card = "card",
    CdNlD = "cd-nl-d",
    CdNlM = "cd-nl-m",
    CdPl = "cd-pl",
    CdS2 = "cd-s2",
    CdSarA = "cd-sar-a",
    CdSarD = "cd-sar-d",
    CheckmarkCircle = "checkmark-circle",
    Checkmark = "checkmark",
    ChevronLeft = "chevron-left",
    ChevronRight = "chevron-right",
    Chevron = "chevron",
    CircleCheckmark = "circle-checkmark",
    Circle = "circle",
    Close = "close",
    CloudDownload = "cloud-download",
    Cloud = "cloud",
    Coal = "coal",
    ColdStorage = "cold-storage",
    Collapse = "collapse",
    ComboChart = "combo-chart",
    Company = "company",
    Constellation = "constellation",
    ConstructionEarly = "construction-early",
    ConstructionMedium = "construction-medium",
    Copy = "copy",
    CopyLink = "copy_link",
    CopyLinkGrey = "copy_link_grey",
    Cow = "cow",
    Crane = "crane",
    Credits = "credits",
    Crosshair = "crosshair",
    Csv = "csv",
    Deadwood = "deadwood",
    Desktop = "desktop",
    DestructionHeavy = "destruction-heavy",
    DestructionMedium = "destruction-medium",
    DestructionRoad = "destruction-road",
    DiffSarA = "diff-sar-a",
    DiffSarD = "diff-sar-d",
    Download = "download",
    Downloadable = "downloadable",
    DrawPolygon = "draw-polygon",
    Dropdown = "dropdown",
    Edit = "edit",
    Exit = "exit",
    ExpandMore = "expand-more",
    Expand = "expand",
    Facebook = "facebook",
    FieldDown = "field-down",
    FieldUp = "field-up",
    Field = "field",
    FillGaps = "fill-gaps",
    Fill = "fill",
    FilledChevronBottom = "filled-chevron-bottom",
    FilledChevronRight = "filled-chevron-right",
    Filter = "filter",
    Filters = "filters",
    FirstPage = "first-page",
    FlameBlob = "flame-blob",
    Flame = "flame",
    Form = "form",
    FormatAlignLeft = "format_align_left",
    ForwardFill = "forward-fill",
    Forward = "forward",
    FromServer = "from_server",
    GeoJson = "geo-json",
    Google = "google",
    Graph = "graph",
    Help = "help",
    Hidden = "hidden",
    Hide = "hide",
    HighRes = "high-res",
    Highlight = "highlight",
    History = "history",
    Home = "home",
    Image = "image",
    Info = "info",
    IngestedExtent = "ingested-extent",
    IntersectionFull = "intersection-full",
    IntersectionHalf = "intersection-half",
    Iron = "iron",
    Land = "land",
    LastPage = "last-page",
    Layers = "layers",
    LeftArrow = "left-arrow",
    LineChart = "line-chart",
    Linkedin = "linkedin",
    Lithium = "lithium",
    LoadingArc = "loading_arc",
    Location = "location",
    Lock = "lock",
    LogData = "log_data",
    LogImage = "log_image",
    LogPolygon = "log_polygon",
    Logo = "logo",
    Mail = "mail",
    MapSearch = "map-search",
    MeasureCross = "measure-cross",
    Menu = "menu",
    Metal = "metal",
    MidRes = "mid-res",
    Minus = "minus",
    MissileLauncher = "missile-launcher",
    MoreVertical = "more-vertical",
    MoveVerticalLarge = "move-vertical-large",
    Move = "move",
    Multipolygon = "multipolygon",
    NewWindow = "new-window",
    News = "news",
    NightLight = "night-light",
    NotOilPad = "not-oil-pad",
    Notifications = "notifications",
    NotificationsOn = "notifications_on",
    OilPad = "oil-pad",
    OilTank = "oil-tank",
    OutlinedRectangleWithPoints = "outlined-rectangle-with-points",
    PartiallyIngested = "partially-ingested",
    Pdf = "pdf",
    Phone = "phone",
    Pin = "pin",
    Plus = "plus",
    Polygon = "polygon",
    Pool = "pool",
    Publish = "publish",
    QuestionBubble = "question-bubble",
    Radar = "radar",
    Refresh = "refresh",
    RemoveFrame = "remove_frame",
    Reset = "reset",
    RightArrow = "right-arrow",
    Road = "road",
    Ruler = "ruler",
    SarChangeDirectional = "sar-change-directional",
    SarChangeNegative = "sar-change-negative",
    SarChangePositive = "sar-change-positive",
    SarDiffNegative = "sar-diff-negative",
    SarDiffPositive = "sar-diff-positive",
    SatelliteLine = "satellite-line",
    SatelliteLookside = "satellite-lookside",
    SatelliteUplink = "satellite-uplink",
    Satellite = "satellite",
    Save = "save",
    SearchImage = "search-image",
    Search = "search",
    SearchImages = "search_images",
    Settings = "settings",
    Shape = "shape",
    Share = "share",
    Ship = "ship",
    SingleLink = "single_link",
    SmallMarker = "small-marker",
    Solar = "solar",
    Stack = "stack",
    Stock = "stock",
    Sun = "sun",
    Tank = "tank",
    Target = "target",
    TextFields = "text_fields",
    Time = "time",
    Touch = "touch",
    Trash = "trash",
    Tree = "tree",
    TruckWithMissile = "truck-with-missile",
    Truck = "truck",
    Twitter = "twitter",
    TwoPointLine = "two-point-line",
    Undo = "undo",
    Upload = "upload",
    User = "user",
    Vegetation = "vegetation",
    Visible = "visible",
    Warning = "warning",
    Water = "water",
    WoodPile = "wood-pile",
    ZoomIn = "zoom_in",
    ZoomOut = "zoom_out",
}
