import { assert } from "skCommon/utils/assert";

import { Operation, OperationDef } from "skInsights/framework/abstract/operation";
import { AnyDashboardData } from "skInsights/framework/data/structures";
import { transformSeries, assertSeriesLike } from "skInsights/framework/data/helpers";

type PickOperationType = "core/pick";
const PickOperationType: PickOperationType = "core/pick";

/**
 * Pick single value form series converting series into single-value type.
 */
export class PickOperation extends Operation<PickOperationDef> {

    public readonly type = PickOperationType;

    public execute(def: PickOperationDef, data: AnyDashboardData): AnyDashboardData {
        assertSeriesLike(data);
        assert(def.index, "Pick operation has no index specified");

        const res = transformSeries(data, ({ series }) => {
            const point = series[def.index === "last" ? series.length - 1 : 0];

            return {
                value: point.y,
                meta: {
                    date: point.x,
                },
            };
        });

        return res;
    }
}

/**
 * Convert Series data (or set of Series) into SingleValue data type by picking
 * single datapoint.
 */
export interface PickOperationDef extends OperationDef<PickOperationType> {
    /**
     * Which data point should be selected
     */
    index: "last" | "first";
}
