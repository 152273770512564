import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { computed, runInAction, makeObservable } from "mobx";
import { toStream } from "mobx-utils";
import {
    BehaviorSubject,
    EMPTY,
    from,
    Observable,
    of,
    Subscription,
} from "rxjs";
import { mapTo, mergeMap, switchMap, tap, map } from "rxjs/operators";
import { OutputData } from "@editorjs/editorjs";

import { observableRef } from "skCommon/state/mobxUtils";
import { Logger } from "skCommon/utils/logger";
import { assert } from "skCommon/utils/assert";
import { BaseComponent } from "skCommon/angular/base/base.component";

import { DocumentService } from "skInsights/modules/documents/document.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { InsightsDocumentModel } from "skInsights/modules/documents/manager/insightsDocumentModel";
import { BlockEditorModel } from "skInsights/partials/blockEditor/blockEditorModel";
import { EditorJsPlugin } from "skInsights/partials/blockEditor/editorJs";
import { TemplateSectionPlugin } from "skInsights/partials/blockEditor/plugins/templateSection/templateSectionPlugin";
import { InsightsDocument } from "skInsights/modules/documents/document";
import { emptyOutputData } from "skInsights/partials/blockEditor/outputData";
import { isTemplateSection } from "skInsights/partials/blockEditor/plugins/templateSection/templateSection";

@Component({
    selector: "sk-documents-edit",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./documentsEdit.pug",
})
export class DocumentsEditComponent extends BaseComponent {

    private filteredOptionsSubject = new BehaviorSubject<string>("");

    public filteredOptions: Observable<string[]> = this.filteredOptionsSubject
        .asObservable()
        .pipe(
            map(value => this._filter(value || "")),
        );

    public options: string[] = [];

    public categories$ = this.documentService.categories$;

    @observableRef
    public model?: InsightsDocumentModel;

    public templates$ = this.documentService.templates$;

    @observableRef
    public updatingLayout: boolean = false;

    @computed
    public get editables(): EditablePartial[] {
        return this.getDocumentEditables();
    }

    @computed
    public get editorPlugins(): EditorJsPlugin[] {
        return this.model && this.model.template
            ? [new TemplateSectionPlugin()]
            : [];
    }

    public get extendsWarning(): string | null {
        return !this.editables.slice(1).every(e => e.editor.empty)
            ? "You will lose content if the newly selected template contains lower number of sections"
            : null;
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private documentService: DocumentService,
        private snackBarService: SnackBarService,
        private logger: Logger,
    ) {
        super();

        makeObservable(this);
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.activatedRoute.paramMap.subscribe(params => {
            const idParam = params.get("id");

            if (idParam) {
                this.loadDocument(idParam);
            } else {
                this.snackBarService.notify("Missing document ID");
            }
        });

        this.addSubscription(
            this.categories$.subscribe(categories =>
                this.options = categories.filter(category =>
                    typeof category === "string") as string[]),
        );

        this.addSubscription(this.observeExtendedTemplate());
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }

    public async saveChanges(): Promise<void> {
        assert(this.model && this.editables, "No document loaded");

        try {
            await this.updateModelFromEditors();
            await this.documentService.saveDocument(this.model.serialize());
            this.snackBarService.notify("Saved");
        } catch (e) {
            this.logger.error(e, "Save changes in document");
            this.snackBarService.notify(e.message);
        }
    }

    private async loadDocument(id: string): Promise<void> {
        try {
            const doc = await this.documentService.loadDocument(id);
            this.model = new InsightsDocumentModel(doc);
        } catch (e) {
            this.snackBarService.notify("Could not load document");
            this.logger.error(e, "Load document");
        }
    }

    private observeExtendedTemplate(): Subscription {
        const model$ = from(toStream(() => this.model, true));

        return model$.pipe(
            switchMap(model => this.watchModelExtends$(model).pipe(
                switchMap(changedModel => of(null).pipe(
                    tap(() => this.updatingLayout = true),
                    mergeMap(() => this.updateModelFromEditors()),
                    mergeMap(() => this.updateAfterExtendsChanged(changedModel)),
                )),
                tap(() => this.updatingLayout = false),
            )),
        ).subscribe();
    }

    /**
     * Check model's new extends value, and arrange content accordingly
     * (e.g. move content from section into base content when document doesn't
     * extend template anymore)
     */
    private updateAfterExtendsChanged(model: InsightsDocumentModel): Observable<null> {
        return of(model.extends).pipe(
            mergeMap(id => id ? this.documentService.loadDocument(id) : of(null)),
            tap(templateDoc => runInAction(() => {
                if (!templateDoc) {
                    if (model.content) {
                        model.content = model.content;
                    } else if (model.sections) {
                        const populatedSection = Object.values(model.sections)
                            .find(sec => sec.blocks.length > 0);

                        // TODO: what to do about the rest of the sections?!
                        model.content = populatedSection || emptyOutputData();
                    }

                    model.sections = null;
                } else {
                    const sections = this.findSections(templateDoc);

                    if (model.content) {
                        const newEntries = sections.map(s => [s, emptyOutputData()]);

                        if (newEntries.length) {
                            // Put the existing content in the first section
                            newEntries[0][1] = model.content;
                        }

                        model.sections = Object.fromEntries(newEntries);
                    } else if (model.sections) {
                        const secSet = new Set(sections);
                        const populatedSections = Object.entries(model.sections)
                            .filter(([, sec]) => sec.blocks.length > 0);

                        const matchedMap = new Map(
                            populatedSections.filter(([name]) => secSet.has(name)),
                        );

                        const unmatchedList = populatedSections
                            .filter(([name]) => !secSet.has(name))
                            .map(([, content]) => content);

                        model.sections = Object.fromEntries(
                            sections.map(sec => [
                                sec,
                                matchedMap.has(sec)
                                    ? matchedMap.get(sec)!
                                    : unmatchedList.shift() || emptyOutputData(),
                            ]),
                        );
                    }

                    model.content = null;
                }
            })),
            mapTo(null),
        );
    }

    /**
     * Check document's content / sections and find all sections blocks, which
     * user can populate.
     */
    private findSections(doc: InsightsDocument): string[] {
        const contents: OutputData[] = [];

        if (doc.content) {
            contents.push(doc.content);
        } else if (doc.sections) {
            contents.push(...Object.values(doc.sections));
        }

        return contents.flatMap(output => output.blocks
            .filter(isTemplateSection),
        ).map(sectionBlock => sectionBlock.data.name);
    }

    /**
     * Watch model, when available, and emit it whenever exteds changes.
     */
    private watchModelExtends$(model?: InsightsDocumentModel): Observable<InsightsDocumentModel> {
        return model
            ? from(toStream(() => model.extends)).pipe(mapTo(model))
            : EMPTY;
    }

    private getDocumentEditables(): EditablePartial[] {
        if (!this.model) {
            return [];
        } else if (this.model.sections) {
            return Object.entries(this.model.sections).map(([title, content]) => ({
                title,
                editor: new BlockEditorModel(content),
            }));
        } else if (this.model.content) {
            return [{ editor: new BlockEditorModel(this.model.content) }];
        } else {
            return [{ editor: new BlockEditorModel() }];
        }
    }

    private async updateModelFromEditors(): Promise<void> {
        assert(this.editables, "Editor not initialized");
        assert(this.model, "Model not initialized");

        if (this.editables.length === 1 && !this.editables[0].title) {
            this.model.content = await this.editables[0].editor.getOutputData();
            this.model.sections = null;
        } else {
            const partialEntries = await Promise.all(this.editables.map(async e => [
                e.title,
                await e.editor.getOutputData(),
            ]));
            this.model.sections = Object.fromEntries(partialEntries);
        }
    }

    public filterOptions(text: string) {
        this.filteredOptionsSubject.next(text);
    }
}

interface EditablePartial {
    title?: string;
    editor: BlockEditorModel;
}
