import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core";
import {
    MatSelectChange,
} from "@angular/material/select";
import {
    DateTime,
} from "luxon";

@Component({
    selector: "sk-date-picker-calendar-header",
    templateUrl: "./datePickerCalendarHeader.pug",
    styleUrls: ["./datePickerCalendarHeader.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerCalendarHeaderComponent {

    @Input()
    public activeDateTime: DateTime = DateTime.now();
    @Input()
    public calendarStartDateTime: DateTime = DateTime.now();
    @Input()
    public calendarEndDateTime: DateTime = DateTime.now();

    @Output()
    public activatedDateTimeChange = new EventEmitter<DateTime>();

    public get minMonth(): number {
        return this.calendarStartDateTime.month;
    }

    public get maxMonth(): number {
        return this.calendarEndDateTime.month;
    }

    public get minYear(): number {
        return this.calendarStartDateTime.year;
    }

    public get maxYear(): number {
        return this.calendarEndDateTime.year;
    }

    public get currentlyShownMonth(): string {
        return this.  activeDateTime.monthLong;
    }

    public get selectableYears(): number[] {
        const startYear = this.calendarStartDateTime.year;
        const endYear = this.calendarEndDateTime.year;
        const calendarDiffYears = endYear - startYear + 1;

        return Array.from(Array(calendarDiffYears).keys())
            .map((year: number) => {
                return this.calendarStartDateTime.plus({years: year}).year;
            });
    }

    public activateSelectedYear(selectedYear: MatSelectChange): void {
        const activatedDateTime = this.activeDateTime.set({year: selectedYear.value});

        this.activatedDateTimeChange.emit(activatedDateTime);
    }

    public activatePreviousMonth(): void {
        const dateTimeToBeActivated = this.activeDateTime.minus({months: 1});
        this.activateDateTime(dateTimeToBeActivated);
    }

    public activateNextMonth(): void {
        const dateTimeToBeActivated = this.activeDateTime.plus({months: 1});
        this.activateDateTime(dateTimeToBeActivated);
    }

    private activateDateTime(dateTime: DateTime): void {
        const dateTimeIsWithinBoundaries = this.yearIsWithinBoundaries(dateTime.year);

        const dateTimeIsNotAtBoundary = (
            dateTime.year !== this.minYear
            && dateTime.year !== this.maxYear
        );

        const dateTimeIsAboveMin = (
            dateTime.year === this.minYear
            && dateTime.month >= this.minMonth
        );

        const dateTimeIsUnderMax = (
            dateTime.year === this.maxYear
            && dateTime.month <= this.maxMonth
        );

        const mothCanBeApplied = dateTimeIsWithinBoundaries
            && (
                    dateTimeIsNotAtBoundary
                    || dateTimeIsAboveMin
                    || dateTimeIsUnderMax
                );

        if (mothCanBeApplied) {
            this.activeDateTime = dateTime;
        }

        this.activatedDateTimeChange.emit(dateTime);
    }

    private yearIsWithinBoundaries(year: number): boolean {
        return (year >= this.minYear && year <= this.maxYear);
    }
}
