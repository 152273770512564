import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from "@angular/core";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import { ParagraphData } from "@editorjs/paragraph";
import { toStream } from "mobx-utils";
import { combineLatest, EMPTY, from, merge, Observable, ObservableInput, of, ReplaySubject, Subscription } from "rxjs";
import { filter, map, mergeMap, share, shareReplay, switchAll, switchMap, take, tap } from "rxjs/operators";
import { makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { observableRef } from "skCommon/state/mobxUtils";
import { assert } from "skCommon/utils/assert";
import { exists } from "skCommon/utils/types";

import { BlockEditorModel } from "skInsights/partials/blockEditor/blockEditorModel";
import { EditorJsPlugin, createEditorJs } from "skInsights/partials/blockEditor/editorJs";
import { ImageBlockPluginService } from "skInsights/partials/blockEditor/plugins/imageBlockPlugin.service";
import { StylishParagraphPlugin } from "skInsights/partials/blockEditor/plugins/stylishParagraph/stylishParagraphPlugin";
import { sanitizeOutputData } from "skInsights/partials/blockEditor/sanitize";

@Component({
    selector: "sk-block-editor",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./blockEditor.pug",
})
export class BlockEditorComponent extends BaseComponent {

    @ViewChild("editorContainer")
    public editorContainerRef?: ElementRef<HTMLElement>;

    @observableRef
    @Input()
    public plugins?: EditorJsPlugin[];

    @observableRef
    @Input()
    public model?: BlockEditorModel;

    @observableRef
    private editorRef?: EditorRef;

    constructor(private imageBlockPluginService: ImageBlockPluginService) {
        super();

        makeObservable(this);
    }

    public async ngAfterViewInit(): Promise<void> {
        this.addSubscription(this.observeInputs());
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.editorRef) {
            this.editorRef.editor.destroy();
        }
    }

    private observeInputs(): Subscription {
        const latestContentSubject = new ReplaySubject<ObservableInput<OutputData | undefined>>(1);

        const modelContentUpdating$ = from(toStream(() => this.model, true)).pipe(
            filter(exists),
            map(model => of(model.initialContent)),
            tap(content$ => latestContentSubject.next(content$)),
        );

        const latestContent$ = latestContentSubject.pipe(
            switchAll(),
            map(content => this.ensureNotEmptyContent(content)),
        );

        const editor$ = from(toStream(() => this.plugins, true)).pipe(
            map(plugs => plugs || []),
            tap(() => {
                if (this.editorRef) {
                    const outputData = this.editorRef.editor.save()
                        .then(data => sanitizeOutputData(data));
                    latestContentSubject.next(outputData);
                }
            }),
            tap(() => this.editorRef = undefined),
            switchMap(plugins => {
                const newEditor$ = this.createAndKeepEditor$(plugins).pipe(
                    share(),
                );

                const updating$ = combineLatest([newEditor$, latestContent$]).pipe(
                    mergeMap(([ed, content]) => {
                        content = content || { blocks: [] };
                        return ed.editor.render(content);
                    }, 1),
                    filter(() => false),
                ) as Observable<never>;

                return merge(newEditor$, updating$);
            }),
            tap(ref => this.editorRef = ref),
            shareReplay(1),
        );

        const currentEditorRef$ = from(toStream(() => this.editorRef, true)).pipe(
            filter(exists),
            take(1),
        );

        const saveRequests$ = from(toStream(() => this.model, true)).pipe(
            switchMap(model => model ? model.outputRequests$ : EMPTY),
            mergeMap(request => currentEditorRef$.pipe(
                map(ref => ({ editor: ref.editor, request })),
            )),
            tap(({ editor, request }) => request.callback(editor.save())),
        );

        return merge(
            modelContentUpdating$,
            editor$,
            saveRequests$,
        ).subscribe();
    }

    /**
     * Create and emit a new editor and keep it alive as long as there is a
     * subscriber.
     */
    private createAndKeepEditor$(plugins: EditorJsPlugin[]): Observable<EditorRef> {
        return new Observable(suber => {
            const refPromise = this.createEditor(plugins)
                .then(ref => (suber.next(ref), ref))
                .catch(err => (suber.error(err), null));

            return async () => {
                const ref = await refPromise;

                if (ref) {
                    this.destroyEditor(ref);
                }
            };
        });
    }

    private destroyEditor({ editor, holder }: EditorRef): void {
        editor.destroy();

        if (holder.parentElement) {
            holder.parentElement.removeChild(holder);
        }
    }

    private async createEditor(plugins: EditorJsPlugin[]): Promise<EditorRef> {
        assert(this.editorContainerRef, "Container doesn't exist");

        const holder = document.createElement("div");

        this.editorContainerRef.nativeElement.append(holder);

        const editor = createEditorJs({
            holder,
            inlineToolbar: true,
            onChange: () => this.updateModelEmpty(editor),
        }, [
            this.imageBlockPluginService,
            new StylishParagraphPlugin(),
            ...plugins,
        ]);

        await editor.isReady;

        return { holder, editor };
    }

    private updateModelEmpty(editor: EditorJS): void {
        // Apparently editorjs fires change events even when not initated...
        if (this.model && editor.blocks) {
            this.model.empty = editor.blocks.getBlocksCount() === 0;
        }
    }

    /**
     * Check whether content is empty and add empty paragraph if it is.
     * Workaround 'coz for some reason, when using custom default block, it's
     * not created by default when the content is empty.
     */
    private ensureNotEmptyContent(content: OutputData | undefined): OutputData {
        if (!content?.blocks?.length) {
            return {
                ...(content || {}),
                blocks: [{
                    type: "paragraph",
                    data: {
                        text: "",
                    } as ParagraphData,
                }],
            };
        } else {
            return content;
        }
    }
}

interface EditorRef {
    editor: EditorJS;
    holder: HTMLElement;
}
