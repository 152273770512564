export const Admin = "admin";

export enum AdminRoutes {
    ExternalSources = "external-sources",
    GrantAccess = "grant-access",
    CustomerManagement = "customers",
    Migrator = "migrator",
    Deliveries = "deliveries",
    AllDashboards = "all-dashboards",
}
