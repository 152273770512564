import { ChangeDetectionStrategy, Component } from "@angular/core";
import { toStream } from "mobx-utils";
import { makeObservable } from "mobx";

import { observableRef } from "skCommon/state/mobxUtils";
import { ComponentDef } from "skCommon/insights/dashboard";

import { DesignerModel } from "skInsights/framework/designer/designerModel";

/**
 * Fallback designer which allows chaning the dashboard component's definition
 * directly using JSON editor.
 */
@Component({
    selector: "sk-json-designer",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./jsonDesigner.pug",
    styles: [
        `:host { display: contents; }`,
    ],
})
export class JsonDesignerComponent<T extends ComponentDef> {

    @observableRef
    public json: T;

    constructor(
        designerModel: DesignerModel<T>,
    ) {
        makeObservable(this);

        this.json = designerModel.initialDefinition;

        designerModel.updateDefinitionStream(toStream(() => this.json));
    }
}
