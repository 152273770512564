import { ChangeDetectionStrategy, Component } from "@angular/core";

/**
 * Component that should be displayed by each "top level" dashboard component
 */
@Component({
    selector: "sk-dashboard-copyright",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./dashboardCopyright.pug",
    styleUrls: ["dashboardCopyright.scss"],
})
export class DashboardCopyrightComponent { }
