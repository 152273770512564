import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot } from "@angular/router";

import { DashboardRef } from "skInsights/framework/dashboardData";

@Injectable({ providedIn: "root" })
export class DashboardRefResolver implements Resolve<DashboardRef> {

    public async resolve(route: ActivatedRouteSnapshot): Promise<DashboardRef> {
        const id = route.paramMap.get("id");

        const revisionId = route.paramMap.get("revisionId");

        if (id) {
            const dashboardRef = { id } as DashboardRef;
            if (revisionId) {
                dashboardRef.revisionId = revisionId;
            }
            return dashboardRef;
        } else {
            throw new Error("No dashboard reference provided");
        }
    }
}
