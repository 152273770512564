import { NgModule } from "@angular/core";

import { SharedModule } from "skCommon/angular/shared.module";
import { SkeletonDirective } from "skCommon/ui/skeleton/skeleton.directive";
import { SkeletonTextComponent } from "skCommon/ui/skeleton/skeletonText.component";

import "./skeletons.scss";

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        SkeletonTextComponent,
        SkeletonDirective,
    ],
    exports: [
        SkeletonTextComponent,
        SkeletonDirective,
    ],
})
export class SkeletonModule { }
