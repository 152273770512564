import { Component, ChangeDetectionStrategy, Inject, TemplateRef, Injector, StaticProvider, NgModule } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ComponentType } from "@angular/cdk/portal";

import { ComponentDef } from "skCommon/insights/dashboard";

import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { RenderableComponent } from "skInsights/framework/renderable";
import { LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { SharedModule } from "skInsights/shared.module";

@Component({
    selector: "sk-dialog-wrapper",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./dialogWrapper.pug",
})
export class DialogWrapperComponent {

    public renderable?: RenderableComponent;

    public templateRef?: TemplateRef<any>;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        private data: DialogWrapperData,
        moduleRegistryService: ModuleRegistryService,
    ) {
        if (data.refOrDef instanceof TemplateRef) {
            this.templateRef = data.refOrDef;
        } else if ("component" in data.refOrDef) {
            const component = moduleRegistryService.getAngularComponent(data.refOrDef);

            this.renderable = {
                component,
                injector: this.getInjector([
                    {
                        provide: LAYOUT_COMPONENT_DEF_TOKEN,
                        useValue: data.refOrDef,
                    },
                ], data),
            };
        } else {
            this.renderable = {
                component: data.refOrDef,
                injector: this.getInjector([], data.data),
            };
        }
    }

    /**
     * Create new injector if necessary, otherwise return component's injector.
     */
    private getInjector(providers: StaticProvider[], data?: any) {
        const parent = this.data.injector;
        const hasData = data && Object.keys(data).length;

        if (!providers.length && !hasData) {
            return parent;
        }

        return Injector.create({
            providers: [
                {
                    provide: MAT_DIALOG_DATA,
                    useValue: data.data,
                },
                ...providers,
            ],
            parent,
        });
    }
}

export interface DialogWrapperData {
    refOrDef: TemplateRef<any> | ComponentType<any> | ComponentDef;
    /**
     * Data to provide to the created view as MAT_DIALOG_DATA
     */
    data: any;
    injector: Injector;
}

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        DialogWrapperComponent,
    ],
    exports: [
        DialogWrapperComponent,
    ],
})
export class DialogWrapperModule { }

