import { OutputData } from "@editorjs/editorjs";

/**
 * For some reson Image block or editorjs escapes ampersands in image URLs
 * which is certainly unwanted. It would be nice to somehow fix that via some
 * monkey patching or extending, but for now I am not sure what really causes
 * that and thus the output needs to be always passed through this function.
 */
export function sanitizeOutputData(data: OutputData): OutputData {
    for (const block of data.blocks) {
        if (block.data?.file?.url) {
            block.data.file.url = block.data.file.url.replace(/&amp;/g, "&");
        }
    }

    return data;
}
