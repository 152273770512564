import { Doc } from "skCommon/firebase/doc";
import { Dashboard, DashboardSchema } from "skCommon/insights/dashboard";

export function parseDashboard(dashboardDoc: Doc<Dashboard>): ParsedDashboard {
    let schema: DashboardSchema;

    try {
        schema = JSON.parse(dashboardDoc.schema);
    } catch (e) {
        throw new Error(`Could not parse dashboard ${dashboardDoc.id} schema: ${e.message}`);
    }

    return {
        ...dashboardDoc,
        schema,
    };
}

export function serializeDashboard(displayable: ParsedDashboard): Doc<Dashboard> {
    let schema: string;

    try {
        schema = JSON.stringify(displayable.schema);
    } catch (e) {
        throw new Error(`Could not stringify dashboard ${displayable.id} schema: ${e.message}`);
    }

    return {
        ...displayable,
        schema,
    };
}

export interface ParsedDashboard extends Omit<Doc<Dashboard>, "schema"> {
    schema: DashboardSchema;
}
