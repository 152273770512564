import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sortByDate",
})
export class SortByDatePipe implements PipeTransform {

    public transform(array: any[], key?: any): any[] {
        return array.sort(
            (a: any, b: any) =>
                new Date(b[key]).getTime() - new Date(a[key]).getTime(),
        );
    }
}
