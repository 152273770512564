import { Client } from "skCommon/api/client/simple";
import { MANAGER_AUTH } from "skCommon/auth0/manager/managerAuthenticator";
import { CONFIG } from "skCommon/auth/oauthConfig";

const LIST_USERS_DEFAULT_OPTIONS = {
    // query summary must be included in the result
    include_totals: true,
    search_engine: "v3",
    page: 0,
    sort: "email:1",
    // should match ListedUser interface
    fields: [
        "name",
        "email",
        "user_id",
        "name",
        "logins_count",
        "last_login",
    ].join(","),
};

export class ManagerClient extends Client {
    public readonly api = "auth0-management";
    public readonly authType = MANAGER_AUTH;

    public readonly contentType = "application/json; charset=utf-8";

    public createUser(userData: CreateUserOptions) {
        return this.call<RawUser>({
            endpoint: "users",
            method: "POST",
            body: Object.assign(
                { connection: CONFIG.CONN.DB},
                userData,
            ),
        }).promise;
    }

    public listUsers(options?: ListUserOptions) {
        return this.call<ListUserResponse>({
            endpoint: "users",
            method: "GET",
            params: Object.assign({}, LIST_USERS_DEFAULT_OPTIONS, options),
        }).promise;
    }

    public getUser(userId: string) {
        return this.call<RawUser>({
            endpoint: "users",
            method: "GET",
            params: {
                user_id: userId,
            },
        }).promise;
    }

    public removeUser(userId: string) {
        return this.call<void>({
            endpoint: "users",
            method: "DELETE",
            params: {
                user_id: userId,
            },
        }).promise;
    }

    public setGroups(userId: string, roles: UserGroups[]) {
        return this.call<void>({
            endpoint: "users",
            method: "PATCH",
            params: {
                user_id: userId,
            },
            body: {
                app_metadata: {
                    sk_groups: roles,
                },
            },
        }).promise;
    }

    public setExtraPermissions(userId: string, permissions: string[]) {
        return this.call<void>({
            endpoint: "users",
            method: "PATCH",
            params: {
                user_id: userId,
            },
            body: {
                app_metadata: {
                    sk_extra_permissions: permissions,
                },
            },
        }).promise;
    }
}

let client: ManagerClient;
export function getManagerClient() {
    if (!client) {
        client = new ManagerClient();
    }
    return client;
}

interface CreateUserOptions {
    name?: string;
    email: string;
    password: string;
    user_metadata?: {};
    email_verified?: boolean;
    app_metadata?: {
        sk_groups: UserGroups[];
    };
}

interface ListUserResponse {
    start: number;
    limit: number;
    length: number;
    total: number;
    users: ListedUser[];
}

export interface ListedUser {
    email: string;
    email_verified: boolean;
    user_id: string;
    name: string;
    logins_count: number;
    last_login: string;
}

interface ListUserOptions {
    /**
     * The amount of entries per page. Default: 50. Max value: 100
     */
    per_page?: number;
    /**
     * The page number. Zero based.
     */
    page?: number;
    /**
     * The field to use for sorting. Use field:order where order is 1 for
     * ascending and -1 for descending. For example created_at:1
     * Default "email:1"
     */
    sort?: string;
    // TODO - in this place, we could allow setting fields we want to read.
    // That's hard from typescripts point of view.
    // We could accepts a sample object of the RawUser interface,
    // and then use Object.keys() on it.
    // fields?: string[] // <--- this is the param
    /**
     * Query in
     * [Lucene query string syntax](http://www.lucenetutorial.com/lucene-query-syntax.html).
     * Some query types cannot be used on
     * metadata fields, for details see
     * [Searchable Fields (v3)](
     * https://auth0.com/docs/users/search/v3/query-syntax#searchable-fields).
     */
    q?: string;
}

export interface RawUser {
    app_metadata: {
        sk_groups: UserGroups[];
        sk_extra_permissions?: string[];
    };
    created_at: string;
    email: string;
    email_verified: boolean;
    family_name: string;
    given_name: string;
    identities: Array<any>;
    last_ip: string;
    last_login: string;
    locale: string;
    logins_count: number;
    name: string;
    nickname: string;
    picture: string;
    updated_at: string;
    user_id: string;
    user_metadata: any;
}

export enum PermissionGroups {
    User = "user",
    PowerUser = "power-user",
    Developer = "developer",
    DataAnalyst = "data-analyst",
    UserManager = "user-manager",
    Admin = "admin",
    Annotator = "annotator",
    InsightsSubscriber = "insights-subscriber",
    AlchemistAnnotator = "alchemist-annotator",
    GuardianUser = "guardian-user",
}

export enum AccountingGroups {
    AccountingCommercial = "accounting-commercial",
    AccountingDevelopment = "accounting-development",
    AccountingImod = "accounting-imod",
}

export type UserGroups = AccountingGroups | PermissionGroups;

// tslint:disable-next-line:variable-name
export const UserGroups = { ...PermissionGroups, ...AccountingGroups };
