import { NgModule } from "@angular/core";

import { BlockEditorComponent } from "skInsights/partials/blockEditor/blockEditor.component";
import { SharedModule } from "skInsights/shared.module";

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        BlockEditorComponent,
    ],
    exports: [
        BlockEditorComponent,
    ],
})
export class BlockEditorModule { }
