import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { makeObservable } from "mobx";

import { observableRef } from "skCommon/state/mobxUtils";
import { DashboardAccess, InsightsCustomer } from "skCommon/insights/customers";

import { CustomerManagementService } from "skInsights/admin/customerManagement.service";
import {
    ManageSubscriptionDialog, ManageSubscriptionDialogData,
} from "skInsights/admin/manageSubscriptionsDialog/manageSubscriptionDialog";

interface DashboardAccessTable {
    id: string;
    name: string;
    duration: string;
    permission: string;
}

const ACTIVE_COLUMNS = ["name", "duration", "permission", "actions"];
const HISTORY_COLUMNS = ["name", "duration", "permission"];

@Component({
    selector: "sk-customer-page",
    templateUrl: "./customerPage.pug",
    styleUrls: ["./customerPage.scss", "./../admin.scss"],
})
export class CustomerPageComponent implements OnInit {

    private customerId: string = "";

    public activeColumns = ACTIVE_COLUMNS;

    public historyColumns = HISTORY_COLUMNS;

    @observableRef
    public customer: InsightsCustomer | undefined;

    @observableRef
    public activeDashboardTable: DashboardAccessTable[] = [];

    @observableRef
    public allDashboardTable: DashboardAccessTable[] = [];

    constructor(
        private activatedRoute: ActivatedRoute,
        private customerManagementService: CustomerManagementService,
        private dialog: MatDialog,
        ) {
        makeObservable(this);
    }

    public ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            this.customerId = params.get("id")!;

            if (this.customerId !== undefined) {
                this.customerManagementService.getCustomer(this.customerId)
                    .subscribe((customer) => {
                        this.customer = customer;
                        this.activeDashboardTable = this.getActiveDashboardsTable();
                        this.allDashboardTable = this.getAllDashboardsTable();
                    });
            }
        });
    }

    public openSubscriptionManagement() {
        this.dialog.open(
            ManageSubscriptionDialog,
            {
                width: "640px",
                height: "auto",
                data: {
                    emails: [this.customer?.email],
                    activeDashboards: this.getIdsOfActiveDashboards(),
                    customerIds: [this.customerId],
                    edit: false,
                } as ManageSubscriptionDialogData,
            },
        );
    }

    private getIdsOfActiveDashboards(): string[] {
        return  this.customer
            ? Object.keys(this.customer.dashboards)
                .filter(key => this.customer?.dashboards[key].hasAccess())
            : [];
    }

    private getActiveDashboardsTable(): DashboardAccessTable[] {
        return Object.entries(this.customer!.dashboards)
            .filter(([, access]) => access.hasAccess())
            .map(([id, access]) => this.mapDashboardToTable(id, access));
    }

    private getAllDashboardsTable(): DashboardAccessTable[] {
        return Object.entries(this.customer!.dashboards)
            .map(([id, access]) => this.mapDashboardToTable(id, access));    }

    private mapDashboardToTable(id: string, access: DashboardAccess): DashboardAccessTable {
        return {
            id: id,
            name: access.name,
            duration: `${access.startDate.toLocaleDateString()}
                        -
                       ${access.endDate.toLocaleDateString()}`,
            permission: access.accessType,
        };
    }

    public editAccess(dashboardId: string) {
        this.dialog.open(
            ManageSubscriptionDialog,
            {
                width: "640px",
                height: "auto",
                data: {
                    emails: [this.customer?.email],
                    activeDashboards: [dashboardId],
                    customerIds: [this.customerId],
                    edit: true,
                } as ManageSubscriptionDialogData,
            },
        );
    }

    public removeAccess(dashboardId: string) {
        this.customerManagementService.removeDashboardAccess(this.customerId, dashboardId);
    }
}
