import download from "downloadjs";
import { formatDate } from "@angular/common";
import { Injectable } from "@angular/core";

import { mergeTimeseries, MergedTimeseriesPoint } from "skCommon/utils/timeseries";
import { objectToCsv } from "skCommon/utils/csv";
import { sanitizeFilename } from "skCommon/utils/download";

import { DashboardSeries, DashboardSeriesPair } from "skInsights/framework/data/structures";
import { flattenSeriesPair, isSeries } from "skInsights/framework/data/helpers";

@Injectable({ providedIn: "root" })
export class DashboardExportService {

    public async downloadSeriesAsCsv(
        exportables: ExportableSeries[],
        chartName: string,
    ): Promise<void> {
        const flattenedExportables = this.flattenExportables(exportables);

        const merged = mergeTimeseries(
            flattenedExportables.map(exp => exp.series.series),
            "x",
            "y",
        );

        const csvContent = objectToCsv(merged, [
            {
                selector: r => formatDate(r.x, "yyyy-MM-dd HH:mm:ss", "en_US", "UTC"),
                title: "Date",
            },
            ...flattenedExportables.map((exp, i) => ({
                selector: (row: MergedTimeseriesPoint<"x", "y">) => row.y[i],
                title: exp.name,
            })),
        ]);

        await this.exportCSV(csvContent, chartName);
    }

    public async downloadAllSeriesAsCsv(
        merged: MergedTimeseriesPoint<"x", "y">[],
        names: string[],
        csvName: string,
    ): Promise<void> {
        const csvContent = objectToCsv(merged, [
            {
                selector: r => formatDate(r.x, "yyyy-MM-dd", "en_US", "UTC"),
                title: "Date",
            },
            ...names.map((name, i) => ({
                selector: (row: MergedTimeseriesPoint<"x", "y">) => row.y[i],
                title: name,
            })),
        ]);
        await this.exportCSV(csvContent, csvName, false);
    }

    private async exportCSV(content: string, name: string, nameWithHash: boolean = true) {
        const csvBuffer = new TextEncoder().encode(content);

        const hashBuffer = await crypto.subtle.digest("SHA-1", csvBuffer);
        const hash = this.bufferToHex(hashBuffer);
        const blob = new Blob([csvBuffer], { type: "text/csv" });

        const fileName =
            sanitizeFilename(name)
            + (nameWithHash ? `-${hash}` : formatDate(Date.now(), "-dd-MM-yyyy", "en-US"))
            + ".csv";

        download(blob, fileName);
    }

    private bufferToHex(buffer: ArrayBuffer): string {
        const byteToHex: string[] = [];

        for (let n = 0; n <= 0xff; ++n) {
            const hexOctet = n.toString(16).padStart(2, "0");
            byteToHex.push(hexOctet);
        }

        const buff = new Uint8Array(buffer);
        const hexOctets = new Array(buff.length);

        for (let i = 0; i < buff.length; ++i) {
            hexOctets[i] = byteToHex[buff[i]];
        }

        return hexOctets.join("");
    }

    /**
     * Get rid of exportables containing series-pairs
     */
    private flattenExportables(
        exportables: ExportableSeries[],
    ): ExportableSeries<DashboardSeries>[] {
        return exportables.flatMap(({ series, name }) => {
            if (isSeries(series)) {
                return [{
                    series,
                    name,
                }];
            } else {
                return flattenSeriesPair(series)
                    .map((flattenedSeries, i) => ({
                        series: flattenedSeries,
                        name: `${name} ${series.meta.name} ${series.meta.pairSuffix[i]}`,
                    }));
            }
        });
    }
}

export interface ExportableSeries<T extends ExportableSeriesData = ExportableSeriesData> {
    series: T;
    name: string;
}

export type ExportableSeriesData = DashboardSeries | DashboardSeriesPair;
