import {
    Component,
    ChangeDetectionStrategy,
    Input,
    EventEmitter,
    Output,
    ViewChild,
    ElementRef,
    AfterViewInit,
} from "@angular/core";
import { MatInput } from "@angular/material/input";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { makeObservable } from "mobx";

import { observableRef, computed } from "skCommon/state/mobxUtils";
import { BaseComponent } from "skCommon/angular/base/base.component";

@Component({
    selector: "sk-search-select",
    templateUrl: "./searchSelect.pug",
    styleUrls: ["./searchSelect.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchSelectComponent<T> extends BaseComponent implements AfterViewInit {

    @observableRef
    @Input()
    public label: string;

    @observableRef
    @Input()
    public options: SearchSelectOption<T>[] = [];

    @observableRef
    @Input()
    public width: number | string;

    @observableRef
    @Input()
    public placeholder: string;

    @observableRef
    @Input()
    public value: T;

    @Output()
    public valueChange: EventEmitter<T> = new EventEmitter();

    @observableRef
    public optionSearch = "";

    @observableRef
    public minWidthPx: string;

    @ViewChild("selectTrigger")
    public selectTrigger: ElementRef<HTMLDivElement>;

    @ViewChild("searchInput")
    public searchInput: ElementRef<MatInput>;

    @ViewChild("virtualScrollViewport")
    public virtualScrollViewport: CdkVirtualScrollViewport;

    @computed
    public get selectedOption(): SearchSelectOption<T> {
        return this.options.find(opt => opt.value === this.value);
    }

    public get placeholderLabel(): string {
        return this.placeholder || "Select...";
    }

    @computed
    public get filteredOptions(): SearchSelectOption<T>[] {
        return this.options.filter(opt => {
            const optKey = opt.key || opt.label.toLowerCase();

            return this.searchTerms.every(term => optKey.includes(term));
        });
    }

    @computed
    public get searchTerms(): string[] {
        return this.optionSearch
            .trim()
            .split(/\s+/g)
            .map(term => term.toLocaleLowerCase());
    }

    public get widthPx(): string {
        return this.width ? `${this.width}px` : "auto";
    }

    constructor() {
        super();
        makeObservable(this);
    }

    public ngAfterViewInit(): void {
        const updateMinWidth = () => {
            const width = this.selectTrigger.nativeElement.clientWidth;
            this.minWidthPx = width + "px";
        };

        const resizeObserver = new ResizeObserver(updateMinWidth);
        resizeObserver.observe(this.selectTrigger.nativeElement);
    }

    public selectOption(option?: SearchSelectOption<T>): void {
        const value = option ? option.value : null;

        this.value = value;
        this.valueChange.emit(value);
    }

    public focusSearch(): void {
        this.optionSearch = "";

        setTimeout(() => {
            this.searchInput.nativeElement.focus();
            this.virtualScrollViewport.checkViewportSize();
        });
    }

    public resetSelection(): void {
        this.selectOption(null);
    }
}

export interface SearchSelectOption<T> {
    value: T;
    label: string;
    detail?: string;
    key?: string;
    img?: string;
    icon?: string;
}
