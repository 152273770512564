import { Type } from "@angular/core";
import "chartjs-plugin-zoom/src/index.js";

import { ChartContext } from "skCommon/chart/chartContext";

/**
 * Import the original zoom plugin and make sure wheel event is not propagated.
 */
export function zoomPlugin<T extends Type<ChartContext>>(
    Base: T,
): T {
    class ZoomPluginMixin extends Base {

        private stopWheelPropagationEnabled = false;

        private zoomHandlerPatched = false;

        public update(): void {
            super.update();

            if (!this.zoomHandlerPatched) {
                const chart = this.chart as any;
                chart.$zoom._node.removeEventListener("wheel", chart.$zoom._wheelHandler);
                const orig = chart.$zoom._wheelHandler;
                const chartContext = this;
                chart.$zoom._wheelHandler = function (e: WheelEvent) {
                    if (!chartContext.options.plugins.zoom.zoom.ignore) {
                        orig.call(this, e);
                    }
                };
                chart.$zoom._node.addEventListener("wheel", chart.$zoom._wheelHandler);
                this.zoomHandlerPatched = true;
            }

            const zoomEnabled = this.options.plugins.zoom.zoom?.enabled
                && !this.options.plugins.zoom.zoom?.ignore;

            if (zoomEnabled && !this.stopWheelPropagationEnabled) {
                this.chart.canvas.addEventListener("wheel", stopPropagation);
                this.stopWheelPropagationEnabled = true;
            } else if (!zoomEnabled && this.stopWheelPropagationEnabled) {
                this.chart.canvas.removeEventListener("wheel", stopPropagation);
                this.stopWheelPropagationEnabled = false;
            }
        }
    }

    return ZoomPluginMixin;
}

function stopPropagation(e: WheelEvent): void {
    e.stopPropagation();
}

declare module "chart.js" {
    interface PluginOptionsByType {
        zoom: any;
    }
}
