import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import JsonEditor, { JSONEditorOptions } from "jsoneditor";
import { EMPTY, of, Subject } from "rxjs";
import { catchError, debounceTime, mergeMap } from "rxjs/operators";
import "brace/theme/twilight";
import { makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { observableRef } from "skCommon/state/mobxUtils";

@Component({
    selector: "sk-json-editor",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: "",
    styleUrls: ["./jsonEditor.scss"],
})
export class JsonEditorComponent extends BaseComponent implements OnDestroy, AfterViewInit {

    @Input()
    @observableRef
    public value: Object = {};

    @Output()
    public valueChange: EventEmitter<Object> = new EventEmitter();

    @Input()
    @observableRef
    public options: JSONEditorOptions = {
        mode: "code",
        theme: "ace/theme/twilight",
    };

    private editor?: JsonEditor;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
    ) {
        super();

        makeObservable(this);
    }

    public ngAfterViewInit(): void {
        const update$ = new Subject<void>();

        let lastEmittedValue = this.value;

        this.editor = new JsonEditor(this.elementRef.nativeElement, {
            ...this.options,
            onChange: () => update$.next(),
        }, this.value);

        update$.pipe(
            debounceTime(300),
            mergeMap(() => this.editor ? of(this.editor.get()) : EMPTY),
            // Ignore editor's parse errors
            catchError((_e: any, caught$) => caught$),
        ).subscribe(json => {
            lastEmittedValue = json;
            this.valueChange.emit(json);
        });

        this.reaction(() => this.value, val => {
            if (this.editor && val !== lastEmittedValue) {
                this.editor.update(val);
            }
        });
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.editor) {
            this.editor.destroy();
        }
    }
}
