import { ChangeDetectionStrategy, Component } from "@angular/core";
import { makeObservable, observable } from "mobx";
import { clone } from "traverse";

import { Logger } from "skCommon/utils/logger";
import { ParsedDashboard } from "skCommon/insights/parsedDashboard";
import { addErrorCtx } from "skCommon/core/error";

import { DashboardMigration, MigrationService } from "skInsights/admin/migrator/migration.service";
import { DashboardService } from "skInsights/dashboard/dashboard.service";
import { InsightsService } from "skInsights/insights.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";

const MIGRATOR_RUNNING = Symbol();

/**
 * Interface for running migrations, which take a dashboard definition and
 * modify it (e.g. replace component with another other)
 */
@Component({
    selector: "sk-migrator",
    templateUrl: "./migrator.pug",
    styleUrls: ["./migrator.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MigratorComponent {

    @observable.ref
    public previews?: MigrationPreview;

    @observable.ref
    public dashboardId: string = "";

    @observable.deep
    public migrationOptions: MigrationOption[] = Object.values(DashboardMigration)
        .map(migration => ({
            label: this.migrationService.LABELS[migration],
            migration,
            enabled: true,
        }));

    constructor(
        private dashboardService: DashboardService,
        private migrationService: MigrationService,
        private insightsService: InsightsService,
        private snackBarServie: SnackBarService,
        private logger: Logger,
    ) {
        makeObservable(this);
    }

    /**
     * Run the migration without saving it, displaying the new schema.
     */
    public async previewMigration(): Promise<void> {
        try {
            this.insightsService.globalLoadingProcesses.add(MIGRATOR_RUNNING);

            const dashboards = await this.migrateUserInput();

            this.previews = {
                before: JSON.stringify(dashboards.before.schema, null, 2),
                after: JSON.stringify(dashboards.after.schema, null, 2),
            };
        } catch (e) {
            this.snackBarServie.notify(`Migration failed: ${e.message}`);
            this.logger.error(e);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(MIGRATOR_RUNNING);
        }
    }

    /**
     * Run the migration and overwrite it
     */
    public async runMigration(): Promise<void> {
        try {
            this.insightsService.globalLoadingProcesses.add(MIGRATOR_RUNNING);

            const { after } = await this.migrateUserInput();

            this.dashboardService.saveDashboard(
                after.id,
                {
                    schema: JSON.stringify(after.schema),
                },
            );
        } catch (e) {
            this.snackBarServie.notify(`Migration failed: ${e.message}`);
            this.logger.error(e);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(MIGRATOR_RUNNING);
        }
    }

    public async runMigrationForAll() {
        try {
            this.insightsService.globalLoadingProcesses.add(MIGRATOR_RUNNING);

            const dashboards = await this.migrateAll();


            for (const {after} of dashboards) {
                await this.dashboardService.saveDashboard(
                    after.id,
                    {
                        schema: JSON.stringify(after.schema),
                    },
                );
            }

        } catch (e) {
            this.snackBarServie.notify(`Migration failed: ${e.message}`);
            this.logger.error(e);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(MIGRATOR_RUNNING);
        }
    }



    private async migrateUserInput(): Promise<MigrationDashboards> {
        const dashboard = await this.dashboardService
            .loadDashboardRef({ id: this.dashboardId });
        const selectedMigrations = this.migrationOptions
            .filter(({ enabled }) => enabled)
            .map(({ migration }) => migration);

        return this.migrateDashboard(dashboard, selectedMigrations);
    }

    private async migrateDashboard(
        dashboard: ParsedDashboard,
        migrationOptions:  DashboardMigration[],
    ): Promise<MigrationDashboards> {

        const oldSchema = dashboard.schema;
        const newSchema = clone(oldSchema);

        for (const selectedMigrationName of migrationOptions) {
            this.migrationService[selectedMigrationName](newSchema);
        }

        return {
            before: dashboard,
            after: {
                ...dashboard,
                schema: newSchema,
            },
        };
    }

    private async migrateAll(): Promise<MigrationDashboards[]> {
        const selectedMigrations = this.migrationOptions
            .filter(({ enabled }) => enabled)
            .map(({ migration }) => migration);
        const availableDashboards = await this.dashboardService.loadAllGlobalDashboards();

        const migrationDashboard: MigrationDashboards[] = [];

        for (const dashboard of availableDashboards) {
            try {
                migrationDashboard.push(
                    await this.migrateDashboard(dashboard, selectedMigrations),
                );
            } catch (e) {
                throw addErrorCtx(e, {
                    dashboardId: dashboard.id,
                });
            }
        }

        return migrationDashboard;
    }

}

interface MigrationDashboards {
    before: ParsedDashboard;
    after: ParsedDashboard;
}

interface MigrationPreview {
    before: string;
    after: string;
}

interface MigrationOption {
    label: string;
    migration: DashboardMigration;
    enabled: boolean;
}
