import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { makeObservable, observable } from "mobx";
import { Observable } from "rxjs";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { DashboardSchema } from "skCommon/insights/dashboard";

import { DashboardRoutes, EditDashboardRoutes } from "skInsights/dashboard/dashboard.routing";
import { DashboardRevision, DashboardService } from "skInsights/dashboard/dashboard.service";

@Component({
    selector: "sk-dashboard-editor-history",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./dashboardEditorHistory.pug",
    styleUrls: ["dashboardEditorHistory.scss"],
})
export class DashboardEditorHistoryComponent extends BaseComponent {

    public revisions$: Observable<DashboardRevision[]> | undefined;

    private dashboardId: string | undefined;

    public displayingJsonId = "";

    @observable.ref
    public json?: DashboardSchema;

    constructor(
        private dashboardService: DashboardService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        super();
        this.activatedRoute.parent!.data.subscribe(data => {
            this.dashboardId = data.dashboardRef.id;
            this.revisions$ = this.dashboardService.getDashboardRevisions(data.dashboardRef.id);
        });
        makeObservable(this);
    }

    public openDashboard(revisionId: string): void {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([
                DashboardRoutes.Dashboard,
                this.dashboardId,
                EditDashboardRoutes.History,
                revisionId,
            ]),
        );
        window.open(url, "_blank");
    }

    public openJson(revision: DashboardRevision): void {
        if (this.displayingJsonId === revision.id) {
            this.displayingJsonId = "";
        } else {
            this.displayingJsonId = revision.id;
            this.json = revision.schema;
        }
    }
}
