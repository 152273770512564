import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { makeObservable } from "mobx";

import { assert } from "skCommon/utils/assert";
import { Logger } from "skCommon/utils/logger";
import { observableRef } from "skCommon/state/mobxUtils";
import { DataRef } from "skCommon/insights/dashboard";

import { LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { CardComponent, CardComponentDef } from "skInsights/framework/card/cardComponent";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { LinerHueColorcoding, LinerHueColorcodingDef, StopsColorcodingDef, createColorcoding } from "skInsights/modules/tables/helpers/colorcoding";
import { assertSingleValuesLike, deconstructCollection } from "skInsights/framework/data/helpers";
import { ExpressionService } from "skInsights/framework/expression.service";
import { CardService } from "skInsights/framework/card/card.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";

export const HeatmapComponentType: HeatmapComponentType = "tables/heatmap";
export type HeatmapComponentType = "tables/heatmap";

const DEFAULT_COLORCODING = new LinerHueColorcoding({
    color: "#f96666",
    hueRange: 120,
    values: [-0.05, 0.05],
});

@Component({
    selector: "sk-heatmap",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./heatmap.pug",
    styleUrls: ["./heatmap.scss"],
    providers: [CardService],
})
export class HeatmapComponent extends CardComponent<HeatmapComponentDef> {

    public header: string[] = [];

    @observableRef
    public rows: RowData[] = [];

    public get loading(): boolean {
        return this.rows.length === 0;
    }

    public get eNotation(): number {
        return this.def.eNotation ?? 2;
    }

    public get showNotationNote(): boolean {
        return this.eNotation !== 0
            && (this.def.showNotationNote ?? true);
    }

    constructor(
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        protected readonly def: HeatmapComponentDef,
        private dataRefService: DataRefService,
        private expressionService: ExpressionService,
        private logger: Logger,
        private snackBarService: SnackBarService,
        cardService: CardService,
    ) {
        super(cardService);
        makeObservable(this);

        this.header = this.def.columns.map(col => col.title);

        this.loadData().catch(e => {
            // TODO: only show error if user is editor and the error is not native
            this.logger.error(e, "Heatmap data loading");
            this.snackBarService.notify(`Could not render heatmap: ${e.message}`);
        });
    }

    private async loadData(): Promise<void> {
        const cc = this.def.colorcoding
            ? createColorcoding(this.def.colorcoding)
            : DEFAULT_COLORCODING;

        const sources = await Promise.all(this.def.columns.map(
            col => this.dataRefService.resolveAny(col.data),
        ));

        this.rows = await Promise.all(this.def.rows.map(async row => {
            const rowDataPromises = this.def.columns.map(async (_, i) => {
                const pipeCall = this.expressionService.parsePipeCall(row.pipe);

                assert(pipeCall, `Invalid pipe call provided "${pipeCall}"`);

                const out = await this.dataRefService.executePipe(
                    pipeCall,
                    sources[i],
                );

                assertSingleValuesLike(out);

                const { value } = deconstructCollection(out);

                return {
                    value: value * 10 ** this.eNotation,
                    color: cc.getColor(value),
                };
            });

            return {
                title: row.title,
                data: await Promise.all(rowDataPromises),
            };
        }));
    }
}

interface RowData {
    title: string;
    data: {
        value: number;
        color: string;
    }[];
}

/**
 * Pivot table with background colorcoded by its value
 */
interface HeatmapComponentDef extends CardComponentDef {
    component: HeatmapComponentType;
    /**
     * Array of column definitions providing title and source data for the
     * column
     */
    columns: ColumnDefinition[];
    /**
     * Array of row definitions providing title and named pipe name which should
     * be used on the column's source data.
     */
    rows: RowDefinition[];
    /**
     * Optional configuration of the colorcoding that is used to paint cell
     * backgrounds. Currently two forms of colorcoding are accepted:
     *
     * *LinerHueColorcoding*: set an initial color and hue is then rotated
     * depending on cell's value
     *
     * *LinerHueColorcoding*: Allows to manually set a specific color for
     * diferent ranges in form of array of value-color touples. The first
     * element should awlays be just a color string without any start defined
     * (this defines the color for all values from infitity to the next color.)
     * All other elements should be touples containing the start value and color
     *
     * When not provided, default colorcoding is used, which works well for
     * heatmap with low values (-0.05 to 0.05).
     *
     * @example Example which defines color intervals:
     *      (+inf, 0.5), (0.5, 0), (0, -0.5), (-0.5, -inf)
     *      `["#00FF00", [0.5, "#005000"], [0, "#505050"], [-0.5, "#500000"]]`
     *
     * @example Example of the hue rotation method:
     *      `{ color: "#f96666", hueRange: 120, values: [-0.05, 0.05] }`
     */
    colorcoding?: LinerHueColorcodingDef | StopsColorcodingDef;
    /**
     * When not 0, all values are displayed in E notation of given exponent.
     * @default 2 All values are multiplies by 100
     */
    eNotation: number;
    /**
     * When true, a small footnote about notation is displayed below the heatmap
     * @default true
     */
    showNotationNote: boolean;
}

interface ColumnDefinition {
    /**
     * @interpolated
     */
    title: string;
    /**
     * @dataref
     */
    data: DataRef;
}

interface RowDefinition {
    /**
     * @interpolated
     */
    title: string;
    /**
     * Named pipe defined in schema to execute on each column's data.
     */
    pipe: string;
}
