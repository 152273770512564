import { Injectable } from "@angular/core";

import { ParsedDashboard } from "skCommon/insights/parsedDashboard";

import { UserService } from "skInsights/user/user.service";

/**
 * Service providing set of default global constants available in any
 * dashboard's context.
 */
@Injectable({ providedIn: "root" })
export class GlobalConstantsSerivce {

    constructor(
        private userStore: UserService,
    ) { }

    public getConstants(dashboard: ParsedDashboard): Record<string, string> {
        const profile = this.userStore.getProfile();
        const userId = this.userStore.id;

        return {
            [DashboardGlobals.CurrentUserName]: profile.name || profile.email,
            [DashboardGlobals.CurrentUserEmail]: profile.email,
            [DashboardGlobals.CatalogKey]: "~" + btoa(userId).replace(/=/g, ""),
            [DashboardGlobals.DashboardName]: dashboard.meta.name,
            [DashboardGlobals.DashboardIcon]: dashboard.meta.icon || "",
        };
    }
}

export enum DashboardGlobals {
    CurrentUserName = "CURRENT_USER_NAME",
    CurrentUserEmail = "CURRENT_USER_EMAIL",
    CatalogKey = "CATALOG_KEY",
    DashboardName = "DASHBOARD_NAME",
    DashboardIcon = "DASHBOARD_ICON",
}
