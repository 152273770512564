import firebase from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import { take } from "rxjs/operators";
import { observable, makeObservable } from "mobx";
import { AngularFireFunctions } from "@angular/fire/functions";

import { getDefaultAuthentication } from "skCommon/core/authentication";
import { Auth0Client } from "skCommon/auth0/client";
import { BaseUserService } from "skCommon/angular/baseUserService";
import { Logger } from "skCommon/utils/logger";
import { FullUserProfile } from "skCommon/core/user";
import { UserAuthData, SPACEKNOW_OAUTH, OAuthTokenData } from "skCommon/auth/authenticator";
import { getAuthClient } from "skCommon/auth/client";

export class FirebaseAuthUserService extends BaseUserService {

    @observable
    public userInitInProgress = false;

    private userInit: Promise<void>;

    private firebaseAuthState: firebase.User | null = null;

    public get loggedInFirebase(): boolean {
        return !!this.firebaseAuthState;
    }

    constructor(
        protected angularFireAuth: AngularFireAuth,
        protected angularFireFunctions: AngularFireFunctions,
        logger: Logger,
    ) {
        super(logger);

        makeObservable(this);

        this.angularFireAuth.authState.subscribe(state => {
            this.firebaseAuthState = state;
        });
    }

    public initUser(): Promise<void> {
        if (!this.userInit) {
            this.userInit = (async () => {
                this.userInitInProgress = true;

                try {
                    await this.tryToProcessURLAuthData();
                    await this.autoLogin();
                } catch (e) {
                    this.logger.error(e);
                } finally {
                    this.userInitInProgress = false;
                }
            })();
        }

        return this.userInit;
    }

    private async authenticateFirestore(profile: FullUserProfile) {
        const current = await this.angularFireAuth.user.pipe(take(1)).toPromise();
        if (current && current.uid === profile.sub) {
            return;
        }

        const authorize = this.angularFireFunctions
            .httpsCallable<{ token: string }, { token: string }>("authorize");
        const authentication = getDefaultAuthentication();

        const authData = authentication.getAuthenticationData(SPACEKNOW_OAUTH) as UserAuthData;
        const accessToken = (authData.data as OAuthTokenData).accessToken;

        const auth$ = authorize({ token: accessToken });

        const firebaseToken = (await auth$.toPromise()).token;

        await this.angularFireAuth.setPersistence("local");
        await this.angularFireAuth.signInWithCustomToken(firebaseToken);
    }

    protected async setUserData(profile: FullUserProfile): Promise<void> {
        if (getAuthClient() instanceof Auth0Client) {
            await this.authenticateFirestore(profile);
        }

        this.logger.addBoundField(
            "user",
            {
                id: profile.sub,
                email: profile.email,
            },
        );

        await super.setUserData(profile);
    }

    protected async revokeAuthData(silent?: boolean) {
        if (getAuthClient() instanceof Auth0Client) {
            await this.angularFireAuth.signOut();
        }

        this.logger.removeBoundField("user");
        return super.revokeAuthData(silent);
    }
}
