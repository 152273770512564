import traverse from "traverse";

import { assert } from "skCommon/utils/assert";
import { exists } from "skCommon/utils/types";

export function permissions<T extends {}>(def: T): PermissionNamespace<T> {
    return traverse(def).map(function () {
        if (this.path.length && this.key !== "toString") {
            const permissionPath = this.path.map(
                k => k
                    .replace(/^[A-Z]/, c => c.toLowerCase())
                    .replace(/[A-Z]/g, c => "-" + c.toLowerCase()),
            ).join(".");

            this.update({
                ...this.node,
                toString() {
                    return permissionPath;
                },
            });
        }
    });
}

/**
 * Find all permissions that match given glob-like expression.
 *
 * Example permissions: [
 *  "analytics.sets.one",
 *  "analytics.sets.two",
 * ]
 *
 * Example input: analytics.sets.*
 *
 * Would return ["one", "two"]
 */

export function matchPermissions(ownedPermissions: string[], permissionMatch: string): string[] {
    const firstAsterisk = permissionMatch.indexOf("*");
    const lastAsterisk = permissionMatch.lastIndexOf("*");

    assert(
        firstAsterisk !== -1 && firstAsterisk === lastAsterisk,
        "Permission match needs to have exactly one * character",
    );

    const expContent = permissionMatch
        .replace(/\./g, "\\.")
        .replace("*", "([^\.]+)");

    const exp = new RegExp(`^${expContent}$`);

    return ownedPermissions.map(perm => perm.match(exp))
        .map(match => match?.[1])
        .filter(exists);
}

export type PermissionLike = string | { toString(): string };

type PermissionNamespace<T extends {}> = {
    [k in keyof T]: PermissionNamespace<T[k]> & {
        toString(): string;
    }
} & { toString(): string };
