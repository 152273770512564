import {
    Chart,
    TimeScale,
    LinearScale,
    Title,
    Legend,
    PointElement,
    Tooltip,
    BarElement,
    BarController,
} from "chart.js";

/**
 * Register all required chartjs components for date/linear line charts to work
 */
export function registerBarCharts(): void {
    Chart.register(
        BarElement,
        TimeScale,
        LinearScale,
        Title,
        Legend,
        BarController,
        PointElement,
        Tooltip,
    );

    (Chart as any).defaults.scale.display = "auto";
}
