import { DashboardData } from "skInsights/framework/dashboardData";
import { DataProvider } from "skInsights/framework/data/dataProvider";

export abstract class DashboardSource<TDef> {

    public abstract readonly type: string;

    /**
     * @todo Each source is provided also reference to the current DashboardData
     *  which may result in infinite loop when used incorrectly, but currently
     *  it's necessary since mutations need to request data from other sources.
     *  Remove once we get rid of mutations.
     */
    public abstract create(def: TDef, existingData: DashboardData): Promise<DataProvider>;

    public canAccess(_def: TDef): Promise<boolean> | boolean {
        return true;
    }
}
