import { Injectable } from "@angular/core";

import { PageExport } from "skCommon/exports/pageExport/pageExport";

import { ComponentStaticRenderer } from "skInsights/framework/dashboardPdf/componentStaticRenderer";
import { DashboardPdfService } from "skInsights/framework/dashboardPdf/dashboardPdf.service";
import { DashboardPdfOptions } from "skInsights/framework/dashboardPdf/dashboardPdfOptions";
import { FlexComponentDef } from "skInsights/modules/core/components/flex/flex.component";

@Injectable()
export class FlexStaticRendererService implements ComponentStaticRenderer<FlexComponentDef> {

    constructor(
        private dashboardPdfService: DashboardPdfService,
    ) { }

    public async renderIntoPage(
        def: FlexComponentDef,
        exportPage: PageExport,
        opts: DashboardPdfOptions,
    ): Promise<void> {
        for (const child of def.children) {
            await this.dashboardPdfService.renderComponent(exportPage, child.content, false, opts);
        }
    }
}
