import { permissions } from "skCommon/utils/permissions";

/**
 * Permissions used by all applications so we have all of them at one place
 * and so we stop duplicating some permission definitions over different
 * applications.
 */
export const {
    Credits,
    Olaf,
    Admin,
    Annotations,
    Imagery,
    Kraken,
    ExportRaster,
    Analytics,
    Watchdog,
    Insights,
    Deliveries,
    Workflows,
} = permissions({
    Olaf: {
        /**
         * User can run analyses
         */
        Analysis: {},
        /**
         * Allows download of raw imagery with now added value.
         * .{provider}.{dataset} can be appended to only allow certain datasets to
         * be exported that way.
         */
        Export: {},
        /**
         * A raw data about scenes and providers may be displayed to the user.
         */
        SceneDetails: {},
        /**
         * User can select providers to run the analysis on, otherwise the
         * providers are selected automatically.
         */
        ProviderSelect: {},
        /**
         * Show features which either export or expect some input related to the
         * backend scripts such as scene tiles records.
         */
        BackendUtils: {},
        /**
         * Propagate certain backend errors to the user instead of showing generic
         * "something went wrong".
         */
        PropagateErrors: {},
        /**
         * User can change polygon source
         */
        PolygonSource: {},
        BillableArea: {},
        /**
         * Do not use any artifical limits and allow whatever the APIs allow.
         */
        PolygonSize: {
            Unlimited: {},
            Annotation: {},
        },
        /**
         * Allow running task with imagery only
         */
        ImageryOnly: {},
    },

    Annotations: {
        Manage: {},
        Annotate: {},
    },

    Imagery: {
        Order: {},
    },

    Admin: {
        Tasking: {
            GetPipeline: {},
        },
    },

    Credits: {
        GetRemainingCredit: {},
    },

    Kraken: {
        DryRun: {},
        Protect: {},
        Release: {},
    },

    ExportRaster: {},

    /**
     * Namespace containing permissions allowing extra functionality in
     * analytics. Since so many accounts have the whole `olaf` permission which
     * makes it impossible to introduce a new permissions that only few
     * selected users will have, we should NEVER give the whole analytics
     * permission to any account.
     */
    Analytics: {
        /**
         * Allow user to use "hidden" datasets, which they may have permissions
         * for, but we want to hide them from most of the users anyway.
         */
        HiddenDatasets: {},
        FeatureInfo: {
            /**
             * Allows updating metadata for the DI-specific feature info table
             */
            Manage: {},
        },
        /**
         * Allow kraken to be released with clippedImagery option
         */
        ClippedImagery: {},
        AnalysisSet: {},
        /**
         * Display some utility features for hacking and debugging
         */
        Debug: {},
        /**
         * Permissions related to finished analyses
         */
        Analysis: {
            /**
             * Allow user to retry all failed processes in a finished analysis
             */
            Retry: {},
            SkiRenderer: {},
        },
        /**
         * Permission to open location in baidu maps
         */
        BaiduMaps: {},
        /**
         * Special functionality per delivery
         */
         Deliveries: {
            Alchemist: {
                DrawLabel: {},
            },
            SarChangeDirDerivates: {},
        },
    },

    Watchdog: {},

    Insights: {
        Data: {
            Export: {},
            Explore: {},
            Hidden: {},
            Metrics: {},
        },
        Dashboard: {
            Copy: {},
            Create: {},
            Edit: {},
            Publish: {},
            Export: {},
        },
        Documents: {
            Manage: {},
        },
        Admin: {
            ExternalSources: {},
            GrantAccess: {},
            Overwrite: {},
            ManageCustomers: {},
            Migrate: {},
            Deliveries: {},
        },
    },

    Deliveries: {
        Alchemist: {},
    },

    Workflows: {},
});
