import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { computed, observableRef } from "skCommon/state/mobxUtils";
import { DocsItem, DocsTag } from "skCommon/insights/docs";

const INTERPOLATED = "interpolated";
const DATESTRING = "datestring";
const DATAREF = "dataref";

const SPECIAL_TAGS = new Set([
    INTERPOLATED,
    DATESTRING,
    DATAREF,
]);

@Component({
    selector: "sk-docs-item",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./docsItem.pug",
})
export class DocsItemComponent extends BaseComponent {

    @Input()
    @observableRef
    public item!: DocsItem;

    @computed
    public get tagMap(): Map<string, string | void> {
        return new Map(
            this.item.tags.map(({ name, content }) => [name, content]),
        );
    }

    @computed
    public get tags(): DocsTag[] {
        return this.item.tags
            .filter(tag => !SPECIAL_TAGS.has(tag.name.toLowerCase()));
    }

    @computed
    public get interpolated(): boolean {
        return this.tagMap.has(INTERPOLATED);
    }

    @computed
    public get datestring(): boolean {
        return this.tagMap.has(DATESTRING);
    }

    @computed
    public get dataref(): boolean {
        return this.tagMap.has(DATAREF);
    }

    @computed
    public get commentHtml(): SafeHtml {
        return this.markdownify(this.item.comment || "");
    }

    constructor(
        private domSanitizer: DomSanitizer,
    ) {
        super();

        makeObservable(this);
    }

    public markdownify(text: string): SafeHtml {
        const commentHtml = text
            .replace(/`([^`]+)`/g, (_, content) => `<code>${content}</code>`);

        return this.domSanitizer.bypassSecurityTrustHtml(commentHtml);
    }
}
