import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, Observable, combineLatest, map, Subscription } from "rxjs";

import { Logger } from "skCommon/utils/logger";
import { DashboardAccessType } from "skCommon/insights/customers";

import { CustomerManagementService } from "skInsights/admin/customerManagement.service";
import { DashboardService } from "skInsights/dashboard/dashboard.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";

interface DashboardRow {
    checked: boolean;
    name: string;
    type: string;
    id: string;
}

interface FormOptions {
    name: string;
    value: any;
}

const ACCESS_TYPES = [
    {name: "Full access", value: DashboardAccessType.Full},
    {name: "Trial with Data explorer", value: DashboardAccessType.TrialWithDataExplorer},
    {name: "Trial", value: DashboardAccessType.Trial},
] as FormOptions[];


// value is number of days
const DURATIONS_OPTIONS = [
    {name: "1 month", value: 30},
    {name: "3 month", value: 91},
    {name: "6 month", value: 182},
    {name: "1 year", value: 364},
    {name: "2 years", value: 728},
] as FormOptions[];

const TODAY = new Date();

enum DialogState {
    DashboardSelection = "dashboardSelection",
    PermissionSelection = "permissionSelection",
}

@Component({
    templateUrl: "./manageSubscriptionDialog.pug",
    styleUrls: ["./manageSubscriptionDialog.scss", "./../admin.scss"],
})
// tslint:disable-next-line:component-class-suffix
export class ManageSubscriptionDialog implements OnInit, OnDestroy {

    public disableBackButton = false;

    public dashboards: DashboardRow[] = [];

    public dialogState = DialogState.DashboardSelection;

    public title = "Manage subscription";

    public accessTypes = ACCESS_TYPES;
    public durationOptions = DURATIONS_OPTIONS;

    public accessForm = new FormGroup({
        duration: new FormControl(""),
        startDate: new FormControl(TODAY, [Validators.required]),
        endDate: new FormControl(null, [Validators.required]),
        permissions: new FormControl(this.accessTypes[0].value),
    });

    public loadingDashboards = true;

    private searchQuerySubject$: BehaviorSubject<string> = new BehaviorSubject("");
    public searchQuery$: Observable<string> = this.searchQuerySubject$.asObservable();

    private subs: Subscription[] = [];

    constructor(
        private dashboardService: DashboardService,
        private customerManagementService: CustomerManagementService,
        public dialogRef: MatDialogRef<ManageSubscriptionDialog>,
        private snackBarService: SnackBarService,
        private logger: Logger,
        @Inject(MAT_DIALOG_DATA)
        public data: ManageSubscriptionDialogData,
        ) {
        if (!this.data.edit) {
            this.subs.push(
                combineLatest([
                        // get all available dashboards and map them to DashboardRow
                        this.dashboardService.availableDashboards$
                            .pipe(
                                map((dashboards) =>
                                    dashboards
                                        .filter((dashboard) =>
                                            !this.data.activeDashboards.includes(dashboard.id))
                                        .map((dashboard) => (
                                            {
                                                checked: false,
                                                name: dashboard.meta.name,
                                                type: dashboard.meta.description,
                                                id: dashboard.id,
                                            }) as DashboardRow,
                                        ),
                                )),
                        this.searchQuery$,
                    ],
                )
                    .pipe(
                        map(([dashboards, searchQuery]) => {
                            if (searchQuery === "") {
                                return dashboards;
                            }
                            return dashboards.filter((dashboard) =>
                                dashboard.name.includes(searchQuery));
                        }),
                    )
                    .subscribe((dashboards) => {
                        this.dashboards = dashboards;
                        this.loadingDashboards = false;
                    }),
            );
        }
    }

    public ngOnInit(): void {
        if (this.data.edit) {
            this.setUpEditMode();
        }
        this.durationChange();
        this.endDateChange();
    }

    private setUpEditMode(): void {
        this.disableBackButton = true;
        this.customerManagementService
            .getDashboardAccess(this.data.customerIds[0], this.data.activeDashboards[0])
            .subscribe((dashboardAccess) => {
                this.dashboards = [{
                    checked: true,
                    name: dashboardAccess.name,
                    type: "",
                    id: this.data.activeDashboards[0],
                }];
                this.accessForm.get("startDate")!.setValue(dashboardAccess.startDate);
                this.accessForm.get("endDate")!.setValue(dashboardAccess.endDate);
                this.accessForm.get("permissions")!.setValue(dashboardAccess.accessType);
                this.grantAccess();
            });
    }

    public ngOnDestroy(): void {
        this.subs.forEach((sub) => sub.unsubscribe());
    }


    private endDateChange(): void {
        this.subs.push(
            this.accessForm.get("endDate")!.valueChanges.subscribe(() => {
                this.accessForm.get("duration")!.setValue("", {emitEvent: false});
            }),
        );
    }

    private durationChange(): void {
        this.subs.push(
            this.accessForm.get("duration")!.valueChanges.subscribe((duration) => {
                const startDate = this.accessForm.get("startDate");
                if (startDate) {
                    const newValue = new Date();
                    newValue.setDate(startDate.value.getDate() + duration);
                    newValue.toLocaleDateString();
                    this.accessForm.get("endDate")!.setValue(newValue, {emitEvent: false});
                } else {
                    this.accessForm.get("endDate")!.setValue(TODAY + duration, {emitEvent: false});
                }
            }),
        );
    }

    public search(searchQuery: string): void {
        this.searchQuerySubject$.next(searchQuery);
    }

    public isAtLeastOneDashboardSelected(): boolean {
        return this.dashboards
            && this.dashboards
            .filter((dashboard) => dashboard.checked)
            .length > 0;
    }

    public grantAccess(): void {
        this.dialogState = DialogState.PermissionSelection;
        const checkedDashboards = this.dashboards
            .filter((dashboard) => dashboard.checked);
        if (checkedDashboards.length === 1) {
            this.title = checkedDashboards[0].name;
        } else {
            this.title = checkedDashboards.length + " dashboards";
        }
    }

    public back() {
        this.dialogState = DialogState.DashboardSelection;
        this.title = "Manage subscription";
    }

    public async save() {
        await this.dashboards
            .filter(dashboard => dashboard.checked)
            .forEach(dashboard => {
                this.data.customerIds.forEach(customerId => {
                    this.customerManagementService.addDashboardAccess(
                        customerId,
                        dashboard.id,
                        this.accessForm.get("startDate")!.value,
                        this.accessForm.get("endDate")!.value,
                        dashboard.name,
                        this.accessForm.get("permissions")!.value,
                    )
                        .then(() => this.dialogRef.close())
                        .catch((err) => {
                            this.logger.error(err, "Saving dashboard access");
                            this.snackBarService.notify(`Could not save dashboard access: ${err.message}`);
                        });
                });
            });
    }
}

export interface ManageSubscriptionDialogData {
    emails: string[];
    activeDashboards: string[];
    customerIds: string[];
    edit: boolean;
}
