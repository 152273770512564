import { NgModule } from "@angular/core";

import { SortByDatePipe } from "skCommon/angular/pipes/sortByDate/sortByDate.pipe";

@NgModule({
    declarations: [
        SortByDatePipe,
    ],
    exports: [
        SortByDatePipe,
    ],
})
export class SortByDateModule { }
