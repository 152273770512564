import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";

import { RouterStore } from "skCommon/angular/router.store";
import { PermissionLike } from "skCommon/utils/permissions";

import { UserService } from "skInsights/user/user.service";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {

    constructor(
        private userStore: UserService,
        private routerStore: RouterStore,
    ) { }

    public async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean> {
        await this.userStore.initUser();

        if (!this.userStore.loggedIn) {
            this.routerStore.navigate(["/login"], {
                queryParams: {
                    returnTo: state.url,
                },
            });
            return false;
        }

        const data = route.data as AuthGuardData;

        if (data.permissions) {
            if (!this.userStore.permissions.hasAll(data.permissions)) {
                return false;
            }
        }

        return true;
    }
}

export interface AuthGuardData {
    permissions: PermissionLike[];
}
