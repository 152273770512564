import { ChartConfiguration, ChartDataset, ChartOptions, DeepPartial, LinearScale, LinearScaleOptions, Tick, TooltipItem, Filler } from "chart.js";
import moment from "moment";
import Color from "color";
import { AnnotationOptions } from "chartjs-plugin-annotation/types/options";
import { clone } from "traverse";

import { exists } from "skCommon/utils/types";

import { SeriesDataPoint } from "skInsights/framework/data/structures";
import { getAvailableColors } from "skInsights/modules/charts/helpers/chartColors";
import { parseDateString } from "skInsights/utils/dateString";

const SUPERSCRIPT: Record<string, string> = {
    "-": "⁻",
    "0": "⁰",
    "1": "¹",
    "2": "²",
    "3": "³",
    "4": "⁴",
    "5": "⁵",
    "6": "⁶",
    "7": "⁷",
    "8": "⁸",
    "9": "⁹",
};

const DEFAULT_Y_SCALE = "y-left-0";

const AVAILABLE_SCALES: Record<string, ScaleOptions> = {
    "y-left-0": {
        type: "linear",
        position: "left",
        ticks: {
            callback: labelToString,
        },
        gridLines: {
            display: true,
        },
    },
    "y-left-1": {
        type: "linear",
        position: "left",
        ticks: {
            callback: labelToString,
        },
    },
    "y-right-0": {
        type: "linear",
        position: "right",
        ticks: {
            callback: labelToString,
        },
    },
    "y-right-1": {
        type: "linear",
        position: "right",
        ticks: {
            callback: labelToString,
        },
    },
};

export function makeChartOptions({
    datasets: datasetOptions,
    dateRange,
    axes,
    visibleGroup,
    dateFormat,
    annotations,
}: ChartInternalOptions): ChartConfiguration {
    const colorIterators: Map<string | undefined, IterableIterator<string>> = new Map();

    const datasets = datasetOptions.flatMap(opt => {
        let availableColors = colorIterators.get(opt.group);

        if (!availableColors) {
            availableColors = getAvailableColors(
                datasetOptions
                    .filter(({ group }) => group === opt.group)
                    .map(({ color }) => color)
                    .filter(exists),
            );
            colorIterators.set(opt.group, availableColors);
        }

        return makeDatasetOptions(opt, availableColors);
    });

    for (const [i, dataset] of datasets.entries()) {
        // Very hacky way to put the aux series before and after the main one
        if (dataset.fill === "-1") {
            const aux0 = datasets[i - 1];
            datasets[i - 1] = datasets[i - 2];
            datasets[i - 2] = aux0;
            dataset.fill = "-2";
        }
    }

    const min = new Date(Math.min(
        ...datasets.map(({ data }) => +data[0].x),
    ));

    const max = new Date(Math.max(
        ...datasets.map(({ data }) => +data[data.length - 1].x),
    ));

    return {
        type: "line",
        data: {
            datasets,
            labels: null!,
        },
        plugins: [Filler],
        options: {
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                },
            },
            hover: {
                mode: "nearestEach",
            },
            responsive: true,
            aspectRatio: 1.8,
            maintainAspectRatio: false,
            animation: {
                duration: 0,
                radius: {
                    duration: 100,
                },
            },
            scales: {
                x: {
                    gridLines: {
                        display: true,
                    },
                    type: "time",
                    ticks: {
                        maxRotation: 0,
                        autoSkipPadding: 10,
                    },
                    time: {
                        minUnit: "month",
                        displayFormats: {
                            month: "MMM YYYY",
                        },
                    },
                    min: dateRange?.min
                        ? parseDateString(dateRange.min).getTime()
                        : null,
                    max: dateRange?.max
                        ? parseDateString(dateRange.max).getTime()
                        : null,
                },
                ...clone(AVAILABLE_SCALES),
                ...(axes || {}),
            },
            plugins: {
                filler: {
                    propagate: false,
                },
                seriesGroups: {
                    visibleGroup: visibleGroup || void 0,
                },
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: true,
                    mode: "nearestEach",
                    callbacks: {
                        title: getTooltipDateFormatter(dateFormat),
                        label: formatTooltipValue,
                    },
                },
                zoom: {
                    pan: {
                        enabled: true,
                        mode: "x",
                        rangeMin: { x: min },
                        rangeMax: { x: max },
                    },
                    zoom: {
                        enabled: true,
                        mode: () => {
                            // tslint:disable-next-line: deprecation
                            const e = event as WheelEvent;

                            if (e && (e.shiftKey || e.altKey || e.metaKey)) {
                                return "y";
                            } else {
                                return "x";
                            }
                        },
                        rangeMin: { x: min },
                        rangeMax: { x: max },
                    },
                },
                annotation: {
                    drawTime: "afterDatasetsDraw",
                    annotations,
                },
            },
        } as ChartOptions<"line">,
    };
}

export function makeDatasetOptions(
    {
        series,
        color,
        label,
        scale,
        hidden,
        group,
        dash,
        dashOffset,
        width,
        refs,
    }: DatasetInternalOptions,
    availableColors: Iterator<string>,
): ChartDataset<"line">[] {
    color = color || availableColors.next().value;

    const areaBgColor = new Color(color).alpha(0.1).toString();
    const areaBorderColor = new Color(color).mix(new Color("#FFFFFF"), 0.86).toString();
    const commonOptions = {
        refs,
        pointRadius: 0,
        borderWidth: width ?? 1.5,
        borderDash: dash,
        borderDashOffset: dashOffset,
        cubicInterpolationMode: "monotone",
        lineTension: 0.3,
        hoverRadius: 5,
        label: label,
        parsing: {
            xAxisKey: "x",
            yAxisKey: "y",
        },
        yAxisID: scale || DEFAULT_Y_SCALE,
        xAxisID: "x",
        group,
        hidden,
    };

    if (isAreaSeries(series)) {
        return [
            {
                ...commonOptions,
                // Typings do not allow Date type in x due to line dataset using the
                // same point definition as scatter chart for some reason
                data: series[1] as unknown as XIsNumber<typeof series[0][0]>[],
                borderColor: areaBorderColor,
                pointBackgroundColor: areaBorderColor,
            } as ChartDataset<"line">,
            {
                ...commonOptions,
                data: series[0] as unknown as XIsNumber<typeof series[0][0]>[],
                pointBackgroundColor: areaBorderColor,
                borderColor: areaBorderColor,
                backgroundColor: areaBgColor,
                fill: "-1",
            } as ChartDataset<"line">,
        ];
    } else {
        return [{
            ...commonOptions,
            data: series as unknown as XIsNumber<typeof series[0]>[],
            pointBackgroundColor: color,
            borderColor: color,
        } as ChartDataset<"line">];
    }
}

function isAreaSeries(input: SupportedSeriesInput): input is AreaSeriesInput {
    return input.length === 2 && input[0] instanceof Array;
}

export function makeAnnotationOptions({
    id,
    color,
    label,
    hover,
    date,
}: InternalAnnotationOptions): AnnotationOptions<"line"> {
    return {
        id,
        display: true,
        type: "line",
        scaleID: "x",
        value: date,
        borderColor: color,
        borderWidth: 1,
        borderDash: [5, 5],
        label: {
            content: "!",
            position: "end" as any, // old typings
            yAdjust: -4,
            font: {
                family: "serif",
                size: 16,
                lineHeight: 20,
                weight: "bold",
                style: "normal",
            },
            backgroundColor: Color(color).darken(0.3).alpha(0.8).string(),
            xPadding: 7,
            yPadding: 0,
            enabled: !!label,
            cornerRadius: 100,
        },
        hover,
        tooltip: label,
    };
}

function labelToString(tick: number, _i: number, ticks: Tick[]): string {
    const endAbsValues = [ticks[0], ticks.at(-1)!]
        .map(({ value }) => Math.abs(value));
    const highestAbsVal = Math.max(...endAbsValues);

    return formatValue(tick, highestAbsVal);
}

function toSuperScript(text: string): string {
    return text.split("")
        .map(char => char in SUPERSCRIPT ? SUPERSCRIPT[char] : char)
        .join("");
}

function getTooltipDateFormatter(dateFormat?: string): (tooltipItems: TooltipItem[]) => string {
    return (tooltipItems: TooltipItem[]) => {
        return moment(tooltipItems[0].dataPoint.x)
            .utc()
            .format(dateFormat || "YYYY-MM-DD");
    };
}

function formatTooltipValue({ dataPoint, formattedValue }: TooltipItem): string {
    if ("y" in dataPoint && typeof dataPoint.y === "number") {
        return formatValue(dataPoint.y);
    } else {
        return formattedValue;
    }
}

/**
 * Format any value so it uses expoential form such as 1.23×10⁵ for large
 * number while using regular decimal number for short values close to 1.
 *
 * @param refValue Value to use to decide the format, useful when multiple
 *  values should have the same format
 */
function formatValue(value: number, refValue: number = value): string {
    const abs = Math.abs(refValue);
    const log10 = Math.floor(Math.log10(abs));
    const zeros = -log10 - 1;

    if (value !== 0 && (refValue < 1 && zeros > 3 || refValue > 1e3)) {
        const exponent = log10;
        const exp = value * 10 ** -exponent;
        const exValue = exp.toLocaleString("en-US", {
            minimumFractionDigits: 1,
            maximumFractionDigits: 3,
        });
        const exponentSup = toSuperScript(exponent.toString());

        return `${exValue}×10${exponentSup}`;
    } else {
        return value.toLocaleString("en-US", {
            minimumFractionDigits: 1,
            maximumFractionDigits: log10 > 1
                ? Math.max(1, 5 - log10)
                : 5,
        });
    }
}

export interface DatasetInternalOptions {
    series: SupportedSeriesInput;
    /**
     * References that are propagated to all children chartjs timeseries that
     * are used to control visibility, which solves:
     * 1. an aux series can be also controlled by its main series' visibility
     * 2. single ref may control multiple series which is neede for area charts,
     *    that are created out of multiple series
     */
    refs: [string, ...string[]];
    label: string;
    color?: string;
    width?: number;
    dash?: number[];
    dashOffset?: number;
    hidden?: boolean;
    scale?: string;
    group?: string;
}

export type SupportedSeriesInput = SeriesDataPoint[] | AreaSeriesInput;

export type AreaSeriesInput = [SeriesDataPoint[], SeriesDataPoint[]];

export interface ChartInternalOptions {
    datasets: DatasetInternalOptions[];
    dateRange?: {
        min?: string;
        max?: string;
    };
    axes?: Record<string, LinearScale>;
    visibleGroup?: string;
    dateFormat?: string;
    annotations: any[];
}

export interface InternalAnnotationOptions {
    id: string;
    hover: boolean;
    date: Date;
    color: string;
    label?: string;
}

/**
 * Overrride x type to number while keeping the rest
 */
type XIsNumber<T> = Omit<T, "x"> & { x: number };

type ScaleOptions = DeepPartial<LinearScaleOptions> & { type: "linear" };
