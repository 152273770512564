import { makeObservable, observable } from "mobx";
import { toStream } from "mobx-utils";
import { combineLatest, from, Observable } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { PageDefinition } from "skInsights/modules/core/components/sidebarView/sidebarView.component";
import { DesignerModel } from "skInsights/framework/designer/designerModel";

export class PageModel {

    @observable.ref
    public title: string;

    public contentModel: DesignerModel;

    public definition$: Observable<PageDefinition>;

    constructor(private origDef: PageDefinition) {
        makeObservable(this);

        this.title = origDef.title;
        this.contentModel = new DesignerModel(origDef.content);

        const content$ = this.contentModel.definition$;
        const title$ = from(toStream(() => origDef.title, true));

        this.definition$ = combineLatest([title$, content$]).pipe(
            map(([title, content]) => ({
                ...this.origDef,
                title,
                content,
            })),
            shareReplay(),
        );
    }
}
