import { Injectable, Inject, Injector } from "@angular/core";
import { formatNumber } from "@angular/common";
import { Map as MapboxMap, Marker, LngLatLike, LngLatBoundsLike } from "mapbox-gl";
import { point, featureCollection, feature } from "@turf/helpers";
import { getCoord } from "@turf/invariant";
import bbox from "@turf/bbox";
import { h } from "jsx-dom";
import { mean } from "simple-statistics";

import { Logger } from "skCommon/utils/logger";
import { getFlag } from "skCommon/flags";
import { Bbox, Point } from "skCommon/utils/geometry";
import { generateUniqueId } from "skCommon/utils/uniqueId";
import { Cluster, MarkerClusterer } from "skCommon/mapbox/markerClusterer";
import { expandBbox } from "skCommon/utils/projection";
import { ComponentDef, DataRef } from "skCommon/insights/dashboard";

import { MapPlugin, MapPluginDef, MAP_PLUGIN_DEF_TOKEN, MapPluginMapOptions } from "skInsights/modules/maps/plugin";
import { DashboardSingleValue } from "skInsights/framework/data/structures";
import { DatacubeAoiService } from "skInsights/helpers/datacube/datacubeAoi.service";
import { PopupService } from "skInsights/partials/popup/popup.service";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { toDashboardSingleValue } from "skInsights/framework/data/helpers";

/**
 * Map component plugin which displays single values with region metadata on map.
 */
@Injectable()
export class ValueLabels implements MapPlugin {

    private instanceId = generateUniqueId();

    private mapData: MapPoint[] = [];

    private renderedMarkers: RenderedValueLabel[] = [];

    public get mapOptions(): MapPluginMapOptions {
        return {
            extent: bbox(featureCollection(this.mapData)),
            zoom: this.def.zoom,
        };
    }

    constructor(
        @Inject(MAP_PLUGIN_DEF_TOKEN)
        private def: ValueLabelsDef,
        private map: MapboxMap,
        private dataRefService: DataRefService,
        private logger: Logger,
        private datacubeAoiService: DatacubeAoiService,
        private popupService: PopupService,
        private injector: Injector,
    ) { }

    public async init(): Promise<void> {
        await this.datacubeAoiService.loadAois();

        const aois = (await import("skInsights/aois.json")).default;
        const values = await this.dataRefService.resolveFlattenValues(this.def.pointSource);

        const validValues = values.filter(v => {
            if (!v.meta.region) {
                this.logger.warning(`ValueLabels: ${v.meta.name} has no region, skipping`);
                return false;
            }

            if (!(v.meta.region! in aois)) {
                this.logger.warning(`ValueLabels: unknown region ${v.meta.region}`);
                return false;
            }

            return true;
        });

        this.mapData = validValues.map(val => {
            const region = val.meta.region! as keyof typeof aois;
            const aoi = aois[region];

            return point(
                aoi.point.coordinates,
                {
                    name: aoi.name,
                    value: val,
                    regionCode: region,
                },
            );
        });
    }

    public async render(): Promise<void> {
        const clusterer = new MarkerClusterer<MapPointProps>(this.map, 30);

        clusterer.setData(this.mapData);

        clusterer.clusters$.subscribe(clusters => this.renderMarkers(clusters));
    }

    private renderMarkers(clusters: Cluster<MapPointProps>[]): void {
        this.renderedMarkers.forEach(({ marker }) => marker.remove());

        this.renderedMarkers = clusters.map(cluster => {
            const marker = new Marker({
                element: this.createValueLabel(cluster),
                anchor: "center",
            }).setLngLat(getCoord(cluster) as LngLatLike).addTo(this.map);

            return { cluster, marker };
        });
    }

    private createValueLabel(cluster: Cluster<MapPointProps>): HTMLElement {
        const children = cluster.properties.childrenProperties;
        const country = children.length === 1
            ? children[0].regionCode.replace(/-.+$/, "")
            : void 0;
        const singleValue = toDashboardSingleValue(
            mean(children.map(props => props.value.value)),
            children[0].value.meta,
        );
        const dialogContent = this.def.dialogContent;
        const clickable: boolean = !!dialogContent || children.length > 1;

        const flag = country
            ? <img src={ getFlag(country) } class="icon" />
            : <div class="cluster-size icon">{children.length}</div>;

        return <div
            class={{ "map-value-label": true, "clickable": clickable }}
            onClick={() => this.handleLabelClick(cluster)}
        >
            { flag }
            <div class={["map-value-label-text", this.getSingleValueClass(singleValue)]}>
                { this.singleValueToString(singleValue) }
            </div>
        </div> as HTMLElement;
    }

    private handleLabelClick(cluster: Cluster<MapPointProps>): void {
        if (cluster.properties.clusterSize === 1) {
            const childProps = cluster.properties.childrenProperties[0];
            const mapPoint = feature(cluster.geometry, childProps);
            this.displayDialogContent(mapPoint);
        } else if (cluster.properties.clusterBbox) {
            const clusterBbox = Bbox.fromGeoJson(cluster.properties.clusterBbox);
            const expandedBbox = expandBbox(clusterBbox, 1, 1.2);
            this.map.fitBounds(expandedBbox.toGeoJson() as LngLatBoundsLike);
        }
    }

    /**
     * Open dialog defined in the plugin definition with MAP_SERIES variable
     * set to the clicked value name.
     */
    private displayDialogContent(mapPoint: MapPoint): void {
        if (this.def.dialogContent) {
            const pos = this.projectToScreen(mapPoint.geometry.coordinates);
            const props = mapPoint.properties;

            this.popupService.open(
                Injector.create({
                    parent: this.injector,
                    providers: this.dataRefService.extend({
                        MAP_SERIES: props.value.meta.name,
                        REGION_CODE: props.regionCode,
                        REGION_NAME: props.name,
                    }),
                }),
                this.def.dialogContent,
                {
                    id: `${this.instanceId}-${props.regionCode}`,
                    position: {
                        left: pos.x,
                        top: pos.y,
                    },
                },
            );
        }
    }

    private projectToScreen(pos: GeoJSON.Position): Point {
        const mapPos = this.map.project(pos as LngLatLike);
        const divBox = this.map.getContainer().getBoundingClientRect();

        return {
            x: mapPos.x + divBox.left,
            y: mapPos.y + divBox.top,
        };
    }

    private singleValueToString(singleValue: DashboardSingleValue): string {
        const prefix = this.getArrow(singleValue);

        return prefix
            + formatNumber(Math.abs(singleValue.value), "en_US", "1.0-1")
            + (singleValue.meta.unit || "");
    }

    private getSingleValueClass({ value }: DashboardSingleValue): string {
        switch (Math.sign(value)) {
            case 1:
                return "change-positive";
            case -1:
                return "change-negative";
            default:
                return "change-none";
        }
    }

    private getArrow({ value }: DashboardSingleValue): string {
        switch (Math.sign(value)) {
            case 1:
                return "⬆ ";
            case -1:
                return "⬇ ";
            default:
                return "";
        }
    }
}

type MapPoint = GeoJSON.Feature<GeoJSON.Point, MapPointProps>;

interface MapPointProps {
    name: string;
    value: DashboardSingleValue;
    regionCode: string;
}

interface ValueLabelsDef extends MapPluginDef {
    /**
     * Source for the SingleValue data that should be displayed on map. Region
     * metadata is required and values are assumed to be change result.
     */
    pointSource: DataRef;
    /**
     * Component that should be displayed in the dialog opened after clicking
     * on the point. ID of currently opened point is available as
     * `@MAP_SERIES` so in case of e.g. chart series for current point
     * can be selected using following source definitions:
     * ```
     * {
     *     "id": "some-series-source",
     *     "select": "@MAP_SERIES"
     * }
     * ```
     */
    dialogContent?: ComponentDef;
    /**
     * Allows setting default zoom. Useful when providing single point on map
     * and thus reasonable zoom cannot be automatically deduced.
     */
    zoom?: number;
}

interface RenderedValueLabel {
    marker: Marker;
    cluster: Cluster<MapPointProps>;
}
