import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { observableRef } from "skCommon/state/mobxUtils";

import { AvailableDashboard } from "skInsights/dashboard/availableDashboard";
import { DashboardRoutes } from "skInsights/dashboard/dashboard.routing";

const DEFAULT_THUMBNAIL = "/img/dashboard-thumbnail.png";

@Component({
    selector: "sk-dashboard-card",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./dashboardCard.pug",
})
export class DashboardCardComponent extends BaseComponent {

    @observableRef
    @Input()
    public dashboard?: AvailableDashboard;

    public get viewUrl(): string | void {
        return this.dashboard
            ? `/${DashboardRoutes.Dashboard}/${this.dashboard.id}`
            : void 0;
    }

    public get editUrl(): string | void {
        return this.dashboard
            ? `/${DashboardRoutes.EditDashboard}/${this.dashboard.id}`
            : void 0;
    }

    public get loading(): boolean {
        return !this.dashboard;
    }

    public get backgroundImage(): SafeUrl {
        return this.domSanitizer.bypassSecurityTrustStyle(
            this.loading
                ? "none"
                : `url("${this.dashboard?.meta?.thumbnail || DEFAULT_THUMBNAIL}")`,
        );
    }

    public get editable(): boolean {
        return !!this.dashboard?.editable;
    }

    public get tags(): string[] {
        if (!this.dashboard) {
            return [];
        }

        return this.dashboard?.meta.tags || [];
    }

    constructor(
        private domSanitizer: DomSanitizer,
    ) {
        super();

        makeObservable(this);
    }
}
