/**
 * Get fetch implementation
 */

let fetchImpl: fetchLike | undefined;

export function callFetch(url: string|Request, opts?: RequestInit): Promise<Response> {
    if (!fetchImpl) {
        fetchImpl = fetch;
    }

    return fetchImpl(url, opts);
}

export function setFetchImpl(newFetchImpl: fetchLike) {
    fetchImpl = newFetchImpl;
}

export type fetchLike = (url: string|Request, opts?: RequestInit) => Promise<Response>;
