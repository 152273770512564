import { DatacubeFilter, DatacubeFilterType } from "skCommon/datacube/client";
import { formatDate } from "skCommon/utils/data";

/**
 * Constant to use for version filter when the version should be populated the
 * latest version according to catalogue.
 */
export const LATEST_VERSION = "latest";

export function queryToFilters(
    item: FilterQuery,
    getLatestVersion: (item: FilterQuery) => string = latestVersionNotSupported,
): DatacubeFilter[] {
    const out: DatacubeFilter[] = [];

    if (item.project) {
        out.push({
            field: "project",
            type: DatacubeFilterType.ValueList,
            params: { values: [item.project] },
        });
    }
    if (item.algorithm) {
        out.push({
            field: "algorithm",
            type: DatacubeFilterType.ValueList,
            params: { values: [item.algorithm] },
        });
    }
    if (item.source) {
        out.push({
            field: "source",
            type: DatacubeFilterType.ValueList,
            params: {
                values: typeof item.source === "string"
                    ? [item.source]
                    : item.source,
            },
        });
    }
    if (item.version) {
        const version = item.version === LATEST_VERSION
            ? getLatestVersion(item)
            : item.version;

        out.push({
            field: "version",
            type: DatacubeFilterType.ValueList,
            params: { values: [version] },
        });
    }
    if (item.aoi) {
        out.push({
            field: "aoi",
            type: DatacubeFilterType.GeoIntersects,
            params: { geometryLabel: item.aoi },
        });
    }

    if (item.aoiId) {
        out.push({
            field: "aoiId",
            type: DatacubeFilterType.ValueList,
            params: { values: [item.aoiId] },
        });
    }

    if (typeof item.cloudCover === "number" && item.cloudCover < 1) {
        out.push({
            type: DatacubeFilterType.ValueRange,
            field: "cloudCover",
            params: {
                min: 0,
                max: item.cloudCover,
            },
        });
    }

    if (typeof item.intersectionRatio === "number" && item.intersectionRatio > 0) {
        out.push({
            type: DatacubeFilterType.ValueRange,
            field: "intersectionRatio",
            params: {
                min: item.intersectionRatio,
                max: 1,
            },
        });
    }

    if (item.from || item.to) {
        out.push({
            type: DatacubeFilterType.TimeRange,
            field: "startDatetime",
            params: {
                from: item.from ? formatDate(item.from) : null,
                to: item.to ? formatDate(item.to) : null,
            },
        });
        out.push({
            type: DatacubeFilterType.TimeRange,
            field: "endDatetime",
            params: {
                from: item.from ? formatDate(item.from) : null,
                to: item.to ? formatDate(item.to) : null,
            },
        });
    }


    return out;
}

function latestVersionNotSupported(): string {
    throw new Error("Latest version not supported.");
}

export interface FilterQuery {
    project?: string;
    algorithm?: string;
    source?: string | string[];
    version?: string;
    aoi?: string;
    aoiId?: string;
    cloudCover?: number;
    intersectionRatio?: number;
    from?: Date;
    to?: Date;
}
