import { Injectable, TemplateRef } from "@angular/core";
import { makeObservable } from "mobx";

import { observableRef } from "skCommon/state/mobxUtils";

/**
 * Service which allows dashboard components to render parts of their template
 * in the dashboard's navigation panel.
 */
@Injectable({ providedIn: "root" })
export class DynamicNavService {

    @observableRef
    public dynamicNav?: DynamicNav;

    constructor() {
        makeObservable(this);
    }

    /**
     * Show given template in the dashboard navigation and return disposer that
     * needs to be called when the owner component is destroyed.
     */
    public renderNav(nav: DynamicNav): Function {
        this.dynamicNav = nav;

        return () => {
            if (nav === this.dynamicNav) {
                this.dynamicNav = void 0;
            }
        };
    }
}

export interface DynamicNav {
    template: TemplateRef<any>;
    beforeMain?: boolean;
}
