import { Component, Inject, ViewChild, ElementRef, NgModule, ChangeDetectionStrategy } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { makeObservable } from "mobx";

import { observable } from "skCommon/state/mobxUtils";
import { SharedModule } from "skCommon/angular/shared.module";
import { AutorunModule } from "skCommon/angular/state/autorun.module";
import { DialogData, PromptPayload } from "skCommon/ui/dialog/dialog.misc";


@Component({
    templateUrl: "./promptDialog.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: ["mat-dialog-content { min-width: 400px }"],
})
export class PromptDialogComponent {

    @observable
    public answer: string = "";

    private data: PromptDialogData;

    private features: PromptFeatures;

    public get title(): string {
        return this.data.payload.title;
    }

    public get description(): string | undefined {
        return this.data.payload.description;
    }

    public get displayTextInput(): boolean {
        return "textInput" in this.features && this.features.textInput;
    }

    public get displayTextArea(): boolean {
        return "textArea" in this.features && this.features.textArea;
    }

    public get selectOptions(): SelectPromptOption[] | undefined {
        return "options" in this.features
            ? this.features.options
            : undefined;
    }

    public get submitLabel(): string {
        return this.features.submitLabel || "Submit";
    }

    @ViewChild("promptInput")
    public inputElement?: ElementRef<HTMLInputElement>;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        data: PromptDialogData,
    ) {
        makeObservable(this);

        this.data = data;
        this.features = data.payload.features || {};

        if ("initialValue" in this.features && this.features.initialValue) {
            this.answer = this.features.initialValue;
        }
    }

    public ngAfterViewInit(): void {
        setTimeout(() => this.inputElement && this.inputElement.nativeElement.focus());
    }

    public submit(): void {
        this.data.defer.resolve(this.answer);
    }
}

export type PromptDialogData = DialogData<string, FeaturePromptPayload>;

export type FeaturePromptPayload = PromptPayload<{
    features?: PromptFeatures;
}>;

export type PromptFeatures = BasicPromptFeatures
    | TextAreaPromptFeatures
    | InputPromptFeatures
    | SelectPromptFeatures;

export interface BasicPromptFeatures {
    submitLabel?: string;
}

export interface InputPromptFeatures extends BasicPromptFeatures {
    textInput: true;
    initialValue?: string;
}

export interface TextAreaPromptFeatures extends BasicPromptFeatures {
    textArea: true;
    initialValue?: string;
}

export interface SelectPromptFeatures extends BasicPromptFeatures {
    options: SelectPromptOption[];
    initialValue?: string;
}

export interface SelectPromptOption {
    label: string;
    value: string;
}

@NgModule({
    imports: [
        SharedModule,
        AutorunModule,
    ],
    declarations: [
        PromptDialogComponent,
    ],
})
export class ConfirmDialogModule { }
