import { observable, makeObservable } from "mobx";

import { Dashboard } from "skCommon/insights/dashboard";
import { ParsedDashboard } from "skCommon/insights/parsedDashboard";

export class DashboardModel {

    @observable.ref
    public name: string;

    @observable.ref
    public description: string;

    @observable.shallow
    public tags: Set<string>;

    @observable.ref
    public thumbnail: string;

    @observable.ref
    public global: boolean;

    @observable.ref
    public promoted: boolean;

    @observable.ref
    public public: boolean;

    @observable.ref
    public icon: string | null;

    constructor(dashboard?: ParsedDashboard) {
        makeObservable(this);

        this.name = dashboard?.meta.name || "";
        this.description = dashboard?.meta.description || "";
        this.thumbnail = dashboard?.meta.thumbnail || "";
        this.tags = new Set(dashboard?.meta.tags || []);
        this.public = !!dashboard?.public;
        this.global = !!dashboard?.global;
        this.promoted = !!dashboard?.promoted;
        this.icon = dashboard?.meta.icon || null;
    }

    public toObject(): Pick<Dashboard, "meta" | "global" | "public" | "promoted"> {
        return {
            global: this.global,
            public: this.public,
            promoted: this.promoted,
            meta: {
                name: this.name,
                description: this.description,
                tags: [...this.tags],
                thumbnail: this.thumbnail,
                icon: this.icon,
            },
        };
    }
}
