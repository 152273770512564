import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import download from "downloadjs";
import { from, lastValueFrom, mergeMap, tap, toArray } from "rxjs";

import { DatacubeClient, ProductData, RawProduct } from "skCommon/datacube/client";
import { sanitizeFilename } from "skCommon/utils/download";
import { sortByWeight } from "skCommon/utils/sort";
import { assert } from "skCommon/utils/assert";
import { exists, nonEmptyArray } from "skCommon/utils/types";
import { Logger } from "skCommon/utils/logger";
import { Insights } from "skCommon/permissions";

import {
    MetadataDialogComponent,
} from "skInsights/dashboard/dashboardView/metadataDialog/metadataDialog.component";
import { DashboardSource } from "skInsights/framework/abstract/dashboardSource";
import {
    DashboardSeries,
    DashboardDataType,
    DashboardString,
    DashboardCollection,
    SeriesLike,
    DashboardSeriesPair,
} from "skInsights/framework/data/structures";
import { SeriesSetProvider } from "skInsights/framework/data/providers/seriesSetProvider";
import { DataProvider } from "skInsights/framework/data/dataProvider";
import { ProductCatalogService } from "skInsights/helpers/datacube/productCatalog.service";
import { ExplorableDataProvider, ExplorableGroup } from "skInsights/framework/data/explorableDataset";
import { MetadataList } from "skInsights/framework/data/metadataList";
import { assertSeries } from "skInsights/framework/data/helpers";
import { ProductMetricQueries } from "skInsights/modules/datacube/sources/utils/productMetricQueries";
import { UserService } from "skInsights/user/user.service";

@Injectable()
export class ProductSource extends DashboardSource<ProductSourceDef> {

    public type: string = "datacube/product";

    constructor(
        private productCatalogService: ProductCatalogService,
        private datacubeClient: DatacubeClient,
        private userService: UserService,
        private logger: Logger,
        private matDialog: MatDialog,
    ) {
        super();
    }

    public async canAccess(def: ProductSourceDef): Promise<boolean> {
        const ids = await this.productCatalogService.getAvailableProductIds();

        return ids.has(def.productId);
    }

    public async create(def: ProductSourceDef): Promise<DataProvider> {
        const productId = def.productId;

        const [data, info] = await Promise.all([
            this.datacubeClient.getAndParseProduct({ productId }),
            this.datacubeClient.getProducts({ productId }),
        ]);

        if (!info.length) {
            throw new Error(`No product info found for product ${productId}`);
        }

        const productInfo = info[0];
        const metadata = productInfo.metadata || {};

        const timeseriesMap = this.makeTimeseriesMap(productId, data, metadata);

        if (this.userService.permissions.has(Insights.Data.Metrics)) {
            await this.addMetricesIfViable(productInfo, timeseriesMap);
        }

        return new ProductDataProvider(
            productId,
            {
                type: DashboardDataType.Collection,
                set: Object.fromEntries(timeseriesMap),
            },
            metadata,
            this.datacubeClient,
            this.matDialog,
        );
    }

    private makeTimeseriesMap(
        productId: string,
        data: ProductData[],
        metadata: Record<string, any>,
    ): Map<string, DashboardSeries> {
        const timeseriesMap = new Map<string, DashboardSeries>();

        for (const point of data) {
            for (const [ts, val] of Object.entries(point.values)) {
                if (val !== null) {
                    // CSV AoIs use underscores even though everywhere else a dash is used
                    const aoiId = point.aoiId
                        ? point.aoiId.replace(/_/g, "-")
                        : void 0;
                    const tsId = aoiId ? `${ts}[${aoiId}]` : ts;

                    if (!timeseriesMap.has(tsId)) {
                        timeseriesMap.set(tsId, {
                            type: DashboardDataType.Series,
                            series: [],
                            meta: {
                                uid: `${productId}:${tsId}`,
                                name: tsId,
                                region: aoiId
                                    ? aoiId
                                    : metadata.observedLocation?.locationsCovered,
                            },
                        });
                    }

                    timeseriesMap.get(tsId)!.series.push({
                        x: point.valueDate,
                        y: val,
                    });
                }
            }
        }

        return timeseriesMap;
    }

    /**
     * Check whether product supports metric, if so add them into given
     * timeseries map.
     */
    private async addMetricesIfViable(
        productInfo: RawProduct,
        timeseriesMap: Map<string, DashboardSeries>,
    ): Promise<void> {
        const { metadata, requestDefinitions } = productInfo;

        const supervisedSeries = Object.keys(metadata?.benchmarkPredictions || {});
        const isSupervised = supervisedSeries.length > 0;
        const definitions = isSupervised
            ? requestDefinitions.map(([, def]) => def)
            : [];

        const metrics$ = from(definitions).pipe(
            mergeMap(definition => {
                const metricQueries = ProductMetricQueries.create(definition);

                return metricQueries ? metricQueries.getAllMetrics() : [];
            }),
            mergeMap(async metricQuery => ({
                metricQuery,
                metricData: await this.datacubeClient.get(metricQuery.query)
                    .then(({ csvLink }) => this.datacubeClient.openDatacubeCsv(csvLink))
                    .catch(e => (
                        this.logger.error(e, `Metric ${metricQuery.label} for product ${productInfo.productId}`),
                        undefined
                    )),
            })),
            tap(({ metricData, metricQuery }) => {
                const series = metricData!.map(point => ({
                    x: point.endDatetime,
                    y: point.value,
                }));

                timeseriesMap.set(
                    `metrics/${metricQuery.algorithm}/${metricQuery.name}`,
                    {
                        series,
                        meta: {
                            uid: `${productInfo.productId}:${metricQuery.algorithm}-${metricQuery.name}`,
                            name: metricQuery.label,
                        },
                        type: DashboardDataType.Series,
                    },
                );
            }),
            toArray(),
        );

        await lastValueFrom(metrics$);
    }
}

export class ProductDataProvider extends SeriesSetProvider<DashboardString>
        implements ExplorableDataProvider {

    constructor(
        public readonly productId: string,
        seriesSet: DashboardCollection<DashboardSeries>,
        private metadata: MetadataDict = {},
        private datacubeClient: DatacubeClient,
        private matDialog: MatDialog,
    ) {
        super(seriesSet);

        const order = [
            "polygonIndustry",
            "polygonType",
            "polygonSubType",
            "polygonSubType2",
        ];

        if (metadata.observedLocation) {
            const entries = Object.entries(metadata.observedLocation)
                .map(([key, val]) => ({ key, val }));
            const list = sortByWeight(entries, order, "key")
                .map(({ key, val }) => `${key}: ${val}`);

            this.metadata = {
                ...metadata,
                observedLocation: new MetadataList(list),
            };
        }
    }

    public async select(q: string): Promise<SeriesLike | DashboardString | void> {
        const METADATA_PREFIX = "metadata/";
        let auxQuery: AuxQuery | undefined;

        if (q.startsWith(METADATA_PREFIX)) {
            const fullKey = q.replace(METADATA_PREFIX, "");
            const keyPath = fullKey.split(".");

            assert(nonEmptyArray(keyPath), `Invalid metadata key ${fullKey}`);

            const resolved = keyPath.reduce<MetadataDict | MetadataValue | undefined>(
                (obj, key) => obj && typeof obj === "object" && key in obj
                    ? (obj as MetadataDict)[key]
                    : undefined,
                this.metadata,
            );

            if (exists(resolved)) {
                const stringValue = typeof resolved === "object"
                    ? JSON.stringify(resolved)
                    : resolved.toString();

                return {
                    type: DashboardDataType.String,
                    text: stringValue,
                };
            }
        } else if (auxQuery = this.parseAuxQuery(q)) {
            return this.generateAuxSeries(auxQuery);
        } else {
            return (await super.select(`${this.productId}_${q}`))
                || (await super.select(q));
        }
    }

    public getExplorableGroups(id: string): [ExplorableGroup] {
        return [{
            id,
            typeName: "Datacube Product",
            name: this.stringOrNone(this.metadata.shortName)
                || this.stringOrNone(this.metadata.productName)
                || this.productId,
            items: Object.values(this.seriesSet.set)
                .map(series => ({
                    id: series.meta.uid,
                    name: this.makePrettySeriesName(id, series.meta.name),
                    data: series,
                })),
            buttons: [
                {
                    name: "Show Product Metadata",
                    onClick: () => this.openMetadata(),
                    icon: "news",
                },
                {
                    name: "Download data dictionary as CSV",
                    onClick: () => this.downloadProductSalesInfoCsv(),
                    icon: "csv",
                }],
        }];
    }


    public async downloadProductSalesInfoCsv(): Promise<void> {
        const salesInfo = await this.datacubeClient
            .getProductSalesInfo({productId: this.productId});

        download(salesInfo, sanitizeFilename(`data_dictionary_${this.productId}.csv`));
    }

    public openMetadata(): Promise<void> {
        this.matDialog.open<MetadataDialogComponent, MetadataDict>(
            MetadataDialogComponent,
            {
                data: this.metadata,
                panelClass: "mat-dialog-no-padding",
            },
        );
        return Promise.resolve();
    }

    private stringOrNone(input: any): string | undefined {
        return typeof input === "string" ? input : void 0;
    }

    private makePrettySeriesName(productId: string, seriesId: string): string {
        return seriesId.replace(productId, "").replace(/_/g, " ");
    }

    private async generateAuxSeries(q: AuxQuery): Promise<SeriesLike> {
        switch (q.auxName) {
            case "confidence_interval":
                const origSeries = await this.requireSingleSeries(q.timeseries);
                const stdSeries = await this.requireSingleSeries(`${q.timeseries}_std`);

                return this.calculateConfidenceInterval(origSeries, stdSeries);

            default:
                const msg = `Cannot create unknown aux series "${q.auxName}" for series "${q.timeseries}"`;
                throw new Error(msg);
        }
    }

    /**
     * Calculate confidence interval (collection containing two series for area
     * visualization) using given series.
     * Post with the calculation explained for reference:
     * https://spacek.slack.com/archives/C02186FEZAP/p1637327181086300
     */
    private calculateConfidenceInterval(
        origSeries: DashboardSeries,
        stdSeries: DashboardSeries,
    ): DashboardSeriesPair {
        const minSeries = origSeries.series.map((point, i) => ({
            y: point.y - stdSeries.series[i].y * 2,
            x: point.x,
        }));
        const maxSeries = origSeries.series.map((point, i) => ({
            y: point.y + stdSeries.series[i].y * 2,
            x: point.x,
        }));

        return {
            type: DashboardDataType.SeriesPair,
            meta: {
                uid: `${origSeries.meta.uid}_confidence_interval`,
                name: `95% Confidence Interval`,
                pairSuffix: ["Min", "Max"],
                icon: "stock",
            },
            pair: [
                minSeries,
                maxSeries,
            ],
        };
    }

    /**
     * Parse query for auxiliary timeseries, that are computed to accompany
     * other available timeseries the product offers.
     * e.g. confidency interval, which is computed from std deviation
     * timeseries
     *
     * Aux query format: aux/ORIGINAL_TIMESERIES_NAME/AUX_TYPE
     * Example: aux/SK_AGR_CUR_MAN_SAI_IN_D_30d_benchmark_prediction_te_manufacturing_pmi/confidence_interval
     */
    private parseAuxQuery(query: string): AuxQuery | undefined {
        const exp = /^aux\/([^/]+)\/([^/]+)/;
        const result = query.match(exp);

        if (result && result.length === 3) {
            return {
                timeseries: result[1],
                auxName: result[2],
            };
        } else {
            return undefined;
        }
    }

    /**
     * Select series of given name or throw if it doesn't exist
     */
    private async requireSingleSeries(seriesQuery: string): Promise<DashboardSeries> {
        const series = await super.select(seriesQuery);

        assert(series, `Timeseries ${seriesQuery} doesn't exist`);
        assertSeries(series);

        return series;
    }
}

/**
 * Load timeseries from Datacube Product API.
 *
 * Single timeseries can be selected by simply querying its name like so:
 * `source_id/SK_MAN_MET_ALU_STO_SAI_DE_D_24d_index_level`. For such products
 * AoI information is taken from the observedLocation.locationsCovered value
 * in metadata.
 *
 * Multiple timeseries can be selected using wildcard. So for example the
 * following query selects all index_level timeseries
 * `source_id/*_index_level`
 *
 * If the timeseries included a AoI information, the aoi code is appended in
 * square brackets to the timeseries name like so:
 * `timeseries_name[aoi_code]`
 *
 * This way you may query single timeseries on single aoi:
 * `pollution-de-source/SK_PI_R_DE_W_NO2[de-saxony]`
 *
 * Or again you may use an asterisk to select more than one aoi:
 * `pollution-de-source/SK_PI_R_DE_W_NO2[*]`
 *
 * Product metadata are available with the metadata/query like so:
 * `metadata/any_metadata_name`
 *
 * Additional auxiliary series can be requested for selected timeseries using
 * following format:
 * `aux/original_timeseries/aux_series_name`
 * so for example with products that contain benchmark predition and its
 * standard deviation, a confidence interval can be requested like:
 * `aux/SK_AGR_CUR_MAN_SAI_IN_D_30d_benchmark_prediction_te_manufacturing_pmi/confidence_interval`
 */
export interface ProductSourceDef {
    type: ProductSourceType;
    /**
     * @example "SK_PI_R_DE_W"
     */
    productId: string;

    /**
     * Hide this source from data explorer
     */
    omitFromDataExplorer?: boolean;
}

interface AuxQuery {
    timeseries: string;
    auxName: string;
}

const ProductSourceType: ProductSourceType = "datacube/product";
type ProductSourceType = "datacube/product";

type MetadataValue = boolean | object | number | string | MetadataDict;
export type MetadataDict = {
    [k in string]: MetadataValue;
};
