import { LinearScale } from "chart.js";

import { ComponentDef, DataRef } from "skCommon/insights/dashboard";

import { CardComponentDef } from "skInsights/framework/card/cardComponent";
import { LineChartComponentType } from "skInsights/modules/charts/lineChart/lineChart.component";
import { DashboardSeries, DashboardSeriesPair } from "skInsights/framework/data/structures";
import { DatasetInternalOptions } from "skInsights/modules/charts/helpers/chartOptions";

/**
 * Line chart component supporting events, multiple scales, tabs.
 *
 * @listens highlight Highlight event of given ID
 * @listens show Show series with given ID
 * @listens hide Hide series with given ID
 */
export interface LineChartComponentDef extends CardComponentDef {
    component: LineChartComponentType;
    series: ChartSeriesDef[];
    /**
     * List of available tabs to which the series may be assigned.
     */
    tabs?: Record<string, ChartTab>;
    /**
     * Dictionary of custom liner scale axes
     * @see https://www.chartjs.org/docs/master/axes/cartesian/linear
     */
    axes?: Record<string, LinearScale>;
    /**
     * Events to display on the graph as vertical dashed lines.
     */
    events?: Record<string, ChartEventDef>;
    /**
     * Controls behaviour of the event toggle button
     *
     * @default "enabled"
     */
    eventsState?: EventsState;
    /**
     * momentjs date format to use in the chart tooltip.
     *
     * @see https://momentjs.com/docs/#/parsing/string-format/
     * @default "YYYY-MM-DD"
     */
    dateFormat?: string;
    /**
     * Initial displayed date range. If not specified, it is computed from
     * displayed data instead. Note that the user may still zoom out to see
     * data beyond this limit.
     *
     * @example { "min": "2010", "max": "2020-02-03" }
     * @example { "max": "2020" }
     */
    dateRange?: {
        /**
         * @datestring
         */
        min?: string;
        /**
         * @datestring
         */
        max?: string;
    };
}

export interface ChartTab {
    /**
     * Name of the tab visible to the user
     * @interpolated
     */
    name: string;
    /**
     * Icon displayed next to the tab name. For flags use alpha-2 codes, e.g. `de`
     */
    icon?: string;
}

export interface ChartEventDef {
    /**
     * Event's date (position on X-axis)
     * @datestring
     * @interpolated
     */
    date: string;
    /**
     * Color of the event line and label
     */
    color?: string;
    /**
     * Label displayed on top of the chart. Timeseries name provided by the
     * source is used if label not provided.
     * @interpolated
     */
    label?: string;
    /**
     * Content of dialog displayed when event is clicked. If not specified the
     * event will ignore any mouse interaction
     */
    detail?: ComponentDef;
}

export interface ChartSeriesDef {
    /**
     * Data source to plot
     * @dataref
     */
    data: DataRef;
    /**
     * Label used in chart legend. Legend is hidden when no series has label
     * defined.
     * @interpolated
     */
    label?: string;
    /**
     * Label used when exporting as e.g. CSV. `label` is used when not provided.
     * @interpolated
     */
    exportLabel?: string;
    /**
     * More verbose detailed description of the series. Displayed in a tooltip
     * when legend is hovered.
     * @interpolated
     */
    detail?: string;
    /**
     * Color is automatically assigned if not defined
     */
    color?: string;
    /**
     * Either a predefined axis such as `y-left-0`, `y-left-1`, `y-right-0`,
     * `y-right-1` or any axis defined in the `axes` property.
     */
    scale?: string;
    /**
     * Whether the series should be hidden by default
     */
    disabled?: boolean;
    /**
     * Only display this series when given tab is active. Tab needs to exist in
     * the `tabs` dictionary. If tabs dictionary exists but the series has no
     * tab assigned it will be visible on all tabs.
     */
    tab?: string;
    /**
     * Line width
     * @default 1.5
     */
    width?: number;
    /**
     * Dash lengths
     * @example `[1, 5]` would create pretty much dash - dot pattern
     */
    dash?: number[];
    /**
     * Spacing between each dash
     */
    dashOffset?: number;
    /**
     * Name of the auxillary timeseries that should accompany this one. This
     * aux timeseries will added to the chart with the option to hide it, but
     * it cannot be displayed with the main timeseries hidden.
     * @example `"confidence_interval"`
     */
    aux?: string;
    /**
     * Disable aux series by default
     */
    auxDisabled?: boolean;
}

export enum EventsState {
    /**
     * Always enabled - toggle is hidden and events cannot be disabled
     */
    Always = "always",
    /**
     * Toggle is displayed and enabled by default
     */
    Enabled = "enabled",
    /**
     * Toggle is displayed and disabled by default
     */
    Disabled = "disabled",
}

/**
 * Input for single chart.js dataset -- for series pair may still actually
 * represent multiple chart timeseries
 */
export interface ChartInputSeries {
    series: DashboardSeries | DashboardSeriesPair;
    options: DatasetInternalOptions;
}

/**
 * All data and options related to single dataset defined in the chart
 * definition -- may contain multiple series
 */
export interface ChartDatasetInput {
    def: ChartSeriesDef;
    /**
     * Chart series created out of this dataset
     */
    inputSeries: {
        main: ChartInputSeries;
        aux?: ChartInputSeries;
    };
}
