import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { makeObservable } from "mobx";

import { mergeTimeseries } from "skCommon/utils/timeseries";
import { observableRef } from "skCommon/state/mobxUtils";
import { Logger } from "skCommon/utils/logger";
import { DataRef } from "skCommon/insights/dashboard";

import { LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { CardComponent, CardComponentDef } from "skInsights/framework/card/cardComponent";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { CardService } from "skInsights/framework/card/card.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";

export const SimpleTableComponentType: SimpleTableComponentType = "tables/simple-table";
export type SimpleTableComponentType = "tables/simple-table";

@Component({
    selector: "sk-simple-table",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./simpleTable.pug",
    providers: [CardService],
})
export class SimpleTableComponent extends CardComponent<SimpleTableComponentDef> {

    @observableRef
    public rows: TableRow[] = [];

    public header: string[];

    constructor(
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        protected readonly def: SimpleTableComponentDef,
        private dataRefService: DataRefService,
        private logger: Logger,
        private snackBarService: SnackBarService,
        cardService: CardService,
    ) {
        super(cardService);
        makeObservable(this);

        const cols = this.def.columns;

        this.header = cols.map(col => col.title);

        this.initRows().catch(e => {
            this.logger.error(e, "Chart initialization");
            this.snackBarService.notify(`Chart initialization failed: ${e}`);
        });
    }

    private async initRows(): Promise<void> {
        const cols = this.def.columns;

        const resolved = await Promise.all(
            cols.map(col => this.dataRefService.resolveSeries(col.data)),
        );

        const timeseries = resolved.map(data => data.series);

        this.rows = mergeTimeseries(timeseries, "x", "y")
            .map(({ x: date, y: values }) => ({ date, values }));
    }
}

/**
 * Simple table with first column containing the value's datetime and the rest
 * of the columns containing provided timeseries.
 */
interface SimpleTableComponentDef extends CardComponentDef {
    component: SimpleTableComponentType;
    columns: SimpleChartColumn[];
}

interface SimpleChartColumn {
    /**
     * Data to display. Only supports single timeseries.
     * @dataref
     */
    data: DataRef;
    /**
     * Column title
     *
     * @interpolated
     */
    title: string;
}

interface TableRow {
    date: Date;
    values: number[];
}
