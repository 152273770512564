import { Component, ChangeDetectionStrategy, Input, Injector, ViewChild, ElementRef, OnInit, Output, EventEmitter } from "@angular/core";

import { ComponentDef } from "skCommon/insights/dashboard";

import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { RenderableComponent } from "skInsights/framework/renderable";
import { POPUP_OVERLAY_CONTAINER_TOKEN } from "skInsights/partials/popup/config";

/**
 * Component used internally by the Slides layout component. Represents single
 * slide rendering its child component and providing container for material
 * overlays.
 */
@Component({
    selector: "sk-slide-container",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./slideContainer.pug",
})
export class SlideContainerComponent implements OnInit {

    @Input()
    public def?: ComponentDef;

    @Input()
    public rendered?: boolean;

    @Input()
    public last?: boolean;

    @Output()
    public next: EventEmitter<void> = new EventEmitter();

    public renderable?: RenderableComponent;

    @ViewChild("overlayContainer")
    public overlayContainerRef?: ElementRef<HTMLElement>;

    constructor(
        private moduleRegistryService: ModuleRegistryService,
        private injector: Injector,
    ) { }

    public ngOnInit(): void {
        if (!this.def) {
            throw new Error("Slide needs to be initiated with component definition");
        }

        this.renderable = {
            component: this.moduleRegistryService.getAngularComponent(this.def),
            injector: Injector.create({
                parent: this.injector,
                providers: [
                    {
                        provide: LAYOUT_COMPONENT_DEF_TOKEN,
                        useValue: this.def,
                    },
                    {
                        provide: POPUP_OVERLAY_CONTAINER_TOKEN,
                        useValue: () => this.overlayContainerRef?.nativeElement,
                    },
                ],
            }),
        };
    }

    public scrollToNext(): void {
        this.next.emit();
    }
}
