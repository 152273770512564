import { NgModule } from "@angular/core";

import { AutorunDirective } from "skCommon/angular/state/autorun.directive";

@NgModule({
    declarations: [
        AutorunDirective,
    ],
    exports: [
        AutorunDirective,
    ],
})
export class AutorunModule { }
