import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MatChipsModule } from "@angular/material/chips";
import { ClipboardModule } from "@angular/cdk/clipboard";

import { ManagerClient } from "skCommon/auth0/manager/client";
import { Insights } from "skCommon/permissions";

import { AllDashboardComponent } from "skInsights/admin/all-dashboards/allDashboard.component";
import { CustomerPageComponent } from "skInsights/admin/customerPage/customerPage.component";
import { DeliveriesComponent } from "skInsights/admin/deliveries/deliveries.component";
import {
    ManageSubscriptionDialog,
} from "skInsights/admin/manageSubscriptionsDialog/manageSubscriptionDialog";
import { DashboardModule } from "skInsights/dashboard/dashboard.module";
import { SharedModule } from "skInsights/shared.module";
import { AuthGuard, AuthGuardData } from "skInsights/user/auth.guard";
import { Admin, AdminRoutes } from "skInsights/admin/admin.routing";
import { ExternalSourcesComponent } from "skInsights/admin/externalSources.component";
import { GrantAccessComponent } from "skInsights/admin/grantAccess.component";
import { CustomerManagementComponent } from "skInsights/admin/customerManagement.component";
import { MigratorComponent } from "skInsights/admin/migrator/migrator.component";
import { AddCustomerDialogComponent } from "skInsights/admin/addCustomer/addCustomerDialog.component";

const RoutingModule = RouterModule.forChild([
    {
        path: Admin,
        children: [
            {
                path: AdminRoutes.Deliveries,
                component: DeliveriesComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [Insights.Admin.Deliveries],
                } as AuthGuardData,
            },
            {
                path: AdminRoutes.ExternalSources,
                component: ExternalSourcesComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [Insights.Admin.ExternalSources],
                } as AuthGuardData,
            },
            {
                path: AdminRoutes.GrantAccess,
                component: GrantAccessComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [Insights.Admin.GrantAccess],
                } as AuthGuardData,
            },
            {
                path: AdminRoutes.CustomerManagement,
                component: CustomerManagementComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [Insights.Admin.ManageCustomers],
                } as AuthGuardData,
            },
            {
                path: `${AdminRoutes.CustomerManagement}/:id`,
                component: CustomerPageComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [Insights.Admin.ManageCustomers],
                } as AuthGuardData,
            },
            {
                path: AdminRoutes.Migrator,
                component: MigratorComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [Insights.Admin.Migrate],
                } as AuthGuardData,
            },
            {
                path: AdminRoutes.AllDashboards,
                component: AllDashboardComponent,
                canActivate: [AuthGuard],
                data: {
                    permissions: [Insights.Admin.ManageCustomers],
                } as AuthGuardData,
            },
        ],
    },
]);

@NgModule({
    imports: [
        SharedModule,
        RoutingModule,
        MatChipsModule,
        ReactiveFormsModule,
        ClipboardModule,
        DashboardModule,
    ],
    declarations: [
        ExternalSourcesComponent,
        GrantAccessComponent,
        CustomerManagementComponent,
        MigratorComponent,
        CustomerPageComponent,
        ManageSubscriptionDialog,
        DeliveriesComponent,
        AddCustomerDialogComponent,
        AllDashboardComponent,
    ],
    providers: [
        ManagerClient,
    ],
})
export class AdminModule { }
