import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { from } from "rxjs";
import { map, mergeMap, toArray } from "rxjs/operators";

import { adaptFirestoreDoc } from "skCommon/firebase/doc";
import { externalSourcesData } from "skCommon/insights/database";
import { ExternalSourceData } from "skCommon/insights/externalSources";

import { DashboardSource } from "skInsights/framework/abstract/dashboardSource";
import { SeriesSetProvider } from "skInsights/framework/data/providers/seriesSetProvider";
import { DashboardDataType, DashboardSeries, DataCopyright } from "skInsights/framework/data/structures";

const ONS_COPYRIGHT: DataCopyright = {
    text: "Dataset by Office for National Statistics",
    url: "https://www.ons.gov.uk/",
};

@Injectable()
export class OnsSource extends DashboardSource<OnsSourceDef> {

    public type: string = "external/ons";

    constructor(
        private angularFirestore: AngularFirestore,
    ) {
        super();
    }

    public create(def: OnsSourceDef): Promise<SeriesSetProvider> {
        return from(def.cdids).pipe(
            map(id => externalSourcesData("ons", id)),
            mergeMap(path => this.angularFirestore.doc(path).get()),
            map(snapshot => {
                const data = adaptFirestoreDoc<ExternalSourceData>(snapshot);

                return [data.id, {
                    type: DashboardDataType.Series,
                    series: data.series,
                    meta: {
                        name: data.name,
                        copyright: ONS_COPYRIGHT,
                        viewOnly: true,
                    },
                } as DashboardSeries];
            }),
            toArray(),
            map(entries => {
                return new SeriesSetProvider({
                    type: DashboardDataType.Collection,
                    set: Object.fromEntries(entries),
                });
            }),
        ).toPromise();
    }
}

/**
 * Source which loads selected series from Index of Production time series
 * by the ONS. https://www.ons.gov.uk/
 *
 * All provided series are flagged `viewOnly` meaning no component should
 * allow export of raw data.
 */
interface OnsSourceDef {
    type: OnsSourceType;
    /**
     * List of series to load. The cdids should be equally formatted as they
     * are in the CSV.
     *
     * @example "benchmark-source/KUL7"
     */
    cdids: string[];
}

const OnsSourceType: OnsSourceType = "external/ons";
type OnsSourceType = "external/ons";
