import { LoggerTransport, LogLevel, LogEntry } from "skCommon/utils/logger";

const LEVEL_METHODS: { [k in LogLevel]: keyof Console } = {
    [LogLevel.Debug]: "debug",
    [LogLevel.Info]: "info",
    [LogLevel.Warning]: "warn",
    [LogLevel.Error]: "error",
};

const SEVERITY_STYLE: { [k in LogLevel]: string } = {
    [LogLevel.Debug]: "color: gray",
    [LogLevel.Info]: "color: green",
    [LogLevel.Warning]: "color: yellow",
    [LogLevel.Error]: "color: red",
};

export class ConsoleTransport implements LoggerTransport {
    /**
     * @param ignoredBoundFields List of bound fields that should not be
     *  displayed in the console.
     */
    constructor(private ignoredBoundFields: string[] = []) { }

    public log(logEntry: LogEntry) {
        const { level } = logEntry;

        const levelMethod = LEVEL_METHODS[level];
        const style = SEVERITY_STYLE[level];

        const context = Object.entries(logEntry.context)
            .filter(([key]) => !this.ignoredBoundFields.includes(key))
            .map(([key, val]) => ["\n    ◦ " + key + ":", val])
            .flat();

        const message = ["%c•", style, logEntry.message, logEntry.details, ...context]
            .filter(d => d);

        if (level === LogLevel.Error && "stack" in logEntry.details) {
            // Firefox only displays the stack correctly if its part of the
            // Error instance.
            const errorToLog = new Error();
            const prefix = logEntry.details?.errorContext
                ? `[${logEntry.details.errorContext}] `
                : "";

            errorToLog.name = logEntry.details.type;
            errorToLog.stack = logEntry.details.stack;
            errorToLog.message = `${prefix}${logEntry.message}`;

            // Include all additional details
            const msgDetails = Object.entries(logEntry.details)
                .filter(e => e[0] !== "type" && e[0] !== "stack")
                .map(([k, v]) => `${k}: ${v}`)
                .join("\n");
            if (msgDetails) {
                errorToLog.message += `\n${msgDetails}`;
            }

            // tslint:disable-next-line:no-console
            console.error(errorToLog);
        } else {
            console[levelMethod](...message);
        }
    }
}
