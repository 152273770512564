import EditorJS, { EditorConfig, ToolConstructable, ToolSettings } from "@editorjs/editorjs";

import { BASE_TOOLS } from "skInsights/partials/blockEditor/baseTools";

/**
 * Wrapper around the EditorJS constructor which accepts list of plugins so new
 * functionality can be easily added without the need to touch the config.
 */
export function createEditorJs(
    config: Omit<EditorConfig, "tools">,
    plugins: EditorJsPlugin[] = [],
) {
    let tools = { ...BASE_TOOLS };

    for (const plugin of plugins) {
        if (plugin.getTools) {
            tools = {
                ...tools,
                ...plugin.getTools(),
            };
        }
    }

    return new EditorJS({
        ...config,
        tools,
        minHeight: 40,
    });
}

export interface EditorJsPlugin {
    getTools?(): Record<string, ToolConstructable | ToolSettings>;
}
