import {
        ChangeDetectionStrategy,
        Component,
        EventEmitter,
        Input,
        Output,
} from "@angular/core";
import {
    DateTime,
} from "luxon";

import {
    CalendarDialogData,
    WeekDayNumber,
} from "skCommon/datePicker/model";

@Component({
    selector: "sk-date-picker-calendar-selectable-daytimes-grid",
    templateUrl: "./datePickerCalendarSelectableDaytimesGrid.pug",
    styleUrls: ["./datePickerCalendarSelectableDaytimesGrid.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerCalendarSelectableDaytimesGridComponent {

    @Input()
    public calendarDialogData: CalendarDialogData | undefined = undefined;

    @Input()
    public activeDateTime: DateTime = DateTime.now();

    @Output()
    public newDateTimeSelected = new EventEmitter<DateTime>();

    public get selectedDateTime(): DateTime | undefined {
        return this.calendarDialogData?.selectedDateTime;
    }

    public get minDateTime(): DateTime | undefined {
        return this.calendarDialogData?.minDateTime;
    }

    public get maxDateTime(): DateTime | undefined {
        return this.calendarDialogData?.maxDateTime;
    }

    public get disableToday(): boolean | undefined {
        return this.calendarDialogData?.options?.disableToday;
    }

    public get disableFutureDates(): boolean| undefined {
        return this.calendarDialogData?.options?.disableFutureDates;
    }

    public get selectableDateTimes(): DateTime[] {
        return this.defineDateTimeForEachDayInMonth(this.activeDateTime);
    }

    public get previousMonthsOverflownDateTimes(): DateTime[] {
        const dateTimes: DateTime[] = [];
        const firstDateTimeInMonth = this.selectableDateTimes[0];

        if (firstDateTimeInMonth) {
            const firstWeekDayNumber = firstDateTimeInMonth.weekday;

            if (firstWeekDayNumber !== WeekDayNumber.Sunday) {
                for (let j = 1; j <= firstWeekDayNumber; j++) {
                    const overflownPreviousMonthsDaysInFirstMonthWeek
                        = firstDateTimeInMonth.minus({days: j});
                    dateTimes.unshift(overflownPreviousMonthsDaysInFirstMonthWeek);
                }
            }
        }

        return dateTimes;
    }

    public get nextMonthsOverflownDateTimes(): DateTime[] {
        const dateTimes: DateTime[] = [];
        const lastDateTimeInMonth = this.selectableDateTimes[this.selectableDateTimes.length - 1];

        if (lastDateTimeInMonth) {
            const lastWeekDayNumber = lastDateTimeInMonth.weekday;

            if (lastWeekDayNumber !== WeekDayNumber.Saturday) {
                const numberOfOverflownNextMonthDays = lastWeekDayNumber === WeekDayNumber.Sunday
                    ? 7 : 7 - lastWeekDayNumber;

                for (let j = 1; j < numberOfOverflownNextMonthDays; j++) {
                    const overflownNextMonthsDaysInLaststMonthWeek
                        = lastDateTimeInMonth.plus({days: j});
                    dateTimes.push(overflownNextMonthsDaysInLaststMonthWeek);
                }
            }
        }

        return dateTimes;
    }

    private defineDateTimeForEachDayInMonth(dateTime: DateTime): DateTime[] {
        const numberOfDaysInMonth = dateTime.daysInMonth;
        const firstDayInMonth = dateTime.set({day: 1});

        return Array.from(Array(numberOfDaysInMonth).keys())
            .map((dayIndexedSlot: number) => {
                return firstDayInMonth.plus({ days: dayIndexedSlot });
            });
    }

    public isSelectedDateTime(dateTime: DateTime): boolean {
        return dateTime.toISODate() === this.selectedDateTime?.toISODate();
    }

    public isToday(dateTime: DateTime): boolean {
        return dateTime.toISODate() === DateTime.now().toISODate();
    }

    public isDisabledToday(dateTime: DateTime): boolean {
        return !!this.disableToday && this.isToday(dateTime);
    }

    public isDisabledFutureDay(dateTime: DateTime): boolean {
        return !!this.disableFutureDates && dateTime.diffNow("days").days > 0;
    }

    public isDisabledMinDay(dateTime: DateTime): boolean {
        let disabled = false;

        if (this.minDateTime) {
            disabled = dateTime.diff(this.minDateTime, "days").days < 0;
        }

        return disabled;
    }

    public isDisabledMaxDay(dateTime: DateTime): boolean {
        let disabled = false;

        if (this.maxDateTime) {
            disabled = dateTime.diff(this.maxDateTime, "days").days > 0;
        }

        return disabled;
    }

    public isDisabled(dateTime: DateTime): boolean {
        return this.isDisabledToday(dateTime) ||
            this.isDisabledFutureDay(dateTime) ||
            this.isDisabledMinDay(dateTime) ||
            this.isDisabledMaxDay(dateTime);
    }

    public selectDateTime(dateTime: DateTime): void {
        this.newDateTimeSelected.emit(dateTime);
    }
}
