import { ChangeDetectionStrategy, Component } from "@angular/core";

import { Logger } from "skCommon/utils/logger";

import { ClientDelivery, CsvExportType } from "skInsights/clientDelivery/clientDelivery";
import { CHINA_DELIVERY } from "skInsights/clientDelivery/deliveries/chinaDelivery";
import { MALAYSIA_DELIVERY } from "skInsights/clientDelivery/deliveries/malaysiaDelivery";
import { ClientDeliveryReportService } from "skInsights/clientDelivery/downloadDelivery.service";
import { InsightsService } from "skInsights/insights.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";

const CREATING_REPORT = Symbol();

@Component({
    selector: "sk-deliveries",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./deliveries.pug",
})
export class DeliveriesComponent {

    public readonly DATA = CsvExportType.DATA;
    public readonly FORECAST = CsvExportType.FORECAST;

    public deliveries: ClientDelivery[] = [
        MALAYSIA_DELIVERY,
        CHINA_DELIVERY,
    ];

    constructor(
        private clientDeliveryReportService: ClientDeliveryReportService,
        private insightsService: InsightsService,
        private logger: Logger,
        private snackBarService: SnackBarService,
    ) { }

    public async runDelivery(delivery: ClientDelivery, csvType?: CsvExportType): Promise<void> {
        this.insightsService.globalLoadingProcesses.add(CREATING_REPORT);

        try {
            await this.clientDeliveryReportService.runDelivery(delivery, csvType);
        } catch (e) {
            this.snackBarService.notify(e.message);
            this.logger.error(e);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(CREATING_REPORT);
        }
    }
}
