import destination from "@turf/destination";
import distance from "@turf/distance";
import bbox from "@turf/bbox";
import bboxPolygon from "@turf/bbox-polygon";
import { point, featureCollection } from "@turf/helpers";
import { LngLatBounds } from "mapbox-gl";

import { round } from "skCommon/utils/data";

/**
 * @class Bbox
 */
export class Bbox implements BboxProps {
    private _xmax: number;
    private _xmin: number;
    private _ymin: number;
    private _ymax: number;

    private _center: GeoJSON.Position = null;

    get xmax(): number {
        return this._xmax;
    }
    set xmax(val: number) {
        this._xmax = val;
        this._center = null;
    }

    get xmin(): number {
        return this._xmin;
    }
    set xmin(val: number) {
        this._xmin = val;
        this._center = null;
    }

    get ymin(): number {
        return this._ymin;
    }
    set ymin(val: number) {
        this._ymin = val;
        this._center = null;
    }

    get ymax(): number {
        return this._ymax;
    }
    set ymax(val: number) {
        this._ymax = val;
        this._center = null;
    }

    get center(): GeoJSON.Position {
        if (!this._center) {
            this._center = [
                this.xmin + (this.xmax - this.xmin) / 2,
                this.ymin + (this.ymax - this.ymin) / 2,
            ];
        }

        return this._center;
    }

    get width(): number {
        return this.xmax - this.xmin;
    }

    get height(): number {
        return this.ymax - this.ymin;
    }

    get topLeft(): GeoJSON.Position {
        return [this.xmin, this.ymax];
    }

    get topRight(): GeoJSON.Position {
        return [this.xmax, this.ymax];
    }

    get bottomLeft(): GeoJSON.Position {
        return [this.xmin, this.ymin];
    }

    get bottomRight(): GeoJSON.Position {
        return [this.xmax, this.ymin];
    }

    get top(): GeoJSON.Position {
        return [this.xmin + this.width / 2, this.ymax];
    }

    get right(): GeoJSON.Position {
        return [this.xmax, this.ymin + this.height / 2];
    }

    get bottom(): GeoJSON.Position {
        return [this.xmin + this.width / 2, this.ymin];
    }

    get left(): GeoJSON.Position {
        return [this.xmin, this.ymin + this.height / 2];
    }

    public get leftRightDistance() {
        if (this.top[1] > 0 && this.bottom[1] < 0) {
            // bbox crosses equator => there is gonna be the distance longest
            return calculateLatLonDistance(
                [this.xmin, 0],
                [this.xmax, 0],
            ) * 1000;
        } else if (Math.abs(this.top[1]) < Math.abs(this.bottom[1])) {
            // bbox's top is closer to equator
            return calculateLatLonDistance(this.topLeft, this.topRight) * 1000;
        } else {
            return calculateLatLonDistance(this.bottomLeft, this.bottomRight) * 1000;
        }
    }

    public get leftRightCenterDistance(): number {
        return calculateLatLonDistance(this.left, this.right) * 1000;
    }

    public get topBottomDistance(): number {
        return calculateLatLonDistance(this.top, this.bottom) * 1000;
    }

    public get longestSide(): number {
        return Math.max(this.leftRightDistance, this.topBottomDistance);
    }

    public get shortestSide(): number {
        return Math.min(this.leftRightDistance, this.topBottomDistance);
    }

    constructor(ymax?: number, xmax?: number, ymin?: number, xmin?: number) {
        this._xmax = xmax || 0;
        this._ymax = ymax || 0;
        this._xmin = xmin || 0;
        this._ymin = ymin || 0;
    }

    ///
    ///
    /// Public methods
    ///
    ///

    public toString(): string {
        return `Bbox(${this.ymax}, ${this.xmax}, ${this.ymin}, ${this.xmin})`;
    }

    /**
     * Resize this bbox to contain given bbox. If this bbox already contains
     * given bbox, do nothing.
     */
    public resizeToContain(target: Bbox): void {
        if (target.xmin < this.xmin) {
            this.xmin = target.xmin;
        }

        if (target.ymin < this.ymin) {
            this.ymin = target.ymin;
        }

        if (target.xmax > this.xmax) {
            this.xmax = target.xmax;
        }

        if (target.ymax > this.ymax) {
            this.ymax = target.ymax;
        }
    }

    /**
     * Adapt value of another bbox
     *
     * @param source Any kind of structure with bbox-like properties
     */
    public adapt(source: BboxProps): Bbox {
        this._ymax = source.ymax;
        this._xmax = source.xmax;
        this._ymin = source.ymin;
        this._xmin = source.xmin;

        this._center = null;

        return this;
    }

    public contains(containee: GeoJSON.Position) {
        return Bbox.overlap(
            this,
            new Bbox(containee[1], containee[0], containee[1], containee[0]),
        );
    }

    public containsBbox(containee: Bbox) {
        return containee.xmax < this.xmax
            && containee.xmin > this.xmin
            && containee.ymax < this.ymax
            && containee.ymin > this.ymin;
    }

    /**
     * Creates new Bbox instance from GeoJSON bbox primitive
     *
     * 0: left
     * 1: bottom
     * 2: right
     * 3: top
     *
     * @returns {GeoJSON.BBox} - 0: left, 1: bottom,
     * 2: right, 3: top
     */
    public toGeoJson(): GeoJSON.BBox {
        return [this.xmin, this.ymin, this.xmax, this.ymax];
    }

    public toPolygonFeature(id?: string) {
        const feature = bboxPolygon(this.toGeoJson());

        if (!!id) {
            feature.id = id;
        }

        return feature;
    }

    public toLine(): GeoJSON.Position[] {
        return [
            this.topLeft,
            this.topRight,
            this.bottomRight,
            this.bottomLeft,
        ];
    }

    /**
     * Create Bbox that encloses circle specified by center and point on circle
     * The resulting bounding box will enclose the circle
     */
    public static fromCenterAndPoint(
        center: GeoJSON.Position,
        pointOnCircle: GeoJSON.Position,
    ) {
        const centerPoint = point(center);
        const radius = distance(
            centerPoint,
            point(pointOnCircle),
            { units: "meters" },
        );

        return this.fromCenterAndRadius(center, radius);
    }

    /**
     * Create Bbox that encloses circle specified by center and radius in meters
     * The resulting bounding box will enclose the circle
     */
    public static fromCenterAndRadius(
        center: GeoJSON.Position,
        radius: number,
    ) {
        const centerPoint = point(center);

        const left = destination(centerPoint, radius, -90, { units: "meters" });
        const right = destination(centerPoint, radius, 90, { units: "meters" });
        const top = destination(centerPoint, radius, 0, { units: "meters" });
        const bottom = destination(centerPoint, radius, 180, { units: "meters" });

        const bBox = bbox(featureCollection([top, right, bottom, left]));

        return Bbox.fromGeoJson(<[number, number, number, number]>bBox);
    }

    public static fromLngLatBounds(bounds: LngLatBounds): Bbox {
        return new Bbox(
            bounds.getNorth(),
            bounds.getEast(),
            bounds.getSouth(),
            bounds.getWest(),
        );
    }

    ///
    ///
    /// Static methods
    ///
    ///

    public static from(source: Bbox) {
        return new Bbox(source.ymax, source.xmax, source.ymin, source.xmin);
    }

    /**
     * Creates new Bbox instance from GeoJSON bbox primitive
     *
     * 0: left
     * 1: bottom
     * 2: right
     * 3: top
     *
     * @static
     * @param {GeoJsonBboxPrimitive} source - 0: left, 1: bottom,
     * 2: right, 3: top
     * @returns Bbox
     */
    public static fromGeoJson(source: GeoJSON.BBox) {
        if (source.length > 4) {
            throw new Error("SK BBox does not support 3D BBoxes");
        }
        return new Bbox(source[3], source[2], source[1], source[0]);
    }

    public static overlap(a: Bbox, b: Bbox) {
        const width = a.width;
        const height = a.height;
        const width2 = b.width;
        const height2 = b.height;
        const precision = 10;

        let xMidDiff: number,
            yMidDiff: number,
            overlaps: boolean;

        xMidDiff = Math.abs(a.xmin + width / 2 - b.xmin - width2 / 2);
        yMidDiff = Math.abs(a.ymin + height / 2 - b.ymin - height2 / 2);

        overlaps = round(2 * xMidDiff, precision) < round(width2 + width, precision)
            && round(2 * yMidDiff, precision) < round(height2 + height, precision);

        return overlaps;
    }

    public static fromVertices(vertices: GeoJSON.Position[]): Bbox {
        if (!(vertices instanceof Array) || vertices.length === 0) {
            throw new Error("Invalid Bbox#fromVertices call.");
        }

        let xmax = vertices[0][0];
        let ymax = vertices[0][1];
        let xmin = vertices[0][0];
        let ymin = vertices[0][1];

        for (let i = 1; i < vertices.length; i++) {
            const current = vertices[i];
            if (current[0] > xmax) {
                xmax = current[0];
            }
            if (current[1] > ymax) {
                ymax = current[1];
            }
            if (current[0] < xmin) {
                xmin = current[0];
            }
            if (current[1] < ymin) {
                ymin = current[1];
            }
        }

        return new Bbox(ymax, xmax, ymin, xmin);
    }

    /**
     * Transform point from one bbox to another bbox
     */
    public static transform(
        sourceBbox: Bbox,
        globalBbox: Bbox,
        sourcePoint: Point,
        flipY = false,
    ): Point {
        let divX,
            divY;

        divX = (sourcePoint.x - sourceBbox.xmin) / (sourceBbox.xmax - sourceBbox.xmin);
        divY = (sourcePoint.y - sourceBbox.ymin) / (sourceBbox.ymax - sourceBbox.ymin);

        if (isNaN(divX)) {
            divX = 0;
        }
        if (isNaN(divY)) {
            divY = 0;
        }

        const newPoint = {
            x: globalBbox.xmin + (globalBbox.xmax - globalBbox.xmin) * divX,
            y: globalBbox.ymin + (globalBbox.ymax - globalBbox.ymin) * divY,
        };

        if (flipY) {
            newPoint.y = globalBbox.height - newPoint.y;
        }

        return newPoint;
    }

    /**
     * Create Bbox instance containing all given bboxes
     */
    public static expand(bboxs: Bbox[]) {
        const bb = new Bbox(
                Number.NEGATIVE_INFINITY,
                Number.NEGATIVE_INFINITY,
                Number.POSITIVE_INFINITY,
                Number.POSITIVE_INFINITY,
            );

        for (const box of bboxs) {
            if (box.ymax > bb.ymax) {
                bb.ymax = box.ymax;
            }

            if (box.xmax > bb.xmax) {
                bb.xmax = box.xmax;
            }

            if (box.ymin < bb.ymin) {
                bb.ymin = box.ymin;
            }

            if (box.xmin < bb.xmin) {
                bb.xmin = box.xmin;
            }
        }

        return bb;
    }
}

export function calculatePointDistance(p1: Point, p2: Point) {
    const dimDist = calculateDimensionDistance(p1, p2);

    return Math.sqrt(
        Math.pow(dimDist.x, 2) + Math.pow(dimDist.y, 2),
    );
}

export function calculateDimensionDistance(p1: Point, p2: Point) {
    return {
        x: Math.abs(p1.x - p2.x),
        y: Math.abs(p1.y - p2.y),
    };
}

/**
 * Return distance between two LatLon points in kilometers.
 */
export function calculateLatLonDistance(
    p1: GeoJSON.Position,
    p2: GeoJSON.Position,
) {
    return distance(point(p1), point(p2));
}

/**
 * Transform and translate bbox so it does not collide with any of the provided
 * bboxes. Tries to keep original size if possible, bbox is transformed only
 * when translation alone is not enough.
 */
export function transformCollidingBbox(origBbox: Bbox, colBboxes: Bbox[])
        : Bbox {
    const enum ColDir {
        Top,
        Right,
        Bottom,
        Left,
    }

    const box = new Bbox().adapt(origBbox),
        reserves = [
            Number.POSITIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Number.POSITIVE_INFINITY,
            Number.POSITIVE_INFINITY,
        ];

    for (const collider of colBboxes) {
        const collisions = new Array(4),
            dists = new Array(4),
            width = Math.max(collider.width, box.width),
            height = Math.max(collider.height, box.height);

        dists[ColDir.Top] = box.ymax - collider.ymin,
        collisions[ColDir.Top] = dists[ColDir.Top] <= height
            && dists[ColDir.Top] > 0;

        dists[ColDir.Right] = box.xmax - collider.xmin, // 32
        collisions[ColDir.Right] = dists[ColDir.Right] <= width
            && dists[ColDir.Right] > 0;

        dists[ColDir.Bottom] = collider.ymax - box.ymin,
        collisions[ColDir.Bottom] = dists[ColDir.Bottom] <= height
            && dists[ColDir.Bottom] > 0;

        dists[ColDir.Left] = collider.xmax - box.xmin,
        collisions[ColDir.Left] = dists[ColDir.Left] <= width
            && dists[ColDir.Left] > 0;

        let bestDist = Number.POSITIVE_INFINITY,
            bestDistDir;

        for (let i = 0; i < 4; i++) {
            const collidesInOtherAxis = collisions[(4 + i + 1) % 4]
                || collisions[(4 + i - 1) % 4];

            if (collidesInOtherAxis) {
                if (collisions[i] && collidesInOtherAxis && bestDist > dists[i]) {
                    bestDist = dists[i];
                    bestDistDir = i;
                } else if (dists[i] >= 0) {
                    if (reserves[i] > dists[i]) {
                        reserves[i] = dists[i];
                    }
                }
            }
        }

        if (bestDistDir === ColDir.Top) {
            box.ymax -= bestDist;
            box.ymin -= Math.min(bestDist, reserves[ColDir.Top]);
            reserves[ColDir.Bottom] = 0;
        } else if (bestDistDir === ColDir.Right) {
            box.xmax -= bestDist;
            box.xmin -= Math.min(bestDist, reserves[ColDir.Right]);
            reserves[ColDir.Left] = 0;
        } else if (bestDistDir === ColDir.Bottom) {
            box.ymin += bestDist;
            box.ymax += Math.min(bestDist, reserves[ColDir.Bottom]);
            reserves[ColDir.Top] = 0;
        } else if (bestDistDir === ColDir.Left) {
            box.xmin += bestDist;
            box.xmax += Math.min(bestDist, reserves[ColDir.Left]);
            reserves[ColDir.Right] = 0;
        }
    }

    return box;
}

///
///
/// Interfaces
///
///

export interface BboxProps {
    xmax: number;
    xmin: number;
    ymax: number;
    ymin: number;
}

export interface Point {
    x: number;
    y: number;
}
