import { DatacubeFilter, DatacubeFilterType, DatacubeGetQuery } from "skCommon/datacube/client";
import { assert } from "skCommon/utils/assert";

/**
 * Helper class used to analyze product's series queries and generate a new
 * ones for the metrices which are not part of the product but are actually
 * automatically created for it.
 */
export class ProductMetricQueries {

    public static create(query: DatacubeGetQuery): ProductMetricQueries | undefined {
        // Find the requests with _std algorithm filter
        const stdAlgoFilterIndex = query.filters.findIndex(filter => (
            filter.field === "algorithm"
            && filter.type === DatacubeFilterType.ValueList
            && filter.params.values[0].includes("supervised")
            && filter.params.values[0].includes("_std")
        ));

        if (stdAlgoFilterIndex >= 0) {
            return new ProductMetricQueries(query, stdAlgoFilterIndex);
        } else {
            return undefined;
        }
    }

    private constructor(
        private query: DatacubeGetQuery,
        private stdAlgoFilterIndex: number,
    ) { }

    /**
     * Modify the original query to request metric of given type
     */
    public createMetric(metricName: MetricName): MetricQuery {
        const origAlgoFilter = this.query.filters[this.stdAlgoFilterIndex];

        assert(
            origAlgoFilter && origAlgoFilter.type === DatacubeFilterType.ValueList,
            "Incorrect filter type provided",
        );

        const originalAlgorithm = origAlgoFilter.params.values[0];
        const newAlgorithm = originalAlgorithm.replace("_std", `_${metricName}`);

        const newQuery = {
            ...this.query,
            filters: [
                ...this.getNonAlgorithmFilters(),
                {
                    field: "algorithm",
                    type: DatacubeFilterType.ValueList,
                    params: {
                        values: [newAlgorithm],
                    },
                },
            ],
        };

        return {
            query: newQuery as DatacubeGetQuery,
            label: METRIC_LABELS[metricName],
            name: metricName,
            algorithm: newAlgorithm,
        };
    }

    public getAllMetrics(): MetricQuery[] {
        return Object.values(MetricName)
            .map(metricName => this.createMetric(metricName));
    }

    private getNonAlgorithmFilters(): DatacubeFilter[] {
        const nonAlgoFilters = [...this.query.filters];

        nonAlgoFilters.splice(this.stdAlgoFilterIndex, 1);

        return nonAlgoFilters.filter(filter => filter.field !== "aoi");
    }
}

export interface MetricQuery {
    name: MetricName;
    label: string;
    query: DatacubeGetQuery;
    algorithm: string;
}

export enum MetricName {
    DirectionalSym = "directional_sym",
    IndexCorrelation = "correlation",
    IndexMape = "mape",
    IndexMda = "mda",
}

export const METRIC_LABELS: Record<MetricName, string> = {
    [MetricName.DirectionalSym]: "Directional symmetry",
    [MetricName.IndexCorrelation]: "Index correlation",
    [MetricName.IndexMape]: "Mean absolute percentage error",
    [MetricName.IndexMda]: "Multiple discriminant analysis",
};
