import { Type } from "@angular/core";

import { TaskedClient, TaskedResult, TaskedClientAction } from "skCommon/api/client/tasked";
import { TaskResponse } from "skCommon/tasking/pipeline";
import { Method, ResponseFormat } from "skCommon/core/http";
import { Pagination } from "skCommon/api/client/pagination";
import { pickIfExists } from "skCommon/utils/object";
import { PaginatedOptions } from "skCommon/api/client/simple";

export abstract class ApiClient extends TaskedClient {

    protected makeTaskedEndpoint<P, R>(opts: EndpointOptions<P>): TaskedEndpoint<P, R> {
        return createEndpoint<P, R>(this, opts);
    }

    // tslint:disable: max-line-length
    protected makeSimpleEndpoint<R>(opts: BasicSimpleEndpointOptions<undefined>): PayloadlessSimpleEndpoint<R>;
    protected makeSimpleEndpoint<P, R>(opts: BasicSimpleEndpointOptions<P>): SimpleEndpoint<P, R>;
    protected makeSimpleEndpoint<P, R>(opts: PaginatedSimpleEndpointOptions<P>): SimpleEndpoint<P, R, true>;
    protected makeSimpleEndpoint<P, R>(opts: SimpleEndpointOptions<P>): PayloadlessSimpleEndpoint<R> | SimpleEndpoint<P, R, boolean> {
    // tslint:enable: max-line-length
        return function (payload?: P) {
            let payloadOptions: Partial<PaginatedOptions> = {};
            const method = opts.method || Method.Post;
            const bodyfallback = method === Method.Get ? void 0 : {};

            if (opts.useParams) {
                payloadOptions = {
                    body: bodyfallback,
                    params: payload,
                };
            } else {
                payloadOptions = {
                    body: opts.before?.(payload) || payload || bodyfallback,
                };
            }

            const paginator = "pagination" in opts
                    ? new opts.pagination
                    : undefined;

            return (this as TaskedClient).call<R>({
                method,
                endpoint: opts.endpoint,
                paginator,
                ...pickIfExists(opts, "responseFormat"),
                ...payloadOptions,
            }).promise;
        }.bind(this);
    }
}

function createEndpoint<P, R>(
    scope: TaskedClient,
    opts: EndpointOptions<P>,
): TaskedEndpoint<P, R> {
    const pagination = "pagination" in opts ? opts.pagination : undefined;
    const mainCall = function (payload: P, pipelineId: string) {
        return (this as TaskedClient).taskedCall<R>({
            method: Method.Post,
            endpoint: opts.endpoint,
            body: opts.before?.(payload) || payload,
            pipelineId,
            paginator: pagination ? new pagination() : undefined,
        });
    }.bind(scope);

    const helpers: TaskedEndpointHelpers<P, R> = {
        initiate: function (payload: P) {
            return (this as TaskedClient).call<R>({
                method: Method.Post,
                endpoint: opts.endpoint,
                body: opts.before?.(payload) || payload,
                urlSuffix: TaskedClientAction.Initiate,
                paginator: pagination ? new pagination() : undefined,
            });
        }.bind(scope),
        retrieve: function (pipelineId: string) {
            return (this as TaskedClient).call<R>({
                method: Method.Post,
                endpoint: opts.endpoint,
                body: { pipelineId },
                urlSuffix: TaskedClientAction.Retrieve,
                paginator: pagination ? new pagination() : undefined,
            });
        }.bind(scope),
        resume: function (pipelineId: string) {
            return mainCall({}, pipelineId);
        }.bind(scope),
        retried: function (payload: P, pipelineId: string) {
            return (this as TaskedClient).taskedCall<R>({
                method: "POST",
                endpoint: opts.endpoint,
                body: opts.before?.(payload) || payload,
                pipelineId,
                pipelineRetries: 2,
                paginator: pagination ? new pagination() : undefined,
            });
        }.bind(scope),
        cold: function (payload: P) {
            return (this as TaskedClient).taskedCall<R>({
                method: Method.Post,
                endpoint: opts.endpoint,
                body: opts.before?.(payload) || payload,
                cold: true,
                paginator: pagination ? new pagination() : undefined,
            });
        }.bind(scope),
        resumeCold: function (pipelineId: string) {
            return (this as TaskedClient).taskedCall<R>({
                method: Method.Post,
                endpoint: opts.endpoint,
                body: {}, // not used since pipelineId was provided
                pipelineId,
                cold: true,
                paginator: pagination ? new pagination() : undefined,
            });
        }.bind(scope),
    };

    for (const [k, fn] of Object.entries(helpers)) {
        mainCall[k] = fn;
    }

    return mainCall;
}

export type SimpleEndpointOptions<T> = BasicSimpleEndpointOptions<T>
    | PaginatedSimpleEndpointOptions<T>;

export type EndpointOptions<T> = BasicEndpointOptions<T> | PaginatedEndpointOptions<T>;

export type BasicSimpleEndpointOptions<T> = BasicEndpointOptions<T> & SimpleAdditionalOptions;

export interface BasicEndpointOptions<T> {
    endpoint: string;
    responseFormat?: ResponseFormat;
    /**
     * Optional function which receives provided input data and may modify
     * them. Returned payload is then used as an actual payload send to the
     * API.
     */
    before?: (data: T) => any;
}

export type PaginatedSimpleEndpointOptions<T> = PaginatedEndpointOptions<T>
    & SimpleAdditionalOptions;

export interface PaginatedEndpointOptions<T> extends BasicEndpointOptions<T> {
    /**
     * Class that should be used for creating new paginator for every request.
     */
    pagination?: Type<Pagination>;
}

export type SimpleEndpoint<P, R, Paginated extends boolean = false> =
    (payload: P) => Promise<Paginated extends true ? R[] : R>;
export type PayloadlessSimpleEndpoint<R> = () => Promise<R>;

export interface TaskedEndpoint<PayloadType, ResponseType>
    extends TaskedEndpointHelpers<PayloadType, ResponseType> {

    (payload: PayloadType, pipelineId?: string): TaskedResult<ResponseType>;
}

interface TaskedEndpointHelpers<PayloadType, ResponseType> {
    resume: (pipelineId: string) => TaskedResult<ResponseType>;
    initiate: (payload: PayloadType) => Promise<TaskResponse>;
    retrieve: (pipelineId: string) => Promise<ResponseType>;
    retried: (payload: PayloadType, pipelineId?: string) => TaskedResult<ResponseType>;
    /**
     * Create TaskedResult for this API call, but only actually initiate the
     * pipeline once there's a subscriber on the TaskedResult observable and
     * stop checking the pipeline's status once all subscribers are
     * unsubscribed.
     */
    cold: (payload: PayloadType) => TaskedResult<ResponseType>;
    /**
     * Same as TaskedEndpointHelpers#cold but expect expect ID of already
     * existing pipeline
     */
    resumeCold: (pipelineId?: string) => TaskedResult<ResponseType>;
}

interface SimpleAdditionalOptions {
    method?: Method;
    useParams?: boolean;
}
