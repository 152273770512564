import { atob } from "abab";

import { SkError } from "skCommon/core/error";

export function parseJwtPayload<T>(jwt: string): T {
    const parts = jwt.split(".");

    if (parts.length < 3) {
        throw new JwtError("Invalid JWT string");
    }

    try {
        return JSON.parse(atob(parts[1]));
    } catch (e) {
        throw new JwtError("Invalid JWT payload");
    }
}

export class JwtError extends SkError {
    public get dataToLog() {
        return {};
    }

    constructor(msg: string) {
        super("JwtError", msg);
    }
}
