import { Injectable, Injector, TemplateRef, Type } from "@angular/core";
import { makeObservable, observable } from "mobx";
import { toStream } from "mobx-utils";
import { EMPTY, from } from "rxjs";
import { switchMap } from "rxjs/operators";

import { SidePanelOptions, SidePanelRef } from "skCommon/ui/sidePanel/sidePanelRef";
import { assert } from "skCommon/utils/assert";

/**
 * Service used to open components in a side panel.
 */
@Injectable()
export class SidePanelService {

    @observable.ref
    private _currentPanelRef?: SidePanelRef;

    private hostExists: boolean = false;

    public get currentPanelRef(): SidePanelRef | void {
        return this._currentPanelRef;
    }

    constructor(private injector: Injector) {
        makeObservable(this);

        from(toStream(() => this._currentPanelRef)).pipe(
            switchMap(ref => !ref ? EMPTY : ref.close$),
        ).subscribe(() => this.close());
    }

    public open<T = any>(
        content: Type<T> | TemplateRef<T>,
        options?: SidePanelOptions<{}>,
    ): SidePanelRef;
    public open<T = any, D extends {} = {}>(
        content: Type<T> | TemplateRef<T>,
        options: SidePanelOptions<D> & { data: D },
    ): SidePanelRef;
    public open<T = any, D extends {} = {}>(
        content: Type<T> | TemplateRef<T>,
        options: SidePanelOptions<D> = {},
    ): SidePanelRef {
        assert(this.hostExists, "Cannot open side panel without existing host");

        this._currentPanelRef = new SidePanelRef(content, {
            injector: this.injector,
            ...options,
        });

        return this._currentPanelRef;
    }

    public close(): void {
        this._currentPanelRef = void 0;
    }

    public announceHost(): void {
        this.hostExists = true;
    }
}
