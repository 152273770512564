import { Inject, Injectable } from "@angular/core";
import { formatDate } from "@angular/common";
import { LineControllerDatasetOptions } from "chart.js";
import { clone } from "traverse";

import { mergeTimeseries } from "skCommon/utils/timeseries";
import { PageExport } from "skCommon/exports/pageExport/pageExport";
import { PageExportService } from "skCommon/exports/pageExport/pageExport.service";
import { trimCanvas } from "skCommon/utils/dom";
import { ChartContext } from "skCommon/chart/chartContext";
import { assert } from "skCommon/utils/assert";
import { SkColor } from "skCommon/colors";
import { ComponentDef } from "skCommon/insights/dashboard";
import { ImageRetriever } from "skCommon/exports/pageExport/imageRetriever";

import { SeriesDataPoint } from "skInsights/framework/data/structures";
import {
    DashboardExportService,
} from "skInsights/helpers/dashboardExport.service";
import coverImg from "skInsights/clientDelivery/assets/intro.jpg";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { ChartSeriesDef, LineChartComponentDef } from "skInsights/modules/charts/lineChart/definitions";
import { LineChartComponentType } from "skInsights/modules/charts/lineChart/lineChart.component";
import { LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { ChartBuilderService } from "skInsights/modules/charts/helpers/chartBuilder.service";
import { ExpressionService } from "skInsights/framework/expression.service";
import { DashboardData } from "skInsights/framework/dashboardData";
import { ProductSourceDef } from "skInsights/modules/datacube/sources/product";
import {
    ChartQuery,
    ClientDelivery,
    CsvExportType,
    ExistingChartQuery,
    PageChart, PageDoubleChart,
    UglyChartOptions,
    UglyDoubleChartOptions,
} from "skInsights/clientDelivery/clientDelivery";

/**
 * Set of tools for creating consistently ugly PDF output, since the PDF needs
 * to be 1:1 same to the old manually created delivery, which looks like 💩
 */
@Injectable()
export class UglyDeliveryService {

    constructor(
        private pageExportService: PageExportService,
        private moduleRegistryService: ModuleRegistryService,
        private chartBuilderService: ChartBuilderService,
        private expressionService: ExpressionService,
        private dashboardData: DashboardData,
        private dashboardExportService: DashboardExportService,
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        private rootComponentDef: ComponentDef,
        private imageRetriever: ImageRetriever,
    ) { }

    /**
     * Create pdf according to provided ClientDelivery recipe and download it.
     */
    public async downloadPdfDelivery(delivery: ClientDelivery): Promise<void> {
        const page = await this.getPdfTemplate(delivery.title);

        for (const deliveryPage of delivery.pages) {

            if ("chart" in deliveryPage) {
                await this.drawUglyChartPage(page, deliveryPage);
            } else {
                await this.drawUglyDoubleChartPage(page, deliveryPage);
            }
        }

        const dateLabel = formatDate(new Date(), "yyyy-MM-dd", "en_US");
        const filename = `${delivery.filename}-${dateLabel}`;
        this.pageExportService.downloadPageExport(page, filename);
    }

    /**
     * Create csv according to provided ClientDelivery recipe and download it.
     */
    public async downloadCsvDelivery(
        delivery: ClientDelivery,
        csvType: CsvExportType,
    ): Promise<void> {
        const series: SeriesDataPoint[][] = [];
        const names: string[] = [];
        for (const deliveryPage of delivery.pages) {
            const charts: (PageDoubleChart | PageChart)[] = [];
            if ("charts" in deliveryPage) {
                charts.push(...deliveryPage.charts);
            }
            if ("chart" in deliveryPage) {
                charts.push(deliveryPage.chart);
            }
            for (const [chartIndex, chart] of charts.entries()) {
                let def = this.getChartForQueries(chart.datasets);
                def = this.removeAuxSeries(def);
                if (typeof chart.dcrThreshold === "number") {
                    def = this.applyDcrFilter(def, chart.dcrThreshold);
                }
                const data = await this.chartBuilderService.loadDatasets(def);
                data.forEach((chartDatasetInput, index) => {
                    const s = chartDatasetInput.inputSeries.main.series;
                    if (
                        "series" in s &&
                        charts[chartIndex].datasets[index].csvExportType === csvType
                    ) {
                        series.push(s.series);
                        const dataset = charts[chartIndex].datasets[index];
                        assert(dataset.csvName, `Chart ${chartIndex} dataset ${index} should be included but has no CSV name`);
                        names.push(dataset.csvName);
                    }
                });
            }
        }
        const merge = mergeTimeseries(series, "x", "y");

        await this.dashboardExportService.downloadAllSeriesAsCsv(merge, names, delivery.filename);
    }

        /**
     * Create PDF of correct format with intro page
     */
    public async getPdfTemplate(title: string): Promise<PageExport> {
        const pdf = await this.pageExportService.createPageExport({
            padding: {
                y: {top: 0, bottom: 0},
                x: 0,
            },
            orientation: "landscape",
            format: [297 * 0.86, 540 / 960 * 297 * 0.86],
        });
        pdf.defaultFontOptions.style = "regular";

        const img = await this.imageRetriever.getImage(coverImg);

        pdf.putImage(img, {
            width: pdf.width,
        });

        pdf.setPointer(0);

        pdf.typeParagraph(title, {
            style: "medium",
            color: SkColor.Neutral00,
            align: "center",
            top: 25,
            lineHeight: 1.2,
            fontSize: 62 as any,
            width: pdf.width - 10,
            left: 10,
        });

        return pdf;
    }

    /**
     * Create update period string (past week before product's last update)
     */
    public getPeriodString(periodEnd: Date): string {
        const periodStart = new Date(periodEnd);
        periodStart.setUTCDate(periodStart.getUTCDate() - 7);

        const startMonth = formatDate(periodStart, "MMM", "en_US", "utc");
        const endMonth = formatDate(periodEnd, "MMM", "en_US", "utc");
        const startDate = formatDate(periodStart, "dd", "en_US", "utc");
        const endDate = formatDate(periodEnd, "dd", "en_US", "utc");

        const secondMonth = startMonth === endMonth ? "" : `${endMonth} `;

        return `${startMonth} ${startDate} - ${secondMonth}${endDate}`;
    }

    /**
     * Create page with single chart and header. The chart may have up to two
     * datasets. If multiple datasets are provided, the chart title is ignored
     * and legend is displayed instead.
     */
    public async drawUglyChartPage(
        page: PageExport,
        { header, chart }: UglyChartOptions,
    ): Promise<void> {
        const { canvas, latest } = await this.exportChart(chart, 1184 * 0.65, 660 * 0.65);

        page.ensureNewPage();
        page.typeParagraph(this.replacePeriod(header, latest), {
            top: 2,
            left: 10,
            width: page.width - 20,
            fontSize: 26 as any,
            style: "medium",
            lineHeight: 1.2,
            color: "#4a86e8" as any,
        });

        if (chart.title) {
            page.typeParagraph(this.replacePeriod(chart.title, latest), {
                top: 13,
                color: "#777777" as any,
                fontSize: 16,
                align: "center",
            });
        }

        const chartWidth = 0.68 * page.width;
        const chartHeight = canvas.height / canvas.width * chartWidth;

        page.setPointer(page.maxAvailableHeight - chartHeight - 6);
        const canvasImage = {
            data: canvas,
            width: canvas.width,
            height: canvas.height,
        };
        page.putImage(canvasImage, {
            width: chartWidth,
            height: chartHeight,
            positionX: "center",
        });
    }

    /**
     * Create page with two rows, each containing text or left side and small
     * chart on right side.
     */
    public async drawUglyDoubleChartPage(
        page: PageExport,
        { header, charts }: UglyDoubleChartOptions,
    ): Promise<void> {
        page.ensureNewPage();
        page.typeParagraph(header, {
            top: 2,
            left: 2,
            fontSize: 26 as any,
            style: "medium",
            lineHeight: 1.2,
            color: "#4a86e8" as any,
        });

        for (const [i, chartSpec] of charts.entries()) {
            const { canvas, latest } = await this.exportChart(chartSpec, 1184 * 0.7, 660 * 0.7, 1);

            page.setPointer(0);

            page.typeParagraph(this.replacePeriod(chartSpec.text, latest), {
                top: i * page.height * 0.5  + page.height * 0.15,
                left: 2,
                fontSize: 24 as any,
                style: "medium",
                lineHeight: 1.2,
                color: "#4a86e8" as any,
            });

            if (chartSpec.title) {
                page.setPointer(0);
                page.typeParagraph(this.replacePeriod(chartSpec.title, latest), {
                    top: i * page.height * 0.5,
                    left: page.width * 0.5,
                    color: "#777777" as any,
                    fontSize: 10 as any,
                    align: "center",
                });
            }

            page.setPointer(0);
            const topPadding = 3;

            const chartHeight = 0.47 * page.height - topPadding;
            const canvasImage = {
                data: canvas,
                width: canvas.width,
                height: canvas.height,
            };
            page.putImage(canvasImage, {
                top: topPadding + i * 0.5 * page.height,
                height: chartHeight,
                positionX: page.width * 0.51,
            });
        }
    }

    /**
     * Create chart definition containing timeseries for all given queries
     * modified to work well for pdf export.
     */
    public getChartForQueries(queries: ChartQuery[]): LineChartComponentDef {
        let outChartDef: LineChartComponentDef | undefined;

        for (const q of queries) {
            let found: FoundTimeseries | undefined;

            if ("chartName" in q) {
                found = this.findTimeseriesByName(q);
            } else {
                found = this.createExtraChart(q.productId, q.productSeries);
            }

            const dataset = {
                ...found.series,
                data: found.series.data + (q.pipe || ""),
                disabled: false,
            };

            if (q.newTitle) {
                dataset.label = q.newTitle;
            }

            if (!outChartDef) {
                outChartDef = {
                    ...found.chart,
                    tabs: undefined,
                    series: [dataset],
                };
            } else {
                outChartDef.series.push(dataset);
            }
        }

        assert(outChartDef, "No chart query provided");

        return outChartDef;
    }

    /**
     * Prepare chart's data and options so it can be rendered into pdf.
     */
    private async exportChart(
        pageChart: PageChart,
        width: number,
        height: number,
        dpr: number = 2,
    ): Promise<ExportedChart> {
        let def = this.getChartForQueries(pageChart.datasets);
        def = this.removeAuxSeries(def);

        if (typeof pageChart.dcrThreshold === "number") {
            def = this.applyDcrFilter(def, pageChart.dcrThreshold);
        }

        const chart = await this.chartBuilderService.loadAndCreateChart(def);

        this.modifyChartForExport(chart, pageChart);

        const origCanvas = await chart.exportAsCanvas(width, height, dpr, true, true);
        const latestDatapoint = Math.max(
            ...chart.config.data.datasets
                .map(dataset => (dataset.data.at(-1) as any).x),
        );

        return {
            canvas: trimCanvas(origCanvas),
            latest: new Date(latestDatapoint),
        };
    }

    /**
     * Load given product data and create a chart definition for it.
     *
     * Since conveiniently some charts that need to go into into the report are
     * not part of the dashboard, we need to manually load data for those and
     * prepare chart definition that will then be used to render into pdf.
     */
    private createExtraChart(productId: string, series: string): FoundTimeseries {
        const definition: ProductSourceDef = {
            type: "datacube/product",
            productId,
        };
        const srcId = `extra__${productId}`;
        const seriesDef = {
            data: `${productId}/${series}`,
        };

        if (this.dashboardData.hasSource(srcId)) {
            const source = this.moduleRegistryService.getSource(definition);

            this.dashboardData.addSource(srcId, {
                definition,
                source,
            });
        }

        return {
            chart: {
                component: "charts/line-chart",
                series: [seriesDef],
            },
            series: seriesDef,
        };
    }

    /**
     * Traverse through the dashboard and fine chart with timeseries under given name.
     */
    private findTimeseriesByName({
        chartName,
        tsName,
        tabName,
    }: ExistingChartQuery): FoundTimeseries {
        const queue: ComponentDef[] = [this.rootComponentDef];
        let def: ComponentDef | undefined;

        while (def = queue.shift()) {
            if (def.component === LineChartComponentType) {
                const currentChartDef = def as LineChartComponentDef;
                const tabNameEntries = Object.entries(currentChartDef.tabs || {})
                    .map(([tabId, { name }]) => [name, tabId] as const);
                const tabs = new Map(tabNameEntries);

                if (currentChartDef.template?.title === chartName) {
                    const dataset = currentChartDef.series.find(s => (
                        s.label === tsName
                        && (!tabName || s.tab === tabs.get(tabName))
                    ));

                    if (dataset) {
                        return {
                            series: dataset,
                            chart: currentChartDef,
                        };
                    }
                }
            } else {
                const opts = this.moduleRegistryService.getComponentOptions(def);

                if (opts.getChildComponents) {
                    queue.push(...opts.getChildComponents(def));
                }
            }
        }

        throw new Error(`Could not find dataset for ${chartName} / ${tsName}`);
    }

    private modifyChartForExport(
        ctx: ChartContext,
        { datasets, startYear }: PageChart,
    ): void {
        ctx.config = {
            ...ctx.config,
            options: {
                ...ctx.config.options,
                responsive: false,
                maintainAspectRatio: false,
                layout: {
                    padding: 0,
                },
                animation: false,
                plugins: {
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        display: ctx.config.data.datasets.length > 1,
                        position: "top",
                        labels: {
                            boxHeight: 0,
                            boxWidth: 24,
                        },
                    },
                },
            },
        };

        (ctx.config.options!.scales!.x! as any).time = {
            minUnit: "month",
            displayFormats: {
                month: "DD/MM/YYYY",
            },
        };

        assert(ctx.config.options?.scales?.x, "Chart doesn't have x scale defined, cannot export");

        if (typeof startYear === "number" && startYear < 0) {
            const today = new Date();
            ctx.config.options.scales.x.min = Date.UTC(
                today.getUTCFullYear() + startYear,
                today.getUTCMonth(),
                today.getUTCDate(),
            );
        } else {
            ctx.config.options.scales.x.min = Date.UTC(startYear ?? 2020, 0);
        }

        ctx.config.options.scales.x.ticks = {
            ...ctx.config.options.scales.x.ticks,
            maxRotation: 65,
            minRotation: 65,
        };

        for (const [i, originalChartDataset] of ctx.config.data.datasets.entries()) {
            const datasetQuery = datasets[i];

            if (!datasetQuery.keepStyle) {
                const lineDataset = originalChartDataset as LineControllerDatasetOptions;
                const numOfTs = ctx.config.data.datasets.length;

                lineDataset.borderWidth = 3;

                if (ctx.config.data.datasets.length === 1) {
                    lineDataset.tension = 0.3;
                    lineDataset.cubicInterpolationMode = "default";
                } else {
                    lineDataset.tension = 0;
                    lineDataset.cubicInterpolationMode = "monotone";
                }

                if (i === 1 || numOfTs === 1) {
                    lineDataset.borderColor = datasetQuery.overrideColor || "#0270b9";

                    if (numOfTs > 1) {
                        lineDataset.yAxisID = "y-right-0";
                    }
                } else if (i === 0) {
                    lineDataset.borderColor = datasetQuery.overrideColor || "#fb0600";
                    lineDataset.borderDash = [9];
                    lineDataset.borderDashOffset = 9;
                    lineDataset.yAxisID = "y-left-0";
                }

                if (datasetQuery.scaleRange) {
                    const scale = ctx.config.options.scales[lineDataset.yAxisID];
                    assert(scale, `Scale ${lineDataset.yAxisID} doesn't exist`);

                    scale.min = datasetQuery.scaleRange[0];
                    scale.max = datasetQuery.scaleRange[1];
                }
            }
        }
    }

    private applyDcrFilter(
        origDef: LineChartComponentDef,
        threshold: number,
    ): LineChartComponentDef {
        const def = clone(origDef);

        def.series = def.series.map(seriesDef => {
            // Use dcr-filter for all chart values
            // This is ugly and absolutely specific to single delivery,
            // but necessary since we do not want to upkeep two similar
            // dashboards with slight differences...
            const parsed = this.expressionService.parsePipedExpression(seriesDef.data);
            const dataRef = parsed.expression.trim();

            // Time series named like this should have DCR timeseries available
            // by replacing this part with _coverage_ratio
            const needle = /(_index_level|_(normal|high|low)_activity_level).*/;

            if (dataRef.match(needle)) {
                const dcrRef = dataRef.replace(needle, "_coverage_ratio");
                const replacement = `${dataRef} | dcr-filter("${dcrRef}", ${threshold.toFixed(2)}, true)`;
                const updatedData = seriesDef.data.replace(dataRef, replacement);

                return {
                    ...seriesDef,
                    data: updatedData,
                };
            } else {
                // Not datacube product that we can run dcr-filter on
                return seriesDef;
            }
        });

        return def;
    }

    private removeAuxSeries(origDef: LineChartComponentDef): LineChartComponentDef {
        const def = clone(origDef);

        def.series = def.series.map(seriesDef => ({
            ...seriesDef,
            aux: undefined,
        }));

        return def;
    }

    private replacePeriod(str: string, lastDatapointDt: Date): string {
        const periodToken = "{period}";
        const periodString = this.getPeriodString(lastDatapointDt);
        return str.replaceAll(periodToken, periodString);
    }
}

interface FoundTimeseries {
    chart: LineChartComponentDef;
    series: ChartSeriesDef;
}

interface ExportedChart {
    /**
     * Canvas with the chart drawn ready to be transfered into pdf
     */
    canvas: HTMLCanvasElement;
    /**
     * Last datapoint date in this chart
     */
    latest: Date;
}
