import { Component, Input, ViewChild, ElementRef } from "@angular/core";

import { ChartContext } from "skDatacube/chart/chart";

@Component({
    selector: "sk-chart",
    template: `<ng-container *skAutorun><canvas #chartCanvas></canvas><ng-content></ng-content></ng-container>`,
})
export class ChartComponent {

    @ViewChild("chartCanvas")
    public chartCanvas: ElementRef<HTMLCanvasElement>;

    @Input()
    public chart: ChartContext;

    public ngAfterViewInit(): void {
        this.chart.render(this.chartCanvas.nativeElement);
    }
}
