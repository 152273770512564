import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { SharedModule } from "skInsights/shared.module";
import { UserService } from "skInsights/user/user.service";
import { LoginComponent } from "skInsights/user/login.component";
import { AnonGuard } from "skInsights/user/anon.guard";

enum LoginRoutes {
    Login = "login",
}

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild([
            {
                path: LoginRoutes.Login,
                component: LoginComponent,
                canActivate: [AnonGuard],
            },
        ]),
    ],
    declarations: [
        LoginComponent,
    ],
    providers: [
        UserService,
    ],
})
export class UserModule { }
