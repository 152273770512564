import {
    Interaction,
    Chart,
    ChartEvent,
    InteractionItem,
} from "chart.js";
import { getRelativePosition } from "chart.js/helpers";

/**
 * Extend the chart.js's interaction modes and add "nearestEach" which works
 * like { mode: nearest, axis: x, intersect: false }, but always finds item for
 * every dataset.
 */
export function registerNearestEachTooltipMode(): void {
    if (!Interaction.modes.nearestEach) {
        Interaction.modes.nearestEach = nearestEach;
    }
}

/**
 * src/core/core.interaction.js uses just way too many private helper functions,
 * so for now the implementation is slower and much less versatile.
 *
 * @todo at least implement the binary search
 */
function nearestEach(
    chart: Chart,
    e: ChartEvent,
): InteractionItem[] {
    const position = getRelativePosition(e.native as MouseEvent, chart);
    const datasets = chart.getSortedVisibleDatasetMetas();

    return datasets.map(dataset => {
        let bestDistance = Number.POSITIVE_INFINITY;
        let bestPoint: InteractionItem;

        for (const [index, element] of dataset.data.entries()) {
            const distance = element.x - position.x;

            if (Math.abs(distance) < Math.abs(bestDistance)) {
                bestPoint = {
                    element,
                    datasetIndex: dataset.index,
                    index,
                };
                bestDistance = distance;
            }

            if (distance > 0) {
                break;
            }
        }

        return bestPoint;
    });
}

declare module "chart.js" {
    interface InteractionModeMap {
        nearestEach: {};
    }
}
