import { Doc } from "skCommon/firebase/doc";

import { InsightsDocument } from "skInsights/modules/documents/document";

/**
 * Abstract service that simply only handles fetching insights documents from
 * firestore. Put into separate class like this so it can be easily replaces in
 * node environment. If you want to use the service, inject this class instead
 * of the environment specific one (such as FrontendDocumentLoaderService).
 */
export abstract class DocumentLoaderService {

    public abstract loadDocument(id: string): Promise<Doc<InsightsDocument>>;
}
