import { ChangeDetectionStrategy, Component, Inject, NgModule } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { computed, makeObservable } from "mobx";

import { assert } from "skCommon/utils/assert";
import { sortByWeight } from "skCommon/utils/sort";

import { MetadataList } from "skInsights/framework/data/metadataList";
import { MetadataDict } from "skInsights/modules/datacube/sources/product";
import { SharedModule } from "skInsights/shared.module";

const RAW_VALUE_TYPES = new Set([
    "string",
    "number",
    "boolean",
]);

@Component({
    selector: "sk-metadata-dialog",
    templateUrl: "./metadataDialog.pug",
    styleUrls: ["./metadataDialog.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetadataDialogComponent {

    private metadataOrder: string[] = [
        "observedLocation",
    ];

    @computed
    public get metadata(): MetadataRow[] {
        const rows = Object.entries(this.data)
            .map(([label, value]) => {
                const base: MetadataRowBase = { label };

                if (value instanceof MetadataList) {
                    return {
                        ...base,
                        list: value.lines,
                    };
                } else if (RAW_VALUE_TYPES.has(typeof value)) {
                    return {
                        ...base,
                        rawValue: true,
                        value: value as string | number | boolean,
                    } as const;
                } else {
                    return {
                        ...base,
                        json: JSON.stringify(value, null, 4),
                    };
                }
            });

        return sortByWeight(rows, this.metadataOrder, "label");
    }

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data:  MetadataDict,
    ) {
        makeObservable(this);

        assert(data, "Metadata Dialog requires metadata to be passed as data");
    }
}

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        MetadataDialogComponent,
    ],
})
export class MetadataDialogModule { }

type MetadataRow = ListMetadataRow | JsonMetadataRow | RawValueMetadataRow;

interface MetadataRowBase {
    label: string;
}

interface ListMetadataRow extends MetadataRowBase {
    list: readonly string[];
}

interface JsonMetadataRow extends MetadataRowBase {
    json: string;
}

interface RawValueMetadataRow extends MetadataRowBase {
    rawValue: true;
    value: string | number | boolean;
}
