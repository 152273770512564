import moment from "moment";
import * as statistics from "simple-statistics";

import { groupBy } from "skCommon/utils/group";
import { sortByKey, SortDirection } from "skCommon/utils/sort";
import { assert } from "skCommon/utils/assert";

import { OperationDef, OperationWithPipe } from "skInsights/framework/abstract/operation";
import { AnyDashboardData, SeriesDataPoint } from "skInsights/framework/data/structures";
import { transformSeries, assertSeriesLike } from "skInsights/framework/data/helpers";
import { PipeArgument } from "skInsights/framework/pipeCall";

type ResampleOperationType = "statistics/resample";
const ResampleOperationType: ResampleOperationType = "statistics/resample";

export class ResampleOperation extends OperationWithPipe<ResampleOperationDef> {

    public readonly type = ResampleOperationType;

    public readonly pipe = "resample";

    public readonly handlers: Record<ResamplePeriod, ResampleHandler> = {
        [ResamplePeriod.Month]: {
            getDate: d => moment(d).startOf("month").toDate(),
            getKey: d => moment(d).format("YYYY-MM"),
        },
        [ResamplePeriod.Quarter]: {
            getDate: d => moment(d).startOf("quarter").toDate(),
            getKey: d => moment(d).format("YYYY-Q"),
        },
        [ResamplePeriod.Year]: {
            getDate: d => moment(d).startOf("quarter").toDate(),
            getKey: d => d.getUTCFullYear().toString(),
        },
    };

    public execute(
        def: ResampleOperationDef,
        data: AnyDashboardData,
    ): AnyDashboardData {
        assertSeriesLike(data);

        return transformSeries(data, ({ series }) => ({
            series: this.resample(def, series),
        }));
    }

    public executePipe(
        args: PipeArgument[],
        data: AnyDashboardData,
    ): AnyDashboardData {
        assert(typeof args[0] === "string", "resample pipe: period not provided");
        assert(!args[1] || typeof args[1] === "string", "resample pipe: invalid method");

        return this.execute({
            operation: ResampleOperationType,
            period: args[0] as ResamplePeriod,
            method: args[1] as ResampleMethod,
        }, data);
    }

    private resample(
        def: ResampleOperationDef,
        series: SeriesDataPoint[],
    ): SeriesDataPoint[] {
        const identifiedPoints = series.map(point => {
            const handler = this.handlers[def.period];

            return {
                y: point.y,
                x: handler.getDate(point.x),
                key: handler.getKey(point.x),
            };
        });

        const map = groupBy(identifiedPoints, "key");

        return [...map.values()].map(vals => {
            let y: number;

            if (def.method === ResampleMethod.Last) {
                y = vals.sort(sortByKey("x", SortDirection.Desc))[0].y;
            } else if (def.method === ResampleMethod.First) {
                y = vals.sort(sortByKey("x", SortDirection.Asc))[0].y;
            } else {
                y = statistics.mean(vals.map(v => v.y));
            }
            const x = vals[0].x;

            return { y, x };
        });
    }
}

/**
 * Calculate mean average for each selected period
 * Can be used as pipe: resample(period, method)
 * Example: | resample("month", "last")
 */
interface ResampleOperationDef extends OperationDef<ResampleOperationType> {
    period: ResamplePeriod;
    /**
     * How to resample the set of values into single values
     * @default `"mean"`
     */
    method?: ResampleMethod;
}

enum ResamplePeriod {
    Month = "month",
    Quarter = "quarter",
    Year = "year",
}

enum ResampleMethod {
    Mean = "mean",
    Last = "last",
    First = "fist",
}

interface ResampleHandler {
    getKey(input: Date): string;
    getDate(input: Date): Date;
}
