import { btoa } from "abab";

import { generateUniqueId } from "skCommon/utils/uniqueId";

export async function generateChallenge(): Promise<PkceChallenge> {
    const verifier = urlEncodeBase64(generateUniqueId(32));

    const challengeBuffer = await makeHash(verifier);
    const challenge = urlEncodeBase64(bufferToBase64(challengeBuffer));

    return { verifier, challenge };
}

// Following utility function are taken from:
// https://github.com/auth0/spa-pkce

function bufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    return btoa(String.fromCharCode(...bytes));
}

function urlEncodeBase64(str: string): string {
    return str.replace(/\+/g, "-")
        .replace(/\//g, "_")
        .replace(/=/g, "");
}

async function makeHash(str: string): Promise<ArrayBuffer> {
    const arr = new TextEncoder().encode(str);
    return window.crypto.subtle.digest("SHA-256", arr);
}

interface PkceChallenge {
    verifier: string;
    challenge: string;
}
