import { Injectable } from "@angular/core";

import { extendError } from "skCommon/core/error";
import { exists } from "skCommon/utils/types";

import { PipeArgument, PipeCall } from "skInsights/framework/pipeCall";

@Injectable({ providedIn: "root" })
export class ExpressionService {

    /**
     * Find pipes (separated by |) in the expression and return each parsed
     * pipe call separately.
     */
    public parsePipedExpression(fullExpression: string): SeparatedPipes {
        const [expression, ...pipeCalls] = fullExpression.split("|")
            .map(t => t.trim());

        const pipes = pipeCalls.map(pipeCallString => {
            try {
                return this.parsePipeCall(pipeCallString);
            } catch (e) {
                throw extendError(e, `Could not parse call "${pipeCallString}" in expression "${fullExpression}": `);
            }
        });

        return { expression, pipes };
    }

    /**
     * Parse content of the piped call (pipe name and optionallly its arguments)
     */
    public parsePipeCall(pipeCall: string): PipeCall {
        const [, name, brackets] = pipeCall.match(/^([0-z_\-]+) *(\(.*\))? *$/) || [];
        let args: PipeArgument[] = [];

        if (name) {
            if (brackets) {
                const content = brackets.slice(1, -1);

                args = this.parseArguments(content);
            }

            return { name, args };
        } else {
            throw new Error(`Could not parse pipe call "${pipeCall}"`);
        }
    }

    /**
     * Parse arguments from the string that was inside the pipe's brackets.
     * When the method is called recursively for each following argument,
     * expectComma should be true.
     */
    private parseArguments(argStr?: string, expectComma: boolean = false): PipeArgument[] {
        argStr = argStr ? argStr.trim() : "";

        if (!argStr) {
            return [];
        }

        if (expectComma) {
            if (!argStr.startsWith(",")) {
                throw new Error(`Unexpected ${argStr} after argument`);
            } else {
                argStr = argStr.slice(1);
            }
        }

        const parsedArgs = this.tryParseStringArg(argStr)
            || this.tryParseKeywordArg(argStr)
            || this.tryParseNumberArg(argStr);

        if (parsedArgs) {
            return parsedArgs;
        } else {
            throw new Error(`Found no parser for argument expression ${argStr}`);
        }
    }

    private tryParseStringArg(argStr: string): PipeArgument[] | void {
        const strMatch = argStr.match(/^ *"([^"]*)" *(.*)$/)
            || argStr.match(/^ *'([^']*)' *(.*)$/);

        if (strMatch) {
            return [
                strMatch[1],
                ...this.parseArguments(strMatch[2], true),
            ];
        }
    }

    private tryParseKeywordArg(argStr: string): PipeArgument[] | void {
        const keywordMatch = argStr.match(/^ *(true|false|null) *(.*)$/);

        if (keywordMatch) {
            const kw = keywordMatch[1];
            let value: PipeArgument | void;

            switch (kw) {
                case "true": value = true; break;
                case "false": value = false; break;
                case "null": value = null; break;
            }

            if (!exists(value)) {
                throw new Error(`Unknown keyword ${kw}`);
            }

            return [
                value,
                ...this.parseArguments(keywordMatch[2], true),
            ];
        }
    }

    private tryParseNumberArg(argStr: string): PipeArgument[] | void {
        const numberMatch = argStr.match(/^ *([0-9\.e]+) *(.*)$/);
        const parsedNumber = numberMatch ? parseFloat(numberMatch[1]) : NaN;

        if (numberMatch && !Number.isNaN(parsedNumber)) {
            return [
                parsedNumber,
                ...this.parseArguments(numberMatch[2], true),
            ];
        }
    }
}

export interface SeparatedPipes {
    expression: string;
    pipes: PipeCall[];
}
