import { Observable, ObservableInput, of, Subject } from "rxjs";
import { shareReplay, switchAll } from "rxjs/operators";

import { ComponentDef } from "skCommon/insights/dashboard";

/**
 * Class whose instance should be provided for each designer component so it
 * can be used to notify about component definition changes.
 */
export class DesignerModel<T extends ComponentDef = ComponentDef> {

    public definition$: Observable<T>;

    private readonly _definitionSub$ = new Subject<ObservableInput<T>>();

    constructor(public initialDefinition: T) {
        this.definition$ = this._definitionSub$.pipe(
            switchAll(),
            shareReplay(1),
        );

        this.definition$.subscribe(def => this.initialDefinition = def);

        this._definitionSub$.next(of(initialDefinition));
    }

    public updateDefinitionStream(def$: ObservableInput<T>): void {
        this._definitionSub$.next(def$);
    }
}
