import { observable, makeObservable } from "mobx";
import { Injectable } from "@angular/core";

import { assert } from "skCommon/utils/assert";
import { RouterStore } from "skCommon/angular/router.store";
import { Dashboard } from "skCommon/insights/dashboard";
import { ParsedDashboard } from "skCommon/insights/parsedDashboard";

import { DashboardService } from "skInsights/dashboard/dashboard.service";
import { DashboardRoutes } from "skInsights/dashboard/dashboard.routing";
import { UserService } from "skInsights/user/user.service";

@Injectable({ providedIn: "root" })
export class DashbaordEditorService {

    @observable.ref
    public dashboard?: ParsedDashboard;

    @observable.ref
    public exists: boolean = false;

    public get overwriting(): boolean {
        return !!this.dashboard && this.dashboard.userId !== this.userService.id;
    }

    constructor(
        private dashboardService: DashboardService,
        private routerStore: RouterStore,
        private userService: UserService,
    ) {
        makeObservable(this);
    }

    public async updateDashboard(part: Partial<ParsedDashboard>): Promise<void> {
        assert(this.dashboard, "No dashboard loaded");

        const update: Partial<Dashboard> = {
            ...part,
            schema: void 0,
        };

        if (part.schema) {
            update.schema = JSON.stringify(part.schema);
        } else {
            delete update.schema;
        }

        await this.dashboardService.saveDashboard(
            this.dashboard.id,
            update,
        );

        this.dashboard = {
            ...this.dashboard,
            ...update,
            schema: part.schema || this.dashboard.schema,
        };
    }

    public async deleteDashboard(): Promise<void> {
        assert(this.dashboard, "No dashboard loaded");

        await this.dashboardService.deleteDashboard(this.dashboard.id);

        this.routerStore.navigate([DashboardRoutes.Home]);
    }

    /**
     * Reset service state. Should be called when editor is closed.
     */
    public reset(): void {
        this.dashboard = undefined;
        this.exists = false;
    }
}
