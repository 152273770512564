import { NgModule } from "@angular/core";

import { SharedModule } from "skCommon/angular/shared.module";
import { AutorunModule } from "skCommon/angular/state/autorun.module";

import { InterpolatePipe } from "skInsights/partials/richText/interpolate.pipe";
import { RichTextComponent } from "skInsights/partials/richText/richText.component";

@NgModule({
    imports: [
        SharedModule,
        AutorunModule,
    ],
    declarations: [
        RichTextComponent,
        InterpolatePipe,
    ],
    exports: [
        InterpolatePipe,
        RichTextComponent,
    ],
})
export class RichTextModule { }
