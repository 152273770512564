import { mean } from "simple-statistics";

import "skCommon/extensions/array.prototype.at";
import { assert } from "skCommon/utils/assert";

import { Operation, OperationDef } from "skInsights/framework/abstract/operation";
import { AnyDashboardData, SeriesDataPoint } from "skInsights/framework/data/structures";
import { transformSeries, assertSeriesLike } from "skInsights/framework/data/helpers";

type SimpleRollingAverateOperationType = "statistics/simple-rolling-average";
const SimpleRollingAverageOperationType: SimpleRollingAverateOperationType = "statistics/simple-rolling-average";

export class SimpleRollingAverageOperation extends Operation<SimpleRollingAverageOperationDef> {

    public readonly type = SimpleRollingAverageOperationType;

    public execute(
        def: SimpleRollingAverageOperationDef,
        data: AnyDashboardData,
    ): AnyDashboardData {
        assertSeriesLike(data);

        if (def.subsetSize < 2) {
            throw new Error("Subset size needs to be larger than 1");
        }

        return transformSeries(data, ({ series }) => ({
            series: this.calculate(def, series),
        }));
    }

    private calculate(
        { subsetSize }: SimpleRollingAverageOperationDef,
        series: SeriesDataPoint[],
    ): SeriesDataPoint[] {
        assert(series.length, "Cannot calculate rolling average over empty timeseries");

        if (series.length < subsetSize) {
            return [this.getAveragePoint(series)];
        } else {
            const numOfAvgs = series.length - subsetSize + 1;
            const avgs = new Array<SeriesDataPoint>(numOfAvgs);

            for (let i = 0; i < numOfAvgs; i++) {
                const points = series.slice(i, i + subsetSize);

                avgs[i] = this.getAveragePoint(points);
            }

            return avgs;
        }
    }

    private getAveragePoint(points: SeriesDataPoint[]): SeriesDataPoint {
        const values = points.map(v => v.y);

        return {
            x: new Date(+points.at(-1)!.x),
            y: mean(values),
        };
    }
}

/**
 * Pipe operation which computes simple rolling average with constant subset
 * size.
 */
interface SimpleRollingAverageOperationDef
        extends OperationDef<SimpleRollingAverateOperationType> {
    /**
     * Number of points to calculate average from
     */
    subsetSize: number;
}
