import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { makeObservable } from "mobx";

import { observableRef } from "skCommon/state/mobxUtils";

@Component({
    selector: "sk-toggle-chip",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./toggleChip.pug",
    styleUrls: ["./toggleChip.scss"],
})
export class ToggleChipComponent {

    @HostBinding("class.sk-toggle-chip-checked")
    @Input()
    @observableRef
    public checked: boolean = false;

    @Output()
    public checkedChange = new EventEmitter<boolean>();

    @HostBinding("class.sk-toggle-chip-disabled")
    @Input()
    @observableRef
    public disabled: boolean = false;

    @Input()
    @observableRef
    public color?: string;

    constructor() {
        makeObservable(this);
    }

    public toggle(): void {
        this.checkedChange.emit(!this.checked);
    }
}
