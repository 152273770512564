import { matchPermissions, PermissionLike } from "skCommon/utils/permissions";
import { checkPermissions } from "skCommon/core/userUtils";

/**
 * Class offering interface for comparing permissions. Can be used to allow
 * function to check permissions without requiring the whole user service to be
 * provided.
 *
 * @param getPermissions function that returns "owned" permissions
 */
export class PermissionAccessor {

    constructor(private getPermissions: () => string[]) { }

    /**
     * Check whether user has given permission
     */
    public has(permission: PermissionLike): boolean {
        return checkPermissions(this.getPermissions(), [permission], true);
    }

    /**
     * Check whether user has all given permissions
     */
    public hasAll(permissions: PermissionLike[]): boolean {
        return checkPermissions(this.getPermissions(), permissions, true);
    }

    /**
     * Check whether user has any given permission
     */
    public hasAny(permissions: PermissionLike[]): boolean {
        return checkPermissions(this.getPermissions(), permissions, false);
    }

    /**
     * Return list of permissions which match given expression
     * @see matchPermissions
     */
    public match(permissionMatch: string): string[] {
        return matchPermissions(this.getPermissions(), permissionMatch);
    }
}
