import { Client } from "skCommon/api/client/simple";
import { getDefaultConfig } from "skCommon/api/config";
import { urlUtil } from "skCommon/api/config/url";

export class GoogleMapsClient extends Client {
    public readonly api = "google-maps";
    public readonly authType = null;

    public getStaticImageUrl(query: GoogleStaticImageQuery): string {
        return urlUtil.get(this.apiObject, "static-map", {
            centerLat: query.center[1],
            centerLon: query.center[0],
            zoom: Math.round(query.zoom),
            width: query.width,
            height: query.height,
            mapType: query.mapType,
            key: this.getApiKey(),
        });
    }

    public getTileUrl(mapType: GoogleMapType): string {
        switch (mapType) {
            case GoogleMapType.Hybrid:
                return "https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}";
            case GoogleMapType.RoadMap:
                return "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}";
            case GoogleMapType.Satellite:
                return "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}";
            case GoogleMapType.Terrain:
                return "https://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}";
            default:
                throw new Error(`No URL for google map ${mapType}`);
        }
    }

    private getApiKey(): string {
        return getDefaultConfig().getByHost(this.apiObject.data.api_key) as string;
    }
}

let googleMapsClient: GoogleMapsClient;

export function getGoogleMapsClient() {
    if (!googleMapsClient) {
        googleMapsClient = new GoogleMapsClient();
    }

    return googleMapsClient;
}

export enum GoogleMapType {
    RoadMap = "roadmap",
    Satellite = "satellite",
    Terrain = "terrain",
    Hybrid = "hybrid",
}

export interface GoogleStaticImageQuery {
    center: [number, number];
    zoom: number;
    width: number;
    height: number;
    mapType: GoogleMapType;
}
