import { extendError } from "skCommon/core/error";
import { AnyOperationDef } from "skCommon/insights/dashboard";

import { AnyDashboardData, DashboardString, DashboardSingleValue } from "skInsights/framework/data/structures";
import { toDashboardString, isCollection, toDashboardSingleValue } from "skInsights/framework/data/helpers";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { DataProvider } from "skInsights/framework/data/dataProvider";
import { collectionSelect } from "skInsights/framework/query/collectionSelect";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { PipeArgument } from "skInsights/framework/pipeCall";

/**
 * Pipe which executes series of operations and returns the result. Its
 * optional argument can be used to select only some data.
 */
export class OperationPipe implements DashboardPipe {

    constructor(
        private operations: AnyOperationDef[],
        private moduleRegistryService: ModuleRegistryService,
    ) { }

    public async execute(
        input: AnyDashboardData,
        [query]: [string?],
        dataRefService: DataRefService,
    ): Promise<PipeResult> {
        const out = await this.operations.reduce(async (payloadPromise, op, i) => {
            const payload = await payloadPromise;

            try {
                return this.moduleRegistryService.executeOperation(
                    op,
                    payload,
                    dataRefService,
                );
            } catch (e) {
                throw extendError(e, `[pipe#${i}] `);
            }
        }, Promise.resolve(input));

        const result = new PipeResult(out);

        return query
            ? new PipeResult(await result.select(query))
            : result;
    }
}

export class NumberPipe implements DashboardPipe<DashboardSingleValue> {

    constructor(private cb: (input: number, ...args: PipeArgument[]) => number) { }

    public async execute(
        input: AnyDashboardData,
        // tslint:disable-next-line: trailing-comma
        args: PipeArgument[]
    ): Promise<PipeResult<DashboardSingleValue>> {
        const { value } = toDashboardSingleValue(input);

        return new PipeResult(toDashboardSingleValue(this.cb(value, ...args)));
    }
}

export class StringPipe implements DashboardPipe<DashboardString> {

    constructor(private cb: (input: string, ...args: PipeArgument[]) => string) { }

    public async execute(
        input: AnyDashboardData,
        args: PipeArgument[],
    ): Promise<PipeResult<DashboardString>> {
        const { text } = toDashboardString(input);

        return new PipeResult(toDashboardString(this.cb(text, ...args)));
    }
}

export class PipeResult<T extends AnyDashboardData = AnyDashboardData> implements DataProvider {

    constructor(private data: T) { }

    public async select(q: string): Promise<T> {
        if (q === "*") {
            return this.data;
        } else if (isCollection(this.data)) {
            return collectionSelect(this.data, q) as T;
        } else {
            throw new Error(`Invalid query "${q}" for ${this.data.type} pipe`);
        }
    }
}

export interface DashboardPipe<
    TOutput extends AnyDashboardData = AnyDashboardData,
    TInput extends AnyDashboardData = AnyDashboardData
> {
    execute(
        input: TInput,
        args: Partial<PipeArgument[]>,
        dataRefService: DataRefService,
    ): Promise<PipeResult<TOutput>>;
}
