import { Injectable } from "@angular/core";

import { SkError } from "skCommon/core/error";
import { Client } from "skCommon/api/client/simple";
import { getDefaultConfig } from "skCommon/api/config";
import { urlUtil } from "skCommon/api/config/url";

@Injectable({ providedIn: "root" })
export class BingClient extends Client {
    public readonly api = "bing";
    public readonly authType = null;

    public getStaticImageUrl(query: ImageryMapQuery): string {
        return urlUtil.get(this.apiObject, "imagery-map", {
            mapSize: query.mapWidth + "," + query.mapHeight,
            imagerySet: query.imagerySet,
            centerLat: query.centerPoint[1],
            centerLon: query.centerPoint[0],
            zoomLevel: Math.round(query.zoomLevel),
            key: this.getApiKey(),
        });
    }

    /**
     * Wrapper around imageryMetadta endpoint that picks only useful data.
     */
    public async getTilesInfo(imagerySet: BingImagerySet): Promise<TilesInfo> {
        const metadata = await this.imageryMetadata(imagerySet);

        if (
            metadata.resourceSets.length < 0
            || metadata.resourceSets[0].resources.length < 0
        ) {
            throw new BingError("No imagery metadata found.", imagerySet);
        }

        const resource = metadata.resourceSets[0].resources[0];

        // Microsoft copyright is so long... Remove the part about API usage.
        // If the copyright drastically changes, it's gonna be displayed in
        // full again.
        const sliceFrom = metadata.copyright.indexOf(" This API");

        // HTTPS is supported, but the URL we get from their api is http.
        const tileUrl = resource.imageUrl.replace("http://", "https://");

        return {
            urls: resource.imageUrlSubdomains.map(
                sub => tileUrl.replace("{subdomain}", sub),
            ),
            copyright: metadata.copyright.slice(0, sliceFrom),
        };
    }

    public imageryMetadata(
        imagerySet: BingImagerySet,
        pos?: MapPosition,
    ): Promise<ImageryMetadata> {
        let centerPoint: string | undefined;
        if (pos) {
            centerPoint = `${pos.center[1]},${pos.center[0]}`;
        }
        return this.call<ImageryMetadata>({
            endpoint: "imagery-metadata",
            params: {
                imagerySet,
                centerPoint,
                key: this.getApiKey(),
                zoomLevel: pos?.zoomLevel,
            },
        }).promise;
    }

    private getApiKey(): string {
        return getDefaultConfig().getByHost(this.apiObject.data.api_key) as string;
    }
}

let bingClient: BingClient;

export function getBingClient() {
    if (!bingClient) {
        bingClient = new BingClient();
    }

    return bingClient;
}

export class BingError extends SkError {
    public get dataToLog() {
        return {
            requestedImagery: this.request,
        };
    }

    constructor(msg: string, private request: string) {
        super("BingError", msg);
    }
}

export const enum BingImagerySet {
    Aerial = "Aerial",
    AerialWithLabels = "AerialWithLabels",
    AerialWithLabelsOnDemand = "AerialWithLabelsOnDemand",
    Birdseye = "Birdseye",
    BirdseyeWithLabels = "BirdseyeWithLabels",
    BirdseyeV2 = "BirdseyeV2",
    BirdseyeV2WithLabels = "BirdseyeV2WithLabels",
    CanvasDark = "CanvasDark",
    CanvasLight = "CanvasLight",
    CanvasGray = "CanvasGray",
    OrdnanceSurvey = "OrdnanceSurvey",
    Road = "Road",
    RoadOnDemand = "RoadOnDemand",
    Streetside = "Streetside",
}

export interface ImageryMapQuery {
    centerPoint: GeoJSON.Position;
    zoomLevel: number;
    mapWidth: number;
    mapHeight: number;
    imagerySet: BingImagerySet;
}

export interface TilesInfo {
    urls: string[];
    copyright: string;
}

export interface Resource {
    __type: string;
    imageHeight: number;
    /**
     * URL contains {subdomain} and {quadkey} placeholders that need to be
     * replaced.
     */
    imageUrl: string;
    imageUrlSubdomains: string[];
    imageWidth: number;
    imageryProviders?: any;
    vintageEnd?: string;
    vintageStart?: string;
    zoomMax: number;
    zoomMin: number;
}

export interface ResourceSet {
    estimatedTotal: number;
    resources: Resource[];
}

export interface ImageryMetadata {
    authenticationResultCode: string;
    brandLogoUri: string;
    copyright: string;
    resourceSets: ResourceSet[];
    statusCode: number;
    statusDescription: string;
    traceId: string;
}

export interface MapPosition {
    center: GeoJSON.Position;
    zoomLevel: number;
}
