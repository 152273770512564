import moment from "moment";

import { parseDurationString } from "skCommon/utils/time";

import { Operation, OperationDef } from "skInsights/framework/abstract/operation";
import { AnyDashboardData, SeriesDataPoint } from "skInsights/framework/data/structures";
import { transformSeries, assertSeriesLike } from "skInsights/framework/data/helpers";

type ChangeOperationType = "statistics/change";
const ChangeOperationType: ChangeOperationType = "statistics/change";

export class ChangeOperation extends Operation<ChangeOperationDef> {

    public readonly type = ChangeOperationType;

    public execute(def: ChangeOperationDef, data: AnyDashboardData): AnyDashboardData {
        assertSeriesLike(data);

        return transformSeries(data, ({ series }) => ({
            series: this.computeChange(def, series),
            meta: !!def.percentual ? {
                unit: "%",
            } : void 0,
        }));
    }

    private computeChange(def: ChangeOperationDef, series: SeriesDataPoint[]): SeriesDataPoint[] {
        const getRefDate = this.makeRefDateGetter(def.change);

        const res = series.reduceRight<ChangeResult>(
            ({ queue, out }, point, i) => {
                // Use current point to calculate change for each queued datapoint
                // which has reference date after current point.
                while (queue.length && +point.x <= +queue[0].refDate) {
                    const queued = queue.shift()!;
                    const chng = this.interpolateChange(
                        point,
                        series[i + 1],
                        queued,
                        !!def.percentual,
                    );

                    out.push(chng);
                }

                const refDate = getRefDate(point.x, i, series);

                if (refDate) {
                    queue.push({ point, refDate });
                }

                return { queue, out };
            },
            { out: [], queue: [] },
        );

        res.out.reverse();

        return res.out;
    }

    private makeRefDateGetter(type: ChangeType | DurationString): RefDateGetter {
        switch (type) {
            case KnownChangeType.Yoy:
                return (d: Date) => moment(d).subtract(1, "year").toDate();
            case KnownChangeType.Mom:
                return (d: Date) => moment(d).subtract(1, "month").toDate();
            case KnownChangeType.Qoq:
                return (d: Date) => moment(d).subtract(1, "quarter").toDate();
            case KnownChangeType.Continuous:
                return (_: Date, i: number, series: SeriesDataPoint[]) =>
                    i ? series[i - 1].x : null;
            default:
                const [amount, unit] = parseDurationString(type);

                return (d: Date) => moment(d)
                    .subtract(amount, unit as any).toDate();
        }

        throw new Error(`Unsupported change type: ${type}`);
    }

    /**
     * Interpolate reference value using 2 closest points and use it to
     * calculate the resulting change
     */
    private interpolateChange(
        p0: SeriesDataPoint,
        p1: SeriesDataPoint,
        ref: QueuedDataPoint,
        percentual?: boolean,
    ): SeriesDataPoint {
        const fraction = (+ref.refDate - +p0.x) / (+p1.x - +p0.x);
        const refValue = p0.y + (p1.y - p0.y) * fraction;

        let changeVal: number;

        if (percentual) {
            changeVal = ((ref.point.y - refValue)) / Math.abs(refValue) * 100;
        } else {
            changeVal = ref.point.y - refValue;
        }

        return {
            x: ref.point.x,
            y: changeVal,
        };
    }
}

/**
 * Pipe operation which computes year on year, quarter on quarter etc.
 * change for input series or series set. If the resulting series would have
 * no data points a error is thrown.
 */
interface ChangeOperationDef extends OperationDef<ChangeOperationType> {
    /**
     * Change interval either defined by the ChangeType constants
     * (`yoy`, `mom`, `qoq` or `continuous`) or by Duration String.
     * Duration String consists of amount and unit optionally separated by a
     * space. Amount is any whole number and unit may be one of following:
     * years, y
     * months, M
     * weeks, w
     * days, d
     * hours, h
     * minutes, m
     * seconds, s
     * milliseconds, ms
     *
     * @example `"mom"`
     * @example `"5d"`
     * @example `"10 months"`
     * @example `"2 y"`
     * @see https://momentjs.com/docs/#/durations/creating/
     */
    change: ChangeType | DurationString;
    /**
     * Calculate simple percentaul change instead of absolute value.
     *
     * @note Percentage change can be very misleading sometimes and it's not
     *  recommended!
     */
    percentual?: boolean;
}

type ChangeType = KnownChangeType | "string";

enum KnownChangeType {
    Yoy = "yoy",
    Qoq = "qoq",
    Mom = "mom",
    Continuous = "continuous",
}

interface QueuedDataPoint {
    refDate: Date;
    point: SeriesDataPoint;
}

interface ChangeResult {
    out: SeriesDataPoint[];
    queue: QueuedDataPoint[];
}

type RefDateGetter = (value: Date, index: number, series: SeriesDataPoint[]) => Date | null;

type DurationString = string;
