import { Pipe, PipeTransform } from "@angular/core";
import { from, Observable, of } from "rxjs";

import { DataRefService } from "skInsights/framework/dataRef.service";

@Pipe({ name: "interpolate" })
export class InterpolatePipe implements PipeTransform {

    constructor(
        private dataRefService: DataRefService,
    ) { }

    public transform(origStr?: string): Observable<string> {
        if (!origStr) {
            return of("");
        }

        const real = this.dataRefService.interpolate(origStr);

        return from(real);
    }
}
