/**
 * Group by object property or custom key and return Map.
 */
export function groupBy<Rt, T>(
    items: T[],
    getKey: (v: T) => string,
): Map<string, T[]>;
export function groupBy<Rt, T, K extends keyof T>(
    items: T[],
    key: K,
): Map<T[K], T[]>;
export function groupBy<Rt, T extends Record<string | number | symbol, any>, K extends keyof T>(
    items: T[],
    keyOrFn: K | ((v: T) => T[K]),
): Map<T[K], Rt[]> {
    const map = new Map<string, T[]>();
    const getKey = typeof keyOrFn === "function"
        ? keyOrFn
        : (item: T) => item[keyOrFn];

    for (const item of items) {
        let arr: T[] | undefined = map.get(getKey(item));

        if (!arr) {
            arr = [];
            map.set(getKey(item), arr);
        }

        arr.push(item);
    }

    return map as any;
}

/**
 * Similar to groupBy but only count number of items for each group without
 * actually creating the groups for performance / memory reasons.
 */
export function countByGroup<T>(
    items: T[],
    getKey: (v: T) => string,
): Record<string, number>;
export function countByGroup<T, K extends keyof T>(
    items: T[],
    key: K,
): Record<T[K] extends ValidObjectKey ? T[K] : never, number>;
export function countByGroup<T, K extends keyof T>(
    items: T[],
    keyOrFn: K | ((v: T) => T[K]),
): Record<T[K] extends ValidObjectKey ? T[K] : never, number> {
    const counts: Record<string, number> = {};
    const getKey: Function = typeof keyOrFn === "function"
        ? keyOrFn
        : (item: T) => item[keyOrFn];

    for (const item of items) {
        const key = getKey(item);
        const currentCount = counts[key];

        if (!currentCount) {
            counts[key] = 1;
        } else {
            counts[key]++;
        }
    }

    return counts as Record<T[K] extends ValidObjectKey ? T[K] : never, number>;
}

type ValidObjectKey = string | number | symbol;
