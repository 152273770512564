import { Injectable, NgZone } from "@angular/core";
import mapboxgl, { Map as MapboxMap, MapboxOptions } from "mapbox-gl";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

import { MapStyleService } from "skCommon/map/mapbox/mapStyle.service";
import { getMapboxClient } from "skCommon/mapbox/client";
import { StyleType } from "skCommon/map/mapbox/mapConfig";

/**
 * Service used by the mapbox component that handles the actual creation of the
 * mapbox instance, but can be also used in case we want to create mapbox
 * instance without the use of the mapbox component.
 */
@Injectable({ providedIn: "root" })
export class MapboxService {
    constructor(
        private zone: NgZone,
        private mapStyleService: MapStyleService,
    ) { }

    public async createMap(options: MapboxOptions): Promise<MapboxMap> {
        return this.zone.runOutsideAngular(async () => {
            const client = getMapboxClient();

            mapboxgl.accessToken = client.getApiKey();

            if (
                options.style
                && typeof Object.values(StyleType).some(opt => opt === options.style)
            ) {
                options.style = await this.mapStyleService
                    .getMapboxStyle(options.style as StyleType);
            }

            const map = new MapboxMap(options);

            const canvas = map.getCanvas();

            const resizeSubject = new Subject<null>();
            new ResizeObserver(() => resizeSubject.next(null)).observe(canvas);
            resizeSubject.pipe(
                debounceTime(1),
            ).subscribe(() => map.resize());

            return map;
        });
    }

    public async waitForInit(map: MapboxMap): Promise<void> {
        return this.zone.runOutsideAngular(() => {
            return new Promise<void>((resolve, reject) => {
                const onLoad = () => {
                    map.off("error", onError);
                    resolve();
                };
                const onError = (e: Error) => {
                    map.off("load", onLoad);
                    reject(e);
                };

                map.once("load", onLoad);
                map.once("error", onError);
            });
        });
    }
}
