import { LoggerTransport, LogEntry } from "skCommon/utils/logger";
import { serializeEntry } from "skCommon/utils/logger/tools/serialize";

export class Stackdriver implements LoggerTransport {

    private worker: Worker;

    public constructor() {
        this.worker = new Worker(new URL("./stackdriverWorker.ts", import.meta.url));

        this.worker.addEventListener("error", (e) => {
            // tslint:disable-next-line:no-console
            console.error(e);
        });
    }

    public log(logEntry: LogEntry | string) {

        const message = JSON.stringify(
            {
                entry: logEntry,
                url: window.location.href,
                appName: window.location.host,
            }, serializeEntry);

        this.worker.postMessage(message);
    }
}
