import "moment";
import {
    ChartConfiguration,
    Chart,
    ChartDataset,
    ChartOptions,
} from "chart.js";
import "chartjs-adapter-moment";
import { makeObservable, observable } from "mobx";

import { Point } from "skCommon/utils/geometry";
import { sleep } from "skCommon/utils/delay";

export class ChartContext {

    public chart?: Chart;

    public bounds: ClientRect;

    @observable.ref
    public ready: boolean = false;

    public get options(): ChartOptions {
        return this.chart?.options;
    }

    public get title(): string {
        const text = this.config.options.plugins?.title?.text;

        return typeof text === "string" ? text : text[0];
    }

    constructor(
        public config: ChartConfiguration,
    ) {
        makeObservable(this);
    }

    public update(): void {
        this.chart.update();
    }

    /**
     * Create the chartjs instance and render it on given canvas.
     */
    public render(canvas: HTMLCanvasElement) {
        this.chart = new Chart(
            canvas,
            this.config,
        );
        this.ready = true;

        this.onDatasetsChanged();
        this.update();

        this.bounds = this.chart.canvas.getBoundingClientRect();
    }

    /**
     * Export chart's current state into canvas. Note if different
     * devicePixelRation is provided, output canvas will have actually a
     * different dimensions than the ones provided.
     */
    public async exportAsCanvas(
        width: number,
        height: number,
        dpr?: number,
        keepTransparent: boolean = false,
        preserveConfig: boolean = false,
    ): Promise<HTMLCanvasElement> {
        const exportChartCanvas = document.createElement("canvas");
        exportChartCanvas.width = width;
        exportChartCanvas.height = height;

        const modifiedConfig = preserveConfig
            ? this.config
            : this.prepareExportChartOptions();

        if (typeof dpr === "number") {
            modifiedConfig.options.devicePixelRatio = dpr;
        }

        const exportChart = new Chart(exportChartCanvas, modifiedConfig);

        exportChart.render();

        // since chartjs@3 something in the render call is not sync and thus we
        // need to wait for all microtasks to finish.
        await sleep(0);

        if (!keepTransparent) {
            return this.addWhiteBackground(exportChartCanvas);
        } else {
            return exportChartCanvas;
        }
    }

    public async exportAsPng(width: number, height: number, dpr?: number): Promise<Blob> {
        const exportCanvas = await this.exportAsCanvas(width, height, dpr);

        return new Promise((resolve, reject) => {
            exportCanvas.toBlob(blob => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error("Cannot export canvas to blob"));
                }
            }, "image/png");
        });
    }

    public moveDatasetToAxis(datasetIndex: number, axis: string): void {
        (this.chart.data.datasets[datasetIndex] as ChartDataset<"line">)
            .yAxisID = axis;
        (this.chart as any).resetZoom();
    }

    public projectToScreen({ x, y }: Point): Point {
        const rect = this.chart.canvas.getBoundingClientRect();

        return {
            y: rect.top + y,
            x: rect.left + x,
        };
    }

    protected updateDatasets(datasets: ChartDataset[]): void {
        this.config.data.datasets = datasets;
        this.onDatasetsChanged();
    }

    /**
     * Hook plugins can use to react when datasets change
     */
    protected onDatasetsChanged(): void { }

    /**
     * Modify chart options so it's better suitable for exporting
     */
    private prepareExportChartOptions(): ChartConfiguration {
        const sourceConfig = this.chart ? this.chart.config : this.config;

        return {
            type: sourceConfig.type,
            data: {
                ...sourceConfig.data,
                datasets: sourceConfig.data.datasets.filter(
                    (_, i) => this.chart
                        ? this.chart.isDatasetVisible(i)
                        : true,
                ).map(d => ({
                    ...d,
                    // For whatever reason, when hidden property is
                    // programatically changed, it's not propagated into the
                    // config
                    hidden: false,
                })),
            },
            options: {
                ...sourceConfig.options,
                responsive: false,
                maintainAspectRatio: false,
                layout: {
                    padding: 16,
                },
                animation: false,
                plugins: {
                    tooltip: {
                        enabled: false,
                    },
                    legend: {
                        display: true,
                        position: "bottom",
                        labels: {
                            boxWidth: 6,
                            usePointStyle: true,
                            pointStyle: "circle",
                        },
                    },
                },
            },
        };
    }

    /**
     * Draw given canvas onto canvas with white background
     */
    private addWhiteBackground(canvas: HTMLCanvasElement): HTMLCanvasElement {
        const outCanvas = document.createElement("canvas");

        outCanvas.width = canvas.width;
        outCanvas.height = canvas.height;

        const ctx = outCanvas.getContext("2d");

        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, 0, outCanvas.width, outCanvas.height);

        ctx.drawImage(canvas, 0, 0);

        return outCanvas;
    }
}
