/**
 * Structure used to pass additional data to dashboard components without
 * making the component dependant on unrelated services / logic.
 */
export class DashboardContext {

    public exportable: boolean;

    constructor(conf: Partial<DashboardContext>) {
        this.exportable = conf.exportable || false;
    }
}
