import {
    CommonModule,
} from "@angular/common";
import {
    NgModule,
} from "@angular/core";
import {
    FormsModule,
    ReactiveFormsModule,
} from "@angular/forms";

import {
    SharedModule,
} from "skCommon/angular/shared.module";
import {
    DatePickerComponent,
} from "skCommon/datePicker/datePicker.component";
import {
    DatePickerCalendarComponent,
} from "skCommon/datePicker/calendar/datePickerCalendar.component";
import {
    DatePickerInputComponent,
} from "skCommon/datePicker/input/datePickerInput.component";
import {
    DatePickerCalendarDaysLabelsComponent,
} from "skCommon/datePicker/calendarDaysLabels/datePickerCalendarDaysLabels.component";
import {
    DatePickerCalendarSelectableDaytimesGridComponent,
} from "skCommon/datePicker/selectableDaytimesGrid/datePickerCalendarSelectableDaytimesGrid.component";
import {
    DatePickerCalendarHeaderComponent,
} from "skCommon/datePicker/calendarHeader/datePickerCalendarHeader.component";

@NgModule({
    declarations: [
        DatePickerComponent,
        DatePickerInputComponent,
        DatePickerCalendarComponent,
        DatePickerCalendarHeaderComponent,
        DatePickerCalendarDaysLabelsComponent,
        DatePickerCalendarSelectableDaytimesGridComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        DatePickerComponent,
    ],
})
export class DatePickerModule {}
