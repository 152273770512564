import {
    Chart,
    LineElement,
    TimeScale,
    LinearScale,
    Title,
    Legend,
    LineController,
    PointElement,
    Tooltip,
} from "chart.js";

/**
 * Register all required chartjs components for date/linear line charts to work
 */
export function registerLineCharts(): void {
    Chart.register(
        LineElement,
        TimeScale,
        LinearScale,
        Title,
        Legend,
        LineController,
        PointElement,
        Tooltip,
    );

    (Chart as any).defaults.scale.gridLines.display = false;
    (Chart as any).defaults.scale.display = "auto";
}
