import { Component, ChangeDetectionStrategy, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { Logger } from "skCommon/utils/logger";
import { observableRef } from "skCommon/state/mobxUtils";

import { DashboardService } from "skInsights/dashboard/dashboard.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { InsightsService } from "skInsights/insights.service";
import { DashbaordEditorService } from "skInsights/dashboard/dashboardEditor/dashboardEditor.service";
import { DashboardRef } from "skInsights/framework/dashboardData";

const LOADING = Symbol();

@Component({
    selector: "sk-dashboard-editor",
    templateUrl: "./dashboardEditor.pug",
    styleUrls: ["./dashboardEditor.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardEditorComponent extends BaseComponent implements OnDestroy {

    @observableRef
    public refId?: string;

    public get overwriting(): boolean {
        return !!this.dashbaordEditorService.overwriting;
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private dashboardService: DashboardService,
        private snackBarService: SnackBarService,
        private logger: Logger,
        private insightsService: InsightsService,
        private dashbaordEditorService: DashbaordEditorService,
    ) {
        super();

        makeObservable(this);

        this.addSubscription(this.activatedRoute.data.subscribe(data => {
            const componentData = data as DashboardEditorComponentData;

            this.refId = componentData.dashboardRef.id;
            this.prepareDashboard(componentData);
        }));
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.dashbaordEditorService.reset();
    }

    public async prepareDashboard({ dashboardRef }: DashboardEditorComponentData): Promise<void> {
        try {
            this.insightsService.globalLoadingProcesses.add(LOADING);

            const dashboard = await this.dashboardService.loadDashboardRef(dashboardRef);

            this.dashbaordEditorService.dashboard = dashboard;
        } catch (e) {
            this.snackBarService.notify(`Dashboard could not be loaded: ${e.message}`);
            this.logger.error(e, "Preparing dashboard in editor");
        } finally {
            this.insightsService.globalLoadingProcesses.delete(LOADING);
        }
    }
}

export interface DashboardEditorComponentData {
    dashboardRef: DashboardRef;
}
