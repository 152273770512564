import { ImageOptions } from "jspdf";

export abstract class ImageRetriever {

    public abstract getLogo(): Promise<RetrievedImage>;

    public abstract getImage(url: string): Promise<RetrievedImage>;

}

export interface RetrievedImage {
    data: ImageOptions["imageData"];
    width: number;
    height: number;
}
