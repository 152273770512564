import { ChangeDetectionStrategy, Component } from "@angular/core";
import { makeObservable } from "mobx";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { observableRef } from "skCommon/state/mobxUtils";

import { DesignerModel } from "skInsights/framework/designer/designerModel";
import { PageModel } from "skInsights/modules/core/components/sidebarView/designer/page.model";
import { SectionModel } from "skInsights/modules/core/components/sidebarView/designer/section.model";
import { SidebarViewComponentDef } from "skInsights/modules/core/components/sidebarView/sidebarView.component";

@Component({
    selector: "sk-sidebar-view-designer",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./sidebarViewDesigner.pug",
    styleUrls: ["./sidebarViewDesigner.scss"],
})
export class SidebarViewDesignerComponent extends BaseComponent {

    @observableRef
    public selectedPage?: PageModel;

    public sections: SectionModel[];

    constructor(private designerModel: DesignerModel<SidebarViewComponentDef>) {
        super();

        makeObservable(this);

        const origDef = this.designerModel.initialDefinition;

        this.sections = origDef.sections.map(secDef => new SectionModel(secDef));
        this.selectedPage = this.sections[0].pages[0];

        const sectionDefs$ = combineLatest(this.sections.map(sec => sec.definition$));

        const definition$ = sectionDefs$.pipe(
            map(sections => ({
                ...origDef,
                sections,
            })),
        );

        this.designerModel.updateDefinitionStream(definition$);
    }
}
