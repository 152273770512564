import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";

import { RouterStore } from "skCommon/angular/router.store";

import { UserService } from "skInsights/user/user.service";

@Injectable({ providedIn: "root" })
export class AnonGuard implements CanActivate {

    constructor(private routerStore: RouterStore, private userStore: UserService) { }

    public async canActivate(): Promise<boolean> {
        await this.userStore.initUser();

        if (this.userStore.loggedIn) {
            this.routerStore.navigate(["/"]);
            return false;
        }

        return true;
    }
}
