import { Injectable } from "@angular/core";
import { SubscriptionLike } from "rxjs";

@Injectable()
export abstract class SubscriptionCleanerService {

    protected subscriptions: SubscriptionLike[] = [];

    /**
     * Make lifecycle member aware of given subscription and make sure its
     * unsubscibed on its destroyal.
     */
    public addSubscription(sub: SubscriptionLike): void {
        this.subscriptions.push(sub);
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
