import { OnInit, OnDestroy, Directive } from "@angular/core";
import {
    reaction,
    when,
    autorun,
    IReactionPublic,
    IReactionOptions,
} from "mobx";
import { keepAlive } from "mobx-utils";

import { SubscriptionCleanerService } from "skCommon/angular/base/subscriptionCleaner.service";
import { whenever } from "skCommon/state/mobxUtils";

@Directive()
export abstract class BaseComponent
    extends SubscriptionCleanerService
    implements BaseComponentInterface {

    private _initListeners: Function[];
    private _removeListeners: Function[];

    protected get removeListeners() {
        // lazy initialization
        if (!this._removeListeners) {
            this._removeListeners = [];
        }

        return this._removeListeners;
    }

    private get initListeners() {
        // lazy initialization
        if (!this._initListeners) {
            this._initListeners = [];
        }

        return this._initListeners;
    }

    public ngOnInit() {
        for (const listener of this.initListeners) {
            this.removeListeners.push(listener.call(this));
        }
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        for (const unlistener of this.removeListeners) {
            unlistener.call(this);
        }
    }

    /**
     * Adds a listener setup function to a list. The function is executed in
     * ngOnInit lifecycle stage and HAS TO return a function which cancels
     * or removes the listener
     *
     * @param {Function} listenerSetup
     */
    public addInitListener(listenerSetup: Function) {
        this._initListeners = this.initListeners.slice();
        this._initListeners.push(listenerSetup);
    }

    public setInterval(handler: Function, int: number) {
        const id = setInterval(handler, int);

        this.removeListeners.push(
            clearTimeout.bind(window, id),
        );
    }

    /**
     * MobX reaction wrapper that also handles disposement on component destroy
     */
    public reaction<T>(
        expression: (r: IReactionPublic) => T,
        effect: (arg: T, prev: T, r: IReactionPublic) => void,
        opts?: IReactionOptions<T, boolean>,
    ) {
        this.removeListeners.push(
            reaction(
                expression,
                effect,
                Object.assign({ context: this }, opts),
            ),
        );
    }

    public whenever(
        expression: (r: IReactionPublic) => boolean,
        effect: () => void,
        opts?: IReactionOptions<boolean, boolean>,
    ) {
        this.removeListeners.push(
            whenever(
                expression,
                effect,
                Object.assign({ context: this }, opts),
            ),
        );
    }

    public when(
        expression: () => boolean,
        effect: () => void,
    ) {
        this.removeListeners.push(
            <Function>when(
                expression,
                effect,
            ),
        );
    }

    /**
     * MobX autorun wrapper that also handles disposement on component destroy
     */
    public autorun(executor: (r: IReactionPublic) => void) {
        this.removeListeners.push(autorun(executor));
    }

    /**
     * Wrapper around keepAlive mobx util.
     */
    public keepAlive(mobxObservable: any, property?: string) {
        if (property) {
            this.removeListeners.push(keepAlive(mobxObservable));
        } else {
            this.removeListeners.push(keepAlive(mobxObservable, property));
        }
    }

    /**
     * Method that can be used with the ngFor's trackby to easily track by item's id.
     */
    public trackBy(property: string): Function {
        return (_i: number, obj: any) => obj[property];
    }

    protected onDestroy(fn: Function) {
        this.removeListeners.push(fn);
    }
}

export interface BaseComponentConstructor {
    prototype: BaseComponent;
    new(...args): BaseComponentInterface;
}

interface BaseComponentInterface extends OnInit, OnDestroy { }

