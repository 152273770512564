import { Injectable, Injector } from "@angular/core";

import { LinkHandler } from "skInsights/framework/insightsProtocol";
import { DocumentComponentDef } from "skInsights/modules/documents/components/document.component";
import { PopupService } from "skInsights/partials/popup/popup.service";

@Injectable()
export class DocumentLinkHandlerService implements LinkHandler {
    public match = /\/document\/([0-z]+)/;

    constructor(private popupService: PopupService) { }

    public execute(link: string, injector: Injector): void {
        const [, documentId] = link.match(this.match)!;

        this.popupService.openDialog<DocumentComponentDef>(injector, {
            component: "documents/document",
            documentId,
        });
    }
}
