import { ChangeDetectionStrategy, Component, Inject, NgModule } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { SharedModule } from "skCommon/angular/shared.module";
import { AutorunModule } from "skCommon/angular/state/autorun.module";
import { DeferredPromise, DialogData, PromptPayload } from "skCommon/ui/dialog/dialog.misc";

@Component({
    templateUrl: "./confirmDialog.pug",
    styleUrls: ["./confirmDialog.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {

    public defer: DeferredPromise<boolean | null>;

    public get title(): string {
        return this.data.title;
    }

    public get description(): string | undefined {
        return this.data.description;
    }

    public get nullOption(): string {
        return this.data?.nullOption || "";
    }

    // If null option is defined, then the dialog has three action buttons
    // with null option labeled as "Cancel" then default negative button
    // must be labeled differently as "No"
    public get negativeActionButtonText(): string {
        return this.data?.actionButtons?.negative?.text || "No";
    }

    public get negativeActionButtonColor(): string {
        return this.data?.actionButtons?.negative?.color || "warn";
    }

    public get positiveActionButtonText(): string {
        return this.data?.actionButtons?.positive?.text || "Yes";
    }

    public get positiveActionButtonColor(): string {
        return this.data?.actionButtons?.positive?.color || "primary";
    }

    public get noIsPrimary(): boolean {
        return !!this.data?.noIsPrimary;
    }

    public get primaryLabel(): string {
        return this.noIsPrimary
            ? this.negativeActionButtonText
            : this.positiveActionButtonText;
    }

    public get secondaryLabel(): string {
        return this.noIsPrimary
            ? this.positiveActionButtonText
            : this.negativeActionButtonText;
    }

    private data: PromptPayload<ConfirmDialogPayload>;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        data: ConfirmDialogData,
    ) {
        this.data = data.payload;
        this.defer = data.defer;
    }

    public yes(): void {
        this.defer.resolve(true);
    }

    public no(): void {
        this.defer.resolve(false);
    }

    public submitNull(): void {
        this.defer.resolve(null);
    }

    public doPrimaryAction(): void {
        if (this.noIsPrimary) {
            this.no();
        } else {
            this.yes();
        }
    }

    public doSecondaryAction(): void {
        if (this.noIsPrimary) {
            this.yes();
        } else {
            this.no();
        }
    }
}

export type ConfirmDialogData = DialogData<boolean | null, PromptPayload<ConfirmDialogPayload>>;

interface ConfirmDialogPayload {
    nullOption?: string;
    noIsPrimary?: boolean;
    actionButtons?: {
        negative?: {
            text: string;
            color?: "primary" | "warn";
        };
        positive?: {
            text: string;
            color?: "primary" | "warn";
        };
    };
}


@NgModule({
    imports: [
        SharedModule,
        AutorunModule,
    ],
    declarations: [
        ConfirmDialogComponent,
    ],
})
export class ConfirmDialogModule { }

