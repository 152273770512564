import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { mergeMap, every, filter } from "rxjs/operators";

import { Doc } from "skCommon/firebase/doc";
import { DashboardAccessType, getAccessType } from "skCommon/insights/customers";
import { AnySourceDef, Dashboard } from "skCommon/insights/dashboard";
import { DashboardDigest } from "skCommon/insights/dashboardDigest";
import { ParsedDashboard } from "skCommon/insights/parsedDashboard";
import { Insights } from "skCommon/permissions";

import { UserService } from "skInsights/user/user.service";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";

/**
 * Service used to check whether user can open or edit a dashboard.
 */
@Injectable({ providedIn: "root" })
export class AvailabilityService {

    constructor(
        private userService: UserService,
        private moduleRegistryService: ModuleRegistryService,
    ) { }

    public async getAvailability(digest: Doc<DashboardDigest>): Promise<DashboardAvailability> {
        const owned = this.userService.id === digest.userId;
        const access = this.getCustomerAccess(digest.id);

        const available = this.userService.loggedIn && (
            owned
            || (
                (!!digest.global || !!digest.public)
                && access !== DashboardAccessType.None
                && await this.canAccessSources(digest.sources)
            )
        );

        const editable = this.isEditable(digest);

        return { available, editable };
    }

    public isEditable(dashboard: AnyDashboardStruct): boolean {
        return this.userService.loggedIn
            && this.userService.permissions.has(Insights.Dashboard.Edit)
            && (
                this.userService.id === dashboard.userId
                || this.userService.permissions.has(Insights.Admin.Overwrite)
            );
    }

    /**
     * Check whether user is allowed to export raw data from given dashboard.
     */
    public isExportable(dashboard: AnyDashboardStruct): boolean {
        const access = this.getCustomerAccess(dashboard.id);

        return this.userService.permissions.has(Insights.Data.Export)
            && (access === DashboardAccessType.Full || access === DashboardAccessType.None);
    }

    private async canAccessSources(defs: AnySourceDef[]): Promise<boolean> {
        const canAccessSources = await from(defs).pipe(
            filter(def => !def.hidden),
            mergeMap(async def => {
                const source = this.moduleRegistryService.getSource(def);

                return await source.canAccess(def);
            }),
            every(ok => ok),
        ).toPromise();

        return !!canAccessSources;
    }

    /**
     * Check user's Customer info which may contain further information about
     * access to given dashboard.
     */
    private getCustomerAccess(dbId: string): DashboardAccessType {
        return getAccessType(this.userService.customerInfo, dbId);
    }
}

export type AnyDashboardStruct = Doc<DashboardDigest> | Doc<Dashboard> | ParsedDashboard;

export interface DashboardAvailability {
    available: boolean;
    editable: boolean;
}
