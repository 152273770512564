import { Injectable, Inject, Optional, Type } from "@angular/core";

import { AnySourceDef, ComponentDef, AnyOperationDef } from "skCommon/insights/dashboard";

import { DASHBOARD_SOURCES_TOKEN, OPERATIONS_TOKEN, LAYOUT_COMPONENTS_TOKEN, ComponentClassDefinition, LayoutComponentOptions } from "skInsights/framework/tokens";
import { DashboardSource } from "skInsights/framework/abstract/dashboardSource";
import { Operation, OperationWithPipe } from "skInsights/framework/abstract/operation";
import { LayoutComponent } from "skInsights/framework/abstract/layoutComponent";
import { AnyDashboardData } from "skInsights/framework/data/structures";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { DashboardPipe, PipeResult } from "skInsights/framework/pipe";
import { PipeArgument } from "skInsights/framework/pipeCall";

@Injectable({ providedIn: "root" })
export class ModuleRegistryService {

    private sources: Map<string, DashboardSource<any>> = new Map();
    private operations: Map<string, Operation<any>> = new Map();
    private components: Map<string, ComponentClassDefinition> = new Map();

    constructor(
        @Optional()
        @Inject(DASHBOARD_SOURCES_TOKEN)
        sources: DashboardSource<any>[],
        @Optional()
        @Inject(OPERATIONS_TOKEN)
        operations: Operation<any>[],
        @Optional()
        @Inject(LAYOUT_COMPONENTS_TOKEN)
        components: ComponentClassDefinition[],
    ) {
        for (const source of (sources || [])) {
            this.sources.set(source.type, source);
        }
        for (const operation of (operations || [])) {
            this.operations.set(operation.type, operation);
        }
        for (const component of (components || [])) {
            this.components.set(component.type, component);
        }
    }

    public getSource<T extends AnySourceDef>(def: T): DashboardSource<T> {
        if (!this.sources.has(def.type)) {
            throw new Error(`Source type ${def.type} is not supported`);
        }

        return this.sources.get(def.type)!;
    }

    public getAngularComponent<T extends ComponentDef>(def: T): Type<LayoutComponent<T>> {
        if (!this.components.has(def.component)) {
            throw new Error(`Component ${def.component} is not supported`);
        }

        return this.components.get(def.component)!.componentClass;
    }

    public getComponentOptions<T extends ComponentDef>(def: T): LayoutComponentOptions<T> {
        if (!this.components.has(def.component)) {
            throw new Error(`Component ${def.component} is not supported`);
        }

        return this.components.get(def.component)!.options || {};
    }

    public executeOperation<T extends AnyOperationDef>(
        def: T,
        data: AnyDashboardData,
        dataRefService: DataRefService,
    ): AnyDashboardData | Promise<AnyDashboardData> {
        if (!this.operations.has(def.operation)) {
            throw new Error(`Operation ${def.operation} is not supported`);
        }

        return this.operations.get(def.operation)!.execute(def, data, dataRefService);
    }

    public getOperationProvidedPipes(): Record<string, DashboardPipe> {
        const pipeEntries = [...this.operations.values()]
            .filter<OperationWithPipe<any>>(
                (o): o is OperationWithPipe<any> => o instanceof OperationWithPipe,
            )
            .map(op => [op.pipe, {
                async execute(
                    input: AnyDashboardData,
                    args: PipeArgument[],
                    dataRefService: DataRefService,
                ) {
                    return new PipeResult(
                        await op.executePipe(args, input, dataRefService),
                    );
                },
            }] as const);

        return Object.fromEntries(pipeEntries);
    }
}
