import { Subject } from "rxjs";
import { formatNumber } from "@angular/common";

/**
 * Parse date formats used by Spaceknow APIs
 */
export function parseDate(inp: string): Date {
    return new Date(inp.replace(" UTC", "").replace(" ", "T") + "Z");
}

/**
 * Format date into format used by Spaceknow APIs
 */
export function formatDate(date: Date) {
    return date.toISOString().substr(0, 19).replace("T", " ");
}

/**
 * Format date into short format used in ranges by Spaceknow APIs
 */
export function formatShortDate(date: Date): string {
    return date.toISOString().substr(0, 10);
}


/**
 * Returns date in format "yyyy'-W'w"
 */
export function getWeeklyDateFormat(date: Date, firstWeekDay?: FirstWeekDay): string {
    return date.getUTCFullYear() + "-W"
        + getWeekNumber(date, firstWeekDay).toString().padStart(2, "0");
}

/**
 * Convert area in meters into the most suitable units.
 */
export function getFormattedArea(areaInMsq: number, minPrecision = 3): string {
    const { unit, formattedValue } = getFormattedAreaComponents(areaInMsq, minPrecision);

    return `${formattedValue} ${unit}`;
}

/**
 * Convert area in meters into the most suitable units.
 */
export function getFormattedAreaComponents(
    areaInMsq: number,
    minPrecision: number = 3,
): FormattedArea {
    let unit: string;
    let exp: number;

    if (areaInMsq > 1e4) {
        unit = "km²";
        exp = 1e6;
    } else {
        unit = "m²";
        exp = 1;
    }

    const log = areaInMsq !== 0 ? Math.log10(areaInMsq / exp) : -1;
    const nonDecimals = Math.floor(log) + 1;
    const decimals = Math.max(minPrecision - nonDecimals, 0);
    const roundedValue = round(areaInMsq / exp, decimals);
    const formattedValue = formatNumber(roundedValue, "en_US", "1.0-" + decimals);

    return {
        value: roundedValue,
        formattedValue,
        unit,
    };
}

export function padDecimalNumber(num: number, preferredLength: number) {
    const pad = preferredLength - Math.floor(num).toString(10).length;
    return round(num, pad);
}

export function recursiveJoin(array: Array<any>, separator: string): string {
    let str = "";

    array.forEach((el) => {
        let part;

        if (el instanceof Array) {
            if (el.length) {
                part = recursiveJoin(el, separator);
            }
        } else {
            part = el.toString();
        }

        if (part !== undefined) {
            str += part.toString();
        }

        str += separator;
    });

    return str.slice(0, -1);
}

export function arrayContentEquals<T>(a: Array<T>, b: Array<T>) {
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}

export function sum(summand1: number, summand2: number): number {
    return summand1 + summand2;
}

export function getMean(data: number[]): number {
    return data.reduce(sum) / data.length;
}

/**
 * Converts array of integers in **incremental order** to array of intervals
 * representing sequences of integers. Returned intervals are inclusive.
 * E. g.
 * [1, 2, 4, 10, 11, 12, 13, 14] => [[1, 2], [4, 4], [10, 14]]
 */
export function sequenceToIntervals(input: number[]): [number, number][] {
    const intervals: [number, number][] = [];

    let currentFrom: number = null,
        currentTo: number = null;

    for (const num of input) {
        if (currentFrom === null) {
            currentFrom = num;
        } else if (currentTo === null) {
            if (currentFrom + 1 >= num) {
                currentTo = num;
            } else {
                intervals.push([currentFrom, currentFrom]);
                currentFrom = num;
            }
        } else {
            if (currentTo + 1 >= num) {
                currentTo = num;
            } else {
                intervals.push([currentFrom, currentTo]);
                currentFrom = num;
                currentTo = null;
            }
        }
    }

    intervals.push([
        currentFrom,
        currentTo === null ? currentFrom : currentTo,
    ]);

    return intervals;
}

export function round(value: number, places = 0): number {
    if (places > 0) {
        const exp = 10 ** places;

        return Math.round(value * exp) / exp;
    } else {
        return Math.round(value);
    }
}

export class MonthNameResolver {
    private readonly names: Array<string>;

    constructor(format: Intl.DateTimeFormatOptions["month"] = "long") {
        const defaultLocale = Intl.DateTimeFormat().resolvedOptions().locale;
        const defaultValid = defaultLocale && defaultLocale === "und";

        const formatter = new Intl.DateTimeFormat(
                defaultValid ? defaultLocale : "en-US",
                {
                    month: format,
                },
            ),
            date = new Date(2016, 0, 10);

        this.names = new Array(12);

        for (let i = 0; i < 12; i++) {
            date.setUTCMonth(i);
            this.names[i] = formatter.format(date);
        }
    }

    public resolve(month: number) {
        return this.names[month];
    }
}

class LocalDataProvider {
    private readonly FLAG_TRUE = "true";
    private readonly FLAG_FALSE = "false";
    private readonly FLAG_PREFIX = "f:";

    public readonly flagChanges = new Subject();

    private storage = window.localStorage;

    public hasFlag(name: string) {
        const val = this.storage.getItem(this.FLAG_PREFIX + name);

        return val === this.FLAG_TRUE;
    }

    public setFlag(name: string) {
        this.storage.setItem(this.FLAG_PREFIX + name, this.FLAG_TRUE);
        this.flagChanges.next({ name, action: "set" });
    }

    public unsetFlag(name: string) {
        this.storage.setItem(this.FLAG_PREFIX + name, this.FLAG_FALSE);
        this.flagChanges.next({ name, action: "unset" });
    }

    public getFlags() {
        const flags = {};

        for (let i = 0; i < this.storage.length; i++) {
            const key = this.storage.key(i);

            if (key.startsWith(this.FLAG_PREFIX)) {
                const k = key.slice(this.FLAG_PREFIX.length);
                flags[k] = this.hasFlag(k);
            }
        }

        return flags;
    }

    public setObject<T>(name: string, value: T) {
        this.storage.setItem(name, JSON.stringify(value));
    }

    public getObject<T>(name: string): T {
        const json = this.storage.getItem(name);
        let obj: {};

        try {
            obj = JSON.parse(json);
        } catch (e) {
            // TODO: Why do we swallow this error? Whyyyyyyyyyyyyyyyy?
            obj = undefined;
        }

        return <T>obj;
    }

    public remove(name: string) {
        this.storage.removeItem(name);
    }

    public removeFlag(name: string): void {
        this.remove(this.FLAG_PREFIX + name);
    }
}

let localData: LocalDataProvider;

export function getLocalData() {
    if (!localData) {
        localData = new LocalDataProvider();
    }

    return localData;
}


/**
 * From strftime npm package, counts weekdays like in python (for backend compatibility)
 */
function getWeekNumber(date: Date, firstWeekDay = FirstWeekDay.Sunday): number {
    // This works by shifting the weekday back by one day if we
    // are treating Monday as the first day of the week.
    let weekDay = date.getDay();
    if (firstWeekDay === FirstWeekDay.Monday) {
        if (weekDay === 0) {// Sunday
            weekDay = 6;
        } else {
            weekDay--;
        }
    }

    const firstDayOfYearUtc = Date.UTC(date.getFullYear(), 0, 1);
    const dateUtc = Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
    const yearDay = Math.floor((dateUtc - firstDayOfYearUtc) / 86400000);
    const weekNum = (yearDay + 7 - weekDay) / 7;

    return Math.floor(weekNum);
}

export enum FirstWeekDay {
    Monday = "Monday",
    Sunday = "Sunday",
}

interface FormattedArea {
    value: number;
    unit: string;
    formattedValue: string;
}
