import { Injectable, Injector } from "@angular/core";
import { computed, makeObservable } from "mobx";

import { Insights } from "skCommon/permissions";
import { assert } from "skCommon/utils/assert";
import { ReinjectorService } from "skCommon/angular/reinjector.service";
import { PageExportService } from "skCommon/exports/pageExport/pageExport.service";
import { PageExport } from "skCommon/exports/pageExport/pageExport";
import { ComponentDef } from "skCommon/insights/dashboard";

import { DataRefService } from "skInsights/framework/dataRef.service";
import { DashboardGlobals } from "skInsights/dashboard/globalConstants.service";
import { UserService } from "skInsights/user/user.service";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { DashboardPdfOptions } from "skInsights/framework/dashboardPdf/dashboardPdfOptions";

/**
 * Service for downloading the whole dashboard page as PDF. Provided for each
 * dashboard specifically so dashboard information can be injected.
 */
@Injectable()
export class DashboardPdfService {

    @computed
    public get available(): boolean {
        return this.userService.permissions.has(Insights.Dashboard.Export);
    }

    constructor(
        private userService: UserService,
        private dataRefService: DataRefService,
        private moduleRegistryService: ModuleRegistryService,
        private injector: Injector,
        private reinjectorService: ReinjectorService,
        private pageExportService: PageExportService,
    ) {
        makeObservable(this);
    }

    public async downloadPdf(
        componentDef: ComponentDef,
        options: DashboardPdfOptions = {},
    ): Promise<void> {
        const page = await this.pageExportService.createPageExport();

        await this.renderComponent(page, componentDef, true, options);

        const dbName = this.dataRefService.getConstant(DashboardGlobals.DashboardName);

        this.pageExportService.downloadPageExport(page, dbName);
    }

    /**
     * Render component according its definition into existing PageExport
     * instance.
     */
    public async renderComponent(
        page: PageExport,
        componentDef: ComponentDef,
        required: boolean = false,
        options: DashboardPdfOptions,
    ): Promise<void> {

        const componentOptions = this.moduleRegistryService.getComponentOptions(componentDef);
        const StaticRenderer = componentOptions.staticRenderer;

        assert(!required || StaticRenderer, `Root component ${componentDef.component} has no static renderer`);

        if (StaticRenderer) {
            const renderer = this.reinjectorService.get(this.injector, StaticRenderer);
            await renderer.renderIntoPage(componentDef, page, options);
        }
    }
}
