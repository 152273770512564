import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import {
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import {
    DateTime,
} from "luxon";

import {
    CALENDAR_WIDTH,
    CalendarDialogData,
    CalendarOptions,
    DEFAULT_CALENDAR_OPTIONS,
    DEFAULT_INPUT_LABEL,
    DATEPICKER_INPUT_BOTTOM_PADDING,
} from "skCommon/datePicker/model";
import {
    DatePickerCalendarComponent,
} from "skCommon/datePicker/calendar/datePickerCalendar.component";

@Component({
    selector: "sk-date-picker-input",
    templateUrl: "./datePickerInput.pug",
    styleUrls: ["./datePickerInput.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerInputComponent implements OnInit {

    public selectedDateTime: DateTime | undefined = undefined;

    @Input()
    public minDate: Date | undefined = undefined;

    @Input()
    public maxDate: Date | undefined = undefined;

    @Input()
    public options: CalendarOptions = DEFAULT_CALENDAR_OPTIONS;

    @Output()
    public dateSelected = new EventEmitter<Date>();

    constructor(
        private readonly dialog: MatDialog,
        private readonly elementRef: ElementRef,
    ) { }

    @HostListener("click", ["$event"])
    public onClick(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        this.openCalendar();
    }

    public ngOnInit(): void {
        if (!!this.options?.initialValue) {
            this.selectedDateTime = this.options.initialValue
                ? DateTime.fromJSDate(this.options.initialValue, { zone: "utc" })
                : undefined;
        }
    }

    public get label(): string {
        return this.options.label || DEFAULT_INPUT_LABEL;
    }

    public get selectedDateLongName(): string {
        return this.selectedDateTime
            ? this.selectedDateTime.toLocaleString(DateTime.DATE_FULL) : "";
    }

    private get minDateTime(): DateTime | undefined {
        return this.minDate
            ? DateTime.fromJSDate(this.minDate, { zone: "utc" })
            : undefined;
    }

    private get maxDateTime(): DateTime | undefined {
        return this.maxDate
            ? DateTime.fromJSDate(this.maxDate, { zone: "utc" })
            : undefined;
    }

    private openCalendar(): void {
        const boundingRect: DOMRect = this.elementRef.nativeElement.getBoundingClientRect();
        const dialogCoveredArea = boundingRect.left + CALENDAR_WIDTH;
        const calendarDialogData: CalendarDialogData = {
            selectedDateTime: this.selectedDateTime,
            minDateTime: this.minDateTime,
            maxDateTime: this.maxDateTime,
            options: this.options,
        };
        const calendarDialogViewSettings = {
            autoFocus: false,
            restoreFocus: false,
            panelClass: "sk-calendar-dialog",
            backdropClass: "sk-transparent-backdrop-overlay",
            width: CALENDAR_WIDTH + "px",
            position: {
                right: `calc(100vw - ${dialogCoveredArea}px)`,
                top: `${boundingRect.bottom - DATEPICKER_INPUT_BOTTOM_PADDING}px`,
            },
        };

        const dialogRef: MatDialogRef<DatePickerCalendarComponent> | undefined
            = this.dialog.open(DatePickerCalendarComponent, {
                ...calendarDialogViewSettings,
                data: calendarDialogData,
            });

        dialogRef.afterClosed().subscribe((selectedDateTime: DateTime) => {
            if (selectedDateTime) {
                this.selectedDateTime = selectedDateTime;
                this.dateSelected.emit(selectedDateTime.toJSDate());
            }
        });
    }
}
