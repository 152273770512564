import { ChangeDetectionStrategy, Component, ElementRef, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: "sk-expanded-card",
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: ``,
})
export class ExpandedCardComponent {

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        @Inject(MAT_DIALOG_DATA)
        private dialogData: ExpandedCardData,
    ) { }

    public ngAfterViewInit(): void {
        this.elementRef.nativeElement.appendChild(this.dialogData.element);
    }
}

export interface ExpandedCardData {
    element: HTMLElement;
}
