import { PermissionLike } from "skCommon/utils/permissions";

export function checkPermissions(
    userPermissions: string[],
    permissions: PermissionLike[],
    all: boolean,
) {
    const permCheck = checkPermission.bind(this, userPermissions);

    return all
        ? permissions.every(permCheck)
        : permissions.some(permCheck);
}

function checkPermission(userPermissions: string[], permission: PermissionLike): boolean {
    const testedPerm = permission.toString().split(".");
    return userPermissions.some((profilePerm) => {
        const userPerm = profilePerm.split(".");
        const testLength = Math.min(userPerm.length, testedPerm.length);

        return userPerm.slice(0, testLength).join(".")
            === testedPerm.slice(0, testLength).join(".");
    });
}
