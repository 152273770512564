/**
 * Helper function that picks property out of object only if it exists.
 * Sometimes we want to set property only if some variable exists, otherwise
 * the property should not exist at all (not even with the undefined value).
 *
 * To do that we can use following code:
 * ```
 * {
 *  ...(some.input ? { input: some.input } : {})
 * }
 * ```
 *
 * And this function does exactly that but it's easier to use:
 *
 * ```
 * {
 *  ...pickIfExists(some, "input")
 * }
 * ```
 */
export function pickIfExists<
    T extends {},
    K extends keyof T & string,
    O extends string = K,
>(
    obj: T,
    key: K,
    outKey: O,
): {} | { [k in O]: T[K] };
export function pickIfExists<
    T extends {},
    K extends keyof T & string,
    O extends string = K,
>(
    obj: T,
    key: K,
): {} | { [k in K]: T[K] };
export function pickIfExists<
    T extends {},
    K extends keyof T & string,
    O extends string = K,
>(
    obj: T,
    key: K,
    outKey?: O,
): {} | { [k in K]: T[K] } {
    if (key in obj && obj[key]) {
        return { [outKey || key]: obj[key] };
    } else {
        return {};
    }
}

export function omit<T, K extends keyof T>(obj: T, prop: K): Omit<T, K> {
    obj = { ...obj };
    delete obj[prop];
    return obj;
}
