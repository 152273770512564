/**
 * Collection of computed minimal dashboard representations used when listing
 * available dashboards.
 */
export const dashboardDigests = collection("dashboardDigests");

/**
 * Complete schema of the dashboard containing all information required to
 * display the dashboard in all its beauty.
 */
export const dashboards = collection("dashboards");

/**
 * Subcollection containing dashboard revision history
 */
export const dashboardRevisions = collection("revisions");

/**
 * Documents containing metadata about the source like details about last
 * computations etc.
 */
export const externalSources = collection("externalSources");

/**
 * Subcollection containing the actual scraped data.
 */
export const externalSourcesData = subCollection(externalSources, "data");

/**
 * HTML-like documents that can be displayed as part of the dashboard.
 */
export const insightsDocuments = collection("insightsDocuments");

/**
 * Collection containing info about users with access to the dashboards.
 */
export const insightsCustomers = collection("insightsCustomers");

function collection(name: string): Collection {
    return (ref?: string) => ref ? `${name}/${ref}` : name;
}

function subCollection(parent: Collection, name: string): SubCollection {
    const sub = collection(name);
    return (ref: string, ref2?: string) => parent(ref) + "/" + sub(ref2);
}

type Collection = (ref?: string) => string;
type SubCollection = (ref: string, ref2?: string) => string;
