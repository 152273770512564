import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import firebase from "firebase";
import { Observable, combineLatest, of, from, lastValueFrom } from "rxjs";
import { map, catchError, switchMap, toArray, mergeMap, shareReplay } from "rxjs/operators";
import { observable, makeObservable } from "mobx";
import cloneDeep from "clone-deep";
import { toStream } from "mobx-utils";

import { DashboardFirestoreRevision } from "skCommon/insights/dashboardFirebaseRevision";
import { deduplicate } from "skCommon/utils/deduplication";
import { Logger } from "skCommon/utils/logger";
import { Doc, adaptFirestoreDoc, createFirestoreDoc } from "skCommon/firebase/doc";
import { ComponentDef, Dashboard, DashboardSchema } from "skCommon/insights/dashboard";
import { DashboardDigest } from "skCommon/insights/dashboardDigest";
import { dashboardDigests, dashboardRevisions, dashboards } from "skCommon/insights/database";
import { ParsedDashboard, parseDashboard } from "skCommon/insights/parsedDashboard";

import { AvailableDashboard } from "skInsights/dashboard/availableDashboard";
import { CardComponentDef } from "skInsights/framework/card/cardComponent";
import { DashboardRef } from "skInsights/framework/dashboardData";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { LineChartComponentType } from "skInsights/modules/charts/lineChart/lineChart.component";
import { DocumentComponentDef } from "skInsights/modules/documents/components/document.component";
import { UserService } from "skInsights/user/user.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { AvailabilityService, DashboardAvailability } from "skInsights/framework/availability.service";


@Injectable({ providedIn: "root" })
export class DashboardService {

    @observable.ref
    public availableDashboards$ = this.getSharedAvailableDashboards$();

    constructor(
        private userService: UserService,
        private angularFirestore: AngularFirestore,
        private snackBarService: SnackBarService,
        private logger: Logger,
        private availabilityService: AvailabilityService,
        private matSnackBar: MatSnackBar,
        private moduleRegistryService: ModuleRegistryService,
    ) {
        makeObservable(this);
    }
    public async loadAllGlobalDashboards(): Promise<ParsedDashboard[]> {
         const collection = await this.angularFirestore.collection<Doc<Dashboard>>(
            dashboards(),
            (ref) => ref.where("global", "==", true),
        ).get().toPromise();

        const parsedDashboards = [];
        if (collection) {
            for (const dashboard of collection.docs) {
                if (dashboard) {
                    parsedDashboards.push(parseDashboard(adaptFirestoreDoc<Dashboard>(dashboard)));
                }
            }
        }
        return parsedDashboards;

    }

    public getAllDashboard(): Observable<AvailableDashboard[]> {
        return this.angularFirestore.collection<Doc<DashboardDigest>>(
            dashboardDigests())
            .get()
            .pipe(
                map(collection => collection.docs),
                map(docs => docs.map(d => adaptFirestoreDoc(d))),
                switchMap(docs => this.makeAvailableDashboards(docs)),
            );
    }

    public async loadDashboardRef(ref: DashboardRef): Promise<ParsedDashboard> {
        if (ref.id) {
            let doc: firebase.firestore.DocumentSnapshot<Dashboard>;
            if (ref.revisionId) {
                doc = await lastValueFrom(
                    this.angularFirestore
                        .doc<Dashboard>(`${dashboards(ref.id)}/${dashboardRevisions(ref.revisionId)}`)
                        .get(),
                );
            } else {
                doc = await lastValueFrom(
                    this.angularFirestore.doc<Dashboard>(dashboards(ref.id))
                        .get(),
                );
            }

            if (doc.exists) {
                return parseDashboard(adaptFirestoreDoc<Dashboard>(doc));
            } else {
                throw new Error(`Dashboard ${ref.id} does not exist`);
            }
        } else {
            throw new Error(`Invalid dashboard reference`);
        }
    }

    public async saveDashboard(id: string, data: Partial<Dashboard>): Promise<void> {
        data = createFirestoreDoc(data);
        await this.angularFirestore.doc<Dashboard>(dashboards(id)).update(data);
    }

    /**
     * Create given dashboard and return its ID.
     */
    public async createDashboard(data: Partial<Dashboard>): Promise<string> {
        data = createFirestoreDoc(data);
        const ref = await this.angularFirestore.collection(dashboards()).add(data);

        return ref.id;
    }

    /**
     * Create default empty dashboard and return its ID.
     */
    public async createEmptyDashboard(): Promise<string> {
        const dashboard: Dashboard = {
            createdOn: new Date(),
            meta: {
                name: "New dashboard",
            },
            schema: JSON.stringify({
                layout: null,
                mutations: {},
                sources: {},
            } as DashboardSchema),
            userId: this.userService.id,
        };

        return this.createDashboard(dashboard);
    }

    public async deleteDashboard(id: string): Promise<void> {
        await this.angularFirestore.doc(dashboards(id)).delete();
    }

    /**
     * Return copy of the dashboard with current user as a owner.
     */
    public copyDashboard(board: ParsedDashboard): ParsedDashboard {
        return {
            ...cloneDeep(board),
            userId: this.userService.id,
            global: false,
            createdOn: new Date(),
        };
    }

    public getOwnedDashboards$(): Observable<Doc<DashboardDigest>[]> {
        return this.angularFirestore.collection<DashboardDigest>(
            dashboardDigests(),
            col => col.where("userId", "==", this.userService.id),
        ).valueChanges({ idField: "id" }).pipe(
            map(docs => docs.map(d => adaptFirestoreDoc(d))),
            shareReplay(),
        );
    }

    public getGlobalDashboards$(): Observable<Doc<DashboardDigest>[]> {
        return this.angularFirestore.collection<DashboardDigest>(
            dashboardDigests(),
            col => col.where("global", "==", true),
        ).valueChanges({ idField: "id" }).pipe(
            map(docs => docs.map(d => adaptFirestoreDoc(d))),
            shareReplay(),
        );
    }

    private getSharedAvailableDashboards$(): Observable<AvailableDashboard[]> {
        const loggedIn$ = from(toStream(() => this.userService.loggedIn, true));

        return loggedIn$.pipe(
            switchMap(() => combineLatest([
                this.getOwnedDashboards$(),
                this.getGlobalDashboards$(),
            ])),
            map(([o, g]) => o.concat(g)),
            map(docs => deduplicate(docs, ["id"])),
            switchMap(docs => this.makeAvailableDashboards(docs)),
            map(loadedDashboards => loadedDashboards.filter(db => db.available || db.promoted)),
            catchError(e => {
                this.logger.error(e, "Loading available dashboards");
                this.snackBarService.notify("Could not load your dashbaords, please try it again later or contact us at support@spaceknow.com");
                return of([]);
            }),
            shareReplay(),
        );
    }

    private async makeAvailableDashboards(
        docs: Doc<DashboardDigest>[],
    ): Promise<AvailableDashboard[]> {
        return lastValueFrom(from(docs).pipe(
            mergeMap(async doc => {
                let availability: DashboardAvailability = {
                    available: false,
                    editable: false,
                };

                try {
                    availability = await this.availabilityService.getAvailability(doc);
                } catch (e: any) {
                    this.logger.error(e, `Checking dashboard ${doc.id} availability`);
                }

                return {
                    ...doc,
                    ...availability,
                };
            }),
            toArray(),
        ));
    }

    public getDashboardRevisions(dashboardId: string): Observable<DashboardRevision[]> {
        const path = `${dashboards(dashboardId)}/${dashboardRevisions()}`;
        return this.angularFirestore
            .collection<DashboardFirestoreRevision>(path)
            .valueChanges({ idField: "id" })
            .pipe(
                map(docs => docs.map(doc => adaptFirestoreDoc(doc!))),
                map(docs => docs.map(doc => (
                    {...parseDashboard(doc!), dateOfChange: doc.dateOfChange}
                ))),
                map(docs => docs.map(
                    dashboard => ({
                        id: dashboard.id,
                        dateOfChange: dashboard.dateOfChange,
                        schema: dashboard.schema,
                    })),
                ),
                catchError((err) => {
                    this.logger.error(err, "couldn't load dashboards revisions");
                    this.matSnackBar.open(
                        `Couldn't load dashboards revisions: ${err.message}`,
                        undefined,
                        {
                            panelClass: "snack-error",
                            duration: 10000,
                        },
                    );
                    return of([]);
                }),
            );
    }

    public async getDocumentsUsage(): Promise<ProductsInDocument[]> {
        const globalDashboards = await this.loadAllGlobalDashboards();
        const ret: ProductsInDocument[] = [];
        for (const dashboard of globalDashboards) {
            ret.push(...(await this.getProductsInDocuments(dashboard)));
        }
        return ret;
    }

    private async getProductsInDocuments(
        dashboard:  ParsedDashboard,
    ): Promise<ProductsInDocument[]> {
        const layout = dashboard.schema.layout;
        const productsInDocuments: ProductsInDocument[] = [];
        if (layout) {
            const options = this.moduleRegistryService.getComponentOptions(layout);
            if (options.getChildComponents) {
                const childComponents = options.getChildComponents(layout);
                childComponents.forEach((comp) => {
                    productsInDocuments.push(
                        ...this.findLineChart(comp, dashboard.schema, []),
                    );
                });
            }
            return productsInDocuments;
        }
        return [];
    }

    private isDatacubeProduct(sourceId: string, dashboardSchema: DashboardSchema): boolean {
        return !!dashboardSchema.sources
            && dashboardSchema.sources[sourceId]
            && dashboardSchema.sources[sourceId].type === "datacube/product";
    }

    private findLineChart(
        component: ComponentDef,
        schema: DashboardSchema,
        result: ProductsInDocument[],
    ): ProductsInDocument[] {
        if (!component) {
            return result;
        }
        const componentOptions = this.moduleRegistryService.getComponentOptions(component);
        if (component?.component === LineChartComponentType) {
            if (componentOptions.getComponentSources) {
                const sources = componentOptions.getComponentSources(component)
                    .map((s) => s.split("/", 1)[0])
                    .filter((s: string) => this.isDatacubeProduct(s, schema));
                const doc = (component as unknown as CardComponentDef);
                const documentation = doc.documentation as DocumentComponentDef;
                if (!documentation || !documentation.documentId) {
                    return result;
                }
                const index = result.findIndex(
                    (item) => item.documentId === documentation.documentId);
                const filteredSources: string[] = [];
                sources.forEach(source => {
                    if (!filteredSources.includes(source)) {
                        filteredSources.push(source);
                    }
                });
                if (index >= 0) {
                    result[index].productIds.push(
                        ...filteredSources
                            .filter(source => !result[index].productIds.includes(source)),
                    );
                } else {
                    result.push(
                        {
                            documentId: documentation.documentId,
                            productIds: filteredSources,
                        });
                }
            }
        } else {
            if (componentOptions.getChildComponents) {
                const children: ComponentDef[] = componentOptions.getChildComponents(component);
                children.forEach(child => {
                    this.findLineChart(child, schema, result);
                });
            }
        }
        return result;
    }
}

export interface DashboardRevision {
    id: string;
    dateOfChange: Date;
    schema: DashboardSchema;
}


export interface ProductsInDocument {
    documentId: string;
    productIds: string[];
}
