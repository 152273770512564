import { NgModule, ErrorHandler } from "@angular/core";
import { AngularFireModule, FIREBASE_OPTIONS } from "@angular/fire";
import { AngularFireFunctionsModule } from "@angular/fire/functions";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from "@angular/material/snack-bar";
import { RouterModule } from "@angular/router";
import { AngularFirestoreModule } from "@angular/fire/firestore";

import { getDefaultConfig } from "skCommon/api/config";
import { API_CONFIG } from "skCommon/api/apiConfig";
import { Logger, getLogger } from "skCommon/utils/logger";
import { LoggerErrorHandler } from "skCommon/angular/base/errorHandler";
import { APP_NAME } from "skCommon/angular/configuration";
import { SidePanelModule } from "skCommon/ui/sidePanel/sidePanel.module";
import { DateRangePickerModule } from "skCommon/dateRangePicker/dateRangePicker.module";
import { AppLogoModule } from "skCommon/angular/partials/appLogo/appLogo.module";

import { InsightsComponent } from "skInsights/insights.component";
import { UserModule } from "skInsights/user/user.module";
import { SharedModule } from "skInsights/shared.module";
import { DashboardModule } from "skInsights/dashboard/dashboard.module";
import { DatacubeModule } from "skInsights/modules/datacube/datacube.module";
import { ChartsModule } from "skInsights/modules/charts/charts.module";
import { StatisticsModule } from "skInsights/modules/statistics/statistics.module";
import { MapsModule } from "skInsights/modules/maps/maps.module";
import { CoreModule } from "skInsights/modules/core/core.module";
import { ExternalModule } from "skInsights/modules/external/external.module";
import { TablesModule } from "skInsights/modules/tables/tables.module";
import { AdminModule } from "skInsights/admin/admin.module";
import { DocumentsModule } from "skInsights/modules/documents/documents.module";
import { DocsModule } from "skInsights/docs/docs.module";
import { MenuEntriesModule } from "skInsights/menuEntries.module";

const FIREBASE_CONFIG = getDefaultConfig().getByHost(
    API_CONFIG["firebase-insights"].data.config,
);

@NgModule({
    imports: [
        SharedModule,
        AngularFireModule,
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        AngularFireAuthModule,
        UserModule,
        DashboardModule,
        AdminModule,
        SidePanelModule,
        DocsModule,

        RouterModule.forRoot([
            {
                path: "",
                redirectTo: "/home",
                pathMatch: "full",
            },
        ], {
            useHash: false,
            scrollPositionRestoration: "enabled",
            anchorScrolling: "enabled",
            scrollOffset: [0, 150],
        }),

        MenuEntriesModule,
        DatacubeModule,
        CoreModule,
        ChartsModule,
        StatisticsModule,
        MapsModule,
        ExternalModule,
        TablesModule,
        DocumentsModule,
        DateRangePickerModule,
        AppLogoModule,
    ],
    declarations: [
        InsightsComponent,
    ],
    providers: [
        {
            provide: FIREBASE_OPTIONS,
            useValue: FIREBASE_CONFIG,
        },
        {
            provide: Logger,
            useValue: getLogger(),
        },
        {
            provide: ErrorHandler,
            useClass: LoggerErrorHandler,
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 10,
                verticalPosition: "top",
                horizontalPosition: "center",
            } as MatSnackBarConfig,
        },
        {
            provide: APP_NAME,
            useValue: "SpaceKnow Insights",
        },
    ],
    bootstrap: [InsightsComponent],
})
export class InsightsModule {}
