import { NgModule } from "@angular/core";

import { DatacubeClient } from "skCommon/datacube/client";

import { provideOperation, provideSource } from "skInsights/framework/tokens";
import { ProductSource } from "skInsights/modules/datacube/sources/product";
import { GetQuerySource } from "skInsights/modules/datacube/sources/getQuery";
import { DcrFilter } from "skInsights/modules/datacube/operations/dcrFilter";

@NgModule({
    providers: [
        DatacubeClient,
        provideSource(ProductSource),
        provideSource(GetQuerySource),
        provideOperation(DcrFilter),
    ],
})
export class DatacubeModule { }
