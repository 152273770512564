import { AnyDashboardData } from "skInsights/framework/data/structures";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { PipeArgument } from "skInsights/framework/pipeCall";

export abstract class Operation<TDef> {

    public abstract readonly type: string;

    /**
     * Actual implementation of the operation
     */
    public abstract execute(
        def: TDef,
        data: AnyDashboardData,
        dataRefService: DataRefService,
    ): AnyDashboardData | Promise<AnyDashboardData>;
}

/**
 * Operation that also registers a pipe so it's easier to use.
 */
export abstract class OperationWithPipe<TDef> extends Operation<TDef> {

    public abstract pipe: string;

    public abstract executePipe(
        args: PipeArgument[],
        data: AnyDashboardData,
        dataRefService: DataRefService,
    ): AnyDashboardData | Promise<AnyDashboardData>;
}

export interface OperationDef<T extends string> {
    operation: T;
}
