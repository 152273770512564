import { Subject } from "rxjs";

import { LogLevel as LogLevelEnum } from "skCommon/utils/logger";

/**
 * Map of data to be logged on given action.
 * Mapped according to the name of the class method
 * wrapped by corresponding decorator.
 */
const actionsToBeLoggedMap = new Map<Function, ActionLogConfig>();

/**
 * Local handler of the actions stack.
 * Observed via subscription in auto logger service.
 */
let actionLoggerSubject = new Subject<any>();

// tslint:disable-next-line:variable-name
export const Log = {
    Action: LogAction,
    Level: LogLevel,
    ParentClassName: LogParentClassName,
    Param: LogParam,
};

export function observeActionLogger() {
    if (!actionLoggerSubject) {
        actionLoggerSubject = new Subject<ActionLogRow>();
    }

    return actionLoggerSubject;
}

function actionLogConfig(key: Function) {
    let actionToBeLogged: ActionLogConfig;

    if (!actionsToBeLoggedMap.has(key)) {
        actionToBeLogged = {
            paramsToBeLogged: [],
            args: [],
            level: null,
        };

        actionsToBeLoggedMap.set(key, actionToBeLogged);
    } else {
        actionToBeLogged = actionsToBeLoggedMap.get(key);
    }

    return actionToBeLogged;
}

/**
 * Mandatory decorator which must precede any other log decorator.
 * Registers the action in stack and when related method is executed,
 * the observable subject is updated.
 * Should be used as a class method decorator without brackets:
 * @Log.Action
 */
function LogAction(
    _target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor,
): TypedPropertyDescriptor<any> {
    let conf = actionLogConfig(descriptor.value);
    const originalFn = descriptor.value;

    return {
        ...descriptor,
        value: function (...args: any[]) {
            const loggedMethodName = propertyKey;

            conf = {
                ...conf,
                loggedMethodName,
                args,
            };

            setObservableSubject(conf);

            return originalFn.call(this, ...args);
        },
    };
}

/**
 * Optionally overides labels (args keys) of logged method params.
 * Only param matching passed index will be logged.
 * Index should correspond to the input param position within decorated method.
 * Each overidden param must be decorated individually ie:
 * @Log.Param('first_parameter', 0)
 * @Log.Param('second_parameter', 1)
 */
function LogParam(key: string, index: number) {
    return function (
        _prototype: any,
        _key: string,
        descriptor: PropertyDescriptor,
    ) {
        const conf = actionLogConfig(descriptor.value);

        conf.paramsToBeLogged.push({ key, index });
    };
}

/**
 * Optionally prefixes the action name with passed value.
 * Passed value should correspond to the name of the parent class.
 * If not provided, the logged action is named by name of decorated method.
 */
function LogParentClassName(parentClassName: string) {
    return function (
        _prototype: any,
        _key: string,
        descriptor: PropertyDescriptor,
    ) {
        const conf = actionLogConfig(descriptor.value);

        conf.parentClassName = parentClassName;
    };
}

function LogLevel(level: LogLevelEnum) {
    return function (
        _prototype: any,
        _key: string,
        descriptor: PropertyDescriptor,
    ) {
        const conf = actionLogConfig(descriptor.value);

        conf.level = level;
    };
}

function setObservableSubject(conf: ActionLogConfig) {
    const actionName = conf.parentClassName
        ? `${conf.parentClassName}.${conf.loggedMethodName}`
        : conf.loggedMethodName;

    const row: ActionLogRow = {
        action: actionName,
    };

    if (!!conf.paramsToBeLogged?.length) {
        const payload = conf.paramsToBeLogged.map((paramToBeLogged) => {
            const paramValue = conf.args[paramToBeLogged.index];

            return { [paramToBeLogged.key]: paramValue };
        });

        row.payload = payload;
    }

    if (conf.level === null) {
        row.level = LogLevelEnum.Debug;
    } else {
        row.level = conf.level;
    }

    actionLoggerSubject.next(row);
}

interface ActionLogRow {
    action: string;
    payload?: any;
    level?: number;
}

interface ActionLogConfig {
    loggedMethodName?: string;
    parentClassName?: string;
    level?: LogLevelEnum;
    args: any[];
    paramsToBeLogged: { key: string; index: number }[];
}
