export function camelize(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) {
            return "";
        }
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
}

export function decamelize(str: string): string {
    return str
        .replace(/([a-z\d])([A-Z])/g, "$1" + " " + "$2")
        .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, "$1" + " " + "$2")
        .toLowerCase();
}

/**
 * Wrapper around String.prototype.replace which allows the callback to return
 * promise of the replacement string. Once all replacements resolve, new string
 * is returned.
 */
export async function asyncCallbackReplace(
    inputStr: string,
    exp: RegExp | string,
    callback: (substring: string, ...args: any[]) => Promise<string>,
): Promise<string> {
    const replacements: PromisedReplacement[] = [];
    let i = 0;

    let outString = inputStr.replace(exp, (ss, ...args) => {
        // Hopefully a unique string, which will serve as placeholder until
        // the actual value resolves.
        const placeholder = `###@[${i++}]`;

        replacements.push({
            placeholder,
            promise: callback(ss, ...args),
        });

        return placeholder;
    });

    await Promise.all(replacements.map(async ({ placeholder, promise }) => {
        const resultStr = await promise;

        outString = outString.replace(placeholder, resultStr);
    }));

    return outString;
}

interface PromisedReplacement {
    placeholder: string;
    promise: Promise<string>;
}
