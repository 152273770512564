import { ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { OutputData } from "@editorjs/editorjs";
import { makeObservable } from "mobx";

import { PageExportService } from "skCommon/exports/pageExport/pageExport.service";
import { observableRef, observableShallow } from "skCommon/state/mobxUtils";
import { assert } from "skCommon/utils/assert";
import { Logger } from "skCommon/utils/logger";

import { LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { CardService } from "skInsights/framework/card/card.service";
import { CardComponent, CardComponentDef } from "skInsights/framework/card/cardComponent";
import { DocumentService } from "skInsights/modules/documents/document.service";
import { DocumentContentService } from "skInsights/modules/documents/documentContent.service";
import { outputDataToHtml } from "skInsights/partials/blockEditor/outputData";
import { RichTextService } from "skInsights/partials/richText/richText.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { UserService } from "skInsights/user/user.service";

export const DocumentComponentType: DocumentComponentType = "documents/document";
export type DocumentComponentType = "documents/document";

@Component({
    selector: "sk-documents-document",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./document.pug",
    styleUrls: ["document.scss"],
    providers: [CardService],
})
export class DocumentComponent extends CardComponent<DocumentComponentDef> {

    @observableRef
    public safeHtml?: SafeHtml;

    @observableRef
    public content?: OutputData;

    @observableRef
    public loading: boolean = false;

    @observableShallow
    public history: string[] = [];

    @ViewChild("theHtmlHolder")
    private htmlHolderRef?: ElementRef<HTMLElement>;

    private title = "brief";

    public get canGoBack(): boolean {
        return this.history.length > 1;
    }

    constructor(
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        protected readonly def: DocumentComponentDef,
        private richTextService: RichTextService,
        private logger: Logger,
        private snackBarService: SnackBarService,
        private documentService: DocumentService,
        private pageExportService: PageExportService,
        private userService: UserService,
        private documentContentService: DocumentContentService,
        cardService: CardService,
    ) {
        super(cardService);

        makeObservable(this);
    }

    public async ngOnInit(): Promise<void> {
        super.ngOnInit();

        this.reaction(
            () => this.safeHtml,
            html => html && this.updateEventHandlers(),
            { delay: 200 },
        );

        this.updateContent(this.def.documentId);
    }

    public goBack(): void {
        if (this.history.length > 1) {
            this.history.pop();
            this.updateContent(this.history.pop()!);
        }
    }

    private async updateContent(documentId: string): Promise<void> {
        try {
            this.loading = true;
            await this.loadDocument(documentId);
        } catch (e) {
            this.logger.error(e, "Load document");
            this.snackBarService.notify(`Could not load document: ${e.message}`);
        } finally {
            this.loading = false;
        }
    }

    private async loadDocument(documentId: string): Promise<void> {
        this.safeHtml = "";

        const doc = await this.documentService.loadDocument(documentId);

        this.title = doc.title;

        this.content = await this.documentContentService.getDocumentContent(doc);

        const html = outputDataToHtml(this.content);

        this.safeHtml = this.richTextService.sanitize(html);

        this.history.push(documentId);
    }

    private updateEventHandlers(): void {
        assert(this.htmlHolderRef, "HTML holder missing");

        const docProtocol = "insights://document/";

        const anchors: HTMLAnchorElement[] = [];

        this.htmlHolderRef.nativeElement.querySelectorAll("a[href]")
            .forEach(el => anchors.push(el as HTMLAnchorElement));

        // TODO: use link service
        anchors.filter(a => a.href.startsWith(docProtocol))
            .forEach(a => this.handleDocumentLink(a, a.href.replace(docProtocol, "")));
    }

    private handleDocumentLink(el: HTMLElement, docId: string): void {
        el.addEventListener("click", e => {
            e.preventDefault();
            this.updateContent(docId);
        });
    }

    public async downloadPdf() {
        assert(this.content, "Content doesn't exist yet, cannot be exported");
        const email = this.userService.getProfile().email;
        const page = await this.documentContentService.exportDocumentContent(
            this.content,
            `Created for ${email}`,
        );
        this.pageExportService.downloadPageExport(page, this.title);
    }


}

/**
 * Component which loads and renders existing document.
 */
export interface DocumentComponentDef extends CardComponentDef {
    component: DocumentComponentType;
    documentId: string;
}
