import { Client } from "skCommon/api/client/simple";
import { API_CONFIG } from "skCommon/api/apiConfig";
import { getDefaultConfig } from "skCommon/api/config";
import { SPACEKNOW_OAUTH } from "skCommon/auth/authenticator";

/**
 * Standard OAuth 2.0 client.
 */
export abstract class OAuthClient extends Client {

    public readonly authType = SPACEKNOW_OAUTH;
    public readonly contentType = "application/json; charset=utf-8";

    protected useFormData = false;

    protected clientId = getDefaultConfig().getByHost(
        this.apiObject.data.client_id,
    ) as string;

    protected audience = getDefaultConfig().getByHost(
        this.apiObject.data.audience,
    ) as string;

    constructor(public readonly api: keyof typeof API_CONFIG) {
        super();
    }

    public async userInfo(): Promise<OAuthUserInfo> {
        const result = this.call<OAuthUserInfo>({
            endpoint: "userinfo",
        });

        return result.promise;
    }

    public getAuthorizeUrl(payload: AuthorizePayload): string {
        const urlUtil = getDefaultConfig().url;

        payload.client_id = payload.client_id || this.clientId;
        payload.audience = payload.audience || this.audience || undefined;

        return urlUtil.get(
            this.apiObject,
            "authorize",
            payload,
        );
    }

    public getToken(pl: GetTokenPayload): Promise<GetTokenResponse> {
        if (!("client_id" in pl)) {
            pl.client_id = this.clientId;
        }

        if (!("audience" in pl)) {
            pl.audience = this.audience;
        }

        const headers = new Headers();

        if (this.useFormData) {
            headers.append("Content-Type", "application/x-www-form-urlencoded");
        }

        return this.call<GetTokenResponse>({
            endpoint: "get-token",
            body: pl,
            skipAuthentication: true,
            headers,
        }).promise;
    }

    public getLoginExpiration() {
        return getDefaultConfig().getByHost(
            this.apiObject.data.login_expiration,
        );
    }
}

export interface GetTokenPayload {
    grant_type: string;
    client_id?: string;
    client_secret?: string;
    audience?: string;
    username?: string;
    password?: string;
    refresh_token?: string;
    scope?: string;
    realm?: string;
    otp?: string;
    code?: string;
    code_verifier?: string;
    redirect_uri?: string;
}

export interface AuthorizePayload {
    client_id?: string;
    audience?: string;
    response_type: string;
    connection: string;
    redirect_uri: string;
    scope: string;
    code_challenge_method?: string;
    code_challenge?: string;
    code_verifier?: string;
    state?: string;
}

export interface GetTokenResponse {
    access_token: string;
    id_token: string;
    token_type: string;
    expires_in: number;
    refresh_token?: string;
}

export enum AuthProvider {
    db = "Username-Password-Authentication",
    facebook = "facebook",
    google = "google-oauth2",
    linkedin = "linkedin",
}

/**
 * Adapted OAuth user info with the oidc namespaces already removed
 */
export interface OAuthUserInfo {
    email: string;
    email_verified: boolean;
    family_name?: string;
    gender?: string;
    given_name?: string;
    ip: string;
    location: {
        lat: number;
        lon: number;
    };
    locale?: string;
    name?: string;
    nickname?: string;
    picture?: string;
    sub: string;
    updated_at: string;
}
