export {};

if (!("at" in Array.prototype)) {
    /**
     * TC39 Stage-3 proposal
     * https://github.com/tc39/proposal-relative-indexing-method
     */
    function at(this: Array<any>, n: number) {
        n = Math.trunc(n) || 0;

        if (n < 0) {
            n += this.length;
        }
        if (n < 0 || n >= this.length) {
            return undefined;
        }

        return this[n];
    }

    Object.defineProperty(Array.prototype, "at", {
        value: at,
        writable: true,
        enumerable: false,
        configurable: true,
    });
}

declare global {
    interface Array<T> {
        // For some reason this throws error during build, even though it
        // 1. works fine and 2. editor doesn't complain
        // My guess is that this only works in strict mode and while for
        // editing we use strict here, we don't use it during actual build
        // so we can remove this once me strictify whole codebase hopefully
        // @ts-ignore
        at(index: 0): this extends [infer F, ...Array<infer L>] ? F
            : this extends [...Array<infer F>, infer L] ? F | L
            : (T | undefined);
        // @ts-ignore
        at(index: -1): this extends [...Array<infer F>, infer L] ? L
            : this extends [infer F, ...Array<infer L>] ? F | L
            : (T | undefined);
        at(index: number): T | undefined;
    }
}
