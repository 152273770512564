import { ImgFetcher } from "skCommon/utils/imgFetcher";

export function hasParentWithAttr(element: HTMLElement, attr: string,
        endAtElement?: HTMLElement): boolean {
    while (element && element !== endAtElement) {
        if (element.hasAttribute(attr)) {
            return true;
        }

        element = element.parentElement;
    }

    return false;
}

export async function canvasToBlob(canvas: HTMLCanvasElement) {
    const promise = new Promise<Blob>(resolve => {
        canvas.toBlob(resolve);
    });

    return promise;
}

export async function blobToImg(blob: Blob): Promise<HTMLImageElement> {
    return urlToImg(URL.createObjectURL(blob));
}

export async function urlToImg(url: string): Promise<HTMLImageElement> {
    return await new ImgFetcher({
        url,
        cors: true,
    }).fetch();
}

/**
 * Programatically open system "open file" dialog without including a file
 * input element in the DOM.
 *
 * @note Keep in mind that if dialog is closed without selecting any file the
 *  returned promise never resolves!
 */
export function showFileSelectDialog(opts: SelectDialogOptions = {}): Promise<FileList> {
    const fileElement = <HTMLInputElement>document.createElement("input");

    fileElement.type = "file";
    fileElement.multiple = !!opts.multiple;
    if (opts.accept) {
        fileElement.accept = opts.accept;
    }

    return new Promise((resolve, reject) => {
        try {
            fileElement.addEventListener("change", () => {
                resolve(fileElement.files);
            });
            fileElement.click();
        } catch (e) {
            reject(e);
        }
    });
}

export function readFileAsText(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener("load", () => resolve(reader.result as string));
        reader.addEventListener("error", e => reject(e));

        reader.readAsText(file);
    });
}

/**
 * Create new canvas with all fully-transparent rows and columns removed trimmed.
 */
export function trimCanvas(canvas: HTMLCanvasElement): HTMLCanvasElement {
    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    let top = 0;
    let bottom = 0;
    let left = 0;
    let right = 0;

    // For each direction, go through every every column or row until an
    // non-transparent pixel is found.
    for (let i = 0; i < imageData.data.length; i += 4) {
        const relevantPixelAlphaIndex = i + 3;
        if (imageData.data[relevantPixelAlphaIndex] > 0) {
            top = Math.floor(i / 4 / canvas.width);
            break;
        }
    }

    for (let i = 0; i < imageData.data.length; i += 4) {
        const relevantPixelAlphaIndex = imageData.data.length - 1 - i;
        if (imageData.data[relevantPixelAlphaIndex] > 0) {
            bottom = Math.floor(i / 4 / canvas.width);
            break;
        }
    }

    for (let i = 0; i < imageData.data.length; i++) {
        const column = Math.floor(i / canvas.height);
        const yInColumn = i % canvas.height;
        const columnsOffset = yInColumn * canvas.width;
        const relevantPixelAlphaIndex = (columnsOffset + column) * 4 + 3;

        if (imageData.data[relevantPixelAlphaIndex] > 0) {
            left = Math.floor(i / canvas.height);
            break;
        }
    }

    for (let i = 0; i < imageData.data.length; i++) {
        const column = Math.floor(i / canvas.height);
        const yInColumn = i % canvas.height;
        const columnsOffset = yInColumn * canvas.width;
        const relevantPixelAlphaIndex = imageData.data.length
            - (columnsOffset + column) * 4 - 1;

        if (imageData.data[relevantPixelAlphaIndex] > 0) {
            right = Math.floor(i / canvas.height);
            break;
        }
    }

    const trimmedCanvas = document.createElement("canvas");
    const newWidth = canvas.width - left - right;
    const newHeight = canvas.height - top - bottom;

    trimmedCanvas.width = newWidth;
    trimmedCanvas.height = newHeight;

    const trimmedCtx = trimmedCanvas.getContext("2d");
    trimmedCtx.drawImage(
        canvas,
        left, top, newWidth, newHeight,
        0, 0, newWidth, newHeight,
    );

    return trimmedCanvas;
}

export function copyToClipboard(str: string): void {
    const input = document.createElement("input");
    input.value = str;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
}

interface SelectDialogOptions {
    /**
     * File input accept string
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
     */
    accept?: string;
    /**
     * Allow selection of multiple files
     */
    multiple?: boolean;
}
