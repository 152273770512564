// Source: https://github.com/lukes/ISO-3166-Countries-with-Regional-Codes
import sourceCountries from "skCommon/flags/countries.json";
// List of flags available in the img directory -- needs to be manually updated
// when new flag is added
import availableFlags from "skCommon/flags/availableFlags.json";

const NO_FLAG = `/flags/no-flag.svg`;

let countries: Country[];
let map: Map<string, Country>;

export function getCountries(): Country[] {
    prepareCountries();

    return countries;
}

export function getCountry(code: string): Country {
    prepareCountries();

    return map.get(code);
}

export function getFlag(code: string): string {
    const contry = getCountry(code);

    return contry ? contry.iconPath : NO_FLAG;
}

function prepareCountries() {
    if (!countries) {
        const availableFlagsSet = new Set(availableFlags);
        countries = sourceCountries.map(({ name, "alpha-2": isoCode }) => {
            const code = isoCode.toLowerCase();

            return {
                code,
                name,
                iconPath: availableFlagsSet.has(code)
                    ? `/flags/${code}.svg`
                    : void 0,
            };
        });

        const entries = countries.map(country => [country.code, country] as const);
        map = new Map(entries);
    }
}

interface Country {
    name: string;
    code: string;
    iconPath?: string;
}
