import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class DatacubeAoiService {

    public aois?: Readonly<Record<string, DatacubeAoi>>;

    public async loadAois(): Promise<void> {
        const aoisModule = await import("skInsights/aois.json");
        this.aois = aoisModule.default as Record<string, DatacubeAoi>;
    }
}

export interface DatacubeAoi {
    name: string;
    point: GeoJSON.Point;
}
