const PREFIX = "__RAW_VALUE:";

export class Float {
    constructor(private value: number, private precision = 1) { }

    public toJSON(): string {
        return PREFIX + this.toString();
    }

    public toString(): string {
        return this.value.toFixed(this.precision);
    }

    public valueOf(): number {
        return this.value;
    }
}

export function float(val: number, precision?: number): number {
    return new Float(val, precision) as unknown as number;
}

/**
 * JSON.stringify with support for "raw" values, which are then used instead of
 * stringified value.
 */
export function jsonStringifyWithFloats(obj: any): string {
    const json = JSON.stringify(obj);

    return json.replace(
        new RegExp(`"${PREFIX}([^"]+)"`, "g"),
        "$1",
    );
}
