import { ChangeDetectionStrategy, Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BarOptions, ChartConfiguration, ChartDataset, TooltipItem } from "chart.js";
import moment from "moment";
import Color from "color";
import download from "downloadjs";
import { makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { ChartContext } from "skCommon/chart/chartContext";
import { registerLineCharts } from "skCommon/chart/register/lineCharts";
import { registerBarCharts } from "skCommon/chart/register/barCharts";
import { computed, observableRef, observableShallow } from "skCommon/state/mobxUtils";
import { Logger } from "skCommon/utils/logger";
import { registerNearestEachTooltipMode } from "skCommon/chart/plugins/nearestEachTooltipMode";
import { assert } from "skCommon/utils/assert";
import { ChartExportService } from "skCommon/chart/chartExport.service";
import { generateUniqueId } from "skCommon/utils/uniqueId";
import { sortByKey, SortDirection } from "skCommon/utils/sort";
import { Insights } from "skCommon/permissions";
import { ExplorerOptions } from "skCommon/insights/dashboard";

import { DashboardRoutes } from "skInsights/dashboard/dashboard.routing";
import { DashboardViewComponentData } from "skInsights/dashboard/dashboardView/dashboardView.component";
import { DashboardViewService } from "skInsights/dashboard/dashboardView/dashboardView.service";
import { BuilderService } from "skInsights/framework/builder.service";
import { DashboardData, RegisteredDataProvider } from "skInsights/framework/dashboardData";
import { ExplorableDataProvider, ExplorableGroup, ExplorableItem, isExplorableDataset } from "skInsights/framework/data/explorableDataset";
import { InsightsService } from "skInsights/insights.service";
import { getAvailableColors } from "skInsights/modules/charts/helpers/chartColors";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { DashboardButton } from "skInsights/partials/buttons/button";
import { DashboardEvents } from "skInsights/framework/dashboardEvents";
import { LinkService } from "skInsights/framework/link.service";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { DashboardExportService } from "skInsights/helpers/dashboardExport.service";
import { AvailabilityService } from "skInsights/framework/availability.service";
import { UserService } from "skInsights/user/user.service";

const LOADING = Symbol();

@Component({
    selector: "sk-data-explorer",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./dataExplorer.pug",
    styleUrls: ["./dataExplorer.scss"],
    // Provide services needed by the sk-dashboard-button although we don't
    // really use them.
    providers: [
        {
            provide: DashboardEvents,
            useClass: DashboardEvents,
        },
        {
            provide: DashboardData,
            useValue: new DashboardData({}),
        },
        LinkService,
        ...DataRefService.provide(),
    ],
})
export class DataExplorerComponent extends BaseComponent {

    public readonly chartContext: ChartContext;

    public readonly Assignation = DatasetAssignation;

    @observableRef
    public selectedGroup?: ExplorableGroupOption;

    @observableRef
    public searchQuery: string = "";

    @observableRef
    private options: ExplorerOptions = {};

    @observableRef
    private dataProviders?: RegisteredDataProvider[];

    @observableRef
    private dashboardData?: DashboardData;

    @observableShallow
    private assignedItems: Map<string, ExplorableItemOption> = new Map();

    private colorIterator = getAvailableColors();

    @computed
    public get groups(): ExplorableGroupOption[] {
        const terms = this.searchQuery.toLowerCase().split(/\s+/);

        return this.allGroups.filter(
            grp => terms.every(t => grp.searchKey.includes(t)),
        );
    }

    @computed
    public get documentationButton(): DashboardButton | null {
        return this.options.documentation ? {
            icon: "news",
            text: "Documentation",
            link: this.options.documentation,
        } : null;
    }

    public get dashboardName(): string | undefined {
        return this.dashboardViewService.dashboard?.meta.name;
    }

    public get backToDashboardUrl(): string | null {
        const db = this.dashboardViewService.dashboard;

        return db
            ? `/${DashboardRoutes.Dashboard}/${db?.id}`
            : null;
    }

    public get emptyChart(): boolean {
        return this.assignedItems.size === 0
            && !!this.dashboardData;
    }

    public get hasDownloadableData(): boolean {
        return this.assignedItems.size > 0;
    }

    public get canExportData(): boolean {
        return this.hasDownloadableData
            && !!this.dashboardViewService.dashboard
            && this.availabilityService.isExportable(this.dashboardViewService.dashboard);
    }

    public get legendItems(): ExplorableItemOption[] {
        return [...this.assignedItems.values()];
    }

    @computed
    private get allGroups(): ExplorableGroupOption[] {
        return this.allGroupInputs.map(grp => this.makeGroupOption(grp));
    }

    @computed
    private get allGroupInputs(): ExplorableGroup[] {
        if (!this.dataProviders) {
            return [];
        }

        return this.dataProviders
            .filter(({ provider }) => isExplorableDataset(provider))
            .flatMap(({ provider, sourceId }) =>
                (provider as ExplorableDataProvider).getExplorableGroups(sourceId))
            .sort(sortByKey("name", SortDirection.Asc));
    }

    constructor(
        private activatedRoute: ActivatedRoute,
        private dashboardViewService: DashboardViewService,
        private snackBarService: SnackBarService,
        private logger: Logger,
        private builderService: BuilderService,
        private insightsService: InsightsService,
        private dashboardExportService: DashboardExportService,
        private chartExportService: ChartExportService,
        private availabilityService: AvailabilityService,
        private userService: UserService,
    ) {
        super();

        makeObservable(this);

        this.addSubscription(this.activatedRoute.data.subscribe(data => {
            this.prepareSources(data as DashboardViewComponentData);
        }));

        registerLineCharts();
        registerBarCharts();
        registerNearestEachTooltipMode();

        this.chartContext = new ChartContext(this.makeChartOptions());

        this.reaction(
            () => [...this.assignedItems.values()],
            items => this.updateChartDatasets(items),
        );
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.dashboardViewService.dashboard = undefined;
    }

    public assignSeries(item: GroupedExplorableItem, chartType: DatasetAssignation): void {
        // Only single bar chart allowed
        if (chartType === DatasetAssignation.Bar) {
            [...this.assignedItems.values()]
                .filter(assItem => assItem.assigned === chartType)
                .forEach(assItem => this.assignedItems.delete(assItem.id));
        }

        this.assignedItems.set(
            item.data.meta.uid,
            {
                ...item,
                assigned: chartType,
                icon: this.getAssignedIcon(chartType),
                color: chartType === DatasetAssignation.Bar
                    ? Color(this.colorIterator.next().value)
                        .alpha(0.2).rgb().string()
                    : this.colorIterator.next().value,
                visible: true,
            },
        );
    }

    public removeSeries(item: ExplorableItem): void {
        this.assignedItems.delete(item.data.meta.uid);
    }

    public toggleItemVisibility(item: ExplorableItemOption): void {
        this.assignedItems.set(
            item.id,
            {
                ...item,
                visible: !item.visible,
            },
        );
    }

    private getAssignedIcon(assignation: DatasetAssignation): string {
        switch (assignation) {
            case DatasetAssignation.Bar:
                return "combo-chart";
            case DatasetAssignation.LinePrimary:
                return "arrow-left";
            case DatasetAssignation.LineSecondary:
                return "arrow-right";
        }
    }

    public async prepareSources({ dashboardRef }: DashboardViewComponentData): Promise<void> {
        try {
            this.insightsService.globalLoadingProcesses.add(LOADING);
            const { schema } = await this.dashboardViewService.updateDashboard(dashboardRef);

            this.dashboardData = await this.builderService.getDashboardData(schema);
            this.dataProviders = await this.dashboardData.preloadAllSources({
                includeHidden: this.userService.permissions.has(Insights.Data.Hidden),
            });
            this.options = schema.explorer || {};
        } catch (e) {
            this.snackBarService.notify(`Data could not be loaded: ${e.message}`);
            this.logger.error(e, "Preparing dashboard");
        } finally {
            this.insightsService.globalLoadingProcesses.delete(LOADING);
        }
    }

    public selectGroup(group: ExplorableGroupOption): void {
        this.selectedGroup = group;
    }

    public deselectGroup(group: ExplorableGroupOption): void {
        if (group.id === this.selectedGroup?.id) {
            this.selectedGroup = void 0;
        }
    }

    public downloadCsv(): void {
        const exportables = [...this.assignedItems.values()].map(item => ({
            series: item.data,
            name: `${item.groupName} ${item.name}`,
        }));

        this.dashboardExportService.downloadSeriesAsCsv(
            exportables,
            "data-explorer",
        );
    }

    public downloadPdf(): void {
        this.chartExportService.exportAsPdf(
            [this.chartContext],
            `explorer-data-${generateUniqueId()}`,
        );
    }

    public async downloadPng(): Promise<void> {
        const png = await this.chartContext.exportAsPng(1000, 700);
        download(png, `dashboard-data-${generateUniqueId()}.png`);
    }

    public clearTimeseries(): void {
        this.assignedItems.clear();
    }

    private makeChartOptions(): ChartConfiguration<"line"> {
        return {
            type: "line",
            data: {
                datasets: [],
                labels: null!,
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        type: "time",
                        ticks: {
                            maxRotation: 0,
                            autoSkipPadding: 10,
                        },
                        time: {
                            minUnit: "month",
                            displayFormats: {
                                month: "MMM YYYY",
                            },
                        },
                    },
                    [DatasetAssignation.LinePrimary]: {
                        type: "linear",
                        position: "left",
                        gridLines: {
                            display: true,
                            drawTicks: true,
                        },
                    },
                    [DatasetAssignation.LineSecondary]: {
                        type: "linear",
                        position: "right",
                    },
                    [DatasetAssignation.Bar]: {
                        display: false,
                    },
                },
                hover: {
                    mode: "nearestEach",
                },
                layout: {
                    padding: {
                        bottom: 4,
                        top: 8,
                        left: 4,
                        right: 8,
                    },
                },
                plugins: {
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: "x",
                        },
                        zoom: {
                            enabled: true,
                            mode: () => {
                                // tslint:disable-next-line: deprecation
                                const e = event as WheelEvent;

                                if (e && (e.shiftKey || e.altKey || e.metaKey)) {
                                    return "y";
                                } else {
                                    return "x";
                                }
                            },
                        },
                    },
                    tooltip: {
                        mode: "nearestEach",
                        callbacks: {
                            title: (tooltipItems: TooltipItem[]) => {
                                return moment(tooltipItems[0].dataPoint.x)
                                    .utc()
                                    .format("YYYY-MM-DD");
                            },
                        },
                    },
                    legend: {
                        display: false,
                    },
                },
            },
        };
    }

    private updateChartDatasets(items: ExplorableItemOption[]): void {
        const chart = this.chartContext.chart;

        items = items.filter(t => t.visible);

        const newDatasets = new Map(items.map(item => [item.id, item]));
        const datasets = chart.data.datasets as SupportedDatasets[];

        const existingDatasets = datasets.filter(dataset => {
            if (typeof dataset.uid === "string" && newDatasets.has(dataset.uid)) {
                newDatasets.delete(dataset.uid);
                return true;
            } else {
                return false;
            }
        });

        chart.data.datasets = [
            ...existingDatasets,
            ...[...newDatasets.values()]
                .map(item => this.createChartDataset(item)),
        ];

        this.resetZoomIfNecessary();
        this.chartContext.update();
    }

    private resetZoomIfNecessary(): void {
        const chart = this.chartContext.chart;

        if (!chart.data.datasets.length) {
            assert(chart.options.scales?.x, "X scale is not defined");
            chart.options.scales.x.max = void 0;
            chart.options.scales.x.min = void 0;
        }
    }

    private createChartDataset(item: ExplorableItemOption): ChartDataset<"line"> | ChartDataset<"bar"> {
        return {
            refs: [item.data.meta.uid],
            uid: item.data.meta.uid,
            data: item.data.series as any,
            xAxisID: "x",
            yAxisID: item.assigned,
            parsing: {
                xAxisKey: "x",
                yAxisKey: "y",
            },
            label: item.data.meta.name,
            borderWidth: 1,
            borderColor: item.color,
            ...this.getChartTypeSpecificOptions(item),
        };
    }

    private getChartTypeSpecificOptions(
        { assigned, color }: ExplorableItemOption,
    ): Partial<ChartDataset<"line" | "bar">> {
        switch (assigned) {
            case DatasetAssignation.LinePrimary:
            case DatasetAssignation.LineSecondary:
                return {
                    fill: false,
                    pointRadius: 0,
                    pointBackgroundColor: color,
                    cubicInterpolationMode: "monotone",
                    tension: 0.3,
                };
            case DatasetAssignation.Bar:
                return {
                    type: "bar",
                    borderWidth: 0,
                    backgroundColor: color,
                } as Partial<BarOptions>;
        }
    }

    private makeGroupOption(grp: ExplorableGroup): ExplorableGroupOption {
        return {
            ...grp,
            searchKey: grp.name.toLowerCase(),
            items: grp.items.map(
                item => this.assignedItems.has(item.id)
                    ? this.assignedItems.get(item.id)!
                    : {
                        ...item,
                        name: item.name || item.data.meta.name,
                        groupName: grp.name,
                    },
            ),
            indicators: grp.items
                .filter(item => this.assignedItems.has(item.data.meta.uid))
                .map(item => ({
                    color: this.assignedItems.get(item.data.meta.uid)!.color,
                })),
        };
    }
}

declare module "chart.js" {
    interface LineOptions {
        uid: string;
    }

    interface BarOptions {
        uid: string;
    }
}

enum DatasetAssignation {
    LinePrimary = "line-primary",
    LineSecondary = "line-secondary",
    Bar = "bar",
}

interface GroupedExplorableItem extends ExplorableItem {
    groupName: string;
    name: string;
}

interface ExplorableItemOption extends GroupedExplorableItem {
    assigned: DatasetAssignation;
    icon: string;
    color: string;
    visible: boolean;
    groupName: string;
}

interface ExplorableGroupOption extends ExplorableGroup {
    searchKey: string;
    items: (ExplorableItemOption | GroupedExplorableItem)[];
    indicators: ExplorableGroupIndicator[];
}

interface ExplorableGroupIndicator {
    color: string;
}

/**
 * Can be removed when ChartContext generics are supported.
 */
type SupportedDatasets = ChartDataset<"line"> | ChartDataset<"bar">;
