import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    templateUrl: "./addCustomerDialog.pug",
    styleUrls: ["./addCustomerDialog.scss", "./../admin.scss"],
})
export class AddCustomerDialogComponent {

    public customerForm = new FormGroup({
        name: new FormControl("", [Validators.required]),
        email: new FormControl("", [Validators.required]),
        company: new FormControl("", [Validators.required]),
        userId: new FormControl("", [Validators.required]),
    });

    constructor(
        public dialogRef: MatDialogRef<AddCustomerDialogComponent>,
    ) {}

    public save(): void {
        return this.dialogRef.close(this.customerForm.value);
    }

}

