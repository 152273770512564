export enum PipelineStatus {
    New = "NEW",
    Processing = "PROCESSING",
    Resolved = "RESOLVED",
    Failed = "FAILED",
}

export interface TaskStatusResponse {
    status: PipelineStatus;
    nextTry: number;
}

export interface TaskResponse {
    pipelineId: string;
    status: PipelineStatus;
    nextTry: number;
}
