import { Injector, TemplateRef, Type } from "@angular/core";
import { Observable, of, ReplaySubject, Subject } from "rxjs";
import { filter, mapTo, mergeMap, switchMap } from "rxjs/operators";

/**
 * Reference to currently visible side panel provided to both the caller and
 * the side panel component used to programatically control the dialog.
 */
export class SidePanelRef<T = any, D extends {} = {}> {

    public readonly injector: Injector;

    private closeSubject$: Subject<boolean> = new Subject();

    /**
     * Replay subject holding an optional callback which is called on close and
     * returns true or false whether the panel should be actually closed
     */
    private onCloseSubject$: Subject<OnCloseCallback> = new ReplaySubject(1);

    public get close$(): Observable<void> {
        return this.closeSubject$.pipe(
            switchMap(bypass =>
                bypass
                    ? of(() => true)
                    : this.onCloseSubject$,
            ),
            mergeMap(async fn => fn(), 1),
            filter(shouldClose => shouldClose),
            mapTo(void 0),
        );
    }

    public get width(): string {
        return this.options.width || "360px";
    }

    public get templateBased(): boolean {
        return this.content instanceof TemplateRef;
    }

    public get data(): Partial<D> {
        return this.options.data || {};
    }

    constructor(
        public readonly content: Type<T> | TemplateRef<T>,
        private readonly options: Readonly<SidePanelOptions<D>>,
    ) {
        this.injector = Injector.create({
            parent: options.injector,
            providers: [
                {
                    provide: SidePanelRef,
                    useValue: this,
                },
            ],
        });

        this.onCloseSubject$.next(() => true);
    }

    public close(bypassCallback: boolean = false): void {
        this.closeSubject$.next(bypassCallback);
    }

    public onClose(callback: OnCloseCallback) {
        this.onCloseSubject$.next(callback);
    }
}

export interface SidePanelOptions<D extends {} = {}> {
    /**
     * Parent injector - inspired by material dialog implementation
     */
    injector?: Injector;
    width?: string;
    data?: D;
}

export type OnCloseCallback = (() => boolean | Promise<boolean>);
