import { RESPONSE_FORMAT, Method } from "skCommon/core/http";
import { getDefaultConfig } from "skCommon/api/config";
import { OAuthClient } from "skCommon/auth/oauthClient";
import { AuthClient, SignupResult, UserMetadata, UserProfile, AppMetadata } from "skCommon/auth/client";
import { CONFIG } from "skCommon/auth/oauthConfig";
import { UserAuthData } from "skCommon/auth/authenticator";

export class Auth0Client extends OAuthClient implements AuthClient {

    constructor() {
        super("auth0");
    }

    public async getLogoutUrl(_data: UserAuthData, returnUrl?: string): Promise<string> {

        const urlUtil = getDefaultConfig().url;

        returnUrl = returnUrl || urlUtil.base();

        return urlUtil.get(
            this.apiObject,
            "logout",
            {
                returnTo: returnUrl,
                client_id: this.clientId,
            },
        );
    }

    public async signup(options: Auth0SignupOptions): Promise<SignupResult> {

        const response = await this.call<Auth0SignupResponse>({
            endpoint: "signup",
            dontLogProperties: CONFIG.DONT_LOG_PROPS,
            skipAuthentication: true,
            body: {
                client_id: this.clientId,
                connection: CONFIG.CONN.DB,
                email: options.email,
                password: options.password,
                user_metadata: options.user_metadata,
            },
        }).promise;

        return {
            email: response.email,
            id: response._id,
        };
    }

    public async resetPassword(email: string): Promise<void> {

        await this.call({
            endpoint: "change-or-reset-password",
            dontLogProperties: CONFIG.DONT_LOG_PROPS,
            body: {
                client_id: this.clientId,
                connection: CONFIG.CONN.DB,
                email,
            },
            skipAuthentication: true,
            responseFormat: RESPONSE_FORMAT.PLAIN,
        }).promise;
    }

    public async updateUserMetadata(
        userId: string,
        data: UserMetadata,
    ): Promise<UserMetadata> {
        const profile = await this.call<Auth0UserProfile>({
            endpoint: "user",
            method: Method.Patch,
            dontLogProperties: CONFIG.DONT_LOG_PROPS,
            params: {
                id: userId,
            },
            body: {
                user_metadata: data,
            },
            skipAuthentication: false,
        }).promise;

        return profile.user_metadata;
    }

    public async getUser(userId: string): Promise<UserProfile> {
        const auth0Profile = await this.call<Auth0UserProfile>({
            endpoint: "user",
            method: Method.Get,
            dontLogProperties: CONFIG.DONT_LOG_PROPS,
            params: {
                id: userId,
            },
            skipAuthentication: false,
        }).promise;

        return {
            email: auth0Profile.email,
            verified: auth0Profile.email_verified,
            appMetadata: auth0Profile.app_metadata || {},
            userMetadata: auth0Profile.user_metadata || {},
            createdAt: new Date(auth0Profile.created_at),
            picture: auth0Profile.picture,
            userId: auth0Profile.user_id,
            hasPassword: auth0Profile.identities.some(
                identity => !identity.isSocial,
            ),
        };
    }
}

///
///
/// Interfaces
///
///

export interface Auth0SignupOptions {
    email: string;
    password: string;
    user_metadata?: { [key: string]: string };
}

/**
 * Raw auth0 profile as received from the management API
 */
export interface Auth0UserProfile {
    email: string;
    email_verified: boolean;
    name?: string;
    given_name?: string;
    family_name?: string;
    picture?: string;
    gender?: string;
    locale?: string;
    updated_at?: string;
    user_id: string;
    nickname?: string;
    identities: {
        provider: string;
        user_id: string;
        connection: string;
        isSocial: boolean;
    }[];
    created_at?: string;
    user_metadata: UserMetadata;
    app_metadata: AppMetadata;
    last_ip?: string;
    last_login?: string;
    logins_count?: number;
}

export enum DetectionEntityView {
    Vector = "vector",
    Raster = "raster",
    Centroid = "centroid",
}

interface Auth0SignupResponse {
    _id: string;
    email: string;
    email_verified: string;
}
