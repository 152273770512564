import { Injectable } from "@angular/core";
import { observable, makeObservable } from "mobx";

@Injectable()
export class LoadingStore {
    @observable
    public loadingCount = 0;

    constructor() {
        makeObservable(this);
    }
}
