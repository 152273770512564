import { Style } from "mapbox-gl";
import { Injectable } from "@angular/core";

import { getMapboxClient } from "skCommon/mapbox/client";
import { BingImagerySet, getBingClient } from "skCommon/bing/client";
import { getGoogleMapsClient, GoogleMapType } from "skCommon/google/client";
import { callFetch } from "skCommon/core/fetch";
import { StyleType } from "skCommon/map/mapbox/mapConfig";
import { assert } from "skCommon/utils/assert";

/**
 * Service for loading mapbox styles (base maps) and their thumbnails
 */
@Injectable({ providedIn: "root" })
export class MapStyleService {
    /**
     * List of style which are not simply saved on mapbox and need to be
     * generated somehow.
     */
    private STYLE_GENERATORS = new Map<StyleType, () => Promise<Style>>([
        [StyleType.BingAerial, () => this.getBingStyle()],
        [StyleType.GoogleSatellite, () => this.getGoogleStyle(GoogleMapType.Hybrid)],
        [StyleType.GoogleSatelliteOnly, () => this.getGoogleStyle(GoogleMapType.Satellite)],
    ]);

    private STATIC_IMAGE_URL_GENERATORS =
        new Map<StyleType, StaticImageUrlGenerator>([
            [StyleType.BingAerial, q => this.getBingStaticImageUrl(q)],
            [
                StyleType.GoogleSatellite,
                q => this.getGoogleStaticImageUrl(GoogleMapType.Satellite, q),
            ],
            [
                StyleType.GoogleSatelliteOnly,
                q => this.getGoogleStaticImageUrl(GoogleMapType.Satellite, q),
            ],
            [StyleType.LocalOsm, () => "/img/osm-preview.png"],
            [StyleType.LocalSatellite, () => "/img/satellite-preview.png"],
        ]);

    private mapStyleCache = new Map<string, Style>();

    /**
     * Get style for given map type. If it's not cached yet, load it from mapbox
     * API, decorate it with our specific properties and store into cache.
     */
    public async getMapboxStyle(type: StyleType): Promise<Style> {

        if (!this.mapStyleCache.has(type)) {
            this.mapStyleCache.set(type, await this.loadStyle(type));
        }

        return this.mapStyleCache.get(type)!;
    }

    /**
     * Get static image url from client depending on the styleId
     */
    public getStaticImageUrlForStyle(query: StaticImageQuery): string {
        const style = query.styleId;

        if (this.STATIC_IMAGE_URL_GENERATORS.has(style)) {
            return this.STATIC_IMAGE_URL_GENERATORS.get(style)!(query);
        } else {
            return getMapboxClient().getStaticImageUrl(query);
        }
    }

    private async loadStyle(type: StyleType) {
        if (this.STYLE_GENERATORS.has(type)) {
            return this.STYLE_GENERATORS.get(type)!();
        } else if (this.isStyleUrl(type)) {
            const res = await callFetch(type);
            return await res.json();
        } else {
            const client = getMapboxClient();

            const style = await client.loadStyle(type) as Style;
            this.updateMapboxStyle(style);

            return style;
        }
    }

    private isStyleUrl(type: StyleType): boolean {
        return type.startsWith("https://") || type.startsWith("/");
    }

    /**
     * Decorate given Mapbox style with attribution and make sure glyph url
     * leads to our account (default mapbox url is used unless given map style
     * doesn't make use some of our fonts, we want to use that font in our
     * custom layers though).
     */
    private updateMapboxStyle(style: Style) {
        if (style.glyphs) {
            style.glyphs = style.glyphs.replace("/mapbox", "/spaceknow-analytics");
        }
    }

    private async getBingStyle(): Promise<Style> {
        const bing = getBingClient();
        const tilesInfo = await bing.getTilesInfo(BingImagerySet.Aerial);

        return await this.getCustomStyle(
            StyleType.BingAerial,
            StyleType.Satellite,
            [1, 23],
            tilesInfo.urls,
            tilesInfo.copyright,
        );
    }

    private async getGoogleStyle(googleMapType: GoogleMapType): Promise<Style> {
        const googleClient = getGoogleMapsClient();
        const tileUrl = await googleClient.getTileUrl(googleMapType);

        return await this.getCustomStyle(
            StyleType.GoogleSatellite,
            StyleType.SatelliteOnly,
            [0, 19],
            [tileUrl],
        );
    }

    /**
     * Mapbox hosted styles do not support external map tiles sources, so we
     * need to define it locally, but so we don't have to host whole style
     * (which is huge json), let's fetch the mapbox one and just overwrite the
     * map tiles source.
     */
    private async getCustomStyle(
        styleType: StyleType,
        baseStyle: StyleType,
        zoomRange: [number, number],
        tiles: string[],
        attribution?: string,
    ): Promise<Style> {
        const mapboxStyle = await this.getMapboxStyle(baseStyle);

        assert(mapboxStyle.layers, "Original style has no layer");

        // Make semi-deep copy (only the parts we'll actually change)
        const style = Object.assign({}, mapboxStyle, {
            sources: Object.assign({}, mapboxStyle.sources),
            layers: mapboxStyle.layers.slice(),
        });

        style.sources[styleType] = {
            type: "raster",
            tiles,
            minzoom: zoomRange[0],
            maxzoom: zoomRange[1],
            tileSize: 256,
            attribution: attribution || "",
        };

        // Find original raster layer and overwrite its source .
        const originalRasterLayerIndex = style.layers.findIndex(
            layer => layer.type === "raster",
        );

        style.layers.splice(originalRasterLayerIndex, 1, {
            type: "raster",
            source: styleType,
            id: styleType,
            minzoom: zoomRange[0],
            maxzoom: zoomRange[1],
        } as mapboxgl.AnyLayer);

        return style;
    }

    private getBingStaticImageUrl(query: StaticImageQuery): string {
        return getBingClient().getStaticImageUrl({
            centerPoint: query.coord,
            imagerySet: BingImagerySet.Aerial,
            mapWidth: query.width,
            mapHeight: query.height,
            zoomLevel: query.zoom,
        });
    }

    private getGoogleStaticImageUrl(
        googleMapType: GoogleMapType,
        query: StaticImageQuery,
    ): string {
        return getGoogleMapsClient().getStaticImageUrl({
            width: query.width,
            height: query.height,
            mapType: googleMapType,
            zoom: query.zoom,
            center: query.coord as [number, number],
        });
    }
}

export type StaticImageUrlGenerator = (q: StaticImageQuery) => string;

export interface StaticImageQuery {
    coord: GeoJSON.Position;
    zoom: number;
    width: number;
    height: number;
    styleId: StyleType;
}
