import { Component, ChangeDetectionStrategy } from "@angular/core";
import { when, makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { AnySourceDef, ComponentDef } from "skCommon/insights/dashboard";
import { DialogService } from "skCommon/ui/dialog/dialog.service";
import { assert } from "skCommon/utils/assert";
import { Logger } from "skCommon/utils/logger";
import { observableRef } from "skCommon/state/mobxUtils";

import { DashbaordEditorService } from "skInsights/dashboard/dashboardEditor/dashboardEditor.service";
import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { parseDataRef } from "skInsights/framework/query/dataRef";
import { InsightsService } from "skInsights/insights.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { DashboardRoutes } from "skInsights/dashboard/dashboard.routing";
import { ProductCatalogService } from "skInsights/helpers/datacube/productCatalog.service";

const SAVING = Symbol();

@Component({
    selector: "sk-dashboard-editor-json",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./dashboardEditorJson.pug",
    styleUrls: ["./dashboardEditorJson.scss"],
})
export class DashboardEditorJsonComponent extends BaseComponent {

    @observableRef
    public json: any = {};

    public get openUrl(): string[] | null {
        const dashboard = this.dashbaordEditorService.dashboard;

        if (dashboard) {
            return ["/", DashboardRoutes.Dashboard, dashboard.id];
        } else {
            return null;
        }
    }

    constructor(
        private dashbaordEditorService: DashbaordEditorService,
        private insightsService: InsightsService,
        private logger: Logger,
        private snackBarService: SnackBarService,
        private moduleRegistryService: ModuleRegistryService,
        private dialogService: DialogService,
        private productCatalogService: ProductCatalogService,
    ) {
        super();

        makeObservable(this);
    }

    public async ngAfterViewInit(): Promise<void> {
        await when(() => !!this.dashbaordEditorService.dashboard);

        const schema = this.dashbaordEditorService.dashboard!.schema;

        this.json = schema || {
            layout: null,
            mutations: {},
            sources: {},
        };
    }

    public async saveJson(): Promise<void> {
        let confirm: boolean | null = true;

        try {
            this.snackBarService.notify(`Checking the dashboard...`, 5);

            this.insightsService.globalLoadingProcesses.add(SAVING);
            const issues = await this.findIssuesInDashboard(this.json.sources, this.json.layout);
            this.insightsService.globalLoadingProcesses.delete(SAVING);

            if (issues.unusedSourceIds.length || issues.inactiveProducts.length) {
                confirm = await this.dialogService.confirm(
                    "The dashboard contains unused sources or inactive product ids, do you want to save it anyway?",
                    `Unused sources: ${issues.unusedSourceIds}` +
                    `Inactive product ids: ${issues.inactiveProducts}`);
            }
            this.insightsService.globalLoadingProcesses.add(SAVING);
            if (confirm) {
                await this.dashbaordEditorService.updateDashboard({ schema: this.json });

                this.snackBarService.notify(`Saved`, 3);
            }
        } catch (e) {
                this.logger.error(e, "Saving JSON dashboard schema");
                this.snackBarService.notify(`Could not save schema: ${e.message}`);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(SAVING);
        }
    }

    private async findIssuesInDashboard(
        sources: Record<string, AnySourceDef> | undefined,
        layout: ComponentDef | null,
    ): Promise<DashboardIssues> {
        return {
            unusedSourceIds: this.findUnusedSources(sources, layout),
            inactiveProducts: await this.findInactiveProducts(sources),
        };
    }

    private async findInactiveProducts(
        sources: Record<string, AnySourceDef> | undefined,
    ): Promise<string[]> {
        const products = await this.productCatalogService.getProducts();
        const inactive: string[] = [];
        if (sources) {
            const sourcesKeys = Object.keys(sources);
            sourcesKeys.forEach(key => {
                const foundSource = products.find(p => p.productId === key);
                if (foundSource && !foundSource.active) {
                    inactive.push(foundSource.productId);
                }
            });
        }
        return inactive;
    }

    private findUnusedSources(
        sources: Record<string, AnySourceDef> | undefined,
        layout: ComponentDef | null,
    ): string[] {
        if (sources) {
            const sourcesKeys = Object.keys(sources);
            assert(layout, "Dashboard has no component!");
            const defs = this.flattenComponents(layout);
            const usedSources: string[] = [];
            for (const def of defs) {
                const option = this.moduleRegistryService.getComponentOptions(def);
                if (option.getComponentSources) {
                    usedSources.push(
                        ...option.getComponentSources(def)
                            .map((source) => parseDataRef(source).id));
                }
            }
            return usedSources.filter(s => !sourcesKeys.includes(s));
        }
        return [];
    }

    private flattenComponents(
            lastDef: ComponentDef,
            defArray: ComponentDef[] = [],
    ): ComponentDef[] {
            const options = this.moduleRegistryService.getComponentOptions(lastDef);
            defArray.push(lastDef);
            if (options.getChildComponents) {
                options.getChildComponents(lastDef)
                    .flatMap(childDef => {
                        defArray = this.flattenComponents(childDef, defArray);
                    });
            }
            return defArray;
        }
}

interface DashboardIssues {
    unusedSourceIds: string[];
    inactiveProducts: string[];
}
