import { assert } from "skCommon/utils/assert";

import { OperationDef, OperationWithPipe } from "skInsights/framework/abstract/operation";
import { isCollection, isSeries } from "skInsights/framework/data/helpers";
import { AnyDashboardData, DashboardDataType, DashboardSeries, SeriesDataPoint } from "skInsights/framework/data/structures";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { PipeArgument } from "skInsights/framework/pipeCall";

type DcrFilterType = "datacube/dcr-filter";
const DcrFilterType: DcrFilterType = "datacube/dcr-filter";

export class DcrFilter extends OperationWithPipe<DcrFilterDef> {

    public readonly type = DcrFilterType;

    public pipe = "dcr-filter";

    public async execute(
        def: DcrFilterDef,
        data: AnyDashboardData,
        dataRefService: DataRefService,
    ): Promise<DashboardSeries> {
        let seriesData: DashboardSeries;

        if (isSeries(data)) {
            seriesData = data;
        } else if (isCollection(data, DashboardDataType.Series)) {
            seriesData = Object.values(data.set)[0];
        } else {
            throw new Error("");
        }

        const dcrSeries = (await dataRefService.resolveFlattenSeries(def.dcrRef))[0];
        const dcrMap = new Map(dcrSeries.series.map(point => [point.x, point.y]));

        let filteredSeries: SeriesDataPoint[];
        if (def.trailing) {
            while (true) {
                const last = seriesData.series.at(-1);
                if (!last) {
                    return {
                        ...seriesData,
                        series: [],
                    };
                }
                if (((dcrMap.get(last.x) ?? 0) <= def.minDcr)) {
                    seriesData.series.pop();
                } else {
                    filteredSeries = seriesData.series;
                    break;
                }
            }
        } else {
            filteredSeries = seriesData.series.filter(
                point => (dcrMap.get(point.x) ?? 0) > def.minDcr,
            );
        }

        assert(filteredSeries.length > 0, "DCR filter resulted in empty timeseries");

        return {
            ...seriesData,
            series: filteredSeries,
        };
    }

    public executePipe(
        args: PipeArgument[],
        data: AnyDashboardData,
        dataRefService: DataRefService,
    ): Promise<DashboardSeries> {
        const dcrRef = args[0];
        const minDcr = args[1];
        let trailing;

        assert(typeof dcrRef === "string" && !!dcrRef, `Invalid dcr-filter argument ${args}`);
        assert(typeof minDcr === "number", `Invalid minDcr value: ${minDcr}`);

        if (args[2] && typeof args[2] === "boolean") {
            trailing = args[2];
        }

        return this.execute({
            operation: DcrFilterType,
            dcrRef,
            minDcr,
            trailing,
        }, data, dataRefService);
    }
}

/**
 * Pipe for filtering datacube product datapoints by their coresponding DCR.
 * Usage: dcr-filter(dcrTimeseriesRef, minDcr)
 * Example: `| dcr-filter("SK_MIN_MIN_COA_SAI_CN_D/*_30d_coverage_ratio", 0.1)`
 */
export interface DcrFilterDef extends OperationDef<DcrFilterType> {
    /**
     * @dataref
     */
    dcrRef: string;
    /**
     * Minimal DCR value, exclusive
     */
    minDcr: number;
    /**
     * start filtiring from end
     */
    trailing?: boolean;
}
