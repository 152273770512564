import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
} from "@angular/core";

import {
    CalendarOptions,
    DEFAULT_CALENDAR_OPTIONS,
} from "skCommon/datePicker/model";

@Component({
    selector: "sk-date-picker",
    templateUrl: "./datePicker.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent {

    @Input()
    public maxDate: Date | undefined = undefined;
    @Input()
    public minDate: Date | undefined = undefined;
    @Input()
    public options: CalendarOptions = DEFAULT_CALENDAR_OPTIONS;

    @Output()
    public dateSelected = new EventEmitter<Date>();

    public emitSelectedValue(date: Date): void {
        this.dateSelected.emit(date);
    }
}
