import { ClassProvider, InjectionToken, Injector, Type, ValueProvider } from "@angular/core";

export const LINK_HANDLERS_TOKEN = new InjectionToken<LinkHandler>("Insights link handlers");

export const INSIGHTS_PROTOCOL = "insights://";

export function provideLinkHandler(
    handler: Type<LinkHandler> | LinkHandler,
): ClassProvider | ValueProvider {
    return {
        provide: LINK_HANDLERS_TOKEN,
        multi: true,
        ...(typeof handler === "function" ? { useClass: handler } : { useValue: handler }),
    };
}

export interface LinkHandler {
    match: RegExp;
    execute: (link: string, injector: Injector) => void;
}
