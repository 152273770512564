import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";

import { SidePanelModule } from "skCommon/ui/sidePanel/sidePanel.module";
import { SkeletonModule } from "skCommon/ui/skeleton/skeleton.module";

import { TextComponent, TextComponentType } from "skInsights/modules/core/components/text.component";
import { provideComponent, provideOperation } from "skInsights/framework/tokens";
import { FlexComponent, FlexComponentDef, FlexComponentType } from "skInsights/modules/core/components/flex/flex.component";
import { SharedModule } from "skInsights/shared.module";
import { SlidesComponent, SlidesComponentDef, SlidesComponentType } from "skInsights/modules/core/components/slides/slides.component";
import { RichTextModule } from "skInsights/partials/richText/richText.module";
import { HtmlComponent, HtmlComponentType } from "skInsights/modules/core/components/html/html.component";
import { SlideContainerComponent } from "skInsights/modules/core/components/slides/slideContainer.component";
import { PickOperation } from "skInsights/modules/core/operations/pick";
import { ButtonsModule } from "skInsights/partials/buttons/buttons.module";
import { FilterOperation } from "skInsights/modules/core/operations/filter";
import { provideDesigner } from "skInsights/framework/designer/provider";
import { SlidesDesignerComponent } from "skInsights/modules/core/components/slides/designer/slidesDesigner.component";
import { DesignerModule } from "skInsights/framework/designer/designer.module";
import { FlexDesignerComponent } from "skInsights/modules/core/components/flex/designer/flexDesigner.component";
import { HtmlDesignerComponent } from "skInsights/modules/core/components/html/designer/htmlDesigner.component";
import { DashboardCopyrightModule } from "skInsights/partials/dashboardCopyright/dashboardCopyright.module";
import { SidebarViewComponent, SidebarViewComponentDef, SidebarViewComponentType } from "skInsights/modules/core/components/sidebarView/sidebarView.component";
import { SidebarViewDesignerComponent } from "skInsights/modules/core/components/sidebarView/designer/sidebarViewDesigner.component";
import { SidebarViewStaticRendererService } from "skInsights/modules/core/components/sidebarView/sidebarViewStaticRenderer.service";
import { FlexStaticRendererService } from "skInsights/modules/core/components/flex/flexStaticRenderer.service";
import { HtmlStaticRendererService } from "skInsights/modules/core/components/html/htmlStaticRenderer.service";

@NgModule({
    imports: [
        SharedModule,
        ButtonsModule,
        RichTextModule,
        DesignerModule,
        DashboardCopyrightModule,
        SidePanelModule,
        SkeletonModule,
        MatRippleModule,
    ],
    declarations: [
        TextComponent,
        HtmlComponent,
        FlexComponent,
        SlidesComponent,
        SlideContainerComponent,
        HtmlDesignerComponent,
        FlexDesignerComponent,
        SlidesDesignerComponent,
        SidebarViewComponent,
        SidebarViewDesignerComponent,
    ],
    providers: [
        provideComponent(TextComponentType, TextComponent),
        provideComponent(HtmlComponentType, HtmlComponent, {
            staticRenderer: HtmlStaticRendererService,
        }),
        provideComponent(FlexComponentType, FlexComponent, {
            getChildComponents: (def: FlexComponentDef) => (
                def.children.map(child => child.content)
            ),
            staticRenderer: FlexStaticRendererService,
        }),
        provideComponent(SlidesComponentType, SlidesComponent, {
            getChildComponents: (def: SlidesComponentDef) => def.slides.map(slide => (
                "content" in slide ? slide.content : slide
            )),
        }),
        provideComponent(SidebarViewComponentType, SidebarViewComponent, {
            getChildComponents: (def: SidebarViewComponentDef) => (
                def.sections.flatMap(section => (
                    section.pages.map(page => page.content)
                ))
            ),
            staticRenderer: SidebarViewStaticRendererService,
        }),

        provideDesigner(HtmlComponentType, HtmlDesignerComponent),
        provideDesigner(FlexComponentType, FlexDesignerComponent),
        provideDesigner(SlidesComponentType, SlidesDesignerComponent),
        provideDesigner(SidebarViewComponentType, SidebarViewDesignerComponent),

        provideOperation(PickOperation),
        provideOperation(FilterOperation),
    ],
})
export class CoreModule { }
