import { InjectionToken } from "@angular/core";

export const MAP_CONFIG_TOKEN = new InjectionToken<MapConfig>("Olaf map component config");

export enum StyleType {
    Roadmap = "cje7a7883h3by2ss304iitzlo",
    Satellite = "cjwrr94o86mlt1cs6x1171d6e",
    SatelliteOnly = "ckcvxti8k0wav1imuszdje0f7",
    Monochrome = "ckjigylwg3eqs19nuteuq7b4i",
    BingAerial = "bing-aerial",
    GoogleSatellite = "google-satellite",
    GoogleSatelliteOnly = "google-satellite-only",
    LocalOsm = "https://maps.local/styles/osm-bright/style.json",
    LocalSatellite = "/map-styles/satellite.json",
}

export interface MapConfig {
    availableStyles: StyleType[];
    defaultStyle: StyleType;
}
