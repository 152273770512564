import { ChangeDetectionStrategy, Component } from "@angular/core";
import { toStream } from "mobx-utils";
import { combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { computed, observableRef } from "skCommon/state/mobxUtils";

import { DesignerModel } from "skInsights/framework/designer/designerModel";
import { SlideModel } from "skInsights/modules/core/components/slides/designer/slideModel";
import { SlidesComponentDef, SlidesComponentType } from "skInsights/modules/core/components/slides/slides.component";

@Component({
    selector: "sk-slide-designer",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./slidesDesigner.pug",
    styleUrls: ["./slidesDesigner.scss"],
})
export class SlidesDesignerComponent extends BaseComponent {

    public slides: SlideModel[];

    @observableRef
    public navigation: boolean;

    @observableRef
    public currentSlideIndex: number = 0;

    @computed
    public get currentSlide(): SlideModel {
        return this.slides[this.currentSlideIndex];
    }

    constructor(private designerModel: DesignerModel<SlidesComponentDef>) {
        super();

        makeObservable(this);

        const initDef = this.designerModel.initialDefinition;

        this.slides = initDef.slides.map((slide, i) => new SlideModel(i, slide));

        this.navigation = !!initDef.navigation;

        const navigation$ = toStream(() => this.navigation, true);
        const slides$ = combineLatest(this.slides.map(slide => slide.getDefinition$()));

        const def$ = combineLatest([navigation$, slides$]).pipe(
            map(([navigation, slides]) => ({
                component: SlidesComponentType,
                navigation,
                slides,
            })),
        );

        this.designerModel.updateDefinitionStream(def$);
    }
}
