import { Component, ChangeDetectionStrategy } from "@angular/core";
import { makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { groupBy } from "skCommon/utils/group";
import { observableRef } from "skCommon/state/mobxUtils";
import { ModuleDocs } from "skCommon/insights/docs";

@Component({
    selector: "sk-docs",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./docs.pug",
})
export class DocsComponent extends BaseComponent {

    @observableRef
    public sections?: DocsSection[];

    @observableRef
    public openSection?: DocsSection;

    @observableRef
    public openModule?: ModuleDocs;

    constructor() {
        super();

        makeObservable(this);
    }

    public async ngOnInit() {
        super.ngOnInit();

        const data = (await import("skInsights/docs.json")).default as ModuleDocs[];

        this.sections = [...groupBy(data, "section").entries()]
            .map(([name, modules]) => ({
                name,
                modules,
            }));
    }
}

interface DocsSection {
    name: string;
    modules: ModuleDocs[];
}
