import { extendError, SkError } from "skCommon/core/error";

const VALUE_SEPARATOR = ",";
const ROW_SEPARATORS = /\r?\n/;
const PREFERRED_ROW_SEPARATOR = "\n";

export function createCsvFile(content: string) {
    return new Blob([content], {
        type: "text/csv",
    });
}

/**
 * Create a CSV file text contaning
 * @param data Objects to create rows from
 * @param cols List of columns
 * @param groupBy Group rows with same values in column of given index.
 *  The column needs to be sorted.
 * @param transpose
 */
export function objectToCsv<T>(
    data: T[],
    cols: ColumnDetail<T>[],
    groupBy: number = null,
    transpose: boolean = false,
): string {
    const dataColumns = getDataColumns(cols);
    const headerRows = getHeader(cols);
    // Itearte over data and create rows
    const dataRows = data.map(item => dataColumns.map(col => {
        const raw = col.selector(item);

        if (typeof raw !== "number" && !raw) {
            return "";
        } else {
            return raw.toString();
        }
    }));

    if (groupBy !== null) {
        groupDataRows(dataRows, groupBy);
    }

    return array2dToCsv(
        headerRows.concat(dataRows),
        transpose,
    );
}

export function array2dToCsv(
    data: string[][],
    transpose: boolean = false,
): string {
    if (!data.length) {
        return "";
    }

    data = data.map(row => {
        try {
            return row.map(item => item.toString());
        } catch (e) {
            throw extendError(e, `Could not create CSV row ${row.join("|")}: `);
        }
    });

    let out = "";

    if (!transpose) {
        for (const row of data) {
            out += row
                .map(val => escapeDelimiter(val || ""))
                .join(VALUE_SEPARATOR) + PREFERRED_ROW_SEPARATOR;
        }
    } else {
        const len = data.map(r => r.length).reduce((max, v) => Math.max(max, v));

        for (let i = 0; i < len; i++) {
            for (const row of data) {
                out += (row.length > i ? escapeDelimiter(row[i] || "") : "")
                    + VALUE_SEPARATOR;
            }

            out = out.replace(/,$/, "\n");
        }
    }

    return out;
}

export function csvToArray2d(csv: string): CsvTable {
    return csv.split(ROW_SEPARATORS).map(
        row => row.split(VALUE_SEPARATOR).map(
            val => val.startsWith(`"`) && val.endsWith(`"`)
                ? val.slice(1, -1)
                : val,
        ),
    );
}

export function csvToJson<T>(str: string): T[] {
    const csv = csvToArray2d(str);
    const headers = csv[0];
    const json = [];
    let trailing = [];

    for (let i = 1; i < csv.length; i++) {
        const row = csv[i];
        const obj = {};
        for (const [j, value] of row.entries()) {
            obj[headers[j].trim()] = value;
        }

        if (row.length === 1 && !row[0]) {
            trailing.push(obj);
        } else {
            if (trailing.length) {
                json.push(...trailing);
                trailing = [];
            }
            json.push(obj);
        }
    }

    return json;
}

export function isParentColumn<T>(col: ColumnDetail<T>): col is ParentColumnDetail<T> {
    return "children" in col;
}

export function isDataColumn<T>(col: ColumnDetail<T>): col is DataColumnDetail<T> {
    return "selector" in col;
}

/**
 * Create header rows - go through provided column def tree breadth-first
 * and whenever column with children is met, move them into the
 * `nextHeaderRow`, otherwise put null there. Only `nextHeaderRow` contains,
 * nulls only, the header is done.
 */
function getHeader<T>(cols: ColumnDetail<T>[]): string[][] {
    const header: string[][] = [];
    let tableYPointer = 0;

    let nextHeaderRow: ColumnDetail<any>[][] = cols.map(rootCol => [rootCol]);

    while (nextHeaderRow.some(col => col !== null)) {
        const currentRow = nextHeaderRow;
        let tableXPointer = 0;

        nextHeaderRow = [];
        header[tableYPointer] = [];

        for (const rowCols of currentRow) {
            if (rowCols === null) {
                header[tableYPointer].push("");
                nextHeaderRow.push(null);
                tableXPointer++;
            } else {
                rowCols.forEach((subCol, subIndex) => {
                    header[tableYPointer].push(subCol.title);

                    if (isParentColumn(subCol)) {
                        nextHeaderRow.push(subCol.children);
                    } else {
                        nextHeaderRow.push(null);
                    }

                    if (subIndex > 0) {
                        // New column was added on lower level, copy the value
                        // from previous cell for rows above.
                        for (let y = tableYPointer - 1; y >= 0; y--) {
                            header[y].splice(
                                tableXPointer,
                                0,
                                header[y][tableXPointer - 1],
                            );
                        }
                    }

                    tableXPointer++;
                });
            }
        }

        tableYPointer++;
    }

    return header;
}

/**
 * Find all data columns and return then in shallow array in correct order.
 */
function getDataColumns<T>(cols: ColumnDetail<T>[]): DataColumnDetail<T>[] {
    let dataColumns: DataColumnDetail<T>[] = [];

    for (const col of cols) {
        if (isParentColumn(col)) {
            dataColumns = dataColumns.concat(getDataColumns(col.children));
        } else {
            dataColumns.push(col);
        }
    }

    return dataColumns;
}

function groupDataRows(data: string[][], colIndex: number) {
    for (let i = 1; i < data.length; i++) {
        if (data[i][colIndex] === data[i - 1][colIndex]) {
            for (let c = 0; c < data[i].length; c++) {
                if (c !== colIndex && data[i][c]) {
                    data[i - 1][c] = data[i][c];
                }
            }

            data.splice(i, 1);
            i--;
        }
    }
}

function escapeDelimiter(value: string) {
    if (value.indexOf(VALUE_SEPARATOR) === -1) {
        return value;
    }

    if (value.indexOf("\"") === -1) {
        return "\"" + value + "\"";
    }

    throw new CsvError(
        `Value with both ${VALUE_SEPARATOR} and " is not supported.`,
    );
}

export type ColumnDetail<T> = ParentColumnDetail<T> | DataColumnDetail<T>;

export type CsvTable = string[][];

export interface ParentColumnDetail<T> {
    title: string;
    children: ColumnDetail<T>[];
}

export interface DataColumnDetail<T> {
    title: string;
    selector: (dataRow: T) => string|number|boolean;
}

export class CsvError extends SkError {
    public dataToLog = {};

    constructor(err: string) {
        super("CsvError", err);
    }
}
