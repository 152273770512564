export function deduplicate<T>(
    list: T[],
    keys: (keyof T)[],
): T[] {
    const map = new Map<string | number, T>();

    for (const item of list) {
        const uuid = keys.reduce((id, key) => id += String(item[key]), "");
        map.set(uuid, item);
    }

    return [...map.values()];
}
