import { escapeRegExp } from "skCommon/utils/escapeRegExp";

import { DashboardCollection, DashboardDataType, CollectableData } from "skInsights/framework/data/structures";

/**
 * Run select query for series set returning either single series or set if
 * query contained a wildcard
 */
export function collectionSelect<T extends CollectableData>(
    seriesSet: DashboardCollection<T>,
    query: string,
): DashboardCollection<T> | T | void {
    if (query.includes("*")) {
        const safeQuery = escapeRegExp(query);
        const expr = new RegExp("^" + safeQuery.replace(/\\\*/g, ".+") + "$");

        const recs = Object.keys(seriesSet.set)
            .filter(key => !!key.match(expr))
            .map(key => [key, seriesSet.set[key]]);

        if (recs.length) {
            return {
                type: DashboardDataType.Collection,
                set: Object.fromEntries(recs),
            };
        }
    } else {
        return seriesSet.set[query];
    }
}
