/**
 * Create HTML code displaying given icon which can be used in toolbox button
 * definition.
 */
export function getToolboxIcon(name: string): string {
    return `
    <svg class="icon"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="18"
            height="14">
        <use xlink:href="/spritemap.svg#sprite-${name}"></use>
    </svg>`;
}
