import { Injectable } from "@angular/core";

import { getDatacubeClient, DatacubeGetQuery, GetDatapointsResponse, DatacubeFilter } from "skCommon/datacube/client";
import { queryToFilters, FilterQuery } from "skCommon/datacube/query";
import { Logger } from "skCommon/utils/logger";
import { generateUniqueId } from "skCommon/utils/uniqueId";
import { groupBy } from "skCommon/utils/group";

import { DashboardSource } from "skInsights/framework/abstract/dashboardSource";
import {
    DashboardDataType,
} from "skInsights/framework/data/structures";
import { DataProvider } from "skInsights/framework/data/dataProvider";
import { SingleSeriesProvider } from "skInsights/framework/data/providers/singleSeriesProvider";
import { CatalogService } from "skInsights/helpers/datacube/catalog.service";
import { parseDateString } from "skInsights/utils/dateString";
import { ExplorableDataProvider, ExplorableGroup } from "skInsights/framework/data/explorableDataset";

@Injectable()
export class GetQuerySource extends DashboardSource<GetQuerySourceDef> {

    public type: string = GetQuerySourceType;

    constructor(
        private catalogService: CatalogService,
        private logger: Logger,
    ) {
        super();
    }

    public async canAccess(_def: GetQuerySourceDef): Promise<boolean> {
        return this.catalogService.hasCatalogItemForQuery();
    }

    public async create(def: GetQuerySourceDef): Promise<DataProvider> {
        const client = getDatacubeClient();

        const payload = def.query
            ? { filters: queryToFilters(this.deserializeQuery(def.query)) }
            : def.payload ? def.payload : null;

        if (!payload) {
            throw new Error("Neither query not payload specified.");
        }

        let result: GetDatapointsResponse | void;

        if (!def.async) {
            try {
                result = await client.get(payload);
            } catch (e) {
                this.logger.warning(`GetQuery's sync request failed: ${e.message}`);
            }
        }

        if (!result) {
            result = await client.getAsync(payload);
        }

        const csv = await client.openDatacubeCsv(result.csvLink);

        if (!csv.length) {
            throw new Error(`Query returned empty CSV`);
        }

        const { name, aoi } = this.parseMetaFromQuery(payload);

        return new GetQueryDataProvider({
            type: DashboardDataType.Series,
            series: csv.map(point => ({ x: point.endDatetime, y: point.value })),
            meta: {
                uid: generateUniqueId(),
                region: aoi,
                name: def.name ? def.name : name,
                viewOnly: true,
            },
        });
    }

    private parseMetaFromQuery(q: DatacubeGetQuery): { aoi: string, name: string } {
        const groupedFilters = groupBy(q.filters, "field");

        const algorithm = this.filtersToString(groupedFilters.get("algorithm") || []);
        const aoi = this.filtersToString(groupedFilters.get("aoi") || []);

        const namePrefix = aoi ? `${aoi.toUpperCase()} ` : "";
        const prettyName = algorithm
            .replace(/^[A-z]+_/, "")
            .replace(/_/g, " ");

        const name = `${namePrefix}${prettyName}`;

        return {
            name,
            aoi,
        };
    }

    private deserializeQuery(json: Json<FilterQuery>) {
        return {
            ...json,
            from: json.from ? parseDateString(json.from) : void 0,
            to: json.to ? parseDateString(json.to) : void 0,
        };
    }

    private filtersToString(filters: DatacubeFilter[]): string {
        return filters
            .flatMap(
                f => "values" in f.params
                    ? f.params.values
                    : "geometryLabel" in f.params
                        ? [f.params.geometryLabel]
                        : [],
            )
            .join();
    }
}

class GetQueryDataProvider
    extends SingleSeriesProvider
    implements DataProvider, ExplorableDataProvider {

    public getExplorableGroups(id: string): [ExplorableGroup] {
        return [{
            id,
            typeName: "Datacube Query",
            name: this.series.meta.name,
            items: [{
                id: this.series.meta.uid,
                data: this.series,
            }],
            buttons: [],
        }];
    }
}

/**
 * Load single timeseries from the internal Datacube API.
 *
 * The only selector the source accepts is `*` like so: `source-id/*`
 *
 * Due to limited ability for users to check their own permissions, the
 * datacube should exist in the datacube catalog.
 * https://datacube.spaceknow.com/#/explorer/catalogue
 *
 * All provided series are flagged viewOnly and thus not downloadable by user.
 */
export interface GetQuerySourceDef {
    type: GetQuerySourceType;
    /**
     * Query using which the datacube filters are created. Ideally the query
     * should correspond to one of the datacube catalog items.
     */
    query?: Json<FilterQuery>;
    /**
     * Alternatively a custom payload may be specified. The payload will be
     * sent to datacube API as is.
     */
    payload?: DatacubeGetQuery;
    /**
     * Always use async endpoint. If not set, the app will first try to get the
     * data using the sync endpoint and will only fallback to async if the sync
     * request fails.
     */
    async?: boolean;
    /**
     * label for name of timeseries for this source in data explorer
     */
    name?: string;
}

const GetQuerySourceType: GetQuerySourceType = "datacube/get-query";
type GetQuerySourceType = "datacube/get-query";

type Json<T> = T extends Date
  ? string
  : T extends object
    ? {
        [k in keyof T]: Json<T[k]>;
    }
    : T;
