import { DurationInputArg1, DurationInputArg2 } from "moment";

export const FULL_PERIOD = Symbol();
export const DEFAULT_PERIOD = Symbol();

export interface ZoomPeriod {
    label: string;
    period: ZoomPeriodDef;
}

export type ZoomPeriodDef = [DurationInputArg1, DurationInputArg2]
    | typeof FULL_PERIOD
    | typeof DEFAULT_PERIOD
    | DateRange;

export function isRange(period: ZoomPeriodDef): period is DateRange {
    return typeof period === "object"
        && ("min" in period || "max" in period);
}

export interface DateRange {
    min?: Date;
    max?: Date;
}
