import { Injectable } from "@angular/core";

import { DashboardSchema } from "skCommon/insights/dashboard";

import { DashboardData } from "skInsights/framework/dashboardData";

@Injectable({ providedIn: "root" })
export class CacheService {

    private cachedData: Map<string, DashboardData> = new Map();

    public async get(schema: DashboardSchema): Promise<DashboardData | undefined> {
        const hash = await this.hash(schema);

        return this.cachedData.get(hash);
    }

    public async store(schema: DashboardSchema, data: DashboardData): Promise<void> {
        const hash = await this.hash(schema);

        this.cachedData.set(hash, data);
    }

    private async hash(schema: DashboardSchema): Promise<string> {
        const keyObj = [schema.sources, schema.mutations, schema.pipes];
        const json = JSON.stringify(keyObj);
        const schemaBuffer = new TextEncoder().encode(json);

        const hashBuffer = await crypto.subtle.digest("SHA-1", schemaBuffer);

        return new TextDecoder().decode(hashBuffer);
    }
}
