import "moment-timezone";
import moment from "moment";

import { bootstrapApplication } from "skCommon/angular/bootstrap";
import { getLogger } from "skCommon/utils/logger";

import { InsightsModule } from "skInsights/insights.module";

import "../scss/insights.scss";

moment.tz.setDefault("UTC");

bootstrapApplication({
    module: InsightsModule,
}).catch(e => {
    getLogger().error(e);
    document.getElementById("init-error")!.style.display = "block";
    document.getElementById("init-error-reason")!.textContent = e.stack!;
});
