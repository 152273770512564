import { Directive, HostBinding, Input } from "@angular/core";

/**
 * Display basic skeleton animation over this element as long as the input is
 * true
 */
@Directive({
    selector: "[skSkeleton]",
})
export class SkeletonDirective {

    @Input()
    @HostBinding("class.sk-skeleton-block")
    public skSkeleton: boolean = false;
}
