import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, QuerySnapshot } from "@angular/fire/firestore";
import { AngularFireFunctions } from "@angular/fire/functions";
import { observable, makeObservable } from "mobx";

import { FirebaseAuthUserService } from "skCommon/angular/firebaseAuthUserService";
import { FullUserProfile } from "skCommon/core/user";
import { adaptFirestoreDoc, Doc } from "skCommon/firebase/doc";
import { insightsCustomers } from "skCommon/insights/database";
import { Logger } from "skCommon/utils/logger";
import { InsightsCustomer } from "skCommon/insights/customers";

@Injectable()
export class UserService extends FirebaseAuthUserService {

    @observable.ref
    public customerInfo?: Doc<InsightsCustomer>;

    constructor(
        private angularFirestore: AngularFirestore,
        angularFireAuth: AngularFireAuth,
        angularFireFunctions: AngularFireFunctions,
        logger: Logger,
    ) {
        super(angularFireAuth, angularFireFunctions, logger);

        makeObservable(this);
    }

    public async setUserData(profile: FullUserProfile): Promise<void> {
        await super.setUserData(profile);

        const customerResult = await this.angularFirestore
            .collection(insightsCustomers()).ref
            .where("userId", "==", profile.userId)
            .get() as QuerySnapshot<InsightsCustomer>;

        if (!customerResult.empty) {
            this.customerInfo = adaptFirestoreDoc<InsightsCustomer>(customerResult.docs[0]);
        }
    }
}
