import { NgModule } from "@angular/core";
import { ScrollingModule } from "@angular/cdk/scrolling";

import { SkeletonModule } from "skCommon/ui/skeleton/skeleton.module";

import { SimpleTableComponent, SimpleTableComponentType } from "skInsights/modules/tables/components/simpleTable.component";
import { provideComponent } from "skInsights/framework/tokens";
import { SharedModule } from "skInsights/shared.module";
import { ButtonsModule } from "skInsights/partials/buttons/buttons.module";
import { RichTextModule } from "skInsights/partials/richText/richText.module";
import { HeatmapComponent, HeatmapComponentType } from "skInsights/modules/tables/components/heatmap.component";

@NgModule({
    imports: [
        SharedModule,
        ButtonsModule,
        RichTextModule,
        ScrollingModule,
        SkeletonModule,
    ],
    declarations: [
        SimpleTableComponent,
        HeatmapComponent,
    ],
    providers: [
        provideComponent(SimpleTableComponentType, SimpleTableComponent),
        provideComponent(HeatmapComponentType, HeatmapComponent),
    ],
})
export class TablesModule { }
