import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { toStream } from "mobx-utils";
import { from, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { DashbaordEditorService } from "skInsights/dashboard/dashboardEditor/dashboardEditor.service";

@Injectable({ providedIn: "root" })
export class DashboardEditorTitleResolver implements Resolve<Observable<string>> {

    constructor(
        private dashboardEditorService: DashbaordEditorService,
    ) { }

    /**
     * @note cannot return just observable, otherwise angular waits for emitted
     * value, instead of putting the observable into data
     */
    public async resolve(): Promise<Observable<string>> {
        return from(toStream(() => this.dashboardEditorService.dashboard, true)).pipe(
            map(db => db ? `Edit: ${db.meta.name}` : ""),
        );
    }
}
