import { Injectable } from "@angular/core";
import { reaction } from "mobx";

import { Logger } from "skCommon/utils/logger";

import { UserService } from "skInsights/user/user.service";

@Injectable({ providedIn: "root" })
export class TrackingService {

    constructor(
        private userService: UserService,
        private logger: Logger,
    ) { }

    public init(): void {
        if (!window.hj) {
            this.logger.warning("HJ not availble on global scope");
            return;
        }

        reaction(() => this.userService.loggedIn, loggedIn => {
            if (loggedIn) {
                window.hj(
                    "identify",
                    this.userService.id,
                    {
                        email: this.userService.profile.email,
                    },
                );
            }
        });
    }
}

declare global {
    interface Window {
        hj: Function;
    }
}
