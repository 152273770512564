// tslint:disable:no-unused-expression

import {
    observe,
    Lambda,
    IArraySplice,
    IValueDidChange,
    IObservableArray,
    reaction,
    IReactionPublic,
    IReactionDisposer,
    IReactionOptions,
    action as mobxAction,
    observable as mobxObservable,
    computed as mobxComputed,
    makeObservable,
    when,
} from "mobx";
import { Observable } from "rxjs";

import { assert } from "skCommon/utils/assert";

export function observeProp<T extends {[k: string]: any}, K extends keyof T, P>(
    store: T,
    prop: K,
    handler: (diff: IArraySplice<P>) => void,
): () => void {
    let deepObserveDisposer: Lambda,
        refObserveDisposer: Lambda;

    // Observe the value if possible
    deepObserveDisposer = observe<P>(store[prop], handler, true);

    // Also observe the reference
    refObserveDisposer = observe<T, K>(store, prop, (diff) => {
        // Try to observe the new value as well
        deepObserveDisposer && deepObserveDisposer();
        deepObserveDisposer = observe(store[prop], handler);

        handler(createSpliceDiff(diff));
    });

    return function () {
        deepObserveDisposer && deepObserveDisposer();
        refObserveDisposer && refObserveDisposer();
    };
}

export function whenever(
    expression: (r: IReactionPublic) => boolean,
    effect: () => void,
    opts?: IReactionOptions<boolean, boolean>,
): IReactionDisposer {
    return reaction(
        expression,
        (truthy) => {
            if (truthy) {
                effect();
            }
        },
        opts,
    );
}

export function action(...args: any[]) {
    return mobxAction.apply(this, args);
}

export function observableRef(...args: any[]) {
    return mobxObservable.ref.apply(this, args);
}

export function observableDeep(...args: any[]) {
    return mobxObservable.deep.apply(this, args);
}

export function computed(...args: any[]) {
    return mobxComputed.apply(this, args);
}

export function observable(...args: any[]) {
    return mobxObservable.apply(this, args);
}

export function observableShallow(...args: any[]) {
    return mobxObservable.shallow.apply(this, args);
}

/**
 * Simplify general array observe to return splice events on update events.
 * @param array Array to observe
 * @param handler Callback on change
 * @param fireImmediately Fire splice with current array contents
 * @returns Disposer to stop observing
 */
export function arrayObserve<T>(
    array: IObservableArray<T>,
    handler: (change: IArraySplice<T>) => void,
    fireImmediately?: boolean,
    ): () => void {
        return observe(array, (change) => {
            if (change.type === "splice") {
                handler(change);
            } else {
                handler({
                    type: "splice",
                    object: array,
                    added: [change.newValue],
                    removed: [change.oldValue],
                    index: change.index,
                    removedCount: 1,
                    addedCount: 1,
                    observableKind: "array",
                    debugObjectName: "not_used",
                });
            }
        }, fireImmediately);
    }

let actionBatchInterval = 2000;

export function setActionBatchInterval(v: number): void {
    actionBatchInterval = v;
}

export function iterableToStream<V, T extends (Iterable<V>)>(
    obs: T,
    fireImmediately?: boolean,
): Observable<T> {
    return new Observable((sub) => {
        const dispose = observe(obs, () => {
            sub.next(obs);
        });

        if (fireImmediately) {
            sub.next(obs);
        }

        return () => dispose();
    });
}

/**
 * Wait for given function to not return undefined and resolve with the new
 * existing value.
 */
export async function waitFor<T>(predicate: () => T): Promise<Exclude<T, undefined>> {
    let lastValue: T | undefined;

    await when(() => {
        lastValue = predicate();
        return lastValue !== undefined;
    });

    return lastValue as Exclude<T, undefined>;
}

/**
 * Class which throttles calls of given functions and then calls them sync in
 * action.
 */
export class ActionBatch {
    private currentBatch: ActionBatchEntry[] = [];
    private timeout: number | Object = null;

    constructor() {
        makeObservable(this);
    }

    public add<T>(callback: () => T | Promise<T>): Promise<T> {
        if (this.timeout === null) {
            this.timeout = setTimeout(() => {
                this.flush();
            }, actionBatchInterval);
        }

        const entry: Partial<ActionBatchEntry> = { callback };

        const promise = new Promise<T>((res, rej) => {
            entry.resolve = res;
            entry.reject = rej;
        });

        this.currentBatch.push(entry as ActionBatchEntry);

        return promise;
    }

    @action
    private flush(): void {
        const oldBatch = this.currentBatch;
        this.currentBatch = [];
        this.timeout = null;

        oldBatch.forEach(({ callback, resolve, reject }) => {
            try {
                resolve(callback());
            } catch (e) {
                reject(e);
            }
        });
    }
}

interface ActionBatchEntry {
    callback: () => any;
    resolve: (v: any) => void;
    reject: (e: Error) => void;
}

/**
 * Create splice diff from the reference update diff.
 */
function createSpliceDiff<T>(
    diff: IValueDidChange<IObservableArray<T>>,
): IArraySplice<T> {
    assert(diff.type === "update", "array diff type is not update");

    const newValue = diff.newValue as IObservableArray<T>;
    const oldValue = diff.oldValue as IObservableArray<T>;

    return {
        type: "splice",
        object: newValue,
        added: [...newValue],
        removed: [...oldValue],
        index: 0,
        removedCount: oldValue.length,
        addedCount: newValue.length,
        observableKind: "array",
        debugObjectName: "not_used",
    };
}
