import { exists } from "skCommon/utils/types";

export function sortByKey<T>(
    key: keyof T,
    direction: SortDirection = SortDirection.Asc,
): (a: T, b: T) => number {
    return (a: T, b: T) => {
        const aIsDefined = exists(a[key]);
        const bIsDefined = exists(b[key]);

        if (aIsDefined && !bIsDefined || a[key] > b[key]) {
            return direction;
        } else if (!aIsDefined && bIsDefined || a[key] < b[key]) {
            return -direction;
        } else {
            return 0;
        }
    };
}

export function sortByKeys<T>(
    keys: (keyof T)[],
    directions: SortDirection[] = [SortDirection.Asc],
): (a: T, b: T) => number {
    return (a: T, b: T) => {
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const direction = directions[i < directions.length ? i : (directions.length - 1)];

            if (a[key] > b[key]) {
                return direction;
            } else if (a[key] < b[key]) {
                return -direction;
            }
        }

        return 0;
    };
}

/**
 * Return input items sorted in order given by a list of items or property
 * values. If the item / property value is not in the order list, it's put at
 * the end of the output array.
 */
export function sortByWeight<T>(
    items: readonly T[],
    order: readonly T[],
): T[];
export function sortByWeight<T, K extends keyof T>(
    items: readonly T[],
    order: readonly T[K][],
    key: K,
): T[];
export function sortByWeight<T, K extends keyof T>(
    items: any[],
    order: (T[K] | T)[],
    key?: K,
): T[] {
    const orderMap = new Map(order.map((v, k) => [v, k]));

    return [...items].sort((a, b) => {
        const aWeight = orderMap.get(key ? a[key] : a) ?? Number.POSITIVE_INFINITY;
        const bWeight = orderMap.get(key ? b[key] : b) ?? Number.POSITIVE_INFINITY;

        return aWeight - bWeight;
    });
}

export enum SortDirection {
    Asc = 1,
    Desc = -1,
}
