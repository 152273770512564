import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EMPTY, from, merge, Observable, of, ReplaySubject, share, switchMap } from "rxjs";

/**
 * Component which displays skeleton before the input text resolves
 */
@Component({
    selector: "sk-skeleton-text",
    templateUrl: "./skeletonText.pug",
    styleUrls: ["./skeletonText.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonTextComponent {

    public text$: Observable<string | undefined>;

    @Input()
    public accent?: "primary";

    private textInput$: ReplaySubject<Observable<string>> = new ReplaySubject();

    @Input()
    public set text(input: Promise<string> | Observable<string>) {
        this.textInput$.next(from(input));
    }

    constructor() {
        this.text$ = this.textInput$.pipe(
            switchMap(text$ => merge(
                of(undefined),
                text$ ? text$ : EMPTY,
            )),
            share(),
        );
    }
}
