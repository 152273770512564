import { Component, ChangeDetectionStrategy, Inject, TemplateRef, NgModule, HostListener } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { OverlayContainer } from "@angular/cdk/overlay";

import { RenderableComponent } from "skInsights/framework/renderable";
import {
    PANEL_CLASS,
    ACTIVE_OVERLAY_CLASS,
    PASSIVE_OVERLAY_CLASS,
} from "skInsights/partials/popup/popupDom";
import { SharedModule } from "skInsights/shared.module";
import { DialogWrapperData, DialogWrapperModule } from "skInsights/partials/popup/dialogWrapper.component";

/**
 * Wrapper of dialog wrapper which adds window-like logic (dragging, z-index ...)
 * but leaves the actual content rendering to the dialog-wrapper.
 */
@Component({
    selector: "sk-popup-wrapper",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./popupWrapper.pug",
})
export class PopupWrapperComponent {

    public renderable?: RenderableComponent;

    public templateRef?: TemplateRef<any>;

    constructor(
        private overlayContainer: OverlayContainer,
        @Inject(MAT_DIALOG_DATA)
        private data: PopupWrapperData,
    ) { }

    public ngOnInit(): void {
        this.bringForward();
    }

    @HostListener("mousedown")
    public onClick(): void {
        this.bringForward();
    }

    /**
     * Ugly method that manually queries panel elements and makes panel with
     * given ID active. MatDialog doesn't offer any interface to access the
     * overlay panel HTML elements so a direct DOM access needs to be used.
     */
    public bringForward(): void {
        const el = this.overlayContainer.getContainerElement();

        el.querySelectorAll("." + PANEL_CLASS).forEach(panel => {
            // Top level element where the z-index needs to be set
            const overlayWrapper = panel.parentElement!;

            if (panel.classList.contains(this.data.id)) {
                overlayWrapper.classList.remove(PASSIVE_OVERLAY_CLASS);
                overlayWrapper.classList.add(ACTIVE_OVERLAY_CLASS);
            } else {
                overlayWrapper.classList.remove(ACTIVE_OVERLAY_CLASS);
                overlayWrapper.classList.add(PASSIVE_OVERLAY_CLASS);
            }
        });
    }
}

export interface PopupWrapperData extends DialogWrapperData {
    id: string;
}

@NgModule({
    imports: [
        SharedModule,
        DragDropModule,
        DialogWrapperModule,
    ],
    declarations: [
        PopupWrapperComponent,
    ],
})
export class PopupWrapperModule { }

