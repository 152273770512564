import { Injectable } from "@angular/core";

import { FontLoaderService, LocalFont } from "skCommon/fonts/fontLoader.service";
import robotoTtfUrl from "skCommon/fonts/roboto/roboto-regular.ttf";
import robotoMediumTtfUrl from "skCommon/fonts/roboto/roboto-medium.ttf";
import robotoLightTtfUrl from "skCommon/fonts/roboto/roboto-light.ttf";
import robotoItalicTtfUrl from "skCommon/fonts/roboto/roboto-italic.ttf";
import robotoBoldTtfUrl from "skCommon/fonts/roboto/roboto-bold.ttf";
import robotoMediumItalicTtfUrl from "skCommon/fonts/roboto/roboto-medium-italic.ttf";
import robotoLightItalicTtfUrl from "skCommon/fonts/roboto/roboto-light-italic.ttf";

/**
 * Should be provided as FontLoaderService in web environment to load raw files
 * as arraybuffer.
 * @see FontLoaderService
 */
@Injectable()
export class WebFontLoaderService extends FontLoaderService {

    private fontUrls: Record<LocalFont, string> = {
        [LocalFont.RobotoRegular]: robotoTtfUrl,
        [LocalFont.RobotoMedium]: robotoMediumTtfUrl,
        [LocalFont.RobotoLight]: robotoLightTtfUrl,
        [LocalFont.RobotoBold]: robotoBoldTtfUrl,
        [LocalFont.RobotoItalic]: robotoItalicTtfUrl,
        [LocalFont.RobotoMediumItalic]: robotoMediumItalicTtfUrl,
        [LocalFont.RobotoLightItalic]: robotoLightItalicTtfUrl,
    };

    public async loadFont(fontName: LocalFont): Promise<ArrayBuffer> {
        const url = this.fontUrls[fontName];
        return await (await fetch(url)).arrayBuffer();
    }
}
