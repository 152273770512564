import { Injectable } from "@angular/core";

import { RawProduct, DatacubeClient } from "skCommon/datacube/client";

@Injectable({ providedIn: "root" })
export class ProductCatalogService {

    public products?: Promise<RawProduct[]>;

    constructor(private datacubeClient: DatacubeClient) { }

    public getProducts(): Promise<RawProduct[]> {
        if (!this.products) {
            this.products = this.datacubeClient.getProducts();
        }

        return this.products;
    }

    public async getAvailableProducts(): Promise<RawProduct[]> {
        const products = await this.getProducts();

        return products.filter(prod => prod.downloadable);
    }

    public async getAvailableProductIds(): Promise<Set<string>> {
        const products = await this.getAvailableProducts();

        return new Set(products.map(p => p.productId));
    }
}

export interface DatacubeAoi {
    name: string;
    point: GeoJSON.Point;
}
