import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";

import { LayoutComponent, LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { LayoutButton } from "skInsights/partials/buttons/button";

export const TextComponentType: TextComponentType = "core/text";
export type TextComponentType = "core/text";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./text.pug",
})
export class TextComponent extends LayoutComponent<TextComponentDef> {

    public title?: string = this.def.title;
    public subtitle?: string = this.def.subtitle;
    public text?: string = this.def.text;
    public buttons?: LayoutButton[] = this.def.buttons;

    constructor(
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        public readonly def: TextComponentDef,
    ) {
        super();
    }
}

/**
 * Component containing basic formatted text. Mostly useful for titles or short
 * simple messages.
 */
export interface TextComponentDef {
    component: TextComponentType;
    /**
     * @interpolated
     */
    title?: string;
    /**
     * @interpolated
     */
    subtitle?: string;
    /**
     * @interpolated
     */
    text?: string;
    /**
     * Buttons displayed in the top right corner
     */
    buttons?: LayoutButton[];
}
