import {
    observable,
    action,
    computed,
    makeObservable,
} from "mobx";

import { SkError } from "skCommon/core/error";
import {
    getDefaultAuthentication as getAuthentication,
} from "skCommon/core/authentication";
import {
    getUser,
    FullUserProfile,
} from "skCommon/core/user";
import { Log } from "skCommon/utils/actionLogger";
import { SPACEKNOW_OAUTH, UserAuthData } from "skCommon/auth/authenticator";
import { Logger } from "skCommon/utils/logger";
import { AuthProvider } from "skCommon/auth/oauthClient";
import { UserMetadata, getAuthClient, AppMetadata } from "skCommon/auth/client";
import { PermissionAccessor } from "skCommon/user/permissionAccessor";

export class BaseUserService {

    public readonly permissions = new PermissionAccessor(() => this.getProfile().permissions);

    @observable.ref
    public profile?: FullUserProfile;

    private initHash = window.location.hash;

    private initSearch = window.location.search;

    @computed
    public get loggedIn(): boolean {
        return !!this.profile;
    }

    public get id(): string {
        return this.profile ? this.profile.userId : null;
    }

    /**
     * Indicates that user email is missing or unverified.
     */
    @computed
    public get unverified(): boolean {
        return !this.profile
            || !this.profile.email
            || !this.profile.email_verified;

    }

    @computed
    public get userMetadata(): UserMetadata {
        return this.loggedIn
            ? this.profile.userMetadata
            : null;
    }

    @computed
    public get appMetadata(): AppMetadata {
        return this.loggedIn
            ? this.profile.appMetadata
            : null;
    }

    @computed
    public get hasNonSocialIdentity(): boolean {
        return this.loggedIn && this.profile.hasPassword;
    }

    constructor(protected logger: Logger) {
        makeObservable(this);
    }

    ///
    ///
    /// Public methods
    ///
    ///

    public getProfile(): FullUserProfile {
        if (this.loggedIn) {
            return this.profile;
        } else {
            throw new NotLoggedInError();
        }
    }

    @Log.Action
    @Log.Param("email", 0)
    @Log.Param("permanent", 2)
    @Log.ParentClassName("UserService")
    @action
    public async login(
        username: string,
        password: string,
        permanent: boolean,
    ): Promise<void> {
        this.profile = null;

        await getUser()
            .getToken({
                permanent,
                username,
                password,
            });

        const profile = await this.fetchProfile();

        await this.setUserData(profile);
    }

    @Log.Action
    @Log.Param("provider", 0)
    @Log.ParentClassName("UserService")
    @action
    public async passiveLogin(provider?: AuthProvider): Promise<void> {
        this.profile = null;

        await getUser().passiveLogin({ provider });
    }

    /**
     * Try to login with permanent auth data
     */
    @Log.Action
    @Log.ParentClassName("UserService")
    @action
    public async autoLogin(): Promise<void> {
        if (this.loggedIn) {
            return;
        }

        const auth = getAuthentication();

        if (!auth.hasAuthenticationData(SPACEKNOW_OAUTH)) {
            // Permanent auth data doesn't exist
            return;
        }

        const userAuthData = auth.getAuthenticationData(SPACEKNOW_OAUTH) as UserAuthData;

        const expirationTime = getAuthClient().getLoginExpiration();

        // Check whether user's login isn't too old (e.g. older that last
        // change to rules)
        if ((userAuthData.loginTimestamp || 0) < expirationTime) {
            // Force user to log in again.
            await this.revokeAuthData(true);
            return;
        }

        let authDataValid = await auth.validate(SPACEKNOW_OAUTH),
            profile: FullUserProfile;

        if (!authDataValid) {
            try {
                authDataValid = await this.renewAuthData();
            } catch (e) {
                this.logger.error(e);
                authDataValid = false;
            }
        }

        if (authDataValid) {
            profile = await this.fetchProfile();
        }

        if (!profile) {
            // Permanent auth data wasn't renewed, remove currently present
            // auth data without reload of the app.
            await this.revokeAuthData(true);
        } else {
            await this.setUserData(profile);
        }
    }

    @Log.Action
    @Log.ParentClassName("UserService")
    @action
    public async logOut(silent?: boolean): Promise<void> {
        await this.revokeAuthData(silent);
    }

    @Log.Action
    @Log.ParentClassName("UserService")
    @action
    public tryToProcessURLAuthData(): Promise<UserAuthData> {
        const hash = this.initHash;
        const search = this.initSearch;

        return getUser().setAuthDataFromUrlIfAvailable(search + hash);
    }

    @Log.Action
    @Log.Param("email", 0)
    @Log.ParentClassName("UserService")
    @action
    public async signupPassword(
        email: string,
        password: string,
        options: SignupOptions = {},
    ): Promise<void> {
        await getUser().signup({
            email,
            password,
            user_metadata: options.user_metadata,
        });
    }

    @Log.Action
    @Log.ParentClassName("UserService")
    @action
    public async resetPassword(email: string): Promise<void> {
        await getUser().resetPassword(email);
    }

    @Log.Action
    @Log.ParentClassName("UserService")
    @action
    public async changePassword(email: string): Promise<void> {
        await getUser().resetPassword(email);
    }

    @Log.Action
    @Log.ParentClassName("UserService")
    @action
    public updateUserMetadata(data: UserMetadata): Promise<UserMetadata> {
        return getUser()
            .updateMetadata(this.profile.sub, data)
            .then(userMetadata => this.profile.userMetadata = userMetadata);
    }

    /**
     * Additional (async) actions that should happen during login.
     */
    protected async setUserData(profile: FullUserProfile): Promise<void> {
        this.profile = profile;
    }

    @Log.Action
    @Log.ParentClassName("UserService")
    @action
    protected async revokeAuthData(silent?: boolean): Promise<void> {
        this.profile = undefined;
        // does not perform revocation when auth data is not present
        // and is silenced about it
        if (silent && !getAuthentication().hasAuthenticationData(SPACEKNOW_OAUTH)) {
            return;
        }
        await getAuthentication().revoke(SPACEKNOW_OAUTH, silent);
    }

    @Log.Action
    @Log.ParentClassName("UserService")
    @action
    private fetchProfile(): Promise<FullUserProfile> {
        return getUser().fetchProfile();
    }

    @Log.Action
    @Log.ParentClassName("UserService")
    @action
    private renewAuthData(): Promise<boolean> {
        return getAuthentication().renew(SPACEKNOW_OAUTH);
    }
}

///
///
/// Errors
///
///

class NotLoggedInError extends SkError {
    public dataToLog = {};

    constructor() {
        super(
            "NotLoggedInError",
            "User is not logged in, no profile available.",
        );
    }
}

export interface SignupOptions {
    user_metadata?: { [key: string]: string };
}
