import { Component, ChangeDetectionStrategy } from "@angular/core";

import { Logger } from "skCommon/utils/logger";

import { UserService } from "skInsights/user/user.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";

@Component({
    selector: "sk-login",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./login.pug",
    styleUrls: ["./login.scss"],
})
export class LoginComponent {

    constructor(
        private userService: UserService,
        private snackBarService: SnackBarService,
        private logger: Logger,
    ) { }

    public async performLogin(): Promise<void> {
        try {
            await this.userService.passiveLogin();
        } catch (e) {
            this.snackBarService.notify("Log in failed, please try it again later");
            this.logger.error(e);
        }
    }
}
