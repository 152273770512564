import { OutputBlockData } from "@editorjs/editorjs";

import { TypedOutputBlockData } from "skInsights/partials/blockEditor/typings/extra";

export const TEMPLATE_SECTION_TYPE = "template";

export function isTemplateSection(
    block: OutputBlockData,
): block is TypedOutputBlockData<typeof TEMPLATE_SECTION_TYPE, TemplateSectionToolData> {
    return block.type === TEMPLATE_SECTION_TYPE;
}

export interface TemplateSectionToolData {
    name: string;
}
