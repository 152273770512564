import { NgModule } from "@angular/core";
import { MatChipsModule } from "@angular/material/chips";
import { MatTabsModule } from "@angular/material/tabs";
import { RouterModule } from "@angular/router";

import { ResolveObject } from "skCommon/angular/helpers";
import { ChartModule } from "skCommon/chart/angular/chart.module";
import { SearchSelectModule } from "skCommon/angular/partials/searchSelect/searchSelect.module";
import { SkeletonModule } from "skCommon/ui/skeleton/skeleton.module";
import { SortByDateModule } from "skCommon/angular/pipes/sortByDate/sortByDate.module";

import {
    DashboardEditorHistoryComponent,
} from "skInsights/dashboard/dashboardEditor/history/dashboardEditorHistory.component";
import { SharedModule } from "skInsights/shared.module";
import { ButtonsModule } from "skInsights/partials/buttons/buttons.module";
import { DashboardViewComponent, DashboardViewComponentData } from "skInsights/dashboard/dashboardView/dashboardView.component";
import { DashboardListComponent } from "skInsights/dashboard/dashboardList/dashboardList.component";
import { DashboardCardComponent } from "skInsights/dashboard/dashboardList/dashboardCard.component";
import { DashboardRoutes, EditDashboardRoutes } from "skInsights/dashboard/dashboard.routing";
import { DashboardEditorComponent, DashboardEditorComponentData } from "skInsights/dashboard/dashboardEditor/dashboardEditor.component";
import { DashboardEditorGeneralComponent } from "skInsights/dashboard/dashboardEditor/dashboardEditorGeneral.component";
import { DashboardEditorJsonComponent } from "skInsights/dashboard/dashboardEditor/dashboardEditorJson.component";
import { AuthGuard } from "skInsights/user/auth.guard";
import { DashboardRefResolver } from "skInsights/dashboard/dashboardRef.resolver";
import { DataExplorerComponent } from "skInsights/dashboard/dashboardView/dataExplorer/dataExplorer.component";
import { DashboardViewTitleResolver } from "skInsights/dashboard/dashboardView/dashboardViewTitle.resolver";
import { DashboardEditorTitleResolver } from "skInsights/dashboard/dashboardEditor/dashboardEditorTitle.resolver";
import { JsonEditorModule } from "skInsights/partials/jsonEditor/jsonEditor.module";
import { DashboardDesignerComponent } from "skInsights/dashboard/dashboardEditor/designer/dahboardDesigner.component";
import { DesignerModule } from "skInsights/framework/designer/designer.module";
import { ZoomButtonModule } from "skInsights/partials/zoomButton/zoomButton.module";
import { DashboardCopyrightModule } from "skInsights/partials/dashboardCopyright/dashboardCopyright.module";
import { provideMainNavEntryProvider } from "skInsights/nav/nav";
import { DashboardMainNavService } from "skInsights/dashboard/dashboardView/dashboardMainNav.service";
import { DataExplorerTitleResolver } from "skInsights/dashboard/dashboardView/dataExplorer/dataExplorerTitle.resolver";

const RoutingModule = RouterModule.forChild([
    {
        path: `${DashboardRoutes.Dashboard}/:id`,
        canActivate: [AuthGuard],
        resolve: {
            dashboardRef: DashboardRefResolver,
            title$: DashboardViewTitleResolver,
        } as ResolveObject<DashboardViewComponentData>,
        children: [
            {
                path: "",
                component: DashboardViewComponent,
            },
        ],
    },
    {
        path: `${DashboardRoutes.Dashboard}/:id/${EditDashboardRoutes.History}/:revisionId`,
        canActivate: [AuthGuard],
        resolve: {
            dashboardRef: DashboardRefResolver,
            title$: DashboardViewTitleResolver,
        } as ResolveObject<DashboardViewComponentData>,
        children: [
            {
                path: "",
                component: DashboardViewComponent,
            },
        ],
    },
    {
        path: `${DashboardRoutes.Dashboard}/:id/${DashboardRoutes.DataExplorer}`,
        canActivate: [AuthGuard],
        data: {
            hideOverflow: true,
        },
        resolve: {
            dashboardRef: DashboardRefResolver,
            title$: DataExplorerTitleResolver,
        } as ResolveObject<DashboardViewComponentData>,
        children: [
            {
                path: "",
                component: DataExplorerComponent,
            },
        ],
    },
    {
        path: `${DashboardRoutes.EditDashboard}/:id`,
        component: DashboardEditorComponent,
        canActivate: [AuthGuard],
        resolve: {
            dashboardRef: DashboardRefResolver,
            title$: DashboardEditorTitleResolver,
        } as ResolveObject<DashboardEditorComponentData>,
        children: [
            {
                path: EditDashboardRoutes.General,
                component: DashboardEditorGeneralComponent,
            },
            {
                path: EditDashboardRoutes.Json,
                component: DashboardEditorJsonComponent,
            },
            {
                path: EditDashboardRoutes.Designer,
                component: DashboardDesignerComponent,
            },
            {
                path: EditDashboardRoutes.History,
                component: DashboardEditorHistoryComponent,
            },
            {
                path: "**",
                redirectTo: EditDashboardRoutes.General,
            },
        ],
    },
    {
        path: `${DashboardRoutes.Home}`,
        component: DashboardListComponent,
        canActivate: [AuthGuard],
        data: {
            getTitle(): string {
                return "Dashboards";
            },
        },
    },
]);

@NgModule({
    imports: [
        SharedModule,
        MatChipsModule,
        MatTabsModule,
        RoutingModule,
        ChartModule,
        ButtonsModule,
        JsonEditorModule,
        DesignerModule,
        ZoomButtonModule,
        SearchSelectModule,
        DashboardCopyrightModule,
        SkeletonModule,
        SortByDateModule,
    ],
    declarations: [
        DashboardViewComponent,
        DashboardListComponent,
        DashboardCardComponent,
        DashboardEditorComponent,
        DashboardEditorGeneralComponent,
        DashboardEditorJsonComponent,
        DashboardDesignerComponent,
        DataExplorerComponent,
        DashboardEditorHistoryComponent,
    ],
    exports: [
        DashboardCardComponent,
    ],
    providers: [
        provideMainNavEntryProvider(DashboardMainNavService),
    ],
})
export class DashboardModule { }
