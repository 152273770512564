import { Component, ChangeDetectionStrategy, ElementRef, Input, AfterViewInit } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { toStream } from "mobx-utils";
import { delay, filter, from, of, switchMap } from "rxjs";
import { makeObservable } from "mobx";

import { observableRef } from "skCommon/state/mobxUtils";
import { BaseComponent } from "skCommon/angular/base/base.component";
import { exists } from "skCommon/utils/types";

import { DashboardEvents } from "skInsights/framework/dashboardEvents";
import { LinkService } from "skInsights/framework/link.service";

/**
 * Component that renders HTML specified in the dashboard schema. Should be
 * only used inside layout components as it requires DashboardEventService to
 * be provided.
 */
@Component({
    selector: "sk-rich-text",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./richText.pug",
    styleUrls: ["./richText.scss"],
})
export class RichTextComponent extends BaseComponent implements AfterViewInit {

    @observableRef
    @Input()
    public html?: SafeHtml;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private linkService: LinkService,
        public dashboardEvents: DashboardEvents,
    ) {
        super();

        makeObservable(this);
    }

    public ngAfterViewInit(): void {
        const sub = from(toStream(() => this.html, true)).pipe(
            filter(exists),
            switchMap(html => of(html).pipe(
                delay(10),
            )),
        ).subscribe(() => {
            const actions = this.elementRef.nativeElement
                .querySelectorAll("[component][event]");

            actions.forEach(el => {
                el.addEventListener("mousedown", e => {
                    this.handleAction(el);
                    e.preventDefault();
                });
            });

            const links = this.elementRef.nativeElement.querySelectorAll("a[href]");

            links.forEach(el => {
                el.addEventListener("mousedown", e => {
                    const href = (el as HTMLAnchorElement).href;
                    this.linkService.executeLink(href);
                    e.preventDefault();
                });
            });
        });

        this.addSubscription(sub);
    }

    private handleAction(e: Element): void {
        this.dashboardEvents.emit({
            component: e.getAttribute("component")!,
            event: e.getAttribute("event")!,
            data: e.getAttribute("data") ?? void 0,
        });
    }
}
