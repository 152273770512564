import { NgModule } from "@angular/core";

import { provideComponent } from "skInsights/framework/tokens";
import { SharedModule } from "skInsights/shared.module";
import { MapComponent, MapComponentType } from "skInsights/modules/maps/components/map.component";
import { ValueLabels } from "skInsights/modules/maps/plugins/valueLabels";
import { plugin } from "skInsights/modules/maps/plugin";
import { ButtonsModule } from "skInsights/partials/buttons/buttons.module";

@NgModule({
    imports: [
        SharedModule,
        ButtonsModule,
    ],
    declarations: [
        MapComponent,
    ],
    providers: [
        provideComponent(MapComponentType, MapComponent),

        plugin("value-labels", ValueLabels),
    ],
})
export class MapsModule { }
