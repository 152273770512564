export abstract class SkError extends Error {
    public static readonly ERR_DEFAULT = "ERR_DEFAULT";
    public static readonly MESSAGES = new Map<string, string>();

    public skipLog = false;
    public previousError: Error;
    public rawStack: string;

    protected _trace: string;

    protected _message: string;

    public abstract get dataToLog(): {};

    public set trace(trace: string) {
        this._trace = trace;
    }

    public get trace() {
        if (!!this._trace) {
            return this._trace;

        } else if (!!this.previousError
                && this.previousError instanceof SkError) {

            return this.previousError.trace;
        }

        return undefined;
    }

    constructor(public name: string, public code: string) {
        super(name);

        Object.defineProperty(this, "message", {
            get(this: SkError): string {
                if (!!this._message) {
                    return this._message;
                }

                // tslint:disable-next-line:no-string-literal
                this._message = this.constructor["MESSAGES"].has(this.code)
                    // tslint:disable-next-line:no-string-literal
                    ? this.constructor["MESSAGES"].get(this.code)
                    : this.code;

                return this._message;
            },
        });

        Object.defineProperty(this, "stack", {
            get(this: SkError) {
                const msg = `${this.name}: ${this.message}`;

                return this.rawStack.startsWith(msg)
                    ? this.rawStack
                    : `${msg}\n${this.rawStack}`;
            },

            set(this: SkError, v: string) {
                this.rawStack = v;
            },
        });

        // Stack of native Error also contains an error message which is empty on
        // construction and so we need to prepend the custom message.
        this.rawStack = new Error().stack;
    }

    public toString() {
        return `${this.name}: ${this.message}`;
    }
}

/**
 * Wrapper around any error which also adds additional data to log.
 */
export class SkErrorWrap<T = {}> extends SkError {
    public dataToLog: T;

    constructor(public previousError: Error | SkError, dataToLog: T) {
        super(previousError.name, previousError.message);
        this.rawStack = previousError instanceof SkError
            ? previousError.rawStack
            : previousError.stack;

        const data: T = {} as T;

        if ("dataToLog" in previousError) {
            Object.assign(data, previousError.dataToLog);
        }

        Object.assign(data, dataToLog);

        this.dataToLog = data;
    }
}

export function extendError(e: Error, msg: string): Error {
    if  (!(e instanceof SkError)) {
        e.message = msg + e.message;
    }

    return e;
}

/**
 * Adds some additional information that will be logged with the error. Much
 * easier to use than creating new error subclass and copying original stack
 * so we don't lose the original source of the error.
 *
 * This function should replace most of the SkError usages, which only bring
 * troubles.
 *
 * Usage:
 *  } catch (e) {
 *      throw addErrorCtx(e, { "analysisId": id })
 *  }
 */
export function addErrorCtx(e: CtxError, ctx: Record<string, string>): Error {
    if (!e.skCtx) {
        e.skCtx = {};
    }

    Object.assign(e.skCtx, ctx);

    return e;
}

export interface CtxError extends Error {
    skCtx?: Record<string, string>;
}
