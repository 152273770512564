import { observable, makeObservable } from "mobx";

/**
 * Service which makes viewport sizes observable.
 */
export class Viewports<T extends string> {

    @observable.ref
    private viewportWidth = window.innerWidth;

    @observable.ref
    private viewportHeight = window.innerHeight;

    public get size(): { height: number, width: number } {
        return {
            height: this.viewportHeight,
            width: this.viewportWidth,
        };
    }

    constructor(private viewports: AvailableViewports<T>) {
        makeObservable(this);

        window.addEventListener("resize", () => {
            this.viewportWidth = window.innerWidth;
            this.viewportHeight = window.innerHeight;
        });
    }

    public is(size: T): boolean {
        return this.viewportWidth <= this.viewports[size];
    }

    public greaterThan(size: T): boolean {
        return this.viewportWidth > this.viewports[size];
    }
}

type AvailableViewports<T extends string> = Record<T, number>;
