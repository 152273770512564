import { Injectable } from "@angular/core";

import { assert } from "skCommon/utils/assert";
import { exists } from "skCommon/utils/types";
import { ComponentDef, DashboardSchema } from "skCommon/insights/dashboard";

import { ModuleRegistryService } from "skInsights/framework/moduleRegistry.service";
import { FlexComponentDef, FlexComponentType } from "skInsights/modules/core/components/flex/flex.component";
import { SlidesComponentDef, SlidesComponentType } from "skInsights/modules/core/components/slides/slides.component";
import { PageDefinition, SidebarViewComponentDef, SidebarViewComponentType } from "skInsights/modules/core/components/sidebarView/sidebarView.component";
import { TextComponentDef, TextComponentType } from "skInsights/modules/core/components/text.component";
import { HtmlComponentType } from "skInsights/modules/core/components/html/html.component";

export enum DashboardMigration {
    FlattenFlex = "flattenFlex",
    SlidesToSidebarView = "slidesToSidebarView",
    AdaptSidebarViewHeader = "adaptSidebarViewHeader",
    MoveIntroText = "moveIntroText",
    MergeSplitPages = "mergeSplitPages",
}

/**
 * Service implementation of containing all available migrations. Migration
 * change schema in-place so we don't need to deep-clone it for every migration.
 */
@Injectable({ providedIn: "root" })
export class MigrationService implements Record<DashboardMigration, MigrationMethod> {

    public readonly LABELS: Readonly<Record<DashboardMigration, string>> = {
        [DashboardMigration.FlattenFlex]: "Flatten flex",
        [DashboardMigration.SlidesToSidebarView]: "Convert slides to sidebar view",
        [DashboardMigration.AdaptSidebarViewHeader]: "Adapt sidebar view header info",
        [DashboardMigration.MoveIntroText]: "Move intro text to top",
        [DashboardMigration.MergeSplitPages]: "Merge split sidepanel pages",
    };

    constructor(
        private moduleRegistryService: ModuleRegistryService,
    ) { }

    public [DashboardMigration.FlattenFlex](schema: DashboardSchema): void {
        if (schema.layout) {
            this.flattenFlexComponents(schema.layout);
        }
    }

    public [DashboardMigration.SlidesToSidebarView](schema: DashboardSchema): void {
        const DEFAULT_TITLE = "UNTITLED";

        // Assuming slides is always the top-level component
        if (schema.layout && schema.layout.component === SlidesComponentType) {
            const slidesDef = schema.layout as SlidesComponentDef;

            const pages = slidesDef.slides.map(slide => (
                "content" in slide
                    ? {
                        content: slide.content,
                        title: slide.title || DEFAULT_TITLE,
                    }
                    : {
                        content: slide,
                        title: DEFAULT_TITLE,
                    }
            ));

            const sidebarViewDef: SidebarViewComponentDef = {
                component: SidebarViewComponentType,
                sections: [{
                    label: "Overview",
                    pages,
                }],
            };

            schema.layout = sidebarViewDef;
        }
    }

    /**
     * Convert first core/text component on each sidebar-view page into actual
     * sidebar-view page title.
     */
    public [DashboardMigration.AdaptSidebarViewHeader](schema: DashboardSchema): void {
        // Assuming sidebar-view is always a top-level component
        if (schema.layout && schema.layout.component === SidebarViewComponentType) {
            const sidebarViewDef = schema.layout as SidebarViewComponentDef;

            // Find pages with title-like first component
            const pages = sidebarViewDef.sections
                .flatMap(section => section.pages)
                .map(page => {
                    if (page.content.component !== FlexComponentType) {
                        return;
                    }

                    const flex = page.content as FlexComponentDef;
                    const firstChild = flex.children[0];

                    if (firstChild?.content.component !== TextComponentType) {
                        return;
                    }

                    // Remove the child
                    flex.children.splice(0, 1);

                    // And return it so we can still read from it
                    return {
                        page,
                        textComponent: firstChild.content as TextComponentDef,
                    };
                })
                .filter(exists);

            for (const { page, textComponent } of pages) {
                // Subtitles were defined such as:
                // Last data update @{SK_AGR_CUR_MAN_SAI_CN_D/metadata/mostRecentDeliveryDt | date}
                const exp = /Last data update \@\{([^\|\}]+) ?| ?date}/;
                const expResult = textComponent.subtitle?.match(exp);

                if (expResult?.[1]) {
                    page.lastDate = expResult[1].trim();
                }
            }
        }
    }

    /**
     * Find first html component on each page and move as a very first
     * component on the page
     */
    public [DashboardMigration.MoveIntroText](schema: DashboardSchema): void {
        // Assuming sidebar-view is always a top-level component
        if (!schema.layout || schema.layout.component !== SidebarViewComponentType) {
            return;
        }

        const sidebarView = schema.layout as SidebarViewComponentDef;

        for (const section of sidebarView.sections) {
            for (const page of section.pages) {
                if (page.content.component === FlexComponentType) {
                    const flex = page.content as FlexComponentDef;

                    // Find HTML component and remove it from the flex
                    const firstHtml = flex.children.find((child, i, arr) => {
                        if (child.content.component === HtmlComponentType) {
                            // Remove the original component
                            arr.splice(i, 1);
                            return true;
                        } else {
                            return false;
                        }
                    });

                    if (firstHtml) {
                        flex.children.unshift(firstHtml);
                    }
                }
            }
        }
    }

    /**
     * Merge sidebar-view pages with trailing number if the rest of the name
     * matches
     */
    public [DashboardMigration.MergeSplitPages](schema: DashboardSchema): void {
        // Assuming sidebar-view is always a top-level component
        if (!schema.layout || schema.layout.component !== SidebarViewComponentType) {
            return;
        }

        const sidebarView = schema.layout as SidebarViewComponentDef;
        const splitPageExp = /^(.+)( +\d)$/;

        for (const section of sidebarView.sections) {
            const mainPages: Map<string, PageDefinition> = new Map();

            for (const page of section.pages) {
                const splitPage = page.title.match(splitPageExp);
                const pageBaseName = splitPage?.[1].trim() || page.title;
                const pageNumber = (splitPage?.[2] || "1").trim();

                assert(pageBaseName, "Page without name?");

                if (pageNumber === "1") {
                    // First page => rename it and store it
                    page.title = pageBaseName;
                    mainPages.set(pageBaseName, page);
                } else {
                    // nth page => merge with the first one
                    const basePage = mainPages.get(pageBaseName);
                    assert(basePage, "Cannot merge: pages not in order");

                    if (
                        basePage.content.component === FlexComponentType
                        && page.content.component === FlexComponentType
                    ) {
                        const basePageContent = basePage.content as FlexComponentDef;
                        const pageContent = page.content as FlexComponentDef;

                        basePageContent.children.push(...pageContent.children);
                    } else {
                        assert(false, "Cannot merge pages without flex child");
                    }
                }
            }

            section.pages = [...mainPages.values()];
        }
    }

    private flattenFlexComponents(
        def: ComponentDef,
        isFlexChild: boolean = false,
    ): ComponentDef[] {
        const isFlex = def.component === FlexComponentType;

        if (isFlex && !isFlexChild) {
            // We will fill all flex children into this component
            const flexComp = def as FlexComponentDef;

            flexComp.direction = "column";

            flexComp.children = flexComp.children
                .flatMap(child => this.flattenFlexComponents(child.content, true))
                .map(content => ({ content }));

            return [flexComp];
        } else if (isFlex && isFlexChild) {
            // Superfluous flex layer, get rid of this component by return its
            // children
            const flexComp = def as FlexComponentDef;

            return flexComp.children
                .flatMap(child => this.flattenFlexComponents(child.content, true));
        } else {
            const options = this.moduleRegistryService.getComponentOptions(def);

            if (options.getChildComponents) {
                options.getChildComponents(def)
                    .flatMap(childDef => this.flattenFlexComponents(childDef, false));
            }

            return [def];
        }
    }
}

export type MigrationMethod = (schema: DashboardSchema) => void;
