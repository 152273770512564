import { Injectable } from "@angular/core";
import { makeObservable } from "mobx";

import { computed } from "skCommon/state/mobxUtils";
import { RouterStore } from "skCommon/angular/router.store";
import { extendError } from "skCommon/core/error";
import { Svg } from "skCommon/svg";
import { Logger } from "skCommon/utils/logger";
import { Insights } from "skCommon/permissions";
import { serializeDashboard } from "skCommon/insights/parsedDashboard";

import { DashboardRoutes } from "skInsights/dashboard/dashboard.routing";
import { DashboardService } from "skInsights/dashboard/dashboard.service";
import { DashboardViewService } from "skInsights/dashboard/dashboardView/dashboardView.service";
import { AvailabilityService } from "skInsights/framework/availability.service";
import { InsightsService } from "skInsights/insights.service";
import { MainNavEntry, MainNavEntryProvider } from "skInsights/nav/nav";
import { UserService } from "skInsights/user/user.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";

const COPYING = Symbol();

/**
 * Service providing dashboard related buttons into main menu
 */
@Injectable()
export class DashboardMainNavService implements MainNavEntryProvider {

    @computed
    public get canCopy(): boolean {
        return this.userService.loggedIn
            && this.userService.permissions.has(Insights.Dashboard.Copy)
            && !!this.dashboardViewService.dashboard;
    }

    @computed
    public get canEdit(): boolean {
        return !!this.dashboardViewService.dashboard
            && this.availabilityService.isEditable(this.dashboardViewService.dashboard);
    }

    public get editUrl(): string[] {
        const { dashboard } = this.dashboardViewService;

        if (dashboard) {
            return ["/", DashboardRoutes.EditDashboard, dashboard.id];
        } else {
            return [];
        }
    }

    @computed
    public get navEntries(): MainNavEntry[] {
        const out: MainNavEntry[] = [];

        if (this.canCopy) {
            out.push({
                icon: Svg.Copy,
                label: "Copy dashboard",
                onClick: () => this.copyDashboard(),
            });
        }

        if (this.canEdit) {
            out.push({
                icon: Svg.Edit,
                label: "Edit Dashboard",
                link: this.editUrl,
            });
        }

        return out;
    }

    constructor(
        private userService: UserService,
        private dashboardViewService: DashboardViewService,
        private dashboardService: DashboardService,
        private snackBarService: SnackBarService,
        private logger: Logger,
        private insightsService: InsightsService,
        private routerStore: RouterStore,
        private availabilityService: AvailabilityService,
    ) {
        makeObservable(this);
    }


    public async copyDashboard(): Promise<void> {
        if (!this.dashboardViewService.dashboard) {
            return;
        }

        try {
            this.insightsService.globalLoadingProcesses.add(COPYING);
            const copy = this.dashboardService.copyDashboard(
                this.dashboardViewService.dashboard,
            );

            copy.meta.name += " (copy)";

            const id = await this.dashboardService
                .createDashboard(serializeDashboard(copy));

            this.routerStore.navigate([DashboardRoutes.EditDashboard, id]);
        } catch (e) {
            this.logger.error(extendError(e, "Copy dashboard"));
            this.snackBarService.notify(`Could not copy dashboard: ${e.message}`);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(COPYING);
        }
    }
}
