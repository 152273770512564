import { Component, ChangeDetectionStrategy, Injector } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { observableRef } from "skCommon/state/mobxUtils";
import { Logger } from "skCommon/utils/logger";

import { GlobalConstantsSerivce } from "skInsights/dashboard/globalConstants.service";
import { DashboardRef } from "skInsights/framework/dashboardData";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { BuilderService } from "skInsights/framework/builder.service";
import { RenderableComponent } from "skInsights/framework/renderable";
import { DashboardViewService } from "skInsights/dashboard/dashboardView/dashboardView.service";
import { DashboardContext } from "skInsights/framework/abstract/dashboardContext";
import { AvailabilityService } from "skInsights/framework/availability.service";

@Component({
    selector: "sk-dashboard-view",
    templateUrl: "./dashboardView.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardViewComponent extends BaseComponent {

    @observableRef
    public loading: boolean = false;

    @observableRef
    public root?: RenderableComponent;

    constructor(
        private activatedRoute: ActivatedRoute,
        private dashboardViewService: DashboardViewService,
        private snackBarService: SnackBarService,
        private logger: Logger,
        private injector: Injector,
        private globalConstantsSerivce: GlobalConstantsSerivce,
        private builderService: BuilderService,
        private availabilityService: AvailabilityService,
    ) {
        super();

        makeObservable(this);

        this.addSubscription(this.activatedRoute.data.subscribe(data => {
            this.prepareDashboard(data as DashboardViewComponentData);
        }));
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();

        this.dashboardViewService.dashboard = undefined;
    }

    public async prepareDashboard({ dashboardRef }: DashboardViewComponentData): Promise<void> {
        this.logger.info(`Opening dashboard ${dashboardRef.id}`);

        try {
            this.loading = true;

            const dashboard = await this.dashboardViewService.updateDashboard(dashboardRef);

            this.root = await this.builderService.build({
                schema: dashboard.schema,
                constants: this.globalConstantsSerivce.getConstants(dashboard),
                parent: Injector.create({
                    providers: [
                        {
                            provide: Logger,
                            useValue: this.logger.extend({
                                dashboard: dashboardRef.id,
                            }),
                        },
                    ],
                    parent: this.injector,
                }),
                context: new DashboardContext({
                    exportable: this.availabilityService.isExportable(dashboard),
                }),
            });

            this.logger.info(`Dashboard loaded ${dashboardRef.id}`);
        } catch (e) {
            this.snackBarService.notify(`Dashboard could not be loaded: ${e.message}`);
            this.logger.error(e, "Preparing dashboard");
        } finally {
            this.loading = false;
        }
    }
}

export interface DashboardViewComponentData {
    dashboardRef: DashboardRef;
}
