import { ElementRef, Injectable, Injector } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { observable, makeObservable } from "mobx";

import { ExpandedCardComponent, ExpandedCardData } from "skInsights/framework/card/expandedCard.component";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { ViewportService } from "skInsights/helpers/viewport.service";
import { PopupService } from "skInsights/partials/popup/popup.service";

/**
 * Service encapsulating and providing all functionality requierd by the
 * generic card components, so that the extended CardComponent doesn't require
 * more than one injection from parent component.
 */
@Injectable({ providedIn: "any" })
export class CardService {

    @observable.ref
    private expanded?: ExpandedCard;

    public get isExpanded(): boolean {
        return !!this.expanded;
    }

    constructor(
        public viewportService: ViewportService,
        public dataRefService: DataRefService,
        public popupService: PopupService,
        public injector: Injector,
        private matDialog: MatDialog,
        private elementRef: ElementRef<HTMLElement>,
    ) {
        makeObservable(this);
    }

    public async expand(): Promise<void> {
        await this.collapse();

        const cardElement = this.elementRef.nativeElement;
        const parentElement = cardElement.parentElement as HTMLElement;

        const placeholderElement = this.replaceElementWithPlaceholder(cardElement);

        const dialogRef = this.matDialog.open<ExpandedCardComponent, ExpandedCardData>(
            ExpandedCardComponent, {
                data: {
                    element: cardElement,
                },
                width: "90vw",
                height: "80vh",
                maxWidth: "90vw",
                panelClass: "expanded-card-dialog",
            },
        );

        this.expanded = {
            dialogRef,
            parentElement,
            cardElement,
            placeholderElement,
        };

        this.registerOnClose(this.expanded);
    }

    public collapse(): void {
        if (!this.expanded) {
            return;
        }

        this.expanded.dialogRef.close();
    }

    private returnExpandedElement(): void {
        if (!this.expanded) {
            return;
        }

        this.expanded.placeholderElement.replaceWith(this.expanded.cardElement);
        this.expanded = void 0;
    }


    private registerOnClose(card: ExpandedCard): void {
        card.dialogRef.afterClosed().subscribe(() => this.returnExpandedElement());
    }

    /**
     * Create a placeholder which should prevent any change in the dashboard
     * layout when the original elment is removed.
     *
     * Unfortunately I wasn't able to find a way to achieve that by using
     * a single div with hard-set dimensions due to some flex magic so for now
     * a deep DOM copy is used which works well but is not very performant
     * solution.
     */
    private replaceElementWithPlaceholder(element: HTMLElement): HTMLElement {
        const placeholder = element.cloneNode(true) as HTMLElement;

        // Remove IDs in placeholder so they are still unique
        const childIterator = document.createNodeIterator(placeholder, NodeFilter.SHOW_ELEMENT);
        let node: HTMLElement | null;

        while (node = childIterator.nextNode() as HTMLElement)  {
            node.id = "";
        }

        placeholder.style.visibility = "hidden";

        element.replaceWith(placeholder);

        return placeholder;
    }
}

interface ExpandedCard {
    parentElement: HTMLElement;
    cardElement: HTMLElement;
    placeholderElement: HTMLElement;
    dialogRef: MatDialogRef<ExpandedCardComponent, ExpandedCardData>;
}
