import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";

export class DashboardEvents {

    private events = new Subject<DashboardEvent>();

    public emit(e: DashboardEvent): void {
        this.events.next(e);
    }

    public listen<T extends DashboardEvent>(
        componentRef: string,
    ): Observable<T> {
        return this.events.pipe(
            filter(e => e.component === componentRef),
        ) as Observable<T>;
    }
}

export interface DashboardEvent {
    component: string;
    event: string;
    data?: string;
}
