import {
    AuthenticatorInterface,
    AuthenticationType,
    AuthenticationData,
    getDefaultAuthentication,
} from "skCommon/core/authentication";
import { ClientOptions } from "skCommon/api/client/simple";
import { userClient } from "skCommon/user/client";
import { parseJwtPayload } from "skCommon/utils/jwt";

export const MANAGER_AUTH = "auth0manager";

export class ManagerAuthenticator implements AuthenticatorInterface<Auth0ManagerData> {
    public authType: AuthenticationType = AuthenticationType.Options;

    constructor() {
        getDefaultAuthentication().registerAuthenticator(MANAGER_AUTH, this);
    }

    public async validateAuth(authData: Auth0ManagerData): Promise<boolean> {
        if (authData.expiryTimestamp < Date.now()) {
            return false;
        }

        return true;
    }

    public async renewAuth(): Promise<Auth0ManagerData> {
        const tokenData = await userClient.getManagementToken();
        return {
            data: {
                token: tokenData.authorization,
            },
            expiryTimestamp: parseJwtPayload<Auth0TokenData>(tokenData.authorization)
                .exp * 1000,
            loginTimestamp: Date.now(),
        };
    }

    public revokeAuth(): Promise<boolean> {
        // Auth0 management token cannot be revoked
        return Promise.resolve(true);
    }

    public setRequestOptions?<O extends ClientOptions>(
        _api: string,
        options: O,
        authData: Auth0ManagerData,
    ): void {
        if (!options.headers) {
            options.headers = new Headers();
        }

        options.headers.set(
            "Authorization", authData.data.token,
        );
    }

    /**
     * Makes sure that the auth data is loaded.
     */
    public async loadAuth() {
        const at = getDefaultAuthentication();
        if (at.hasAuthenticationData(MANAGER_AUTH)) {
            const aData =
                at.getAuthenticationData(MANAGER_AUTH) as Auth0ManagerData;
            if (!await this.validateAuth(aData)) {
                at.renew(MANAGER_AUTH);
            }
        } else {
            const aData = await this.renewAuth();
            at.setAuthenticationData(MANAGER_AUTH, aData);
        }
    }

}

let defManager: ManagerAuthenticator;

export function getManagerAuth() {
    if (!defManager) {
        defManager = new ManagerAuthenticator();
    }
    return defManager;
}

type Auth0ManagerData = AuthenticationData<{ token: string }>;


interface Auth0TokenData {
    iss: string;
    sub: string;
    aud: string;
    /**
     * Issue timestamp
     */
    iat: number;
    /**
     * Expiry timestamp
     */
    exp: number;
    azp: string;
    scope: string;
    gty: string;
}
