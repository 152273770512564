import { Injectable, Injector, Type } from "@angular/core";

/**
 * We often want to inject another service with the current injector rather
 * rather than the global one, so this service offers a helper, so we don't
 * need to create child injector manually.
 */
@Injectable({ providedIn: "root" })
export class ReinjectorService {

    public get<T>(parent: Injector, Service: Type<T>): T {
        const childInjector = Injector.create({
            providers: [{
                provide: Service,
            }],
            parent,
        });

        return childInjector.get(Service);
    }
}
