import { NgModule } from "@angular/core";

import { LoadingDirective } from "skCommon/angular/partials/loading/loading.directive";
import { SharedModule } from "skCommon/angular/shared.module";
import { LoadingStore } from "skCommon/angular/partials/loading/loading.store";
import { LoadingService } from "skCommon/angular/partials/loading/loading.service";

@NgModule({
    imports: [
        SharedModule,
    ],
    declarations: [
        LoadingDirective,
    ],
    providers: [
        LoadingStore,
        LoadingService,
    ],
    exports: [
        LoadingDirective,
    ],
})
export class LoadingModule { }
