import {
    CommonModule,
} from "@angular/common";
import {
    NgModule,
} from "@angular/core";

import {
    SharedModule,
} from "skCommon/angular/shared.module";
import {
    DateRangePickerDialogComponent,
} from "skCommon/dateRangePicker/dialog/dateRangePickerDialog.component";
import {
    DatePickerModule,
} from "skCommon/datePicker/datePicker.module";

@NgModule({
    declarations: [
        DateRangePickerDialogComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        DatePickerModule,
    ],
    exports: [
        DateRangePickerDialogComponent,
    ],
})
export class DateRangePickerDialogModule {}
