/**
 * Replace whitespaces with underscore and remove all non-word characters except
 * dashes as periods.
 */
export function sanitizeFilename(filename: string) {
    return filename
        .replace(/\s+/g, "_")
        .replace(/[^a-zA-Z0-9_\-\.]/g, "")
        .toLowerCase();
}
