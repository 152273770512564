import { InjectionToken } from "@angular/core";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { generateUniqueId } from "skCommon/utils/uniqueId";
import { ComponentDef } from "skCommon/insights/dashboard";

export const LAYOUT_COMPONENT_DEF_TOKEN = new InjectionToken<ComponentDef>(
    "Definition of this layout component",
);

export abstract class LayoutComponent<TDef extends ComponentDef> extends BaseComponent {

    protected readonly abstract def: TDef;

    protected readonly instanceId: string = generateUniqueId();

    protected get ref(): string {
        return this.def.ref || this.instanceId;
    }

    constructor() {
        super();
    }
}
