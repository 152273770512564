import { Component, Input, HostBinding } from "@angular/core";

@Component({
    // tslint:disable-next-line:component-selector
    selector: "[skLoading]",
    templateUrl: "./loading.pug",
    styleUrls: ["./loading.scss"],
})
// tslint:disable-next-line:component-class-suffix
export class LoadingDirective {
    @Input()
    @HostBinding("class.loading")
    public skLoading: boolean;
}

