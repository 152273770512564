import { NgModule } from "@angular/core";

import { SharedModule } from "skCommon/angular/shared.module";
import { AutorunModule } from "skCommon/angular/state/autorun.module";
import { ChartComponent } from "skCommon/chart/angular/chart.component";

@NgModule({
    imports: [
        SharedModule,
        AutorunModule,
    ],
    declarations: [
        ChartComponent,
    ],
    exports: [
        ChartComponent,
    ],
})
export class ChartModule { }
