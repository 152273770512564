import {
    Component,
    ChangeDetectionStrategy,
    Inject,
    AfterViewInit,
    Injector,
    ViewChild,
} from "@angular/core";
import download from "downloadjs";
import { when, makeObservable } from "mobx";
import { MatMenu } from "@angular/material/menu";

import { ChartContext } from "skCommon/chart/chartContext";
import { ChartExportService } from "skCommon/chart/chartExport.service";
import { AnnotationClickEvent, LineAnnotationElement, AnnotationPlugin } from "skCommon/chart/plugins/annotation";
import { computed, observableRef } from "skCommon/state/mobxUtils";
import { Logger } from "skCommon/utils/logger";
import { ObservableDatasets } from "skCommon/chart/plugins/observableDatasets";
import { SeriesGroups } from "skCommon/chart/plugins/seriesGroups";
import { generateUniqueId } from "skCommon/utils/uniqueId";
import { getCountry } from "skCommon/flags";
import { assert } from "skCommon/utils/assert";
import { exists } from "skCommon/utils/types";
import "skCommon/extensions/array.prototype.at";
import { sortByKey } from "skCommon/utils/sort";

import { LAYOUT_COMPONENT_DEF_TOKEN } from "skInsights/framework/abstract/layoutComponent";
import { DataCopyright } from "skInsights/framework/data/structures";
import { DashboardEvents } from "skInsights/framework/dashboardEvents";
import { PopupService, PopupPosition } from "skInsights/partials/popup/popup.service";
import { ChartRipplesComponent } from "skInsights/modules/charts/lineChart/misc/chartRipples.component";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { CardComponent } from "skInsights/framework/card/cardComponent";
import { parseDateString } from "skInsights/utils/dateString";
import { DashboardButton } from "skInsights/partials/buttons/button";
import { DashboardExportService, ExportableSeries } from "skInsights/helpers/dashboardExport.service";
import { CardService } from "skInsights/framework/card/card.service";
import { DashboardContext } from "skInsights/framework/abstract/dashboardContext";
import { ZoomPeriodDef } from "skInsights/partials/zoomButton/zoomPeriod";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { ChartBuilderService } from "skInsights/modules/charts/helpers/chartBuilder.service";
import { ChartDatasetInput, ChartInputSeries, ChartSeriesDef, EventsState, LineChartComponentDef } from "skInsights/modules/charts/lineChart/definitions";

export const LineChartComponentType: LineChartComponentType = "charts/line-chart";
export type LineChartComponentType = "charts/line-chart";

@Component({
    selector: "sk-charts-line-chart",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./lineChart.pug",
    styleUrls: ["./lineChart.scss"],
    providers: [CardService, ChartBuilderService],
})
export class LineChartComponent
        extends CardComponent<LineChartComponentDef>
        implements AfterViewInit {

    @observableRef
    public chartContext?: ChartContext & ObservableDatasets & AnnotationPlugin & SeriesGroups;

    @ViewChild(ChartRipplesComponent)
    public chartRipples!: ChartRipplesComponent;


    @observableRef
    @ViewChild("downloadMenu", { read: MatMenu })
    public downloadMenuMatMenu!: MatMenu;

    @observableRef
    public eventsEnabled: boolean;

    @observableRef
    public id: string;

    public eventsState: EventsState;

    /**
     * Chart series mapped to the index of the original dataset definition
     */
    public datasets: ChartDatasetInput[] = [];

    protected expandable = true;

    public get currentTab(): string | void {
        return this.chartContext?.currentGroup;
    }

    public get tabs(): TabHeader[] | void {
        return this.def.tabs
            ? Object.entries(this.def.tabs).map(([id, t]) => {
                const flagUrl = t.icon && getCountry(t.icon)?.iconPath;

                return {
                    id,
                    name: t.name,
                    flagUrl,
                    icon: !flagUrl && t.icon ? t.icon : void 0,
                };
            }) : void 0;
    }

    @computed
    public get legend(): LegendItem[] {
        const ctx = this.chartContext;

        if (!ctx?.ready) {
            return [];
        }

        return this.datasets
            .filter(({ inputSeries }) => ctx.isDisplayedByRefs(inputSeries.main.options.refs))
            .map(({ def, inputSeries }) => ({
                visible: ctx.isVisibleByRefs(inputSeries.main.options.refs),
                label: inputSeries.main.options.label,
                ref: inputSeries.main.options.refs.at(-1),
                color: ctx.getColorByRef(inputSeries.main.options.refs[0]),
                aux: inputSeries.aux ? {
                    ref: inputSeries.aux.options.refs.at(-1),
                    name: inputSeries.aux.series.meta.name,
                    icon: inputSeries.aux.series.meta.icon,
                    visible: ctx.isVisibleByRefs(inputSeries.aux.options.refs),
                } : undefined,
                detail: def.detail,
                copyright: inputSeries.main.series.meta.copyright,
                rightAxis: def.scale
                    ? ctx.chart?.scales[def.scale]?.position === "right"
                    : false,
            }))
            .sort(sortByKey("rightAxis"));
    }

    public get csvDownloadAvailable(): boolean {
        return this.dashboardContext.exportable
            && this.exportableInputs.length > 0;
    }

    @computed
    public get hoveredEvent(): { style: Partial<CSSStyleDeclaration>, label: string } | void {
        if (!this.chartContext) {
            return;
        }

        const annotation = this.chartContext.hoveredAnnotation;

        if (annotation && annotation.value && annotation.tooltip) {
            const date = annotation.value as Date;
            const left = this.chartContext.chart?.scales.x.getPixelForValue(+date, undefined!)
                || 0;

            return {
                style: {
                    left: `${left}px`,
                },
                label: annotation.tooltip,
            };
        }
    }

    public get defaultZoomPeriod(): ZoomPeriodDef | undefined {
        if (this.def.dateRange) {
            return {
                min: this.def.dateRange.min
                    ? parseDateString(this.def.dateRange.min)
                    : void 0,
                max: this.def.dateRange.max
                    ? parseDateString(this.def.dateRange.max)
                    : void 0,
            };
        } else {
            return void 0;
        }
    }

    public override get cardButtonsLoading(): boolean {
        return !this.chartContext;
    }

    @computed
    protected get cardButtons(): DashboardButton[] {
        const out: DashboardButton[] = [{
            icon: "download",
            text: "Download as...",
            menu: this.downloadMenuMatMenu,
        }];

        if (this.showEventsToggle) {
            out.push({
                icon: "stock",
                text: this.eventsEnabled ? "Hide events" : "Show events",
                onClick: () => this.toggleEvents(),
                accent: "primary",
                toggled: !!this.eventsEnabled,
            });
        }

        return out;
    }

    private get showEventsToggle(): boolean {
        return !!this.def.events
            && Object.values(this.def.events).length > 0
            && this.eventsState !== EventsState.Always;
    }

    @computed
    private get exportableInputs(): { inputSeries: ChartInputSeries; def: ChartSeriesDef; }[] {
        const ctx = this.chartContext;

        if (!ctx?.ready) {
            return [];
        }

        const flattenedSeriesWithDef = this.datasets
            .filter(({ inputSeries }) => ctx.isDisplayedByRefs(inputSeries.main.options.refs))
            .flatMap(dataset => (
                [dataset.inputSeries.main, dataset.inputSeries.aux]
                    .filter(exists)
                    .map(inputSeries => ({
                        inputSeries,
                        def: dataset.def,
                    }))
            ));

        return flattenedSeriesWithDef
            .filter(({ inputSeries }) => ctx.isVisibleByRefs(inputSeries.options.refs))
            .filter(({ inputSeries }) => !inputSeries.series.meta.viewOnly);
    }

    constructor(
        @Inject(LAYOUT_COMPONENT_DEF_TOKEN)
        protected readonly def: LineChartComponentDef,
        private dataRefService: DataRefService,
        private dashbaordEvents: DashboardEvents,
        private logger: Logger,
        private popupService: PopupService,
        private injector: Injector,
        private chartExportService: ChartExportService,
        private dashboardExportService: DashboardExportService,
        private dashboardContext: DashboardContext,
        private snackBarService: SnackBarService,
        private chartBuilderService: ChartBuilderService,
        cardService: CardService,
    ) {
        super(cardService);

        makeObservable(this);

        this.eventsState = this.def.eventsState || EventsState.Enabled;

        this.eventsEnabled = this.eventsState === EventsState.Always
            || this.eventsState === EventsState.Enabled;

        this.initialize().catch(e => {
            this.logger.error(e, "Chart initialization");
            this.snackBarService.notify(`Chart initialization failed: ${e}`);
        });
        this.id = this.def.template?.title ?? "";
    }

    public toggleSeries(seriesRef: string): void {
        this.dashbaordEvents.emit({
            component: this.ref,
            event: "toggle",
            data: seriesRef,
        });
    }

    public toggleEvents(): void {
        this.eventsEnabled = !this.eventsEnabled;
    }

    public downloadPdf(): void {
        this.chartExportService.exportAsPdf(
            [this.chartContext!],
            this.cardTitle || "",
        );
    }

    public async downloadPng(): Promise<void> {
        const png = await this.chartContext!.exportAsPng(2000, 1400);
        download(png, this.cardTitle || `dashboard-data-${generateUniqueId()}.png`);
    }

    public async downloadCsv(): Promise<void> {
        const exportables = await this.getExportableSeriesData();
        this.dashboardExportService.downloadSeriesAsCsv(
            exportables,
            this.cardTitle || "chart",
        );
    }

    public openTab(tabId: string): void {
        this.chartContext!.showGroup(tabId);
    }

    private async initialize(): Promise<void> {
        this.datasets = await this.chartBuilderService.loadDatasets(this.def);
        this.chartContext = await this.chartBuilderService.createChart(this.def, this.datasets);

        this.chartContext.onAnnotationClick(e => this.openDialog(e));

        this.addSubscription(
            this.dashbaordEvents.listen<LineChartEvent>(this.ref)
                .subscribe(e => this.handleEvents(e)),
        );

        this.observeEventsEnabled();
        this.observeExpanded();
    }

    private openDialog(e: AnnotationClickEvent): void {
        const eventId = e.annotationId;
        const id = `line-chart[${this.ref}][${eventId}]`;
        const chartEvent = this.def.events![eventId];

        const position = this.getEventDialogPosition(e.element);

        if (chartEvent && chartEvent.detail) {
            this.popupService.open(this.injector, chartEvent.detail, { id, position });
        }
    }

    private getEventDialogPosition(el: LineAnnotationElement): PopupPosition {
        const x = el.x;
        const y = el.labelRect.y + el.labelRect.height + 16;

        const screenPos = this.chartContext!.projectToScreen({ x, y });

        return {
            left: screenPos.x,
            top: screenPos.y,
        };
    }

    private handleEvents(e: LineChartEvent): void {
        if (!this.chartContext) {
            return;
        }

        switch (e.event) {
            case "highlight":
                const target = this.chartContext.findAnnotation(e.data);

                if (target) {
                    this.highlightEvent(e.data);
                } else {
                    this.logger.warning(`Trying to highlight non-existent event ${e.data}`);
                }
                break;
            case "hide":
                this.chartContext.hideDataset(e.data);
                break;
            case "show":
                this.chartContext.showDataset(e.data);
                break;
            case "toggle":
                this.chartContext.toggleDataset(e.data);
                break;
            default:
                this.logger.warning(`Unknown charts/line-chart event ${e.event}`);
        }
    }

    private async highlightEvent(eventId: string): Promise<void> {
        if (this.chartContext?.chart) {
            const target = this.chartContext!.findAnnotation(eventId);
            const color = target.borderColor;
            const date = target.value;

            const left = (this.chartContext!.chart?.scales.x as any).getPixelForValue(+date);

            this.eventsEnabled = true;
            this.chartRipples.showRipple({ left, color });
        }
    }

    private async observeEventsEnabled(): Promise<void> {
        await when(() => !!this.chartContext?.options);

        this.reaction(() => this.eventsEnabled, () => {
            const anns = this.chartContext!.options.plugins!.annotation.annotations;

            for (const ann of anns) {
                if (this.eventsEnabled) {
                    ann.display = ann._originalDisplay;
                } else {
                    ann._originalDisplay = ann.display;
                    ann.display = false;
                }
            }

            this.chartContext!.update();
        }, { fireImmediately: true });
    }

    private observeExpanded() {
        this.reaction(() => this.cardExpanded, expanded => {
            assert(this.chartContext, "Observe called before chart created");
            assert(this.chartContext.chart?.options.plugins?.zoom, "Zoom is not enabled");

            this.chartContext.chart.options.plugins.zoom.zoom.ignore = !expanded;
            this.chartContext.update();
        });
    }

    private getExportableSeriesData(): Promise<ExportableSeries[]> {
        return Promise.all(
            this.exportableInputs.map(async ({ inputSeries, def }, i) => ({
                series: inputSeries.series,
                name: await this.dataRefService.interpolate(
                    def.exportLabel || def.label || `Series ${i}`,
                ),
            })),
        );
    }
}

interface LineChartEvent {
    component: string;
    event: "highlight" | "show" | "hide" | "toggle";
    data: string;
}

interface LegendItem {
    label: string;
    visible: boolean;
    ref: string;
    color: string;
    aux?: LegendItemAuxInfo;
    detail?: string;
    copyright?: DataCopyright;
}

interface LegendItemAuxInfo {
    ref: string;
    name: string;
    visible: boolean;
    icon?: string;
}

interface TabHeader {
    id: string;
    name: string;
    icon?: string;
    flagUrl?: string;
}
