let crypto: Crypto;

export function generateUniqueId(numberOfBytes = 6, radix = 36) {
    if (!crypto) {
        crypto = typeof window !== "undefined"
            ? (window.crypto || (<any>window).msCrypto)
            // tslint:disable-next-line: no-eval
            : eval("require")("isomorphic-webcrypto");
    }

    const randomBytes = new Uint16Array(numberOfBytes);
    crypto.getRandomValues(randomBytes);

    let uuid = Date.now().toString(radix);

    randomBytes.forEach((byte) => {
        const byteString = byte.toString(radix);
        uuid += byteString;
    });

    return uuid;
}
