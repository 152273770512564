/**
 * Abstract class for loader raw font files for locally available fonts.
 *
 * In generic services etc. use this class and let the application provide the
 * correct implementation, sice we need to load the fonts in different way
 * depending on the envrionment.
 */
export abstract class FontLoaderService {

    public abstract loadFont(fontName: LocalFont): Promise<ArrayBuffer>;
}

export enum LocalFont {
    RobotoBold,
    RobotoMedium,
    RobotoRegular,
    RobotoLight,
    RobotoItalic,
    RobotoLightItalic,
    RobotoMediumItalic,
}
