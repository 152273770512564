// tslint:disable:no-string-literal
import { Map as MapboxMap } from "mapbox-gl";

/**
 * Patch mapbox's private method that created DOM elements to actually use
 * elements that are part of the template.
 *
 * This allows us to easily inject components into the mapbox context, so we
 * can do:
 * ```pug
 *  sk-mapbox
 *      sk-some-component
 * ```
 * And the sk-some-component will be created in the same container as the
 * mapbox's canvas, which is useful for displaying tooltips and other stuff we
 * don't want to use gl rendering for.
 */
export function patchMapboxForAngular(): void {
    if (!MapboxMap.prototype["__skOldSetupContainer"]) {
        MapboxMap.prototype["__skOldSetupContainer"] = MapboxMap.prototype["_setupContainer"];
        MapboxMap.prototype["_setupContainer"] = patchedSetupContainer;
    }

    function patchedSetupContainer(this: any) {
        const canvas = this._container.querySelector(".mapboxgl-canvas");

        this._angularCanvas = canvas;
        this._angularCanvasContainer = canvas.parentElement;

        // Make the properties always return the elements from angular template
        Object.defineProperties(this, {
            _canvas: {
                set: () => {},
                get: function () {
                    return this._angularCanvas;
                },
            },
            _canvasContainer: {
                set: () => {},
                get: function () {
                    return this._angularCanvasContainer;
                },
            },
        });

        this.__skOldSetupContainer.call(this);

        // The mapboxgl-interactive isn't really used anywhere and we can use
        // it to find the mapbox's created canvas-container, which isn't really
        // used, but we cannot stop mapbox from creating it, even when we
        // provide it already existing canvas-container.
        this._container.removeChild(
            this._container.querySelector(".mapboxgl-interactive"),
        );
    }
}

export function restoreMapbox() {
    if (MapboxMap.prototype["__skOldSetupContainer"]) {
        MapboxMap.prototype["_setupContainer"] = MapboxMap.prototype["__skOldSetupContainer"];
    }
}
