import { Injectable, Injector } from "@angular/core";

import { ClientDelivery, CsvExportType } from "skInsights/clientDelivery/clientDelivery";
import { UglyDeliveryService } from "skInsights/clientDelivery/uglyDelivery.service";
import { DashboardService } from "skInsights/dashboard/dashboard.service";
import { GlobalConstantsSerivce } from "skInsights/dashboard/globalConstants.service";
import { BuilderService } from "skInsights/framework/builder.service";
import { DashboardData } from "skInsights/framework/dashboardData";
import { ChartBuilderService } from "skInsights/modules/charts/helpers/chartBuilder.service";

@Injectable({ providedIn: "root" })
export class ClientDeliveryReportService {

    constructor(
        private builderService: BuilderService,
        private dashboardService: DashboardService,
        private globalConstantsSerivce: GlobalConstantsSerivce,
    ) { }

    /**
     * Create and download PDF or CSV delivery report for the provided definition
     */
    public async runDelivery(delivery: ClientDelivery, csvType?: CsvExportType): Promise<void> {
        const injector = await this.getInjectorForDashboard(delivery.sourceDashboardId);
        const uglyDeliveryService = injector.get(UglyDeliveryService);

        if (csvType) {
            await uglyDeliveryService.downloadCsvDelivery(delivery, csvType);
        } else {
            await uglyDeliveryService.downloadPdfDelivery(delivery);
        }
    }

    /**
     * Create injector with given dashboard provided as context so serivces may
     * access its data.
     */
    private async getInjectorForDashboard(dbId: string): Promise<Injector> {
        const db = await this.dashboardService.loadDashboardRef({ id: dbId });
        const built = await this.builderService.build({
            schema: db.schema,
            constants: this.globalConstantsSerivce.getConstants(db),
            providers: [
                {
                    provide: UglyDeliveryService,
                    useClass: UglyDeliveryService,
                },
                {
                    provide: ChartBuilderService,
                    useClass: ChartBuilderService,
                },
            ],
        });

        const dbData = built.injector.get(DashboardData);
        dbData.preloadAllSources();

        return built.injector;
    }
}
