import { Inject, Injectable, Optional, Type } from "@angular/core";

import { ComponentDef } from "skCommon/insights/dashboard";

import { JsonDesignerComponent } from "skInsights/framework/designer/fallback/jsonDesigner.component";
import { DesignerClassDefinition, DESIGNERS_TOKEN } from "skInsights/framework/designer/provider";

/**
 * Registry of all available designer components.
 */
@Injectable({ providedIn: "root" })
export class DesignerRegistryService {

    private components: Map<string, Type<any>> = new Map();

    constructor(
        @Optional()
        @Inject(DESIGNERS_TOKEN)
        components: DesignerClassDefinition[],
    ) {
        for (const component of (components || [])) {
            this.components.set(component.type, component.designerClass);
        }
    }

    public getComponent(def: ComponentDef): Type<any> {
        if (!this.components.has(def.component)) {
            // Fallback designer
            return JsonDesignerComponent;
        }

        return this.components.get(def.component)!;
    }
}
