/**
 * Type of the data received from data source. Different components expect
 * different forms of data and this type can be used to assert the input data
 * are of the expected type.
 */
export enum DashboardDataType {
    Series = "series",
    SeriesPair = "serier-pair",
    Collection = "collection",
    SingleValue = "single-value",
    String = "string",
}

export interface DashboardSeries {
    type: DashboardDataType.Series;
    series: SeriesDataPoint[];
    meta: DashboardMetadata;
}

export interface DashboardSeriesPair {
    type: DashboardDataType.SeriesPair;
    pair: [SeriesDataPoint[], SeriesDataPoint[]];
    meta: PairDashboardMetadata;
}

export interface DashboardCollection<T extends CollectableData> {
    type: DashboardDataType.Collection;
    set: Record<string, T>;
}

export interface DashboardSingleValue {
    type: DashboardDataType.SingleValue;
    value: number;
    meta: DashboardMetadata;
}

export interface DashboardString {
    type: DashboardDataType.String;
    text: string;
}

export interface PairDashboardMetadata extends DashboardMetadata {
    /**
     * Suffixes for both series that should be appended to the timeseries name
     * when displaying pair elements individually (e.g. when exporting into
     * table)
     */
    pairSuffix: [string, string];
}

export interface DashboardMetadata {
    /**
     * Unique identifier across all sources
     */
    uid: string;
    name: string;
    unit?: "%";
    region?: string;
    date?: Date;
    copyright?: DataCopyright;
    viewOnly?: boolean;
    icon?: string;
}

export interface DataCopyright {
    text: string;
    url?: string;
}

export interface DashboardDataTypeMap {
    [DashboardDataType.String]: DashboardString;
    [DashboardDataType.Series]: DashboardSeries;
    [DashboardDataType.SeriesPair]: DashboardSeriesPair;
    [DashboardDataType.SingleValue]: DashboardSingleValue;
    // tslint:disable-next-line: max-line-length
    [DashboardDataType.Collection]: DashboardCollection<DashboardDataTypeMap[Collectables]> | DashboardCollection<DashboardString> | DashboardCollection<DashboardSeries> | DashboardCollection<DashboardSingleValue>;
}

// tslint:disable-next-line: max-line-length
export type Collectables = DashboardDataType.String | DashboardDataType.Series | DashboardDataType.SingleValue;

export type CollectableData = DashboardDataTypeMap[Collectables];

export type AnyDashboardData = DashboardDataTypeMap[DashboardDataType];

export interface SeriesDataPoint {
    y: number;
    x: Date;
}

export type SeriesLike = DashboardSeries
    | DashboardCollection<DashboardSeries>
    | DashboardSeriesPair;
export type SingleValueLike = DashboardSingleValue | DashboardCollection<DashboardSingleValue>;
