import moment, { DurationInputArg2 } from "moment";
import { standardDeviation } from "simple-statistics";

import "skCommon/extensions/array.prototype.at";
import { parseDurationString } from "skCommon/utils/time";
import { assert } from "skCommon/utils/assert";

import { Operation, OperationDef } from "skInsights/framework/abstract/operation";
import { AnyDashboardData, SeriesDataPoint } from "skInsights/framework/data/structures";
import { transformSeries, assertSeriesLike } from "skInsights/framework/data/helpers";

export type StdDevFilterOperationType = "statistics/std-dev-filter";
export const StdDevFilterOperationType: StdDevFilterOperationType = "statistics/std-dev-filter";

/**
 * Pick single value form series converting series into single-value type.
 */
export class StdDevFilterOperation extends Operation<StdDevFilterOperationDef> {

    public readonly type = StdDevFilterOperationType;

    public execute(def: StdDevFilterOperationDef, data: AnyDashboardData): AnyDashboardData {
        assertSeriesLike(data);
        assert(
            typeof def.sampleSize === "number" && def.sampleSize > 0,
            "sampleSize needs to be non-zero number",
        );
        assert(typeof def.maxDeviation === "number", "maxDeviation needs to be a number");

        const [amount, unit] = parseDurationString(def.activeDuration);

        const res = transformSeries(data, ({ series }) => {
            const lastPoint = series.at(-1);

            assert(lastPoint, "Transforming empty timeseries");

            const applySinceDate = moment(lastPoint.x)
                .subtract(amount, unit as DurationInputArg2)
                .toDate();

            return {
                series: this.stdDevFilter(
                    series,
                    def.maxDeviation,
                    def.sampleSize,
                    applySinceDate,
                ),
            };
        });

        return res;
    }

    private stdDevFilter(
        series: SeriesDataPoint[],
        maxDeviation: number,
        sampleSize: number,
        minDate: Date,
    ): SeriesDataPoint[] {
        const out: SeriesDataPoint[] = [];

        for (const point of series) {
            if (point.x < minDate) {
                out.push(point);
            } else {
                const subset: SeriesDataPoint[] = [
                    ...out.slice(-sampleSize + 1),
                    point,
                ];
                const stdDev = standardDeviation(subset.map(p => p.y));

                if (stdDev <= maxDeviation) {
                    out.push(point);
                }
            }
        }

        return out;
    }
}

/**
 * Calculate standard deviation for number of points defined by the config from
 * the end of the series and filter points with the deviation too high.
 */
export interface StdDevFilterOperationDef extends OperationDef<StdDevFilterOperationType> {
    /**
     * Number of points to calculate the standard deviation from.
     */
    sampleSize: number;
    /**
     * Past duration since latest point in which the filtering should be active
     * defined using the Duration String.
     * Duration String consists of amount and unit optionally separated by a
     * space. Amount is any whole number and unit may be one of following:
     * years, y
     * months, M
     * weeks, w
     * days, d
     * hours, h
     * minutes, m
     * seconds, s
     * milliseconds, ms
     *
     * @example `"5d"`
     * @example `"10 months"`
     * @example `"2 y"`
     * @see https://momentjs.com/docs/#/durations/creating/
     */
    activeDuration: string;
    /**
     * Filter points with deviation higher than given number
     */
    maxDeviation: number;
}
