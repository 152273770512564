import { DataProvider } from "skInsights/framework/data/dataProvider";
import { DashboardSeries } from "skInsights/framework/data/structures";

/**
 * Data provider which stores single series and returns it on `*` query.
 */
export class SingleSeriesProvider implements DataProvider {

    constructor(
        protected series: DashboardSeries,
    ) { }

    public async select(q: string): Promise<DashboardSeries | void> {
        if (q.trim() === "*") {
            return this.series;
        }
    }
}
