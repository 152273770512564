import { ClientDelivery, CsvExportType } from "skInsights/clientDelivery/clientDelivery";

export const CHINA_DELIVERY: ClientDelivery = {
    sourceDashboardId: "IcdjEPMTx5N9x0Hf9XzM",
    title: "SpaceKnow China Nowcasting Package",
    filename: "china-delivery",
    pages: [
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125"> SK Aggregated Curated Manufacturing Index</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Aggregated Manufacturing",
                    tsName: "30 Day Aggregated",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Aggregated Curated Manufacturing Index China 30d",
                }],
                title: "SK Aggregated Curated Manufacturing Index 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Manufacturing Index</font>, the chart below displays Manufacturing Index (RHS axis) against official NBS Manufacturing PMI (LHS axis).`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Aggregated Manufacturing",
                    tsName: "China Business Confidence",
                    newTitle: "NBS Manufacturing PMI",
                    scaleRange: [30, 60],
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Aggregated Manufacturing",
                    tsName: "30 Day Aggregated",
                    newTitle: "SK Aggregated Curated Manufacturing Index 30d",
                    csvExportType: CsvExportType.NONE,
                }],
                startYear: 2017,
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Manufacturing Index</font>, the chart below compares <font color="#cc4125">SK Forecast</font> with the m-o-m official NBS Manufacturing PMI (gray line).`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Aggregated Manufacturing",
                    tsName: "China Business Confidence",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Aggregated Manufacturing",
                    tsName: "SK Forecast [China Business Confidence]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Manufacturing PMI",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Aggregated Indices:</font> <font color="#cc4125">SK Aggregated Curated Mining Index</font>,  update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Aggregated Mining",
                    tsName: "30 Day Aggregated",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Aggregated Curated Mining Index China",
                }],
                title: "SK Aggregated Curated Mining Index 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Metals Indices:</font> <font color="#cc4125">SK Steel Refineries SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Steel Refineries",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Steel Refineries SAI China 30d",
                }],
                title: "SK Steel Refineries SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Metals Indices:</font> <font color="#cc4125">SK Steel Storages SAI</font>,  update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Steel Storages",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Steel Storages SAI China 30d",
                }],
                title: "SK Steel Storages SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Metals Indices:</font> <font color="#cc4125">SK Copper Manufacturing SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Copper Manufacturing",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Copper Manufacturing SAI China 30d",
                }],
                title: "SK Copper Manufacturing SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Automotive Indices:</font> <font color="#cc4125">SK Light Vehicle Manufacturing SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Light Vehicle Manufacturing",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Light Vehicle Manufacturing SAI China 30d",
                }],
                title: "SK Light Vehicle Manufacturing SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Automotive Indices:</font> <font color="#cc4125">SK Light Vehicle Manufacturing SAI</font>, the chart below compares <font color="#cc4125">SK Forecast</font> with the m-o-m Car Production provided by the China Association of Automobile Manufacturing (gray line).`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Light Vehicle Manufacturing",
                    tsName: "China Car Production",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Light Vehicle Manufacturing",
                    tsName: "SK Forecast [China Car Production]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Car Production",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Automotive Indices:</font> <font color="#cc4125">SK Light Vehicle Distribution SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Light Vehicle Distribution",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Light Vehicle Distribution SAI China 30d",
                }],
                title: "SK Light Vehicle Distribution SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Automotive Indices:</font> <font color="#cc4125">SK Auto Parts SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Auto Parts",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Auto Parts SAI China 30d",
                }],
                title: "SK Auto Parts SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Automotive Indices:</font> <font color="#cc4125">SK Machinery Manufacturing SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Machinery Manufacturing",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Machinery Manufacturing SAI China 30d",
                }],
                title: "SK Machinery Manufacturing SAI",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Construction Indices:</font> <font color="#cc4125">SK Cement Manufacturing SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Cement Manufacturing",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Cement Manufacturing SAI China 30d",
                }],
                title: "SK Cement Manufacturing SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Transportation Indices:</font> <font color="#cc4125">Trade Indicators</font>`,
            charts: [
                {
                    dcrThreshold: 0.04,
                    text: `<font color="#1155cc">Port Containers:</font>\n<font color="#cc4125">SK Port Containers SAI,</font> update for {period},\nhigher activity = lower trade.`,
                    title: "SK Port Containers SAI 30d",
                    datasets: [{
                        chartName: "Port Containers",
                        tsName: "30 Day Rolling",
                        tabName: "SAI",
                        csvExportType: CsvExportType.DATA,
                        csvName: "SpaceKnow Port Containers SAI China 30d",
                    }],
                    startYear: 2020,
                },
                {
                    dcrThreshold: 0.04,
                    text: `<font color="#1155cc">Industrial Ports:</font>\n<font color="#cc4125">SK Industrial Ports SAI 30d,</font> update for {period},\nhigher activity = more transaction volume.`,
                    title: "SK Industrial Ports SAI 30d",
                    datasets: [{
                        chartName: "Industrial Ports",
                        tsName: "30 Day Rolling",
                        tabName: "SAI",
                        csvExportType: CsvExportType.DATA,
                        csvName: "SpaceKnow Industrial Ports SAI China 30d",
                    }],
                    startYear: 2020,
                },
            ],
        },
        {
            header: `<font color="#1155cc">Transportation Industry:</font> <font color="#cc4125">SK Passenger Airports SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Passenger Airport",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "Spaceknow Passenger Airports SAI China 30d",
                }],
                title: "SK Passenger Airports SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Transportation Industry:</font> <font color="#cc4125">SK Inland Containers SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Inland Containers",
                    tsName: "30 Day Rolling",
                    tabName: "SAI",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Inland Containers SAI China",
                }],
                title: "SK Inland Containers SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Chemical Indices:</font> <font color="#cc4125">SK Inorganic Fertilizers SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Inorganic Fertilizers",
                    tsName: "30 Day Rollings",
                    tabName: "SAI",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Inorganic Fertilizers SAI China 30d",
                }],
                title: "SK Inorganic Fertilizers SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Mining Industry:</font> <font color="#cc4125">SK Coal Mines SAI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Coal Mines",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Coal Mines SAI China 30d",
                }],
                title: "SK Coal Mines SAI 30d",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Retail Indices:</font> <font color="#cc4125">SK Retail Parking CFI</font> (no aggregation), update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Retail Parking",
                    tsName: "Daily",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Retail Parking CF Index China 1d",
                }],
                title: "SK Retail Parking CFI",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Retail Indices:</font> <font color="#cc4125">SK Retail Parking CFI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Retail Parking",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Retail Parking CF Index China 30d",
                }],
                title: "SK Retail Parking CFI",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Retail Indices:</font> <font color="#cc4125">SK Retail Metro Parking CFI</font> (no aggregation), update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Retail Metro Parking",
                    tsName: "Daily",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Retail Metro Parking CF Index China 1d",
                }],
                title: "SK Retail Metro Parking CFI",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Retail Indices:</font> <font color="#cc4125">SK Retail Metro Parking CFI</font>, update for period {period}.`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Retail Metro Parking",
                    tsName: "30 Day Rolling",
                    csvExportType: CsvExportType.DATA,
                    csvName: "SpaceKnow Retail Metro Parking CF Index China 30d",
                }],
                title: "SK Retail Metro Parking CFI",
                startYear: 2020,
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Steel Storages SAI</font>, the chart below compares <font color="#cc4125">SK Forecast</font> with the m-o-m Steel Production provided by the World Steel Association (gray line).`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Steel Storages",
                    tsName: "China Steel Production",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Steel Storages",
                    tsName: "SK Forecast [China Steel Production]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Steel Production",
                }],
            },
        },
        {
            header: `<font color="#1155cc">Manufacturing Indices:</font> <font color="#cc4125">SK Copper Manufacturing SAI</font>, the chart below compares <font color="#cc4125">SK Forecast</font> with the Output Refined Copper compiled by the China National Bureau of Statistics (gray line).`,
            chart: {
                dcrThreshold: 0.04,
                datasets: [{
                    chartName: "Copper Manufacturing",
                    tsName: "China Output Refined Copper",
                    keepStyle: true,
                    csvExportType: CsvExportType.NONE,
                }, {
                    chartName: "Copper Manufacturing",
                    tsName: "SK Forecast [China Output Refined Copper]",
                    keepStyle: true,
                    csvExportType: CsvExportType.FORECAST,
                    csvName: "SpaceKnow Forecast Output Refined Copper",
                }],
            },
        },
    ],
};
