import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { Insights } from "skCommon/permissions";

import { DocsComponent } from "skInsights/docs/docs.component";
import { UserService } from "skInsights/user/user.service";

/**
 * Component displaying icon-button for opening docs dialog when user has
 * appropriate permissions.
 */
@Component({
    selector: "sk-docs-button",
    templateUrl: "./docsButton.pug",
    styles: [`:host { display: "contents"; }`],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocsButtonComponent {

    public get canSeeDocs(): boolean {
        return this.userService.loggedIn
            && this.userService.permissions.has(Insights.Dashboard.Edit);
    }

    constructor(
        private userService: UserService,
        private matDialog: MatDialog,
    ) { }

    public openDocs(): void {
        this.matDialog.open(DocsComponent, {
            width: "90%",
            height: "90%",
        });
    }
}
