import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { MatTabsModule } from "@angular/material/tabs";

import { LoadingModule } from "skCommon/angular/partials/loading/loading.module";
import { SkeletonModule } from "skCommon/ui/skeleton/skeleton.module";
import { Insights } from "skCommon/permissions";
import { WebFontLoaderService } from "skCommon/fonts/webFontLoader.service";
import { FontLoaderService } from "skCommon/fonts/fontLoader.service";
import { ImageRetriever } from "skCommon/exports/pageExport/imageRetriever";
import { BrowserRetriever } from "skCommon/exports/pageExport/browserRetriever";

import { Documents, DocumentsRoutes } from "skInsights/modules/documents/documents.routing";
import { DocumentsEditComponent } from "skInsights/modules/documents/manager/documentsEdit.component";
import { DocumentsListComponent } from "skInsights/modules/documents/manager/documentsList.component";
import { AuthGuard, AuthGuardData } from "skInsights/user/auth.guard";
import { SharedModule } from "skInsights/shared.module";
import { DocumentComponent, DocumentComponentType } from "skInsights/modules/documents/components/document.component";
import { provideComponent } from "skInsights/framework/tokens";
import { DocumentLinkHandlerService } from "skInsights/modules/documents/linkHandlers/documentLinkHandler.service";
import { provideLinkHandler } from "skInsights/framework/insightsProtocol";
import { BlockEditorModule } from "skInsights/partials/blockEditor/blockEditor.module";
import { ButtonsModule } from "skInsights/partials/buttons/buttons.module";
import { RichTextModule } from "skInsights/partials/richText/richText.module";
import { DocumentLoaderService } from "skInsights/modules/documents/documentLoader.service";
import { WebDocumentLoaderService } from "skInsights/modules/documents/webDocumentLoader.service";

const RoutingModule = RouterModule.forChild([
    {
        path: Documents,
        canActivate: [AuthGuard],
        data: {
            permissions: [Insights.Documents.Manage],
        } as AuthGuardData,
        children: [
            {
                path: "",
                component: DocumentsListComponent,
            },
            {
                path: DocumentsRoutes.Edit + "/:id",
                component: DocumentsEditComponent,
            },
        ],
    },
]);

@NgModule({
    imports: [
        SharedModule,
        RoutingModule,
        AngularFireStorageModule,
        BlockEditorModule,
        MatTabsModule,
        LoadingModule,
        ButtonsModule,
        RichTextModule,
        SkeletonModule,
    ],
    declarations: [
        DocumentsListComponent,
        DocumentsEditComponent,
        DocumentComponent,
    ],
    providers: [
        {
            provide: FontLoaderService,
            useClass: WebFontLoaderService,
        },
        {
            provide: ImageRetriever,
            useClass: BrowserRetriever,
        },
        {
            provide: DocumentLoaderService,
            useClass: WebDocumentLoaderService,
        },
        provideComponent(DocumentComponentType, DocumentComponent),

        provideLinkHandler(DocumentLinkHandlerService),
    ],
})
export class DocumentsModule { }
