import { InjectionToken, Type, ClassProvider } from "@angular/core";

import { ComponentDef } from "skCommon/insights/dashboard";

import { Operation } from "skInsights/framework/abstract/operation";
import { LayoutComponent } from "skInsights/framework/abstract/layoutComponent";
import { DashboardSource } from "skInsights/framework/abstract/dashboardSource";
import { ComponentStaticRenderer } from "skInsights/framework/dashboardPdf/componentStaticRenderer";

export const DASHBOARD_SOURCES_TOKEN = new InjectionToken("dashboard data sources");

export const OPERATIONS_TOKEN = new InjectionToken("dashboard data operations");

export const LAYOUT_COMPONENTS_TOKEN = new InjectionToken("dashboard components");

export function provideOperation(clss: Type<Operation<any>>): ClassProvider {
    return {
        provide: OPERATIONS_TOKEN,
        multi: true,
        useClass: clss,
    };
}

export function provideComponent<T extends ComponentDef>(
    type: string,
    clss: Type<LayoutComponent<T>>,
    options?: LayoutComponentOptions<T>,
): ComponentClassProvider {
    return {
        provide: LAYOUT_COMPONENTS_TOKEN,
        multi: true,
        useValue: {
            type,
            componentClass: clss,
            options,
        } as ComponentClassDefinition,
    };
}

export function provideSource(clss: Type<DashboardSource<any>>): ClassProvider {
    return {
        provide: DASHBOARD_SOURCES_TOKEN,
        multi: true,
        useClass: clss,
    };
}

export interface ComponentClassProvider {
    provide: typeof LAYOUT_COMPONENTS_TOKEN;
    multi: true;
    useValue: ComponentClassDefinition;
}

export interface ComponentClassDefinition<T extends ComponentDef = any> {
    type: string;
    componentClass: Type<LayoutComponent<T>>;
    options?: LayoutComponentOptions<T>;
}

export interface LayoutComponentOptions<T extends ComponentDef> {
    /**
     * Optional service reference, which should be used to render the component
     * into pdf
     */
    staticRenderer?: Type<ComponentStaticRenderer<ComponentDef>>;
    /**
     * Optional function that can pick child component definitions
     * (e.g. for containers)
     */
    getChildComponents?(def: T): ComponentDef[];

    getComponentSources?(def: ComponentDef): string[];
}
