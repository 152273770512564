import { OutputData } from "@editorjs/editorjs";
import { observable, makeObservable } from "mobx";
import { Observable, Subject } from "rxjs";

import { isOutputDataEmpty } from "skInsights/partials/blockEditor/outputData";
import { sanitizeOutputData } from "skInsights/partials/blockEditor/sanitize";

/**
 * Model to pass data between BlockEditorComponent and its parent.
 *
 * The editorjs "save" method is async and non-trivial, so it's not a good fit
 * for regular ngModel style of output.
 */
export class BlockEditorModel {

    @observable.ref
    public empty: boolean;

    private outputRequestsSubject = new Subject<OutputRequest>();

    public get outputRequests$(): Observable<OutputRequest> {
        return this.outputRequestsSubject.asObservable();
    }

    constructor(public readonly initialContent?: OutputData) {
        makeObservable(this);

        this.empty = isOutputDataEmpty(initialContent);
    }

    public async getOutputData(): Promise<OutputData> {
        let callback: OutputRequestCallback;
        const promise = new Promise<OutputData>(resolve => callback = resolve);

        this.outputRequestsSubject.next({ callback: callback! });

        return promise.then(d => {
            return sanitizeOutputData(d);
        });
    }
}

export interface OutputRequest {
    callback: OutputRequestCallback;
}

type OutputRequestCallback = (d: OutputData | Promise<OutputData>) => void;
