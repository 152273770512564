import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatButtonModule } from "@angular/material/button";

import { AutorunModule } from "skCommon/angular/state/autorun.module";
import { SidePanelService } from "skCommon/ui/sidePanel/sidePanel.service";
import { SidePanelHostComponent } from "skCommon/ui/sidePanel/sidePanelHost.component";
import { SidePanelHeaderComponent } from "skCommon/ui/sidePanel/sidePanelHeader.component";

@NgModule({
    imports: [
        CommonModule,
        AutorunModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatButtonModule,
    ],
    providers: [
        SidePanelService,
    ],
    declarations: [
        SidePanelHostComponent,
        SidePanelHeaderComponent,
    ],
    exports: [
        SidePanelHostComponent,
        SidePanelHeaderComponent,
    ],
})
export class SidePanelModule { }
