import { DataPoint } from "skCommon/insights/externalSources";

import { Operation, OperationDef } from "skInsights/framework/abstract/operation";
import { AnyDashboardData } from "skInsights/framework/data/structures";
import { transformSeries, assertSeriesLike } from "skInsights/framework/data/helpers";
import { parseDateString } from "skInsights/utils/dateString";

type FilterOperationType = "core/filter";
const FilterOperationType: FilterOperationType = "core/filter";

/**
 * Pick single value form series converting series into single-value type.
 */
export class FilterOperation extends Operation<FilterOperationDef> {

    public readonly type = FilterOperationType;

    public execute(def: FilterOperationDef, data: AnyDashboardData): AnyDashboardData {
        assertSeriesLike(data);

        const res = transformSeries(data, ({ series }) => {

            return {
                series: series.filter(p => {
                    const a = this.resolveOperand(p, def.condition[0]);
                    const b = this.resolveOperand(p, def.condition[2]);

                    return this.compare(a, b, def.condition[1]);
                }),
            };
        });

        return res;
    }

    private resolveOperand(point: DataPoint, operand: Operand): number {
        if (operand === "y" || operand === "x") {
            return +point[operand as keyof DataPoint];
        } else if (typeof operand === "number") {
            return operand;
        } else {
            const time = parseDateString(operand).getTime();

            if (!Number.isNaN(time)) {
                return time;
            } else {
                throw new Error(`Invalid operand "${operand}"`);
            }
        }
    }

    private compare(a: number, b: number, operator: Operator): boolean {
        switch (operator) {
            case ">":
                return a > b;
            case ">=":
                return a >= b;
            case "<":
                return a < b;
            case "<=":
                return a <= b;
        }

        throw new Error(`Invalid operator ${operator}`);
    }
}

/**
 * Filter data points using given condition
 */
interface FilterOperationDef extends OperationDef<FilterOperationType> {
    /**
     * Condition to execute.
     *
     * Allowed operands:
     *     `x` - point's datetime
     *     `y` - point's value,
     *     number: numeric literal
     *     date string like "2019-01-01T00:00:00Z" - date literal
     *
     * Allowed operators: `>`, `>=`, `<`, `<=`
     *
     * @example `["x", ">", "2019"]`
     * @example `["y", "<=", 0.5]`
     * @example `["2019-01-01T00:00:00Z", "<=", "x"]`
     */
    condition: [Operand, Operator, Operand];
}

type Operand = "x" | "y" | string | number;
type Operator = ">" | ">=" | "<" | "<=";
