/**
 * Class that should be applied to each popup dialog. Used to manually access
 * the panel elements.
 */
export const PANEL_CLASS = "dashboard-popup-panel";
/**
 * Class that should be applied to currently active cdk-global-overlay-wrapper
 * element that modifies the overlay's z-index.
 */
export const ACTIVE_OVERLAY_CLASS = "active-global-overlay-wrapper";
/**
 * Class that should be applied to currently passive cdk-global-overlay-wrapper
 * element that modifies the overlay's z-index.
 */
export const PASSIVE_OVERLAY_CLASS = "passive-global-overlay-wrapper";
