import jsPDF, { jsPDFOptions, TextOptionsLight } from "jspdf";
import sanitizeHtml from "sanitize-html";

import "skCommon/extensions/array.prototype.at";
import { SkColor } from "skCommon/colors";
import { PageExportOptions, PagePadding } from "skCommon/exports/pageExport/pageExportOptions";
import { assert } from "skCommon/utils/assert";
import { sortByKey } from "skCommon/utils/sort";
import { htmlCharactersToText } from "skCommon/utils/specialCharacter";
import { RetrievedImage, ImageRetriever } from "skCommon/exports/pageExport/imageRetriever";

const GLOBAL_TEXT_OPTIONS: TextOptionsLight = {
    baseline: "bottom",
};

const FOOTER_TEXT = "The Information contained in this document is confidential," +
" privileged, and only for the use of the intended recipient and may \nnot be" +
" used, published, or redistributed without the prior written consent" +
" of SpaceKnow Inc.";

const FOOTER_HEIGHT = 15;
const LOGO_HEIGHT = 15;

const DPI = 120;
const PX_MM = 25.4 / DPI;
const PX_PT = 72 / DPI;

/**
 * Class used by all components to be exported to access the PDF into which the
 * components should be rendered. Outside providing the access to the pdf object
 * it also contains the information where should the current component be
 * rendered.
 *
 * To keep the style of all components relative consistent, direct access to
 * the PDF instance should be kept to minimum and use wrapper methods such as
 * typeText, putImage, underline etc.
 */
export class PageExport {

    public readonly pdf: jsPDF;

    public readonly defaultFontOptions: Required<FontOptions> = {
        color: SkColor.Neutral100,
        fontSize: 14,
        style: "light",
        lineHeight: 1.5,
    };

    /**
     * Where should the next component be rendered on current page.
     */
    public pagePointer: number = 0;

    /**
     * Padding around each page (mm)
     */
    private padding: PagePadding = {x: 6, y: {top: 10, bottom: 10}};

    /**
     * height of logo incuding padding, if 0 logo is not added
     */
    private logoHeight: number = 0;

    /**
     * height of logo incuding padding, if 0 footer is not added
     */
    private footerHeight: number = 0;

    private firstPageInit = false;

    /**
     * Width currently available to render components on, so the component
     * knows where's center of the page etc.
     */
    public get width(): number {
        return this.pdf.internal.pageSize.getWidth() - this.padding.x * 2;
    }

    public get height(): number {
        return this.maxAvailableHeight - this.pagePointer;
    }

    /**
     * Same as width but in pixels. Useful when generating images to be put
     * into the pdf.
     */
    public get widthPx(): number {
        return this.width / PX_MM;
    }

    public get maxAvailableHeight(): number {
        return this.pdf.internal.pageSize.getHeight()
        - this.padding.y.top
        - this.padding.y.bottom;
    }

    private get pdfOptions(): jsPDFOptions {
        return {
            format: this.options.format ?? "a4",
            unit: "mm",
            orientation: this.options.orientation || "p",
            compress: true,
        };
    }

    constructor(
            private options: PageExportOptions = {},
            private imageRetriever: ImageRetriever,
        ) {
        this.setUpPagePadding();
        this.pdf = new jsPDF(this.pdfOptions);
        if (this.options.watermark) {
            this.addWatermark();
        }
    }

    public addHeaderToFirstPage() {
        if (!this.firstPageInit) {
            if (this.options.headerLogo) {
                this.addLogo();
            }
            if (this.options.footerText) {
                this.addFooter();
            }
            this.firstPageInit = true;
        }
    }

    private setUpPagePadding(): void {
        this.logoHeight = this.options.headerLogo ? LOGO_HEIGHT : 0;
        this.footerHeight = this.options.footerText ? FOOTER_HEIGHT : 0;
        this.padding = this.options.padding ?? this.padding;
        this.padding.y.top += this.logoHeight;
        this.padding.y.bottom += this.footerHeight;
    }


    public finalizePdf(): Blob {
        return this.pdf.output("blob");
    }

    /**
     * Create a new page and move focus on it.
     */
    public addPage(): void {
        this.pdf.addPage(this.pdfOptions.format, this.pdfOptions.orientation);
        this.pagePointer = 0;
        if (this.options.headerLogo) {
            this.addLogo();
        }
        if (this.options.footerText) {
            this.addFooter();
        }
        if (this.options.watermark) {
            this.addWatermark();
        }
    }

    /**
     * Create a new page unless we are already on an empty page.
     */
    public ensureNewPage(): void {
        if (this.pagePointer !== 0) {
            this.addPage();
        }
    }

    public putImage(
        img: RetrievedImage,
        inputOptions: PutImageOptions = {},
        label: string = "",
    ): void {
        const imgWidth = img.width;
        const imgHeight = img.height;
        const imgAspectRatio = imgHeight / imgWidth;

        const nativeWidthMm = Math.min(
            imgWidth / this.widthPx * this.width,
            this.width,
        );
        const computedWidthMm = inputOptions.width ?? (
            inputOptions.height
                ? inputOptions.height / imgAspectRatio
                : nativeWidthMm
        );
        const options: Required<PutImageOptions> = {
            width: computedWidthMm,
            height: inputOptions.height ?? computedWidthMm * imgAspectRatio,
            positionX: inputOptions.positionX ?? 0,
            top: inputOptions.top ?? 0,
        };
        const translate = this.allocateHeight(options.height + options.top);
        const x = options.positionX === "center"
            ? (this.width - options.width) / 2
            : options.positionX;
        const topLeft = translate({
            x,
            y: options.top,
        });
        const bottomRight = translate({
            x: x + options.width,
            y: options.top + options.height,
        });

        this.pdf.addImage({
            imageData: img.data,
            format: "PNG",
            x: topLeft.x,
            y: topLeft.y,
            width: bottomRight.x - topLeft.x,
            height: bottomRight.y - topLeft.y,
            compression: "SLOW",
        });
        this.movePointer(2);
        if (label) {
            this.typeParagraph(
                label,
                {
                    color: SkColor.Neutral80,
                    align: "center",
                });
        }
    }

    /**
     * Type text according to given options and move pointer below it.
     */
    public typeLine(text: string, inputOptions: TypeTextOptions = {}): void {
        text = htmlCharactersToText(text);
        const options: Required<TypeTextOptions> = {
            top: inputOptions.top ?? 0,
            left: inputOptions.left ?? 0,
            ...this.setFontOptions(inputOptions),
        };
        const lineHeightMm = this.getRealLineHeight(options.fontSize, options.lineHeight);

        const tl = this.allocateHeight(options.top + lineHeightMm);
        const { x, y } = tl({
            x: options.left,
            y: options.top + lineHeightMm,
        });

        this.pdf.text(text, x, y, {
            baseline: "bottom",
        });
    }

    public typeParagraph(text: string, inputOptions: TypeParagraphOptions = {}): void {
        text = htmlCharactersToText(text);
        // Needs to be called before splitting!
        const left = inputOptions.left ?? 0;
        const options = {
            ...this.setFontOptions(inputOptions),
            left: left,
            top: inputOptions.top ?? 0,
            width: inputOptions.width ?? this.width - left,
            align: inputOptions.align ?? "left",
        };

        const formatted = this.parseFormatting(text);
        this.writeFormattedText(formatted, options);
    }

    /**
     * Move Y pointer by given height. Basically creates blank space before
     * next call.
     */
    public movePointer(height: number): void {
        this.pagePointer += height;
    }

    /**
     * Set Y pointer to exact position. This should only be used when we need
     * to create some ugly non spaceknow standard document, since every standard
     * pdf content should be created via appropriate calls based on current
     * pointer.
     */
    public setPointer(height: number): void {
        this.pagePointer = height;
    }

    /**
     * Get line height in mm for given font size and line height
     */
    public getRealLineHeight(
        fontSize: number,
        lineHeight: number = this.defaultFontOptions.lineHeight,
    ): number {
        return lineHeight * fontSize * PX_MM;
    }

    /**
     * Ensure there's enough space on given page for content of given height
     * and return function for translating coordinate in the allocated space,
     * so { 0, 0 } is top left cordner and { pageWidth, allocatedHeight }
     * is bottom right corner.
     */
    public allocateHeight(height: number): (coord: PdfCoordinate) => PdfCoordinate {
        this.requireFreeHeight(height);

        const origPointer = this.pagePointer;

        this.pagePointer += height;

        return ({ x, y }: PdfCoordinate) => {
            assert(x <= this.width && x >= 0, `Invalid X coordinate ${x}`);
            assert(y <= height && y >= 0, `Invalid Y coordinate ${y}`);

            return {
                x: x + this.padding.x,
                y: y + this.padding.y.top + origPointer,
            };
        };
    }

    /**
     * Jump onto next page if current page doesn't have enough free space.
     * Useful when we want to make sure multiple components end up at the same
     * page: e.g. requierFreeHeight(imageHeight + spacing + titleHeight)
     */
    public requireFreeHeight(height: number): void {
        assert(height <= this.maxAvailableHeight, `Cannot require more than one full page. Required ${height}mm`);

        if (height > this.height) {
            this.addPage();
        }
    }

    private addFooter() {
        this.setFontOptions({ fontSize: 14 });
        this.pdf.text(
            FOOTER_TEXT,
            this.padding.x,
            this.maxAvailableHeight + this.padding.y.top + (FOOTER_HEIGHT / 2),
            {
                baseline: "bottom",
            },
        );
    }

    private async addLogo() {
        const img = await this.imageRetriever.getLogo();
        const ratio = 10;
        this.pdf.addImage({
            imageData: img.data,
            format: "PNG",
            x: this.padding.x,
            y: this.logoHeight / 2,
            width: img.width / ratio,
            height: img.height / ratio,
            compression: "SLOW",
        });
    }

    private addWatermark() {
        assert(this.options.watermark, `watermark text is not defined`);
        this.setFontOptions({color: SkColor.Neutral80, fontSize: 12});
        this.pdf.text(
            this.options.watermark,
            (this.width / 2) + this.padding.x,
            5,
            {...GLOBAL_TEXT_OPTIONS, align: "center"},
        );
        this.pdf.text(
            this.options.watermark,
            (this.width / 2) + this.padding.x,
            this.pdf.internal.pageSize.getHeight() - 1,
            {...GLOBAL_TEXT_OPTIONS, align: "center"},
        );
    }

    private getAvailableWidthForParagraph(inputOptions: Required<TypeParagraphOptions>) {
        const availableWidth = this.width - inputOptions.left;
        return Math.min(
            inputOptions.width,
            availableWidth,
        );
    }

    private setFontOptions(
        inputOptions: FontOptions,
        defaultOptions: Required<FontOptions> = this.defaultFontOptions,
    ): Required<FontOptions> {
        const options: Required<FontOptions> = {
            color: inputOptions.color ?? defaultOptions.color,
            fontSize: inputOptions.fontSize ?? defaultOptions.fontSize,
            style: inputOptions.style ?? defaultOptions.style,
            lineHeight: inputOptions.lineHeight ?? defaultOptions.lineHeight,
        };

        this.pdf.setFont("roboto", options.style);
        this.pdf.setTextColor(options.color);
        this.pdf.setFontSize(options.fontSize * PX_PT);
        this.pdf.setLineHeightFactor(options.lineHeight);

        return options;
    }

    /**
     * Split paragraph into lines which are then split depending on font changes
     * and type each part of the string separately.
     */
    private writeFormattedText(
        formatted: ParsedFormatting,
        options: Required<TypeParagraphOptions>,
    ): void {
        const lineFactorMargin = 0.4;
        const lineHeightMm = this.getRealLineHeight(options.fontSize, options.lineHeight);
        const maxWidth = this.getAvailableWidthForParagraph(options);
        const lines: string[] = this.pdf.splitTextToSize(formatted.sanitized, maxWidth * 0.96);
        const lineBreaks = this.linesToLineBreaks(lines, options);
        const allBreaks = [...lineBreaks, ...formatted.breakpoints].sort(sortByKey("index"));
        const totalHeight = options.top + (lines.length + lineFactorMargin) * lineHeightMm;
        const translate = this.allocateHeight(totalHeight);

        let currentFont: FontOptions = { ...options };
        let lastIndex = 0;
        let left = options.left;
        let top = options.top;

        for (const breakpoint of allBreaks) {
            const textPart = formatted.sanitized.slice(lastIndex, breakpoint.index);
            const pos = translate({ x: left, y: top + lineHeightMm });

            this.pdf.text(textPart, pos.x, pos.y, GLOBAL_TEXT_OPTIONS);

            lastIndex = breakpoint.index;

            if ("offset" in breakpoint) {
                left += breakpoint.offset;
            } else if ("break" in breakpoint) {
                left = options.left;
                top += lineHeightMm;
            } else {
                left += this.measureStringWidth(textPart);
                currentFont = this.setFontOptions({
                    ...currentFont,
                    ...breakpoint.font,
                }, options);
            }
        }
    }

    /**
     * Take text separated into lines and compute at what positions the original
     * text was split. Also include information how each line should be aligned.
     */
    private linesToLineBreaks(
        lines: string[],
        options: Required<TypeParagraphOptions>,
    ): (LineBreak | LineOffset)[] {
        return lines.reduce<(LineBreak | LineOffset)[]>((breaks, line) => {
            const spaceCharacterLen = 1;
            const lastBreakPos = (breaks.at(-1)?.index ?? 0);
            let offset: number = 0;

            if (options.align === "center") {
                const strWidth = this.measureStringWidth(line);
                offset = (options.width - strWidth) / 2;
            }

            return [
                ...breaks,
                {
                    offset,
                    index: lastBreakPos,
                },
                {
                    break: true,
                    index: line.length + lastBreakPos + spaceCharacterLen,
                },
            ];
        }, []);
    }

    /**
     * Convert supported tags into font options breakpoints which change font
     * options during writing the text into pdf. Ignore and remove all other
     * tags.
     */
    private parseFormatting(str: string): ParsedFormatting {
        const allowedTags = ["b", "font", "i"];
        let text: string = sanitizeHtml(str, {
            allowedTags,
            allowedAttributes: {
                font: ["color"],
            },
        });

        const breakpoints: FormattingBreakpoint[] = [];
        const exp = /<(\/?)([^ \>]+) *([^ \>]*)>/;
        let expResult: RegExpExecArray | null;

        while (expResult = exp.exec(text)) {
            const tag = expResult[0];
            const start = !expResult[1];
            const tagName = expResult[2]!;
            const tagAttrs = expResult[3];
            /**
             * Position of the tag => where should the font option change be applied
             */
            const index = text.indexOf(tag);
            const attributes = this.parseAttributes(tagAttrs);

            text = text.replace(tag, "");

            breakpoints.push({
                index,
                font: this.getFontOptionsChangeForTag(tagName, attributes, start),
            });
        }

        return {
            sanitized: text,
            breakpoints,
        };
    }

    private getFontOptionsChangeForTag(
        tagName: string,
        attributes: Record<string, string>,
        start: boolean,
    ): FontOptions {
        switch (tagName) {
            case "b":
                if (start) {
                    return { style: "medium" };
                } else {
                    return { style: undefined };
                }
            case "i":
                if (start) {
                    const currentFont = this.pdf.getFont();
                    return {
                        style: currentFont.fontStyle === "light" ? "light/italic"
                            : currentFont.fontStyle === "medium" ? "medium/italic"
                            :  "italic",
                    };
                } else {
                    return { style: undefined };
                }
            case "font":
                return {
                    color: attributes.color as any,
                };
            default:
                throw new Error(`No action for tag ${tagName}`);
        }

    }

    /**
     * Parse html attributes, doesn't support escaped quotes and requires
     * value to be quoted. We should find some native DOM way to do this.
     */
    private parseAttributes(attrsStr: string): Record<string, string> {
        const exp = / *([0-9A-z\-]+)(="([^"]*)"|='([^']*)')? */g;
        const results = [...attrsStr.matchAll(exp)];

        return Object.fromEntries(
            results.map(result => [result[1], result[3]]),
        );
    }

    /**
     * Get string width in mm
     */
    private measureStringWidth(str: string): number {
        return this.pdf.getStringUnitWidth(str) * this.pdf.getFontSize() / 72 * 25.4;
    }
}

export interface PdfCoordinate {
    x: number;
    y: number;
}

interface FontOptions {
    color?: SkColor;
    fontSize?: 12 | 14 | 16 | 20 | 32;
    style?: string;
    lineHeight?: number;
}

export interface TypeTextOptions extends FontOptions {
    top?: number;
    left?: number;
}

export interface PutImageOptions {
    width?: number;
    height?: number;
    top?: number;
    positionX?: number | "center";
}

export interface TypeParagraphOptions extends FontOptions {
    top?: number;
    left?: number;
    width?: number;
    align?: "left" | "right" | "center";
}

interface ParsedFormatting {
    /**
     * Text without any HTML tags
     */
    sanitized: string;
    /**
     * List of "breakpoints" where font options change
     */
    breakpoints: FormattingBreakpoint[];
}

/**
 * "Breakpoint" in paragraph where we need to change PDF's font options
 */
interface FormattingBreakpoint {
    /**
     * Paragraph position where the change should happen
     */
    index: number;
    font: FontOptions;
}

/**
 * Breakpoint in paragraph that indicates that we should continue on next line
 */
interface LineBreak {
    break: true;
    /**
     * Paragraph position where the line break should happen
     */
    index: number;
}

/**
 * Breakpoint in paragraph that pushes pointer by given offset. Used to make
 * text aligned.
 */
interface LineOffset {
    offset: number;
    index: number;
}
