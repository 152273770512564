import { SkError } from "skCommon/core/error";
import { sleep } from "skCommon/utils/delay";

export class ImgFetcher {
    public readonly url: string;
    public readonly cors: boolean = false;
    public retries = 0;

    private img: HTMLImageElement;
    private downloaded = false;
    private requested = false;
    private _promise: Promise<HTMLImageElement>;
    private originalRetries: number;

    public get promise() {
        return this._promise;
    }

    public get ready(): boolean {
        return this.downloaded;
    }

    public get loading(): boolean {
        return this.requested;
    }

    constructor(init: ImgFetcherInit) {
        Object.assign(this, init);
    }

    ///
    ///
    /// Pulbic API
    ///
    ///

    public fetch(): Promise<HTMLImageElement> {
        this.originalRetries = this.retries;
        const promise = this.tryFetch();
        this.requested = true;

        this._promise = promise.catch(e => this.handleError(e));

        return this._promise;
    }

    /**
     * Remove reference to downloaded image => this instance won't block GC in
     * collection this image.
     */
    public remove() {
        this.img = null;
    }

    private handleError(e: Error) {
        const retriable = e instanceof ImgFetcherError
            && e.code === ImgFetcherError.ERR_FAILED;

        if (retriable && this.retries) {
            const retry = this.originalRetries - (--this.retries);
            // Linear backoff with 0.5s steps
            return sleep(500 * retry)
                .then(() => this.tryFetch(retry))
                .catch(err => this.handleError(err));
        } else {
            return Promise.reject(e);
        }
    }

    private tryFetch(retry = 0) {
        return new Promise<HTMLImageElement>((resolve, reject) => {
            this.img = new Image();

            this.img.addEventListener("load", () => {
                // Make sure fetcher was not killed before image downloaded
                if (this.img) {
                    this.downloaded = true;

                    resolve(this.img);
                }
            });

            this.img.addEventListener("abort", () => {
                reject(new ImgFetcherError(ImgFetcherError.ERR_ABORTED));
            });

            this.img.addEventListener("error", () => {
                reject(new ImgFetcherError(ImgFetcherError.ERR_FAILED));
            });

            if (this.cors) {
                this.img.crossOrigin = "Anonymous";
            }

            this.img.src = retry
                ? this.appendRetryStamp(this.url, retry)
                : this.url;
        });
    }

    private appendRetryStamp(url: string, retry: number) {
        let separator = "?";

        if (url.includes("?")) {
            separator = "&";
        }

        return url + separator + "retry=" + retry;
    }
}

///
///
/// Error
///
///

export class ImgFetcherError extends SkError {
    public static readonly ERR_ABORTED = "ERR_ABORTED";
    public static readonly ERR_FAILED = "ERR_FAILED";
    public static readonly MESSAGES = new Map([
        [ImgFetcherError.ERR_ABORTED, "Image fetching was aborted"],
        [ImgFetcherError.ERR_ABORTED, "Image fetching failed"],
    ]);

    public dataToLog = {};
    public skipLog = true;

    constructor(code: string) {
        super("ImgFetcherError", code);
    }
}

///
///
/// Interfaces
///
///

export type ImgFetcherInit = Partial<ImgFetcher>;
