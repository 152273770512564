import Color from "color";

export function createColorcoding(def: LinerHueColorcodingDef | StopsColorcodingDef) {
    if (def instanceof Array) {
        return new StopsColorcoding(def);
    } else if ("hueRange" in def) {
        return new LinerHueColorcoding(def);
    } else {
        throw new Error("Unsupported colorcoding definition provided.");
    }
}

export class LinerHueColorcoding {

    constructor(private def: LinerHueColorcodingDef) { }

    public getColor(val: number): string {
        const color = Color(this.def.color);

        const p = Math.max(Math.min(
            1,
            (val - this.def.values[0]) / (this.def.values[1] - this.def.values[0]),
        ), 0);

        return color.rotate(p * this.def.hueRange).rgb().hex();
    }
}

export class StopsColorcoding {

    constructor(private def: StopsColorcodingDef) { }

    public getColor(val: number): string {
        const stops = this.def.slice(1).map(([stopVal]) => stopVal) as number[];
        const colors = this.def.map(s => typeof s === "string" ? s : s[1]);

        const stopIndex = stops.findIndex(stop => stop < val);

        return colors[stopIndex === -1 ? stops.length : stopIndex];
    }
}

export interface Colorcoding {
    getColor(val: number): string;
}

export type StopsColorcodingDef = (ColorStr | ColorStop)[];

export interface LinerHueColorcodingDef {
    /**
     * Starting color (color used for interval -infinity to values[0])
     */
    color: ColorStr;
    /**
     * Hue range in degrees
     */
    hueRange: number;
    /**
     * [min, max] range of values between which should the hue be rotated.
     */
    values: [number, number];
}

type ColorStop = Readonly<[number, ColorStr]>;

type ColorStr = string;
