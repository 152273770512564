import { ChangeDetectionStrategy, Component } from "@angular/core";
import { merge, of } from "rxjs";
import { mapTo } from "rxjs/operators";

import { DashboardService } from "skInsights/dashboard/dashboard.service";

@Component({
    selector: "sk-all-dashboard",
    templateUrl: "./allDashboard.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllDashboardComponent {

    public allDashboards$  = this.dashboardService.getAllDashboard();

    public loading$ = merge(
        of(true),
        this.allDashboards$.pipe(mapTo(false)),
    );

    constructor(
        private dashboardService: DashboardService,
    ) {}
}
