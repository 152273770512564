import { Injectable } from "@angular/core";

import { Viewports } from "skCommon/angular/viewports";

@Injectable({ providedIn: "root" })
export class ViewportService extends Viewports<"md"|"sm"> {
    constructor() {
        super({
            sm: 768,
            md: 992,
        });
    }
}
