import { Injectable } from "@angular/core";

import { urlToImg } from "skCommon/utils/dom";
import { RetrievedImage, ImageRetriever } from "skCommon/exports/pageExport/imageRetriever";
import logo from "skCommon/png/logo.png";

@Injectable()
export class BrowserRetriever extends ImageRetriever {

    public async getLogo(): Promise<RetrievedImage> {
        return this.getImage(logo);
    }

    public async getImage(url: string): Promise<RetrievedImage> {
        const img = await urlToImg(url);
        return {
            data: img,
            height: img.naturalHeight,
            width: img.naturalWidth,
        };
    }
 }
