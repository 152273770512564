import { InjectionToken, Type, ValueProvider } from "@angular/core";
const injectionTokenRegistry: Map<string, InjectionToken<MapPlugin>> = new Map();

export const MAP_PLUGIN_DEF_TOKEN = new InjectionToken("for current map plugin's definition");

/**
 * Get provider which correctly provides Injectable class under given plugin name.
 */
export function plugin(name: string, Plugin: Type<MapPlugin>): ValueProvider {
    return {
        provide: injectPlugin(name),
        useValue: Plugin,
    };
}

/**
 * Get injection token for given map plugin. Inject with string is deprecated
 * now and Type or injection token needs to be used, but the dashboard JSON
 * may only contain string so we need to use extra-angular dictionary which
 * maps plugin name to the singleton injection token.
 */
export function injectPlugin(name: string): InjectionToken<any> {
    if (!injectionTokenRegistry.has(name)) {
        injectionTokenRegistry.set(
            name,
            new InjectionToken(`map plugin "${name}"`),
        );
    }

    return injectionTokenRegistry.get(name)!;
}

export interface MapPlugin {
    /**
     * Reference to a angular component class which should be created in map's
     * overlay container for each instance of this plugin.
     */
    component?: Type<any>;

    /**
     * Options that should be propagated into the map instance.
     */
    mapOptions?: MapPluginMapOptions;

    /**
     * Optionally async method which is called right after the plugin service
     * is instantiated before anything is rendered.
     */
    init?(): Promise<void> | void;

    render?(): void;
}

export interface MapPluginMapOptions {
    /**
     * Map extent when the plugin displays some data and thus should be visible.
     */
    extent?: GeoJSON.BBox;
    /**
     * Max zoom for all the data to be visible
     */
    zoom?: number;
}

export interface MapPluginDef {
    name: string;
}
