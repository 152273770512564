import "moment";
import { Scale } from "chart.js";
import "chartjs-adapter-moment";
import { Subject } from "rxjs";

import { registerLineCharts } from "skCommon/chart/register/lineCharts";
import { ChartContext as BaseChartContext } from "skCommon/chart/chartContext";

registerLineCharts();

export class ChartContext extends BaseChartContext {

    public onLegendClick$: Subject<LegendClickEvent> = new Subject();

    public onAxisClick$: Subject<AxisClickEvent> = new Subject();

    public render(canvas: HTMLCanvasElement): void {
        super.render(canvas);

        this.chart.canvas.addEventListener("click", e => {
            if ((e as MouseEvent).ctrlKey) {
                const activeBox = this.chart.boxes.find((box) =>
                    e.offsetY > box.top
                    && e.offsetY < box.bottom
                    && e.offsetX > box.left
                    && e.offsetX < box.right,
                );

                if (activeBox && activeBox instanceof Scale) {
                    this.onAxisClick$.next({
                        axis: (activeBox as any).id,
                        mouseEvent: e,
                    });
                }
            }
        });
    }
}

export interface LegendClickEvent {
    mouseEvent: MouseEvent;
    datasetIndex: number;
}

export interface AxisClickEvent {
    mouseEvent: MouseEvent;
    axis: string;
}
