import { sortByKey } from "skCommon/utils/sort";

/**
 * Merge multiple series into one with each value representing all values
 * available for that datetimes
 */
export function mergeTimeseries<D extends string, V extends string>(
    series: InputTimeseriesPoint<D, V>[][],
    dateKey: D,
    valueKey: V,
): MergedTimeseriesPoint<D, V>[] {
    const dates = series.flat().map(point => point[dateKey].getTime());

    const outMap = new Map(dates.map(d => [
        d,
        {
            [dateKey]: new Date(d),
            [valueKey]: new Array(series.length).fill(null),
        } as MergedTimeseriesPoint<D, V>,
    ]));

    for (const [i, ser] of series.entries()) {
        for (const point of ser) {
            outMap.get(point[dateKey].getTime())[valueKey][i] = point[valueKey];
        }
    }

    return [...outMap.values()].sort(sortByKey(dateKey));
}

type AnyTimeseriesPoint<D extends string, V extends string, TValue> = {
    [k in D]: Date;
} & {
    [k in V]: TValue;
};

export type InputTimeseriesPoint<D extends string, V extends string> =
    AnyTimeseriesPoint<D, V, number>;
export type MergedTimeseriesPoint<D extends string, V extends string> =
    AnyTimeseriesPoint<D, V, number[]>;
