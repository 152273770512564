import { ToolSettings } from "@editorjs/editorjs";

import { EditorJsPlugin } from "skInsights/partials/blockEditor/editorJs";
import { TEMPLATE_SECTION_TYPE } from "skInsights/partials/blockEditor/plugins/templateSection/templateSection";
import { TemplateSectionTool } from "skInsights/partials/blockEditor/plugins/templateSection/templateSectionTool";

export class TemplateSectionPlugin implements EditorJsPlugin {

    public getTools(): { template: ToolSettings } {
        return {
            [TEMPLATE_SECTION_TYPE]: {
                class: TemplateSectionTool,
            },
        };
    }
}
