import { Injectable } from "@angular/core";
import { OutputBlockData, OutputData } from "@editorjs/editorjs";

import { PageExport } from "skCommon/exports/pageExport/pageExport";
import { ImageRetriever } from "skCommon/exports/pageExport/imageRetriever";

@Injectable({ providedIn: "root" })
export class CleanDataExportService {

    constructor(
        private imageRetriever: ImageRetriever,
        ) {}

    public async writeToPage(cleanData: OutputData, page: PageExport): Promise<void> {
        for (const block of cleanData.blocks) {
            await this.writeBlock(page, block);
        }
    }

    private async writeBlock(page: PageExport, block: OutputBlockData): Promise<void> {
        switch (block.type) {
            case "paragraph": {
                page.typeParagraph(block.data.text);
                page.movePointer(2);
                break;
            }
            case "header": {
                page.typeParagraph(
                    block.data.text,
                    {
                        fontSize: this.levelToSize(block.data.level),
                        style: "regular",
                    },
                );
                break;
            }
            case "list": {
                block.data.items.forEach((item: string) => {
                    page.typeParagraph("   -   " + item);
                });
                page.movePointer(2);
                break;
            }
            case "image": {
                const image = await this.imageRetriever.getImage(block.data.file.url);
                const ratio = image.height / image.width;
                const width = page.width - 50 * ratio;
                page.putImage(
                    image,
                    {
                        width: width,
                        positionX: "center",
                    },
                    block.data.caption,
                );
                break;
            }

        }
    }

    private levelToSize(level: number): 12 | 14 | 16 | 20 | 32 {
            switch (level) {
                case 1: {
                    return 32;
                }
                case 2: {
                    return 20;
                }
                case 3: {
                    return 16;
                }
                default: {
                    return 12;
                }
            }
        }



}
