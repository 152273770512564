import { MatMenu } from "@angular/material/menu";

import { DashboardEvent } from "skInsights/framework/dashboardEvents";

export function isLinkAction(
    button: DashboardButton,
): button is ButtonDef<LinkAction> {
    return "link" in button && !!button.link;
}

export function isEventAction(
    button: DashboardButton,
): button is ButtonDef<EventAction> {
    return "event" in button && !!button.event;
}

export function isFnAction(
    button: DashboardButton,
): button is ButtonDef<FnAction> {
    return "onClick" in button && !!button.onClick;
}

export function isMatMenuAction(
    button: DashboardButton,
): button is ButtonDef<MatMenuAction> {
    return "menu" in button && !!button.menu;
}

export type DashboardButton = LayoutButton | ButtonDef<FnAction> | ButtonDef<MatMenuAction>;

/**
 * Unified serializable representation of link or button accepted by various
 * components
 */
export type LayoutButton = ButtonDef<LinkAction> | ButtonDef<EventAction>;

export type ButtonDef<Action> = ButtonStyle & Action;

export interface ButtonStyle {
    /**
     * Button's label. If icon is specified, text is displayed in button's tooltip.
     */
    text: string;
    /**
     * Icon to display in a circular icon button
     */
    icon?: string;
    /**
     * Button's color
     */
    accent?: string;
}

export interface LinkAction {
    /**
     * Button's url opened in new tab
     */
    link: string;
}

export interface EventAction {
    /**
     * Event to fire on click
     */
    event: DashboardEvent;
}

export interface FnAction {
    classes?: Record<string, boolean>;
    toggled?: boolean;

    onClick(): void;
}

export interface MatMenuAction {
    menu: MatMenu;
}
