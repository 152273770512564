import { NgModule } from "@angular/core";

import { SharedModule } from "skInsights/shared.module";
import { JsonEditorModule } from "skInsights/partials/jsonEditor/jsonEditor.module";
import { DesignerWrapperComponent } from "skInsights/framework/designer/designerWrapper.component";
import { JsonDesignerComponent } from "skInsights/framework/designer/fallback/jsonDesigner.component";

/**
 * Module declaring base component used for displaying dashboard designer
 */
@NgModule({
    imports: [
        SharedModule,
        JsonEditorModule,
    ],
    declarations: [
        DesignerWrapperComponent,
        JsonDesignerComponent,
    ],
    exports: [
        DesignerWrapperComponent,
        JsonDesignerComponent,
    ],
})
export class DesignerModule { }
