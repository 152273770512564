import { Injectable, Injector } from "@angular/core";

import { ReinjectorService } from "skCommon/angular/reinjector.service";
import { PageExport } from "skCommon/exports/pageExport/pageExport";
import { SkColor } from "skCommon/colors";

import { ComponentStaticRenderer } from "skInsights/framework/dashboardPdf/componentStaticRenderer";
import { ChartBuilderService } from "skInsights/modules/charts/helpers/chartBuilder.service";
import { LineChartComponentDef } from "skInsights/modules/charts/lineChart/definitions";

@Injectable()
export class LineChartStaticRendererService implements ComponentStaticRenderer<LineChartComponentDef> {

    constructor(
        private injector: Injector,
        private reinjectorService: ReinjectorService,
    ) { }

    public async renderIntoPage(def: LineChartComponentDef, page: PageExport) {
        const chartBuilderService = this.reinjectorService.get(this.injector, ChartBuilderService);
        const aspectRatio = 2 / 5;
        const width = page.widthPx;
        const height = width * aspectRatio;
        const topMargin = 3;
        const tabNameTopMargin = 1;
        const titleFontSize = 16;
        const chartHeightMm = page.width * aspectRatio;
        const chartTabDefs = this.splitByTabs(def);
        const titleHeightMm = page.getRealLineHeight(titleFontSize) + topMargin;
        const tabNameHeightMm = page.getRealLineHeight(titleFontSize) + topMargin;

        for (const [i, tab] of chartTabDefs.entries()) {
            const writeTitle = i === 0 && def.template?.title;
            // Make sure title, tab name and chart are always on same page
            const requiredHeight = chartHeightMm
                + (tab.name ? tabNameHeightMm : 0)
                + (writeTitle ? titleHeightMm : 0);

            const chartCtx = await chartBuilderService.loadAndCreateChart(tab.def);
            const canvas = await chartCtx.exportAsCanvas(width, height, 2);
            const imgData = canvas.getContext("2d")!
                .getImageData(0, 0, canvas.width, canvas.height);

            page.requireFreeHeight(requiredHeight);

            if (writeTitle && def.template?.title) {
                page.typeLine(def.template.title, {
                    fontSize: titleFontSize,
                    left: 4,
                    top: topMargin,
                });
            }

            if (tab.name) {
                page.typeLine(tab.name, {
                    fontSize: 14,
                    left: 4,
                    top: tabNameTopMargin,
                    style: "medium",
                    color: SkColor.Primary80,
                });
            }

            page.putImage({
                data: imgData,
                width: imgData.width,
                height: imgData.height,
            });
        }
    }

    /**
     * Since a chart may contain of several tabs, we need to separate those
     * into different charts for PDF and also remove timeseries that are
     * disabled by default.
     */
    private splitByTabs(def: LineChartComponentDef): LineChartTabExport[] {
        if (def.tabs) {
            return Object.entries(def.tabs).map(([tabId, tabInfo]) => {
                return {
                    name: tabInfo.name,
                    def: {
                        ...def,
                        series: def.series.filter(s => s.tab === tabId)
                            .filter(s => !s.disabled),
                        tabs: undefined,
                    },
                };
            });
        } else {
            return [{ def }];
        }
    }
}

interface LineChartTabExport {
    def: LineChartComponentDef;
    name?: string;
}
