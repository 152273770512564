import { observable, makeObservable } from "mobx";
import { toStream } from "mobx-utils";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { pickIfExists } from "skCommon/utils/object";
import { ComponentDef } from "skCommon/insights/dashboard";

import { DesignerModel } from "skInsights/framework/designer/designerModel";
import { SlideDefinition } from "skInsights/modules/core/components/slides/slides.component";

export class SlideModel {

    @observable.ref
    public title?: string;

    public content: DesignerModel;

    constructor(public index: number, slide: ComponentDef | SlideDefinition) {
        makeObservable(this);

        if ("component" in slide) {
            this.content = new DesignerModel(slide);
        } else {
            this.title = slide.title;
            this.content = new DesignerModel(slide.content);
        }
    }

    public getDefinition$(): Observable<SlideDefinition> {
        return combineLatest([
            toStream(() => this.title, true),
            this.content.definition$,
        ]).pipe(
            map(([title, content]) => ({
                content,
                ...pickIfExists({ title }, "title"),
            })),
        );
    }
}
