import { Method } from "skCommon/core/http";
import { getDefaultConfig } from "skCommon/api/config";
import { urlUtil } from "skCommon/api/config/url";
import { Client } from "skCommon/api/client/simple";
import { Tile } from "skCommon/utils/projection";
import { ImgFetcher } from "skCommon/utils/imgFetcher";

export class MapboxClient extends Client {
    public readonly api = "mapbox";
    public readonly authType = null;
    public readonly contentType = "application/json";

    public getApiKey(): string {
        return getDefaultConfig().getByHost(this.apiObject.data.api_key) as string;
    }

    public loadStyle(styleId: string): Promise<{}> {
        return this.call<{}>({
            endpoint: "styles",
            params: {
                styleId,
                access_token: this.getApiKey(),
            },
            method: Method.Get,
        }).promise;
    }

    public getStaticImageUrl(props: StaticImageProps): string {
        return urlUtil.get(this.apiObject, "static-image", {
            styleId: props.styleId,
            lon: props.coord[0],
            lat: props.coord[1],
            zoom: props.zoom,
            bearing: props.bearing || 0,
            pitch: props.pitch || 0,
            width: props.width,
            height: props.height,
            size: props.retina ? "@2x" : "",
            access_token: this.getApiKey(),
        });
    }

    public getTileJson(id: string): Promise<TileJson> {
        return this.call<TileJson>({
            endpoint: "tile-json",
            params: {
                id: id.replace("mapbox://", ""),
                access_token: this.getApiKey(),
            },
            method: Method.Get,
        }).promise;
    }

    public getTileUrl(tileJson: TileJson, tile: Tile, ext: string = "png"): string {
        const urlTemplate = tileJson.tiles[0].replace(".jpg", "." + ext);
        return urlTemplate
            .replace("{z}", tile[0].toString())
            .replace("{x}", tile[1].toString())
            .replace("{y}", tile[2].toString());
    }

    public downloadTile(tileJson: TileJson, tile: Tile): Promise<HTMLImageElement> {
        return new ImgFetcher({ url: this.getTileUrl(tileJson, tile), cors: true }).fetch();
    }
}

let client: MapboxClient;

export function getMapboxClient() {
    if (!client) {
        client = new MapboxClient();
    }

    return client;
}

interface StaticImageProps {
    styleId: string;
    coord: GeoJSON.Position;
    zoom: number;
    width: number;
    height: number;
    retina?: boolean;
    bearing?: number;
    pitch?: number;
}

export interface TileJson {
    bounds: GeoJSON.BBox;
    center: GeoJSON.Position;
    created: number;
    filesize: number;
    fillzoom: number;
    format: string;
    id: string;
    mapbox_logo: boolean;
    maxzoom: number;
    minzoom: number;
    modified: number;
    name: string;
    private: boolean;
    scheme: string;
    tilejson: string;
    tiles: string[];
    version: string;
    webpage: string;
}
