import { Injectable } from "@angular/core";
import download from "downloadjs";
import Pdf from "jspdf";

import { ChartContext } from "skCommon/chart/chartContext";

@Injectable({
    providedIn: "root",
})
export class ChartExportService {

    public async exportAsPdf(charts: ChartContext[], name: string): Promise<void> {
        const PX_PT = 0.75;
        const WIDTH = 1000;
        const HEIGHT = 800;

        const pdf = new Pdf("l", "pt");
        pdf.deletePage(1);

        for (const chart of charts) {
            const img = await chart.exportAsCanvas(WIDTH, HEIGHT);
            pdf.addPage([WIDTH * PX_PT, HEIGHT * PX_PT], "l");
            pdf.addImage(img, "JPEG", 0, 0, WIDTH * PX_PT, HEIGHT * PX_PT);
        }

        const blob = pdf.output("blob");

        download(blob, name + ".pdf");
    }
}
