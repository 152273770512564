import { Injectable } from "@angular/core";
import { OutputData } from "@editorjs/editorjs";

import { PageExportService } from "skCommon/exports/pageExport/pageExport.service";
import { Doc } from "skCommon/firebase/doc";
import { PageExport } from "skCommon/exports/pageExport/pageExport";

import { DocumentSections, InsightsDocument } from "skInsights/modules/documents/document";
import { emptyOutputData } from "skInsights/partials/blockEditor/outputData";
import { CleanDataExportService } from "skInsights/partials/blockEditor/cleanDataExport.service";
import { DocumentLoaderService } from "skInsights/modules/documents/documentLoader.service";
import { isTemplateSection } from "skInsights/partials/blockEditor/plugins/templateSection/templateSection";

/**
 * Environment-agnostic service that handles parsing and exporting of of the
 * document content. So it can be used in node environment, it shouldn't inject
 * any frontend-specific services.
 */
@Injectable({ providedIn: "root" })
export class DocumentContentService {

    constructor(
        private pageExportService: PageExportService,
        private cleanDataExportService: CleanDataExportService,
        private documentLoaderService: DocumentLoaderService,
    ) { }

    /**
     * Get document content and fetch any used templates, creating a new
     * OutputData structure
     */
    public async getDocumentContent(doc: InsightsDocument): Promise<OutputData> {
        return doc.extends
            ? await this.extendTemplate(doc.extends, doc.sections)
            : doc.content
                ? doc.content
                : emptyOutputData();
    }

    /**
     * Properly load all document's content and export its content as pdf in
     * form of PageExport
     * @see DocumentContentService#exportDocumentContent
     */
    public async exportDocument(doc: Doc<InsightsDocument>): Promise<PageExport> {
        const content = await this.getDocumentContent(doc);
        return this.exportDocumentContent(content);
    }

    /**
     * Export finalized OutputData (there should be no template blocks) into
     * pdf PageExport instance with extra logo & copyright info.
     */
    public async exportDocumentContent(
        content: OutputData,
        watermark?: string,
    ): Promise<PageExport> {
        const page = await this.pageExportService.createPageExport(
            {
                padding: {x: 20, y: {top: 10, bottom: 10}},
                footerText: true,
                headerLogo: true,
                watermark,
            });
        await this.cleanDataExportService.writeToPage(content, page);
        return page;
    }

    private async extendTemplate(
        templateId: string,
        sections: DocumentSections,
    ): Promise<OutputData> {
        const template = await this.documentLoaderService.loadDocument(templateId);

        const templateContent = await this.getDocumentContent(template);

        return {
            ...templateContent,
            blocks: templateContent.blocks.flatMap(
                block => isTemplateSection(block)
                    ? sections[block.data.name]?.blocks || []
                    : [block],
            ),
        };
    }
}
