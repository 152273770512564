import { Injectable } from "@angular/core";
import download from "downloadjs";
import jsPDF from "jspdf";

import { normalize } from "skCommon/utils/normalizeDiacritics";
import { generateUniqueId } from "skCommon/utils/uniqueId";
import { sanitizeFilename } from "skCommon/utils/download";
import { PageExport } from "skCommon/exports/pageExport/pageExport";
import { PageExportOptions } from "skCommon/exports/pageExport/pageExportOptions";
import { FontLoaderService, LocalFont } from "skCommon/fonts/fontLoader.service";
import { ImageRetriever } from "skCommon/exports/pageExport/imageRetriever";


@Injectable({ providedIn: "root" })
export class PageExportService {

    constructor(
        private fontLoaderService: FontLoaderService,
        private imageRetriever: ImageRetriever,
    ) { }

    /**
     * Create PageExport instance which offers an interface for creating PDFs.
     */
    public async createPageExport(options: PageExportOptions = {}): Promise<PageExport> {
        const page = new PageExport(options, this.imageRetriever);

        await this.addFontToPdf(page.pdf, LocalFont.RobotoRegular, "roboto", "regular");
        await this.addFontToPdf(page.pdf, LocalFont.RobotoMedium, "roboto", "medium");
        await this.addFontToPdf(page.pdf, LocalFont.RobotoLight, "roboto", "light");
        await this.addFontToPdf(page.pdf, LocalFont.RobotoLightItalic, "roboto", "light/italic");
        await this.addFontToPdf(page.pdf, LocalFont.RobotoMediumItalic, "roboto", "medium/italic");

        page.addHeaderToFirstPage();
        return page;
    }

    /**
     * Download given PageExport instance as PDF file. Name is sanitized and
     * should not contain the .pdf extension since that is automatically
     * appended.
     */
    public downloadPageExport(pageExport: PageExport, name: string): void {
        const pdfFile = pageExport.finalizePdf();
        const filename = sanitizeFilename(normalize(name)) + ".pdf";

        download(pdfFile, filename, "application/pdf");
    }

    private async addFontToPdf(
        pdf: jsPDF,
        font: LocalFont,
        fontName: string,
        fontStyle: string,
    ): Promise<void> {
        const filename = `${generateUniqueId()}.ttf`;
        const buffer = await this.fontLoaderService.loadFont(font);
        let binStr: string;

        if (typeof FileReader === "function") {
            // Browser or fake dom exists
            const reader = new FileReader();
            reader.readAsBinaryString(new Blob([buffer]));
            binStr = await new Promise<string>((resolve, reject) => {
                reader.onloadend = () => resolve(reader.result as string);
                reader.onerror = () => reject(reader.error);
            });
        } else if (typeof (globalThis as any).Buffer === "function") {
            // Node
            binStr = (globalThis as any).Buffer.from(buffer).toString("binary");
        } else {
            throw new Error("Cannot load font: Neither FileReader nor Buffer is available");
        }
        const ttfBase64 = btoa(binStr);
        pdf.addFileToVFS(filename, ttfBase64);
        pdf.addFont(filename, fontName, fontStyle);
    }
}
