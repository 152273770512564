import { NgModule } from "@angular/core";

import { provideSource } from "skInsights/framework/tokens";
import { OnsSource } from "skInsights/modules/external/sources/ons";

@NgModule({
    providers: [
        provideSource(OnsSource),
    ],
})
export class ExternalModule { }
