import { Injectable } from "@angular/core";

import { LoadingStore } from "skCommon/angular/partials/loading/loading.store";

@Injectable()
export class LoadingService {

    public get loading(): boolean {
        return !!this.loadingStore.loadingCount;
    }

    constructor(
        private loadingStore: LoadingStore,
    ) {}

    public async runAsync<T extends () => any, R = ReturnType<T>>(fn: T): Promise<R> {
        this.loadingStore.loadingCount++;
        let result: R;

        try {
            result = await fn();
        } finally {
            this.loadingStore.loadingCount--;
        }

        return result;
    }
}
