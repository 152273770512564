import { DataProvider } from "skInsights/framework/data/dataProvider";
import { DashboardSeries } from "skInsights/framework/data/structures";

export function isExplorableDataset(
    provider: DataProvider,
): provider is ExplorableDataProvider {
    return "getExplorableGroups" in provider;
}

/**
 * Only data providers which implement the ExplorableDataset interface are then
 * displayed in the Data Explorer interface.
 */
export interface ExplorableDataProvider extends DataProvider {
    getExplorableGroups(parentId: string): ExplorableGroup[];
}

export interface ExplorableGroup {
    id: string;
    typeName: string;
    name: string;
    items: ExplorableItem[];
    buttons: ActionButtons[];
}

export interface ExplorableItem {
    id: string;
    data: DashboardSeries;
    /**
     * Pretty user-readable name
     */
    name?: string;
}

export interface ActionButtons {
    name: string;
    onClick: () => Promise<void>;
    icon: string;

}
