import { Component, ChangeDetectionStrategy } from "@angular/core";
import { when, makeObservable } from "mobx";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { computed, observableRef } from "skCommon/state/mobxUtils";
import { Logger } from "skCommon/utils/logger";
import { SearchSelectOption } from "skCommon/angular/partials/searchSelect/searchSelect.component";
import { getCountries } from "skCommon/flags";
import { propertyExists } from "skCommon/utils/types";
import { Insights } from "skCommon/permissions";

import { InsightsService } from "skInsights/insights.service";
import { DashboardModel } from "skInsights/dashboard/dashboardEditor/dashboardMetaModel";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { DashbaordEditorService } from "skInsights/dashboard/dashboardEditor/dashboardEditor.service";
import { UserService } from "skInsights/user/user.service";

const SAVING = Symbol();

@Component({
    selector: "sk-dashboard-editor-general",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./dashboardEditorGeneral.pug",
})
export class DashboardEditorGeneralComponent extends BaseComponent {

    @observableRef
    public model: DashboardModel = new DashboardModel();

    public get canSave(): boolean {
        return !!this.model.name && !!this.dashbaordEditorService.dashboard?.id;
    }

    public get canPublish(): boolean {
        return this.userService.permissions.has(Insights.Dashboard.Publish);
    }

    public get canDelete(): boolean {
        return !this.dashbaordEditorService.overwriting;
    }

    @computed
    public get availableIcons(): SearchSelectOption<string>[] {
        return getCountries()
            .filter(propertyExists("iconPath"))
            .map(country => ({
                value: country.iconPath,
                label: country.name,
                key: `${country.name} ${country.code}`.toLocaleLowerCase(),
                img: country.iconPath,
            }));
    }

    constructor(
        private snackBarService: SnackBarService,
        private logger: Logger,
        private insightsService: InsightsService,
        private dashbaordEditorService: DashbaordEditorService,
        private userService: UserService,
    ) {
        super();

        makeObservable(this);
    }

    public async ngOnInit(): Promise<void> {
        super.ngOnInit();

        await when(() => !!this.dashbaordEditorService.dashboard);
        this.model = new DashboardModel(this.dashbaordEditorService.dashboard);

        this.whenever(() => this.model.promoted, () => {
            this.model.global = true;
        });

        this.whenever(() => !this.model.global, () => {
            this.model.promoted = false;
        });
    }

    public async saveMetadata(): Promise<void> {
        if (!this.canSave) {
            return;
        }

        try {
            this.insightsService.globalLoadingProcesses.add(SAVING);

            await this.dashbaordEditorService.updateDashboard(
                this.model.toObject(),
            );
        } catch (e) {
            this.snackBarService.notify(`Dashboard could not saved: ${e.message}`);
            this.logger.error(e, "Saving dashboard in editor");
        } finally {
            this.insightsService.globalLoadingProcesses.delete(SAVING);
        }
    }

    public async deleteDashboard(): Promise<void> {
        try {
            this.insightsService.globalLoadingProcesses.add(SAVING);

            await this.dashbaordEditorService.deleteDashboard();
        } catch (e) {
            this.snackBarService.notify(`Dashboard could not deleted: ${e.message}`);
            this.logger.error(e, "Deleting dashboard");
        } finally {
            this.insightsService.globalLoadingProcesses.delete(SAVING);
        }
    }

}
