/**
 * Number of milliseconds in a day.
 */
export const DAY_TIME = 24 * 60 * 60 * 1000;

export function dateToMonthNumber(date: Date): MonthNumber {
    return (date.getUTCFullYear() - 1970) * 12 + date.getUTCMonth();
}

/**
 * @param mon Ordinal number of the month since 1970-01-01
 * @return Month represented in YYYY-MM string. For example:
 *  0 => 1970-01
 *  12 => 1971-01
 *  422 => 2005-03
 */
export function monthNumberToYearMonth(mon: MonthNumber): string {
    const yearMonth = getYearMonth(mon);

    return yearMonth[0]
        + "-"
        + (yearMonth[1] + 1).toString().padStart(2, "0");
}

export function monthNumberToDate(mon: MonthNumber): Date {
    const yearMonth = getYearMonth(mon);

    return new Date(Date.UTC(yearMonth[0], yearMonth[1], 1));
}

function getYearMonth(mon: MonthNumber): [number, number] {
    const monthNum = mon % 12;

    return [
        1970 + Math.floor(mon / 12),
        monthNum,
    ];
}

/**
 * Create months interval from two dates. When date is too close to the start
 * or to the end of the month, then the month is skipped.
 *
 * @return Pair of two ordinal representation of months since 1970-01
 */
export function monthInterval(from: Date, to: Date): MonthInterval {
    let fromMon = dateToMonthNumber(from),
        toMon = dateToMonthNumber(to);

    if (fromMon !== toMon) {
        if (from.getUTCDate() > 28) {
            fromMon++;
        }
        if (to.getUTCDate() < 2) {
            toMon--;
        }

        if (toMon < fromMon) {
            toMon = fromMon;
        }
    }

    return [fromMon, toMon];
}

export function setGMTToZero(date: Date): Date {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}

export function parseDurationString(duration: string): [number, string] {
    const groups = duration.match(/^ *([0-9]+) *([A-z]+) *$/);

    if (groups && groups.length === 3) {
        const [, amountStr, unit] = groups;
        const amount = parseFloat(amountStr);

        if (amount && unit && !Number.isNaN(amount)) {
            return [amount, unit];
        }
    }

    throw new Error(`Invalid duration ${duration}`);
}

/**
 * Month represented as ordinal number since 1970-01-01. E.g.
 *  0 => 1970-01
 *  12 => 1971-01
 *  422 => 2005-03
 */
export type MonthNumber = number;

/**
 * @typedef MonthInterval
 * @type {Array}
 * @property {MonthNumber} 0 - First month.
 * @property {MonthNumber} 1 - Last month.
 */
export type MonthInterval = [number, number];
