import { CtxError, SkError } from "skCommon/core/error";

export enum LogLevel {
    Debug,
    Info,
    Warning,
    Error,
}

export class Logger {
    private disabled = false;
    private logLevel: LogLevel = LogLevel.Info;

    /**
     * Fields that will be logged by default in all log events
     */
    private boundFields: Record<string, any> = {};

    private transports: Set<LoggerTransport> = new Set<LoggerTransport>();

    private prefix: string = "";

    constructor() {}

    public extend(boundFields: Record<string, any>) {
        const extended = new Logger();

        extended.transports = this.transports;
        extended.logLevel = this.logLevel;
        extended.boundFields = {
            ...this.boundFields,
            ...boundFields,
        };

        return extended;
    }

    /**
     * From now on prefix each log entry with given text.
     */
    public setPrefix(prefix: string): void {
        this.prefix = prefix;
    }

    public setLogLevel(level: LogLevel) {
        this.logLevel = level;
    }

    public getLogLevel() {
        return this.logLevel;
    }

    public addTransport(transport: LoggerTransport) {
        this.transports.add(transport);
    }

    public setTransports(transports: Set<LoggerTransport>) {
        this.transports = transports;
    }

    public addBoundField(name: string, value: any) {
        this.boundFields[name] = value;
    }

    public removeBoundField(name: string) {
        delete this.boundFields[name];
    }

    public setBoundFields(fields: Object): void {
        this.boundFields = fields;
    }

    public disable() {
        this.disabled = true;
    }

    public enable() {
        this.disabled = false;
    }

    ///
    ///
    /// Log API
    ///
    ///

    public error(err: Error, context?: string): void {
        if (!this.shouldLog(LogLevel.Error)) {
            return;
        }

        if (!err) {
            return;
        }

        if (typeof err === "string") {
            // Someone threw very ugly error!
            err = new Error(`String thrown: ${err}`);
        }

        let dataToLog: Record<string, any> = {
            stack: err.stack,
        };

        if (err instanceof SkError) {
            dataToLog = Object.assign(dataToLog, err.dataToLog);

            if (err.trace) {
                dataToLog.trace = err.trace;
            }
        }

        if (context) {
            dataToLog.errorContext = context;
        }

        if ("skCtx" in err) {
            Object.assign(dataToLog, (err as CtxError).skCtx);
        }

        this.processLogEntry(
            LogLevel.Error,
            `${err.name}: ${err.message}`,
            dataToLog,
        );
    }

    public warning(message: string, additionalData?: Object): void {
        if (!this.shouldLog(LogLevel.Warning)) {
            return;
        }

        this.processLogEntry(LogLevel.Warning, message, additionalData);
    }

    public info(message: string, additionalData?: Object): void {
        if (!this.shouldLog(LogLevel.Info)) {
            return;
        }

        this.processLogEntry(LogLevel.Info, message, additionalData);
    }

    public debug(message: string, additionalData?: Object): void {
        if (!this.shouldLog(LogLevel.Debug)) {
            return;
        }

        this.processLogEntry(LogLevel.Debug, message, additionalData);
    }

    private processLogEntry(level: LogLevel, message: string,
            additionalData: Object): void {
        message = `${this.prefix}${message}`;

        const dataToLog = Object.assign(
            { level, message },
            { context: this.boundFields || {} },
            { details: additionalData || {} },
        );

        this.transports.forEach((transport) => {
            transport.log(dataToLog);
        });
    }

    private shouldLog(level: LogLevel) {
        return !this.disabled && level >= this.logLevel;
    }
}

let loggerInstance: Logger;

export function getLogger() {
    if (!loggerInstance) {
        loggerInstance = new Logger();
    }

    return loggerInstance;
}

///
///
/// Interfaces
///
///

export interface LogEntry {
    level: LogLevel;
    message: string;
    details: Record<string, any>;
    context: Record<string, any>;
}

export interface LoggerTransport {
    log(logEntry: LogEntry | string): void;
}
