import "core-js/es6/reflect";
import "core-js/es7/reflect";
import "zone.js/dist/zone-evergreen.min.js";
import { enableProdMode, ApplicationRef, Type, NgModuleRef } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { enableDebugTools } from "@angular/platform-browser";
import { configure } from "mobx";

import { BuildEnvironment, getBuildEnv } from "skCommon/core/manifest";
import { getLogger } from "skCommon/utils/logger";
import { registerAuthClient } from "skCommon/auth/client";
import { Auth0Client } from "skCommon/auth0/client";
import { ConsoleTransport } from "skCommon/utils/logger/transport/console";
import { Stackdriver } from "skCommon/utils/logger/transport/stackdriver";

/**
 * Entry file main.ts is very similar for each angular application so this
 * function handles all the generic calls so the main.ts of each app can focus
 * on app-specific configuration. This module should be ideally imported before
 * any other skCommon module, since it imports zone and some shims.
 *
 * Responsibilities:
 *  - setup mobx
 *  - setup auth client
 *  - correctly initialize logger
 *  - enable angular debug / production modes respectively
 *  - bootstrap the module
 */
export async function bootstrapApplication(options: BootstrapOptions): Promise<void> {
    const productionMode = getBuildEnv() === BuildEnvironment.Production;

    // Mobx configuration
    configure({
        // We do not use actions when not necessary
        enforceActions: "never",
    });

    if (!options.omitAuthClient) {
        registerAuthClient(new Auth0Client());
    }

    initializeLogger(productionMode);
    handleUncaughtErrors();

    if (productionMode) {
        enableProdMode();
    }

    const moduleRef = await platformBrowserDynamic().bootstrapModule(options.module);

    if (!productionMode) {
        enableAngularDebugTools(moduleRef);
    }
}

function enableAngularDebugTools(moduleRef: NgModuleRef<any>): void {
    const applicationRef = moduleRef.injector.get(ApplicationRef);
    const appComponent = applicationRef.components[0];
    enableDebugTools(appComponent);
}

function initializeLogger(
    productionMode: boolean,
): void {
    const rootLogger = getLogger();

    if (productionMode) {
        rootLogger.addTransport(new Stackdriver());
    } else {
        rootLogger.addTransport(new ConsoleTransport());
    }
}

function handleUncaughtErrors(): void {
    const rootLogger = getLogger();

    window.onerror = (
        main: string | Event,
        _filename?: string,
        _lineno?: number,
        _colno?: number,
        error?: Error,
    ) => rootLogger.error(main instanceof ErrorEvent ? main.error : error);
}

export interface BootstrapOptions {
    /**
     * Application's angular module to bootstrap
     */
    module: Type<any>;
    /**
     * Do not automatically initialize authentication client
     */
    omitAuthClient?: boolean;
}
