import { NgModule } from "@angular/core";

import { provideOperation } from "skInsights/framework/tokens";
import { ChangeOperation } from "skInsights/modules/statistics/operations/change";
import { CumulativeRollingAverageOperation } from "skInsights/modules/statistics/operations/cumulativeRollingAverage";
import { SimpleRollingAverageOperation } from "skInsights/modules/statistics/operations/simpleRollingAverage";
import { ResampleOperation } from "skInsights/modules/statistics/operations/resample";
import { StdDevFilterOperation } from "skInsights/modules/statistics/operations/stdDevFilter";

@NgModule({
    providers: [
        provideOperation(ChangeOperation),
        provideOperation(SimpleRollingAverageOperation),
        provideOperation(CumulativeRollingAverageOperation),
        provideOperation(ResampleOperation),
        provideOperation(StdDevFilterOperation),
    ],
})
export class StatisticsModule { }
