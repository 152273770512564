import { NgModule } from "@angular/core";
import { DragDropModule } from "@angular/cdk/drag-drop";

import { ChartModule as CommonChartModule } from "skCommon/chart/angular/chart.module";
import { ToggleChipModule } from "skCommon/ui/toggleChip/toggleChip.module";
import { SkeletonModule } from "skCommon/ui/skeleton/skeleton.module";

import { provideComponent } from "skInsights/framework/tokens";
import { LineChartComponentDef } from "skInsights/modules/charts/lineChart/definitions";
import { LineChartComponent, LineChartComponentType } from "skInsights/modules/charts/lineChart/lineChart.component";
import { SharedModule } from "skInsights/shared.module";
import { ChartRipplesComponent } from "skInsights/modules/charts/lineChart/misc/chartRipples.component";
import { RichTextModule } from "skInsights/partials/richText/richText.module";
import { ButtonsModule } from "skInsights/partials/buttons/buttons.module";
import { ZoomButtonModule } from "skInsights/partials/zoomButton/zoomButton.module";
import { LineChartStaticRendererService } from "skInsights/modules/charts/lineChart/lineChartStaticRenderer.service";

@NgModule({
    imports: [
        SharedModule,
        ButtonsModule,
        CommonChartModule,
        DragDropModule,
        RichTextModule,
        ZoomButtonModule,
        ToggleChipModule,
        SkeletonModule,
    ],
    declarations: [
        LineChartComponent,
        ChartRipplesComponent,
    ],
    providers: [
        provideComponent(LineChartComponentType, LineChartComponent, {
            staticRenderer: LineChartStaticRendererService,
            getComponentSources: (def: LineChartComponentDef) => {
                return def.series.map((s) => s.data);
            },
        }),
    ],
})
export class ChartsModule { }
