import { Injectable, Type } from "@angular/core";
import { ChartConfiguration } from "chart.js";

import { Logger } from "skCommon/utils/logger";
import { registerNearestEachTooltipMode } from "skCommon/chart/plugins/nearestEachTooltipMode";
import { registerLineCharts } from "skCommon/chart/register/lineCharts";
import { ChartContext } from "skCommon/chart/chartContext";
import { AnnotationPlugin, annotationPlugin } from "skCommon/chart/plugins/annotation";
import { ObservableDatasets, observableDatasets } from "skCommon/chart/plugins/observableDatasets";
import { SeriesGroups, seriesGroups } from "skCommon/chart/plugins/seriesGroups";
import { zoomPlugin } from "skCommon/chart/plugins/zoom";
import { exists } from "skCommon/utils/types";

import { DashboardSeries } from "skInsights/framework/data/structures";
import { isSeriesPair } from "skInsights/framework/data/helpers";
import { parseDataRef } from "skInsights/framework/query/dataRef";
import { ChartDatasetInput, ChartEventDef, ChartInputSeries, ChartSeriesDef, LineChartComponentDef } from "skInsights/modules/charts/lineChart/definitions";
import { DataRefService } from "skInsights/framework/dataRef.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { DatasetInternalOptions, makeAnnotationOptions, makeChartOptions } from "skInsights/modules/charts/helpers/chartOptions";
import { getAvailableColors } from "skInsights/modules/charts/helpers/chartColors";
import { parseDateString } from "skInsights/utils/dateString";

/**
 * Service used to construct and configure chartjs instances so 1. the
 * component isn't so crowded, 2. can be re-used in static renderer.
 */
@Injectable()
export class ChartBuilderService {

    constructor(
        private dataRefService: DataRefService,
        private logger: Logger,
        private snackBarService: SnackBarService,
    ) { }

    /**
     * Load all data and create chart context instance according to the definition
     */
    public async loadAndCreateChart(def: LineChartComponentDef): Promise<ChartContextWithPlugins> {
        return this.createChart(def, await this.loadDatasets(def));
    }

    /**
     * Create ChartContext instance according to given chart definition.
     * Provided tabs are used chart groups and the first group is selected by
     * default.
     */
    public async createChart(
        def: LineChartComponentDef,
        datasets: ChartDatasetInput[],
    ): Promise<ChartContextWithPlugins> {
        this.registerChartJs();
        const Chart = this.getChartContextWithPlugins();
        const flattenedDatasets = this.flattenAllSeries(datasets);
        const chartJsOptions = await this.prepareChartOptions(def, flattenedDatasets);

        return new Chart(chartJsOptions);
    }

    /**
     * Load all data required by given chart definition
     */
    public async loadDatasets(def: LineChartComponentDef): Promise<ChartDatasetInput[]> {
        const promises = def.series.map(series => this.resolveSeriesDef(series));
        const resolved = await Promise.all(promises);
        const datasetData = resolved.flat();

        return await Promise.all(
            datasetData.map((source, i) => this.prepareSeriesOptions(source, i)),
        );
    }

    /**
     * Since single dataset definition may be resolved into multiple timeseries,
     * flatten those so there's object for each timeseries.
     */
    private flattenAllSeries(datasets: ChartDatasetInput[]): DatasetInternalOptions[] {
        return datasets.flatMap(d => [
            d.inputSeries.main.options,
            d.inputSeries.aux?.options,
        ].filter(exists));
    }

    /**
     * Return chart context class with all required mixins applied
     */
    private getChartContextWithPlugins(): Type<ChartContextWithPlugins> {
        return seriesGroups(annotationPlugin(observableDatasets(zoomPlugin(ChartContext))));
    }

    private registerChartJs(): void {
        registerNearestEachTooltipMode();
        registerLineCharts();
    }

    /**
     * Create chartjs input options according to the chart definition and
     * loaded datasets.
     */
    private async prepareChartOptions(
        def: LineChartComponentDef,
        datasets: DatasetInternalOptions[],
    ): Promise<ChartConfiguration> {
        const tabIds = Object.keys(def.tabs || {});

        return makeChartOptions({
            datasets,
            axes: def.axes,
            dateFormat: def.dateFormat,
            dateRange: def.dateRange,
            visibleGroup: tabIds.length ? tabIds[0] : undefined,
            annotations: await this.makeAnnotations(def.events),
        });
    }

    /**
     * Create options for the chartjs annotations plugin according to the
     * annotations configuration from chart definition.
     */
    private async makeAnnotations(dict?: Record<string, ChartEventDef>): Promise<any[]> {
        if (!dict) {
            return [];
        }

        const events = Object.entries(dict);
        const usedColors = events.map(e => e[1].color!).filter(cl => cl);
        const colors = getAvailableColors(usedColors);

        const eventPromises = events.map(async ([id, ev]) => {
            const color = ev.color || colors.next().value;
            const label = ev.label && await this.dataRefService.interpolate(ev.label);
            const date = parseDateString(
                await this.dataRefService.interpolate(ev.date),
            );

            return makeAnnotationOptions({
                id,
                date,
                color,
                label,
                hover: !!ev.detail,
            });
        });

        return Promise.all(eventPromises);
    }

    /**
     * Create dataset options out of resolved dashboard series and its
     * definition
     */
    private async prepareSeriesOptions(
        { series, def }: LoadedChartSeries,
        seriesIndex: number,
    ): Promise<ChartDatasetInput> {
        const color = def.color;
        const label = def.label
            ? await this.dataRefService.interpolate(def.label)
            : (series.meta.name || `Time Series ${seriesIndex}`);
        const mainRef = seriesIndex.toString();
        const main: ChartInputSeries = {
            options: {
                refs: [mainRef],
                group: def.tab,
                scale: def.scale,
                series: series.series,
                color,
                label,
                hidden: def.disabled ? true : false,
                width: def.width,
                dash: def.dash,
                dashOffset: def.dashOffset,
            },
            series,
        };

        let aux: ChartInputSeries | undefined;

        if (def.aux) {
            const productId = parseDataRef(def.data).id;
            const auxQuery = `${productId}/aux/${series.meta.name}/${def.aux}`;
            const auxSeries = await this.dataRefService.resolveAny(auxQuery);

            if (!isSeriesPair(auxSeries)) {
                throw new Error("Only series-pair aux series are currently supported");
            }

            aux = {
                series: auxSeries,
                options: {
                    ...main.options,
                    refs: [mainRef, `${mainRef}-aux`],
                    series: auxSeries.pair,
                    label: `${main.options.label} ${auxSeries.meta.name}`,
                    hidden: def.disabled || def.auxDisabled ? true : false,
                },
            };
        }

        return {
            def,
            inputSeries: { main, aux },
        };
    }

    /**
     * Load data for given chart series definition
     */
    private async resolveSeriesDef(
        def: ChartSeriesDef,
    ): Promise<LoadedChartSeries[]> {
        try {
            const flatten = await this.dataRefService.resolveFlattenSeries(def.data);

            return flatten.map(series => ({ series, def }));
        } catch (e) {
            let msg = `Could not load data for chart series ${def.data}`;
            this.logger.error(e, msg);

            msg += `: ${e.message}`;
            this.snackBarService.notify(msg, 10);

            return [];
        }
    }
}

/**
 * Chart context class with all plugin mixins we use here
 */
export type ChartContextWithPlugins = ChartContext
    & ObservableDatasets
    & AnnotationPlugin
    & SeriesGroups;

/**
 * Seried definition with already loaded data
 */
interface LoadedChartSeries {
    series: DashboardSeries;
    def: ChartSeriesDef;
}
