import { getLogger, Logger } from "skCommon/utils/logger";
import { callFetch as fetch } from "skCommon/core/fetch";
import { Backoff } from "skCommon/utils/backoff";
import { SkError } from "skCommon/core/error";
import { sleep } from "skCommon/utils/delay";

let defaultHttp: Http;

const MAX_RETRIES_NETWORK_ERROR = 12;
const MAX_RETRIES_SERVER_ERROR = 3;

export class Http {
    protected log: Logger = getLogger();

    public async request<T>(
        url: string,
        options: RequestOptions,
    ) {
        let result: T,
            retry = 0;
        const trace = !!options.trace
            ? options.trace
            : undefined;

        const config: RequestConfig = {...options};

        config.maxRetriesOnNetworkError = config.maxRetriesOnNetworkError >= 0
            ? config.maxRetriesOnNetworkError
            : MAX_RETRIES_NETWORK_ERROR;
        config.maxRetriesOnServerError = config.maxRetriesOnServerError >= 0
            ? config.maxRetriesOnServerError
            : MAX_RETRIES_SERVER_ERROR;
        config.dontLogProperties = config.dontLogProperties
            || Object.create(null);

        if (!!config.body) {
            try {
                if (
                    typeof config.body === "string"
                    || typeof Blob !== "undefined" && config.body instanceof Blob
                ) {
                    config.payload = config.body;
                } else if (
                    config.headers
                    && config.headers.has("Content-Type")
                    && config.headers.get("Content-Type").includes("application/x-www-form-urlencoded")
                ) {
                    config.payload = (Object.entries(config.body) as [string, string][])
                        .map(([k, v]) => [k, encodeURIComponent(v)])
                        .map(tuple => tuple.join("="))
                        .join("&");
                } else {
                    config.payload = JSON.stringify(config.body);
                }
            } catch (e) {
                let err: HttpRequestError;

                err = new HttpRequestError(
                    HttpRequestError.ERR_DEFAULT,
                    url,
                );
                err.trace = trace;

                throw err;
            }
        }

        this.log.debug("HTTP request", {
            retry,
            trace: trace,
            method: config.method,
            url,
        });

        result = await this.try<T>(retry, url, config);

        while (result instanceof SkError) {
            const maxRetries = result instanceof HttpFetchError
                ? config.maxRetriesOnNetworkError
                : config.maxRetriesOnServerError;

            // repeat only on error responses with status code of 500 and above,
            // too many requests error or on network errors
            if (result instanceof HttpResponseError) {
                if (result.status < 500 && result.status !== 429) {
                    break;
                }
            }

            retry++;

            // do not break if the browser is offline
            if (
                isOnline()
                // break when retries exceeded maximum
                && retry > maxRetries
            ) {
                break;
            }

            result.skipLog = true;

            this.log.debug("HTTP request", {
                retry,
                trace: trace,
                method: config.method,
                url,
            });

            result = await this.try<T>(retry, url, config);
        }

        if (result instanceof SkError) {
            result.trace = trace;
            throw result;
        }

        return result;
    }

    private try<T>(attempt: number, url: string, config: RequestConfig) {
        let result: Promise<T>;

        // retry at least once per 30 seconds
        result = sleep(Math.min(Backoff.calculate(attempt), 30) * 1000)
            .then(() => this.requestPromise<T>(url, config))
            .catch((err) => err);

        return result;
    }

    private async requestPromise<T>(url: string, config: RequestConfig) {
        let response: Response;

        try {
            response = await fetch(url, {
                method: config.method,
                body: config.payload,
                headers: config.headers,
                redirect: "follow",
            });
        } catch (e) {
            let error: HttpFetchError;

            error = new HttpFetchError(
                HttpFetchError.ERR_DEFAULT,
                url,
            );

            error.previousError = e;

            throw error;
        }

        let err: HttpResponseError,
            result: T;

        if (!response.ok && response.status !== 424) {
            let errCode: string;

            if (response.status === 401) {
                errCode = HttpResponseError.ERR_AUTH;
            } else {
                errCode = HttpResponseError.ERR_NOT_OK;
            }

            err = new HttpResponseError(
                errCode,
                response.url,
                response.status,
                await response.text(),
            );

            throw err;
        }

        try {
            if (config.responseFormat === RESPONSE_FORMAT.JSON) {
                const text = await response.text();
                const infinity = `:null`;
                result = JSON.parse(text.replaceAll(":Infinity", infinity));
            } else if (config.responseFormat === RESPONSE_FORMAT.BLOB) {
                result = <any>(await response.blob());
            } else {
                // If PLAIN or none
                result = <any>(await response.text());
            }
        } catch (e) {
            const error = new HttpResponseError(
                HttpResponseError.ERR_PARSE,
                response.url,
                response.status,
            );

            error.trace = config.trace;

            throw error;
        }

        return result;
    }
}

defaultHttp = new Http();

export function getDefaultHttp(): Http {
    return defaultHttp;
}

export function setDefaultHttp(httpInst: Http) {
    defaultHttp = httpInst;
}

export const CONTENT_TYPES = {
    JSON: "application/json;charset=utf-8",
    PLAIN: "text/plain;charset=utf-8",
};

export enum Method {
    Post = "POST",
    Get = "GET",
    Put = "PUT",
    Delete = "DELETE",
    Patch = "PATCH",
}

///
///
/// Errors
///
///

export abstract class HttpError extends SkError { }

export class HttpFetchError extends HttpError {
    public static readonly MESSAGES = new Map(<[string, string][]>[
        [
            HttpFetchError.ERR_DEFAULT,
            "Request failed because of an http error",
        ],
    ]);

    get dataToLog() {
        return {
            url: this.url,
        };
    }

    constructor(
        code: string,
        public url?: string,
    ) {
        super("HttpFetchError", code);
    }
}

export class HttpRequestError extends HttpError {
    public static readonly MESSAGES = new Map(<[string, string][]>[
        [
            HttpRequestError.ERR_DEFAULT,
            "Failed to process request body",
        ],
    ]);

    get dataToLog() {
        return {
            url: this.url,
        };
    }

    constructor(
        code: string,
        public url?: string,
    ) {
        super("HttpRequestError", code);
    }
}

export class HttpResponseError extends HttpError {
    public static readonly ERR_PARSE = "ERR_PARSE";
    public static readonly ERR_AUTH = "ERR_AUTH";
    public static readonly ERR_NOT_OK = "ERR_NOT_OK";

    public static readonly MESSAGES = new Map(<[string, string][]>[
        [
            HttpResponseError.ERR_PARSE,
            "Failed to parse request response",
        ],
        [
            HttpResponseError.ERR_NOT_OK,
            "Request failed because of a server error",
        ],
        [
            HttpResponseError.ERR_AUTH,
            "Request failed because of an authentication error",
        ],
    ]);

    get dataToLog() {
        return {
            url: this.url,
            status: this.status,
            responseBody: this.responseBody,
        };
    }

    constructor(
        code: string,
        public url?: string,
        public status?: number,
        public responseBody?: string,
    ) {
        super("HttpResponseError", code);
    }
}

///
///
/// Interfaces
///
///

export const RESPONSE_FORMAT = {
    JSON: <ResponseFormat>"JSON",
    PLAIN: <ResponseFormat>"PLAIN",
    BLOB: <ResponseFormat>"BLOB",
};

export type ResponseFormat = "JSON" | "PLAIN" | "BLOB";

export interface RequestOptions {
    method?: string;
    body?: {} | string;
    params?: {};
    headers?: Headers;
    responseFormat?: ResponseFormat;
    maxRetriesOnServerError?: number;
    maxRetriesOnNetworkError?: number;
    dontLogProperties?: {};
    skipAuthentication?: boolean;
    trace?: string;
}

interface RequestConfig extends RequestOptions {
    payload?: string | Blob;
}

function isOnline() {
    return typeof navigator === "undefined"
        || navigator.onLine !== false;
}

export interface ErrorResponse {
    error: string;
    errorMessage: string;
}
