import { assert } from "skCommon/utils/assert";
import { AnySourceDef, DataRef } from "skCommon/insights/dashboard";

import { AnyDashboardData, DashboardDataType } from "skInsights/framework/data/structures";
import { isCollection } from "skInsights/framework/data/helpers";
import { parseDataRef } from "skInsights/framework/query/dataRef";
import { DataProvider } from "skInsights/framework/data/dataProvider";
import { DashboardPipe } from "skInsights/framework/pipe";
import { DashboardSource } from "skInsights/framework/abstract/dashboardSource";

/**
 * Class used to provide data for dashboard components. Stores all loaded
 * sources and should only exist once per dashboard.
 */
export class DashboardData {

    private sourceDataProviders: Map<string, Promise<DataProvider>> = new Map();

    private sourceRecipes: Map<string, SourceRecipe> = new Map();

    constructor(private pipes: Record<string, DashboardPipe>) { }

    public getNamedPipe(name: string): DashboardPipe {
        if (this.pipes[name]) {
            return this.pipes[name];
        } else {
            throw new Error(`Unknown named pipe "${name}"`);
        }
    }

    public hasSource(id: string): boolean {
        return this.sourceRecipes.has(id);
    }

    public addSource(id: string, src: SourceRecipe): void {
        const existingSource = this.sourceRecipes.get(id);

        if (existingSource && existingSource !== src) {
            throw new Error(`Source ${id} already exists in dashboard data provider`);
        }

        this.sourceRecipes.set(id, src);
    }

    public async resolve(ref: DataRef): Promise<AnyDashboardData> {
        const refObj = parseDataRef(ref);

        const provider = await this.getSourceDataProvider(refObj.id);

        if (provider) {
            const data = await provider.select(refObj.select);

            if (data && (data.type !== DashboardDataType.Collection || isCollection(data))) {
                return data;
            } else {
                throw new Error(`No data found for ${refObj.id}(${refObj.select})`);
            }
        } else {
            throw new Error(`Source "${refObj.id}" is not available`);
        }
    }

    public async preloadAllSources(
        options: PreloadAllOptions = {},
    ): Promise<RegisteredDataProvider[]> {
        const sourceEntries = [...this.sourceRecipes.entries()];
        const promises = sourceEntries
            .filter(([, recipe]) =>
                (!recipe.definition.hidden
                || options.includeHidden)
                && !recipe.definition.omitFromDataExplorer)
            .map(async ([sourceId]) => {
                const provider = await this.getSourceDataProvider(sourceId);

                return { sourceId, provider };
            });

        return Promise.all(promises);
    }

    private async getSourceDataProvider(sourceId: string): Promise<DataProvider> {
        let providerPromise = this.sourceDataProviders.get(sourceId);

        if (!providerPromise) {
            providerPromise = this.createSourceDataProvider(sourceId);
            this.sourceDataProviders.set(sourceId, providerPromise);
        }

        return providerPromise;
    }

    private createSourceDataProvider(sourceId: string): Promise<DataProvider> {
        const recipe = this.sourceRecipes.get(sourceId);

        assert(recipe, `Source ${sourceId} doesn't exist`);

        return recipe.source.create(recipe.definition, this);
    }
}

export interface DashboardRef {
    id: string;
    revisionId?: string;
}

export interface RegisteredDataProvider {
    sourceId: string;
    provider: DataProvider;
}

/**
 * Structure containing everything needed to load data for a source
 */
interface SourceRecipe {
    definition: AnySourceDef;
    source: DashboardSource<any>;
}

interface PreloadAllOptions {
    includeHidden?: boolean;
}
