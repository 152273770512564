import { Observable, fromEvent, of, merge, Subscription } from "rxjs";
import { map, filter } from "rxjs/operators";

import { SkError } from "skCommon/core/error";
import { ClientOptions } from "skCommon/api/client/simple";

let defaultAuthentication: Authentication;

class Authentication {
    private authenticators = new Map<string, AuthenticatorInterface<AuthenticationData>>();
    private authData = new Map<string, AuthenticationData>();
    private storedAuthDataSubscriptions = new Map<string, Subscription>();

    private renewProcesses = new Map<string, Promise<boolean>>();

    public registerAuthenticator(
        type: string,
        authenticator: AuthenticatorInterface<AuthenticationData>,
    ) {
        this.authenticators.set(type, authenticator);
        this.watchAuthenticationData(type);
    }

    /**
     * @param temp Do not store given authentication data, only use them for
     *  this session.
     */
    public setAuthenticationData(
        type: string,
        data: AuthenticationData,
        temp: boolean = false,
    ) {
        if (!!data) {
            this.authData.set(type, data);
        } else {
            this.authData.delete(type);
        }

        if (!temp) {
            this.saveAuthenticationData(type, data);
        }
    }

    public getAuthenticationData(type: string) {
        this.checkAuthDataPresence(type);

        return this.authData.get(type);
    }

    public hasAuthenticationData(type: string): boolean {
        return this.authData.has(type);
    }

    public setRequestAuthData(type: string,
        payload: AuthDataPayload): AuthDataPayload {

        this.checkAuthenticatorRegistration(type);
        this.checkAuthDataPresence(type);

        const authData = this.authData.get(type);
        const authenticator = this.authenticators.get(type);

        // pass request options and authData to authenticator since
        // authenticator knows how to set the data
        try {
            if (authenticator.authType & AuthenticationType.Options) {
                authenticator.setRequestOptions(
                    payload.api,
                    payload.options,
                    authData,
                );
            }

            if (authenticator.authType & AuthenticationType.Url) {
                const url = authenticator.setRequestUrl(payload.url, authData);

                payload.url = url;
            }
        } catch (e) {
            this.throwAuthenticatorError(
                type,
                AuthenticationError.ERR_AUTHENTICATOR_RUNTIME,
                e,
            );
        }

        return payload;
    }

    public async validate(type: string) {
        const authenticator = this.authenticators.get(type);

        this.checkAuthenticatorRegistration(type);
        this.checkAuthDataPresence(type);

        const authData = this.authData.get(type);

        return await authenticator.validateAuth(authData);
    }

    public async renew(type: string): Promise<boolean> {
        if (!this.renewProcesses.has(type)) {
            const promise = this.runRenewProcess(type)
                .finally(() => this.renewProcesses.delete(type));

            this.renewProcesses.set(type, promise);
        }

        return this.renewProcesses.get(type);
    }

    /**
     * @param type Type of authentication to be revoked
     * @param silent When true, the revocation is non intrusive and
     *  doesn't really affect the application.
     * @throws {AuthenticationError} Thrown when no auth data present.
     */
    public async revoke(type: string, silent: boolean = false) {
        const authenticator = this.authenticators.get(type);

        this.checkAuthenticatorRegistration(type);
        this.checkAuthDataPresence(type);

        try {
            const removedData = this.getAuthenticationData(type);

            // Whatever will the revoke handler do, remove the authentication
            // data first. That way handler can freely redirect user.
            // Also failure in revoke handler may result in unloggoutable
            // state, which is worse, that incorrect revocation.
            this.setAuthenticationData(type, null);
            this.clearAuthDataSubscription(type);

            await authenticator.revokeAuth(removedData, silent);

            return true;
        } catch (e) {
            if (e instanceof SkError) {
                return false;
            }

            throw e;
        }
    }

    private async runRenewProcess(type: string): Promise<boolean> {
        const authenticator = this.authenticators.get(type);

        this.checkAuthenticatorRegistration(type);
        this.checkAuthDataPresence(type);

        const authData = this.authData.get(type);

        const newAuthData = await authenticator.renewAuth(authData);

        if (newAuthData) {
            this.setAuthenticationData(type, newAuthData);
            return true;
        }

        return false;
    }

    private saveAuthenticationData(type: string, authData: AuthenticationData) {
        if (typeof window !== "undefined") {
            if (!!authData) {
                window.localStorage.setItem(type, JSON.stringify(authData));
            } else {
                window.localStorage.removeItem(type);
            }
        }
    }

    private getStoredAuthenticationData$(type: string): Observable<AuthenticationData | null> {
        if (typeof window === "undefined") {
            return of(null);
        }

        return merge(
            of(null),
            fromEvent<StorageEvent>(window, "storage")
                .pipe(filter(e => e.key === type)),
        ).pipe(
            map(() => window.localStorage.getItem(type)),
            map(json => json ? JSON.parse(json) : null),
        );
    }

    private watchAuthenticationData(type: string) {
        const sub = this.getStoredAuthenticationData$(type)
            .subscribe(data => this.updateAuthenticationData(type, data));

        this.storedAuthDataSubscriptions.set(type, sub);
    }

    private updateAuthenticationData(type: string, authData: AuthenticationData): void {
        if (authData) {
            this.authData.set(type, authData);
        } else if (this.authData.has(type)) {
            // Auth data were removed from storage => user probably logged out
            this.revoke(type);
        }
    }

    private checkAuthDataPresence(type: string) {
        if (!this.authData.has(type)) {
            throw new AuthenticationError(
                AuthenticationError.ERR_NO_AUTH_DATA,
                type,
            );
        }
    }

    private checkAuthenticatorRegistration(type: string) {
        if (!this.authenticators.has(type)) {
            this.throwAuthenticatorError(
                type,
                AuthenticationError.ERR_NO_AUTHENTICATOR,
            );
        }
    }

    private throwAuthenticatorError(type: string, code: string, e?: Error) {
        const err = new AuthenticationError(
            code,
            type,
        );

        err.previousError = e;

        throw err;
    }

    private clearAuthDataSubscription(type: string): void {
        if (this.storedAuthDataSubscriptions.has(type)) {
            this.storedAuthDataSubscriptions.get(type).unsubscribe();
            this.storedAuthDataSubscriptions.delete(type);
        }
    }
}

defaultAuthentication = new Authentication();

export function getDefaultAuthentication() {
    return defaultAuthentication;
}

///
///
/// Errors
///
///

export class AuthenticationError extends SkError {
    public static readonly ERR_NO_AUTHENTICATOR = "ERR_NO_AUTHENTICATOR";
    public static readonly ERR_NO_AUTH_DATA = "ERR_NO_AUTH_DATA";
    public static readonly ERR_AUTHENTICATOR_RUNTIME = "ERR_AUTHENTICATOR_RUNTIME";

    public static readonly MESSAGES = new Map(<[string, string][]>[
        [
            AuthenticationError.ERR_AUTHENTICATOR_RUNTIME,
            "Authenticator runtime error",
        ],
        [
            AuthenticationError.ERR_NO_AUTHENTICATOR,
            "No such authenticator registered",
        ],
        [
            AuthenticationError.ERR_NO_AUTH_DATA,
            "No authentication data exist",
        ],
    ]);

    get dataToLog() {
        return {
            auth_type: this.type,
        };
    }

    constructor(code: string, public type?: string) {
        super("AuthenticationError", code);
    }
}

///
///
/// Interfaces
///
///

export interface AuthenticatorInterface<T extends AuthenticationData> {
    authType: AuthenticationType;
    validateAuth(authData: T): Promise<boolean>;
    renewAuth(authData: T): Promise<T>;
    revokeAuth(authData: T, silent?: boolean): Promise<boolean>;
    setRequestOptions?<O extends ClientOptions>(
        api: string,
        options: O,
        authData: T,
    ): void;
    setRequestUrl?(url: string, authData: T): string;
}

export interface AuthDataPayload {
    options?: ClientOptions;
    url?: string;
    api?: string;
}

export interface AuthenticationData<T extends {} = {}> {
    /**
     * Expiry timestamp in ms since 1970
     */
    expiryTimestamp: number;
    loginTimestamp: number;
    data: T;
}

export enum AuthenticationType {
    Url = 1,
    Options = 1 << 1,
}
