import { Injectable } from "@angular/core";
import { observable, makeObservable } from "mobx";

@Injectable({ providedIn: "root"})
export class InsightsService {

    /**
     * As long as some loading process exists, global loader should be
     * displayed covering the whole app.
     */
    @observable.shallow
    public globalLoadingProcesses: Set<Symbol> = new Set();

    constructor() {
        makeObservable(this);
    }
}
