import { OAuthClient } from "skCommon/auth/oauthClient";
import { DetectionEntityView } from "skCommon/auth0/client";
import { PolygonSource } from "skCommon/polygon/sources/source";
import { AuthenticationData } from "skCommon/core/authentication";

let globalClient: AuthClient;

export function registerAuthClient(client: AuthClient): void {
    globalClient = client;
}

export function getAuthClient(): AuthClient {
    return globalClient;
}

export interface AuthClient extends OAuthClient {
    getLogoutUrl(data: AuthenticationData, returnUrl: string): Promise<string>;
    signup(opts: SignupOptions): Promise<SignupResult>;
    updateUserMetadata(userId: string, data: UserMetadata): Promise<UserMetadata>;
    getUser(userId: string): Promise<UserProfile>;

    resetPassword?(email: string): Promise<void>;
    changePassword?(email: string, newPassword: string): Promise<void>;
}

export interface UserMetadata {
    options?: UserOptions;
    /**
     * Overrides emails to which watchdog notifications are sent.
     * If we ever want to implement email notifications in other parts of
     * application, this metadata can be defined globally.
     */
    notificationEmails?: string[];
}

export interface AppMetadata {
    logLevel?: number;

    sk_groups?: string[];
    sk_extra_permissions?: string[];
}

export interface UserProfile {
    userId: string;
    email: string;
    verified: boolean;
    hasPassword: boolean;

    userMetadata: UserMetadata;
    appMetadata: AppMetadata;

    createdAt?: Date;
    picture?: string;
}

export interface SignupOptions {
    email: string;
    password: string;
    user_metadata?: { [key: string]: string };
}

export interface SignupResult {
    id: string;
    email: string;
}

export interface UserOptions {
    /**
     * Indicate whether analysis results should be displayed as vectorized
     * geometries or as raster mask or both. When result is available in only
     * single type of view, it should be displayed disregarding this option
     */
    enabledView?: DetectionEntityView;
    clusteredDetections?: boolean;
    agreed?: boolean;
    welcomed?: boolean;
    polygonSource?: PolygonSource;
    chartHeight?: string;
    showAnnotations?: boolean;
    showInnerPolygons?: boolean;
    showInnerPolygonLabels?: boolean;
    /**
     * Also display attention regions vector data for all change algorithms
     */
    displayAttentionRegions?: boolean;
    /**
     * Last used map type
     */
    mapType?: string;
    /**
     * Use the experimental SKI renderer in kraken view
     */
    skiVisualization?: boolean;
}
