import editorJsHtml from "editorjs-html";
import { OutputData } from "@editorjs/editorjs";
import { ParagraphData } from "@editorjs/paragraph";

import { StylishParagraphData } from "skInsights/partials/blockEditor/plugins/stylishParagraph/stylishParagraphData";

export function isOutputDataEmpty(d?: OutputData): boolean {
    return !d
        || d.blocks.length === 0
        || d.blocks.every(block => block.type === "paragraph" && !block.data.text);
}

export function outputDataToHtml(content: OutputData): string {
    const parser = editorJsHtml({
        Raw: (def: any) => def.data.html,
        image: ({ data }: any) => {
            const alt = data.caption || "";
            const src = data.file?.url || "";

            const figcaption = data.caption
                ? `<figcaption>${data.caption}</figcaption>`
                : "";
            const img = `<img src="${src}" alt="${alt}" />`;
            const anchoredImg = `<a href="${src}" target="_blank">${img}</a>`;

            return `<figure>${anchoredImg}${figcaption}</figure>`;
        },
        paragraph: ({ data }: { data: ParagraphData | StylishParagraphData }) => {
            const styleDeclarations: string[] = [];

            if ("fontSize" in data && data.fontSize) {
                styleDeclarations.push(`font-size: ${data.fontSize}em`);
            }

            if ("textAlign" in data && data.textAlign) {
                styleDeclarations.push(`text-align: ${data.textAlign}`);
            }

            if ("color" in data && data.color) {
                styleDeclarations.push(`color: ${data.color}`);
            }

            const attrs = styleDeclarations.length
                ? ` style="${styleDeclarations.join(";")}"`
                : "";

            return `<p${attrs}>${data.text}</p>`;
        },
    });

    const renderedElements = (parser.parse(content) as string[]).join("");

    return `<div class="clean-data-content">${renderedElements}</div>`;
}

export function emptyOutputData(): OutputData {
    return {
        blocks: [],
        time: Date.now(),
    };
}
