import { NgModule } from "@angular/core";

import { DashboardCopyrightComponent } from "skInsights/partials/dashboardCopyright/dashboardCopyright.component";

@NgModule({
    declarations: [
        DashboardCopyrightComponent,
    ],
    exports: [
        DashboardCopyrightComponent,
    ],
})
export class DashboardCopyrightModule { }
