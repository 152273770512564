let buildEnv: BuildEnvironment;

export function getBuildEnv(): BuildEnvironment {
    if (!buildEnv) {
        const buildEnvMeta = document.querySelector("[name=build-env]");
        buildEnv = buildEnvMeta
            && buildEnvMeta.getAttribute("value") as BuildEnvironment
            || BuildEnvironment.Production;
    }

    return buildEnv;
}

export enum BuildEnvironment {
    Devel = "development",
    Production = "production",
}
