import { Component, ChangeDetectionStrategy } from "@angular/core";
import { merge, of } from "rxjs";
import { map, mapTo } from "rxjs/operators";

import { BaseComponent } from "skCommon/angular/base/base.component";
import { Logger } from "skCommon/utils/logger";
import { RouterStore } from "skCommon/angular/router.store";
import { Insights } from "skCommon/permissions";

import { DashboardService } from "skInsights/dashboard/dashboard.service";
import { AvailableDashboard } from "skInsights/dashboard/availableDashboard";
import { InsightsService } from "skInsights/insights.service";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { DashboardRoutes } from "skInsights/dashboard/dashboard.routing";
import { UserService } from "skInsights/user/user.service";

const CREATING = Symbol();

@Component({
    selector: "sk-dashboard-list",
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: "./dashboardList.pug",
})
export class DashboardListComponent extends BaseComponent {

    public allDashboards$ = this.dashboardService.availableDashboards$;

    public loading$ = merge(
        of(true),
        this.allDashboards$.pipe(mapTo(false)),
    );

    public availableDashboards$ = this.allDashboards$.pipe(
        map(dashboards => dashboards.filter(d => d.available)),
        map(dashboards => dashboards.sort((a, b) => a.meta.name.localeCompare(b.meta.name))),
        map(dashboards => dashboards.sort(
            (a, b) => this.getDashboardSortValue(b) - this.getDashboardSortValue(a)),
        ),
    );

    public otherDashboards$ = this.allDashboards$.pipe(
        map(dashboards => dashboards.filter(d => !d.available)),
        map(dashboards => dashboards.sort((a, b) => a.meta.name.localeCompare(b.meta.name))),
    );

    public get canCreateDashboard(): boolean {
        return this.userService.permissions.has(Insights.Dashboard.Create);
    }

    constructor(
        private dashboardService: DashboardService,
        private insightsService: InsightsService,
        private logger: Logger,
        private snackBarService: SnackBarService,
        private routerStore: RouterStore,
        private userService: UserService,
    ) {
        super();
    }

    public async createDashboard(): Promise<void> {
        this.insightsService.globalLoadingProcesses.add(CREATING);

        try {
            const id = await this.dashboardService.createEmptyDashboard();

            await this.routerStore.navigate([DashboardRoutes.EditDashboard, id]);
        } catch (e) {
            this.logger.error(e, "Creating a new dashboard");
            this.snackBarService.notify(`Could not create a new dashboard`);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(CREATING);
        }
    }

    public trackDashboards(_: number, dashboard: AvailableDashboard): string {
        return dashboard.id;
    }

    private getDashboardSortValue(dashboard: AvailableDashboard): number {
        return dashboard.promoted && dashboard.global ? 3
            : dashboard.promoted && !dashboard.global ? 2
                : !dashboard.promoted && !dashboard.global ? 0 : 1;
    }
}
