export const API_CONFIG = {
    "website": {
        urls: {
            STABLE: "https://spaceknow.com/",
            DEVEL: "https://devel.spaceknow.com/",
        },
    },
    "credits-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/credits/",
            DEVEL: "https://api.devel.spaceknow.com/credits/",
        },
        methods: {
            "allocate-geojson": "/area/allocate-geojson",
            "check-geojson": "/area/check-geojson",
            "search-group": "/group/search",
            "create-group": "/group/create",
            "get-group": "/group/get",
            "change-group-credit": "/group/change-credit",
            "bind-user": "/user/bind",
            "unbind-user": "/user/unbind",
            "get-remaining-credits": "/get-remaining-credit",
            "create-payment-token": "/payment/new",
            "buy-credit": "/payment/buy-credit",
        },
    },
    "ragnar-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/imagery/",
            MOCK: "http://localhost:9090/",
            DEVEL: "https://api.devel.spaceknow.com/imagery/",
            ONPREMISE: "https://api.local/imagery/",
        },
        methods: {
            "search": "/search",
            "get-image": "/get-image",
            "order": "/order",
            "scene-info": "/scene-info",
            "size-info": "/size-info",
            "thumbnail": "/thumbnail/{sceneId}",
            "ingest-scene": "/ingest-scene/{provider}",
        },
    },
    "tasking-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/tasking/",
            MOCK: "http://localhost:9090/",
            DEVEL: "https://api.devel.spaceknow.com/tasking/",
            ONPREMISE: "https://api.local/tasking/",
        },
        methods: {
            "status": "/get-status",
            "get-pipeline": "/admin/get-pipeline",
        },
    },
    "auth": {
        data: {
            oidcNamespace: {
                STABLE: "https://spaceknow.com/",
                ONPREMISE: "https://spaceknow.com/",
                DEVEL: "https://devel.spaceknow.com/",
            },
        },
    },
    "auth0": {
        urls: {
            STABLE: "https://spaceknow.auth0.com",
            DEVEL: "https://spaceknow-test.auth0.com",
            ONPREMISE: "https://spaceknow-test.auth0.com",
        },
        methods: {
            "authorize": "/authorize",
            "get-token": "/oauth/token",
            "signup": "/dbconnections/signup",
            "logout": "/v2/logout",
            "change-or-reset-password": "/dbconnections/change_password",
            "userinfo": "/userinfo",
            "user": "/api/v2/users/{id}",
        },
        data: {
            domain: {
                STABLE: "spaceknow.auth0.com",
                ONPREMISE: "spaceknow-test.auth0.com",
                DEVEL: "spaceknow-test.auth0.com",
            },
            client_id: {
                STABLE: "CP2hrNFIStlVEJUFAksfw3htqy9qwsP9",
                ONPREMISE: "WVAl5c9JmluIuQywY3MqTGTP45S9HxpM",
                DEVEL: "WVAl5c9JmluIuQywY3MqTGTP45S9HxpM",
            },
            audience: {
                STABLE: "https://spaceknow.auth0.com/api/v2/",
                DEVEL: "https://spaceknow-test.auth0.com/api/v2/",
            },
            login_expiration: {
                STABLE: 1561035600000,
                DEVEL: 1561035600000,
            },
        },
    },
    "fusionauth": {
        urls: {
            STABLE: "https://fusionauth.local",
            DEVEL: "https://fusionauth.local",
            ONPREMISE: "https://fusionauth.local",
        },
        methods: {
            "authorize": "/oauth2/authorize",
            "get-token": "/oauth2/token",
            "logout": "/oauth2/logout",
            "logout-api": "/api/logout",
            "change-password": "/api/user/change-password",
            "userinfo": "/oauth2/userinfo",
            "user": "/api/user",
        },
        data: {
            client_id: {
                ONPREMISE: "9a496730-73ac-44f8-af35-3cfc2469caf3",
            },
            audience: {
                ONPREMISE: "",
            },
            login_expiration: {
                ONPREMISE: 0,
            },
        },
    },
    "auth0-management": {
        urls: {
            STABLE: "https://spaceknow.auth0.com",
            DEVEL: "https://spaceknow-test.auth0.com",
        },
        methods: {
            users: "/api/v2/users/{user_id}",
        },
    },
    "user-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/user/",
            DEVEL: "https://api.devel.spaceknow.com/user/",
            ONPREMISE: "https://api.local/user/",
        },
        methods: {
            manage: "/manage",
            info: "/info",
        },
    },
    "kraken-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/kraken/",
            MOCK: "http://localhost:9090/",
            DEVEL: "https://api.devel.spaceknow.com/kraken/",
            ONPREMISE: "https://api.local/kraken/",
        },
        methods: {
            "grid": "/grid/{mapId}/{geometryId}/{z}/{x}/{y}/{layer}.{ext}",
            "grid-filename": "/grid/{mapId}/{geometryId}/{z}/{x}/{y}/{filename}",
            "dry-run": "/dry-run",
            "pairwise": "/pairwise",
            "nwise": "/nwise",
            "protect": "/protect",
        },
    },
    "geojsondb-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/geojsondb/",
            DEVEL: "https://api.devel.spaceknow.com/geojsondb/",
            ONPREMISE: "https://api.local/geojsondb/",
        },
        methods: {
            "get-feature": "/api/feature/get",
            "delete-feature": "/api/feature/delete",
            "create-feature": "/api/features/create",
            "modify-feature": "/api/feature/modify",
            "search-features": "/api/features/search",
            "properties": "/api/properties",
        },
    },
    "insights-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/insights/",
            DEVEL: "https://api.devel.spaceknow.com/insights/",
            ONPREMISE: "https://api.local/insights/",
        },
    },
    "datacube-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/datacube/",
            DEVEL: "https://api.devel.spaceknow.com/datacube/",
            ONPREMISE: "https://api.local/datacube/",
        },
        methods: {
            "list-packages": "/packages/list",
            "create-package": "/packages/create",
            "delete-package": "/packages/delete",
            "get-datapoints": "/datapoints/get",
            "create-datapoints": "/datapoints/create",
            "get-aois": "/aois/get",
            "get-catalogue": "/catalogue/get",
            "update-catalogue": "/catalogue/update",
            "list-subscriptions": "/subscriptions/list",
            "update-subscription": "/subscriptions/update",
            "get-product": "/product/get",
            "get-products": "/product-catalogue/get",
            "update-product": "/product-catalogue/update",
            "list-product-packages": "/product-packages/list",
            "create-product-packages": "/product-packages/create",
            "delete-product-packages": "/product-packages/delete",
            "sales-info": "product/sales-info/get",
        },
    },
    "watchdog-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/watchdog/",
            DEVEL: "https://api.devel.spaceknow.com/watchdog/",
        },
        methods: {
            create: "/create",
            update: "/update",
            delete: "/delete",
            get: "/get",
            status: "/statuses/list",
            list: "/list",
        },
    },
    "deliveries-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/deliveries/",
            DEVEL: "https://api.devel.spaceknow.com/deliveries/",
        },
        methods: {
            "alchemist-upload": "/alchemist/upload",
        },
    },
    "workflows-api": {
        urls: {
            STABLE: "https://api.spaceknow.com/workflows/",
            DEVEL: "https://api.devel.spaceknow.com/workflows/",
        },
    },
    "olaf-db": {
        urls: {
            ONPREMISE: "https://db.analytics.local/",
        },
        methods: {
            "analysis": "/analysis/{id}",
            "analysis-frame": "/analysis-frame/{id}",
            "user-metadata": "/user-metadata/{id}",
            "feature-info": "/feature-info/{id}",
            "pin": "/pin/{id}",
            "upload-image": "/upload-image",
        },
    },
    "epsg-io": {
        urls: {
            STABLE: "https://epsg.io/",
            DEVEL: "https://epsg.io/",
        },
        methods: {
            proj4: "{code}.proj4",
        },
    },
    "firebase": {
        data: {
            config: {
                DEVEL: {
                    apiKey: "AIzaSyDbr6d97YcY_BEZboVRGmNqfMg8vVO53HE",
                    databaseURL: "https://spaceknow-devel-analytics.firebaseio.com",
                    projectId: "spaceknow-devel-analytics",
                },
                STAGING: {
                    apiKey: "AIzaSyDF6N1p_MEC0YHTP6eo979mw-JNUlw-01E",
                    databaseURL: "https://spaceknow-staging-analytics.firebaseio.com",
                    projectId: "spaceknow-staging-analytics",
                },
                STABLE: {
                    apiKey: "AIzaSyBnwFsXrKtxVUdQg0LtFlxRy_VWlBZKKFo",
                    databaseURL: "https://spaceknow-analytics.firebaseio.com",
                    projectId: "spaceknow-analytics",
                },
                ONPREMISE: {
                    apiKey: "AIzaSyDbr6d97YcY_BEZboVRGmNqfMg8vVO53HE",
                    databaseURL: "https://spaceknow-devel-analytics.firebaseio.com",
                    projectId: "spaceknow-devel-analytics",
                },
            },
        },
    },
    "firebase-annotations": {
        data: {
            config: {
                DEVEL: {
                    apiKey: "AIzaSyC85FdhdVBAhat9qtasjDcA0bY0Ewyn3-g",
                    databaseURL: "https://spaceknow-devel-annotations.firebaseio.com",
                    projectId: "spaceknow-devel-annotations",
                    storageBucket: "gs://spaceknow-devel-annotations.appspot.com",
                },
                STABLE: {
                    apiKey: "AIzaSyC4L6FJSewKhOH2U691sHAwhrsvYqNqA8Y",
                    databaseURL: "https://spaceknow-annotations-249612.firebaseio.com",
                    projectId: "spaceknow-annotations-249612",
                    storageBucket: "gs://spaceknow-annotations-249612.appspot.com",
                },
            },
        },
    },
    "firebase-datacube": {
        data: {
            config: {
                DEVEL: {
                    apiKey: "AIzaSyDnegTD_IxMQjOweqT7GFM5xthdtNR3dGA",
                    projectId: "spaceknow-devel-datacube",
                },
                STABLE: {
                    apiKey: "AIzaSyBQRY-Z4jUqOOpb4FTdhfiHtH6C5xOnguA",
                    projectId: "spaceknow-datacube",
                },
            },
        },
    },
    "firebase-insights": {
        data: {
            config: {
                STAGING: {
                    apiKey: "AIzaSyDna4ageqv_XNmNGlAOMFjDVg5NBNiYT-o",
                    projectId: "spaceknow-staging-insights",
                    storageBucket: "gs://spaceknow-staging-insights.appspot.com",
                },
                DEVEL: {
                    apiKey: "AIzaSyA_GDUOXWg3GejjrG2o-bycJaySUlOVPxI",
                    projectId: "spaceknow-devel-insights",
                    storageBucket: "gs://spaceknow-devel-insights.appspot.com",
                },
                STABLE: {
                    apiKey: "AIzaSyAS8SrlRi9L6qUal_YHzh4UkSt1VgZrk0o",
                    projectId: "spaceknow-insights",
                    storageBucket: "gs://spaceknow-insights.appspot.com",
                },
            },
        },
    },
    "mapbox": {
        urls: {
            ONPREMISE: "https://api.mapbox.com/",
            STABLE: "https://api.mapbox.com/",
            DEVEL: "https://api.mapbox.com/",
        },
        methods: {
            "styles": "/styles/v1/spaceknow-analytics/{styleId}",
            "static-image": "/styles/v1/spaceknow-analytics/{styleId}/static"
                + "/{lon},{lat},{zoom},{bearing},{pitch}/{width}x{height}{size}",
            "tile-json": "/v4/{id}.json",
        },
        data: {
            api_key: {
                // tslint:disable:max-line-length
                STABLE: "pk.eyJ1Ijoic3BhY2Vrbm93LWFuYWx5dGljcyIsImEiOiJjamJ6YmFjYXEzYXUwMndwYW15M3RyYzc2In0.NqKjsJ4bLT8JurKtDod-xw",
                DEVEL: "pk.eyJ1Ijoic3BhY2Vrbm93LWFuYWx5dGljcyIsImEiOiJjamJ6YmFjYXEzYXUwMndwYW15M3RyYzc2In0.NqKjsJ4bLT8JurKtDod-xw",
                ONPREMISE: "pk.eyJ1Ijoic3BhY2Vrbm93LWFuYWx5dGljcyIsImEiOiJjamJ6YmFjYXEzYXUwMndwYW15M3RyYzc2In0.NqKjsJ4bLT8JurKtDod-xw",
                // tslint:enable:max-line-length
            },
        },
    },
    "bing": {
        urls: {
            STABLE: "https://dev.virtualearth.net/",
            DEVEL: "https://dev.virtualearth.net/",
        },
        methods: {
            "imagery-metadata": "/REST/v1/Imagery/Metadata/{imagerySet}/{centerPoint}",
            // tslint:disable-next-line:max-line-length
            "imagery-map": "/REST/v1/Imagery/Map/{imagerySet}/{centerLat},{centerLon}/{zoomLevel}",
        },
        data: {
            api_key: {
                STABLE: "ArTTcRhddI0U83oJEJG-HFVo5-pWIPogaNStiX_tNKVzgN8Uq73ZoWr5hEjdCvzg",
                DEVEL: "ArTTcRhddI0U83oJEJG-HFVo5-pWIPogaNStiX_tNKVzgN8Uq73ZoWr5hEjdCvzg",
            },
        },
    },
    "google-maps": {
        urls: {
            STABLE: "https://maps.googleapis.com/",
            DEVEL: "https://maps.googleapis.com/",
        },
        methods: {
            // tslint:disable-next-line:max-line-length
            "static-map": "/maps/api/staticmap?center={centerLat},{centerLon}&zoom={zoom}&size={width}x{height}&maptype={mapType}&key={key}",
        },
        data: {
            api_key: {
                STABLE: "AIzaSyDK85c0Ef9Er9yt72lFrKrtKJudkdZvcrA",
                DEVEL: "AIzaSyDK85c0Ef9Er9yt72lFrKrtKJudkdZvcrA",
            },
        },
    },
};

export enum HostingGroups {
    Staging = "STAGING",
    Stable = "STABLE",
    Devel = "DEVEL",
    Mock = "MOCK",
    OnPremise = "ONPREMISE",
}

export const DOMAIN_GROUPS: ApiConfigGroups = {
    "analytics.spaceknow.com": [HostingGroups.Stable],
    "planet.spaceknow.com": [HostingGroups.Stable],
    "prototype.spaceknow.com": [HostingGroups.Stable],
    "demo.spaceknow.com": [HostingGroups.Stable],
    "guardian.spaceknow.com": [HostingGroups.Stable],
    "guardian.beta.spaceknow.com": [HostingGroups.Stable],
    "admin.spaceknow.com": [HostingGroups.Stable],
    "admin.staging.spaceknow.com": [HostingGroups.Stable],
    "geojsondb.spaceknow.com": [HostingGroups.Stable],
    "annotations.spaceknow.com": [HostingGroups.Stable],
    "geojsondb.beta.spaceknow.com": [HostingGroups.Stable],
    "analytics.beta.spaceknow.com": [HostingGroups.Stable],
    "datacube.spaceknow.com": [HostingGroups.Stable],
    "datacube.beta.spaceknow.com": [HostingGroups.Stable],
    "insights.spaceknow.com": [HostingGroups.Stable],
    "spaceknow.localhost": [HostingGroups.Stable],
    "spaceknow-analytics": [HostingGroups.Stable],
    "spaceknow-staging-analytics": [HostingGroups.Stable],
    "spaceknow-annotations-249612": [HostingGroups.Stable],
    "spaceknow-datacube": [HostingGroups.Stable],
    "spaceknow-insights": [HostingGroups.Stable],


    "analytics.staging.spaceknow.com": [ HostingGroups.Staging, HostingGroups.Stable],
    "insights.staging.spaceknow.com": [HostingGroups.Staging, HostingGroups.Stable],
    "staging.spaceknow.localhost": [HostingGroups.Staging, HostingGroups.Stable],
    "spaceknow-staging-insights": [HostingGroups.Staging, HostingGroups.Stable],

    "olaf-mock.spaceknow.localhost": [HostingGroups.Mock],

    "analytics.local": [HostingGroups.OnPremise, HostingGroups.Stable],
    "onpremise.localhost": [HostingGroups.OnPremise, HostingGroups.Stable],
    "olaf-hg.spaceknow.localhost": [HostingGroups.OnPremise, HostingGroups.Stable],

    "analytics.devel.spaceknow.com": [HostingGroups.Devel],
    "admin.devel.spaceknow.com": [HostingGroups.Devel],
    "spaceknow-devel-annotations": [HostingGroups.Devel],
    "spaceknow-devel-datacube": [HostingGroups.Devel],
    "spaceknow-devel-insights": [HostingGroups.Devel],
    "insights.devel.spaceknow.com": [HostingGroups.Devel],
    "datacube.devel.spaceknow.com": [HostingGroups.Devel],
    "annotations.devel.spaceknow.com": [HostingGroups.Devel],
};

export type ApiConfigGroups = Record<string, Array<HostingGroups>>;

export interface ApiObject {
    urls?: ApiConfigPropertyByHost;
    methods?: ApiObjectMethods;
    data?: ApiObjectData;
}

export type ApiConfigPropertyByHost<T = string> = {
    [k in HostingGroups]?: T;
};

export interface ApiObjectMethods {
    [methodName: string]: string;
}

interface ApiObjectData {
    [dataPropName: string]: ApiConfigPropertyByHost<string | number | Object>;
}
