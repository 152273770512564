import { CSSProperties, h } from "jsx-dom";
import { API, BlockToolConstructorOptions } from "@editorjs/editorjs";
import Paragraph, { ParagraphData } from "@editorjs/paragraph";

import { Svg } from "skCommon/svg";

import { StylishData, StylishParagraphData } from "skInsights/partials/blockEditor/plugins/stylishParagraph/stylishParagraphData";

export class StylishParagraphTool extends Paragraph {

    static get sanitize() {
        return {
            text: {
                br: true,
                span: true,
            },
        };
    }

    private api: API;

    private settingsWrapper: HTMLElement = <div></div> as HTMLElement;

    private stylishData: StylishData = {
        fontSize: null,
        textAlign: null,
        color: null,
    };

    /**
     * List of styling functions which are run whenever style changes
     */
    private stylers: ((el: HTMLElement) => void)[] = [];

    constructor(options: BlockToolConstructorOptions) {
        super(options);

        this.api = options.api;

        const data = options.data as ParagraphData | StylishParagraphData;

        this.registerCustomButton(data, {
            property: "textAlign",
            icon: Svg.FormatAlignLeft,
            values: {
                Center: "center",
                Left: "left",
                Right: "right",
                Justify: "justify",
                Default: null,
            },
            styler: this.makeStyler("textAlign", "textAlign"),
        });

        this.registerCustomButton(data, {
            property: "fontSize",
            icon: Svg.TextFields,
            values: {
                Tiny: 0.6,
                Small: 0.8,
                Normal: null,
                Large: 1.2,
                Oversized: 1.4,
            },
            styler: this.makeStyler("fontSize", "fontSize", "em"),
        });

        this.registerCustomButton(data, {
            property: "color",
            icon: Svg.Fill,
            values: {
                Primary: "#4963E1",
                Secondary: "#1A9479",
                Tertiary: "#EC286C",
                Warning: "#7C7110",
                Alert: "#E23734",
                Default: null,
            },
            styler: this.makeStyler("color", "color"),
            optionGenerator: (but, color) => {
                if (color) {
                    but.style.backgroundColor = color;
                    but.style.color = "white";
                }

                return but;
            },
        });
    }

    public renderSettings(): HTMLElement {
        return this.settingsWrapper;
    }

    public render(): HTMLElement {
        const origParagraph = super.render();

        this.updateStylishStyle(origParagraph);

        return origParagraph;
    }

    /**
     * Register a new button displayed in the paragraph option and function
     * which handles the application of the style.
     */
    private registerCustomButton<K extends keyof StylishData>(
        data: ParagraphData | StylishParagraphData,
        {
            icon,
            property,
            values,
            styler,
            optionGenerator,
        }: CustomButtomOptions<K>,
    ): void {
        if (property in data) {
            this.stylishData[property] = (data as StylishParagraphData)[property];
        }

        const list = this.createListSelect<K>(values, selectedValue => {
            // Set the value and update style on click
            this.settingsWrapper.removeChild(list);
            this.stylishData[property] = selectedValue;
            this.updateStylishStyle(this._element);
        }, optionGenerator);

        const button = this.makeCustomButton(icon, () => {
            // Toggle menu on click
            if (this.settingsWrapper.contains(list)) {
                this.settingsWrapper.removeChild(list);
            } else {
                this.settingsWrapper.appendChild(list);
            }
        });

        this.settingsWrapper.appendChild(button);
        this.stylers.push(styler);
    }

    private updateStylishStyle(element: HTMLElement): void {
        this.stylers.forEach(fn => fn(element));
    }

    public save(block: HTMLElement): StylishParagraphData {
        const origData = super.save(block) as ParagraphData;

        return {
            ...origData,
            ...this.stylishData,
        };
    }

    private makeCustomButton(icon: Svg, handler: (e: MouseEvent) => void): HTMLElement {
        const buttonCssClass = this.api.styles.settingsButton;

        return <button class={buttonCssClass} onClick={handler}>
            <svg class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="14">
                <use href={`/spritemap.svg#sprite-${icon}`}></use>
            </svg>
        </button> as HTMLElement;
    }

    private createListSelect<K extends keyof StylishData>(
        options: Record<string, StylishData[K]>,
        handler: (v: StylishData[K]) => void,
        buttonGenerator: OptionGenerator<K> = a => a,
    ): HTMLElement {
        const listStyle: CSSProperties = {
            position: "absolute",
            zIndex: 1,
        };

        const buttons = Object.entries(options).map(([key, option]) =>
            buttonGenerator(
                <button class="p-2 px-4" onClick={() => handler(option)}>
                    {key}
                </button> as HTMLButtonElement,
                option,
            ),
        );

        return <div class="d-flex flex-direction-column card" style={listStyle}>
            {buttons}
        </div> as HTMLElement;
    }

    private makeStyler(
        stylishProp: keyof StylishData,
        cssProp: StringKeys<CSSStyleDeclaration>,
        after: string = "",
    ): CustomButtonStyler {
        return element => {
            const val = this.stylishData[stylishProp];

            if (val) {
                element.style[cssProp] = `${val}${after}`;
            } else {
                element.style[cssProp] = "";
            }
        };
    }
}

interface CustomButtomOptions<K extends keyof StylishData> {
    property: K;
    icon: Svg;
    values: Record<string, StylishData[K]>;
    styler: CustomButtonStyler;
    optionGenerator?: OptionGenerator<K>;
}

type CustomButtonStyler = (el: HTMLElement) => void;

type StringKeys<T> = { [K in keyof T]: Extract<T[K], string> extends never ? never : K}[keyof T];

type OptionGenerator<K extends keyof StylishData> = (
    b: HTMLButtonElement,
    value: StylishData[K],
) => HTMLButtonElement;
