import { Component, ChangeDetectionStrategy } from "@angular/core";
import { map } from "rxjs/operators";
import { makeObservable } from "mobx";

import { DatacubeClient, DatacubeFilter, DatacubePackage, ProductPackage } from "skCommon/datacube/client";
import { FilterQuery, queryToFilters } from "skCommon/datacube/query";
import { observableRef } from "skCommon/state/mobxUtils";
import { assert } from "skCommon/utils/assert";
import { Logger } from "skCommon/utils/logger";
import { DashboardSchema } from "skCommon/insights/dashboard";
import { ParsedDashboard } from "skCommon/insights/parsedDashboard";

import { DashboardService } from "skInsights/dashboard/dashboard.service";
import { DashboardRef } from "skInsights/framework/dashboardData";
import { InsightsService } from "skInsights/insights.service";
import { GetQuerySourceDef } from "skInsights/modules/datacube/sources/getQuery";
import { ProductSourceDef } from "skInsights/modules/datacube/sources/product";
import { SnackBarService } from "skInsights/utils/snackBar.service";
import { ProductCatalogService } from "skInsights/helpers/datacube/productCatalog.service";

const UPDATING = Symbol();

@Component({
    selector: "sk-grant-access",
    templateUrl: "./grantAccess.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrantAccessComponent {

    @observableRef
    public userId: string = "";

    @observableRef
    public dashboardId: string = "";

    public globalDashboards$ = this.dashboardService.availableDashboards$.pipe(
        map(dbs => dbs.filter(db => db.global)),
    );

    public get canSubmit(): boolean {
        return !!this.dashboardId && !!this.userId;
    }

    constructor(
        private dashboardService: DashboardService,
        private insightsService: InsightsService,
        private snackBarService: SnackBarService,
        private logger: Logger,
        private datacubeClient: DatacubeClient,
        private productCatalogService: ProductCatalogService,
    ) {
        makeObservable(this);
    }

    public async grant(): Promise<void> {
        try {
            assert(this.userId && this.dashboardId, "Missing user ID or dashboard ID");

            this.insightsService.globalLoadingProcesses.add(UPDATING);

            const ref = { id: this.dashboardId };
            const dashboard = await this.dashboardService.loadDashboardRef(ref);

            await this.handleDatacubeProducts(ref, dashboard);
            await this.handleDatacubePermissions(ref, dashboard);
        } catch (e) {
            this.logger.error(e, "Grant dashboard permissions");
            this.snackBarService.notify(`Could not grant permissions: ${e.message}`);
        } finally {
            this.insightsService.globalLoadingProcesses.delete(UPDATING);
        }
    }

    private async handleDatacubeProducts(
        ref: DashboardRef,
        dashboard: ParsedDashboard,
    ): Promise<void> {
        const productIds = this.getProductIds(dashboard.schema);

        if (productIds.length) {
            await this.assertProductsActivated(productIds);

            const pkgs = await this.datacubeClient
                .listProductPackages({ userId: this.userId });

            const existingPkgs = this.findPkgsForDashboard(pkgs, ref);

            await this.deleteAllProductPackages(existingPkgs);

            const newPkgDescription = this.makePkgDescription(dashboard);

            await this.datacubeClient.createProductPackage({
                description: newPkgDescription,
                userId: this.userId,
                productIds,
            });
        }
    }

    private async assertProductsActivated(productIds: string[]): Promise<void> {
        const prods = await this.productCatalogService.getProducts();
        const productActiveMap = new Map(prods.map(prod => [prod.productId, prod.active]));

        const notActivatedIds = productIds.filter(id => !productActiveMap.get(id));

        if (notActivatedIds.length) {
            throw new Error(`Products ${notActivatedIds.join(", ")} are not activated`);
        }
    }

    private async handleDatacubePermissions(
        ref: DashboardRef,
        dashboard: ParsedDashboard,
    ): Promise<void> {
        const queries = this.getDatacubeQueries(dashboard.schema);

        const filterPackages = queries.map(q => queryToFilters(q));

        const pkgs = await this.datacubeClient.listPackages(this.userId);
        const existingPkgs = this.findPkgsForDashboard(pkgs, ref);

        await this.deleteAllDatacubePackages(existingPkgs);
        await this.createDatacubePackagesForFilters(dashboard, filterPackages);
    }

    private getProductIds(schema: DashboardSchema): string[] {
        return [...new Set(
            Object.values(schema.sources || {})
                .filter(src => src.type === "datacube/product")
                .map(src => (src as ProductSourceDef).productId),
        )];
    }

    private getDatacubeQueries(schema: DashboardSchema): MinimalFilterQuery[] {
        return Object.values(schema.sources || {})
            .filter(src => src.type === "datacube/get-query")
            .map(src => {
                const { query } = src as GetQuerySourceDef;

                return {
                    algorithm: query?.algorithm,
                    project: query?.project,
                    source: query?.source,
                    aoi: query?.aoi,
                    version: query?.version,
                };
            });
    }

    /**
     * Create package description that is both human readable and parseable, so
     * we the package can be replaced when updated.
     */
    private makePkgDescription(db: ParsedDashboard, i: number = 0): string {
        return `Insights dashboard '${db.meta.name}' (${i}) dbid#${db.id}`;
    }

    /**
     * Find product package that was created for given dashboard.
     */
    private findPkgsForDashboard<T extends { description: string }>(
        packages: T[],
        dbRef: DashboardRef,
    ): T[] {
        return packages.filter(pkg => this.parsePackage(pkg)?.id === dbRef.id);
    }

    /**
     * Parse dashboard ref from the package description.
     */
    private parsePackage<T extends { description: string }>(
        { description }: T,
    ): DashboardRef | undefined {
        const matched = description.match(/dbid#([0-z]+)$/);

        if (matched && matched[1]) {
            return { id: matched[1] };
        } else {
            return void 0;
        }
    }

    private async deleteAllProductPackages(productPackages: ProductPackage[]): Promise<void> {
        const promises = productPackages.map(
            ({ packageId }) => this.datacubeClient.deleteProductPackage({ packageId }),
        );

        await Promise.all(promises);
    }

    private async deleteAllDatacubePackages(packages: DatacubePackage[]): Promise<void> {
        const promises = packages.map(
            ({ packageId }) => this.datacubeClient.deletePackage(packageId),
        );

        await Promise.all(promises);
    }

    private async createDatacubePackagesForFilters(
        dashboard: ParsedDashboard,
        filtersSets: DatacubeFilter[][],
    ): Promise<void> {
        const promises = filtersSets
            .map((filters, i) => ({
                description: this.makePkgDescription(dashboard, i),
                userId: this.userId,
                filters,
            }))
            .map(pkg => this.datacubeClient.createPackage(pkg));

        await Promise.all(promises);
    }
}

type MinimalFilterQuery = Pick<FilterQuery, "algorithm" | "project" | "source" | "aoi" | "version">;
