/**
 * Function for use in Array#filter with strict null checks on which correctly
 * removes guards against void type which `a => !!a` curently does not.
 * Null is intentionally not checked.
 *
 * @see https://github.com/Microsoft/TypeScript/issues/16069
 */
export function exists<T>(a: T | undefined): a is Exclude<T, void> {
    return a !== undefined;
}

/**
 * Return function to use in filter method to ensure given objects have given
 * property non-undefined.
 */
export function propertyExists<T, K extends keyof T>(
    prop: K,
): (v: T) => v is T & { [k in K]-?: Exclude<T[K], undefined> } {
    return (a): a is T & { [k in K]-?: Exclude<T[K], undefined> } => exists(a[prop]);
}

export function nonNull<T>(a: T | null): a is Exclude<T, null> {
    return a !== null;
}

/**
 * Useful type and type checker that
 * 1. tells user that it's never gonna be empty
 * 2. allows referencing arr[0] without any asserting even with strictest
 * TS settings.
 */
export function nonEmptyArray<T>(a: T[]): a is NonEmptyArray<T> {
    return a.length > 0;
}

export type NonEmptyArray<T> = [T, ...T[]];
