import { NgModule } from "@angular/core";

import { JsonEditorComponent } from "skInsights/partials/jsonEditor/jsonEditor.component";

@NgModule({
    declarations: [
        JsonEditorComponent,
    ],
    exports: [
        JsonEditorComponent,
    ],
})
export class JsonEditorModule { }
