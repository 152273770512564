import { Injectable } from "@angular/core";

import { Insights } from "skCommon/permissions";

import { DashboardViewService } from "skInsights/dashboard/dashboardView/dashboardView.service";
import { UserService } from "skInsights/user/user.service";
import { DashboardRoutes } from "skInsights/dashboard/dashboard.routing";

/**
 * Service used outside of the data explorer to check availability and to open
 * the explorer.
 */
@Injectable({ providedIn: "root" })
export class DataExplorerNavService {

    constructor(
        private dashboardViewService: DashboardViewService,
        private userService: UserService,
    ) { }

    public get available(): boolean {
        return this.userService.loggedIn
            && !!this.dashboardViewService.dashboard
            && this.userService.permissions.has(Insights.Data.Explore);
    }

    public get urlForCurrentDashboard(): string[] {
        const { dashboard } = this.dashboardViewService;

        if (dashboard) {
            return [
                "/",
                DashboardRoutes.Dashboard,
                dashboard.id,
                DashboardRoutes.DataExplorer,
            ];
        } else {
            return [];
        }
    }
}
