import { InjectionToken, Type } from "@angular/core";

/**
 * Token for providing designer components which are then used to render editor
 * UI for each module in dashboard. Ideally each registered component should
 * have also designer component registered.
 */
export const DESIGNERS_TOKEN = new InjectionToken("designer components");

export function provideDesigner(
    type: string,
    clss: Type<any>,
): ComponentClassProvider {
    return {
        provide: DESIGNERS_TOKEN,
        multi: true,
        useValue: {
            type,
            designerClass: clss,
        },
    };
}

export interface ComponentClassProvider {
    provide: typeof DESIGNERS_TOKEN;
    multi: true;
    useValue: DesignerClassDefinition;
}

export interface DesignerClassDefinition {
    type: string;
    designerClass: Type<any>;
}
